import { Form } from '@themesberg/react-bootstrap';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const renderBuildingDropdown = ({ label, input, meta, renderedOptions, disabled, change }) => {
	const { t } = useTranslation();

	return (
		<Form.Group>
			{label ? <Form.Label>{label}</Form.Label> : null}

			<Form.Select
				{...input}
				disabled={disabled}
				onChange={(value) => {
					change('floor_id', 0);
					change('room_id', 0);
					input.onChange(value);
				}}>
				<option value="0">{t('select')}</option>
				{renderedOptions}
			</Form.Select>
			<span className="text-danger">{meta.touched && meta.error ? meta.error : null}</span>
		</Form.Group>
	);
};

export const renderFloorDropdown = ({ label, input, meta, renderedOptions, disabled, change }) => {
	const { t } = useTranslation();

	return (
		<Form.Group>
			{label ? <Form.Label>{label}</Form.Label> : null}

			<Form.Select
				{...input}
				disabled={disabled}
				onChange={(value) => {
					change('room_id', 0);
					input.onChange(value);
				}}>
				<option value="0">{t('select')}</option>
				{renderedOptions}
			</Form.Select>
			<span className="text-danger">{meta.touched && meta.error ? meta.error : null}</span>
		</Form.Group>
	);
};
