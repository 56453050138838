import {
	GET_TICKETS_ASSIGNED,
	GET_TICKET,
	GET_TICKETS,
	GET_TICKET_CATEGORIES,
	GET_TICKETS_BELONG_TO_USER,
} from '../../actions/ITDepartment/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	Ticket: {},
	Tickets: [],
	loading: true,
	getTicketLoading: true,
	getTicketsLoading: true,
	getTicketCategoriesLoading: true,
	TicketCategories: [],
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_TICKETS:
			return { ...state, Tickets: action.payload, getTicketsLoading: false };

		case GET_TICKETS_ASSIGNED:
			return { ...state, Tickets: action.payload, getTicketsLoading: false };

		case GET_TICKET:
			return { ...state, ticket: action.payload, getTicketLoading: false };
		case GET_TICKET_CATEGORIES:
			return { ...state, TicketCategories: action.payload, getTicketCategoriesLoading: false };
		case GET_TICKETS_BELONG_TO_USER:
			return { ...state, Tickets: action.payload, getTicketsLoading: false };
		case CLEAR_DATA:
			return {
				...state,
				getTicketsLoading: true,
				Ticket: action.payload,
				getTicketLoading: true,
				getTicketsLoading: true,
				getTicketCategoriesLoading: true,
			};

		default:
			return state;
	}
};
