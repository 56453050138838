//PRODUCT

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_ITEMS = 'GET_ITEMS';

// OVERVIEW

export const GET_OVERVIEW_COUNTERS = 'GET_OVERVIEW_COUNTERS';

//USERS

//DOCUMENTATION

//ERROR

export const ERROR = 'ERROR';

export const CLEAR_DATA = 'CLEAR_DATA';

// Power BI
