import { CREATE_POLICY, GET_POLICY, UPDATE_POLICY } from '../actions/Admin/types';

const INITIAL_STATE = {
	policy: {},
	getPolicyLoading: true,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CREATE_POLICY:
			return {
				...state,
			};
		case UPDATE_POLICY:
			return { ...state };
		case GET_POLICY:
			return { ...state, policy: action.payload, getPolicyLoading: false };

		default:
			return state;
	}
};
