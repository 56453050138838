import { faHome, faPercent } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Card, Col, Row } from '@themesberg/react-bootstrap';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { Field, reduxForm, initialize } from 'redux-form';
import renderDropdownWithAutoComplete from '../../../../components/formComponents/renderDropdownWithAutoComplete';
import renderInput from '../../../../components/formComponents/renderInput';
import renderInputWithIcon from '../../../../components/formComponents/renderInputWithIcon';
import renderTextArea from '../../../../components/formComponents/renderTextArea';
import SubmitButton from '../../../../components/formComponents/SubmitButton';
import { Routes } from '../../../../routes';

const ProjectMilestoneForm = (props) => {
	const { initials, datas, onSubmit, full_name, user_id, companies } = props;
	const { t } = useTranslation();

	useEffect(() => {
		if (initials) {
			props.initialize({
				...initials,
				by: full_name,
				company_id: companies[0].company_id,
				date: new Date(initials.date).toISOString().split('T')[0],
			});
		} else {
			props.initialize({
				by: full_name,
				company_id: companies[0].company_id,
			});
		}
		props.change('user_id', user_id);
		props.change('project_id', datas.project.project_id);
		props.change('project_name', datas.project.project_name);
	}, []);

	return (
		<>
			<div className="d-block mb-4 mb-xl-0">
				<Breadcrumb
					className="d-block"
					listProps={{
						className: 'breadcrumb-dark breadcrumb-transparent',
					}}>
					<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
						<FontAwesomeIcon icon={faHome} />
					</Breadcrumb.Item>
					<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Finance.Project.Index.path }}>
						{t('finance')}{' '}
					</Breadcrumb.Item>
					<Breadcrumb.Item active>{t('project-milestone')}</Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<form onSubmit={props.handleSubmit(onSubmit)}>
				<Row>
					<Col md={12}>
						<Card border="light" className="bg-white shadow-sm mb-4 w-100">
							<Card.Body>
								<h5 className="mb-4">{t('project-milestone-form')}</h5>
								<Row className="mb-3">
									<Col md={6} sm={12}>
										<Field name="by" label={t('by')} component={renderInput} disabled="disabled" />
									</Col>
									<Col md={6} sm={12}>
										<Field
											data={companies}
											dataKey="company_id"
											textField="company_name"
											name="company_id"
											label={t('company')}
											component={renderDropdownWithAutoComplete}
										/>
									</Col>
								</Row>
								<hr />
								<Row className="mb-3">
									<Col lg={6} md={12}>
										<Field name="project_name" disabled="disabled" label={t('project-name')} component={renderInput} />
									</Col>
									<Col lg={6} md={12}>
										<Field name="date" label={t('date-0')} inputType="date" component={renderInput} />
									</Col>
								</Row>
								<Row className="mb-3">
									<Col lg={6} md={12}>
										<Field
											name="total_expense_spent"
											label={t('total-expense-spent')}
											inputType="number"
											component={renderInput}
										/>
									</Col>
									<Col lg={6} md={12}>
										<Field
											name="expected_total_spent"
											label={t('expected-total-spent')}
											inputType="number"
											component={renderInput}
										/>
									</Col>
								</Row>
								<Row className="mb-3">
									<Col lg={6} md={12}>
										<Field
											name="actual_man_power"
											label={t('actual-man-power')}
											inputType="number"
											component={renderInput}
										/>
									</Col>
									<Col lg={6} md={12}>
										<Field
											name="estimated_man_power"
											label={t('estimated-man-power')}
											inputType="number"
											component={renderInput}
										/>
									</Col>
								</Row>
								<Row className="mb-3">
									<Col lg={6} md={12}>
										<Field
											name="schedule"
											label={t('schedule')}
											inputType="number"
											icon={faPercent}
											component={renderInputWithIcon}
										/>
									</Col>
								</Row>
								<Row className="mb-3">
									<Col lg={4} md={12}>
										<Field name="high_risk" label={t('high-risk')} inputType="number" component={renderInput} />
									</Col>
									<Col lg={4} md={12}>
										<Field name="medium_risk" label={t('medium-risk')} inputType="number" component={renderInput} />
									</Col>

									<Col lg={4} md={12}>
										<Field name="low_risk" label={t('low-risk')} inputType="number" component={renderInput} />
									</Col>
								</Row>
								<Row className="mb-3">
									<Field name="issue" label={t('risks-and-issues')} rows={5} component={renderTextArea} />
								</Row>

								<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
									<Link
										to={Routes.Finance.Project.Details.path.replace(':id', datas.project.project_id)}
										className="btn btn-secondary">
										{t('back')}{' '}
									</Link>
									<SubmitButton invalid={false} onSubmit={onSubmit} text={t('submit')} />
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</form>
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		auth: { full_name, user_id, companies },
	} = state;
	return {
		full_name,
		user_id,
		companies,
	};
};

export default compose(
	connect(mapStateToProps, { initialize }),
	reduxForm({ form: 'project_milestone' })
)(ProjectMilestoneForm);
