import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Card, Col, Row } from '@themesberg/react-bootstrap';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { change, Field, formValueSelector, initialize, reduxForm } from 'redux-form';
import { clearData } from '../../../../actions/globalActions';
import renderDropdown from '../../../../components/formComponents/renderDropdown';
import renderDropdownWithAutoComplete from '../../../../components/formComponents/renderDropdownWithAutoComplete';
import renderInput from '../../../../components/formComponents/renderInput';
import SubmitButton from '../../../../components/formComponents/SubmitButton';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { Routes } from '../../../../routes';
import EmployeeEvaluationRankingTable from './EmployeeEvaluationRankingTable';

const selector = formValueSelector('hrEvaluation');

const EmployeeEvaluationForm = (props) => {
	const { user, initials, datas, selectedEmployeeId } = props;
	const { t } = useTranslation();

	useEffect(() => {
		{
			initials != null
				? props.initialize({
						...initials,
						nationality: initials.employee.nationality,
						employee_number: initials.employee.employee_number,
						job_title: initials.employee.job_title,
						department: _.find(datas.employees, { employee_id: initials.employee_id }).department.department_name,
						date: initials.date.split('T')[0] || '',
						by: user.first_name + ' ' + user.last_name,
				  })
				: props.initialize({
						date: new Date().toISOString().split('T')[0],
						by: user.first_name + ' ' + user.last_name,
				  });
		}

		props.change('user_id', user.user_id);

		return () => {
			props.clearData();
		};
	}, []);

	const renderCompanies = () => {
		return user.companies.map((company, index) => {
			return (
				<option key={index} value={company.company_id}>
					{company.company_name}
				</option>
			);
		});
	};

	return (
		<>
			{0 ? (
				<LoadingSpinner />
			) : (
				<>
					<div className="d-block mb-4 mb-xl-0 ">
						<Breadcrumb
							className=" d-block"
							listProps={{
								className: 'breadcrumb-dark breadcrumb-transparent',
							}}>
							<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
								<FontAwesomeIcon icon={faHome} />
							</Breadcrumb.Item>
							<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.HumanResources.Overview.path }}>
								{t('human-resources')}{' '}
							</Breadcrumb.Item>

							<Breadcrumb.Item active>{t('employee-evaluation')}</Breadcrumb.Item>
						</Breadcrumb>
					</div>
					<form onSubmit={props.handleSubmit(props.onSubmit)}>
						<Row>
							<Col md={12}>
								<Card border="light" className="bg-white shadow-sm mb-4  w-100">
									<Card.Body>
										<h5 className="mb-4">{t('human-resources-employee-evaluation-form')} </h5>
										<Row className="mb-3">
											<Col md={6} sm={12}>
												<Field name="by" disabled="disabled" label={t('by')} component={renderInput} />
											</Col>
											<Col md={6} sm={12}>
												<Field
													name="company_id"
													label={t('company')}
													component={renderDropdown}
													renderedOptions={renderCompanies()}
												/>
											</Col>
										</Row>
										<hr />
										<Row className="mb-3">
											<Col md={6} sm={12}>
												<Field
													onChange={(val) => {
														const selectedEmployee = _.find(datas.employees, { employee_id: val });

														props.change('employee_number', selectedEmployee.employee_number);
														props.change('nationality', selectedEmployee.nationality);
														props.change('job_title', selectedEmployee.job_title);
														props.change('department', selectedEmployee.department.department_name);
														props.change(
															'reference',
															`HR/002/${String(selectedEmployee.employee_number).padStart(3, '0')}/${
																new Date().getMonth() + 1
															}${new Date().getDate()}${new Date().getMilliseconds()}`
														);
													}}
													name="employee_id"
													label={t('employee')}
													dataKey={'employee_id'}
													textField="full_name"
													data={datas.employees}
													component={renderDropdownWithAutoComplete}
												/>
											</Col>
											<Col md={6} sm={12}>
												<Field inputType="date" name="date" label={t('date-0')} component={renderInput} />
											</Col>
										</Row>
										<Row className="mb-3">
											<Col md={6} sm={12}>
												<Field
													disabled="disabled"
													inputType="number"
													name="employee_number"
													label={t('employee-number')}
													component={renderInput}
												/>
											</Col>
											<Col md={6} sm={12}>
												<Field
													disabled="disabled"
													name="nationality"
													label={t('nationality')}
													component={renderInput}
												/>
											</Col>
										</Row>
										<Row className="mb-3">
											<Col md={6} sm={12}>
												<Field
													disabled="disabled"
													name="department"
													label={t('department-0')}
													component={renderInput}
												/>
											</Col>
											<Col md={6} sm={12}>
												<Field disabled="disabled" name="job_title" label={t('job-title')} component={renderInput} />
											</Col>
										</Row>
										<Row className="mb-3">
											<Col md={6} sm={12}>
												<Field disabled="disabled" name="reference" label={t('reference')} component={renderInput} />
											</Col>
										</Row>

										<EmployeeEvaluationRankingTable />

										<Row className="mb-3">
											<Col md={6} sm={12}>
												<Field
													name="current_salary"
													inputType="number"
													label={t('current-salary')}
													component={renderInput}
												/>
											</Col>
											<Col md={6} sm={12}>
												<Field
													name="number_of_warning_letters"
													label={t('number-of-warning-letters')}
													inputType="number"
													component={renderInput}
												/>
											</Col>
										</Row>
										<Row className="mb-3">
											<Col md={6} sm={12}>
												<Field name="recommendation" label={t('recommendation')} component={renderInput} />
											</Col>
											<Col md={6} sm={12}>
												<Field
													name="total_salary"
													inputType="number"
													label={t('total-salary-after-increment')}
													component={renderInput}
												/>
											</Col>
										</Row>
										<Row className="mb-3">
											<Col md={6} sm={12}>
												<Field name="supervisor" label={t('supervisor')} component={renderInput} />
											</Col>
											<Col md={6} sm={12}>
												<Field name="hr_manager" label={t('hr-manager')} component={renderInput} />
											</Col>
										</Row>
										<Row className="mb-3">
											<Col md={6} sm={12}>
												<Field name="project_manager" label={t('project-manager')} component={renderInput} />
											</Col>
											<Col md={6} sm={12}>
												<Field name="general_manager" label={t('general-manager')} component={renderInput} />
											</Col>
										</Row>

										<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
											<Link to={Routes.HumanResources.EmployeeEvaluation.Index.path} className="btn btn-secondary">
												{t('back')}{' '}
											</Link>
											<SubmitButton invalid={false} onSubmit={props.onSubmit} text={t('submit')} />
										</div>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</form>
				</>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const selectedEmployeeId = selector(state, 'employee_id');

	return {
		user: state.auth,
		selectedEmployeeId,
	};
};

export default compose(
	connect(mapStateToProps, { initialize, clearData, change }),
	reduxForm({ form: 'hrEvaluation' })
)(EmployeeEvaluationForm);
