import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import { useEffect, useState } from 'react';
import CashAdvanceRequestForm from './CashAdvanceRequestForm';
import { Link } from 'react-router-dom';
import { Routes } from '../../../../routes';
import { faHome, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import IndexTable from '../../../../components/IndexTable';
import { connect } from 'react-redux';
import {
	getCashAdvanceRequests,
	deleteCashAdvanceRequest,
} from '../../../../actions/HumanResources/cashAdvanceRequestActions';
import onDelete from '../../../../helpers/deleteFunction';
import { useTranslation } from 'react-i18next';

const CashAdvanceRequestIndex = (props) => {
	const { t } = useTranslation();

	const { datas, loading } = props;
	const [deleted, setDeleted] = useState(false);
	useEffect(() => {
		props.getCashAdvanceRequests();
	}, [deleted]);
	const renderRows = () => {
		return datas.cashAdvanceRequests.map((cashAdvanceRequest, index) => {
			return (
				<tr key={index}>
					<td className="fw-bold text-center">{cashAdvanceRequest.reference}</td>
					<td className="fw-bold text-center">{cashAdvanceRequest.employee.full_name}</td>
					<td className="fw-bold text-center">{cashAdvanceRequest.employee.job_title}</td>
					<td className="fw-bold text-center">{cashAdvanceRequest.decision}</td>
					<td className="fw-bold text-center">
						{new Date(cashAdvanceRequest.employee.start_date).toLocaleDateString()}
					</td>
					<td className="fw-bold text-center">{cashAdvanceRequest.total_amount}</td>
					<td className="fw-bold text-center">{cashAdvanceRequest.status}</td>
					<td className="fw-bold text-center">
						{cashAdvanceRequest.status == t('active-0') ? (
							<>
								<button
									onClick={() => {
										onDelete(deleted, setDeleted, props.deleteCashAdvanceRequest, cashAdvanceRequest.id);
									}}
									className="btn btn-danger mx-2">
									<FontAwesomeIcon icon={faTrash} />
								</button>
								<Link
									to={Routes.HumanResources.CashAdvanceRequest.Edit.path.replace(':id', cashAdvanceRequest.id)}
									className="btn btn-primary mx-2">
									<FontAwesomeIcon icon={faEdit} />
								</Link>
							</>
						) : null}
					</td>
				</tr>
			);
		});
	};
	return (
		<>
			<div className="py-4">
				<div className="d-block mb-4 mb-xl-0">
					<Breadcrumb
						className="d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.HumanResources.Overview.path }}>
							{t('human-resources')}{' '}
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{t('cash-advance-request')} </Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-5">
					<h4 style={{ display: 'inline' }}>{t('cash-advance-request-0')}</h4>
					<Link className="btn btn-primary py-2 px-3" to={Routes.HumanResources.CashAdvanceRequest.Create.path}>
						{t('add-new')}{' '}
					</Link>
				</div>
				<p className="mb-0">{t('your-most-recent-cash-advance-request-forms-can-be-seen-here')}</p>
			</div>
			<IndexTable
				loading={false}
				headerProps="text-center"
				headers={[
					t('reference'),
					t('employee'),
					t('job-title'),
					'decision',
					t('joining-date'),
					t('total-amount'),
					t('status'),
					'',
				]}
				renderRows={datas.cashAdvanceRequests.length != 0 ? renderRows() : null}
			/>
		</>
	);
};
const mapStateToProps = (state) => {
	const {
		humanResources: {
			cashAdvanceRequest: { cashAdvanceRequests, getCashAdvanceRequestsLoading },
		},
	} = state;
	return {
		datas: { cashAdvanceRequests },
		loading: getCashAdvanceRequestsLoading,
	};
};
export default connect(mapStateToProps, { getCashAdvanceRequests, deleteCashAdvanceRequest })(CashAdvanceRequestIndex);
