import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faShoppingCart, faCogs, faCog } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb, Col, Nav, Row, Button, Card } from '@themesberg/react-bootstrap';

import { Field, reduxForm, initialize } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import onDelete from '../../../../helpers/deleteFunction';
import { clearData } from '../../../../actions/globalActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import SubmitButton from '../../../../components/formComponents/SubmitButton';
import validate from '../../../../validate';
import history from '../../../../history';
import { Routes } from '../../../../routes';
import IndexTable from '../../../../components/IndexTable';
import { getOrders } from '../../../../actions/Delivery/orderActions';
import { indexOf } from 'lodash';
import { useTranslation } from 'react-i18next';

const OrderIndex = (props) => {
	const { datas, loading } = props;
	const { t } = useTranslation();

	const { orders } = datas;
	useEffect(() => {
		props.getOrders();
	}, []);

	const renderRows = () => {
		return orders.map((order, index) => {
			var detailString = '';
			order.order_details.map((item) => (detailString += `${item.item_name} : ${item.item_count}, `));
			var orderDate = new Date(order.createdAt);
			var date_format_str =
				orderDate.getFullYear().toString() +
				'-' +
				((orderDate.getMonth() + 1).toString().length == 2
					? (orderDate.getMonth() + 1).toString()
					: '0' + (orderDate.getMonth() + 1).toString()) +
				'-' +
				(orderDate.getDate().toString().length == 2
					? orderDate.getDate().toString()
					: '0' + orderDate.getDate().toString()) +
				' ' +
				(orderDate.getHours().toString().length == 2
					? orderDate.getHours().toString()
					: '0' + orderDate.getHours().toString()) +
				':' +
				((parseInt(orderDate.getMinutes() / 5) * 5).toString().length == 2
					? (parseInt(orderDate.getMinutes() / 5) * 5).toString()
					: '0' + (parseInt(orderDate.getMinutes() / 5) * 5).toString()) +
				':00';

			return (
				<tr
					onClick={() => {
						history.push(Routes.Delivery.Order.Detail.path.replace(':id', order.order_id));
					}}
					key={index}>
					<td className="fw-bold text-center">{index + 1}</td>
					<td className="fw-bold text-center">{order.user.full_name}</td>
					<td className="fw-bold text-center">
						{detailString.length >= 50 ? detailString.slice(0, 50) + '...' : detailString}
					</td>
					<td className="fw-bold text-center">{order.status}</td>
					<td className="fw-bold text-center">{date_format_str}</td>
				</tr>
			);
		});
	};

	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{t('orders')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>

				<br />
				<div
					className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center 
          ">
					<h4 style={{ display: 'inline' }}>{t('orders-0')}</h4>
				</div>
			</div>

			<IndexTable
				headerProps="text-center"
				headers={['#', t('full-name'), t('orders-1'), t('status'), t('order-date')]}
				renderRows={orders.length != 0 ? renderRows() : null}
				navigationButtons={
					<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-4">
						<Link to={Routes.Delivery.Index.path} className="btn btn-secondary">
							{t('back')}{' '}
						</Link>
					</div>
				}
			/>
		</>
	);
};
const mapStateToProps = (state) => {
	const {
		delivery: {
			order: { orders, getOrdersLoading },
		},
	} = state;

	return { datas: { orders }, loading: getOrdersLoading };
};

export default connect(mapStateToProps, {
	getOrders,
})(OrderIndex);
