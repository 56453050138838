import { Col, Row } from '@themesberg/react-bootstrap';
import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, Field, reduxForm, initialize } from 'redux-form';
import renderInput from '../../../../components/formComponents/renderInput';
import SubmitButton from '../../../../components/formComponents/SubmitButton';
import validate from '../../../../validate';

const RegistrationForm = (props) => {
	const { mail } = props;

	const { t } = useTranslation();
	useEffect(() => {
		props.initialize({ email: mail });
	}, [mail]);

	return (
		<form onSubmit={props.handleSubmit(props.onSubmit)} className="mt-4 text-start text-md-start ">
			<Row>
				<Col md={6} xs={12}>
					<Field className="mb-3 " name="firstName" label={t('first-name-0')} component={renderInput} />
				</Col>
				<Col md={6} xs={12}>
					<Field className="mb-3 " name="lastName" label={t('last-name-0')} component={renderInput} />
				</Col>
			</Row>
			<Row>
				<Col>
					<Field className="mb-3 col-12" name="email" label="E-mail" disabled="disabled" component={renderInput} />
				</Col>
			</Row>
			<Row>
				<Col>
					<Field className="mb-3 col-12" name="token" label={t('token')} inputType="number" component={renderInput} />
				</Col>
			</Row>
			<Row>
				<Col></Col>
				<Col md={6} sm={6} xs={12}>
					<SubmitButton
						className=" w-100 btn btn-primary mb-3"
						onSubmit={props.onSubmit}
						invalid={true}
						text={t('sign-up')}
					/>
				</Col>
			</Row>
		</form>
	);
};

const mapStateToProps = (state, ownProps) => {
	return { mail: state.auth.mail };
};

export default compose(
	connect(mapStateToProps, { initialize }),
	reduxForm({
		form: 'registration',
		validate,
	})
)(RegistrationForm);
