import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Nav } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { deleteItem, getItems } from '../../../../../actions/Delivery/deliveryActions';
import { clearData } from '../../../../../actions/globalActions';
import IndexTable from '../../../../../components/IndexTable';
import onDelete from '../../../../../helpers/deleteFunction';
import history from '../../../../../history';
import { Routes } from '../../../../../routes';

const DeliveryPanelIndex = (props) => {
	const {
		item: { items },
		loading,
	} = props;
	const [deleted, setDeleted] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		props.getItems();

		return () => {
			props.clearData();
		};
	}, [deleted]);

	const renderRows = () => {
		return items.map((value, index) => {
			return (
				<tr key={index}>
					<td className="fw-bold text-center">{index + 1}</td>
					<td className="fw-bold text-center">{value.item_name}</td>
					<td className="fw-bold text-center">{value.description}</td>
					<td className="fw-bold text-center">{value.delivery_item_category?.category_name}</td>
					<td className="fw-bold text-center">
						<button
							onClick={() => {
								onDelete(deleted, setDeleted, props.deleteItem, value.item_id);
							}}
							className="btn btn-danger mx-2">
							<i className="fa fa-trash  "></i>
						</button>
						<Link
							to={Routes.Delivery.Panel.Item.ItemEdit.path.replace(':id', value.item_id)}
							className="btn btn-primary mx-2">
							<i className="fa fa-edit"></i>
						</Link>
					</td>
				</tr>
			);
		});
	};

	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{t('delivery-panel')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<Nav className="justify-content-center row" variant="tabs" defaultActiveKey={window?.location.href}>
					<Nav.Item
						className="bg-primary col text-center "
						style={{
							borderTopLeftRadius: '0.5rem',
							borderTopRightRadius: '0.5rem',
						}}>
						<Nav.Link className=" text-white">{t('item')}</Nav.Link>
					</Nav.Item>

					<Nav.Item className="col text-center ">
						<Nav.Link
							onClick={() => {
								history.push(Routes.Delivery.Panel.Category.Index.path);
							}}>
							{t('category')}{' '}
						</Nav.Link>
					</Nav.Item>

					<Nav.Item className="col text-center ">
						<Nav.Link
							onClick={() => {
								history.push(Routes.Delivery.Panel.Location.Index.path);
							}}>
							{t('location')}{' '}
						</Nav.Link>
					</Nav.Item>
				</Nav>
				<br />
				<div
					className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center 
          ">
					<h4 style={{ display: 'inline' }}>{t('items')}</h4>
					<div>
						<Link className="btn btn-primary py-2 px-3 " to={Routes.Delivery.Panel.Item.ItemCreate.path}>
							{t('add-new')}{' '}
						</Link>
					</div>
				</div>
			</div>

			<IndexTable
				loading={loading}
				headerProps="text-center"
				headers={['#', t('item-name'), t('description'), t('category'), '']}
				renderRows={items.length != 0 ? renderRows() : null}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		item: state.delivery.item,
		loading: state.delivery.item.getItemsLoading,
		categories: state.delivery.item.categories,
	};
};

export default connect(mapStateToProps, {
	getItems,
	deleteItem,
	clearData,
})(DeliveryPanelIndex);
