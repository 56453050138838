import React, { useEffect } from 'react';
import { Field } from 'redux-form';

import renderInput from '../../../../../components/formComponents/renderInput';

export default ({ fields, meta, inputNames, types, totals, showAddRow }) => {
	if (fields.length < 1) {
		useEffect(() => {
			fields.push({});
		}, []);
	}

	const renderFields = (inputNames, parentObjName) => {
		return inputNames.map((input, index) => {
			return (
				<td colSpan={input.col} key={index}>
					<Field
						disabled={input.disabled}
						inputType={types[index]}
						name={`${parentObjName}.${input.name}`}
						component={renderInput}
					/>
				</td>
			);
		});
	};

	return (
		<>
			{fields.map((parentObjName, index) => {
				return <tr key={index}>{renderFields(inputNames, parentObjName)}</tr>;
			})}

			{totals ? (
				<tr>
					<td className="p-3" style={{ borderBottom: 0 }}>
						<h5 className="m-0">Total: </h5>
					</td>
					{totals.map((total, index) => {
						return (
							<td key={index} className="p-3" style={{ borderBottom: 0 }}>
								<h5 className="m-0">{total}</h5>
							</td>
						);
					})}
				</tr>
			) : null}
		</>
	);
};
