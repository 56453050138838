import {
	CREATE_PROJECT_BUILDING,
	DELETE_PROJECT_BUILDING,
	GET_PROJECT_BUILDING,
	GET_PROJECT_BUILDINGS,
} from '../../actions/Construction/types';
import { CLEAR_DATA } from '../../actions/types';

const INITIAL_STATE = {
	projectBuilding: {},
	projectBuildings: [],
	getProjectBuildingLoading: true,
	getProjectBuildingsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_PROJECT_BUILDINGS:
			return { ...state, projectBuildings: action.payload, getProjectBuildingsLoading: false };
		case GET_PROJECT_BUILDING:
			return { ...state, projectBuilding: action.payload, getProjectBuildingLoading: false };
		case CREATE_PROJECT_BUILDING:
			return { ...state };
		case CLEAR_DATA:
			return {
				...state,
				projectBuildings: [],
				getProjectBuildingLoading: true,
				getProjectBuildingsLoading: true,
			};
		case DELETE_PROJECT_BUILDING:
			return { ...state };

		default:
			return state;
	}
};
