import { Policies } from './helpers/policies';

export const Routes = {
	// --------------WELCOME & DASHBOARD--------------

	Welcome: { path: '/welcome' },
	Report: { path: '/report' },
	DashboardOverview: { path: '/dashboard' },
	Overview: { path: '/' },
	Settings: { path: '/settings' },

	// --------------TRANSPORTATION--------------

	Transportation: {},

	// --------------PROCUREMENT--------------

	Workflow: {
		Index: {
			path: '/workflow',
			policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
		},
		Create: {
			path: '/workflow/create',
			policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
		},
		Edit: {
			path: '/workflow/edit/:id',
			policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
		},
	},

	CashFlow: {
		Index: {
			path: '/cashflow',
			policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
		},
		Create: {
			path: '/cashflow/create',
			policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
		},
		Edit: {
			path: '/cashflow/edit/:id',
			policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
		},
		Details: {
			path: '/cashflow/details/:id',
			policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
		},
	},

	Construction: {
		Project: {
			Index: {
				path: '/construction-projects',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
			},
			Create: {
				path: '/construction-projects/create',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
			},
			Edit: {
				path: '/construction-projects/edit/:id',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
			},
			Details: {
				path: '/construction-projects/details/:id',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
			},
		},
		ProjectBuilding: {
			Create: {
				path: '/construction-project-building/create/:project_id',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
			},
			Edit: {
				path: '/construction-project-building/edit/:id',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
			},
		},
		PunchList: {
			Index: {
				path: '/punch-list',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
			},
			Create: {
				path: '/punch-list/create',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
			},
			Edit: {
				path: '/punch-list/edit/:id',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
			},
			Details: {
				path: '/punch-list/details/:id',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
			},
		},
		Discipline: {
			Index: {
				path: '/discipline',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
			},
			Create: {
				path: '/discipline/create',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
			},
			Edit: {
				path: '/discipline/edit/:id',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
			},
		},
		Locations: {
			Index: {
				path: '/construction/locations',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
			},
			Create: {
				path: '/construction/locations/create',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
			},
			Edit: {
				path: '/construction/locations/edit/:id',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
			},
			Details: {
				path: '/construction/locations/details/:id',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
			},
		},
	},

	Procurement: {
		LPO: {
			Index: {
				path: '/local-purchase-order',
				policy: { page: Policies.LPO.PAGE, action: Policies.LPO.VIEW },
			},
			Create: {
				path: '/local-purchase-order/create',
				policy: { page: Policies.LPO.PAGE, action: Policies.LPO.ADD },
			},
			Edit: {
				path: '/local-purchase-order/edit/:id',
				policy: { page: Policies.LPO.PAGE, action: Policies.LPO.EDIT },
			},
			Detail: {
				path: '/local-purchase-order/details/:id',
				policy: { page: Policies.LPO.PAGE, action: Policies.LPO.VIEW },
			},
			Delete: {
				policy: { page: Policies.LPO.PAGE, action: Policies.LPO.DELETE },
			},
		},
		Quotation: {
			Index: {
				path: '/quotation',
				policy: { page: Policies.LPO.PAGE, action: Policies.LPO.VIEW },
			},
			Create: {
				path: '/quotation/create/:isrId',
				policy: { page: Policies.LPO.PAGE, action: Policies.LPO.ADD },
			},
			Edit: {
				path: '/quotation/edit/:id',
				policy: { page: Policies.LPO.PAGE, action: Policies.LPO.EDIT },
			},
			Detail: {
				path: '/quotation/details/:id',
				policy: { page: Policies.LPO.PAGE, action: Policies.LPO.VIEW },
			},
			Delete: {
				policy: { page: Policies.LPO.PAGE, action: Policies.LPO.DELETE },
			},
		},
		ISR: {
			Index: {
				path: '/internal-service-request',
				policy: { page: Policies.ISR.PAGE, action: Policies.ISR.VIEW },
			},
			Create: {
				path: '/internal-service-request/create',
				policy: { page: Policies.ISR.PAGE, action: Policies.ISR.ADD },
			},
			Detail: {
				path: '/internal-service-request/details/:id',
				policy: { page: Policies.ISR.PAGE, action: Policies.ISR.VIEW },
			},
			Edit: {
				path: '/internal-service-request/edit/:id',
				policy: { page: Policies.ISR.PAGE, action: Policies.ISR.EDIT },
			},
			Delete: {
				policy: { page: Policies.ISR.PAGE, action: Policies.ISR.DELETE },
			},
			Checklist: {
				path: '/internal-service-request/checklist/:id',
				policy: { page: Policies.ISR.PAGE, action: Policies.ISR.VIEW },
			},
		},
		Supplier: {
			Index: {
				path: '/suppliers',
				policy: { page: Policies.SUPPLIER.PAGE, action: Policies.SUPPLIER.VIEW },
			},
			Create: {
				path: '/suppliers/create',
				policy: { page: Policies.SUPPLIER.PAGE, action: Policies.SUPPLIER.ADD },
			},
			Edit: {
				path: '/suppliers/edit/:id',
				policy: { page: Policies.SUPPLIER.PAGE, action: Policies.SUPPLIER.EDIT },
			},
			Delete: {
				policy: { page: Policies.SUPPLIER.PAGE, action: Policies.SUPPLIER.DELETE },
			},
			Detail: {
				path: '/suppliers/details/:id',
				policy: { page: Policies.SUPPLIER.PAGE, action: Policies.SUPPLIER.VIEW },
			},
		},
		SupplierEvaluation: {
			Index: {
				path: '/supplier-evaluation',
				policy: { page: Policies.SUPPLIER.PAGE, action: Policies.SUPPLIER.VIEW },
			},
			Edit: {
				path: '/supplier-evaluation/edit/:id',
				policy: { page: Policies.SUPPLIER.PAGE, action: Policies.SUPPLIER.EDIT },
			},
			Create: {
				path: '/supplier-evaluation/create',
				policy: { page: Policies.SUPPLIER.PAGE, action: Policies.SUPPLIER.ADD },
			},
		},
		Item: {
			Index: {
				path: '/products',
				// policy: { page: Policies.SUPPLIER.PAGE, action: Policies.SUPPLIER.VIEW },
			},
			Edit: {
				path: '/products/edit/:id',
				// policy: { page: Policies.SUPPLIER.PAGE, action: Policies.SUPPLIER.VIEW },
			},
			Create: {
				path: '/products/create',
				// policy: { page: Policies.SUPPLIER.PAGE, action: Policies.SUPPLIER.VIEW },
			},
		},
	},
	// --------------INSIGHTS-------------
	Insights: {
		Index: {
			path: '/insights',
		},
		ProcurementOverview: {
			path: '/insights/procurement-overview',
			policy: {
				page: Policies.SUPPLIER.PAGE,
				action: Policies.SUPPLIER.VIEW,
			},
		},
		FinanceOverview: {
			path: '/insights/finance-overview',
			policy: { page: Policies.FINANCE.PAGE, action: Policies.FINANCE.VIEW },
		},
		HumanResourcesOverview: {
			path: '/insights/human-resources-overview',
			policy: {
				page: Policies.HUMAN_RESOURCES.PAGE,
				action: Policies.HUMAN_RESOURCES.VIEW,
			},
		},
		ProjectManagementOverview: {
			path: '/insights/project-management-overivew',
			policy: { page: Policies.PROJECT_MANAGEMENT.PAGE, action: Policies.PROJECT_MANAGEMENT.VIEW },
		},
		TransportationOverview: {
			path: '/insights/transportation-overview',
			policy: {
				page: Policies.TRANSPORTATION.PAGE,
				action: Policies.TRANSPORTATION.VIEW,
			},
		},
		PunchListOverview: {
			path: '/insights/punch-list-overview',
			policy: {
				page: Policies.TRANSPORTATION.PAGE,
				action: Policies.TRANSPORTATION.VIEW,
			},
		},
	},
	// --------------FINANCE--------------

	Finance: {
		Project: {
			Index: {
				path: '/Projects',
				policy: { page: Policies.FINANCE.PAGE, action: Policies.FINANCE.VIEW },
			},
			Details: {
				path: '/Projects/details/:id',
				policy: { page: Policies.FINANCE.PAGE, action: Policies.FINANCE.VIEW },
			},
			Create: {
				path: '/Projects/create',
				policy: { page: Policies.FINANCE.PAGE, action: Policies.FINANCE.ADD },
			},
			Edit: {
				path: '/Projects/edit/:id',
				policy: { page: Policies.FINANCE.PAGE, action: Policies.FINANCE.EDIT },
			},
			Delete: {
				policy: { page: Policies.FINANCE.PAGE, action: Policies.FINANCE.DELETE },
			},
		},
		ProjectMilestone: {
			Create: {
				path: '/project-milestone/create/:project_id',
				policy: { page: Policies.FINANCE.PAGE, action: Policies.FINANCE.ADD },
			},
			Edit: {
				path: '/project-milestone/edit/:id',
				policy: { page: Policies.FINANCE.PAGE, action: Policies.FINANCE.EDIT },
			},
			Delete: {
				policy: { page: Policies.FINANCE.PAGE, action: Policies.FINANCE.DELETE },
			},
		},
	},

	// -------------MAN RESOURCES--------------

	HumanResources: {
		Overview: {
			path: '/human-resources',
			policy: {
				page: Policies.HUMAN_RESOURCES.PAGE,
				action: Policies.HUMAN_RESOURCES.VIEW,
			},
		},
		Report: {
			path: '/human-resources',
			policy: {
				page: Policies.HUMAN_RESOURCES.PAGE,
				action: Policies.HUMAN_RESOURCES.VIEW,
			},
		},
		EmployeeEvaluation: {
			Index: {
				path: '/human-resources-evaluation/',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.VIEW },
			},
			Create: {
				path: '/human-resources-evaluation/create',
				policy: {
					page: Policies.HUMAN_RESOURCES.PAGE,
					action: Policies.HUMAN_RESOURCES.ADD,
				},
			},
			Edit: {
				path: '/human-resources-evaluation/edit/:id',
				policy: {
					page: Policies.HUMAN_RESOURCES.PAGE,
					action: Policies.HUMAN_RESOURCES.EDIT,
				},
			},
		},
		Employee: {
			Index: {
				path: '/human-resources-employee',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.VIEW },
			},
			Create: {
				path: '/human-resources-employee/create',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.ADD },
			},
			Edit: {
				path: '/human-resources-employee/edit/:id',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.EDIT },
			},
			Delete: {
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.DELETE },
			},
		},
		SalaryDeduction: {
			Index: {
				path: '/salary-deduction',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.VIEW },
			},
			Create: {
				path: '/salary-deduction/create',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.ADD },
			},
			Edit: {
				path: '/salary-deduction/edit/:id',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.EDIT },
			},
			Delete: {
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.DELETE },
			},
		},
		SalaryIncrement: {
			Index: {
				path: '/salary-increment',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.VIEW },
			},
			Create: {
				path: '/salary-increment/create',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.ADD },
			},
			Edit: {
				path: '/salary-increment/edit/:id',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.EDIT },
			},
			Delete: {
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.DELETE },
			},
		},
		StartWork: {
			Index: {
				path: '/start-work',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.VIEW },
			},
			Create: {
				path: '/start-work/create',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.ADD },
			},
			Edit: {
				path: '/start-work/edit/:id',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.EDIT },
			},
			Delete: {
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.DELETE },
			},
		},
		WarningLetter: {
			Index: {
				path: '/warning-letter',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.VIEW },
			},
			Create: {
				path: '/warning-letter/create',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.ADD },
			},
			Edit: {
				path: '/warning-letter/edit/:id',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.EDIT },
			},
			Delete: {
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.DELETE },
			},
		},
		Clearance: {
			Index: {
				path: '/clearance',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.VIEW },
			},
			Create: {
				path: '/clearance/create',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.ADD },
			},
			Edit: {
				path: '/clearance/edit/:id',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.EDIT },
			},
			Delete: {
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.DELETE },
			},
		},
		Leave: {
			Index: {
				path: '/leave',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.VIEW },
			},
			Create: {
				path: '/leave/create',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.ADD },
			},
			Edit: {
				path: '/leave/edit/:id',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.EDIT },
			},
			Delete: {
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.DELETE },
			},
		},
		CashAdvanceRequest: {
			Index: {
				path: '/cash-advance',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.VIEW },
			},
			Create: {
				path: '/cash-advance/create',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.ADD },
			},
			Edit: {
				path: '/cash-advance/edit/:id',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.EDIT },
			},
			Delete: {
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.DELETE },
			},
		},
		Termination: {
			Index: {
				path: '/termination',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.VIEW },
			},
			Create: {
				path: '/termination/create',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.ADD },
			},
			Edit: {
				path: '/termination/edit/:id',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.EDIT },
			},
			Delete: {
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.DELETE },
			},
		},
		Resignation: {
			Index: {
				path: '/resignation',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.VIEW },
			},
			Create: {
				path: '/resignation/create',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.ADD },
			},
			Edit: {
				path: '/resignation/edit/:id',
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.EDIT },
			},
			Delete: {
				policy: { page: Policies.HUMAN_RESOURCES.PAGE, action: Policies.HUMAN_RESOURCES.DELETE },
			},
		},
	},

	// --------------PROJECT MANAGEMENT--------------

	ProjectMangement: {},

	// --------------ADMINISTRATION--------------

	Admin: {
		TicketCategory: {
			Index: {
				path: '/ticket-categories',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
			},
			Create: {
				path: '/ticket-categories/create',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
			},
			Edit: {
				path: '/ticket-categories/edit/:id',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
			},
			Delete: {
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
			},
		},
		Department: {
			Index: {
				path: '/departments',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
			},
			Create: {
				path: '/departments/create',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.ADD },
			},
			Edit: {
				path: '/departments/edit/:id',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.EDIT },
			},
			Delete: {
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.DELETE },
			},
		},
		User: {
			Index: {
				path: '/users',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW },
			},
			Edit: {
				path: '/users/edit/:id',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.EDIT },
			},
			Delete: {
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.DELETE },
			},
		},
		Invite: {
			Index: {
				path: '/invites',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.VIEW }, // TO DO: INVITE CHECKBOX
			},
			Create: {
				path: '/invites/create',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.ADD }, // TO DO: INVITE CHECKBOX
			},
			Edit: {
				path: '/invites/edit/:id',
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.EDIT }, // TO DO: INVITE CHECKBOX
			},
			Delete: {
				policy: { page: Policies.ADMIN.PAGE, action: Policies.ADMIN.DELETE }, // TO DO: INVITE CHECKBOX
			},
		},
		Entity: {
			Index: {
				path: '/entities',
				policy: {
					page: Policies.ADMIN.PAGE,
					action: Policies.ADMIN.VIEW,
				},
			},
			Create: {
				path: '/entities/create',
				policy: {
					page: Policies.ADMIN.PAGE,
					action: Policies.ADMIN.ADD,
				},
			},
			Edit: {
				path: '/entities/edit/:id',
				policy: {
					page: Policies.ADMIN.PAGE,
					action: Policies.ADMIN.EDIT,
				},
			},
			Delete: {
				policy: {
					page: Policies.ADMIN.PAGE,
					action: Policies.ADMIN.DELETE,
				},
			},
		},
	},

	// --------------DELIVERY--------------

	Delivery: {
		Index: {
			path: '/delivery',
			policy: {
				page: Policies.DELIVERY.PAGE,
				action: Policies.DELIVERY.VIEW,
			},
		},
		Order: {
			Index: {
				path: '/delivery/orders',
			},
			Detail: {
				path: '/delivery/detail/:id',
			},
			Create: {},
			Edit: {},
		},
		Checkout: {
			path: '/delivery/checkout',
			policy: {
				page: Policies.DELIVERY.PAGE,
				action: Policies.DELIVERY.VIEW,
			},
		},
		Panel: {
			Item: {
				ItemIndex: {
					path: '/delivery/panel/item',
					policy: {
						page: Policies.DELIVERY.PAGE,
						action: Policies.DELIVERY.VIEW,
					},
				},
				ItemCreate: {
					path: '/delivery/panel/item/create',
					policy: {
						page: Policies.DELIVERY.PAGE,
						action: Policies.DELIVERY.VIEW,
					},
				},
				ItemEdit: {
					path: '/delivery/panel/item/edit/:id',
					policy: {
						page: Policies.DELIVERY.PAGE,
						action: Policies.DELIVERY.VIEW,
					},
				},
			},
			Category: {
				Index: {
					path: '/delivery/panel/category',
					policy: {
						page: Policies.DELIVERY.PAGE,
						action: Policies.DELIVERY.VIEW,
					},
				},
				Create: {
					path: '/delivery/panel/category/create',
					policy: {
						page: Policies.DELIVERY.PAGE,
						action: Policies.DELIVERY.VIEW,
					},
				},
				Edit: {
					path: '/delivery/panel/category/edit/:id',
					policy: {
						page: Policies.DELIVERY.PAGE,
						action: Policies.DELIVERY.VIEW,
					},
				},
			},
			Menu: {
				MenuIndex: {
					path: '/delivery/panel/menu',
					policy: {
						page: Policies.DELIVERY.PAGE,
						action: Policies.DELIVERY.VIEW,
					},
				},
			},
			Location: {
				Index: {
					path: '/delivery/panel/location',
					policy: {
						page: Policies.DELIVERY.PAGE,
						action: Policies.DELIVERY.VIEW,
					},
				},
				Create: {
					path: '/delivery/panel/location/create',
					policy: {
						page: Policies.DELIVERY.PAGE,
						action: Policies.DELIVERY.VIEW,
					},
				},
				Edit: {
					path: '/delivery/panel/location/edit/:id',
					policy: {
						page: Policies.DELIVERY.PAGE,
						action: Policies.DELIVERY.VIEW,
					},
				},
			},
		},
	},

	IT_Department: {
		// TICKET
		Ticket: {
			Index: {
				policy: { page: Policies.IT_DEPARTMENT.PAGE, action: Policies.IT_DEPARTMENT.VIEW },
				path: '/ticket',
			},
			Create: {
				path: '/ticket/create',
			},
			Edit: {
				policy: { page: Policies.IT_DEPARTMENT.PAGE, action: Policies.IT_DEPARTMENT.EDIT },

				path: '/ticket/edit/:id',
			},
			Detail: {
				policy: { page: Policies.IT_DEPARTMENT.PAGE, action: Policies.IT_DEPARTMENT.VIEW },

				path: '/ticket/details/:id',
			},
			Review: {
				path: '/ticket/review/:id',
			},
		},
	},

	// --------------AUTHORIZATION--------------

	Signin: { path: '/sign-in' },
	Unauthorized: { path: '/401' },
	UnderConstruction: { path: '/500' },
	NotFound: { path: '/examples/404' },

	//REGISTRATION

	Registration: { path: '/registration' },
	UnInvited: { path: '/uninvited' },
	//PROFILE
	Profile: {
		Index: { path: '/profile' },
		Edit: { path: '/profile-edit' },
	},
	Support: { path: '/support' },
	Notification: { path: '/notification' },

	// --------------FAQ & DOCUMENTATION--------------

	Changelog: { path: '/changelog' },

	// // pages
	// Presentation: { path: "/presentation" },
	// Transactions: { path: "/transactions" },
	// Upgrade: { path: "/upgrade" },
	// BootstrapTables: { path: "/tables/bootstrap-tables" },
	// Billing: { path: "/examples/billing" },
	// Invoice: { path: "/examples/invoice" },
	// Signup: { path: "/examples/sign-up" },
	// ForgotPassword: { path: "/examples/forgot-password" },
	// ResetPassword: { path: "/examples/reset-password" },
	// Lock: { path: "/examples/lock" },
	// ServerError: { path: "/examples/500" },

	// // docs
	// DocsOverview: { path: "/documentation/overview" },
	// DocsDownload: { path: "/documentation/download" },
	// DocsQuickStart: { path: "/documentation/quick-start" },
	// DocsLicense: { path: "/documentation/license" },
	// DocsFolderStructure: { path: "/documentation/folder-structure" },
	// DocsBuild: { path: "/documentation/build-tools" },
	// DocsChangelog: { path: "/documentation/changelog" },

	// // components
	// Accordions: { path: "/components/accordions" },
	// Alerts: { path: "/components/alerts" },
	// Badges: { path: "/components/badges" },
	// Widgets: { path: "/widgets" },
	// Breadcrumbs: { path: "/components/breadcrumbs" },
	// Buttons: { path: "/components/buttons" },
	// Forms: { path: "/components/forms" },
	// Modals: { path: "/components/modals" },
	// Navs: { path: "/components/navs" },
	// Navbars: { path: "/components/navbars" },
	// Pagination: { path: "/components/pagination" },
	// Popovers: { path: "/components/popovers" },
	// Progress: { path: "/components/progress" },
	// Tables: { path: "/components/tables" },
	// Tabs: { path: "/components/tabs" },
	// Tooltips: { path: "/components/tooltips" },
	// Toasts: { path: "/components/toasts" },
	// WidgetsComponent: { path: "/components/widgets" },
};
