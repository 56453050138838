import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';

import { clearData } from '../../../../../actions/globalActions';
import SupplierFeedbackForm from './SupplierFeedbackForm';
import { createSupplierEvaluation } from '../../../../../actions//Procurement/suppliersActions';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import { getLPOs } from '../../../../../actions/Procurement/lpoActions';

const SupplierEvaluationCreate = (props) => {
	const {
		datas: { lpos },
		loading,
	} = props;

	useEffect(() => {
		props.getLPOs();

		return () => {
			props.clearData();
		};
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			props.createSupplierEvaluation(formValues);
		}
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<SupplierFeedbackForm datas={lpos} onSubmit={onSubmit} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		procurement: {
			lpo: { lpos, getLPOsLoading },
		},
	} = state;

	return { datas: { lpos }, loading: getLPOsLoading };
};

export default connect(mapStateToProps, {
	clearData,
	createSupplierEvaluation,
	getLPOs,
})(SupplierEvaluationCreate);
