import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import CategoryForm from './CategoryForm';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import { clearData } from '../../../../../actions/globalActions';
import { getCategory, updateCategory } from '../../../../../actions/Delivery/deliveryActions';

const ItemEdit = (props) => {
	const { id } = props.match.params;
	const { category, loading } = props;

	useEffect(() => {
		props.getCategory(id);
		return () => {
			props.clearData();
		};
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			props.updateCategory(formValues);
		}
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<CategoryForm onSubmit={onSubmit} loading={loading} initials={category} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.delivery.item.getCategoryLoading,
		category: state.delivery.item.category,
	};
};

export default connect(mapStateToProps, { updateCategory, getCategory, clearData })(ItemEdit);
