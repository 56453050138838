import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getDepartments } from '../../../../actions/Admin/departmentActions';
import { getUser, updateUser, getRoles } from '../../../../actions//User/userActions';
import { updatePolicy, getPolicy } from '../../../../actions/Admin/policyActions';
import { getEntities } from '../../../../actions/Admin/entityActions';
import UserForm from './UserForm';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import _ from 'lodash';
import { clearData } from '../../../../actions/globalActions';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useMsal } from '@azure/msal-react';
import { Policies } from '../../../../helpers/policies';

const SWAL = withReactContent(Swal);

const UserEdit = (props) => {
	const { id, departmentName } = props.match.params;
	const { instance } = useMsal();
	const {
		user,
		loading,
		entities,
		roles,
		entityLoading,
		policies,
		policyLoading,
		signedUserId,
		signedUserEmail,
		departments,
		getDepartmentsLoading,
	} = props;

	// GET INITIAL DATAS FOR EDIT FORM
	useEffect(() => {
		props.getUser(id);
		props.getEntities();
		props.getRoles();
		props.getPolicy(id);
		props.getDepartments();
	}, []);

	// ON SUBMIT FUNCTION
	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			const { ADMIN, PROCUREMENT, FINANCE, HUMAN_RESOURCES, PROJECT_MANAGEMENT, TRANSPORTATION, DELIVERY } =
				formValues.policies;

			// POLICY DATA
			var policies = {
				ADMIN: {
					actions: [],
					companies: [],
				},
				ISR: {
					actions: [],
					companies: [],
				},
				LPO: {
					actions: [],
					companies: [],
				},
				SUPPLIER: {
					actions: [],
					companies: [],
				},
				FINANCE: {
					actions: [],
					companies: [],
				},
				HUMAN_RESOURCES: {
					actions: [],
					companies: [],
				},
				PROJECT_MANAGEMENT: {
					actions: [],
					companies: [],
				},
				TRANSPORTATION: {
					actions: [],
					companies: [],
				},
				IT_DEPARTMENT: {
					actions: [],
					companies: [],
				},
				DELIVERY: {
					actions: [],
					companies: [],
				},
			};

			const changePolicyDataStructure = () => {
				Object.keys(Policies).map((policyName) => {
					const policyObj = formValues.policies[policyName];

					if (policyObj) {
						if (policyObj.actions) {
							policies[policyName].actions = Object.keys(policyObj.actions).map((key) => {
								return policyObj.actions[key];
							});
						}

						if (policyObj.companies) {
							policies[policyName].companies = policyObj.companies.map((obj) => {
								return obj['company_id'];
							});
						}
					}
				});
			};
			changePolicyDataStructure();

			if (id == signedUserId) {
				SWAL.fire({
					title: 'Are you sure?',
					text: 'You will be logged out.Please login again.',
					icon: 'warning',
					confirmButtonText: 'Ok',
					reverseButtons: true,
					cancelButtonText: 'Cancel',
					showCancelButton: true,
					confirmButtonColor: 'red',
					didOpen: () => {},
					didClose: () => {},
				}).then((result) => {
					if (result.isConfirmed) {
						props.updateUser(formValues.user);
						props.updatePolicy({
							policies: policies,
							user_id: id,
						});
						instance.logoutRedirect({
							postLogoutRedirectUri: '/',
						});
					} else {
						console.log('Cancel');
					}
				});
			} else {
				props.updateUser(formValues.user);
				props.updatePolicy({
					policies: policies,
					user_id: id,
				});
			}
		}
	};

	// RETURN VIEW
	if (loading || entityLoading || policyLoading || getDepartmentsLoading) {
		return <LoadingSpinner />;
	} else {
		return (
			<div className="mt-3">
				<UserForm
					onSubmit={onSubmit}
					roles={roles}
					departments={departments}
					entities={entities}
					initials={{
						user: {
							id: id,
							...user,
						},
						policies: { ...policies },
					}}
				/>
			</div>
		);
	}
};

const mapStateToProps = (state) => {
	return {
		user: state.user.user,
		loading: state.user.getUserLoading,
		roles: state.user.roles,
		entities: state.admin.entity.entities,
		entityLoading: state.admin.entity.getEntitiesLoading,
		policies: state.policy.policy,
		policyLoading: state.policy.getPolicyLoading,
		signedUserId: state.auth.user_id,
		signedUserEmail: state.auth.email,
		departmentsLoading: state.admin.department.getDepartmentsLoading,
		departments: state.admin.department.departments,
	};
};

export default connect(mapStateToProps, {
	getUser,
	updateUser,
	getRoles,
	getEntities,
	updateUser,
	updatePolicy,
	getPolicy,
	clearData,
	getDepartments,
})(UserEdit);
