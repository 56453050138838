import {
	CREATE_SALARY_DEDUCTION,
	GET_SALARY_DEDUCTIONS,
	GET_SALARY_DEDUCTION,
	DELETE_SALARY_DEDUCTION,
} from '../../actions/HumanResources/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	salaryDeductions: [],
	salaryDeduction: {},
	loading: true,
	getSalaryDeductionLoading: true,
	getSalaryDeductionsLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_SALARY_DEDUCTIONS:
			return { ...state, salaryDeductions: action.payload, getSalaryDeductionsLoading: false };

		case GET_SALARY_DEDUCTION:
			return { ...state, salaryDeduction: action.payload, getSalaryDeductionLoading: false };

		case CREATE_SALARY_DEDUCTION:
			return { ...state };

		case CLEAR_DATA:
			return {
				...state,
				salaryDeduction: action.payload,
				getSalaryDeductionLoading: true,
				getSalaryDeductionsLoading: true,
			};

		case DELETE_SALARY_DEDUCTION:
			return { ...state };

		default:
			return state;
	}
};
