import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { Image, Col, Card } from '@themesberg/react-bootstrap';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const DeliveryCard = ({ item, order, setOrder }) => {
	const [counter, setCounter] = useState(
		_.find(order, { item_name: item.item_name }) ? _.find(order, { item_name: item.item_name }).item_amount : 0
	);
	const { t } = useTranslation();

	useEffect(() => {
		item.item_amount = counter;
		setOrder([...order]);
	}, [counter]);

	const addItemToCard = () => {
		if (order.some((x) => x.item_id == item.item_id)) {
			var index = order.findIndex((x) => x.item_id == item.item_id);
			order[index].item_amount += 1;
			setOrder([...order]);
			setCounter(counter + 1);

			return;
		} else {
			setCounter(counter + 1);
			setOrder([...order, item]);
		}
	};
	const removeItemFromCard = () => {
		if (counter == 1) {
			const updatedCart = order.filter((product) => product.item_id !== item.item_id);
			setCounter(0);

			setOrder(updatedCart);
		} else if (counter > 1) {
			var index = order.findIndex((x) => x.item_id == item.item_id);

			order[index].item_amount -= 1;
			setOrder([...order]);
			setCounter(counter - 1);
		}
	};
	return (
		<Col className="d-flex px-1 pb-2 " xl={4} lg={4} md={6} sm={4} xs={6}>
			<Card>
				<Card.Body className="text-center d-flex flex-column p-0">
					<div className="card-img-top" style={{ width: '35%', height: '35%', alignSelf: 'center', marginTop: '25px' }}>
						<Image src={item.icon} />
					</div>

					<div className="card-body p-lg-3 p-md-4 mt-3">
						<h5 className="card-title text-center" style={{ fontSize: '2.5vh' }}>
							{item.item_name}
						</h5>
						<p className="card-text text-center" style={{ fontSize: '1.6vh' }}>
							{item.description}
						</p>
					</div>

					<div className="text-justify-center row px-0 mx-0 mb-1">
						{counter != 0 ? (
							<div className="w-100 justify-content-center d-flex">
								<button className="btn btn-primary btn-sm col-sm  px-0 mb-2 w-100" onClick={removeItemFromCard}>
									<FontAwesomeIcon icon={faMinus} />
								</button>
								<div
									className="col-sm text-center mx-3"
									style={{ borderWidth: '1px', borderColor: 'black', fontSize: '24px' }}>
									{counter}
								</div>
								<button className="btn btn-primary btn-sm col-sm px-0  mb-2 w-100" onClick={addItemToCard}>
									<FontAwesomeIcon icon={faPlus} />
								</button>{' '}
							</div>
						) : (
							<div className="w-100 justify-content-center">
								<button className="btn btn-primary btn-sm col-sm px-0 w-100 mb-2" onClick={addItemToCard}>
									{t('add-0')}{' '}
								</button>
							</div>
						)}
					</div>
				</Card.Body>
			</Card>
		</Col>
	);
};

export default DeliveryCard;
