import axios from '../../apis/axios';
import history from '../../history';

import {
	CREATE_USER_INVITE,
	GET_USER_INVITES,
	GET_USER_INVITES_WITH_FILTER,
	UPDATE_USER_INVITE,
	GET_USER_INVITE,
	DELETE_USER_INVITE,
} from './types';

import { Routes } from '../../routes';

export const createUserInvite = (formValues) => async (dispatch, getState) => {
	axios
		.post('invite/', formValues)
		.then((response) => {
			dispatch({ type: CREATE_USER_INVITE, payload: response.data });
			history.push(Routes.Admin.Invite.Index.path);
		})
		.catch((err) => {});
};

export const getUserInvites = () => async (dispatch, getState) => {
	axios
		.get('invite/')
		.then((response) => {
			dispatch({ type: GET_USER_INVITES, payload: response.data });
		})
		.catch((err) => {});
};

export const getUserInvitesWithFilter = (offset, limit, query, order, search) => async (dispatch, getState) => {
	var params = '';
	params += order.length != 0 ? `${order}&` : '';
	params += query.length != 0 ? `${query}&` : '';
	params += search.length != 0 ? `${search}` : '';

	axios
		.get(`invite/offset/${offset}/limit/${limit}?${params}`)
		.then((response) => {
			dispatch({ type: GET_USER_INVITES_WITH_FILTER, payload: response.data });
		})
		.catch((err) => {});
};

export const getUserInvite = (id) => async (dispatch, getState) => {
	axios
		.get(`invite/${id}`)
		.then((response) => {
			dispatch({ type: GET_USER_INVITE, payload: response.data });
		})
		.catch((err) => {});
};

export const updateUserInvite = (formValues) => async (dispatch, getState) => {
	axios
		.put(`invite/${formValues.id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_USER_INVITE, payload: response.data });
			history.push(Routes.Admin.Invite.Index.path);
		})
		.catch((err) => {});
};

export const deleteUserInvite = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`invite/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_USER_INVITE, payload: response.data });
			setDeleted(!deleted);
		})
		.catch((err) => {});
};
