import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, ListGroup, Row, Table } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getProject } from '../../../../actions/Finance/projectActions';
import { getProjectMilestones, deleteProjectMilestone } from '../../../../actions/Finance/projectMilestoneActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { Routes } from '../../../../routes';

import onDelete from '../../../../helpers/deleteFunction';
import { useTranslation } from 'react-i18next';

const ProjectDetails = (props) => {
	const project_id = props.match.params.id;
	const { t } = useTranslation();

	const [deleted, setDeleted] = useState(false);

	const {
		datas: { project, projectMilestones },
		loading,
	} = props;

	useEffect(() => {
		props.getProject(project_id);
		props.getProjectMilestones(project_id);
	}, [deleted]);

	const renderMilestones = () => {
		return projectMilestones.map((milestone, index) => {
			return (
				<tr key={index}>
					<td className="text-center fw-bold">{index + 1}</td>
					<td className="text-center fw-bold">{new Date(milestone.date).toISOString().split('T')[0]}</td>
					<td className="text-center fw-bold">
						{milestone.total_expense_spent} / {milestone.expected_total_spent}
					</td>
					<td className="text-center fw-bold">
						{milestone.actual_man_power} / {milestone.estimated_man_power}
					</td>
					<td className="text-center fw-bold">{milestone.schedule} %</td>
					<td className="text-center fw-bold">
						{milestone.high_risk} / {milestone.medium_risk} / {milestone.low_risk}
					</td>
					<td className="fw-bold text-center">
						<button
							onClick={() => {
								onDelete(deleted, setDeleted, props.deleteProjectMilestone, milestone.id, project_id);
							}}
							className="btn btn-danger mx-2">
							<FontAwesomeIcon icon={faTrash} />
						</button>
						<Link
							to={Routes.Finance.ProjectMilestone.Edit.path.replace(':id', milestone.id)}
							className="btn btn-primary mx-2">
							<FontAwesomeIcon icon={faEdit} />
						</Link>
					</td>
				</tr>
			);
		});
	};
	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3 col-lg-12 col-12">
					<Card className="p-0 ">
						<Card.Header className="border-bottom-0 px-4 my-auto">
							<h4 className="mb-0">
								{project.project_name} - {project.project_code}
							</h4>
						</Card.Header>
						<Card.Body className="px-2 py-0">
							<ListGroup className=" border-top mb-4">
								<ListGroup.Item className="border-bottom">
									<Row>
										<Col>
											<h6 className="mb-1">{t('project-owner')}</h6>
											<small className="text-gray-700">{project.project_owner.full_name}</small>
										</Col>
										<Col>
											<h6 className="mb-1">{t('project-company')}</h6>
											<small className="text-gray-700">{project.project_company.company_name}</small>
										</Col>
									</Row>
								</ListGroup.Item>

								<ListGroup.Item className="border-bottom">
									<Row>
										<Col>
											<h6 className="mb-1">{t('customer')}</h6>
											<small className="text-gray-700">{project.customer}</small>
										</Col>
										<Col>
											<h6 className="mb-1">{t('project-sector')}</h6>
											<small className="text-gray-700">{project.sector_project}</small>
										</Col>
									</Row>
								</ListGroup.Item>

								<ListGroup.Item className="border-bottom">
									<Row>
										<Col>
											<h6 className="mb-1">{t('start-date')}</h6>
											<small className="text-gray-700">
												{new Date(project.start_date).toISOString().split('T')[0]}
											</small>
										</Col>
										<Col>
											<h6 className="mb-1">{t('due-date')}</h6>
											<small className="text-gray-700">{new Date(project.due_date).toISOString().split('T')[0]}</small>
										</Col>
									</Row>
								</ListGroup.Item>
								<ListGroup.Item className="border-bottom">
									<Row>
										<Col>
											<h6 className="mb-1">{t('site-code')}</h6>
											<small className="text-gray-700">{project.site_code}</small>
										</Col>
										<Col>
											<h6 className="mb-1">{t('address')}</h6>
											<small className="text-gray-700">{project.address}</small>
										</Col>
									</Row>
								</ListGroup.Item>
							</ListGroup>

							<Row className="ms-0 mb-3">
								<Col>
									<h5>{t('project-milestones')}</h5>
								</Col>
								<Col className="text-right">
									<Link
										to={Routes.Finance.ProjectMilestone.Create.path.replace(':project_id', project_id)}
										className="btn btn-secondary">
										{t('add-new')}{' '}
									</Link>
								</Col>
							</Row>
							<Table responsive className="table-centered table-nowrap rounded mb-3 table-hover ">
								<thead className="thead-light">
									<tr className="text-center">
										<th>#</th>
										<th>{t('date-0')}</th>
										<th>
											Actual/Estimated <br /> {t('total-spent')}
										</th>
										<th>
											Actual/Estimated <br /> {t('man-power')}
										</th>
										<th>{t('schedule')}</th>
										<th>
											High/Medium/Low <br /> {t('risks')}
										</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{projectMilestones.length != 0 ? (
										renderMilestones()
									) : (
										<tr>
											<td colSpan="100%" className="text-center">
												{t('no-data-found')}{' '}
											</td>
										</tr>
									)}
								</tbody>
							</Table>

							<Row className="mt-3 mb-3 px-2">
								<Col>
									<Link className="btn btn-secondary" to={Routes.Finance.Project.Index.path}>
										{t('back')}{' '}
									</Link>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		finance: {
			project: { project, getProjectLoading },
			projectMilestone: { projectMilestones, getProjectMilestones },
		},
	} = state;

	return {
		datas: { project, projectMilestones },
		loading: getProjectLoading || getProjectMilestones,
	};
};

export default connect(mapStateToProps, { getProject, getProjectMilestones, deleteProjectMilestone })(ProjectDetails);
