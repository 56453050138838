import React from 'react';
import { Col, Row } from 'react-bootstrap';

class Camera extends React.Component {
	constructor() {
		super();

		this.cameraNumber = 0;
		this.cameraName = '';
		this.state = {
			imageDataURL: null,
			videoHidden: true,
			takenPictureCount: 0,
		};
	}

	initializeMedia = async () => {
		this.setState({ imageDataURL: null, videoHidden: false });

		if (!('mediaDevices' in navigator)) {
			navigator.mediaDevices = {};
		}

		if (!('getUserMedia' in navigator.mediaDevices)) {
			navigator.mediaDevices.getUserMedia = function (constraints) {
				var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

				if (!getUserMedia) {
					return Promise.reject(new Error('getUserMedia Not Implemented'));
				}

				return new Promise((resolve, reject) => {
					getUserMedia.call(navigator, constraints, resolve, reject);
				});
			};
		}

		//Get the details of video inputs of the device
		const videoInputs = await this.getListOfVideoInputs();

		//The device has a camera
		if (videoInputs.length) {
			navigator.mediaDevices
				.getUserMedia({
					video: {
						deviceId: {
							exact: videoInputs[this.cameraNumber].deviceId,
						},
					},
				})
				.then((stream) => {
					this.player.srcObject = stream;
					this.cameraName = videoInputs[this.cameraNumber].label;
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			alert('The device does not have a camera');
		}
	};

	closeCamera = () => {
		var videoElem = document.getElementById('video');

		const stream = videoElem.srcObject;
		if (stream) {
			const tracks = stream.getTracks();

			tracks.forEach(function (track) {
				track.stop();
			});

			videoElem.srcObject = null;
		}

		this.setState({ imageDataURL: null, videoHidden: true });
	};

	dataURLtoFile = (dataurl, filename) => {
		var arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], filename, { type: mime });
	};

	capturePicture = () => {
		var canvas = document.createElement('canvas');
		canvas.width = this.player.videoWidth;
		canvas.height = this.player.videoHeight;
		var contex = canvas.getContext('2d');
		contex.drawImage(this.player, 0, 0, canvas.width, canvas.height);
		this.player.srcObject.getVideoTracks().forEach((track) => {
			track.stop();
		});

		var currentDate = new Date();
		var photoFileName = this.cameraName.replace(':', '') + ' ' + currentDate.getMilliseconds() + '.png';
		var imgFile = this.dataURLtoFile(canvas.toDataURL('image/png'), photoFileName);

		this.props.setFiles([imgFile]);

		this.setState({ imageDataURL: canvas.toDataURL() });

		this.setState({ videoHidden: true });

		this.setState({ takenPictureCount: this.state.takenPictureCount + 1 });
	};

	switchCamera = async () => {
		const listOfVideoInputs = await this.getListOfVideoInputs();

		// The device has more than one camera
		if (listOfVideoInputs.length > 1) {
			if (this.player.srcObject) {
				this.player.srcObject.getVideoTracks().forEach((track) => {
					track.stop();
				});
			}

			// switch to second camera
			if (this.cameraNumber === 0) {
				this.cameraNumber = 1;
				this.cameraName = listOfVideoInputs[1].label;
			}
			// switch to first camera
			else if (this.cameraNumber === 1) {
				this.cameraNumber = 0;
				this.cameraName = listOfVideoInputs[0].label;
			}

			// Restart based on camera input
			this.initializeMedia();
		} else if (listOfVideoInputs.length === 1) {
			alert('The device has only one camera');
		} else {
			alert('The device does not have a camera');
		}
	};

	getListOfVideoInputs = async () => {
		// Get the details of audio and video output of the device
		const enumerateDevices = await navigator.mediaDevices.enumerateDevices();
		//Filter video outputs (for devices with multiple cameras)
		return enumerateDevices.filter((device) => device.kind === 'videoinput');
	};

	closePhoto = () => {
		this.setState({ imageDataURL: null });
	};

	render() {
		const playerORImage = Boolean(this.state.imageDataURL) ? (
			<img className="col-12 mb-2" src={this.state.imageDataURL} alt="cameraPic" />
		) : (
			<video
				id="video"
				hidden={this.state.videoHidden}
				className="w-100 col-12 mb-2"
				ref={(refrence) => {
					this.player = refrence;
				}}
				autoPlay></video>
		);

		return (
			<div
				className={`d-flex row mx-auto ${
					!this.state.videoHidden ? 'align-items-center justify-content-around' : 'justify-content-around'
				} `}
				style={{ paddingRight: 0, marginRight: 0 }}>
				{playerORImage}
				{!this.state.videoHidden ? (
					<button type="button" className="btn  btn-sm btn-primary col-3" onClick={this.closeCamera}>
						Close Camera
					</button>
				) : (
					<Row>
						<Col>
							<button type="button" className="btn  btn-sm btn-secondary w-100" onClick={this.initializeMedia}>
								{this.state.takenPictureCount > 0 ? 'Take Another Photo' : 'Take Photo'}
							</button>
						</Col>
						{this.state.imageDataURL ? (
							<Col>
								<button type="button" className="btn  btn-sm btn-info w-100  " onClick={this.closePhoto}>
									Close Photo
								</button>
							</Col>
						) : null}
					</Row>
				)}
				<button
					type="button"
					className="btn  btn-sm btn-info col-3 "
					hidden={this.state.videoHidden}
					onClick={this.capturePicture}>
					Capture
				</button>
				<button
					type="button"
					className="btn  btn-sm  btn-secondary btn-info col-3"
					hidden={this.state.videoHidden}
					onClick={this.switchCamera}>
					Switch
				</button>
			</div>
		);
	}
}

export default Camera;
