import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchBar = ({ placeholder, data }) => {
	var ref = useRef(null);
	const [showSearchResults, setShowSearchResults] = useState(false);
	const onClickOutside = () => {
		setShowSearchResults(false);
	};
	const handleClickOutside = (event) => {
		if (ref.current && !ref.current.contains(event.target)) {
			onClickOutside();
		}
	};
	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	});

	const [filteredData, setFilteredData] = useState([]);
	const handleFilter = (event) => {
		const searchWord = event.target.value;
		const newFilter = data.filter((value) => {
			if (searchWord != '') {
				return value.name?.toLowerCase().includes(searchWord?.toLowerCase());
			}
		});
		setFilteredData(newFilter);
	};
	return (
		<form className="navbar-search" ref={ref}>
			<div id="topbarSearch">
				<div className="input-group-merge search-bar input-group">
					<span className="input-group-text">
						<FontAwesomeIcon className="searchIcon" icon={faSearch} />
					</span>
					<input
						type="text"
						placeholder={placeholder}
						onChange={handleFilter}
						className="form-control"
						onClick={() => {
							setShowSearchResults(true);
						}}
					/>
				</div>
				{filteredData.length != 0 && showSearchResults && (
					<div className="dataResult">
						{filteredData.map((value, key) => {
							return (
								<div key={key}>
									<a className="dataItem" href={value.path}>
										<p>{value.name}</p>
									</a>
								</div>
							);
						})}
					</div>
				)}
			</div>
		</form>
	);
};
export default SearchBar;
