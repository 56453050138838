import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';
import { CREATE_POLICY, GET_POLICY, UPDATE_POLICY } from './types';

export const createPolicy = (formValues) => async (dispatch) => {
	// console.log("POLICY CREATE FORM VALUES", formValues);
	axios
		.post('policy/', formValues)
		.then((response) => {
			dispatch({ type: CREATE_POLICY, payload: response.data });
		})
		.catch((err) => {});
};

export const updatePolicy = (formValues) => async (dispatch) => {
	console.log('POLICY CREATE FORM VALUES', formValues);

	axios
		.put(`policy/${formValues.user_id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_POLICY, payload: response.data });
			history.push(Routes.Admin.User.Index.path);
		})
		.catch((err) => {});
};

export const getPolicy = (id) => async (dispatch) => {
	axios
		.get(`policy/${id}`)
		.then((response) => {
			dispatch({ type: GET_POLICY, payload: response.data });
		})
		.catch((err) => {});
};
