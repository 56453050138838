import {
	CREATE_CASH_ADVANCE_REQUEST,
	UPDATE_CASH_ADVANCE_REQUEST,
	GET_CASH_ADVANCE_REQUEST,
	GET_CASH_ADVANCE_REQUESTS,
	DELETE_CASH_ADVANCE_REQUEST,
} from '../../actions/HumanResources/types';
import { CLEAR_DATA } from '../../actions/types';
const initialState = {
	cashAdvanceRequests: [],
	cashAdvanceRequest: {},
	loading: true,
	getCashAdvanceRequestLoading: true,
	getCashAdvanceRequestsLoading: true,
};
export default (state = initialState, action) => {
	switch (action.type) {
		case GET_CASH_ADVANCE_REQUESTS:
			return { ...state, cashAdvanceRequests: action.payload, getCashAdvanceRequestsLoading: false };
		case GET_CASH_ADVANCE_REQUEST:
			return { ...state, cashAdvanceRequest: action.payload, getCashAdvanceRequestLoading: false };
		case CREATE_CASH_ADVANCE_REQUEST:
			return { ...state };
		case CLEAR_DATA:
			return {
				...state,
				cashAdvanceRequest: action.payload,
				getCashAdvanceRequestsLoading: true,
				getCashAdvanceRequestLoading: true,
			};
		case DELETE_CASH_ADVANCE_REQUEST:
			return { ...state };
		default:
			return state;
	}
};
