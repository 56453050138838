import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import CashAdvanceRequestForm from './CashAdvanceRequestForm';
import { getEmployees } from '../../../../actions/HumanResources/employeeActions';
import {
	updateCashAdvanceRequest,
	getCashAdvanceRequest,
} from '../../../../actions/HumanResources/cashAdvanceRequestActions';
import { clearData } from '../../../../actions/globalActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import _ from 'lodash';

const CashAdvanceRequestEdit = (props) => {
	const { id } = props.match.params;
	const { datas, loading } = props;

	useEffect(() => {
		props.getEmployees();
		props.getCashAdvanceRequest(id);
		return () => {
			props.clearData();
		};
	}, []);
	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.updateCashAdvanceRequest(formValues);
		}
	};
	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<CashAdvanceRequestForm datas={datas} onSubmit={onSubmit} initials={datas.cashAdvanceRequest} />
				</div>
			)}
		</>
	);
};
const mapStateToProps = (state) => {
	const {
		humanResources: {
			employee: { employees, getEmployeesLoading },
			cashAdvanceRequest: { cashAdvanceRequest, getCashAdvanceRequestLoading },
		},
	} = state;
	return {
		datas: { employees, cashAdvanceRequest },
		loading: getCashAdvanceRequestLoading || getEmployeesLoading,
	};
};
export default connect(mapStateToProps, { getEmployees, clearData, getCashAdvanceRequest, updateCashAdvanceRequest })(
	CashAdvanceRequestEdit
);
