import { faHome, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import IndexTable from '../../../../components/IndexTable';
import { Routes } from '../../../../routes';
import { getClearances, deleteClearance } from '../../../../actions/HumanResources/clearanceActions';
import onDelete from '../../../../helpers/deleteFunction';
import { useTranslation } from 'react-i18next';

const ClearanceIndex = (props) => {
	const { datas, loading } = props;
	const [deleted, setDeleted] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		props.getClearances();
	}, [deleted]);
	const renderRows = () => {
		return datas.clearances.map((clearance, index) => {
			return (
				<tr key={index}>
					<td className="fw-bold text-center">{clearance.reference}</td>
					<td className="fw-bold text-center">{clearance.employee.full_name}</td>
					<td className="fw-bold text-center">{clearance.employee.job_title}</td>
					<td className="fw-bold text-center">{clearance.employee.manager}</td>
					<td className="fw-bold text-center">{clearance.employee.working_hours}</td>
					<td className="fw-bold text-center">{new Date(clearance.date_of_last_work).toLocaleDateString()}</td>
					<td className="fw-bold text-center">{clearance.status}</td>
					<td className="fw-bold text-center">
						{clearance.status == t('active-0') ? (
							<>
								<button
									onClick={() => {
										onDelete(deleted, setDeleted, props.deleteClearance, clearance.id);
									}}
									className="btn btn-danger mx-2">
									<FontAwesomeIcon icon={faTrash} />
								</button>
								<Link
									to={Routes.HumanResources.Clearance.Edit.path.replace(':id', clearance.id)}
									className="btn btn-primary mx-2">
									<FontAwesomeIcon icon={faEdit} />
								</Link>
							</>
						) : null}
					</td>
				</tr>
			);
		});
	};
	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0 ">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.HumanResources.Overview.path }}>
							{t('human-resources')}{' '}
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{t('staff-labour-clearance')} </Breadcrumb.Item>
					</Breadcrumb>
				</div>

				<div
					className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-5
">
					<h4 style={{ display: 'inline' }}>{t('staff-labour-clearance-0')}</h4>
					<Link className="btn btn-primary py-2 px-3  " to={Routes.HumanResources.Clearance.Create.path}>
						{t('add-new')}{' '}
					</Link>
				</div>
				<p className="mb-0">{t('your-most-recent-staff-labour-clearance-forms-can-be-seen-here')}</p>
			</div>

			<IndexTable
				loading={false}
				headerProps="text-center"
				headers={[
					t('reference'),
					t('employee'),
					t('job-title'),
					t('manager'),
					t('working-hours'),
					t('date-of-last-work'),
					t('status'),
					'',
				]}
				renderRows={datas.clearances.length != 0 ? renderRows() : null}
				// filter={
				//     <div className="mb-3">
				//         <TableFilter
				//             initialFilterCategories={initialFilterCategories}
				//             filters={filters}
				//             setFilters={setFilters}
				//             datas={projects}
				//             setQuery={setQuery}
				//             filterCategories={filterCategories}
				//             setFilterCategories={setFilterCategories}
				//         />
				//     </div>
				// }
				// pagination={
				//     projects.length > 1 ? (
				//         <ReactPaginate
				//             nextLabel=" >"
				//             onPageChange={handlePageClick}
				//             pageCount={Math.ceil(_.last(projects).page_count)}
				//             pageRangeDisplayed={3}
				//             previousLabel="< "
				//             pageClassName="page-item"
				//             pageLinkClassName="page-link"
				//             previousClassName="page-item"
				//             forcePage={offset}
				//             previousLinkClassName="page-link"
				//             nextClassName="page-item"
				//             nextLinkClassName="page-link"
				//             breakLabel="..."
				//             breakClassName="page-item"
				//             breakLinkClassName="page-link"
				//             containerClassName="pagination"
				//             activeClassName="active"
				//             renderOnZeroPageCount={null}
				//         />
				//     ) : null
				// }
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		humanResources: {
			clearance: { clearances, getClearancesLoading },
		},
	} = state;
	return {
		datas: { clearances },
		loading: getClearancesLoading,
	};
};

export default connect(mapStateToProps, { getClearances, deleteClearance })(ClearanceIndex);
