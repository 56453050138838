import { Form, Row, Table } from '@themesberg/react-bootstrap';
import { Field } from 'redux-form';
import renderRadioButton_v2 from '../../../../../components/formComponents/renderRadioButton_v2';
import { date, required, nonZero, notEmpty } from '../../../../../helpers/validator';

export default ({ deliveryStartValue, deliveryCompleteValue, materialDeliveryValue, materialQuantityValue }) => {
	return (
		<Row className="mb-3">
			<Table responsive className="table-centered table-nowrap rounded mb-0 ">
				<thead className="thead-light text-left">
					<tr>
						<th>Activity</th>
						<th className="text-center" colSpan={5}>
							Ratings / Recommendations
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className="fw-bolder">Response to Request to Deliver</td>
						<td>
							<Form.Group>
								<Field
									name="request_response"
									options={[{ value: 'quick', title: 'Quick' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						<td>
							<Form.Group>
								<Field
									name="request_response"
									options={[{ value: 'as_per_Order', title: 'As per Order' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						<td>
							<Form.Group>
								<Field
									name="request_response"
									options={[{ value: 'slow', title: 'Slow' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						<td></td>
					</tr>
					<tr>
						<td className="fw-bolder">Response to technical clarifications</td>
						<td>
							<Form.Group>
								<Field
									name="technical_response"
									options={[{ value: 'quick', title: 'Quick' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						<td>
							<Form.Group>
								<Field
									name="technical_response"
									options={[{ value: 'as_per_Order', title: 'As per Order' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						<td>
							<Form.Group>
								<Field
									name="technical_response"
									options={[{ value: 'slow', title: 'Slow' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						<td></td>
					</tr>
					<tr>
						<td className="fw-bolder">Delivery started on time</td>
						<td>
							<Form.Group>
								<Field
									name="delivery_start"
									options={[{ value: 'yes', title: 'Yes' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						<td>
							<Form.Group>
								<Field
									name="delivery_start"
									value="no"
									options={[{ value: 'no', title: 'No' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						{deliveryStartValue == 'no' ? (
							<>
								<td>
									<Form.Group>
										<Field
											name="delivery_start_delay"
											options={[
												{
													value: 'affected_schedule',
													title: 'Affected Schedule',
												},
											]}
											component={renderRadioButton_v2}
										/>
									</Form.Group>
								</td>
								<td>
									<Form.Group>
										<Field
											name="delivery_start_delay"
											options={[
												{
													value: 'did_not_affected_schedule',
													title: 'Did not affected Schedule',
												},
											]}
											component={renderRadioButton_v2}
										/>
									</Form.Group>
								</td>
							</>
						) : (
							<>
								<td></td>
								<td></td>
							</>
						)}
					</tr>
					<tr>
						<td className="fw-bolder"> Delivery completed on time</td>
						<td>
							<Form.Group>
								<Field
									name="delivery_complete"
									options={[{ value: 'yes', title: 'Yes' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						<td>
							<Form.Group>
								<Field
									name="delivery_complete"
									options={[{ value: 'no', title: 'No' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						{deliveryCompleteValue == 'no' ? (
							<>
								<td>
									<Form.Group>
										<Field
											name="delivery_complete_delay"
											value="affected_schedule"
											options={[
												{
													value: 'affected_schedule',
													title: 'Affected Schedule',
												},
											]}
											component={renderRadioButton_v2}
										/>
									</Form.Group>
								</td>
								<td>
									<Form.Group>
										<Field
											name="delivery_complete_delay"
											options={[
												{
													value: 'did_not_affected_schedule',
													title: 'Did not affected Schedule',
												},
											]}
											component={renderRadioButton_v2}
										/>
									</Form.Group>
								</td>
							</>
						) : (
							<>
								<td></td>
								<td></td>
							</>
						)}
					</tr>
					<tr>
						<td className="fw-bolder">Material delivered as specified</td>
						<td>
							<Form.Group>
								<Field
									name="material_delivery"
									options={[{ value: 'yes', title: 'Yes' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						<td>
							<Form.Group>
								<Field
									name="material_delivery"
									options={[{ value: 'no', title: 'No' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						{materialDeliveryValue == 'no' ? (
							<>
								<td>
									<Form.Group>
										<Field
											name="material_delivery_alternative"
											options={[
												{
													value: 'alternative_accepted',
													title: 'Alternative accepted',
												},
											]}
											component={renderRadioButton_v2}
										/>
									</Form.Group>
								</td>
								<td>
									<Form.Group>
										<Field
											name="material_delivery_alternative"
											options={[
												{
													value: 'alternative_not_accepted',
													title: 'Alternative not accepted',
												},
											]}
											component={renderRadioButton_v2}
										/>
									</Form.Group>
								</td>
							</>
						) : (
							<>
								<td></td>
								<td></td>
							</>
						)}
					</tr>
					<tr>
						<td className="fw-bolder">Delivered material quality</td>
						<td>
							<Form.Group>
								<Field
									name="material_quality"
									options={[{ value: 'good', title: 'Good' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						<td>
							<Form.Group>
								<Field
									name="material_quality"
									options={[{ value: 'fair', title: 'Fair' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						<td>
							<Form.Group>
								<Field
									name="material_quality"
									options={[{ value: 'bad', title: 'Bad' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						<td></td>
					</tr>
					<tr>
						<td className="fw-bolder">Delivered material quantity</td>
						<td>
							<Form.Group>
								<Field
									name="material_quantity"
									options={[{ value: 'full', title: 'Full' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						<td>
							<Form.Group>
								<Field
									name="material_quantity"
									options={[{ value: 'partial', title: 'Partial' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						{materialQuantityValue == 'partial' ? (
							<>
								<td>
									<Form.Group>
										<Field
											name="material_quantity_partial"
											options={[
												{
													value: 'balance_cancelled',
													title: 'Balance Cancelled',
												},
											]}
											component={renderRadioButton_v2}
										/>
									</Form.Group>
								</td>
								<td>
									<Form.Group>
										<Field
											name="material_quantity_partial"
											options={[
												{
													value: 'balance_obtained_from_another_source',
													title: 'Balance obtained from another source',
												},
											]}
											component={renderRadioButton_v2}
										/>
									</Form.Group>
								</td>
							</>
						) : (
							<>
								<td></td>
								<td></td>
							</>
						)}
					</tr>
					<tr>
						<td className="fw-bolder">Goods packiging</td>
						<td>
							<Form.Group>
								<Field
									name="goods_packaging"
									options={[{ value: 'good', title: 'Good' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						<td>
							<Form.Group>
								<Field
									name="goods_packaging"
									options={[{ value: 'fair', title: 'Fair' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						<td>
							<Form.Group>
								<Field
									name="goods_packaging"
									options={[{ value: 'bad', title: 'Bad' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						<td>
							<Form.Group>
								<Field
									name="goods_packaging"
									options={[{ value: 'not_applicable', title: 'Not applicable' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
					</tr>
					<tr>
						<td className="fw-bolder">Goods damages</td>
						<td>
							<Form.Group>
								<Field
									name="goods_damages"
									options={[{ value: 'not_damaged', title: 'Not damaged' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						<td>
							<Form.Group>
								<Field
									name="goods_damages"
									options={[{ value: 'minimal', title: 'Minimal' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						<td>
							<Form.Group>
								<Field
									name="goods_damages"
									options={[{ value: 'excessive', title: 'Excessive' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>

						<td></td>
					</tr>
					<tr>
						<td className="fw-bolder">Recommended future orders</td>
						<td>
							<Form.Group>
								<Field
									name="recommended_orders"
									options={[{ value: 'yes', title: 'Yes' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						<td>
							<Form.Group>
								<Field
									name="recommended_orders"
									options={[{ value: 'no', title: 'No' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td className="fw-bolder">Capable of larger orders</td>
						<td>
							<Form.Group>
								<Field
									name="capable_orders"
									options={[{ value: 'yes', title: 'Yes' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						<td>
							<Form.Group>
								<Field
									name="capable_orders"
									options={[{ value: 'no', title: 'No' }]}
									component={renderRadioButton_v2}
								/>
							</Form.Group>
						</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
				</tbody>
			</Table>
		</Row>
	);
};
