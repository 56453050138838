import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import InvitesForm from './InvitesForm';
import { getRoles } from '../../../../actions//User/userActions';
import { createUserInvite } from '../../../../actions//Admin/userInviteActions';
import { getEntities } from '../../../../actions/Admin/entityActions';
import { getDepartments } from '../../../../actions/Admin/departmentActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';

const InvitestCreate = (props) => {
	const { entities, roles, rolesLoading, entitiesLoading, departments, getDepartmentsLoading } = props;

	useEffect(() => {
		props.getRoles();
		props.getEntities();
		props.getDepartments();
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			props.createUserInvite(formValues);
		}
	};

	if (rolesLoading || entitiesLoading || getDepartmentsLoading) {
		return <LoadingSpinner />;
	} else {
		return (
			<div className="mt-3">
				<InvitesForm roles={roles} entities={entities} onSubmit={onSubmit} departments={departments} />
			</div>
		);
	}
};

const mapStateToProps = (state) => {
	return {
		roles: state.user.roles,
		entities: state.admin.entity.entities,
		rolesLoading: state.user.getRolesLoading,
		entitiesLoading: state.admin.entity.getEntitiesLoading,
		departmentsLoading: state.admin.department.getDepartmentsLoading,
		departments: state.admin.department.departments,
	};
};

export default connect(mapStateToProps, {
	getRoles,
	createUserInvite,
	getEntities,
	getDepartments,
})(InvitestCreate);
