import { AZURE_LOGIN, COMPANY_FILTER, FIRST_REGISTER, INITIAL_LOGIN, SIGN_IN } from '../actions/Auth/types';

const INITIAL_STATE = {
	isRoleLoaded: false,
	policiesLoading: true,
	companyFilter: null,
	initialLogin: false,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SIGN_IN:
			return {
				...state,
				...action.payload,
				isRoleLoaded: true,
				policiesLoading: false,
			};
		case AZURE_LOGIN:
			return {
				...state,
				...action.payload,
			};
		case COMPANY_FILTER:
			return { ...state, ...action.payload };
		case INITIAL_LOGIN:
			return { ...state, initialLogin: action.payload };

		case FIRST_REGISTER:
			return {
				...state,
				mail: action.payload,
			};

		default:
			return state;
	}
};
