import { Card, Col, Row } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import countryList from 'react-select-country-list';
import { compose } from 'redux';
import { Field, initialize, reduxForm } from 'redux-form';
import axios from '../../../../apis/axios';
import renderDropdown from '../../../../components/formComponents/renderDropdown';
import renderInput from '../../../../components/formComponents/renderInput';
import renderPhoneNumberInput from '../../../../components/formComponents/renderPhoneNumberInput';
import renderTextArea from '../../../../components/formComponents/renderTextArea';
import SubmitButton from '../../../../components/formComponents/SubmitButton';
import { changeCompanyStructure } from '../../../../helpers/helpers';
import { Policies } from '../../../../helpers/policies';
import { maxLength128, nonZero, phoneNumber, required } from '../../../../helpers/validator';

const SuppliersForm = (props) => {
	const { initials, invalid, entities, auth, error } = props;

	const [supplierNameCheck, setSupplierNameCheck] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		{
			initials != null
				? props.initialize({
						id: initials.supplier_id,
						supplier_name: initials.supplier_name,
						phone_number: initials.supplier_contact?.phone_number,
						email: initials.supplier_contact?.email,
						contact_role: initials.supplier_contact?.contact_role,
						fax_number: initials.supplier_contact?.fax_number,
						address: initials.supplier_contact?.address,
						contact_name: initials.supplier_contact?.contact_name,
						mobile_phone_number: initials.supplier_contact?.mobile_phone_number,
						country: initials.supplier_contact?.country,
						account_id: initials.account_id, // TO DO:account_id ---> account_code
						user: auth.first_name + ' ' + auth.last_name,
				  })
				: props.initialize({
						country: t('qatar'),
						user: auth.first_name + ' ' + auth.last_name,
				  });
		}

		props.change('user_id', auth.user_id);
	}, []);

	useEffect(() => {
		props.change('isValid', !invalid);
	}, [invalid]);

	const renderCountryOptions = () => {
		return countryList()
			.getLabels()
			.map((value, index) => {
				if (value == t('qatar')) {
					return (
						<option key={value} value={value}>
							{value}
						</option>
					);
				} else {
					return (
						<option key={value} value={value}>
							{value}
						</option>
					);
				}
			});
	};

	const renderCompanies = () => {
		if (auth.policies.SUPPLIER.actions.includes(Policies.SUPPLIER.VIEW_SELF)) {
			return auth.companies.map((company, index) => {
				return (
					<option key={index} value={company.company_id}>
						{company.company_name}
					</option>
				);
			});
		} else if (auth.policies.SUPPLIER.actions.includes(Policies.SUPPLIER.VIEW_ALL)) {
			return entities.map((company, index) => {
				return (
					<option key={index} value={company.company_id}>
						{company.company_name}
					</option>
				);
			});
		} else if (auth.policies.SUPPLIER.actions.includes(Policies.SUPPLIER.VIEW_COMPANY)) {
			return changeCompanyStructure(auth.policies.SUPPLIER.companies, entities).map((company, index) => {
				return (
					<option key={index} value={company.company_id}>
						{company.company_name}
					</option>
				);
			});
		} else {
			return null;
		}
	};

	const checkSupplierName = (name) => {
		if (name) {
			axios.get(`supplier/check/${name}`).then((response) => {
				setSupplierNameCheck(response.data);
			});
		} else {
			setSupplierNameCheck(false);
		}
	};

	return (
		<form onSubmit={props.handleSubmit(props.onSubmit)}>
			<Row>
				<Col xl={8} lg={8} md={12} sm={12} xs={12}>
					<Card border="light" className="bg-white shadow-sm mb-4  w-100">
						<Card.Body>
							<h5 className="mb-4">{t('suppliers-form')}</h5>
							<Row className="mb-3 ">
								<Col md={6} sm={12}>
									<Field name="user" label={t('by')} component={renderInput} disabled="disabled" />
								</Col>
								<Col md={6} sm={12}>
									<Field
										name="company_id"
										label={t('company')}
										component={renderDropdown}
										renderedOptions={renderCompanies()}
									/>
								</Col>
							</Row>
							<hr />
							<Row className="">
								<Col className="mb-3">
									<Field
										validate={[required, maxLength128]}
										onChange={(e) => {
											checkSupplierName(e.target.value);
										}}
										name="supplier_name"
										label={t('supplier-name')}
										component={renderInput}
									/>
								</Col>
							</Row>
							<Row className="">
								<Col className="mb-3">
									<Field
										validate={nonZero}
										name="country"
										label={t('country')}
										component={renderDropdown}
										renderedOptions={renderCountryOptions()}
									/>
								</Col>
							</Row>
							<Row className="">
								<Col className="mb-3">
									<Field
										validate={required}
										name="account_id"
										label={t('account-code')}
										inputType="number"
										component={renderInput}
									/>
									{/*  TO DO:account_id ---> account_code */}
								</Col>
							</Row>
							<Row className="">
								<Col className="mb-3">
									<Field
										validate={[required, phoneNumber]}
										name="phone_number"
										label={t('telephone')}
										inputType="number"
										component={renderPhoneNumberInput}
									/>
								</Col>
							</Row>
							<Row className="">
								<Col className="mb-3">
									<Field
										validate={[required, phoneNumber]}
										name="fax_number"
										label={t('fax')}
										inputType="number"
										component={renderPhoneNumberInput}
									/>
								</Col>
							</Row>
							<Row className="">
								<Col className="mb-3">
									<Field validate={required} name="address" label={t('address')} component={renderTextArea} />
								</Col>
							</Row>
							<Row className="">
								<Col className="mb-3">
									<Field
										validate={required}
										name="contact_name"
										label={t('contact-person-0')}
										component={renderInput}
									/>
								</Col>
							</Row>
							<Row className="">
								<Col className="mb-3">
									<Field
										validate={required}
										name="contact_role"
										label={t('role-of-the-contact-person')}
										component={renderInput}
									/>
								</Col>
							</Row>
							<Row className="">
								<Col className="mb-3">
									<Field
										validate={required}
										name="email"
										label={t('contact-email')}
										component={renderInput}
										inputType="email"
									/>
								</Col>
							</Row>
							<Row className="">
								<Col className="mb-3">
									<Field
										validate={[required, phoneNumber]}
										name="mobile_phone_number"
										label={t('mobile-phone-number')}
										component={renderPhoneNumberInput}
										inputType="number"
									/>
								</Col>
							</Row>
							{supplierNameCheck && <strong className="text-danger">{t('supplier-name-already-exists')}</strong>}

							<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
								<a onClick={() => window.history.back()} className="btn btn-secondary">
									{t('back')}{' '}
								</a>
								<SubmitButton
									disabled={supplierNameCheck}
									invalid={invalid}
									onSubmit={props.onSubmit}
									text={t('submit')}
								/>{' '}
							</div>
						</Card.Body>
					</Card>
				</Col>
				<Col xl={4} lg={4} md={12} sm={12} xs={12}></Col>
			</Row>
		</form>
	);
};

const mapStateToProps = (state) => {
	return {
		entities: state.admin.entity.entities,
		auth: state.auth,
	};
};

export default compose(
	connect(mapStateToProps, { initialize }),

	reduxForm({
		form: 'suppliers',
	})
)(SuppliersForm);
