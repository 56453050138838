// POLICY

export const GET_POLICY = 'GET_POLICY';
export const CREATE_POLICY = 'CREATE_POLICY';
export const UPDATE_POLICY = 'UPDATE_POLICY';

//ENTITY
export const GET_ENTITIES = 'GET_ENTITIES';
export const CREATE_ENTITY = 'CREATE_ENTITY';
export const GET_ENTITY = 'GET_ENTITY';
export const UPDATE_ENTITY = 'UPDATE_ENTITY';
export const DELETE_ENTITY = 'DELETE_ENTITY';

//DEPARTMENT
export const GET_DEPARTMENTS = 'GET_DEPARTMENTS';
export const CREATE_DEPARTMENT = 'CREATE_DEPARTMENT';
export const GET_DEPARTMENT = 'GET_DEPARTMENT';
export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT';
export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENT';

//USER INVITE
export const CREATE_USER_INVITE = 'CREATE_USER_INVITE';
export const GET_USER_INVITES = 'GET_USER_INVITES';
export const GET_USER_INVITES_WITH_FILTER = 'GET_USER_INVITES_WITH_FILTER';
export const GET_USER_INVITE = 'GET_USER_INVITE';
export const UPDATE_USER_INVITE = 'UPDATE_USER_INVITE';
export const DELETE_USER_INVITE = 'DELETE_USER_INVITE';
//TICKET CATEGORY
export const GET_TICKET_CATEGORY = 'GET_TICKET_CATEGORY';
export const GET_TICKET_CATEGORIES = 'GET_TICKET_CATEGORIES';
export const CREATE_TICKET_CATEGORY = 'CREATE_TICKET_CATEGORY';
export const UPDATE_TICKET_CATEGORY = 'UPDATE_TICKET_CATEGORY';
export const DELETE_TICKET_CATEGORY = 'DELETE_TICKET_CATEGORY';
