import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Card, Col, Form, Row } from '@themesberg/react-bootstrap';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { change, Field, initialize, reduxForm, formValueSelector } from 'redux-form';
import renderDropdownWithAutoComplete from '../../../../components/formComponents/renderDropdownWithAutoComplete';
import renderInput from '../../../../components/formComponents/renderInput';
import renderRadioButton from '../../../../components/formComponents/renderRadioButton';
import renderTextArea from '../../../../components/formComponents/renderTextArea';
import SubmitButton from '../../../../components/formComponents/SubmitButton';
import { Routes } from '../../../../routes';

const selector = formValueSelector('cashAdvanceRequest');

const CashAdvanceRequestFrom = (props) => {
	const { initials, datas, onSubmit, full_name, user_id, companies, monthlyValue, numberOfMonths } = props;
	const { t } = useTranslation();

	useEffect(() => {
		if (numberOfMonths && monthlyValue) {
			props.change('total_amount', monthlyValue * numberOfMonths);
		} else {
			props.change('total_amount', null);
		}
	}, [monthlyValue, numberOfMonths]);

	useEffect(() => {
		if (initials) {
			props.initialize({
				...initials,
				by: full_name,
				job_title: initials.employee.job_title,
				date: new Date(initials.employee.start_date).toISOString().split('T')[0],
			});
		} else {
			props.initialize({
				by: full_name,
				company_id: companies[0].company_id,
			});
		}
		props.change('user_id', user_id);
	}, []);

	return (
		<>
			<div className="d-block mb-4 mb-xl-0">
				<Breadcrumb
					className="d-block"
					listProps={{
						className: 'breadcrumb-dark breadcrumb-transparent',
					}}>
					<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
						<FontAwesomeIcon icon={faHome} />
					</Breadcrumb.Item>
					<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.HumanResources.Overview.path }}>
						{t('human-resources')}{' '}
					</Breadcrumb.Item>
					<Breadcrumb.Item active>{t('cash-advance-request-0')} </Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<form onSubmit={props.handleSubmit(onSubmit)}>
				<Row>
					<Col md={12}>
						<Card border="light" className="bg-white shadow-sm mb-4 w-100">
							<Card.Body>
								<h5 className="mb-4">{t('cash-advance-request-form')}</h5>
								<Row className="mb-3">
									<Col md={6} sm={12}>
										<Field name="by" label={t('by')} component={renderInput} disabled="disabled" />
									</Col>
									<Col md={6} sm={12}>
										<Field
											data={companies}
											dataKey="company_id"
											textField="company_name"
											name="company_id"
											label={t('company')}
											component={renderDropdownWithAutoComplete}
										/>
									</Col>
								</Row>
								<hr />
								<Row className="mb-3">
									<Col>
										<Field
											name="employee_id"
											label={t('employee')}
											dataKey="employee_id"
											textField="full_name"
											data={datas.employees}
											component={renderDropdownWithAutoComplete}
											onChange={(val) => {
												const selectedEmployee = _.find(datas.employees, { employee_id: val });
												props.change(
													'reference',
													`HR/007/${String(selectedEmployee.employee_number).padStart(3, '0')}/${
														new Date().getMonth() + 1
													}${new Date().getDate()}${new Date().getMilliseconds()}`
												);
												props.change('job_title', selectedEmployee.job_title);
												props.change('department', selectedEmployee.department.department_name);
												props.change('date', new Date(selectedEmployee.start_date).toISOString().split('T')[0]);
											}}
										/>
									</Col>
									<Col>
										<Field name="job_title" disabled="disabled" component={renderInput} label={t('job-title')} />
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field name="reference" disabled="disabled" component={renderInput} label={t('reference')} />
									</Col>
									<Col>
										<Field
											name="date"
											inputType="date"
											component={renderInput}
											disabled="disabled"
											label={t('joining-date')}
										/>
									</Col>
								</Row>
								<Row className="mb-3">
									{/* <Col>
										<Field name="work-place" component={renderInput} label="Work Place" />
									</Col> */}
									<Col>
										<Field name="monthly_deduction_months" component={renderInput} label={t('months-of-deduction')} />
									</Col>
									<Col>
										<Field
											name="number_of_months"
											component={renderInput}
											label={t('number-of-months-to-pay')}
											inputType="number"
										/>
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field
											name="monthly_deduction_amount"
											component={renderInput}
											label={t('monthly-deduction')}
											inputType="number"
										/>
									</Col>

									<Col>
										<Field name="total_amount" component={renderInput} label={t('total-amount')} inputType="number" />
									</Col>
								</Row>
								<Row className="mb-4">
									<Col>
										<Field name="reason" rows={4} label={t('reason')} component={renderTextArea} />
									</Col>
								</Row>
								{/* <Row className="mb-3">
									<span className="text-center fw-bolder">
										This part to be filled by the concerned Department Head / Project Manager
									</span>
								</Row>
								<Row className="mb-4">
									<Col>
										<Form.Group>
											<Form.Label>Desicion</Form.Label>
											<Field
												name="decision"
												component={renderRadioButton}
												flexDirection="flex-column"
												options={[
													{ title: 'Approved', value: true },
													{ title: 'Rejected', value: false },
												]}
											/>
										</Form.Group>
									</Col>
									<Col>
										<Field name="managger_comments" rows={4} label="Manager Comments" component={renderTextArea} />
									</Col>
								</Row>
								<Row className="mb-3">
									<span className="text-center fw-bolder">
										This part to be filled by HR Department and verified by Accounting Department
									</span>
								</Row>
								<Row>
									<Col>
										<Field name="accounting_comments" rows={4} label="Remarks" component={renderTextArea} />
									</Col>
								</Row> */}
								<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
									<Link to={Routes.HumanResources.CashAdvanceRequest.Index.path} className="btn btn-secondary">
										{t('back')}{' '}
									</Link>
									<SubmitButton invalid={false} onSubmit={onSubmit} text={t('submit')} />
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</form>
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		auth: { full_name, user_id, companies },
	} = state;
	return {
		full_name,
		user_id,
		companies,
	};
};

export default compose(
	connect(mapStateToProps, { initialize, change }),
	reduxForm({ form: 'cashAdvanceRequest' })
)(CashAdvanceRequestFrom);
