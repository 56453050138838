import React, { useEffect } from 'react';
import ProcurementItemForm from './ProcurementItemForm';
import { getItem, updateItem } from '../../../../actions/Procurement/itemActions';
import { getItemCategory } from '../../../../actions/Procurement/itemCategoryActions';

import { connect } from 'react-redux';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { clearData } from '../../../../actions/globalActions';
import _ from 'lodash';

const ProcurementItemEdit = (props) => {
	const { id } = props.match.params;
	const { datas, loading } = props;

	useEffect(() => {
		props.getItem(id);
		console.log(id, datas);
		return () => {
			props.clearData();
		};
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.updateItem(formValues);
		}
	};

	return (
		<div className="mt-3">
			{loading ? <LoadingSpinner /> : <ProcurementItemForm onSubmit={onSubmit} initials={datas.item} />}
		</div>
	);
};

const mapStateToProps = (state) => {
	const {
		procurement: {
			item: { item, getItemLoading },
		},
	} = state;
	return {
		datas: { item },
		loading: getItemLoading,
	};
};

export default connect(mapStateToProps, { updateItem, getItem, clearData, getItemCategory })(ProcurementItemEdit);
