import { Card, Col, Image, Row } from '@themesberg/react-bootstrap';
import React, { useEffect } from 'react';
import { change, Field, initialize, reduxForm } from 'redux-form';

import { getCategories } from '../../../../../actions/Delivery/deliveryActions';
import { clearData } from '../../../../../actions/globalActions';
import renderDropdown from '../../../../../components/formComponents/renderDropdown';
import renderInput from '../../../../../components/formComponents/renderInput';
import renderRadioButton_v2 from '../../../../../components/formComponents/renderRadioButton_v2';
import SubmitButton from '../../../../../components/formComponents/SubmitButton';
import validate from '../../../../../validate';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';

const ItemForm = (props) => {
	const { t } = useTranslation();

	const renderCategories = () => {
		return props.categories.map((category, index) => {
			return (
				<option key={index} value={category.category_id}>
					{category.category_name}
				</option>
			);
		});
	};

	const { initials, onSubmit, invalid } = props;
	useEffect(() => {
		{
			initials != null
				? props.initialize({
						...initials,
						item_id: initials.item_id,
						item_name: initials.item_name,
						category: initials.category_name,
						description: initials.description,
				  })
				: null;
		}

		props.getCategories();

		return () => {
			props.clearData();
		};
	}, []);

	useEffect(() => {
		props.change('isValid', !invalid);
	}, [invalid]);

	return (
		<form onSubmit={props.handleSubmit(onSubmit)}>
			<Row>
				<Col xl={8} lg={8} md={12} sm={12} xs={12}>
					<Card border="light" className="bg-white shadow-sm mb-4  w-100">
						<Card.Body>
							<h5 className="mb-4">{t('item-form')}</h5>
							<Row className="">
								<Col className="mb-3">
									<Field name="item_name" label={t('name')} component={renderInput} />
								</Col>
							</Row>
							<Row className="">
								<Col className="mb-3">
									<Field name="description" label={t('description')} component={renderInput} />
								</Col>
							</Row>
							<Row className="">
								<Col className="mb-3">
									<Field
										name="category_id"
										label={t('category')}
										component={renderDropdown}
										renderedOptions={renderCategories()}
									/>
								</Col>
							</Row>
							<Row>
								<div style={{ marginBottom: '8px', fontSize: '17px', fontWeight: '600' }}>{t('image')}</div>
								<Col>
									<Card>
										<Card.Body className="text-center d-flex flex-column p-2">
											<div
												className="card-img-top justify-content-center"
												style={{ width: '35%', height: '35%', alignSelf: 'center' }}>
												<Image src={'/tempDeliveryAssets/hot-drink.png'} style={{ alignSelf: 'center' }} />
											</div>
											<Field
												radioButtonProps="d-flex justify-content-center"
												name="icon"
												options={[{ value: '/tempDeliveryAssets/hot-drink.png' }]}
												component={renderRadioButton_v2}
											/>
										</Card.Body>
									</Card>
								</Col>
								<Col>
									<Card>
										<Card.Body className="text-center d-flex flex-column p-2">
											<div
												className="card-img-top justify-content-center"
												style={{ width: '35%', height: '35%', alignSelf: 'center' }}>
												<Image src={'/tempDeliveryAssets/cold-drink.png'} style={{ alignSelf: 'center' }} />
											</div>
											<Field
												radioButtonProps="d-flex justify-content-center"
												name="icon"
												options={[{ value: '/tempDeliveryAssets/cold-drink.png' }]}
												component={renderRadioButton_v2}
											/>
										</Card.Body>
									</Card>
								</Col>
								<Col>
									<Card>
										<Card.Body className="text-center d-flex flex-column p-2">
											<div
												className="card-img-top justify-content-center"
												style={{ width: '35%', height: '35%', alignSelf: 'center' }}>
												<Image src={'/tempDeliveryAssets/snacks.png'} style={{ alignSelf: 'center' }} />
											</div>
											<Field
												radioButtonProps="d-flex justify-content-center"
												name="icon"
												options={[{ value: '/tempDeliveryAssets/snacks.png' }]}
												component={renderRadioButton_v2}
											/>
										</Card.Body>
									</Card>
								</Col>
							</Row>
							<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
								<a onClick={() => window.history.back()} className="btn btn-secondary">
									{t('back')}{' '}
								</a>
								<SubmitButton invalid={invalid} onSubmit={onSubmit} text={t('submit')} />
							</div>
						</Card.Body>
					</Card>
				</Col>
				<Col xl={4} lg={4} md={12} sm={12} xs={12}></Col>
			</Row>
		</form>
	);
};

export default compose(
	connect(null, { clearData, initialize, change, getCategories }),
	reduxForm({
		form: 'delivery/item',
		validate,
	})
)(ItemForm);
