import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';
import { CREATE_DISCIPLINE, DELETE_DISCIPLINE, GET_DISCIPLINE, GET_DISCIPLINES, UPDATE_DISCIPLINE } from './types';

export const createDiscipline = (formValues) => async (dispatch, getState) => {
	axios
		.post('/construction/discipline', formValues)
		.then((response) => {
			dispatch({ type: CREATE_DISCIPLINE, payload: response.data });
			history.push(Routes.Construction.Discipline.Index.path);
			toast.success('Discipline has been added.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		})
		.catch((err) => {
			toast.error('Something went wrong! Please open a ticket.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		});
};

export const getDisciplines = () => async (dispatch) => {
	const response = await axios.get('/construction/discipline');
	dispatch({ type: GET_DISCIPLINES, payload: response.data });
};

export const getDisciplinesWithFilter = (offset, limit, query, order, search) => async (dispatch) => {
	var params = '';
	params += order.length != 0 ? `${order}&` : '';
	params += query.length != 0 ? `${query}&` : '';
	params += search.length != 0 ? `${search}` : '';
	axios
		.get(`/construction/discipline/offset/${offset}/limit/${limit}?${params}`)
		.then((response) => {
			dispatch({ type: GET_DISCIPLINES, payload: response.data });
		})
		.catch((err) => {});
};

// export const getDisciplinesContents = () => async (dispatch) => {
// 	const response = await axios.get('/construction/discipline/content/');
// 	dispatch({ type: GET_DISCIPLINES_CONTENTS, payload: response.data });
// };

export const getDiscipline = (id) => async (dispatch) => {
	const response = await axios.get(`/construction/discipline/${id}`);
	dispatch({ type: GET_DISCIPLINE, payload: response.data });
};

export const deleteDiscipline = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`/construction/discipline/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_DISCIPLINE, payload: response.data });
			setDeleted(!deleted);
			toast.success('Discipline has been deleted.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		})
		.catch((err) => {
			toast.error('Something went wrong! Please open a ticket.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		});
};

export const updateDiscipline = (formValues) => async (dispatch) => {
	axios
		.put(`/construction/discipline/${formValues.discipline_id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_DISCIPLINE, payload: response.data });
			history.push(Routes.Construction.Discipline.Index.path);
			toast.success('Discipline has been updated.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		})
		.catch((err) => {
			toast.error('Something went wrong! Please open a ticket.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		});
};
