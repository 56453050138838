import React, { useEffect } from 'react';
import TicketCategoryForm from './TicketCategoryForm';
import { updateTicketCategory, getTicketCategory } from '../../../../actions/Admin/ticketCategoryActions';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { clearData } from '../../../../actions/globalActions';
import _ from 'lodash';

const TicketCategoryEdit = (props) => {
	const { id } = props.match.params;
	const { datas, loading } = props;

	useEffect(() => {
		props.getTicketCategory(id);
		return () => {
			props.clearData();
		};
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			props.updateTicketCategory(formValues);
		}
	};

	return (
		<div className="mt-3">
			{loading ? <LoadingSpinner /> : <TicketCategoryForm initials={datas.ticketCategory} onSubmit={onSubmit} />}
		</div>
	);
};

const mapStateToProps = (state) => {
	const {
		admin: {
			ticketCategory: { ticketCategory, getTicketCategoryLoading },
		},
	} = state;

	return {
		datas: { ticketCategory },
		loading: getTicketCategoryLoading,
	};
};
export default connect(mapStateToProps, { updateTicketCategory, getTicketCategory, clearData })(TicketCategoryEdit);
