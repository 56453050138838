import { CREATE_QUOTATION, DELETE_QUOTATION, GET_QUOTATION, GET_QUOTATIONS } from '../../actions/Procurement/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	quotation: {},
	quotations: [],
	getQuotationLoading: true,
	getQuotationsLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_QUOTATIONS:
			return { ...state, quotations: action.payload, getQuotationsLoading: false };
		case GET_QUOTATION:
			return { ...state, quotation: action.payload, getQuotationLoading: false };

		case CREATE_QUOTATION:
			return { ...state };
		case CLEAR_DATA:
			return {
				...state,
				quotation: action.payload,
				getQuotationLoading: true,
				getQuotationsLoading: true,
			};

		case DELETE_QUOTATION:
			return { ...state };

		default:
			return state;
	}
};
