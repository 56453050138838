import { faEdit, faHome, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import ReactPaginate from 'react-paginate';
import { deleteLocations, getLocationsWithFilter } from '../../../../actions/Construction/locationsActions';
import { clearData } from '../../../../actions/globalActions';
import IndexTable from '../../../../components/IndexTable';
import onDelete from '../../../../helpers/deleteFunction';
import { indexTableFilterQueryGenerator } from '../../../../helpers/helpers';
import { Routes } from '../../../../routes';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const LocationsIndex = (props) => {
	const { locations, loading } = props;
	const { t } = useTranslation();
	const [deleted, setDeleted] = useState(false);
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [query, setQuery] = useState('');
	const [order, setOrder] = useState('');
	const [search, setSearch] = useState('');
	const initialFilterCategories = [
		{ label: t('company'), displayField: null, value: 'company.company_name', queryName: t('company') },
		{ label: t('status'), displayField: null, value: 'status', queryName: t('status') },
	];

	const [filters, setFilters] = useState([]);
	const [filterCategories, setFilterCategories] = useState(initialFilterCategories);

	useEffect(() => {
		props.getLocationsWithFilter(offset, limit, query, order, search);
		return () => {
			props.clearData();
		};
	}, [deleted, limit, offset, query, order, search]);

	useEffect(() => {
		indexTableFilterQueryGenerator(filters, setQuery);
	}, [filters.length]);

	const handlePageClick = (event) => {
		setOffset(event.selected);
	};

	const renderLocations = () => {
		return locations.map((val, index) => {
			if (index != locations.length - 1) {
				return (
					<tr key={index}>
						<td className="fw-bold text-center">{index + 1}</td>
						<td className="fw-bold text-center">{val.building_name}</td>
						<td className="fw-bold text-center">{_.toString(_.map(val.construction_floors, 'floor_name'))}</td>
						<td className="fw-bold text-center">
							{val.construction_floors.map((element, index) => {
								return ` ${_.toString(_.map(element.construction_places, 'place_name'))} ${
									index == val.construction_floors.length - 1 ? '' : '/'
								}`;
							})}
						</td>
						<td className="fw-bold text-center">
							<button
								onClick={() => {
									onDelete(deleted, setDeleted, props.deleteLocations, val.building_id);
								}}
								className="btn btn-danger mx-2">
								<FontAwesomeIcon icon={faTrash} />
							</button>
							<Link
								to={Routes.Construction.Locations.Edit.path.replace(':id', val.building_id)}
								className="btn btn-primary mx-2">
								<FontAwesomeIcon icon={faEdit} />
							</Link>
						</td>
					</tr>
				);
			} else {
				return null;
			}
		});
	};
	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0 ">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{t('locations')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>

				<div
					className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-5
          ">
					<h4 style={{ display: 'inline' }}>{t('locations')}</h4>
					<Link className="btn btn-primary py-2 px-3  " to={Routes.Construction.Locations.Create.path}>
						{t('add-new')}{' '}
					</Link>
				</div>
				<p className="mb-0">{t('manage-your-locations-for-construction-projects')}</p>
			</div>

			<IndexTable
				loading={loading}
				searchable
				setSearch={setSearch}
				headerProps="text-center"
				headers={['#', t('building'), t('floor'), t('place'), '']}
				renderRows={locations.length > 1 ? renderLocations() : null}
				//FILTER KOY
				pagination={
					locations.length > 1 ? (
						<ReactPaginate
							nextLabel=" >"
							onPageChange={handlePageClick}
							pageCount={Math.ceil(_.last(locations).page_count)}
							pageRangeDisplayed={3}
							previousLabel="< "
							pageClassName="page-item"
							pageLinkClassName="page-link"
							previousClassName="page-item"
							forcePage={offset}
							previousLinkClassName="page-link"
							nextClassName="page-item"
							nextLinkClassName="page-link"
							breakLabel="..."
							breakClassName="page-item"
							breakLinkClassName="page-link"
							containerClassName="pagination"
							activeClassName="active"
							renderOnZeroPageCount={null}
						/>
					) : null
				}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		locations: state.construction.locations.locations,
		loading: state.construction.getlocationsLoading,
	};
};

export default connect(mapStateToProps, {
	getLocationsWithFilter,
	clearData,
	deleteLocations,
})(LocationsIndex);
