import {
	GET_DEPARTMENTS,
	CREATE_DEPARTMENT,
	GET_DEPARTMENT,
	UPDATE_DEPARTMENT,
	DELETE_DEPARTMENT,
} from '../../actions/Admin/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	departments: [],
	department: {},
	getDepartmentsLoading: true,
	getDepartmentLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_DEPARTMENTS:
			return { ...state, departments: action.payload, getDepartmentsLoading: false };
		case CREATE_DEPARTMENT:
			return { ...state, department: action.payload };
		case GET_DEPARTMENT:
			return { ...state, department: action.payload, getDepartmentLoading: false };
		case UPDATE_DEPARTMENT:
			return { ...state };
		case CLEAR_DATA:
			return {
				...state,
				department: action.payload,
				getDepartmentLoading: true,
				getDepartmentsLoading: true,
			};

		case DELETE_DEPARTMENT:
			return { ...state };
		default:
			return state;
	}
};
