import { GET_NOTIFICATIONS, GET_RECENT_NOTIFICATIONS } from '../../actions/User/types';
import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	recentNofitications: [],
	notifications: [],
	getRecentNotificationsLoading: true,
	getNotificationsLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_NOTIFICATIONS:
			return {
				...state,
				notifications: action.payload,
				getNotificationsLoading: false,
			};
		case GET_RECENT_NOTIFICATIONS:
			return {
				...state,
				recentNofitications: action.payload,
				getRecentNotificationsLoading: false,
			};
		case CLEAR_DATA:
			return {
				...state,
				recentNofitications: action.payload,
				getRecentNotificationsLoading: true,
			};

		default:
			return state;
	}
};
