import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import ItemForm from './ItemForm';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import { clearData } from '../../../../../actions/globalActions';
import { getItem, updateItem } from '../../../../../actions/Delivery/deliveryActions';
import { getCategories } from '../../../../../actions/Delivery/deliveryActions';

const ItemEdit = (props) => {
	const { id } = props.match.params;
	const { categories, loading, item } = props;

	useEffect(() => {
		props.getItem(id);
		props.getCategories();
		return () => {
			props.clearData();
		};
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			props.updateItem(formValues);
		}
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<ItemForm onSubmit={onSubmit} categories={categories} loading={loading} initials={item} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.delivery.item.getItemLoading,
		categories: state.delivery.item.categories,
		item: state.delivery.item.item,
	};
};

export default connect(mapStateToProps, { updateItem, getItem, clearData, getCategories })(ItemEdit);
