import { Form, InputGroup } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React from 'react';

export default ({
	input,
	label,
	placeholder,
	meta,
	inputType,
	onChange,
	value,
	className,
	disabled,
	inputProps,
	icon,
}) => {
	return (
		<Form.Group className={`${className} ${icon ? 'pe-1' : null}`}>
			{label ? <Form.Label>{label}</Form.Label> : null}
			<InputGroup>
				{icon ? (
					<InputGroup.Text>
						<FontAwesomeIcon icon={icon} />
					</InputGroup.Text>
				) : null}
				<Form.Control
					disabled={disabled}
					onChange={onChange}
					type={inputType}
					value={value}
					as="input"
					{...input}
					{...inputProps}
					placeholder={placeholder}
				/>
			</InputGroup>
			<span className="text-danger">{meta.touched && meta.error ? meta.error : null}</span>
		</Form.Group>
	);
};
