import { useCallback } from 'react';
import { Card } from 'react-bootstrap';
import { Handle, Position } from 'react-flow-renderer';

import endIcon from '../../../../../img/end-icon.png';

const EndNode = ({ data }) => {
	const onChange = useCallback((evt) => {
		data.onChange(evt.target.value);
	}, []);

	return (
		<Card style={{ width: '500px', borderRadius: '0px' }}>
			<Handle type="target" position={Position.Top} />
			<div
				className="fw-bold d-flex align-items-center justify-content-between text-white "
				style={{ backgroundColor: '#e2231a' }}>
				<div className="d-flex align-items-center">
					<div className="d-flex justify-content-center align-items-center" style={{ width: '50px', height: '50px' }}>
						<img style={{ width: '30px', height: '30px' }} src={endIcon} />
					</div>
					<span className="ms-2 fs-5">{data.label}</span>
				</div>
			</div>
		</Card>
	);
};

export default EndNode;
