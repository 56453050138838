import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import ProcurementItemForm from './ProcurementItemForm';

import { createItem } from '../../../../actions/Procurement/itemActions';
const ProcurementItemCreate = (props) => {
	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.createItem(formValues);
		}
	};
	return (
		<div className="mt-3">
			<ProcurementItemForm onSubmit={onSubmit} />
		</div>
	);
};
const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps, { createItem })(ProcurementItemCreate);
