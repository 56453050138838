import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import QuotationForm from './QuotationForm';
import { getQuotation, updateQuotation } from '../../../../actions/Procurement/quotationActions';
import { getSuppliers } from '../../../../actions/Procurement/suppliersActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { clearData } from '../../../../actions/globalActions';

const QuotationEdit = (props) => {
	const { id } = props.match.params;

	const { datas, loading } = props;

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.updateQuotation(formValues);
		}
	};

	if (!loading) {
		datas.quotation.isr.isr_contents.map((content, index) => {
			const commonContent = _.find(datas.quotation.quotation_contents, { description: content.description });

			if (commonContent) {
				content.unit_price = commonContent.unit_price;
				content.amount = commonContent.amount;
				content.id = commonContent.id;
			} else {
				content.id = null;
			}
		});
	}

	useEffect(() => {
		props.getQuotation(id);
		props.getSuppliers();

		return () => {
			props.clearData();
		};
	}, []);

	return (
		<div className="mt-3">
			{loading ? (
				<LoadingSpinner />
			) : (
				<QuotationForm onSubmit={onSubmit} initials={datas.quotation} isrId={datas.quotation.isr_id} datas={datas} />
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	const {
		procurement: {
			supplier: { suppliers, getSuppliersLoading },
			quotation: { quotation, getQuotationLoading },
		},
	} = state;

	return {
		datas: { suppliers, quotation },
		loading: getSuppliersLoading || getQuotationLoading,
	};
};

export default connect(mapStateToProps, { getQuotation, getSuppliers, updateQuotation, clearData })(QuotationEdit);
