import React from 'react';
import { Card, Row, Col, Accordion, Image } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import history from '../../../history';
import { Routes } from '../../../routes';
import Velocity from '../../../img/velocity.svg';

const InsightsCard = ({ cardName, desc, link, imageName }) => {
	return (
		<>
			<Card
				onClick={() => {
					history.push(link);
				}}
				style={{ cursor: 'pointer' }}>
				<Image src={imageName || Velocity} style={{ maxWidth: '300px', alignSelf: 'center' }}></Image>
				<Card.Header>
					<b>{cardName}</b>
				</Card.Header>
				<Card.Body>
					<Card.Text className="">{desc}</Card.Text>
				</Card.Body>
			</Card>
		</>
	);
};

export default InsightsCard;
