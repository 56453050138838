import React from "react";
import SharepointIcon from "../assets/img/icons/microsoft-sharepoint.svg";
const SharepointCard = ({ documentName, documentLink }) => {
  return (
    <li className="document-card">
      <a href={documentLink} rel="noreferrer" target="_blank">
        <i className="far fa-file-alt"></i>
        <p>
          <b>{documentName}</b>
        </p>
      </a>
    </li>
  );
};
export default SharepointCard;
