import { useTranslation } from 'react-i18next';

export default () => {
	const onDragStart = (event, nodeType, label, id) => {
		event.dataTransfer.setData('application/reactflow/nodeType', nodeType);
		event.dataTransfer.setData('application/reactflow/label', label);
		event.dataTransfer.setData('application/reactflow/nodeId', id);
		event.dataTransfer.effectAllowed = 'move';
	};
	const { t } = useTranslation();

	return (
		<aside className="d-flex flex-column bg-black">
			<div className="fw-bolder text-white">{t('you-can-drag-these-nodes-to-the-pane-on-the-left')}</div>
			<div
				className="btn btn-secondary"
				style={{ borderRadius: '0px' }}
				onDragStart={(event) =>
					onDragStart(event, 'actionCreateNode', t('when-form-is-created-updated'), 'action_create')
				}
				draggable>
				{t('when-form-is-created-updated-0')}{' '}
			</div>
			<div
				className="btn btn-secondary"
				style={{ borderRadius: '0px' }}
				onDragStart={(event) => onDragStart(event, 'approvalNode', t('approval'), 'approval')}
				draggable>
				{t('approval-0')}{' '}
			</div>
			<div
				className="btn btn-secondary"
				style={{ borderRadius: '0px' }}
				onDragStart={(event) => onDragStart(event, 'notificationNode', t('notification'), 'notification')}
				draggable>
				{t('notification-0')}{' '}
			</div>

			<div
				className="btn btn-secondary"
				style={{ borderRadius: '0px' }}
				onDragStart={(event) => onDragStart(event, 'endNode', t('end'), 'end')}
				draggable>
				{t('end-0')}{' '}
			</div>
		</aside>
	);
};
