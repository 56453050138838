import React from 'react';
import { connect } from 'react-redux';
import LocationForm from './LocationForm';
import { createLocation } from '../../../../../actions/Delivery/locationActions';

const LocationCreate = (props) => {
	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.createLocation(formValues);
		}
	};

	return (
		<div className="mt-3">
			<LocationForm onSubmit={onSubmit} />
		</div>
	);
};

export default connect(null, { createLocation })(LocationCreate);
