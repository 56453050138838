import { Card, Col, Row } from '@themesberg/react-bootstrap';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, Field, FieldArray, initialize, reduxForm } from 'redux-form';
import renderInput from '../../../../../components/formComponents/renderInput';
import SubmitButton from '../../../../../components/formComponents/SubmitButton';
import validate from '../../../../../validate';
import renderFloorRoomTable from './formComponents/renderFloorRoomTable';

const LocationForm = (props) => {
	const { onSubmit, initials } = props;
	const { t } = useTranslation();

	if (initials) {
		const arr = initials.floors.map((floor, index) => {
			return { floor_name: floor.floor_name, rooms: _.map(floor.rooms, 'room_name') };
		});
	}

	useEffect(() => {
		if (initials) {
			props.initialize({
				...initials,
				floor_room: [
					...initials.floors.map((floor, index) => {
						return { floor_name: floor.floor_name, rooms: _.map(floor.rooms, 'room_name') };
					}),
				],
			});
		} else {
		}
	}, []);

	return (
		<form onSubmit={props.handleSubmit(onSubmit)}>
			<Row>
				<Col md={12}>
					<Card border="light" className="bg-white shadow-sm mb-4  w-100">
						<Card.Body>
							<h5 className="mb-4">{t('delivery-location-form')}</h5>
							<Row className="mb-3 ">
								<Col md={6} sm={12}>
									<Field name="building_name" label={t('building')} component={renderInput} />
								</Col>
							</Row>
							<Row>
								<Col className="mb-3">
									<table className="table overflow-auto formTable w-100 ">
										<thead>
											<tr>
												<th className="text-center">{t('floor')}</th>
												<th className="text-center">{t('room')}</th>
											</tr>
										</thead>
										<tbody>
											<FieldArray name="floor_room" component={renderFloorRoomTable} />
										</tbody>
									</table>
								</Col>
							</Row>

							<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
								<a onClick={() => window.history.back()} className="btn btn-secondary">
									{t('back')}{' '}
								</a>
								<SubmitButton onSubmit={onSubmit} text={t('submit')} invalid={false} />
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</form>
	);
};
export default compose(
	connect(null, { change, initialize }),
	reduxForm({ form: 'deliverAddress', validate })
)(LocationForm);
