import {
	CREATE_WARNING_LETTER,
	GET_WARNING_LETTERS,
	GET_WARNING_LETTER,
	DELETE_WARNING_LETTER,
} from '../../actions/HumanResources/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	warningLetters: [],
	warningLetter: {},
	loading: true,
	getWarningLetterLoading: true,
	getWarningLettersLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_WARNING_LETTERS:
			return { ...state, warningLetters: action.payload, getWarningLettersLoading: false };

		case GET_WARNING_LETTER:
			return { ...state, warningLetter: action.payload, getWarningLetterLoading: false };

		case CREATE_WARNING_LETTER:
			return { ...state };

		case CLEAR_DATA:
			return {
				...state,
				warningLetter: action.payload,
				getWarningLetterLoading: true,
				getWarningLettersLoading: true,
			};

		case DELETE_WARNING_LETTER:
			return { ...state };

		default:
			return state;
	}
};
