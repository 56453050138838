export const GET_HR_EMPLOYEE_EVALUATIONS = 'GET_HR_EMPLOYEE_EVALUATIONS';
export const GET_HR_EMPLOYEE_EVALUATION = 'GET_HR_EMPLOYEE_EVALUATION';
export const CREATE_HR_EMPLOYEE_EVALUATION = 'CREATE_HR_EMPLOYEE_EVALUATION';
export const EDIT_HR_EMPLOYEE_EVALUATION = 'EDIT_HR_EMPLOYEE_EVALUATION';
export const DELETE_HR_EMPLOYEE_EVALUATION = 'DELETE_HR_EMPLOYEE_EVALUATION';
export const UPDATE_HR_EMPLOYEE_EVALUATION = 'UPDATE_HR_EMPLOYEE_EVALUATION';

export const GET_SALARY_DEDUCTION = 'GET_SALARY_DEDUCTION';
export const GET_SALARY_DEDUCTIONS = 'GET_SALARY_DEDUCTIONS';
export const CREATE_SALARY_DEDUCTION = 'CREATE_SALARY_DEDUCTION';
export const UPDATE_SALARY_DEDUCTION = 'UPDATE_SALARY_DEDUCTION';
export const DELETE_SALARY_DEDUCTION = 'DELETE_SALARY_DEDUCTION';

export const GET_SALARY_INCREMENT = 'GET_SALARY_INCREMENT';
export const GET_SALARY_INCREMENTS = 'GET_SALARY_INCREMENTS';
export const CREATE_SALARY_INCREMENT = 'CREATE_SALARY_INCREMENT';
export const UPDATE_SALARY_INCREMENT = 'UPDATE_SALARY_INCREMENT';
export const DELETE_SALARY_INCREMENT = 'DELETE_SALARY_INCREMENT';

export const GET_START_WORK = 'GET_START_WORK';
export const GET_START_WORKS = 'GET_START_WORKS';
export const CREATE_START_WORK = 'CREATE_START_WORK';
export const UPDATE_START_WORK = 'UPDATE_START_WORK';
export const DELETE_START_WORK = 'DELETE_START_WORK';

export const GET_WARNING_LETTER = 'GET_WARNING_LETTER';
export const GET_WARNING_LETTERS = 'GET_WARNING_LETTERS';
export const CREATE_WARNING_LETTER = 'CREATE_WARNING_LETTER';
export const UPDATE_WARNING_LETTER = 'UPDATE_WARNING_LETTER';
export const DELETE_WARNING_LETTER = 'DELETE_WARNING_LETTER';

export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const GET_EMPLOYEE = 'GET_EMPLOYEE';
export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';

export const GET_CLEARANCE = 'GET_CLEARANCE';
export const GET_CLEARANCES = 'GET_CLEARANCES';
export const CREATE_CLEARANCE = 'CREATE_CLEARANCE';
export const UPDATE_CLEARANCE = 'UPDATE_CLEARANCE';
export const DELETE_CLEARANCE = 'DELETE_CLEARANCE';

export const GET_CASH_ADVANCE_REQUEST = 'GET_CASH_ADVANCE_REQUEST';
export const GET_CASH_ADVANCE_REQUESTS = 'GET_CASH_ADVANCE_REQUESTS';
export const CREATE_CASH_ADVANCE_REQUEST = 'CREATE_CASH_ADVANCE_REQUEST';
export const UPDATE_CASH_ADVANCE_REQUEST = 'UPDATE_CASH_ADVANCE_REQUEST';
export const DELETE_CASH_ADVANCE_REQUEST = 'DELETE_CASH_ADVANCE_REQUEST';

export const GET_LEAVE = 'GET_LEAVE';
export const GET_LEAVES = 'GET_LEAVES';
export const CREATE_LEAVE = 'CREATE_LEAVE';
export const UPDATE_LEAVE = 'UPDATE_LEAVE';
export const DELETE_LEAVE = 'DELETE_LEAVE';

export const GET_RESIGNATION = 'GET_RESIGNATION';
export const GET_RESIGNATIONS = 'GET_RESIGNATIONS';
export const CREATE_RESIGNATION = 'CREATE_RESIGNATION';
export const UPDATE_RESIGNATION = 'UPDATE_RESIGNATION';
export const DELETE_RESIGNATION = 'DELETE_RESIGNATION';

export const GET_TERMINATION = 'GET_TERMINATION';
export const GET_TERMINATIONS = 'GET_TERMINATIONS';
export const CREATE_TERMINATION = 'CREATE_TERMINATION';
export const UPDATE_TERMINATION = 'UPDATE_TERMINATION';
export const DELETE_TERMINATION = 'DELETE_TERMINATION';
