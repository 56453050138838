import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ProjectMilestoneForm from './ProjectMilestoneForm';
import { getProject } from '../../../../actions/Finance/projectActions';
import { createProjectMilestone } from '../../../../actions/Finance/projectMilestoneActions';

import LoadingSpinner from '../../../../components/LoadingSpinner';
import _ from 'lodash';

const ProjectMilestoneCreate = (props) => {
	const project_id = props.match.params.project_id;

	const { datas, loading } = props;

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.createProjectMilestone(formValues);
		}
	};

	useEffect(() => {
		props.getProject(project_id);
	}, []);
	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<ProjectMilestoneForm onSubmit={onSubmit} datas={datas} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		finance: {
			project: { project, getProjectLoading },
		},
	} = state;

	return { datas: { project }, loading: getProjectLoading };
};

export default connect(mapStateToProps, { getProject, createProjectMilestone })(ProjectMilestoneCreate);
