import { SAVE_ORDER, GET_ORDERS, GET_ORDER } from '../../actions/Delivery/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	order: [],
	orders: [],
	getOrdersLoading: true,
	orderInfo: {},
	getOrderLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SAVE_ORDER:
			return { ...state, order: action.payload };
		case GET_ORDERS:
			return { ...state, orders: action.payload, getOrdersLoading: false };
		case GET_ORDER:
			return { ...state, orderInfo: action.payload, getOrderLoading: false };
		default:
			return state;
	}
};
