import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getEntity, updateEntity } from '../../../../actions/Admin/entityActions';
import EntitiesForm from './EntitiesForm';

import LoadingSpinner from '../../../../components/LoadingSpinner';

const EntitiesEdit = (props) => {
	const { id } = props.match.params;
	const { company, loading } = props;

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			props.updateEntity(formValues);
		}
	};

	useEffect(() => {
		props.getEntity(id);
	}, []);

	if (loading) {
		return <LoadingSpinner />;
	} else {
		return (
			<div className="mt-3">
				<EntitiesForm onSubmit={onSubmit} initials={{ entityName: company.company_name, id: id }} />
			</div>
		);
	}
};

const mapStateToProps = (state) => {
	return {
		company: state.admin.entity.entity,
		loading: state.admin.entity.getEntityLoading,
	};
};

export default connect(mapStateToProps, { getEntity, updateEntity })(EntitiesEdit);
