import {
	CREATE_DISCIPLINE,
	DELETE_DISCIPLINE,
	GET_DISCIPLINE,
	GET_DISCIPLINES,
} from '../../actions/Construction/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	disciplines: [],
	discipline: {},
	loading: true,
	getDisciplineLoading: true,
	getDisciplinesLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_DISCIPLINES:
			return { ...state, disciplines: action.payload, getDisciplinesLoading: false };

		case GET_DISCIPLINE:
			return { ...state, discipline: action.payload, getDisciplineLoading: false };

		case CREATE_DISCIPLINE:
			return { ...state, discipline: action.payload };

		case CLEAR_DATA:
			return {
				...state,
				discipline: action.payload,
				getDisciplineLoading: true,
				getDisciplinesLoading: true,
			};
		case DELETE_DISCIPLINE:
			return { ...state };
		default:
			return state;
	}
};
