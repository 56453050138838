import React, { Component } from 'react';
import { createCategory } from '../../../../../actions/Delivery/deliveryActions';
import { connect } from 'react-redux';
import CategoryForm from './CategoryForm';

class CategoryCreate extends Component {
	onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			this.props.createCategory(formValues);
		}
	};
	render() {
		return (
			<div className="mt-3">
				<CategoryForm onSubmit={this.onSubmit} />
			</div>
		);
	}
}

export default connect(null, { createCategory })(CategoryCreate);
