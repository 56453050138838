//TICKET
export const CREATE_TICKET = 'CREATE_TICKET';
export const GET_TICKETS = 'GET_TICKETS';
export const GET_TICKET = 'GET_TICKET';
export const DELETE_TICKET = 'DELETE_TICKET';
export const UPDATE_TICKET = 'UPDATE_TICKET';
export const GET_TICKETS_BELONG_TO_USER = 'GET_TICKETS_BELONG_TO_USER';
export const GET_TICKETS_ASSIGNED = 'GET_TICKETS_ASSIGNED';
export const COMPLETE_TICKET = 'COMPLETE_TICKET';
export const RESOLVE_TICKET = 'RESOLVE_TICKET';
export const REDIRECT_TICKET = 'REDIRECT_TICKET';
export const ESCALATE_TICKET = 'ESCALATE_TICKET';
export const GET_TICKET_CATEGORIES = 'GET_TICKET_CATEGORIES';
