import { Card, Col, Row } from '@themesberg/react-bootstrap';
import React, { useEffect } from 'react';
import { change, Field, initialize, reduxForm } from 'redux-form';

import renderInput from '../../../../../components/formComponents/renderInput';
import SubmitButton from '../../../../../components/formComponents/SubmitButton';
import validate from '../../../../../validate';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { clearData } from '../../../../../actions/globalActions';
import { useTranslation } from 'react-i18next';

const CategoryForm = (props) => {
	const { initials, onSubmit, invalid } = props;
	const { t } = useTranslation();

	useEffect(() => {
		props.initialize(initials);

		return () => {
			props.clearData();
		};
	}, []);

	useEffect(() => {
		props.change('isValid', !invalid);
	}, [invalid]);

	return (
		<form onSubmit={props.handleSubmit(onSubmit)}>
			<Row>
				<Col xl={8} lg={8} md={12} sm={12} xs={12}>
					<Card border="light" className="bg-white shadow-sm mb-4  w-100">
						<Card.Body>
							<h5 className="mb-4">{t('category-form')}</h5>
							<Row className="">
								<Col className="mb-3">
									<Field name="category_name" label={t('category-name')} component={renderInput} />
								</Col>
							</Row>
							<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
								<a onClick={() => window.history.back()} className="btn btn-secondary">
									{t('back')}{' '}
								</a>
								<SubmitButton invalid={invalid} onSubmit={onSubmit} text={t('submit')} />
							</div>
						</Card.Body>
					</Card>
				</Col>
				<Col xl={4} lg={4} md={12} sm={12} xs={12}></Col>
			</Row>
		</form>
	);
};

export default compose(
	connect(null, { clearData, initialize, change }),
	reduxForm({
		form: 'item-category',
		validate,
	})
)(CategoryForm);
