import React, { Component, useEffect, useState } from 'react';
import { Breadcrumb, Col, Row, Toast, Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEdit,
	faEye,
	faHome,
	faHourglass,
	faPencilAlt,
	faTrash,
	faUser,
	faWarehouse,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactPaginate from 'react-paginate';
import onDelete from '../../../helpers/deleteFunction';
import { clearData } from '../../../actions/globalActions';
import { Routes } from '../../../routes';
import IndexTable from '../../../components/IndexTable';
import TableFilter from '../../../components/TableFilter';
import { getCashFlowTags, deleteCashFlow, getCashFlowsWithFilter } from '../../../actions/CashFlow/cashFlowActions';
import { indexTableFilterQueryGenerator } from '../../../helpers/helpers';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const CashFlowIndex = (props) => {
	useEffect(() => {
		props.getCashFlowTags();
		return () => {
			props.clearData();
		};
	}, []);
	const { cashflows, loading, cashflowTags } = props;
	const tags = [];
	cashflowTags.map((tag) => {
		tags.push({ tags: tag });
	});
	const { t } = useTranslation();
	const [deleted, setDeleted] = useState(false);
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [query, setQuery] = useState('');
	const [order, setOrder] = useState('');
	const [search, setSearch] = useState('');

	const [filters, setFilters] = useState([]);
	const initialFilterCategories = [{ label: t('tags'), displayField: 'tags', value: 'tags', queryName: t('tags') }];
	console.log('FILTERS', filters);
	const [filterCategories, setFilterCategories] = useState(initialFilterCategories);
	useEffect(() => {
		console.log('here');
		props.getCashFlowsWithFilter(offset, limit, query, order, search);
		return () => {
			props.clearData();
		};
	}, [deleted, limit, offset, query, order, search]);
	useEffect(() => {
		indexTableFilterQueryGenerator(filters, setQuery);
	}, [filters.length]);

	const handlePageClick = (event) => {
		setOffset(event.selected);
	};

	const renderCashFlows = () => {
		return cashflows.map((val, index) => {
			if (index != cashflows.length - 1) {
				/*BURADAKİ ŞEYİ SOR*/
				return (
					<tr key={index}>
						<td className="fw-bold text-center">{index + 1}</td>
						<td className="fw-bold text-center">{val.title}</td>
						<td className="fw-bold text-center">{val.category}</td>
						<td className="fw-bold text-center">{val.total + ' ' + val.currency}</td>
						<td className="fw-bold text-center">{val.tags}</td>
						<td className="fw-bold text-center">{new Date(val.date).toLocaleDateString()}</td>
						<td className="fw-bold text-center">
							<button
								onClick={() => {
									onDelete(deleted, setDeleted, props.deleteCashFlow, val.cash_flow_id);
								}}
								className="btn btn-danger mx-2">
								<FontAwesomeIcon icon={faTrash} />
							</button>
							<Link to={Routes.CashFlow.Details.path.replace(':id', val.cash_flow_id)} className="btn btn-primary mx-2">
								<FontAwesomeIcon icon={faEye} />
							</Link>
							<Link to={Routes.CashFlow.Edit.path.replace(':id', val.cash_flow_id)} className="btn btn-primary mx-2">
								<FontAwesomeIcon icon={faEdit} />
							</Link>
						</td>
					</tr>
				);
			} else {
				return null;
			}
		});
	};
	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0 ">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{t('cash-flow')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>

				<div
					className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-5
          ">
					<h4 style={{ display: 'inline' }}>{t('cash-flow')}</h4>
					<Link className="btn btn-primary py-2 px-3  " to={Routes.CashFlow.Create.path}>
						{t('add-new')}{' '}
					</Link>
				</div>
				<p className="mb-0">{t('manage-your-latest-trades-here')}</p>
			</div>

			<IndexTable
				loading={loading}
				filter={
					<div className="align-self-center">
						<TableFilter
							initialFilterCategories={initialFilterCategories}
							filters={filters}
							setFilters={setFilters}
							datas={tags}
							setQuery={setQuery}
							filterCategories={filterCategories}
							setFilterCategories={setFilterCategories}
						/>
					</div>
				}
				searchable
				setSearch={setSearch}
				headerProps="text-center"
				headers={['#', t('title'), t('category'), t('price'), t('tags'), t('date-0'), '']}
				renderRows={cashflows.length > 1 ? renderCashFlows() : null}
				//FILTER KOY
				pagination={
					cashflows.length > 1 ? (
						<ReactPaginate
							nextLabel=" >"
							onPageChange={handlePageClick}
							pageCount={Math.ceil(_.last(cashflows).page_count)}
							pageRangeDisplayed={3}
							previousLabel="< "
							pageClassName="page-item"
							pageLinkClassName="page-link"
							previousClassName="page-item"
							forcePage={offset}
							previousLinkClassName="page-link"
							nextClassName="page-item"
							nextLinkClassName="page-link"
							breakLabel="..."
							breakClassName="page-item"
							breakLinkClassName="page-link"
							containerClassName="pagination"
							activeClassName="active"
							renderOnZeroPageCount={null}
						/>
					) : null
				}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		cashflows: state.cashFlow.cashflows,
		cashflowTags: state.cashFlow.cashflowTags,
		loading: state.cashFlow.getCashFlowsLoading,
	};
};

export default connect(mapStateToProps, {
	getCashFlowsWithFilter,
	clearData,
	deleteCashFlow,
	getCashFlowTags,
})(CashFlowIndex);
