import _ from 'lodash';
import DropdownList from 'react-widgets/DropdownList';

export const renderCategoryDropdown = ({ input, data, dataKey, textField }) => {
	return (
		<DropdownList
			{...input}
			onBlur={() => input.onBlur()}
			onChange={(selected) => {
				input.onChange(selected);
			}}
			value={input.value || ''}
			data={data}
			textField={textField || ''}
			dataKey={dataKey}
			allowCreate="onFilter"
			onCreate={(value) => {
				input.onChange({ item_category_name: value });
			}}
		/>
	);
};

export const renderItemDropdown = ({ input, data, dataKey, textField, change }) => {
	return (
		<DropdownList
			{...input}
			onBlur={() => input.onBlur()}
			onChange={(selected) => {
				input.onChange(selected);
				change(`${input.name.split('.')[0]}.description`, selected.item_name);
			}}
			value={input.value || ''}
			data={data}
			textField={textField || ''}
			dataKey={dataKey}
			allowCreate="onFilter"
			onCreate={(value) => {
				input.onChange({ item_name: value });
				change(`${input.name.split('.')[0]}.description`, value);
			}}
		/>
	);
};
