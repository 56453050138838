import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Card, Col, Row } from '@themesberg/react-bootstrap';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { change, Field, initialize, reduxForm } from 'redux-form';
import renderCheckbox from '../../../../components/formComponents/renderCheckbox';
import renderDropdown from '../../../../components/formComponents/renderDropdown';
import renderDropdownWithAutoComplete from '../../../../components/formComponents/renderDropdownWithAutoComplete';
import renderInput from '../../../../components/formComponents/renderInput';
import renderTextArea from '../../../../components/formComponents/renderTextArea';
import SubmitButton from '../../../../components/formComponents/SubmitButton';
import { Routes } from '../../../../routes';
import validate from '../../../../validate';

const LeaveForm = (props) => {
	const { initials, datas, onSubmit, full_name, user_id, companies } = props;
	const { t } = useTranslation();

	useEffect(() => {
		if (initials) {
			props.initialize({
				...initials,
				by: full_name,
				job_title: initials.employee.job_title,
				site: initials.employee.site,
				department: _.find(datas.employees, { employee_id: initials.employee_id }).department.department_name,
				leave_start_date: new Date(initials.leave_start_date).toISOString().split('T')[0],
				leave_end_date: new Date(initials.leave_end_date).toISOString().split('T')[0],
				expected_date_to_rejoin: new Date(initials.expected_date_to_rejoin).toISOString().split('T')[0],
			});
		} else {
			props.initialize({
				by: full_name,
				company_id: companies[0].company_id,
				date_of_last_work: new Date().toISOString().split('T')[0],
			});
		}
		props.change('user_id', user_id);
	}, []);

	return (
		<>
			<div className="d-block mb-4 mb-xl-0 ">
				<Breadcrumb
					className=" d-block"
					listProps={{
						className: 'breadcrumb-dark breadcrumb-transparent',
					}}>
					<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
						<FontAwesomeIcon icon={faHome} />
					</Breadcrumb.Item>
					<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.HumanResources.Overview.path }}>
						{t('human-resources')}{' '}
					</Breadcrumb.Item>
					<Breadcrumb.Item active>{t('leave-requests-0')}</Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<form onSubmit={props.handleSubmit(onSubmit)}>
				<Row>
					<Col md={12}>
						<Card border="light" className="bg-white shadow-sm mb-4 w-100">
							<Card.Body>
								<h5 className="mb-4">{t('leave-request-form')}</h5>
								<Row className="mb-3">
									<Col md={6} sm={12}>
										<Field name="by" label={t('by')} component={renderInput} disabled="disabled" />
									</Col>
									<Col md={6} sm={12}>
										<Field
											data={companies}
											dataKey="company_id"
											textField="company_name"
											name="company_id"
											label={t('company')}
											component={renderDropdownWithAutoComplete}
										/>
									</Col>
								</Row>
								<hr />
								<Row className="mb-3">
									<Col>
										<Field
											name="employee_id"
											label={t('employee')}
											dataKey="employee_id"
											textField="full_name"
											data={datas.employees}
											component={renderDropdownWithAutoComplete}
											onChange={(val) => {
												const selectedEmployee = _.find(datas.employees, { employee_id: val });
												props.change(
													'reference',
													`HR/008/${String(selectedEmployee.employee_number).padStart(3, '0')}/${
														new Date().getMonth() + 1
													}${new Date().getDate()}${new Date().getMilliseconds()}`
												);
												props.change('job_title', selectedEmployee.job_title);
												props.change('department', selectedEmployee.department.department_name);
												props.change('site', selectedEmployee.site);
											}}
										/>
									</Col>
									<Col>
										<Field name="job_title" disabled="disabled" component={renderInput} label={t('job-title')} />
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field name="reference" disabled="disabled" component={renderInput} label={t('reference')} />
									</Col>
									<Col>
										<Field name="department" disabled="disabled" component={renderInput} label={t('department-0')} />
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field name="site" disabled="disabled" component={renderInput} label={t('site')} />
									</Col>
									<Col></Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field
											name="leave_type"
											label={t('leave-type')}
											component={renderDropdown}
											renderedOptions={
												<>
													<option value={t('annual-leave')}>{t('annual-leave-0')}</option>
													<option value={t('business-trip')}>{t('business-trip-0')}</option>
													<option value={t('emergency-leave')}>{t('emergency-leave-0')}</option>
													<option value={t('unpaid-leave')}>{t('unpaid-leave-0')}</option>
													<option value={t('other')}>{t('other-0')}</option>
												</>
											}
										/>
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field
											name="leave_start_date"
											inputType="date"
											label={t('leave-start-date')}
											component={renderInput}
										/>
									</Col>
									<Col>
										<Field name="leave_end_date" inputType="date" label={t('leave-end-date')} component={renderInput} />
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field
											name="number_of_days"
											inputType="number"
											label={t('number-of-days-leave')}
											component={renderInput}
										/>
									</Col>
									<Col>
										<Field
											name="expected_date_to_rejoin"
											inputType="date"
											label={t('expected-date-to-rejoin')}
											component={renderInput}
										/>
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field
											name="local_address_po_box"
											inputType="number"
											label={t('local-address-po-box')}
											component={renderInput}
										/>
									</Col>
									<Col>
										<Field
											name="local_address_mobile_number"
											inputType="number"
											label={t('local-address-phone-number')}
											component={renderInput}
										/>
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field name="local_address_full_address" label={t('local-full-address')} component={renderInput} />
									</Col>
									<Col>
										<Field
											name="country_origin_airport"
											label={t('name-of-airport-destination')}
											component={renderInput}
										/>
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field
											name="country_origin_mobile_number"
											inputType="number"
											label={t('country-origin-phone-number')}
											component={renderInput}
										/>
									</Col>
									<Col>
										<Field
											name="country_origin_full_address"
											label={t('country-origin-full-address')}
											component={renderInput}
										/>
									</Col>
								</Row>

								<Row className="mb-3">
									<Col>
										<Field
											name="company_flight_ticket"
											val={true}
											component={renderCheckbox}
											label={t('company-provided-flight-ticket')}
										/>
									</Col>
									<Col>
										<Field
											name="employee_flight_ticket"
											val={true}
											component={renderCheckbox}
											label={t('employee-provided-flight-ticket')}
										/>
									</Col>
									<Col>
										<Field
											name="covid_vaccinated"
											val={true}
											component={renderCheckbox}
											label={t('fully-covid-19-vaccinated')}
										/>
									</Col>
									<Col>
										<Field
											name="vacation_history"
											val={true}
											component={renderCheckbox}
											label={t('vacation-history-attached')}
										/>
									</Col>
								</Row>

								<Row className="mb-3">
									<Col>
										<Field name="remarks" component={renderTextArea} label={t('remarks')} rows={3} />
									</Col>
								</Row>

								<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
									<Link to={Routes.HumanResources.Leave.Index.path} className="btn btn-secondary">
										{t('back')}{' '}
									</Link>
									<SubmitButton invalid={false} onSubmit={onSubmit} text={t('submit')} />
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</form>
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		auth: { full_name, user_id, companies },
	} = state;
	return {
		full_name,
		user_id,
		companies,
	};
};

export default compose(
	connect(mapStateToProps, { initialize, change }),
	reduxForm({ form: 'leave', validate })
)(LeaveForm);
