export const Policies = {
	ADMIN: {
		PAGE: 'ADMIN',
		VIEW: ['VIEW:ALL', 'VIEW:COMPANY', 'VIEW:SELF'],
		VIEW_ALL: 'VIEW:ALL',
		VIEW_COMPANY: 'VIEW:COMPANY',
		VIEW_SELF: 'VIEW:SELF',
		ADD: 'ADD',
		EDIT: 'EDIT',
		DELETE: 'DELETE',
		INVITE: 'INVITE',
	},
	ISR: {
		PAGE: 'ISR',
		VIEW: ['VIEW:ALL', 'VIEW:COMPANY', 'VIEW:SELF'],
		VIEW_ALL: 'VIEW:ALL',
		VIEW_COMPANY: 'VIEW:COMPANY',
		VIEW_SELF: 'VIEW:SELF',
		DELETE: 'DELETE',
		ADD: 'ADD',
		EDIT: 'EDIT',
	},
	SUPPLIER: {
		PAGE: 'SUPPLIER',
		VIEW: ['VIEW:ALL', 'VIEW:COMPANY', 'VIEW:SELF'],
		VIEW_ALL: 'VIEW:ALL',
		VIEW_COMPANY: 'VIEW:COMPANY',
		VIEW_SELF: 'VIEW:SELF',
		DELETE: 'DELETE',
		ADD: 'ADD',
		EDIT: 'EDIT',
	},
	LPO: {
		PAGE: 'LPO',
		VIEW: ['VIEW:ALL', 'VIEW:COMPANY', 'VIEW:SELF'],
		VIEW_ALL: 'VIEW:ALL',
		VIEW_COMPANY: 'VIEW:COMPANY',
		VIEW_SELF: 'VIEW:SELF',
		DELETE: 'DELETE',
		ADD: 'ADD',
		EDIT: 'EDIT',
	},
	FINANCE: {
		PAGE: 'FINANCE',
		VIEW: ['VIEW:ALL', 'VIEW:COMPANY', 'VIEW:SELF'],
		VIEW_ALL: 'VIEW:ALL',
		VIEW_COMPANY: 'VIEW:COMPANY',
		VIEW_SELF: 'VIEW:SELF',
		ADD: 'ADD',
		EDIT: 'EDIT',
		DELETE: 'DELETE',
	},
	PROJECT_MANAGEMENT: {
		PAGE: 'PROJECT_MANAGEMENT',
		VIEW: ['VIEW:ALL', 'VIEW:COMPANY', 'VIEW:SELF'],
		VIEW_ALL: 'VIEW:ALL',
		VIEW_COMPANY: 'VIEW:COMPANY',
		VIEW_SELF: 'VIEW:SELF',
		ADD: 'ADD',
		EDIT: 'EDIT',
		DELETE: 'DELETE',
	},
	HUMAN_RESOURCES: {
		PAGE: 'HUMAN_RESOURCES',
		VIEW: ['VIEW:ALL', 'VIEW:COMPANY', 'VIEW:SELF'],
		VIEW_ALL: 'VIEW:ALL',
		VIEW_COMPANY: 'VIEW:COMPANY',
		VIEW_SELF: 'VIEW:SELF',
		ADD: 'ADD',
		EDIT: 'EDIT',
		DELETE: 'DELETE',
	},
	TRANSPORTATION: {
		PAGE: 'TRANSPORTATION',
		VIEW: ['VIEW:ALL', 'VIEW:COMPANY', 'VIEW:SELF'],
		VIEW_ALL: 'VIEW:ALL',
		VIEW_COMPANY: 'VIEW:COMPANY',
		VIEW_SELF: 'VIEW:SELF',
		ADD: 'ADD',
		EDIT: 'EDIT',
		DELETE: 'DELETE',
	},
	IT_DEPARTMENT: {
		PAGE: 'IT_DEPARTMENT',
		VIEW: ['VIEW:ALL', 'VIEW:COMPANY', 'VIEW:SELF'],
		VIEW_ALL: 'VIEW:ALL',
		VIEW_COMPANY: 'VIEW:COMPANY',
		VIEW_SELF: 'VIEW:SELF',
		ADD: 'ADD',
		EDIT: 'EDIT',
		DELETE: 'DELETE',
	},
	DELIVERY: {
		PAGE: 'DELIVERY',
		VIEW: ['VIEW:ALL', 'VIEW:COMPANY', 'VIEW:SELF'],
		VIEW_ALL: 'VIEW:ALL',
		VIEW_COMPANY: 'VIEW:COMPANY',
		VIEW_SELF: 'VIEW:SELF',
		ADD: 'ADD',
		EDIT: 'EDIT',
		DELETE: 'DELETE',
	},
};
