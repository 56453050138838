import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';

import { createCashFlow, getCashFlowTags } from '../../../actions/CashFlow/cashFlowActions';
import { clearData } from '../../../actions/globalActions';

import LoadingSpinner from '../../../components/LoadingSpinner';
import CashFlowForm from './CashFlowForm';

const CashFlowCreate = (props) => {
	const { datas } = props;
	useEffect(() => {
		props.getCashFlowTags();
		return () => {
			props.clearData();
		};
	}, []);
	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			//AND ISVALID EKLEYECEKSIN
			props.createCashFlow(formValues);
		}
	};

	return (
		<>
			{0 ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<CashFlowForm tags={datas.cashflowTags} onSubmit={onSubmit} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		cashFlow: { cashflowTags },
	} = state;
	return {
		datas: { cashflowTags },
		movie: 'batman',
	};
};

export default connect(mapStateToProps, { createCashFlow, getCashFlowTags, clearData })(CashFlowCreate);
