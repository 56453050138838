import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import _ from 'lodash';
import { getLocation, updateLocations } from '../../../../actions/Construction/locationsActions';
import { clearData } from '../../../../actions/globalActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import LocationsForm from './LocationsForm';

const LocationsEdit = (props) => {
	const { id } = props.match.params;
	const { datas, loading } = props;
	useEffect(() => {
		props.getLocation(id);
		return () => {
			props.clearData();
		};
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			props.updateLocations(formValues);
		}
	};
	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<LocationsForm onSubmit={onSubmit} initials={datas.location} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		construction: {
			locations: { location, getLocationLoading },
		},
	} = state;

	return {
		datas: { location },
		loading: getLocationLoading,
	};
};

export default connect(mapStateToProps, {
	getLocation,
	clearData,
	updateLocations,
})(LocationsEdit);
