import React, { useEffect } from 'react';
import { Col, Row, Card, ListGroup, Table, Image } from '@themesberg/react-bootstrap';
import { connect } from 'react-redux';

import logo from '../../../../img/saportif.png';
import { getLPO } from '../../../../actions//Procurement/lpoActions';
import { clearData } from '../../../../actions/globalActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

const LPODetails = (props) => {
	const { id } = props.match.params;
	const { lpo, getLPOLoading } = props;

	const printRef = React.useRef();
	const { t } = useTranslation();

	// const handleDownloadPdf = async () => {
	// 	const element = printRef.current;
	// 	const canvas = await html2canvas(element);
	// 	const data = canvas.toDataURL('image/png');

	// 	const pdf = new jsPDF('p', 'mm', 'a4');
	// 	const imgProperties = pdf.getImageProperties(data);
	// 	const pdfWidth = pdf.internal.pageSize.getWidth();
	// 	const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

	// 	pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
	// 	pdf.save(`LPO_${uuidv4()}.pdf`);
	// };

	useEffect(() => {
		props.getLPO(id);
	}, []);

	const renderLPOContents = () => {
		return lpo.lpo_contents.map((content, index) => {
			return (
				<tr key={index}>
					<td className="text-center">{index + 1}</td>
					<td className="text-center">{content.description}</td>
					<td className="text-center">{content.unit}</td>
					<td className="text-center">{content.unit_price}</td>
					<td className="text-center">{content.quantity}</td>
					<td className="text-center">{content.amount}</td>
				</tr>
			);
		});
	};

	return (
		<>
			{getLPOLoading ? (
				<LoadingSpinner />
			) : (
				<Row className="pt-4 justify-content-center">
					<Col md={10} ref={printRef} className="pb-4">
						<Card className="border-light shadow-sm position-relative p-4 p-md-5">
							<div className="d-sm-flex justify-content-between border-bottom border-light pb-4 pb-md-5 mb-4 mb-md-5">
								<Image src={logo} width={100} height={100} />
								<div className="my-auto">
									<h2>{t('saportif-technology')}</h2>
								</div>
							</div>
							<div className="mb-3 d-flex flex-column align-items-center justify-content-center">
								<h2 className="mb-0">
									{t('local-purchase-order')} <br />
								</h2>
								<h3>{t('l-p-o-no-9904')}</h3>
							</div>

							<Row className="justify-content-between mb-4 ">
								<Col className="col-sm">
									<div>
										<ListGroup className="list-group simple-list">
											<ListGroup.Item className="fw-normal">
												<span className="fw-bolder">To: </span> {lpo.supplier.supplier_name}
											</ListGroup.Item>
											<ListGroup.Item className="fw-normal">
												<span className="fw-bolder">Address: </span> {lpo.supplier_contact_person_address}
											</ListGroup.Item>
											<ListGroup.Item className="fw-normal">
												<span className="fw-bolder">Attention: </span> {lpo.supplier_receiver}
											</ListGroup.Item>
											<ListGroup.Item className="fw-normal">
												<span className="fw-bolder">{t('job-no')} </span> {lpo.project.project_name}
											</ListGroup.Item>
											<ListGroup.Item className="fw-normal">
												<span className="fw-bolder">Company: </span> {lpo.company.company_name}
											</ListGroup.Item>
										</ListGroup>
									</div>
								</Col>

								<Col className="col-sm col-lg-4">
									<Row className="text-sm-right row">
										<ListGroup className="list-group simple-list">
											<ListGroup.Item className="fw-normal">
												<span className="fw-bolder">{t('qt-ref')} </span> {lpo.reference}
											</ListGroup.Item>
											<ListGroup.Item className="fw-normal">
												<span className="fw-bolder">Phone: </span> {lpo.supplier_contact_person_phone_number}
											</ListGroup.Item>
											<ListGroup.Item className="fw-normal">
												<span className="fw-bolder">Fax: </span> {lpo.supplier_contact_person_fax_number}
											</ListGroup.Item>
											<ListGroup.Item className="fw-normal">
												<span className="fw-bolder">Date: </span> {new Date(lpo.createdAt).toLocaleDateString()}
											</ListGroup.Item>
										</ListGroup>
									</Row>
								</Col>
							</Row>

							<Row>
								<Col className="col-12">
									<div className="table-responsive">
										<Table className="mb-0 table">
											<thead className="bg-light border-top">
												<tr>
													<th className="border-0 text-center">{t('supplier-no')}</th>
													<th className="border-0 text-center">{t('description')}</th>
													<th className="border-0 text-center">{t('unit')}</th>
													<th className="border-0 text-center">{t('price')}</th>
													<th className="border-0 text-center">{t('qty')}</th>
													<th className="border-0 text-center">{t('total')}</th>
												</tr>
											</thead>
											<tbody>{renderLPOContents()}</tbody>
											<tfoot>
												<tr>
													<th className="text-right" colSpan={5}>
														Total:
													</th>
													<td className="text-center" colSpan={4}>
														{lpo.total_value}
													</td>
												</tr>
											</tfoot>
										</Table>
									</div>
									<Row className="mt-4">
										<Col md={12}>
											<ListGroup className="list-group simple-list">
												<ListGroup.Item className="fw-normal">
													<span className="fw-bolder">{t('contact-person')} </span> {lpo.supplier_contact_person_name}
												</ListGroup.Item>
												<ListGroup.Item className="fw-normal">
													<span className="fw-bolder">Delivery: </span> {lpo.delivery}
												</ListGroup.Item>
												<ListGroup.Item className="fw-normal">
													<span className="fw-bolder">{t('delivery-place')} </span> {lpo.delivery_address}
												</ListGroup.Item>
												<ListGroup.Item className="fw-normal">
													<span className="fw-bolder">{t('payment-type-0')} </span> {lpo.payment_type}
												</ListGroup.Item>
												<ListGroup.Item className="fw-normal">
													<span className="fw-bolder">{t('payment-days-0')} </span> {lpo.payment_days}
												</ListGroup.Item>
											</ListGroup>
										</Col>
									</Row>
									<Row className="mt-4">
										<Col md={12}>
											<h5>{t('terms-and-conditions-1')}</h5>
											<p>{lpo.terms_conditions}</p>
										</Col>
									</Row>
									<Row className="mt-4">
										<Col md={4} sm={12}>
											<div className="d-flex flex-column align-items-center justify-content-center">
												<h5>{t('checked-by')}</h5>
												<h6>{lpo.checked_by}</h6>
											</div>
										</Col>
										<Col md={4} sm={12}>
											<div className="d-flex flex-column align-items-center justify-content-center">
												<h5>{t('authorized-signature')}</h5>
												<h6>{lpo.authorized_signature}</h6>
											</div>
										</Col>
										<Col md={4} sm={12}>
											<div className="d-flex flex-column align-items-center justify-content-center">
												<h5>{t('received-by')}</h5>
												<h6>{lpo.received_by}</h6>
											</div>
										</Col>
									</Row>
								</Col>
							</Row>
						</Card>
					</Col>
					{/* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
						<button onClick={handleDownloadPdf} className="btn btn-primary">
							Download as PDF
						</button>
					</div> */}
				</Row>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		lpo: state.procurement.lpo.lpo,
		getLPOLoading: state.procurement.lpo.getLPOLoading,
	};
};

export default connect(mapStateToProps, { getLPO, clearData })(LPODetails);
