import { faClock, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default ({ username, action, action_date }) => {
	return (
		<div className="row">
			<div className="col-auto col">
				<div className="icon icon-shape icon-sm bg-danger text-white rounded-circle">
					<FontAwesomeIcon icon={faTimes} />
				</div>
			</div>
			<div className="ms-n2 col">
				<h3 className="h6  fw-bold mb-1">{username}</h3>
				<p className=" mb-0 card-text">{action}</p>
				<small className="text-gray">
					<FontAwesomeIcon icon={faClock} className="me-2" />
					{new Date(action_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} |{' '}
					{new Date(action_date).toLocaleDateString()}
				</small>
			</div>
		</div>
	);
};
