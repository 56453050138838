import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ProjectBuildingForm from './ProjectBuildingForm';
import { getProject } from '../../../../actions/Construction/projectActions';
import { updateProjectBuilding, getProjectBuilding } from '../../../../actions/Construction/projectBuildingActions';
import { clearData } from '../../../../actions/globalActions';

import LoadingSpinner from '../../../../components/LoadingSpinner';
import _ from 'lodash';

const ProjectBuildingEdit = (props) => {
	const id = props.match.params.id;

	const { datas, loading } = props;

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.updateProjectBuilding(formValues);
		}
	};

	useEffect(() => {
		props.getProjectBuilding(id);

		return () => {
			props.clearData();
		};
	}, []);

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<ProjectBuildingForm onSubmit={onSubmit} datas={datas} initials={datas.projectBuilding} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		construction: {
			projectBuilding: { projectBuilding, getProjectBuildingLoading },
		},
	} = state;

	return {
		datas: { projectBuilding, project: projectBuilding.construction_project },
		loading: getProjectBuildingLoading,
	};
};

export default connect(mapStateToProps, { updateProjectBuilding, getProjectBuilding, clearData })(ProjectBuildingEdit);
