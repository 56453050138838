import { faEdit, faHome, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import IndexTable from '../../../../components/IndexTable';
import { Routes } from '../../../../routes';
import { getEmployees, deleteEmployee } from '../../../../actions/HumanResources/employeeActions';
import onDelete from '../../../../helpers/deleteFunction';
import { useTranslation } from 'react-i18next';

const EmployeeIndex = (props) => {
	const { employees, loading } = props;

	const [deleted, setDeleted] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		props.getEmployees();
	}, [deleted]);

	const renderRows = () => {
		return employees.map((employee, index) => {
			return (
				<tr key={index}>
					<td className="fw-bold text-center">{employee.reference}</td>
					<td className="fw-bold text-center">{employee.full_name}</td>
					<td className="fw-bold text-center">{employee.job_title}</td>
					<td className="fw-bold text-center">{employee.manager}</td>
					<td className="fw-bold text-center">{employee.employee_company.company_name}</td>
					<td className="fw-bold text-center">{employee.department.department_name}</td>
					<td className="fw-bold text-center">
						<button
							onClick={() => {
								onDelete(deleted, setDeleted, props.deleteEmployee, employee.employee_id);
							}}
							className="btn btn-danger mx-2">
							<FontAwesomeIcon icon={faTrash} />
						</button>
						<Link
							to={Routes.HumanResources.Employee.Edit.path.replace(':id', employee.employee_id)}
							className="btn btn-primary mx-2">
							<FontAwesomeIcon icon={faEdit} />
						</Link>
					</td>
				</tr>
			);
		});
	};

	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0 ">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.HumanResources.Overview.path }}>
							{t('human-resources')}{' '}
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{t('employees')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>

				<div
					className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-5
  ">
					<h4 style={{ display: 'inline' }}>{t('employees-0')}</h4>
					<Link className="btn btn-primary py-2 px-3  " to={Routes.HumanResources.Employee.Create.path}>
						{t('add-new')}{' '}
					</Link>
				</div>
				<p className="mb-0">{t('your-most-recent-employees-can-be-seen-here')}</p>
			</div>

			<IndexTable
				loading={loading}
				headerProps="text-center"
				headers={[
					t('reference'),
					t('employee-name'),
					t('job-title'),
					t('manager'),
					t('company'),
					t('department-0'),
					'',
				]}
				renderRows={employees.length != 0 ? renderRows() : null}
				// filter={
				// 	2 > 1 ? (
				// 		<div className="mb-3">
				// 			<TableFilter
				// 				initialFilterCategories={initialFilterCategories}
				// 				filters={filters}
				// 				setFilters={setFilters}
				// 				datas={projects}
				// 				setQuery={setQuery}
				// 				filterCategories={filterCategories}
				// 				setFilterCategories={setFilterCategories}
				// 			/>
				// 		</div>
				// 	) : null
				// }
				// pagination={
				// 	2 > 1 ? (
				// 		<ReactPaginate
				// 			nextLabel=" >"
				// 			onPageChange={handlePageClick}
				// 			pageCount={Math.ceil(_.last(projects).page_count)}
				// 			pageRangeDisplayed={3}
				// 			previousLabel="< "
				// 			pageClassName="page-item"
				// 			pageLinkClassName="page-link"
				// 			previousClassName="page-item"
				// 			forcePage={offset}
				// 			previousLinkClassName="page-link"
				// 			nextClassName="page-item"
				// 			nextLinkClassName="page-link"
				// 			breakLabel="..."
				// 			breakClassName="page-item"
				// 			breakLinkClassName="page-link"
				// 			containerClassName="pagination"
				// 			activeClassName="active"
				// 			renderOnZeroPageCount={null}
				// 		/>
				// 	) : null
				// }
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		humanResources: {
			employee: { employees, getEmployeesLoading },
		},
	} = state;

	return {
		employees,
		loading: getEmployeesLoading,
	};
};

export default connect(mapStateToProps, { getEmployees, deleteEmployee })(EmployeeIndex);
