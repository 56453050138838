import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';
import { CREATE_QUOTATION, DELETE_QUOTATION, GET_QUOTATION, GET_QUOTATIONS, UPDATE_QUOTATION } from './types';

export const createQuotation = (formValues) => async (dispatch) => {
	axios
		.post('quotation/', formValues)
		.then((response) => {
			dispatch({ type: CREATE_QUOTATION, payload: response.data });
			history.push(Routes.Procurement.ISR.Checklist.path.replace(':id', formValues.isr_id));
		})
		.catch((err) => {});
};

export const getQuotations = () => async (dispatch) => {
	axios
		.get(`quotation/`)
		.then((response) => {
			dispatch({ type: GET_QUOTATIONS, payload: response.data });
		})
		.catch((err) => {});
};

export const getQuotationsByISR = (id) => async (dispatch) => {
	axios
		.get(`quotation/isr/${id}`)
		.then((response) => {
			dispatch({ type: GET_QUOTATIONS, payload: response.data });
		})
		.catch((err) => {});
};

export const getQuotation = (id) => async (dispatch) => {
	axios
		.get(`quotation/${id}`)
		.then((response) => {
			dispatch({ type: GET_QUOTATION, payload: response.data });
		})
		.catch((err) => {});
};

export const updateQuotation = (formValues) => async (dispatch) => {
	axios
		.put(`quotation/${formValues.quotation_id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_QUOTATION, payload: response.data });
			history.push(Routes.Procurement.ISR.Checklist.path.replace(':id', formValues.isr_id));
		})
		.catch((err) => {});
};

export const deleteQuotation = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`quotation/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_QUOTATION, payload: response.data });
			setDeleted(!deleted);
		})
		.catch((err) => {});
};
