import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import LPOForm from './LPOForm';

import { getSuppliers } from '../../../../actions//Procurement/suppliersActions';
import { getProjects } from '../../../../actions//Finance/projectActions';
import { getEntities } from '../../../../actions/Admin/entityActions';
import { getISRs } from '../../../../actions//Procurement/isrActions';
import { clearData } from '../../../../actions/globalActions';
import { createLPO } from '../../../../actions//Procurement/lpoActions';

import LoadingSpinner from '../../../../components/LoadingSpinner';

const LPOCreate = (props) => {
	const {
		suppliers,
		getSuppliersLoading,
		projects,
		getProjectsLoading,
		getEntitiesLoading,
		entities,
		isrs,
		getISRsLoading,
	} = props;

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.createLPO(formValues);
		}
	};

	useEffect(() => {
		props.getSuppliers();
		props.getProjects();
		props.getEntities();
		props.getISRs();

		return () => {
			props.clearData();
		};
	}, []);

	return (
		<>
			{getSuppliersLoading || getProjectsLoading || getEntitiesLoading || getISRsLoading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<LPOForm entities={entities} projects={projects} suppliers={suppliers} isrs={isrs} onSubmit={onSubmit} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		suppliers: state.procurement.supplier.suppliers,
		getSuppliersLoading: state.procurement.supplier.getSuppliersLoading,
		projects: state.finance.project.projects,
		getProjectsLoading: state.finance.project.getProjectsLoading,
		entities: state.admin.entity.entities,
		getEntitiesLoading: state.admin.entity.getEntitiesLoading,
		isrs: state.procurement.isr.isrs,
		getISRsLoading: state.procurement.isr.getISRsLoading,
	};
};

export default connect(mapStateToProps, {
	getSuppliers,
	clearData,
	getProjects,
	getEntities,
	getISRs,
	createLPO,
})(LPOCreate);
