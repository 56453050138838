import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import renderInput from './renderInput';

export default ({ fields, meta, inputNames, types, totals, showAddRow }) => {
	if (fields.length < 1) {
		useEffect(() => {
			fields.push({});
		}, []);
	}

	const renderFields = (inputNames, parentObjName) => {
		return inputNames.map((input, index) => {
			return (
				<td colSpan={input.col} key={index}>
					<Field inputType={types[index]} name={`${parentObjName}.${input.name}`} component={renderInput} />
				</td>
			);
		});
	};

	return (
		<>
			{fields.map((parentObjName, index) => {
				return (
					<tr key={index}>
						{renderFields(inputNames, parentObjName)}

						<td>
							<div className="mt-2 ms-2">
								<FontAwesomeIcon
									className="text-danger"
									icon={faTrash}
									size="2x"
									onClick={(e) => {
										e.preventDefault();

										fields.remove(index);
									}}
								/>
							</div>
						</td>
					</tr>
				);
			})}

			{totals ? (
				<tr>
					<td className="p-3" style={{ borderBottom: 0 }}>
						<h5 className="m-0">Total: </h5>
					</td>
					{totals.map((total, index) => {
						return (
							<td key={index} className="p-3" style={{ borderBottom: 0 }}>
								<h5 className="m-0">{total}</h5>
							</td>
						);
					})}
				</tr>
			) : null}
			{showAddRow ? (
				<tr>
					<td style={{ borderBottom: 0 }}>
						<button
							className="field btn btn-secondary"
							onClick={(e) => {
								e.preventDefault();
								fields.push({});
							}}>
							Add Row
						</button>
					</td>
				</tr>
			) : null}
		</>
	);
};
