import { faCog, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteTicket, getLongIncidenceTickets, getTickets } from '../../../actions//ITDepartment/ticketActions';
import IndexTable from '../../../components/IndexTable';
import history from '../../../history';

import { clearData } from '../../../actions/globalActions';

import _ from 'lodash';
import ReactPaginate from 'react-paginate';
import TableFilter from '../../../components/TableFilter';
import { indexTableFilterQueryGenerator } from '../../../helpers/helpers';
import { Routes } from '../../../routes';
import { useTranslation } from 'react-i18next';

const TicketIndex = (props) => {
	const {
		Tickets: { Tickets },
		loading,
		userPolicy,
		user_id,
		roles,
	} = props;
	const { t } = useTranslation();

	const [toggleTab, setToggleTab] = useState('all');

	const [deleted, setDeleted] = useState(false);
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [query, setQuery] = useState('');
	const [order, setOrder] = useState('');
	const [search, setSearch] = useState('');

	const [filters, setFilters] = useState([]);

	const initialFilterCategories = [
		{ label: t('company'), displayField: null, value: 'company.company_name', queryName: t('company') },
		{ label: t('status'), displayField: null, value: 'status', queryName: t('status') },
		{ label: t('department-0'), displayField: null, value: 'department', queryName: t('department-0') },
		{
			label: t('assigned-to'),
			displayField: 'assignedUser.full_name',
			value: 'assignedUser.user_id',
			queryName: 'AssignedTo',
		},
	];

	const [filterCategories, setFilterCategories] = useState(initialFilterCategories);

	// Ticket GET ALL WHEN
	// ITEM IS DELETED
	// LIMIT || OFFSET || QUERY CHANGES
	useEffect(() => {
		if (toggleTab == 'all') {
			props.getTickets(offset, limit, query, order, search);
		} else {
			props.getLongIncidenceTickets(offset, limit, query, order, search);
		}

		return () => {
			props.clearData();
		};
	}, [deleted, limit, offset, query, toggleTab, order, search]);

	useEffect(() => {
		setOffset(0);
		setQuery('');
		setFilters([]);
		setFilterCategories(initialFilterCategories);
		setOrder('');
		setSearch('');
	}, [toggleTab]);

	// CHANGE QUERY WHEN FILTER OBJ CHANGES
	useEffect(() => {
		indexTableFilterQueryGenerator(filters, setQuery);
	}, [filters.length]);

	const renderRows = () => {
		return Tickets.map((val, index) => {
			if (index != Tickets.length - 1) {
				var isLongRunning = Date.now() - new Date(val.date_created) > 7 * 24 * 60 * 60 * 1000 ? true : false;
				return (
					<tr
						key={index}
						style={{ cursor: 'pointer', borderBlockStart: '10px' }}
						onClick={() => history.push(Routes.IT_Department.Ticket.Detail.path.replace(':id', val.id))}>
						<td className={`fw-bold text-center ${isLongRunning ? 'text-danger' : ''}`}>{val.code}</td>
						<td className="fw-bold text-center">{val.title}</td>
						<td className="fw-bold text-center">{val.severity}</td>
						<td className="fw-bold text-center">{val.status}</td>
						<td className="fw-bold text-center">{val.company.company_name}</td>
						<td className="fw-bold text-center">{val.department}</td>
						<td className="fw-bold text-center">{val.by?.full_name}</td>
						<td className="fw-bold text-center">{val.assignedUser?.full_name || '-'}</td>

						<td className="fw-bold text-center">{new Date(val.date_created).toLocaleDateString()}</td>
						{/* <td className="fw-bold text-center">
							<Link
								to={Routes.IT_Department.Ticket.Detail.path.replace(':id', val.id)}
								className="btn btn-primary mx-2">
								<FontAwesomeIcon icon={faEye} />
							</Link>
						</td> */}
					</tr>
				);
			} else {
				return null;
			}
		});
	};

	// REACT PAGINATION ON CLICK FUNCTION
	const handlePageClick = (event) => {
		setOffset(event.selected);
	};

	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0 ">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{t('tickets')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
					<h4 style={{ display: 'inline' }}>{t('open-tickets')}</h4>
					{roles.some((role) => role.role_name.includes(t('it-manager'))) ? (
						<Link to={Routes.Admin.TicketCategory.Index.path} className="btn btn-outline-primary text-center">
							<FontAwesomeIcon icon={faCog} />
						</Link>
					) : null}
				</div>

				<p className="mb-0">{t('most-recent-tickets-assigned-to-you-can-be-seen-here')}</p>
			</div>

			{/* <Nav className="justify-content-center row" variant="tabs" defaultActiveKey={Routes.Admin.Entity.Index.path}>
				<Nav.Item className="col text-center">
					<Nav.Link
						className={`${toggleTab == 'all' ? 'bg-primary  text-white' : null}`}
						onClick={() => {
							setToggleTab('all');
						}}>
						Open Incidents
					</Nav.Link>
				</Nav.Item>
				<Nav.Item className="col text-center ">
					<Nav.Link
						className={`${toggleTab == 'long' ? 'bg-primary  text-white' : null}`}
						onClick={() => {
							setToggleTab('long');
						}}>
						Long-running Incidents
					</Nav.Link>
				</Nav.Item>
			</Nav> */}
			<br />

			<IndexTable
				headerProps="text-center"
				// headers={[
				// 	'Code',
				// 	'Ticket Title',
				// 	'Company',
				// 	'Severity',
				// 	'Department',
				// 	'Created by',
				// 	'Assigned to',
				// 	'Status',
				// 	'Submission Date',
				// 	'',
				// ]}
				headers={[
					{ label: t('code'), value: 'id', sortable: true },
					{ label: t('ticket-title'), value: 'title', sortable: true },
					{ label: t('severity'), value: 'severity', sortable: true },
					{ label: t('status'), value: 'status', sortable: true },
					{ label: t('company'), value: 'company.company_name', sortable: false },
					{ label: t('department-0'), value: 'department', sortable: true },
					{ label: t('created-by'), value: 'by.full_name', sortable: false },
					{ label: t('assigned-to-0'), value: 'assignedUser.full_name', sortable: false },
					{ label: t('submission-date-0'), value: 'date_created', sortable: true },
					// { label: '', value: '', sortable: false },
					// , 'Supplier Name', 'Score', 'Recommended', 'Capable', 'Status', ''
				]}
				setOrder={setOrder}
				searchable
				setSearch={setSearch}
				renderRows={Tickets.length > 1 ? renderRows() : null}
				loading={loading}
				filter={
					<div className="mb-3">
						{Tickets.length > 1 ? (
							<TableFilter
								initialFilterCategories={initialFilterCategories}
								filters={filters}
								setFilters={setFilters}
								datas={Tickets}
								setQuery={setQuery}
								filterCategories={filterCategories}
								setFilterCategories={setFilterCategories}
							/>
						) : null}
					</div>
				}
				pagination={
					Tickets.length > 1 ? (
						<ReactPaginate
							nextLabel=" >"
							onPageChange={handlePageClick}
							pageCount={Math.ceil(_.last(Tickets).page_count)}
							pageRangeDisplayed={3}
							previousLabel="< "
							pageClassName="page-item"
							pageLinkClassName="page-link"
							previousClassName="page-item"
							forcePage={offset}
							previousLinkClassName="page-link"
							nextClassName="page-item"
							nextLinkClassName="page-link"
							breakLabel="..."
							breakClassName="page-item"
							breakLinkClassName="page-link"
							containerClassName="pagination"
							activeClassName="active"
							renderOnZeroPageCount={null}
						/>
					) : null
				}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		Tickets: state.itDepartment.ticket,
		loading: state.itDepartment.ticket.getTicketsLoading,
		userPolicy: state.auth.policies,
		user_id: state.auth.user_id,
		roles: state.auth.roles,
	};
};

export default connect(mapStateToProps, {
	getTickets,
	deleteTicket,
	clearData,
	getLongIncidenceTickets,
})(TicketIndex);
