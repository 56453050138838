import React from 'react';
import { connect } from 'react-redux';

import TicketCategoryForm from './TicketCategoryForm';

import { createTicketCategory } from '../../../../actions/Admin/ticketCategoryActions';

const TicketCategoryCreate = (props) => {
	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			props.createTicketCategory(formValues);
		}
	};

	return (
		<div className="mt-3">
			<TicketCategoryForm onSubmit={onSubmit} />
		</div>
	);
};

export default connect(null, { createTicketCategory })(TicketCategoryCreate);
