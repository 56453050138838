import { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Handle, Position } from 'react-flow-renderer';
import { connect } from 'react-redux';
import { Multiselect } from 'react-widgets';
import { getUsers } from '../../../../../actions/User/userActions';
import LoadingSpinner from '../../../../../components/LoadingSpinner';

import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger } from '@themesberg/react-bootstrap';

import _ from 'lodash';
import FileCheckIcon from '../../../../../img/file-check.png';
import SettingsIcon from '../../../../../img/settings.png';
const ApprovalNode = (props) => {
	const { data, users, getUsersLoading, entities, departments, roles } = props;

	// console.log('DATA VALUE', data.value);

	// SELECT BY OPTION
	const [selectBy, setSelectBy] = useState(true);

	// SELECTING BY USERS STATE
	const [selectedUsers, setSelectedUsers] = useState(data.value.users || []);

	// SELECTING BY GROUP STATES
	const [selectedRoles, setSelectedRoles] = useState([]);
	const [selectedDepartments, setSelectedDepartments] = useState([]);
	const [selectedCompanies, setSelectedCompanies] = useState([]);
	const [approvalStatus, setApprovalStatus] = useState('Approved');
	const [rejectionStatus, setRejectionStatus] = useState('Rejected');

	useEffect(() => {
		props.getUsers();
	}, [getUsersLoading]);

	// EDIT INITALIZATION
	useEffect(() => {
		// CHECK IS EDITING OR NOT
		const isEditing = !_.isEmpty(data.value);

		const { select_by, approval_status, rejection_status, users, roles, departments, companies } = data.value;

		if (isEditing) {
			if (select_by == 'group') {
				setSelectBy(select_by == 'group' ? false : true);
				setSelectedRoles(roles);
				setSelectedDepartments(departments);
				setSelectedCompanies(companies);
				setApprovalStatus(approval_status);
				setRejectionStatus(rejection_status);

				data.onChange((prev) => !prev);
			} else if (select_by == 'users') {
				setSelectedUsers(users);
				setApprovalStatus(approval_status);
				setRejectionStatus(rejection_status);

				data.onChange((prev) => !prev);
			}
		}
	}, []);

	return (
		<Card style={{ width: '500px', borderRadius: '0px' }}>
			<Handle type="target" position={Position.Top} />
			<div
				className="fw-bold d-flex align-items-center justify-content-between text-white "
				style={{ backgroundColor: '#8e59ff' }}>
				<div className="d-flex align-items-center">
					<div className="d-flex justify-content-center align-items-center" style={{ width: '50px', height: '50px' }}>
						<img style={{ width: '30px', height: '30px' }} src={FileCheckIcon} />
					</div>
					<span className="ms-2 fs-5">{data.label}</span>
				</div>

				<OverlayTrigger
					trigger="click"
					placement="bottom"
					rootClose
					overlay={
						<Card
							style={{
								position: 'absolute',
								padding: '2px 10px',
								borderRadius: 3,
								zIndex: 11111,
								...props.style,
							}}>
							<Row>
								<label>Custom Status</label>
								<p>This is will be the status of the entry after approval</p>
							</Row>
							<Row className="mb-3 ">
								<Form.Label>Approval</Form.Label>
								<Form.Group>
									<Form.Select
										value={approvalStatus}
										onChange={(e) => {
											data.value.approval_status = e.target.value;
											data.onChange((prev) => !prev);
											setApprovalStatus(e.target.value);
										}}>
										<option disabled value="0">
											Select
										</option>
										<option value="Approved">Approved</option>
										<option value="Pending">Pending</option>
										<option value="In Review">In Review</option>
									</Form.Select>
								</Form.Group>
							</Row>
							<Row className="mb-3 ">
								<Form.Label>Rejection</Form.Label>
								<Form.Group>
									<Form.Select
										value={rejectionStatus}
										onChange={(e) => {
											data.value.rejection_status = e.target.value;
											data.onChange((prev) => !prev);
											setRejectionStatus(e.target.value);
										}}>
										<option disabled value="0">
											Select
										</option>
										<option value="Rejected">Rejected</option>
										<option value="Pending">Pending</option>
										<option value="In Review">In Review</option>
									</Form.Select>
								</Form.Group>
							</Row>
						</Card>
					}>
					<img src={SettingsIcon} width={20} height={20} className="mx-2" />
				</OverlayTrigger>
			</div>
			<Card.Body>
				{selectBy == 'users' ? (
					<LoadingSpinner />
				) : (
					<>
						<Row className="mb-3">
							<Form.Label>Select By</Form.Label>
							<Form.Group>
								<label className="toggle">
									<input
										onChange={(e) => {
											setSelectBy(!selectBy);
											if (!selectBy) {
												data.value.select_by = 'users';
												data.value.companies = [];
												data.value.roles = [];
												data.value.departments = [];
												setSelectedCompanies([]);
												setSelectedDepartments([]);
												setSelectedRoles([]);

												data.onChange((prev) => !prev);
											} else {
												data.value.select_by = 'group';
												data.value.users = [];
												data.onChange((prev) => !prev);
											}
										}}
										type="checkbox"
										checked={selectBy}
									/>
									<span className="slider"></span>
									<span className="labels" data-on="Users" data-off="Group"></span>
								</label>
							</Form.Group>
						</Row>
						<Row className="mb-3">
							{selectBy ? (
								<>
									<Form.Label>Select Users</Form.Label>
									<Form.Group>
										<Multiselect
											onChange={(e) => {
												data.value.users = e;
												data.onChange((prev) => !prev);
												setSelectedUsers(e);
											}}
											defaultValue={data.value.users ?? []}
											data={users}
											value={selectedUsers}
											dataKey="user_id"
											textField="full_name"
										/>
									</Form.Group>
								</>
							) : (
								<>
									<Row className="mb-3">
										<Form.Label>Select Company</Form.Label>
										<Form.Group>
											<Multiselect
												onChange={(e) => {
													if (e.some((i) => i.company_name === 'All Companies')) {
														data.value.companies = [{ company_id: 'All Companies', company_name: 'All Companies' }];
													} else {
														data.value.companies = e;
													}
													data.onChange((prev) => !prev);
													setSelectedCompanies(e);
												}}
												defaultValue={data.value.companies ?? []}
												value={
													selectedCompanies.some((i) => i.company_name === 'All Companies')
														? selectedCompanies.filter((i) => i.company_name == 'All Companies')
														: selectedCompanies
												}
												data={
													selectedCompanies.some((i) => i.company_name === 'All Companies')
														? []
														: [{ company_id: 'All Companies', company_name: 'All Companies' }, ...entities]
												}
												dataKey="company_id"
												textField="company_name"
											/>
										</Form.Group>
									</Row>
									<Row className="mb-3">
										<Form.Label>Select Deparment</Form.Label>
										<Form.Group>
											<Multiselect
												onChange={(e) => {
													if (e.some((i) => i.department_name === 'All Departments')) {
														data.value.departments = [
															{ department_id: 'All Departments', department_name: 'All Departments' },
														];
													} else {
														data.value.departments = e;
													}
													data.onChange((prev) => !prev);
													setSelectedDepartments(e);
												}}
												defaultValue={data.value.departments ?? []}
												value={
													selectedDepartments.some((i) => i.department_name === 'All Departments')
														? selectedDepartments.filter((i) => i.department_name == 'All Departments')
														: selectedDepartments
												}
												data={
													selectedDepartments.some((i) => i.department_name === 'All Departments')
														? []
														: [{ department_id: 'All Departments', department_name: 'All Departments' }, ...departments]
												}
												dataKey="department_id"
												textField="department_name"
											/>
										</Form.Group>
									</Row>
									<Row className="mb-3">
										<Form.Label>Select Role</Form.Label>
										<Form.Group>
											<Multiselect
												onChange={(e) => {
													if (e.some((i) => i.role_name === 'All Roles')) {
														data.value.roles = [{ role_id: 'All Roles', role_name: 'All Roles' }];
													} else {
														data.value.roles = e;
													}
													data.onChange((prev) => !prev);
													setSelectedRoles(e);
												}}
												defaultValue={data.value.roles ?? []}
												value={
													selectedRoles.some((i) => i.role_name === 'All Roles')
														? selectedRoles.filter((i) => i.role_name == 'All Roles')
														: selectedRoles
												}
												data={
													selectedRoles.some((i) => i.role_name === 'All Roles')
														? []
														: [{ role_id: 'All Roles', role_name: 'All Roles' }, ...roles]
												}
												dataKey="role_id"
												textField="role_name"
											/>
										</Form.Group>
									</Row>
								</>
							)}
						</Row>
					</>
				)}
			</Card.Body>
			<Row className="text-center">
				<Col>
					<span className="icon icon-sm">
						<FontAwesomeIcon icon={faCheck} className="bell-shake" />
					</span>
					<Handle
						style={{ left: 130, bottom: -10, background: '#05a677' }}
						id="yes"
						type="source"
						position={Position.Bottom}
					/>
				</Col>
				<Col>
					<span className="icon icon-sm">
						<FontAwesomeIcon icon={faTimes} className="bell-shake" />
					</span>
					<Handle
						style={{ left: 130, bottom: -10, right: 'auto', background: '#fa5252' }}
						id="no"
						type="source"
						position={Position.Bottom}
					/>
				</Col>
			</Row>
		</Card>
	);
};

const mapStateToProps = (state) => {
	const {
		user: { users, getUsersLoading, roles },
		admin: {
			entity: { entities },
			department: { departments },
		},
	} = state;

	return { users, getUsersLoading, entities, departments, roles };
};

export default connect(mapStateToProps, { getUsers })(ApprovalNode);
