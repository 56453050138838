import { Card, Col, Row } from '@themesberg/react-bootstrap';
import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, Field, FieldArray, formValueSelector, getFormMeta, initialize, reduxForm } from 'redux-form';
import renderInput from '../../../../components/formComponents/renderInput';
import SubmitButton from '../../../../components/formComponents/SubmitButton';
import renderFloorPlaceTable from './formComponents/renderFloorPlaceTable';

const selector = formValueSelector('locations');

const LocationsForm = (props) => {
	const { contentValues, initials, invalid, onSubmit, auth, datas, entities, punch_list_contents } = props;
	const { t } = useTranslation();
	if (initials) {
		const arr = initials.construction_floors.map((floor, index) => {
			return { floor_name: floor.floor_name, places: _.map(floor.places, 'place_name') };
		});
	}

	useEffect(() => {
		{
			initials != null
				? props.initialize({
						...initials,
						floor_place: [
							...initials.construction_floors.map((floor, index) => {
								return { floor_name: floor.floor_name, places: _.map(floor.construction_places, 'place_name') };
							}),
						],
				  })
				: props.initialize({
						user: auth.first_name + ' ' + auth.last_name,
				  });
		}

		props.change('user_id', auth.user_id);
	}, []);

	useEffect(() => {
		props.change('isValid', !invalid);
	}, [invalid]);

	return (
		<form onSubmit={props.handleSubmit(onSubmit)}>
			<Row>
				<Col xl={12} lg={12} md={12} sm={12} xs={12}>
					<Card border="light" className="bg-white shadow-sm mb-4  w-100">
						<Card.Body>
							<h5 className="mb-4">{t('punch-list-form')}</h5>
							<hr />

							<Row className="mb-3 ">
								<Col md={6} sm={12}>
									<Field name="building_name" label={t('building')} component={renderInput} />
								</Col>
							</Row>
							<Row>
								<Col className="mb-3">
									<table className="table overflow-auto formTable w-100 ">
										<thead>
											<tr>
												<th className="text-center">{t('floor')}</th>
												<th className="text-center">{t('place')}</th>
											</tr>
										</thead>
										<tbody>
											<FieldArray name="floor_place" component={renderFloorPlaceTable} />
										</tbody>
									</table>
								</Col>
							</Row>

							<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
								<a onClick={() => window.history.back()} className="btn btn-secondary">
									{t('back')}{' '}
								</a>
								<SubmitButton text={t('submit')} onSubmit={props.onSubmit} invalid={invalid} />
							</div>
						</Card.Body>
					</Card>
				</Col>
				<Col xl={4} lg={4} md={12} sm={12} xs={12}></Col>
			</Row>
		</form>
	);
};

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		locations: state.locations,
		entities: state.admin.entity.entities,
		meta: getFormMeta('locations')(state),
	};
};

export default compose(
	connect(mapStateToProps, { change, initialize }),

	reduxForm({
		form: 'locations',
	})
)(LocationsForm);
