export default ({ input, meta, options, radioButtonProps }) => {
	const renderOptions = () => {
		return options.map((val, index) => {
			return (
				<div key={index} className={`form-check ${radioButtonProps}   `}>
					<input
						type="radio"
						className="form-check-input  "
						{...input}
						checked={input.value == val.value}
						value={val.value}
					/>
					{val.title ? <label className="form-check-label">{val.title}</label> : null}
				</div>
			);
		});
	};

	return (
		<>
			{renderOptions()}
			{<span className="text-danger">{meta.error && meta.touched ? meta.error : null}</span>}
		</>
	);
};
