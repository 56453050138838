import axios from '../../apis/axios';
import { GET_NOTIFICATIONS, GET_RECENT_NOTIFICATIONS } from './types';

export const getRecentNotifications = (id) => async (dipatch) => {
	axios
		.get(`notification/get-recent/${id}`)
		.then((response) => {
			dipatch({ type: GET_RECENT_NOTIFICATIONS, payload: response.data });
		})
		.catch((err) => {});
};

export const getNotifications = (id) => async (dispatch) => {
	axios
		.get(`/notification/${id}`)
		.then((response) => {
			dispatch({ type: GET_NOTIFICATIONS, payload: response.data });
		})
		.catch((err) => {});
};

export const notificationRead = (id) => async (dispatch) => {
	axios
		.get(`notification/read/${id}`)
		.then((response) => {})
		.catch((err) => {});
};
