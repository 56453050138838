import { CREATE_ISR, GET_ISRS, GET_ISR, DELETE_ISR, UPDATE_ISR, GET_ISR_CHECKLISTS, GET_ISRS_PAGINATED } from './types';
import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const createISR = (formValues) => async (dispatch, getState) => {
	const state = getState();

	axios
		.post('/isr', {
			...formValues,
			from_email: state.auth.mail,
		})
		.then((response) => {
			dispatch({ type: CREATE_ISR, payload: response.data });
			history.push(Routes.Procurement.ISR.Index.path);
			toast.success('ISR has been added.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		})
		.catch((err) => {
			toast.error('Something went wrong! Please open a ticket.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		});
};

export const getISRs = () => async (dispatch) => {
	axios
		.get(`/isr`)
		.then((response) => {
			dispatch({ type: GET_ISRS, payload: response.data });
		})
		.catch((err) => {});
};

export const getISRsWithPagination = (offset, limit, query, order, search) => async (dispatch) => {
	var params = '';
	params += order.length != 0 ? `${order}&` : '';
	params += query.length != 0 ? `${query}&` : '';
	params += search.length != 0 ? `${search}` : '';

	axios
		.get(`/isr/offset/${offset}/limit/${limit}?${params}`)
		.then((response) => {
			dispatch({ type: GET_ISRS_PAGINATED, payload: response.data });
		})
		.catch((err) => {});
};
export const getISR = (id) => async (dispatch) => {
	axios
		.get(`/isr/${id}`)
		.then((response) => {
			dispatch({ type: GET_ISR, payload: response.data });
		})
		.catch((err) => {});
};

export const deleteISR = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`isr/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_ISR, payload: response.data });
			setDeleted(!deleted);
			toast.success('ISR has been deleted.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		})
		.catch((err) => {
			toast.error('Something went wrong! Please open a ticket.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		});
};

export const updateISR = (formValues) => async (dispatch, getState) => {
	const state = getState();

	axios
		.put(`isr/${formValues.id}`, {
			...formValues,
			from_email: state.auth.email,
		})
		.then((response) => {
			dispatch({ type: UPDATE_ISR, payload: response.data });
			history.push(Routes.Procurement.ISR.Index.path);
			toast.success('ISR has been updated.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		})
		.catch((err) => {
			toast.error('Something went wrong! Please open a ticket.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		});
};

export const getISRChecklists = (id) => async (dispatch) => {
	axios
		.get(`isr-checklist/${id}`)
		.then((response) => {
			dispatch({ type: GET_ISR_CHECKLISTS, payload: response.data });
		})
		.catch((err) => {});
};

export const toggleChecklist = (task) => async (dipatch) => {
	axios
		.put(`isr-checklist/${task.id}`, task)
		.then((response) => {})
		.catch((err) => {});
};
