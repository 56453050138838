import React from 'react';

import { Col, Row, Image } from '@themesberg/react-bootstrap';

import BgImage from '../../../../assets/img/pages/login.jpg';

import logo from '../../../../img/saportif.png';
import RegistrationForm from './RegistrationForm';

import { register } from '../../../../actions//User/userActions';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const Registration = (props) => {
	const { t } = useTranslation();
	const onSubmit = (formValues) => {
		if (formValues.firstName != null && formValues.lastName != null && formValues.token != null) {
			props.register(formValues);
		}
	};

	return (
		<main>
			<Row style={{ height: '100vh', overflowY: 'auto' }}>
				<Col
					xl={6}
					md={4}
					className=" signin-image"
					style={{
						backgroundImage: `url(${BgImage})`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
						backgroundPosition: 'center',
					}}></Col>
				<Col xl={6} md={8} sm={12} xs={12}>
					<Row className="align-content-center bg-white px-4 p-lg-5 pt-1 h-100">
						<Image src={logo} className="signin-logo  mx-auto" />
						<div className="text-center text-md-center mb-1">
							<h3 className="mb-0">{t('welcome-to-al-saportif-technology-portal')}</h3>
						</div>
						<RegistrationForm onSubmit={onSubmit} mail={props.mail} />
					</Row>
				</Col>
			</Row>
		</main>
	);
};

const mapStateToProps = (state, ownProps) => {
	return { mail: state.auth.mail, auth: state.auth };
};

export default connect(mapStateToProps, { register })(Registration);
