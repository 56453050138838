import { CREATE_PROJECT, DELETE_PROJECT, GET_PROJECT, GET_PROJECTS } from '../../actions/Construction/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	projects: [],
	project: {},
	loading: true,
	getProjectLoading: true,
	getProjectsLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_PROJECTS:
			return { ...state, projects: action.payload, getProjectsLoading: false };

		case GET_PROJECT:
			return { ...state, project: action.payload, getProjectLoading: false };

		case CREATE_PROJECT:
			return { ...state, project: action.payload };

		case CLEAR_DATA:
			return {
				...state,
				project: action.payload,
				getProjectLoading: true,
				getProjectsLoading: true,
			};
		case DELETE_PROJECT:
			return { ...state };
		default:
			return state;
	}
};
