import { CREATE_CLEARANCE, GET_CLEARANCES, GET_CLEARANCE, DELETE_CLEARANCE } from '../../actions/HumanResources/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	clearances: [],
	clearance: {},
	loading: true,
	getClearanceLoading: true,
	getClearancesLoading: true,
};
export default (state = initialState, action) => {
	switch (action.type) {
		case GET_CLEARANCES:
			return { ...state, clearances: action.payload, getClearancesLoading: false };
		case GET_CLEARANCE:
			return { ...state, clearance: action.payload, getClearanceLoading: false };
		case CREATE_CLEARANCE:
			return { ...state };
		case CLEAR_DATA:
			return {
				...state,
				clearance: action.payload,
				getClearanceLoading: true,
				getClearancesLoading: true,
			};
		case DELETE_CLEARANCE:
			return { ...state };
		default:
			return state;
	}
};
