import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Image, Button, Container } from '@themesberg/react-bootstrap';

import { Link } from 'react-router-dom';

import { Routes } from '../../../routes';
import UnderConstructionImage from '../../../assets/img/illustrations/underConstruction.svg';
import { useTranslation } from 'react-i18next';

export default () => {
	const { t } = useTranslation();

	return (
		<main>
			<section className="vh-100 d-flex align-items-center justify-content-center">
				<Container>
					<Row>
						<Col xs={12} className="text-center d-flex align-items-center justify-content-center">
							<div>
								<Card.Link as={Link} to={Routes.DashboardOverview.path}>
									<Image src={UnderConstructionImage} className="img-fluid" style={{ maxHeight: '450px' }} />
								</Card.Link>
								<h1 className="text-primary mt-5">
									{t('this-site-is-under')} <span className="fw-bolder">construction</span>
								</h1>
								<p className="lead my-4">{t('our-site-will-be-ready-for-your-service-as-soon-as-possible')}</p>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</main>
	);
};
