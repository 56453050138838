import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ProjectForm from './ProjectForm';

import { getProject, updateProject } from '../../../../actions/Construction/projectActions';
import { clearData } from '../../../../actions/globalActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { getUsers } from '../../../../actions//User/userActions';
import { getDepartments } from '../../../../actions/Admin/departmentActions';
import { getEntities } from '../../../../actions/Admin/entityActions';
import _ from 'lodash';

const ProjectEdit = (props) => {
	const { id } = props.match.params;
	const { datas, loading } = props;

	useEffect(() => {
		props.getProject(id);
		props.getUsers();
		props.getEntities();
		return () => {
			props.clearData();
		};
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			props.updateProject(formValues);
		}
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<ProjectForm datas={datas} onSubmit={onSubmit} initials={datas.project} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		construction: {
			project: { project, getProjectLoading },
		},
		user: { users, getUsersLoading },
		admin: {
			entity: { entities, getEntitiesLoading },
		},
	} = state;

	return {
		datas: { project, users, entities },
		loading: getProjectLoading || getUsersLoading || getEntitiesLoading,
	};
};

export default connect(mapStateToProps, {
	getProject,
	clearData,
	updateProject,
	getUsers,
	getEntities,
})(ProjectEdit);
