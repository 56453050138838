import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';
import { CREATE_LPO, DELETE_LPO, GET_LPO, GET_LPOS, UPDATE_LPO } from './types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const createLPO = (formValues) => async (dispatch) => {
	axios
		.post('lpo/', formValues)
		.then((response) => {
			dispatch({ type: CREATE_LPO, payload: response.data });
			history.push(Routes.Procurement.LPO.Index.path);
			toast.success('LPO has been added.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		})
		.catch((err) => {
			toast.error('Something went wrong! Please open a ticket.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		});
};

export const getLPOs = () => async (dispatch) => {
	axios
		.get(`/lpo`)
		.then((response) => {
			dispatch({ type: GET_LPOS, payload: response.data });
		})
		.catch((err) => {});
};

export const getLPOsWithFilter = (offset, limit, query, order, search) => async (dispatch) => {
	var params = '';
	params += order.length != 0 ? `${order}&` : '';
	params += query.length != 0 ? `${query}&` : '';
	params += search.length != 0 ? `${search}` : '';
	axios
		.get(`/lpo/offset/${offset}/limit/${limit}?${params}`)
		.then((response) => {
			dispatch({ type: GET_LPOS, payload: response.data });
		})
		.catch((err) => {});
};

export const getLPO = (id) => async (dispatch) => {
	axios
		.get(`lpo/${id}`)
		.then((response) => {
			dispatch({ type: GET_LPO, payload: response.data });
		})
		.catch((err) => {});
};

export const updateLPO = (formValues) => async (dispatch) => {
	axios
		.put(`lpo/${formValues.lpo_id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_LPO, payload: response.data });
			history.push(Routes.Procurement.LPO.Index.path);
			toast.success('LPO has been updated.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		})
		.catch((err) => {
			toast.error('Something went wrong! Please open a ticket.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		});
};

export const deleteLPO = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`lpo/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_LPO, payload: response.data });
			setDeleted(!deleted);
			toast.success('LPO has been deleted.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		})
		.catch((err) => {
			toast.error('Something went wrong! Please open a ticket.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		});
};
