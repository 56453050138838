import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';
import { CREATE_LOCATIONS, DELETE_LOCATIONS, GET_LOCATION, GET_LOCATIONS, UPDATE_LOCATIONS } from './types';

export const createLocation = (formValues) => async (dispatch, getState) => {
	axios
		.post('/construction/location', formValues)
		.then((response) => {
			dispatch({ type: CREATE_LOCATIONS, payload: response.data });
			history.push(Routes.Construction.Locations.Index.path);
			toast.success('Location has been added.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		})
		.catch((err) => {
			toast.error('Something went wrong! Please open a ticket.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		});
};

export const getLocations = () => async (dispatch) => {
	const response = await axios.get('/construction/location');
	dispatch({ type: GET_LOCATIONS, payload: response.data });
};

export const getLocationsWithFilter = (offset, limit, query, order, search) => async (dispatch) => {
	var params = '';
	params += order.length != 0 ? `${order}&` : '';
	params += query.length != 0 ? `${query}&` : '';
	params += search.length != 0 ? `${search}` : '';
	axios
		.get(`/construction/location/offset/${offset}/limit/${limit}?${params}`)
		.then((response) => {
			dispatch({ type: GET_LOCATIONS, payload: response.data });
		})
		.catch((err) => {});
};

// export const getLocationsContents = () => async (dispatch) => {
// 	const response = await axios.get('/construction/location/content/');
// 	dispatch({ type: GET_LOCATIONS_CONTENTS, payload: response.data });
// };

export const getLocation = (id) => async (dispatch) => {
	const response = await axios.get(`/construction/location/${id}`);
	dispatch({ type: GET_LOCATION, payload: response.data });
};

export const deleteLocations = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`/construction/location/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_LOCATIONS, payload: response.data });
			setDeleted(!deleted);
			toast.success('Location has been deleted.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		})
		.catch((err) => {
			toast.error('Something went wrong! Please open a ticket.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		});
};

export const updateLocations = (formValues) => async (dispatch) => {
	axios
		.put(`/construction/location/${formValues.building_id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_LOCATIONS, payload: response.data });
			history.push(Routes.Construction.Locations.Index.path);
			toast.success('Location has been updated.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		})
		.catch((err) => {
			toast.error('Something went wrong! Please open a ticket.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		});
};
