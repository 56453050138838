import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import WorkflowForm from './WorkflowForm';

import { getWorkflow, updateWorkflow, getForms, getModules } from '../../../actions/Workflow/workflowActions';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { clearData } from '../../../actions/globalActions';
import { getUsers, getRoles } from '../../../actions/User/userActions';
import { getEntities } from '../../../actions/Admin/entityActions';
import { getDepartments } from '../../../actions/Admin/departmentActions';

const WorkflowEdit = (props) => {
	const { id } = props.match.params;
	const { data, loading } = props;

	useEffect(() => {
		props.getWorkflow(id);
		props.getForms();
		props.getModules();
		props.getEntities();
		props.getDepartments();
		props.getRoles();
		props.getUsers();
		return () => {
			props.clearData();
		};
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.updateWorkflow(formValues);
		}
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<WorkflowForm onSubmit={onSubmit} datas={data} initial={data.workflow} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		user: { users, getUsersLoading, getRolesLoading },
		admin: {
			entity: { entities, getEntitiesLoading },
			department: { getDepartmentsLoading },
		},
		workflow: { workflow, getWorkflowLoading, forms, getFormsLoading, modules, getModulesLoading },
	} = state;

	return {
		data: { workflow, forms, modules },
		loading:
			getWorkflowLoading ||
			getFormsLoading ||
			getModulesLoading ||
			getUsersLoading ||
			getEntitiesLoading ||
			getDepartmentsLoading ||
			getRolesLoading,
	};
};

export default connect(mapStateToProps, {
	getWorkflow,
	updateWorkflow,
	clearData,
	getForms,
	getModules,
	getUsers,
	getEntities,
	getDepartments,
	getRoles,
})(WorkflowEdit);
