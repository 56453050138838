import { getBezierPath, getEdgeCenter } from 'react-flow-renderer';

import './edge.css';

const foreignObjectSize = 40;

const onEdgeClick = (evt, id) => {
	evt.stopPropagation();
	alert(`remove ${id}`);
};

export default function CustomEdge({
	id,
	sourceX,
	sourceY,
	targetX,
	targetY,
	sourcePosition,
	targetPosition,
	style = {},
	data,
	markerEnd,
}) {
	const edgePath = getBezierPath({
		sourceX,
		sourceY,
		sourcePosition,
		targetX,
		targetY,
		targetPosition,
	});
	const [edgeCenterX, edgeCenterY] = getEdgeCenter({
		sourceX,
		sourceY,
		targetX,
		targetY,
	});

	return (
		<>
			<path
				id={id}
				style={{ ...style, strokeWidth: '3' }}
				className="react-flow__edge-path"
				d={edgePath}
				markerEnd={markerEnd}
			/>
			<text>
				<textPath href={`#${id}`} style={{ fontSize: '12px' }} startOffset="50%" textAnchor="middle">
					{data?.text}
				</textPath>
			</text>
			{/* <foreignObject
				width={foreignObjectSize}
				height={foreignObjectSize}
				x={edgeCenterX - foreignObjectSize / 2}
				y={edgeCenterY - foreignObjectSize / 2}
				className="edgebutton-foreignobject"
				requiredExtensions="http://www.w3.org/1999/xhtml">
				<body>
					<button className="edgebutton" onClick={(event) => onElementRemove(id)}>
						×
					</button>
				</body>
			</foreignObject> */}
		</>
	);
}
