import { Card, Col, Row } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListGroup } from '@themesberg/react-bootstrap';
import { getPunchList } from '../../../../actions/Construction/punchListActions';

import LoadingSpinner from '../../../../components/LoadingSpinner';
import Timeline from '../../../../components/Timeline/Timeline';
import { useTranslation } from 'react-i18next';

const PunchListDetails = (props) => {
	const punch_list_id = props.match.params.id;

	const [deleted, setDeleted] = useState(false);

	const { punchList, timeline, loading, locations } = props;
	const { t } = useTranslation();

	useEffect(() => {
		props.getPunchList(punch_list_id);
	}, [deleted]);

	console.log(punchList);

	const renderContent = (content) => {
		return content.map((data, index) => {
			return (
				<tr key={index}>
					<th className="fs-6" scope="row">
						{data.number || '-'}
					</th>
					<th className="fs-6">{data.location_building || '-'}</th>
					<th className="fs-6">{data.location_place || '-'}</th>
					<th className="fs-6">{data.location_floor || '-'}</th>
					<th className="fs-6">{data.punch_description || '-'}</th>
					<th className="fs-6">{data.discipline || '-'}</th>
					<th className="fs-6">{data.controller || '-'}</th>
					<th className="fs-6 ">{new Date(data.start_date).toLocaleDateString() || '-'}</th>
					<th className="fs-6 ">{new Date(data.end_date).toLocaleDateString() || '-'}</th>
					<th className="fs-6 ">{data.status || '-'}</th>
					<th className="fs-6 ">{data.contractor_name || '-'}</th>
					<th className="fs-6 ">{data.subcontractor_name || '-'}</th>
					<th className="fs-6 ">{data.consultant_name || '-'}</th>
				</tr>
			);
		});
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<Row className="mb-3">
					<Col lg={9} md={12} className="pt-4 pb-2">
						<Card className="p-0 ">
							<Card.Header className="border-bottom-0 px-4 my-auto">
								<h4 className="mb-0">{punchList.title}</h4>
							</Card.Header>
							<Card.Body className="px-2 py-0">
								<ListGroup className=" border-top mb-4">
									<ListGroup.Item className="border-bottom">
										<Row>
											<Col>
												<h6 className="mb-1">{t('project')}</h6>
												<small className="text-gray-700">{punchList.project}</small>
											</Col>
											<Col>
												<h6 className="mb-1">{t('project-number')}</h6>
												<small className="text-gray-700">{punchList.number}</small>
											</Col>
										</Row>
									</ListGroup.Item>
									<ListGroup.Item className="border-bottom">
										<Row>
											<Col>
												<h6 className="mb-1">{t('contractor')}</h6>
												<small className="text-gray-700">{punchList.contractor}</small>
											</Col>
											<Col>
												<h6 className="mb-1">{t('contract')}</h6>
												<small className="text-gray-700">{punchList.contract_title}</small>
											</Col>
										</Row>
									</ListGroup.Item>
									<ListGroup.Item className="border-bottom">
										<Row>
											<Col>
												<h6 className="mb-1">{t('revision')}</h6>
												<small className="text-gray-700">{punchList.revision}</small>
											</Col>
											<Col>
												<h6 className="mb-1">{t('issued-by')}</h6>
												<small className="text-gray-700">{punchList.issued_by}</small>
											</Col>
										</Row>
									</ListGroup.Item>

									<ListGroup.Item className="border-bottom">
										<Row>
											<Col>
												<h6 className="mb-1">{t('date-0')}</h6>
												<small className="text-gray-700">{new Date(punchList.date).toLocaleDateString()}</small>
											</Col>
										</Row>
									</ListGroup.Item>
									<ListGroup.Item className="border-bottom">
										<Row>
											<Col>
												<h6 className="mb-1">{t('building')}</h6>
												<small className="text-gray-700">{punchList.construction_building.building_name}</small>
											</Col>
											<Col>
												<h6 className="mb-1">{t('place')}</h6>
												<small className="text-gray-700">{punchList.construction_place.place_name}</small>
											</Col>
											<Col>
												<h6 className="mb-1">{t('floor')}</h6>
												<small className="text-gray-700">{punchList.construction_floor.floor_name}</small>
											</Col>
											<Col>
												<h6 className="mb-1">{t('design-reference-number')}</h6>
												<small className="text-gray-700">{punchList.reference_number}</small>
											</Col>
										</Row>
									</ListGroup.Item>
									<ListGroup.Item className="border-bottom">
										<Row>
											<Col>
												<h6 className="mb-1">{t('employer')}</h6>
												<small className="text-gray-700">{punchList.author_employer}</small>
											</Col>
											<Col>
												<h6 className="mb-1">{t('company')}</h6>
												<small className="text-gray-700">{punchList.author_company}</small>
											</Col>
											<Col>
												<h6 className="mb-1">{t('consultant-0')}</h6>
												<small className="text-gray-700">{punchList.author_consultant}</small>
											</Col>
											<Col>
												<h6 className="mb-1">{t('general-contractor')}</h6>
												<small className="text-gray-700">{punchList.general_contractor}</small>
											</Col>
										</Row>
									</ListGroup.Item>
								</ListGroup>
								<div className="overflow-auto my-3">
									<table className="table table-striped text-center rounded ">
										<thead className="thead-light">
											<tr>
												<th scope="col">#</th>
												<th scope="col">{t('building')}</th>
												<th scope="col">{t('place')}</th>
												<th scope="col">{t('floor')}</th>
												<th scope="col">{t('punch-description')}</th>
												<th scope="col">{t('discipline')}</th>
												<th scope="col">{t('controller')}</th>
												<th scope="col">{t('start-date')}</th>
												<th scope="col">{t('end-date')}</th>
												<th scope="col">{t('status')}</th>
												<th scope="col">{t('contractor')}</th>
												<th scope="col">{t('subcontractor')}</th>
												<th scope="col">{t('consultant-0')}</th>
											</tr>
										</thead>
										<tbody>
											{_.isEmpty(punchList.punch_list_contents) ? (
												<tr>
													<td colSpan="100%">Loading...</td>
												</tr>
											) : (
												renderContent(punchList.punch_list_contents)
											)}
										</tbody>
									</table>
								</div>
								<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
									<a onClick={() => window.history.back()} className="btn btn-secondary">
										{t('back')}{' '}
									</a>
									<a
										className="btn flex-end btn-secondary mx-2 px-3 "
										target="blank"
										href={`${process.env.REACT_APP_SHAREPOINT_BASE_URL}${process.env.REACT_APP_SHAREPOINT_DOCUMENTS}Construction`}>
										<FontAwesomeIcon className="me-2" icon={faFile} />
										{t('documents')}{' '}
									</a>
								</div>
							</Card.Body>
						</Card>
					</Col>
					<Col lg={3} md={12} className="py-4">
						<Timeline timelines={timeline} />
					</Col>
				</Row>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	console.log(state.construction.punchList.punchList.timeline);
	return {
		punchList: state.construction.punchList.punchList.punch_list,
		timeline: state.construction.punchList.punchList.timeline,
		loading: state.construction.punchList.getPunchListLoading,
	};
};

export default connect(mapStateToProps, { getPunchList })(PunchListDetails);
