import React, { Component } from 'react';
import SuppliersForm from './SuppliersForm';
import { createSupplier } from '../../../../actions//Procurement/suppliersActions';
import { connect } from 'react-redux';

const SuppliersCreate = (props) => {
	const onSubmit = (formValues) => {
		console.log('c');

		if (!_.isEmpty(formValues) && formValues.isValid) {
			props.createSupplier(formValues);
		}
	};
	return (
		<div className="mt-3">
			<SuppliersForm onSubmit={onSubmit} />
		</div>
	);
};

export default connect(null, { createSupplier })(SuppliersCreate);
