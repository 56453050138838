import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import EmployeeForm from './EmployeeForm';
import { updateEmployee, getEmployee } from '../../../../actions/HumanResources/employeeActions';
import { clearData } from '../../../../actions/globalActions';
import { getDepartments } from '../../../../actions/Admin/departmentActions';
import { getEntities } from '../../../../actions/Admin/entityActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';

const EmployeeEdit = (props) => {
	const { id } = props.match.params;

	const { datas, loading, initials } = props;

	useEffect(() => {
		props.getEmployee(id);
		props.getDepartments();
		props.getEntities();

		return () => {
			props.clearData();
		};
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.updateEmployee(_.omit(formValues, ['full_name']));
		}
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<EmployeeForm onSubmit={onSubmit} datas={datas} initials={initials} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		admin: {
			entity: { entities, getEntitiesLoading },
			department: { departments, getDepartmentsLoading },
		},
		humanResources: {
			employee: { employee, getEmployeeLoading },
		},
	} = state;

	return {
		datas: { companies: entities, departments },
		initials: { ...employee },
		loading: getEntitiesLoading || getDepartmentsLoading || getEmployeeLoading,
	};
};

export default connect(mapStateToProps, { updateEmployee, getEmployee, clearData, getEntities, getDepartments })(
	EmployeeEdit
);
