import { CREATE_ITEM, GET_ITEMS, GET_ITEM, DELETE_ITEM, UPDATE_ITEM } from './types';
import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';

export const createItem = (formValues) => async (dispatch, getState) => {
	console.log(formValues);
	axios
		.post('/item-category', formValues)
		.then((response) => {
			dispatch({ type: CREATE_ITEM, payload: response.data });
			history.push(Routes.Procurement.Item.Index.path);
		})
		.catch((err) => {});
};

export const getItems = () => async (dispatch) => {
	axios
		.get('/item-category')
		.then((response) => {
			dispatch({ type: GET_ITEMS, payload: response.data });
		})
		.catch((err) => {});
};

export const getItemsWithFilters = (offset, limit, query, order, search) => async (dispatch) => {
	var params = '';
	params += order.length != 0 ? `${order}&` : '';
	params += query.length != 0 ? `${query}&` : '';
	params += search.length != 0 ? `${search}` : '';

	axios
		.get(`/item-category/offset/${offset}/limit/${limit}?${params}`)
		.then((response) => {
			dispatch({ type: GET_ITEMS, payload: response.data });
		})
		.catch((err) => {});
};

export const getItem = (id) => async (dispatch) => {
	axios
		.get(`/item-category/${id}`)
		.then((response) => {
			dispatch({ type: GET_ITEM, payload: response.data });
		})
		.catch((err) => {});
};

export const deleteItem = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`/item-category/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_ITEM, payload: response.data });
			setDeleted(!deleted);
		})
		.catch((err) => {});
};

export const updateItem = (formValues) => async (dispatch) => {
	axios
		.put(`/item-category/${formValues.item_category_code}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_ITEM, payload: response.data });
			history.push(Routes.Procurement.Item.Index.path);
		})
		.catch((err) => {});
};
