import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Form, Image, ListGroup, Row } from '@themesberg/react-bootstrap';
import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BgImage from '../../../assets/img/pages/login.jpg';
import { loginRequest } from '../../../auth/authConfig';
import history from '../../../history';
import logo from '../../../img/logo-white.png';
import logoMobile from '../../../img/mini.png';
import { Routes } from '../../../routes';

export default (props) => {
	const { inProgress, instance, accounts } = useMsal();
	const { t } = useTranslation();
	const isAuthenticated = useIsAuthenticated();

	const handleLogin = () => {
		instance
			.loginRedirect(loginRequest)
			.then(() => {
				console.log('SIGNED ID');
			})
			.catch((e) => {
				console.log(e);
			});
	};

	useEffect(() => {
		if (isAuthenticated) {
			history.push(Routes.Overview.path);
		}
	}, [isAuthenticated]);

	return (
		<main>
			<Row style={{ height: '100vh', overflowY: 'auto' }}>
				<Col
					xl={6}
					md={4}
					sm={12}
					xs={12}
					className="d-flex justify-content-center  signin-image"
					style={{
						background: `linear-gradient(rgba(34,34,34,1),rgba(34,34,34,0.3)), url(${BgImage})`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
						backgroundPosition: 'center',
					}}>
					<Image src={logo} className="signin-logo" />
					<div className="mx-4  my-auto align-self-center signin-welcome">
						<h1 className="mb-0 text-center align-self-center" style={{ maxWidth: '400px' }}>
							{t('saportif-technology-digital-gateway')}{' '}
						</h1>
					</div>
				</Col>
				<Col xl={6} md={8} sm={12} xs={12}>
					<Row className="align-content-center bg-white px-4 p-lg-5 pt-1  h-100">
						<Image src={logoMobile} className="signin-logo-mobile mx-auto" />
						<div className="text-center text-md-center mb-1">
							<h2 className="mb-0" style={{ fontWeight: 'bold' }}>
								{t('welcome-back')}{' '}
							</h2>
							<p className="mb-0">{t('to-login-please-use-the-buttons-bellow')}</p>
						</div>
						<Form className="mt-4  mb-3 ">
							<Row>
								<Col>
									<Card className="h-100">
										<Card.Body className="px-0">
											<p className="mx-3">
												{t(
													'saportif-technology-portal-is-an-web-based-application-designed-as-a-digital-tool-for-saportif-technology-personel-portal-can-be-used-for'
												)}{' '}
											</p>
											<ListGroup className="mx-lg-5 mx-xl-5 mx-md-5   mx-sm-5 mx-xs-0	 ">
												<ListGroup.Item>
													<FontAwesomeIcon icon={faCheck} /> {t('company-wide-applications')}
												</ListGroup.Item>
												<ListGroup.Item>
													<FontAwesomeIcon icon={faCheck} /> {t('business-processes-and-workflows')}
												</ListGroup.Item>
												<ListGroup.Item>
													<FontAwesomeIcon icon={faCheck} /> {t('management-and-reporting-tools')}
												</ListGroup.Item>
												<ListGroup.Item className="mt-4">
													<Button variant="primary" onClick={() => handleLogin()} className="w-100 ">
														{t('to-the-portal')}{' '}
													</Button>
												</ListGroup.Item>
												<ListGroup.Item>
													<Button variant="outline-primary" as="a" href="https://www.office.com/" className="w-100 ">
														{t('to-the-services')}{' '}
													</Button>
												</ListGroup.Item>
											</ListGroup>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</Form>
					</Row>
				</Col>
			</Row>
		</main>
	);
};
