import React, { Component, useEffect, useState } from 'react';
import { Breadcrumb, Col, Row, Toast, Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEdit,
	faEye,
	faHome,
	faHourglass,
	faPencilAlt,
	faTrash,
	faUser,
	faWarehouse,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getProjectsWithFilter, deleteProject } from '../../../../actions/Construction/projectActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import IndexTable from '../../../../components/IndexTable';
import onDelete from '../../../../helpers/deleteFunction';
import { clearData } from '../../../../actions/globalActions';
import authorizationCheckFunction from '../../../../helpers/authorizationCheckFunction';
import { Routes } from '../../../../routes';
import TableFilter from '../../../../components/TableFilter';
import ReactPaginate from 'react-paginate';
import { indexTableFilterQueryGenerator } from '../../../../helpers/helpers';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const ProjectsIndex = (props) => {
	const { projects, loading } = props;
	const [deleted, setDeleted] = useState(false);
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [query, setQuery] = useState('');
	const [filters, setFilters] = useState([]);
	const { t } = useTranslation();
	const initialFilterCategories = [
		{ label: t('company'), displayField: null, value: 'company.company_name', queryName: t('company') },
		{ label: t('status'), displayField: null, value: 'status', queryName: t('status') },
	];
	const [filterCategories, setFilterCategories] = useState(initialFilterCategories);

	useEffect(() => {
		props.getProjectsWithFilter(offset, limit, query);

		return () => {
			props.clearData();
		};
	}, [deleted, limit, offset, query]);

	useEffect(() => {
		indexTableFilterQueryGenerator(filters, setQuery);
	}, [filters.length]);

	const renderRows = () => {
		return projects.map((val, index) => {
			if (index != projects.length - 1) {
				return (
					<tr key={index}>
						<td className="fw-bold text-center">{index + 1}</td>
						<td className="fw-bold text-center">{val.project_name}</td>
						<td className="fw-bold text-center">{val.status}</td>
						<td className="fw-bold text-center">{new Date(val.start_date).toLocaleDateString()}</td>
						<td className="fw-bold text-center">{new Date(val.estimated_due_date).toLocaleDateString()}</td>
						<td className="fw-bold text-center">
							<button
								onClick={() => {
									onDelete(deleted, setDeleted, props.deleteProject, val.project_id);
								}}
								className="btn btn-danger mx-2">
								<FontAwesomeIcon icon={faTrash} />
							</button>
							<Link
								to={Routes.Construction.Project.Details.path.replace(':id', val.project_id)}
								className="btn btn-primary mx-2">
								<FontAwesomeIcon icon={faEye} />
							</Link>
							<Link
								to={Routes.Construction.Project.Edit.path.replace(':id', val.project_id)}
								className="btn btn-primary mx-2">
								<FontAwesomeIcon icon={faEdit} />
							</Link>
						</td>
					</tr>
				);
			} else {
				return null;
			}
		});
	};

	// REACT PAGINATION ON CLICK FUNCTION
	const handlePageClick = (event) => {
		setOffset(event.selected);
	};

	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0 ">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item>{t('construction-0')} </Breadcrumb.Item>
						<Breadcrumb.Item active>{t('projects-0')} </Breadcrumb.Item>
					</Breadcrumb>
				</div>

				<div
					className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-5
          ">
					<h4 style={{ display: 'inline' }}>{t('construction-projects')}</h4>
					<Link className="btn btn-primary py-2 px-3  " to={Routes.Construction.Project.Create.path}>
						{t('add-new')}{' '}
					</Link>
				</div>
				<p className="mb-0">{t('your-most-recent-construction-projects-can-be-seen-here')}</p>
			</div>

			<IndexTable
				loading={loading}
				headerProps="text-center"
				headers={['#', t('project-name'), t('status'), t('start-date'), t('due-date'), '']}
				renderRows={projects.length != 0 ? renderRows() : null}
				filter={
					<div className="mb-3">
						<TableFilter
							initialFilterCategories={initialFilterCategories}
							filters={filters}
							setFilters={setFilters}
							datas={projects}
							setQuery={setQuery}
							filterCategories={filterCategories}
							setFilterCategories={setFilterCategories}
						/>
					</div>
				}
				pagination={
					projects.length > 1 ? (
						<ReactPaginate
							nextLabel=" >"
							onPageChange={handlePageClick}
							pageCount={Math.ceil(_.last(projects).page_count)}
							pageRangeDisplayed={3}
							previousLabel="< "
							pageClassName="page-item"
							pageLinkClassName="page-link"
							previousClassName="page-item"
							forcePage={offset}
							previousLinkClassName="page-link"
							nextClassName="page-item"
							nextLinkClassName="page-link"
							breakLabel="..."
							breakClassName="page-item"
							breakLinkClassName="page-link"
							containerClassName="pagination"
							activeClassName="active"
							renderOnZeroPageCount={null}
						/>
					) : null
				}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		auth: { policies, companyFilter },
		construction: {
			project: { projects, getProjectsLoading },
		},
	} = state;
	return {
		projects,
		loading: getProjectsLoading,
		userPolicy: policies,
		companyFilter,
	};
};

export default connect(mapStateToProps, {
	getProjectsWithFilter,
	deleteProject,
	clearData,
})(ProjectsIndex);
