import { Card, Col, Row } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, Field, formValueSelector, initialize, reduxForm } from 'redux-form';
import renderDropdown from '../../../../components/formComponents/renderDropdown';
import renderInput from '../../../../components/formComponents/renderInput';
import SubmitButton from '../../../../components/formComponents/SubmitButton';

// import validate from '../../../../validate';
import { nonZero, notEmpty, required } from '../../../../helpers/validator';

import { clearData } from '../../../../actions/globalActions';
import renderMultiselect from '../../../../components/formComponents/renderMultiselect';
import UserPolicyTable from './UserPolicyTable';

import { changeActionStructure, changeCompanyStructure, checkViewCheckbox } from '../../../../helpers/helpers';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const selector = formValueSelector('user');

const UserForm = (props) => {
	const { onSubmit, roles, entities, initials, invalid, formChecks, departments, selectedRoles = [] } = props;
	console.log('ROLES', selectedRoles);
	console.log('LENGTH', selectedRoles.length);
	const { policies } = initials;
	const { t } = useTranslation();
	const [policyViewChecks, setPolicyViewChecks] = useState({
		ADMIN: false,
		ISR: false,
		SUPPLIER: false,
		LPO: false,
		FINANCE: false,
		HUMAN_RESOURCES: false,
		PROJECT_MANAGEMENT: false,
		TRANSPORTATION: false,
		DELIVERY: false,
		IT_DEPARTMENT: false,
	});

	const renderDepartments = () => {
		return departments.map((department, index) => {
			return (
				<option key={index} value={department.department_id}>
					{department.department_name}
				</option>
			);
		});
	};

	// useEffect(() => {
	// 	if (selectedRoles.length != 0) {
	// 		var biggestRole = 0;
	// 		selectedRoles.map((role, index) => {
	// 			RolePolicies.map((obj) => {
	// 				if (obj.role == role.role_name) {
	// 					if (biggestRole < obj.order) {
	// 						biggestRole = obj.order;
	// 					}
	// 				}
	// 			});
	// 		});

	// 		var biggestRoleObj = RolePolicies.find((obj) => obj.order == biggestRole);

	// 		// console.log('BIGGEST ROLE', biggestRoleObj.policies);
	// 		// props.change("policies", biggestRoleObj.policies);
	// 	}
	// }, [selectedRoles.length]);

	// --------------------------------------------------------------------------------------
	//  UNCHECKS THE CHECKBOXES IF THE VIEW IS UNCHECKED
	// UNSELECTS THE MULTISELECT DROPDOWN IF THE COMPANY IS ALL
	const showViewCheckboxes = (policyName, view) => {
		if (policyName == 'ADMIN') {
			if (!view) {
				props.change(`policies[${policyName}].actions.VIEW`, '');
				props.change(`policies[${policyName}].actions.ADD`, ''); // TO DO: INVITE CHECKBOX
				props.change(`policies[${policyName}].actions.EDIT`, '');
				props.change(`policies[${policyName}].actions.DELETE`, '');
				props.change(`policies[${policyName}].companies`, []);
			}
		} else {
			if (!view) {
				props.change(`policies[${policyName}].actions.VIEW`, '');
				props.change(`policies[${policyName}].actions.ADD`, '');
				props.change(`policies[${policyName}].actions.EDIT`, '');
				props.change(`policies[${policyName}].actions.DELETE`, '');
				props.change(`policies[${policyName}].companies`, []);
			}
		}

		if (formChecks[policyName + '_VIEW_VALUE'] == 'VIEW:ALL') {
			props.change(`policies[${policyName}].companies`, []);
		}
	};

	useEffect(() => {
		showViewCheckboxes('ADMIN', policyViewChecks.ADMIN);
	}, [policyViewChecks.ADMIN, formChecks.ADMIN_VIEW_VALUE]);

	useEffect(() => {
		showViewCheckboxes('DELIVERY', policyViewChecks.DELIVERY);
	}, [policyViewChecks.DELIVERY, formChecks.deliveryCompany]);

	useEffect(() => {
		showViewCheckboxes('FINANCE', policyViewChecks.FINANCE);
	}, [policyViewChecks.FINANCE, formChecks.FINANCE_VIEW_VALUE]);

	useEffect(() => {
		showViewCheckboxes('ISR', policyViewChecks.ISR);
	}, [policyViewChecks.ISR, formChecks.ISR_VIEW_VALUE]);
	useEffect(() => {
		showViewCheckboxes('LPO', policyViewChecks.LPO);
	}, [policyViewChecks.LPO, formChecks.LPO_VIEW_VALUE]);
	useEffect(() => {
		showViewCheckboxes('SUPPLIER', policyViewChecks.SUPPLIER);
	}, [policyViewChecks.SUPPLIER, formChecks.SUPPLIER_VIEW_VALUE]);

	useEffect(() => {
		showViewCheckboxes('HUMAN_RESOURCES', policyViewChecks.HUMAN_RESOURCES);
	}, [policyViewChecks.HUMAN_RESOURCES, formChecks.HUMAN_RESOURCES_VIEW_VALUE]);

	useEffect(() => {
		showViewCheckboxes('PROJECT_MANAGEMENT', policyViewChecks.PROJECT_MANAGEMENT);
	}, [policyViewChecks.PROJECT_MANAGEMENT, formChecks.PROJECT_MANAGEMENT_VIEW_VALUE]);

	useEffect(() => {
		showViewCheckboxes('TRANSPORTATION', policyViewChecks.TRANSPORTATION);
	}, [policyViewChecks.TRANSPORTATION, formChecks.TRANSPORTATION_VIEW_VALUE]);

	useEffect(() => {
		showViewCheckboxes('IT_DEPARTMENT', policyViewChecks.IT_DEPARTMENT);
	}, [policyViewChecks.IT_DEPARTMENT, formChecks.IT_DEPARTMENT_VIEW_VALUE]);

	// --------------------------------------------------------------------------------------

	// --------------------------------------------------------------------------------------
	// FOR INITIAL DATAS -> CHANGE STRUCTURE
	const changeDataStructureAndViewCheckOfPolicy = () => {
		Object.keys(policies).map((policyName) => {
			if (policies[policyName]) {
				if (policies[policyName].actions != null) {
					const isChecked = checkViewCheckbox(policies[policyName].actions);
					setPolicyViewChecks((prev) => ({
						...prev,
						[policyName]: isChecked,
					}));

					policies[policyName].actions = changeActionStructure(policies[policyName].actions);
				}

				if (policies[policyName].companies != null) {
					policies[policyName].companies = changeCompanyStructure(policies[policyName].companies, entities);
				}
			}
		});
	};

	// --------------------------------------------------------------------------------------

	useEffect(() => {
		changeDataStructureAndViewCheckOfPolicy();

		props.initialize({
			user: initials.user,
			policies: initials.policies,
		});

		return () => {
			props.clearData();
		};
	}, []);

	useEffect(() => {
		props.change('isValid', !invalid);
	}, [invalid]);

	return (
		<form onSubmit={props.handleSubmit(onSubmit)}>
			<Row>
				<Col xl={12} lg={12} md={12} sm={12} xs={12}>
					<Card border="light" className="bg-white shadow-sm mb-4  w-100">
						<Card.Body>
							<h5 className="mb-4">{t('user-form')}</h5>

							<Row>
								<Col className="mb-3">
									<Field disabled name="user.email" validate={required} component={renderInput} label="E-mail" />
								</Col>
							</Row>
							<Row>
								<Col className="mb-3">
									<Field name="user.first_name" validate={required} component={renderInput} label={t('first-name')} />
								</Col>
							</Row>
							<Row>
								<Col className="mb-3">
									<Field name="user.last_name" validate={required} component={renderInput} label={t('last-name')} />
								</Col>
							</Row>
							<Row>
								<Col className="mb-3">
									<Field
										validate={[required, nonZero]}
										name="user.status"
										renderedOptions={
											<>
												<option value={t('inactive')}>{t('inactive-0')}</option>
												<option value={t('active')}>{t('active-0')}</option>
												<option value={t('on-leave')}>{t('on-leave-0')}</option>
											</>
										}
										component={renderDropdown}
										label={t('status')}
									/>
								</Col>
							</Row>
							<Row>
								<Col className="mb-3">
									<label className="form-label">{t('role')}</label>

									<Field
										validate={[required, notEmpty]}
										name="user.roles"
										component={renderMultiselect}
										data={roles}
										textField="role_name"
										dataKey="role_id"
									/>
								</Col>
							</Row>
							<Row>
								<Col className="mb-3">
									<label className="form-label">{t('company')}</label>
									<Field
										validate={[required, notEmpty]}
										name="user.companies"
										component={renderMultiselect}
										data={entities}
										textField="company_name"
										dataKey="company_id"
									/>
								</Col>
							</Row>
							<Row>
								<Col className="mb-3">
									<Field
										validate={[required, nonZero]}
										name="user.department_id"
										label={t('department-0')}
										component={renderDropdown}
										renderedOptions={renderDepartments()}
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<UserPolicyTable
									formChecks={formChecks}
									entities={entities}
									policyViewChecks={policyViewChecks}
									setPolicyViewChecks={setPolicyViewChecks}
								/>
							</Row>

							<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
								<a onClick={() => window.history.back()} className="btn btn-secondary">
									{t('back')}{' '}
								</a>
								<SubmitButton invalid={invalid} onSubmit={onSubmit} text={t('submit')} />
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</form>
	);
};

const mapStateToProps = (state) => {
	const ADMIN_VIEW_VALUE = selector(state, 'policies.ADMIN.actions.VIEW');
	const ISR_VIEW_VALUE = selector(state, 'policies.ISR.actions.VIEW');
	const LPO_VIEW_VALUE = selector(state, 'policies.LPO.actions.VIEW');
	const SUPPLIER_VIEW_VALUE = selector(state, 'policies.SUPPLIER.actions.VIEW');

	const FINANCE_VIEW_VALUE = selector(state, 'policies.FINANCE.actions.VIEW');
	const HUMAN_RESOURCES_VIEW_VALUE = selector(state, 'policies.HUMAN_RESOURCES.actions.VIEW');
	const PROJECT_MANAGEMENT_VIEW_VALUE = selector(state, 'policies.PROJECT_MANAGEMENT.actions.VIEW');
	const TRANSPORTATION_VIEW_VALUE = selector(state, 'policies.TRANSPORTATION.actions.VIEW');
	const DELIVERY_VIEW_VALUE = selector(state, 'policies.DELIVERY.actions.VIEW');
	const IT_DEPARTMENT_VIEW_VALUE = selector(state, 'policies.IT_DEPARTMENT.actions.VIEW');

	const selectedRoles = selector(state, 'user.roles');

	return {
		formChecks: {
			ADMIN_VIEW_VALUE,
			ISR_VIEW_VALUE,
			LPO_VIEW_VALUE,
			SUPPLIER_VIEW_VALUE,
			FINANCE_VIEW_VALUE,
			HUMAN_RESOURCES_VIEW_VALUE,
			PROJECT_MANAGEMENT_VIEW_VALUE,
			TRANSPORTATION_VIEW_VALUE,
			DELIVERY_VIEW_VALUE,
			IT_DEPARTMENT_VIEW_VALUE,
		},
		selectedRoles,
	};
};

export default compose(
	connect(mapStateToProps, { initialize, clearData, change }),
	reduxForm({ form: 'user' })
)(UserForm);
