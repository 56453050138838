import { Card, Table } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import LoadingSpinner from './LoadingSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp, faSearch } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const IndexTable = ({
	headers,
	renderRows,
	headerProps,
	title,
	cardProps,
	pagination,
	filter,
	loading,
	navigationButtons,
	setOrder,
	setSearch,
	searchable,
}) => {
	const [direction, setDirection] = useState('');
	const [orderVal, setOrderVal] = useState('');
	const { t } = useTranslation();

	const OrderBy = (val) => {
		if (direction.length == 0) {
			setDirection('ASC');
			setOrderVal(val);
			setOrder(`Order=${val}:ASC`);
		} else if (direction == 'ASC') {
			setDirection('DESC');
			setOrderVal(val);
			setOrder(`Order=${val}:DESC`);
		} else {
			setDirection('');
			setOrderVal('');
			setOrder('');
		}
	};

	const renderHeaders = (headers) => {
		return headers.map((header, index) => {
			if (typeof header === 'object') {
				return (
					<th
						key={index}
						className={`border-0 ${headerProps} `}
						onClick={() => (header.sortable ? OrderBy(header.value) : null)}>
						{header.label}{' '}
						{header.sortable ? (
							<FontAwesomeIcon
								className="mx-1"
								icon={orderVal == header.value ? (direction == 'ASC' ? faSortUp : faSortDown) : faSort}
							/>
						) : null}
					</th>
				);
			} else {
				return (
					<th key={index} className={`border-0 ${headerProps} `}>
						{header}
					</th>
				);
			}
		});
	};

	return (
		<Card border="light" className={`shadow-sm mb-4 ${cardProps}`}>
			{title ? <Card.Header className="fs-5 text-center">{title}</Card.Header> : null}
			<Card.Body className="pb-0">
				<div className="d-flex justify-content-between align-items-center pb-3">
					<div>{filter}</div>
					{searchable ? (
						<div className="row">
							<div className="input-group-merge search-bar input-group col-xl-4 col-6">
								<span className="input-group-text">
									<FontAwesomeIcon className="searchIcon" icon={faSearch} />
								</span>
								<input
									type="text"
									className="form-control"
									style={{ maxWidth: '90%' }}
									onChange={(e) => setSearch(`Search=${e.target.value}`)}
								/>
							</div>
						</div>
					) : null}
				</div>
				<Table responsive className="table-centered table-nowrap rounded mb-0 table-hover">
					<thead className="thead-light text-left">
						<tr className="">{renderHeaders(headers)}</tr>
					</thead>
					<tbody>
						{loading ? (
							<tr className="text-center">
								<td colSpan="100%">
									<LoadingSpinner />
								</td>
							</tr>
						) : renderRows != null ? (
							renderRows
						) : (
							<tr className="">
								<td colSpan="100%" className="text-center">
									{t('no-data-found')}
								</td>
							</tr>
						)}
					</tbody>
				</Table>
				<div className="d-flex align-items-center justify-content-end my-4">{pagination}</div>
				{navigationButtons}
			</Card.Body>
		</Card>
	);
};

export default IndexTable;
