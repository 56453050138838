import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faShoppingCart, faCogs, faCog } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb, Col, Nav, Row, Button, Card } from '@themesberg/react-bootstrap';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../../../../../components/LoadingSpinner';

import history from '../../../../../history';
import { Routes } from '../../../../../routes';
import { useTranslation } from 'react-i18next';

const DeliveryPanelMenu = () => {
	const { t } = useTranslation();

	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{t('delivery-panel')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<Nav className="justify-content-center row" variant="tabs" defaultActiveKey={window?.location.href}>
					<Nav.Item className=" col text-center ">
						<Nav.Link
							className=""
							onClick={() => {
								history.push(Routes.Delivery.Panel.Item.ItemIndex.path);
							}}>
							{t('item')}{' '}
						</Nav.Link>
					</Nav.Item>

					<Nav.Item
						className="col text-center bg-primary"
						style={{
							borderTopLeftRadius: '0.5rem',
							borderTopRightRadius: '0.5rem',
						}}>
						<Nav.Link className=" text-white">{t('menu')}</Nav.Link>
					</Nav.Item>
					<Nav.Item className="col text-center ">
						<Nav.Link
							onClick={() => {
								history.push(Routes.Delivery.Panel.Location.Index.path);
							}}>
							{t('location')}{' '}
						</Nav.Link>
					</Nav.Item>
				</Nav>
				<br />
			</div>
		</>
	);
};

export default DeliveryPanelMenu;
