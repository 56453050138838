import { faHourglass, faUser } from '@fortawesome/free-regular-svg-icons';
import { faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { Card, Col, Row } from '@themesberg/react-bootstrap';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import { clearData } from '../../actions/globalActions';
import { getOverviewCounters } from '../../actions/overviewActions';
import IndexTable from '../../components/IndexTable';
import LoadingSpinner from '../../components/LoadingSpinner';
import { CounterWidget } from '../../components/Widgets';

import 'chart.js/auto';
import { Pie } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

const data = {
	labels: ['development-team', 'business-team', 'human-resources', 'devops-team', 'infrastructure-team'],
	datasets: [
		{
			label: 'of-votes',
			data: [12, 19, 3, 8, 2],
			backgroundColor: ['#262B40', '#17a5ce', '#1B998B', '#ADD8E6', '#24CBB8'],
			borderColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'],
			borderWidth: 1,
		},
	],
};

const DashboardOverview = (props) => {
	// const redirectLocation = localStorage.getItem('initalLocation');

	// console.log(redirectLocation);
	// console.log(localStorage.getItem('initalLocation'));

	// if (localStorage.getItem('initalLocation')?.includes('redirect')) {
	// 	console.log('Redirect');
	// 	history.push(redirectLocation.split('=')[1].toString());
	// 	localStorage.setItem('initalLocation', null);
	// }
	const { t } = useTranslation();

	const {
		counters: { allUsers, pendingISR, supplierCount, CompanyTable, ticketCounts },
		loading,
	} = props;
	const { innerWidth: width, innerHeight: height } = window;
	const data = {
		labels: [],
		datasets: [
			{
				label: t('of-votes'),
				data: [],
				backgroundColor: ['#262B40', '#17a5ce', '#1B998B', '#ADD8E6', '#24CBB8'],
				borderColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'],
				borderWidth: 1,
			},
		],
	};
	for (let key in ticketCounts) {
		data.labels.push(key);
		data.datasets[0].data.push(ticketCounts[key]);
	}
	useEffect(() => {
		props.getOverviewCounters();

		return () => {
			props.clearData();
		};
	}, []);

	const renderCompanies = () => {
		return CompanyTable.map((val, index) => {
			return (
				<tr key={index}>
					<td className="text-center">{val.company_name}</td>
					<td className="text-center">{val.user_count}</td>
					<td className="text-center">{val.invite_count}</td>
				</tr>
			);
		});
	};

	const setGraphSize = (width) => {
		if (width >= 1200) {
			//XL
			return 300;
		} else if (992 <= width) {
			//LG
			return 250;
		} else if (768 <= width) {
			//MD
			return 250;
		} else if (576 <= width) {
			//SM
			return 250;
		} else if (336 <= width) {
			//XS
			return 200;
		} else {
			return 250;
		}
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<>
					<Row className="mt-3">
						<Col md={4} sm={4} xs={12} lg={4} className=" mt-2 ">
							<CounterWidget
								icon={faUser}
								category={t('active-users')}
								title={allUsers}
								period={t('last-week-1')}
								percentage="50"
							/>
						</Col>
						<Col md={4} sm={4} xs={12} lg={4} className=" mt-2">
							<CounterWidget
								icon={faWarehouse}
								category={t('suppliers-0')}
								title={supplierCount}
								period={t('last-week-1')}
								percentage="-5"
							/>
						</Col>

						<Col md={4} sm={4} xs={12} lg={4} className=" mt-2">
							<CounterWidget
								title={pendingISR}
								icon={faHourglass}
								category={t('pending-workflows')}
								period={t('last-week-1')}
								percentage="25"
							/>
						</Col>
					</Row>
					<Row className="mt-3">
						<Col xl={8} lg={6} md={12} sm={12} xs={12} className="mt-2">
							<IndexTable headers={[t('company'), t('members'), t('invited-users')]} renderRows={renderCompanies()} />
						</Col>
						<Col xl={4} lg={6} md={12} sm={12} xs={12} className="mt-2">
							<Card>
								<Card.Header>
									{t('activity-by-department')} <h4>{t('saportif-technology')}</h4>
								</Card.Header>
								<Card.Body>
									<Pie
										height={setGraphSize(width)}
										width={setGraphSize(width)}
										options={{
											maintainAspectRatio: false,
										}}
										data={data}
									/>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		counters: state.overview.overviewCounters,
		loading: state.overview.getOverviewCountersLoading,
	};
};

export default connect(mapStateToProps, { getOverviewCounters, clearData })(DashboardOverview);
