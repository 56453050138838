// PARENT -> ENTITY EDIT E BAK
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getProfile, editProfile, createProfile } from '../../../actions//User/userActions';
import ProfileForm from './ProfileForm';
import { clearData } from '../../../actions/globalActions';
import { useTranslation } from 'react-i18next';

const ProfilesEdit = (props) => {
	const { user, loading, user_id, user_profile } = props;
	const initials = () => {};
	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			if (user_profile == null) {
				props.createProfile(formValues);
			} else {
				props.editProfile(formValues);
			}
		}
	};
	const { t } = useTranslation();

	useEffect(() => {
		if (user_id != null) {
			props.getProfile(user_id);
		}

		return () => {
			props.clearData();
		};
	}, [user_id]);

	if (loading) {
		return (
			<div className="d-flex justify-content-center ">
				<div className="spinner-border " role="status">
					<span className="visually-hidden">Loading...</span>
				</div>
			</div>
		);
	} else {
		return (
			<ProfileForm
				onSubmit={onSubmit}
				initials={
					user_profile != null
						? {
								birthday: user_profile.birthday.split('T')[0] || '',
								phone_number: user_profile.phone_number || '',
								mobile_phone_number: user_profile.mobile_phone_number || '',
								profile_id: user_profile.id,
								email: user.email,
								user_id: user.user_id,
								first_name: user.first_name,
								last_name: user.last_name,
						  }
						: {
								user_id: user.user_id,
								first_name: user.first_name,
								last_name: user.last_name,
								email: user.email,
						  }
				}
			/>
		);
	}
};

const mapStateToProps = (state) => {
	return {
		loading: state.user.getUserProfileLoading,
		user_id: state.auth.user_id,
		user: state.user.userProfile,
		user_profile: state.user.userProfile.user_profile,
	};
};

export default connect(mapStateToProps, {
	getProfile,
	editProfile,
	clearData,
	createProfile,
})(ProfilesEdit);
