import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import '../../scss/volt/components/_react-markdown.scss';
import CHANGELOG from '../../markdown/CHANGELOG.md';
import { Card, Row, Col, Accordion } from '@themesberg/react-bootstrap';
import AccordionBody from '@themesberg/react-bootstrap/lib/esm/AccordionBody';

const Changelog = () => {
	const [markdown, setMarkdown] = useState('');

	useEffect(() => {
		fetch(CHANGELOG)
			.then((res) => res.text())
			.then((text) => setMarkdown(text));
	}, []);

	return (
		<div className="body">
			<Row className="mt-3" style={{ width: '100%' }}>
				<Col xl={12} lg={12} md={12} sm={12} xs={12}>
					<Card>
						<ReactMarkdown className="reactMarkdown" children={markdown} />
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default Changelog;
