import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faShoppingCart, faCogs, faCog, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb, Col, Nav, Row, Button, Card } from '@themesberg/react-bootstrap';

import { Link } from 'react-router-dom';

import history from '../../../../../history';
import { Routes } from '../../../../../routes';
import IndexTable from '../../../../../components/IndexTable';

import { getLocations, deleteLocation } from '../../../../../actions/Delivery/locationActions';
import { connect } from 'react-redux';
import onDelete from '../../../../../helpers/deleteFunction';

import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const LocationIndex = (props) => {
	const { datas, loading } = props;
	const { t } = useTranslation();

	const [deleted, setDeleted] = useState(false);

	useEffect(() => {
		props.getLocations();
	}, [deleted]);

	const renderRows = () => {
		return datas.locations.map((location, index) => {
			return (
				<tr key={index}>
					<td className="fw-bold text-center">{index + 1}</td>
					<td className="fw-bold text-center">{location.building_name}</td>
					<td className="fw-bold text-center">{_.toString(_.map(location.floors, 'floor_name'))}</td>
					<td className="fw-bold text-center">
						{location.status == t('active-0') ? (
							<>
								<button
									onClick={() => {
										onDelete(deleted, setDeleted, props.deleteLocation, location.building_id);
									}}
									className="btn btn-danger mx-2">
									<FontAwesomeIcon icon={faTrash} />
								</button>
								<Link
									to={Routes.Delivery.Panel.Location.Edit.path.replace(':id', location.building_id)}
									className="btn btn-primary mx-2">
									<FontAwesomeIcon icon={faEdit} />
								</Link>
							</>
						) : null}
					</td>
				</tr>
			);
		});
	};

	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{t('delivery-panel')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<Nav className="justify-content-center row" variant="tabs" defaultActiveKey={window?.location.href}>
					<Nav.Item className=" col text-center ">
						<Nav.Link
							className=""
							onClick={() => {
								history.push(Routes.Delivery.Panel.Item.ItemIndex.path);
							}}>
							{t('item')}{' '}
						</Nav.Link>
					</Nav.Item>

					<Nav.Item className="col text-center ">
						<Nav.Link
							onClick={() => {
								history.push(Routes.Delivery.Panel.Category.Index.path);
							}}>
							{t('category')}{' '}
						</Nav.Link>
					</Nav.Item>
					{/* 
					<Nav.Item className="col text-center ">
						<Nav.Link
							onClick={() => {
								history.push(Routes.Delivery.Panel.Menu.MenuIndex.path);
							}}>
							Menu
						</Nav.Link>
					</Nav.Item> */}

					<Nav.Item
						className="col text-center bg-primary"
						style={{
							borderTopLeftRadius: '0.5rem',
							borderTopRightRadius: '0.5rem',
						}}>
						<Nav.Link className=" text-white">{t('location')}</Nav.Link>
					</Nav.Item>
				</Nav>
				<br />
				<div
					className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center 
          ">
					<h4 style={{ display: 'inline' }}>{t('locations')}</h4>
					<Link className="btn btn-primary py-2 px-3  " to={Routes.Delivery.Panel.Location.Create.path}>
						{t('add-new')}{' '}
					</Link>
				</div>
			</div>

			<IndexTable
				loading={loading}
				headerProps="text-center"
				headers={['#', t('building'), t('floor'), '']}
				renderRows={datas.locations.length != 0 ? renderRows() : null}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		delivery: {
			location: { locations, getLocationsLoading },
		},
	} = state;

	return { datas: { locations }, loading: getLocationsLoading };
};

export default connect(mapStateToProps, { getLocations, deleteLocation })(LocationIndex);
