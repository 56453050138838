import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faHome, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb, Nav } from '@themesberg/react-bootstrap';

import { connect } from 'react-redux';
import { Routes } from '../../../../routes';
import history from '../../../../history';

import {
	getSuppliers,
	getSuppliersWithFilter,
	deleteSupplier,
} from '../../../../actions//Procurement/suppliersActions';
import IndexTable from '../../../../components/IndexTable';
import { Link } from 'react-router-dom';

import onDelete from '../../../../helpers/deleteFunction';
import { clearData } from '../../../../actions/globalActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import authorizationCheckFunction from '../../../../helpers/authorizationCheckFunction';
import TableFilter from '../../../../components/TableFilter';
import ReactPaginate from 'react-paginate';
import { indexTableFilterQueryGenerator } from '../../../../helpers/helpers';
import { useTranslation } from 'react-i18next';

const SuppliersIndex = (props) => {
	const { t } = useTranslation();

	const {
		suppliers: { suppliers },
		loading,
		userPolicy,
		companyFilter,
	} = props;

	const [deleted, setDeleted] = useState(false);
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [query, setQuery] = useState('');
	const [order, setOrder] = useState('');
	const [search, setSearch] = useState('');

	const [filters, setFilters] = useState([]);

	const initialFilterCategories = [
		{ label: t('company'), displayField: null, value: 'company.company_name', queryName: t('company') },
		{ label: t('status'), displayField: null, value: 'status', queryName: t('status') },
	];
	const [filterCategories, setFilterCategories] = useState(initialFilterCategories);

	useEffect(() => {
		props.getSuppliersWithFilter(offset, limit, query, order, search);

		return () => {
			props.clearData();
		};
	}, [deleted, limit, offset, query, order, search]);

	useEffect(() => {
		indexTableFilterQueryGenerator(filters, setQuery);
	}, [filters.length]);

	const renderRows = () => {
		return suppliers.map((val, index) => {
			if (index != suppliers.length - 1) {
				return (
					<tr key={index}>
						<td className="fw-bold text-center">{index + 1}</td>
						<td className="fw-bold text-center">{val.supplier_name}</td>

						<td
							className={`fw-bold text-center ${
								val.score > 80
									? 'text-success'
									: val.score > 40
									? 'text-warning'
									: val.score != null
									? 'text-danger'
									: ''
							}`}>
							{val.score ? val.score + '%' : '-'}
						</td>
						<td className="fw-bold text-center">{val.recommended ? val.recommended + '%' : '-'}</td>
						<td className="fw-bold text-center">{val.capable ? val.capable + '%' : '-'}</td>
						<td className="fw-bold text-center">{val.status ? val.status : '-'}</td>
						<td className="fw-bold text-center">
							{authorizationCheckFunction(Routes.Procurement.Supplier.Delete.policy, userPolicy) ? (
								<button
									onClick={() => {
										onDelete(deleted, setDeleted, props.deleteSupplier, val.supplier_id);
									}}
									className="btn btn-danger mx-2">
									<FontAwesomeIcon icon={faTrash} />
								</button>
							) : null}
							<Link
								to={Routes.Procurement.Supplier.Detail.path.replace(':id', val.supplier_id)}
								className="btn btn-primary mx-2">
								<FontAwesomeIcon icon={faEye} />
							</Link>
							{authorizationCheckFunction(Routes.Procurement.Supplier.Edit.policy, userPolicy) ? (
								<Link
									to={Routes.Procurement.Supplier.Edit.path.replace(':id', val.supplier_id)}
									className="btn btn-primary mx-2">
									<FontAwesomeIcon icon={faEdit} />
								</Link>
							) : null}
						</td>
					</tr>
				);
			} else {
				return null;
			}
		});
	};

	// REACT PAGINATION ON CLICK FUNCTION
	const handlePageClick = (event) => {
		setOffset(event.selected);
	};

	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0 ">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{t('suppliers-0')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<Nav className="justify-content-center row" variant="tabs" defaultActiveKey={window?.location.href}>
					<Nav.Item
						className="bg-primary col text-center "
						style={{
							borderTopLeftRadius: '0.5rem',
							borderTopRightRadius: '0.5rem',
						}}>
						<Nav.Link
							className=" text-white"
							onClick={() => {
								history.push(Routes.Procurement.Supplier.Index.path);
							}}>
							{t('suppliers-0')}{' '}
						</Nav.Link>
					</Nav.Item>

					<Nav.Item className="col text-center ">
						<Nav.Link
							onClick={() => {
								history.push(Routes.Procurement.SupplierEvaluation.Index.path);
							}}>
							{t('supplier-evaluation')}{' '}
						</Nav.Link>
					</Nav.Item>
				</Nav>
				<br />

				<div
					className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center
          ">
					<h4 style={{ display: 'inline' }}>{t('suppliers-0')}</h4>
					<div
						className="btn btn-primary py-2 px-3  "
						onClick={() => {
							history.push(Routes.Procurement.Supplier.Create.path);
						}}>
						{t('add-new')}{' '}
					</div>
				</div>
				<p className="mb-0">{t('your-most-recent-requests-can-be-seen-here')}</p>
			</div>

			<IndexTable
				loading={loading}
				headerProps="text-center"
				headers={[
					{ label: '#', value: 'supplier_id', sortable: false },
					{ label: t('supplier-name'), value: 'supplier_name', sortable: true },
					{ label: t('score'), value: 'score', sortable: true },
					{ label: t('recommended'), value: 'recommended', sortable: false },
					{ label: t('capable'), value: 'capable', sortable: false },
					{ label: t('status'), value: 'status', sortable: true },
					{ label: '', value: '', sortable: false },
					// , 'Supplier Name', 'Score', 'Recommended', 'Capable', 'Status', ''
				]}
				setOrder={setOrder}
				searchable
				setSearch={setSearch}
				renderRows={suppliers.length != 0 ? renderRows() : null}
				filter={
					<div className="align-self-center">
						<TableFilter
							initialFilterCategories={initialFilterCategories}
							filters={filters}
							setFilters={setFilters}
							datas={suppliers}
							setQuery={setQuery}
							filterCategories={filterCategories}
							setFilterCategories={setFilterCategories}
						/>
					</div>
				}
				pagination={
					suppliers.length > 1 ? (
						<ReactPaginate
							nextLabel=" >"
							onPageChange={handlePageClick}
							pageCount={Math.ceil(_.last(suppliers).page_count)}
							pageRangeDisplayed={3}
							previousLabel="< "
							pageClassName="page-item"
							pageLinkClassName="page-link"
							previousClassName="page-item"
							forcePage={offset}
							previousLinkClassName="page-link"
							nextClassName="page-item"
							nextLinkClassName="page-link"
							breakLabel="..."
							breakClassName="page-item"
							breakLinkClassName="page-link"
							containerClassName="pagination"
							activeClassName="active"
							renderOnZeroPageCount={null}
						/>
					) : null
				}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		suppliers: state.procurement.supplier,
		loading: state.procurement.supplier.getSuppliersLoading,
		user: state.user,
		userPolicy: state.auth.policies,
		companyFilter: state.auth.companyFilter,
	};
};

export default connect(mapStateToProps, {
	getSuppliersWithFilter,
	deleteSupplier,
	clearData,
})(SuppliersIndex);
