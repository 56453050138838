import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';
import {
	GET_PROJECT_MILESTONE,
	GET_PROJECT_MILESTONES,
	CREATE_PROJECT_MILESTONE,
	DELETE_PROJECT_MILESTONE,
	UPDATE_PROJECT_MILESTONE,
} from './types';

export const getProjectMilestones = (project_id) => async (dispatch) => {
	axios
		.get(`project_milestone/project/${project_id}`)
		.then((response) => {
			dispatch({ type: GET_PROJECT_MILESTONES, payload: response.data });
		})
		.catch((err) => {});
};

export const createProjectMilestone = (formValues) => async (dispatch) => {
	axios
		.post('/project_milestone', formValues)
		.then((response) => {
			dispatch({ type: CREATE_PROJECT_MILESTONE, payload: response.data });
			history.push(Routes.Finance.Project.Details.path.replace(':id', formValues.project_id));
		})
		.catch((err) => {});
};

export const getProjectMilestone = (id) => async (dispatch) => {
	axios
		.get(`project_milestone/${id}`)
		.then((response) => {
			dispatch({ type: GET_PROJECT_MILESTONE, payload: response.data });
		})
		.catch((err) => {});
};

export const updateProjectMilestone = (formValues) => async (dispatch) => {
	axios
		.put(`project_milestone/${formValues.id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_PROJECT_MILESTONE, payload: response.data });
			history.push(Routes.Finance.Project.Details.path.replace(':id', formValues.project_id));
		})
		.catch((err) => {});
};

export const deleteProjectMilestone = (id, deleted, setDeleted, project_id) => async (dispatch) => {
	axios
		.delete(`project_milestone/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_PROJECT_MILESTONE, payload: response.data });
			setDeleted(!deleted);

			history.push(Routes.Finance.Project.Details.path.replace(':id', project_id));
		})
		.catch((err) => {});
};
