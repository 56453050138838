import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, ListGroup, Row, Table } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getProject } from '../../../../actions/Construction/projectActions';
import { getProjectBuildings, deleteProjectBuilding } from '../../../../actions/Construction/projectBuildingActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { Routes } from '../../../../routes';

import onDelete from '../../../../helpers/deleteFunction';
import { useTranslation } from 'react-i18next';

const ProjectDetails = (props) => {
	const project_id = props.match.params.id;

	const [deleted, setDeleted] = useState(false);
	const { t } = useTranslation();
	const {
		datas: { project, projectBuildings },
		loading,
	} = props;

	useEffect(() => {
		props.getProject(project_id);
		props.getProjectBuildings(project_id);
	}, [deleted]);

	const renderProjectBuildings = () => {
		return projectBuildings.map((projectBuilding, index) => {
			return (
				<tr key={index}>
					<td className="text-center fw-bold">{projectBuilding.building_name}</td>
					<td className="fw-bold text-center">
						{_.toString(_.map(projectBuilding.construction_project_floors, 'floor_name'))}
					</td>
					<td className="fw-bold text-center">
						{projectBuilding.construction_project_floors.map((floor, index) => {
							return floor.construction_project_places.map((place, index, row) => {
								return index + 1 == row.length ? place.place_name : place.place_name + ',';
							});
						})}
					</td>
					<td className="fw-bold text-center">
						<button
							onClick={() => {
								onDelete(deleted, setDeleted, props.deleteProjectBuilding, projectBuilding.building_id, project_id);
							}}
							className="btn btn-danger mx-2">
							<FontAwesomeIcon icon={faTrash} />
						</button>
						<Link
							to={Routes.Construction.ProjectBuilding.Edit.path.replace(':id', projectBuilding.building_id)}
							className="btn btn-primary mx-2">
							<FontAwesomeIcon icon={faEdit} />
						</Link>
					</td>
				</tr>
			);
		});
	};
	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3 col-lg-12 col-12">
					<Card className="p-0 ">
						<Card.Header className="border-bottom-0 px-4 my-auto">
							<h4 className="mb-0">
								{project.project_name} - {project.project_code}
							</h4>
						</Card.Header>
						<Card.Body className="px-2 py-0">
							<ListGroup className=" border-top mb-4">
								<ListGroup.Item className="border-bottom">
									<Row>
										<Col>
											<h6 className="mb-1">{t('project-manager')}</h6>
											<small className="text-gray-700">{project.project_manager.full_name}</small>
										</Col>
										<Col>
											<h6 className="mb-1">{t('project-company')}</h6>
											<small className="text-gray-700">{project.company.company_name}</small>
										</Col>
									</Row>
								</ListGroup.Item>

								<ListGroup.Item className="border-bottom">
									<Row>
										<Col>
											<h6 className="mb-1">{t('contractor')}</h6>
											<small className="text-gray-700">{project.contractor}</small>
										</Col>
										<Col>
											<h6 className="mb-1">{t('project-code')}</h6>
											<small className="text-gray-700">{project.project_code}</small>
										</Col>
									</Row>
								</ListGroup.Item>

								<ListGroup.Item className="border-bottom">
									<Row>
										<Col>
											<h6 className="mb-1">{t('start-date')}</h6>
											<small className="text-gray-700">
												{new Date(project.start_date).toISOString().split('T')[0]}
											</small>
										</Col>
										<Col>
											<h6 className="mb-1">{t('due-date')}</h6>
											<small className="text-gray-700">
												{new Date(project.estimated_due_date).toISOString().split('T')[0]}
											</small>
										</Col>
									</Row>
								</ListGroup.Item>
								<ListGroup.Item className="border-bottom">
									<Row>
										<Col>
											<h6 className="mb-1">{t('address')}</h6>
											<small className="text-gray-700">{project.address}</small>
										</Col>
										<Col>
											<h6 className="mb-1">{t('status')}</h6>
											<small className="text-gray-700">{project.status}</small>
										</Col>
									</Row>
								</ListGroup.Item>
							</ListGroup>

							<Row className="ms-0 mb-3">
								<Col>
									<h5>{t('project-buildings')}</h5>
								</Col>
								<Col className="text-right">
									<Link
										to={Routes.Construction.ProjectBuilding.Create.path.replace(':project_id', project_id)}
										className="btn btn-secondary">
										{t('add-new')}
									</Link>
								</Col>
							</Row>
							<Table responsive className="table-centered table-nowrap rounded mb-3 table-hover ">
								<thead className="thead-light">
									<tr className="text-center">
										<th>{t('building-name')}</th>
										<th>{t('floor')}</th>
										<th>{t('place')}</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{projectBuildings.length != 0 ? (
										renderProjectBuildings()
									) : (
										<tr>
											<td colSpan="100%" className="text-center">
												{t('no-data-found')}
											</td>
										</tr>
									)}
								</tbody>
							</Table>

							<Row className="mt-3 mb-3 px-2">
								<Col>
									<Link className="btn btn-secondary" to={Routes.Construction.Project.Index.path}>
										{t('back')}{' '}
									</Link>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		construction: {
			project: { project, getProjectLoading },
			projectBuilding: { projectBuildings, getProjectBuildings },
		},
	} = state;

	return {
		datas: { project, projectBuildings },
		loading: getProjectLoading | getProjectBuildings,
	};
};

export default connect(mapStateToProps, { getProject, getProjectBuildings, deleteProjectBuilding })(ProjectDetails);
