import { SAVE_ORDER, CREATE_ORDER, GET_ORDERS, GET_ORDER } from './types';

import axios from '../../apis/axios';
import { Routes } from '../../routes';
import history from '../../history';

export const saveOrder = (order) => async (dispatch) => {
	dispatch({ type: SAVE_ORDER, payload: order });
};

export const createOrder = (formValues) => async (dispatch, getState) => {
	axios
		.post('/delivery/order', formValues)
		.then((response) => {
			dispatch({ type: CREATE_ORDER, payload: response.data });
			dispatch({ type: SAVE_ORDER, payload: [] });

			history.push(Routes.Delivery.Index.path);
		})
		.catch((err) => {
			console.log('ERR', err);
		});
};
export const getOrders = () => async (dispatch, getState) => {
	const state = getState();
	const user_id = state.auth.user_id;
	axios
		.get(`/delivery/orders/${user_id}`)
		.then((response) => {
			dispatch({ type: GET_ORDERS, payload: response.data });
		})
		.catch((err) => {});
};
export const getOrder = (id) => async (dispatch) => {
	axios
		.get(`delivery/order/${id}`)
		.then((response) => {
			dispatch({ type: GET_ORDER, payload: response.data });
		})
		.catch((err) => {});
};
