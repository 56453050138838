/* eslint-disable no-undef */
import axios from 'axios';

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

var store;

export const injectStore = (_store) => {
	store = _store;
};

instance.interceptors.request.use(
	async (config) => {
		config.headers.Authorization = `Bearer ${store.getState().auth.token}`;

		return config;
	},
	(err) => {
		console.log('REQUEST ERROR: ', err);
		return Promise.reject(err);
	}
);

instance.interceptors.response.use(
	function (response) {
		if (response.data.message) {
			console.log(`%c ⚠ RESONSE MESSAGE ⚠ \n ${response.data.message}`, 'color:#00FF00');
		}
		return response;
	},
	function (error) {
		if (error.response.status == 401 || error.response.status == 404 || error.response.status == 403) {
			console.log(`%c ⚠ RESONSE ERROR MESSAGE ⚠ \n ${error.response.data.message}`, 'color:#FFFF00');
		} else if (error.response.status == 500) {
			console.log(`%c ‼ RESONSE ERROR MESSAGE: ‼ \n ${error.response.data.message}`, 'color:#FF0000');
		}
		return Promise.reject(error);
	}
);

export default instance;
