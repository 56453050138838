import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Form, Row } from '@themesberg/react-bootstrap';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, Field, FieldArray, formValueSelector, getFormMeta, initialize, reduxForm } from 'redux-form';
import renderDropdown from '../../../../components/formComponents/renderDropdown';
import renderInput from '../../../../components/formComponents/renderInput';
import renderRadioButton from '../../../../components/formComponents/renderRadioButton';
import renderTextArea from '../../../../components/formComponents/renderTextArea';
import SubmitButton from '../../../../components/formComponents/SubmitButton';
import { changeCompanyStructure } from '../../../../helpers/helpers';
import { Policies } from '../../../../helpers/policies';
// import validate from '../../../../validate';
import { date, nonZero, required } from '../../../../helpers/validator';
import renderContentTableInput from './ISRFormComponents/renderContentTableInput';

const selector = formValueSelector('isr');

const ISRForm = (props) => {
	const [priority, setPriority] = useState(0);
	var { contentValues, initials, auth, invalid, datas, selectedProjectId, entities, startDate } = props;
	var sumBalance = 0;
	var sumAvailability = 0;
	var sumEstimatedValue = 0;
	var sumQuantityRequired = 0;
	const { t } = useTranslation();

	const calculateTableSum = () => {
		contentValues.map((value, index) => {
			value = _.omit(value, 'description', 'bill_of_quantity');

			Object.keys(value).map((val, index) => {
				if (val == 'balance') {
					sumBalance += parseInt(value[val] || 0);
				} else if (val == 'availability') {
					sumAvailability += parseInt(value[val] || 0);
				} else if (val == 'estimated_value') {
					sumEstimatedValue += parseInt(value[val] || 0);
				} else if (val == 'quantity_required') {
					sumQuantityRequired += parseInt(value[val] || 0);
				}
			});
		});
	};

	const renderOptions = (options) => {
		return options.map((option, index) => {
			return (
				<option key={index} value={option}>
					{option}
				</option>
			);
		});
	};
	const renderProjectOptions = (options) => {
		return options.map((option, index) => {
			return (
				<option key={index} value={option.project_id}>
					{option.project_name}
				</option>
			);
		});
	};

	const renderCompanies = () => {
		if (auth.policies.ISR.actions.includes(Policies.ISR.VIEW_SELF)) {
			return auth.companies.map((company, index) => {
				return (
					<option key={index} value={company.company_id}>
						{company.company_name}
					</option>
				);
			});
		} else if (auth.policies.ISR.actions.includes(Policies.ISR.VIEW_ALL)) {
			return entities.map((company, index) => {
				return (
					<option key={index} value={company.company_id}>
						{company.company_name}
					</option>
				);
			});
		} else if (auth.policies.ISR.actions.includes(Policies.ISR.VIEW_COMPANY)) {
			return changeCompanyStructure(auth.policies.ISR.companies, entities).map((company, index) => {
				return (
					<option key={index} value={company.company_id}>
						{company.company_name}
					</option>
				);
			});
		} else {
			return null;
		}
	};

	const calculatePriority = (value) => {
		if (value === t('normal-7-days')) {
			setPriority(7);
		} else if (value === t('urgent-3-days')) {
			setPriority(3);
		} else if (value === t('top-urgent-1-day')) {
			setPriority(1);
		}
	};

	useEffect(() => {
		if (startDate) {
			var nwdate = new Date(startDate);
			nwdate.setDate(nwdate.getDate() + priority);
			props.change('due_date', nwdate.toISOString().split('T')[0]);
		}
	}, [priority, startDate]);

	useEffect(() => {
		if (selectedProjectId && selectedProjectId != 0) {
			const selectedProjectName = datas.projects.filter((obj) => obj.project_id == selectedProjectId)[0].project_name;

			props.change('project_name', selectedProjectName);
			if (props.initials?.reference == undefined || selectedProjectId != props.initials?.project_id) {
				props.change(
					'reference',
					`ISR/${selectedProjectName
						.replace(/[aeiou\s]+|([^aeiou\s])/gm, '$1')
						.toUpperCase()
						.substring(0, 3)}/${new Date().getMonth() + 1}${new Date().getDate()}${new Date().getMilliseconds()}`
				);
			}
			if (selectedProjectId == props.initials?.project_id) {
				props.change('reference', `${props.initials.reference}`);
			}
		} else {
			props.change('reference', '');
		}
	}, [selectedProjectId]);

	if (contentValues) {
		calculateTableSum();
	}
	useEffect(() => {
		props.change('total_balance', sumBalance);
		props.change('total_availability', sumAvailability);
		props.change('total_value', sumEstimatedValue);
		props.change('total_quantity_required', sumQuantityRequired);
		props.change('isValid', !invalid);
	}, [sumBalance, sumAvailability, sumEstimatedValue, sumQuantityRequired, invalid]);
	useEffect(() => {
		{
			!_.isEmpty(initials)
				? props.initialize({
						...initials,
						user: auth.first_name + ' ' + auth.last_name,
						start_date: new Date(initials.start_date).toISOString().split('T')[0],
						due_date: new Date(initials.due_date).toISOString().split('T')[0],
						to: initials.to + '/' + initials.to_email,
						isr_contents:
							initials.isr_contents == 0
								? [
										{
											description: '',
											category: '',
											item: '',
											bill_of_quantity: '',
											unit: null,
											quantity_required: null,
											availability: null,
											balance: null,
											estimated_value: null,
										},
								  ]
								: initials.isr_contents.map((content, index) => {
										initials.isr_contents[index].item = { item_name: content.item_name, item_code: content.item_code };
										initials.isr_contents[index].category = {
											item_category_code: content.item_category_code,
											item_category_name: content.item_category_name,
										};

										return initials.isr_contents[index];
								  }),
				  })
				: props.initialize({
						start_date: new Date().toISOString().split('T')[0],
						due_date: new Date().toISOString().split('T')[0],

						user: auth.first_name + ' ' + auth.last_name,
						isr_contents: [
							{
								category: '',
								item: '',
								description: '',
								bill_of_quantity: '',
								unit: null,
								quantity_required: null,
								availability: null,
								balance: null,
								estimated_value: null,
							},
						],
						from: auth.isRoleLoaded ? auth.full_name : null,
				  });
		}

		props.change('user_id', auth.user_id);
	}, [auth.isRoleLoaded]);
	return (
		<form onSubmit={props.handleSubmit(props.onSubmit)}>
			<Row>
				<Col md={12}>
					<Card border="light" className="bg-white shadow-sm mb-4  w-100">
						<Card.Body>
							<div className="d-flex justify-content-between">
								<h5 className="mb-4">{t('internal-service-request-form')}</h5>
								{(() => {
									if (props.initials != null) {
										let referenceLink = props.initials.reference.split('/').join('-');
										return (
											<a
												className="btn btn-secondary py-2 px-3 "
												target="blank"
												href={`${process.env.REACT_APP_SHAREPOINT_BASE_URL}${process.env.REACT_APP_SHAREPOINT_DOCUMENTS}ISR/${props.initials.company.company_name}/${referenceLink}`}>
												<FontAwesomeIcon className="me-2" icon={faFile} /> {t('documents')}
											</a>
										);
									}
								})()}
							</div>
							<Row className="mb-3 ">
								<Col md={6} sm={12}>
									<Field name="user" label={t('by')} component={renderInput} disabled="disabled" />
								</Col>
								<Col md={6} sm={12}>
									<Field
										name="company_id"
										label={t('company')}
										validate={nonZero}
										component={renderDropdown}
										renderedOptions={renderCompanies()}
									/>
								</Col>
							</Row>
							<hr />
							<Row className="mb-3">
								<Col md={6} sm={12}>
									<Field
										name="request_type"
										label={t('type')}
										validate={nonZero}
										component={renderDropdown}
										renderedOptions={renderOptions([t('material-request'), t('subcontractor-request')])}
									/>
								</Col>
								<Col md={6} sm={12}>
									<Field
										name="from"
										disabled="disabled"
										validate={required}
										label={t('from')}
										component={renderInput}
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col md={6} sm={12}>
									<Field
										name="project_id"
										label={t('project')}
										component={renderDropdown}
										validate={nonZero}
										renderedOptions={renderProjectOptions(datas.projects)}
									/>
								</Col>
								<Col md={6} sm={12}>
									<Field name="reference" disabled="disabled" label={t('reference')} component={renderInput} />
								</Col>
							</Row>
							<Row className="mb-3">
								<Col md={6} sm={12}>
									<Form.Group>
										<Form.Label>{t('level-of-priority')}</Form.Label>
										<Field
											onChange={(value) => calculatePriority(value)}
											name="level_of_priority"
											component={renderRadioButton}
											validate={required}
											options={[
												{ title: t('normal-7-days-0'), value: t('normal-7-days-1') },
												{ title: t('urgent-3-days-0'), value: t('urgent-3-days-1') },
												{
													title: t('top-urgent-1-day-0'),
													value: t('top-urgent-1-day-1'),
												},
											]}
										/>
									</Form.Group>
								</Col>
								<Col md={3} sm={12}>
									<Field
										name="start_date"
										validate={[required, date]}
										inputType="date"
										label={t('date-0')}
										component={renderInput}
									/>
								</Col>
								<Col md={3} sm={12}>
									<Field
										name="due_date"
										inputType={t('date-0')}
										validate={[required, date]}
										label={t('due-date')}
										component={renderInput}
									/>
								</Col>
							</Row>
							<Row>
								<Col className="mb-3">
									<div className="table-responsive">
										<table className="table " style={{ width: 'auto' }}>
											<thead>
												<tr className="text-start text-wrap">
													<th style={{ width: '20%' }}>{t('category')}</th>
													<th style={{ width: '20%' }}>{t('item')}</th>
													<th style={{ width: '20%' }}>
														{t('description')} <br />
														{t('of-works-0')}{' '}
													</th>
													<th style={{ width: '10%' }}>{t('boq-ref')}</th>
													<th style={{ width: '5%' }}>{t('unit')}</th>
													<th style={{ width: '10%' }}>
														QTY <br /> {t('required')}
													</th>
													<th style={{ width: '10%' }}>
														{t('qty-available')} <br /> {t('in-store')}
													</th>
													<th style={{ width: '10%' }}>
														{t('balance-to')} <br /> {t('be-purchased')}
													</th>
													<th style={{ width: '10%' }}>
														{t('estimated')} <br /> {t('value')}
													</th>
													<th style={{ width: '5%' }}></th>
												</tr>
											</thead>
											<tbody className="">
												<FieldArray
													totals={[
														null,
														null,
														null,
														null,
														sumQuantityRequired,
														sumAvailability,
														sumBalance,
														sumEstimatedValue,
													]}
													name="isr_contents"
													change={props.change}
													component={renderContentTableInput}
													categories={datas.categories}
													items={datas.items}
													contentValues={contentValues}
													inputNames={[
														{ name: 'category', col: 1 },
														{ name: 'item', col: 1 },
														{ name: 'description', col: 1 },
														{ name: 'bill_of_quantity', col: 1 },
														{ name: 'unit', col: 1 },
														{ name: 'quantity_required', col: 1 },
														{ name: 'availability', col: 1 },
														{ name: 'balance', col: 1 },
														{ name: 'estimated_value', col: 1 },
													]}
													types={['text', 'text', 'text', 'text', 'text', 'number', 'number', 'number', 'number']}
												/>
											</tbody>
										</table>
									</div>
								</Col>
							</Row>
							<Row className="my-3">
								<Col md={12}>
									<Field
										name="notes"
										label={t('please-add-notes-and-remarks-if-any')}
										component={renderTextArea}
										rows="4"
									/>
								</Col>
							</Row>

							<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
								<a onClick={() => window.history.back()} className="btn btn-secondary">
									{t('back')}{' '}
								</a>
								<SubmitButton invalid={invalid} onSubmit={props.onSubmit} text={t('submit')} />
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</form>
	);
};

const mapStateToProps = (state) => {
	const value = selector(state, 'isr_contents');
	const selectedProjectId = selector(state, 'project_id');
	const selectedProjectName = selector(state, 'project_name');
	const to_user = selector(state, 'to');
	const startDate = selector(state, 'start_date');

	return {
		contentValues: value,
		auth: state.auth,
		to_user: to_user,
		selectedProjectId,
		selectedProjectName,
		entities: state.admin.entity.entities,
		meta: getFormMeta('isr')(state),
		startDate: startDate,
	};
};

export default compose(connect(mapStateToProps, { change, initialize }), reduxForm({ form: 'isr' }))(ISRForm);
