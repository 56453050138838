export const GET_ITEMS = 'GET_ITEMS';
export const CREATE_ITEM = 'CREATE_ITEM';
export const GET_ITEM = 'GET_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const UPDATE_ITEM = 'UPDATE_ITEM';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const GET_CATEGORY = 'GET_CATEGORY';

export const GET_CART = 'GET_CART';

export const GET_LOCATION = 'GET_LOCATION';
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const CREATE_LOCATION = 'CREATE_LOCATION';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const DELETE_LOCATION = 'DELETE_LOCATION';

export const SAVE_ORDER = 'SAVE_ORDER';
export const CREATE_ORDER = 'CREATE_ORDER';
export const GET_ORDER = 'GET_ORDER';
export const GET_ORDERS = 'GET_ORDERS';
export const UPDATE_ORDER = 'UPDATE_ORDER';
