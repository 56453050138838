import { Card, Col, Row } from '@themesberg/react-bootstrap';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, Field, FieldArray, formValueSelector, getFormMeta, initialize, reduxForm } from 'redux-form';

import _ from 'lodash';
import renderDropdown from '../../../../components/formComponents/renderDropdown';
import renderDynamicTableInput from '../../../../components/formComponents/renderDynamicTableInput';
import renderInput from '../../../../components/formComponents/renderInput';
import renderPhoneNumberInput from '../../../../components/formComponents/renderPhoneNumberInput';
import renderSearchableDropdown from '../../../../components/formComponents/renderSearchableDropdown';
import renderTextarea from '../../../../components/formComponents/renderTextArea';
import SubmitButton from '../../../../components/formComponents/SubmitButton';
import { changeCompanyStructure } from '../../../../helpers/helpers';
import { Policies } from '../../../../helpers/policies';
import { nonZero, phoneNumber, required } from '../../../../helpers/validator';
import { useTranslation } from 'react-i18next';

const selector = formValueSelector('lpo');

const LPOForm = (props) => {
	const { t } = useTranslation();

	var {
		contentValues,
		onSubmit,
		suppliers,
		selectedSupplierId,
		entities,
		projects,
		isrs,
		selectedISRId,
		initials,
		paymentTypeValue,
		meta,
		auth,
	} = props;

	// TOTAL VALUE CALCULCATION
	var total = 0;
	var quantityTotal = 0;
	var unitPriceTotal = 0;

	const calculateTableSum = () => {
		contentValues.map((content, index) => {
			content = _.omit(content, 'description', 'unit');

			Object.keys(content).map((value, index) => {
				switch (value) {
					case 'quantity':
						quantityTotal += parseInt(content[value] || 0);
						break;
					case 'unit_price':
						unitPriceTotal += parseInt(content[value] || 0);
						break;
					case 'amount':
						total += parseInt(content[value] || 0);
						break;
				}
			});
		});
	};

	if (contentValues) {
		calculateTableSum();
	}

	useEffect(() => {
		props.change('total_value', total);
	}, [total]);

	// SUPPLIER DROPDOWN CHANGES
	useEffect(() => {
		if (selectedSupplierId && selectedSupplierId != 0) {
			const selectedSupplierObj = suppliers.filter((val) => val.supplier_id == selectedSupplierId)[0];

			props.change('supplier_contact_person_address', selectedSupplierObj.supplier_contact?.address);
			props.change('supplier_contact_person_phone_number', selectedSupplierObj.supplier_contact?.phone_number);
			props.change('supplier_contact_person_fax_number', selectedSupplierObj.supplier_contact?.fax_number);
			props.change('supplier_contact_person_name', selectedSupplierObj.supplier_contact?.contact_name);
		} else {
			props.change('supplier_contact_person_address', '');
			props.change('supplier_contact_person_phone_number', '');
			props.change('supplier_contact_person_fax_number', '');
			props.change('supplier_contact_person_name', '');
		}
	}, [selectedSupplierId]);

	// FORM INITIALIZATION

	useEffect(() => {
		if (!_.isEmpty(initials)) {
			props.initialize({
				...initials,
				date: new Date(initials.date).toISOString().split('T')[0],
				user: auth.first_name + ' ' + auth.last_name,
			});
		} else {
			props.initialize({
				date: new Date().toISOString().split('T')[0],
				lpo_contents: [
					{
						description: '',
						unit: '',
						quantity: null,
						unit_price: null,
						amount: null,
					},
				],
				user: auth.first_name + ' ' + auth.last_name,
				terms_conditions: `1) Above purchase order Number must appear on all invoices / correspondence.\n2) Please forward to our office the following:\n\t- Two copies of your inv.\n\t- Copy of these LPO.-Two copies of your delivery note duly signed by our representative.\n3).If the material is not delivered by the “Delivery on” this LPO is considered cancelled`,
			});
		}

		props.change('user_id', auth.user_id);
	}, []);

	// ISR DROPDOWN CHANGES
	useEffect(() => {
		if (meta && meta.isr_id) {
			if (selectedISRId && selectedISRId != 0 && meta.isr_id.visited) {
				const selectedISRObj = isrs.filter((val) => val.id == selectedISRId)[0];

				props.change('project_id', selectedISRObj.project_id);

				if (props.initials?.reference == undefined || selectedISRObj.project_id != props.initials?.project_id) {
					props.change(
						'reference',
						`LPO/${selectedISRObj.project_name
							.replace(/[aeiou\s]+|([^aeiou\s])/gm, '$1')
							.toUpperCase()
							.substring(0, 3)}/${new Date().getMonth() + 1}${new Date().getDate()}${new Date().getMilliseconds()}`
					);
				}
				if (selectedISRObj.project_id == props.initials?.project_id) {
					props.change('reference', `${props.initials.reference}`);
				}

				var lpoContent = selectedISRObj.isr_contents.map((content) => {
					return {
						description: content.description,
						unit: content.unit,
						quantity: content.quantity_required,
					};
				});

				props.change('checked_by', selectedISRObj.from);
				props.change('authorized_signature', t('general-manager'));
				props.change('received_by', t('finance-department'));
				props.change('lpo_contents', lpoContent);
			} else {
				props.change('project_id', 0);
				props.change('reference', '');
				props.change('lpo_contents', []);
			}
		}
	}, [selectedISRId]);

	// LPO CONTENT VALUES CHANGES
	useEffect(() => {
		if (contentValues) {
			contentValues.map((content, index) => {
				props.change(`lpo_contents[${index}].amount`, content.quantity * content.unit_price || 0);
			});
		}
	}, [quantityTotal, unitPriceTotal]);

	const renderProjectOptions = () => {
		return projects.map((val, index) => {
			return (
				<option key={index} value={val.project_id}>
					{val.project_name}
				</option>
			);
		});
	};

	const renderEntityOptions = () => {
		if (auth.policies.LPO.actions.includes(Policies.LPO.VIEW_SELF)) {
			return auth.companies.map((company, index) => {
				return (
					<option key={index} value={company.company_id}>
						{company.company_name}
					</option>
				);
			});
		} else if (auth.policies.LPO.actions.includes(Policies.LPO.VIEW_ALL)) {
			return entities.map((company, index) => {
				return (
					<option key={index} value={company.company_id}>
						{company.company_name}
					</option>
				);
			});
		} else if (auth.policies.LPO.actions.includes(Policies.LPO.VIEW_COMPANY)) {
			return changeCompanyStructure(auth.policies.LPO.companies, entities).map((company, index) => {
				return (
					<option key={index} value={company.company_id}>
						{company.company_name}
					</option>
				);
			});
		} else {
			return null;
		}
	};

	const renderISROptions = () => {
		return isrs.filter((val, index) => val.status.includes('Approved |'));
	};

	return (
		<form onSubmit={props.handleSubmit(props.onSubmit)}>
			<Row></Row>
			<Row>
				<Col md={12}>
					<Card border="light" className="bg-white shadow-sm mb-4  w-100">
						<Card.Body>
							<h5 className="mb-4">{t('local-purchase-order-form')}</h5>
							<Row className="mb-3 ">
								<Col md={6} sm={12}>
									<Field name="user" label={t('by')} component={renderInput} disabled="disabled" />
								</Col>
								<Col md={6} sm={12}>
									<Field
										validate={nonZero}
										name="company_id"
										label={t('company')}
										component={renderDropdown}
										renderedOptions={renderEntityOptions()}
									/>
								</Col>
							</Row>
							<hr />
							<Row className="mb-3">
								<Col>
									<Field
										validate={required}
										dataKey="supplier_id"
										textField="supplier_name"
										data={suppliers}
										name="supplier_id"
										label={t('supplier')}
										component={renderSearchableDropdown}
									/>
								</Col>
								<Col>
									<Field
										validate={required}
										dataKey="id"
										textField="reference"
										data={isrs.length != 0 ? renderISROptions() : null}
										name="isr_id"
										label={t('internal-service-request')}
										component={renderSearchableDropdown}
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col>
									<Field
										validate={required}
										name="supplier_contact_person_address"
										label={t('supplier-addres')}
										component={renderInput}
									/>
								</Col>
								<Col>
									<Field
										validate={[required, phoneNumber]}
										name="supplier_contact_person_phone_number"
										label={t('phone')}
										component={renderPhoneNumberInput}
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col>
									<Field validate={required} name="supplier_receiver" label={t('attention')} component={renderInput} />
								</Col>
								<Col>
									<Field
										validate={[required, phoneNumber]}
										name="supplier_contact_person_fax_number"
										label={t('fax')}
										component={renderPhoneNumberInput}
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col>
									<Field
										validate={nonZero}
										name="project_id"
										label={t('project')}
										component={renderDropdown}
										renderedOptions={renderProjectOptions()}
									/>
								</Col>
								<Col>
									<Field disabled="disabled" name="reference" label={t('qt-reference')} component={renderInput} />{' '}
								</Col>
							</Row>
							<Row className="mb-3">
								<Col>
									<Field name="date" label={t('date-0')} component={renderInput} inputType="date" />
								</Col>
								<Col></Col>
							</Row>

							<Row>
								<Col className="mb-3">
									<table className="table overflow-auto form-control formTable ">
										<thead>
											<tr>
												<th colSpan={3}>
													{t('description')} <br /> {t('of-works')}
												</th>
												<th>{t('unit')}</th>
												<th>QTY</th>
												<th>{t('unit-price')}</th>
												<th>{t('amount-qar')}</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											<FieldArray
												showAddrow={true}
												name="lpo_contents"
												totals={[null, null, null, null, null, total]}
												component={renderDynamicTableInput}
												inputNames={[
													{ name: 'description', col: 3 },
													{ name: 'unit', col: 1 },
													{ name: 'quantity', col: 1 },
													{ name: 'unit_price', col: 1 },
													{ name: 'amount', col: 1 },
												]}
												types={['text', 'text', 'number', 'number', 'number']}
											/>
										</tbody>
									</table>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col>
									<Field
										validate={required}
										name="supplier_contact_person_name"
										label={t('contact-name')}
										component={renderInput}
									/>
								</Col>
								<Col>
									<Field
										validate={nonZero}
										name="delivery"
										label={t('delivery')}
										component={renderDropdown}
										renderedOptions={
											<>
												<option value={t('normal')}>{t('normal-0')}</option>
												<option value={t('urgent')}>{t('urgent-0')}</option>
												<option value={t('top-urgent')}>{t('top-urgent-0')}</option>
											</>
										}
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col>
									<Field
										validate={[nonZero, required]}
										name="payment_type"
										label={t('payment-type')}
										onChange={(e) => {
											props.change('payment_days', '');
										}}
										component={renderDropdown}
										renderedOptions={
											<>
												<option value={t('in-advanced')}>{t('in-advanced-0')}</option>
												<option value={t('not-in-advanced')}>{t('not-in-advanced-0')}</option>
											</>
										}
									/>
								</Col>
								<Col>
									<Field
										validate={required}
										name="delivery_address"
										label={t('delivery-address')}
										component={renderInput}
									/>
								</Col>
							</Row>
							{paymentTypeValue == t('in-advanced-1') ? (
								<Row className="mb-3">
									<Col>
										<Field
											validate={paymentTypeValue == t('not-in-advanced-1') ? required : null}
											name="payment_days"
											label={t('payment-days')}
											inputType={t('number')}
											component={renderInput}
										/>
									</Col>
									<Col></Col>
								</Row>
							) : null}
							<Row className="mb-3">
								<Col md={12}>
									<Field
										name="terms_conditions"
										label={t('terms-and-conditions-0')}
										component={renderTextarea}
										rows={5}
									/>
								</Col>
							</Row>
							<Row>
								<Col md={4} sm={12}>
									<Field validate={required} name="checked_by" label={t('checked-by')} component={renderInput} />
								</Col>
								<Col md={4} sm={12}>
									<Field
										validate={required}
										name="authorized_signature"
										label={t('authorized-signature')}
										component={renderInput}
									/>
								</Col>
								<Col md={4} sm={12}>
									<Field validate={required} name="received_by" label={t('received-by')} component={renderInput} />
								</Col>
							</Row>
							<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
								<a onClick={() => window.history.back()} className="btn btn-secondary">
									{t('back')}{' '}
								</a>
								<SubmitButton invalid={false} onSubmit={props.onSubmit} text={t('submit')} />
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</form>
	);
};

const mapStateToProps = (state) => {
	const contentValues = selector(state, 'lpo_contents');
	const selectedSupplierId = selector(state, 'supplier_id');
	const selectedISRId = selector(state, 'isr_id');
	const paymentTypeValue = selector(state, 'payment_type');

	return {
		contentValues,
		selectedSupplierId,
		selectedISRId,
		paymentTypeValue,
		meta: getFormMeta('lpo')(state),
		auth: state.auth,
	};
};

export default compose(connect(mapStateToProps, { change, initialize }), reduxForm({ form: 'lpo' }))(LPOForm);
