import React, { useEffect } from 'react';
import Multiselect from 'react-widgets/Multiselect';
import { Field } from 'redux-form';
import renderInput from '../../../../../../components/formComponents/renderInput';

const renderMulitSelectDropdown = ({ input, data }) => {
	return (
		<Multiselect
			{...input}
			onBlur={() => input.onBlur()}
			value={input.value}
			data={data}
			allowCreate="onFilter"
			onCreate={(value) => {
				input.onChange([...input.value, value]);
			}}
		/>
	);
};

export default ({ fields, meta }) => {
	if (fields.length < 1) {
		useEffect(() => {
			fields.push({ floor_name: '', rooms: [] });
		}, []);
	}

	return (
		<>
			{fields.map((parentObjName, index) => {
				return (
					<tr key={index}>
						<td>
							<Field name={`${parentObjName}.floor_name`} component={renderInput} />
						</td>
						<td>
							<Field data={[]} name={`${parentObjName}.rooms`} component={renderMulitSelectDropdown} />
						</td>

						<td>
							<button
								className="form-control table-delete-button"
								onClick={(e) => {
									e.preventDefault();

									fields.remove(index);
								}}>
								<i className="fa fa-trash fa-lg " style={{ color: 'red' }} aria-hidden="true"></i>
							</button>
						</td>
					</tr>
				);
			})}

			<tr>
				<td style={{ borderBottom: 0 }}>
					<button
						className="field btn btn-secondary"
						onClick={(e) => {
							e.preventDefault();
							fields.push({ floor_name: '', rooms: [] });
						}}>
						Add Row
					</button>
				</td>
			</tr>
		</>
	);
};
