const validate = (formValues) => {
	const errors = {};

	// USER EDIT

	if (!formValues.user || formValues.user == '0') {
		errors.user = 'Please select a user';
	}
	if (!formValues.message) {
		errors.message = 'Please enter a message';
	}
	if (!formValues.email) {
		errors.email = 'Please enter a email';
	}
	if (!formValues.first_name) {
		errors.first_name = 'Please enter a first name';
	}
	if (!formValues.last_name) {
		errors.last_name = 'Please enter a last name';
	}
	if (!formValues.role_id || formValues.role_id == '0') {
		errors.role_id = 'Please select a role';
	}
	if (!formValues.company_id || formValues.company_id == '0') {
		errors.company_id = 'Please select a company';
	}
	//PROFILE EDIT
	if (!formValues.birthday) {
		errors.birthday = 'Please enter a birthday.';
	}
	//SUPPLIER
	if (!formValues.supplier_name) {
		errors.supplier_name = 'Please enter a supplier name.';
	}
	if (!formValues.account_id) {
		errors.account_id = 'Please enter a account id.';
	}
	if (!formValues.country || formValues.country == '0') {
		errors.country = 'Please select a country.';
	}
	if (!formValues.phone_number) {
		errors.phone_number = 'Please enter a phone number.';
	}
	if (!formValues.fax_number) {
		errors.fax_number = 'Please enter a fax.';
	}
	if (!formValues.contact_name) {
		errors.contact_name = 'Please enter a contact person.';
	}
	if (!formValues.mobile_phone_number) {
		errors.mobile_phone_number = 'Please enter a mobile phone number.';
	}

	if (!formValues.supplier_id || formValues.supplier_id == '0') {
		errors.supplier_id = 'Please select a supplier';
	}

	// SUPPLIER EVALUATION

	if (!formValues.site_location_name) {
		errors.site_location_name = 'Please enter a site/location name.';
	}
	if (!formValues.site_number) {
		errors.site_number = 'Please enter a site number';
	}
	if (!formValues.material_services) {
		errors.material_services = 'Please enter a material services';
	}
	if (!formValues.report_type) {
		errors.report_type = 'Please select one';
	}
	if (!formValues.suppliers_name) {
		errors.suppliers_name = 'Please select a supplier ';
	}
	if (
		!formValues.date ||
		new Date(formValues.date).getFullYear() - new Date().getFullYear() > 20 ||
		new Date().getFullYear() - new Date(formValues.date).getFullYear() > 20
	) {
		errors.date = 'Please enter a valid date';
	}
	if (!formValues.lpo_no) {
		errors.lpo_no = 'Please enter a lpo no';
	}
	if (!formValues.period) {
		errors.period = 'Please select a period';
	}
	if (!formValues.title) {
		errors.title = 'Please enter a title';
	}
	if (!formValues.request_response) {
		errors.request_response = 'Please select one';
	}
	if (!formValues.delivery_start) {
		errors.delivery_start = 'Please select one';
	}
	if (!formValues.delivery_start_delay && formValues.delivery_start == 'no') {
		errors.delivery_start_delay = 'Please select one';
	}
	if (!formValues.delivery_complete) {
		errors.delivery_complete = 'Please select one';
	}
	if (!formValues.delivery_complete_delay && formValues.delivery_complete == 'no') {
		errors.delivery_complete_delay = 'Please select one';
	}
	if (!formValues.material_delivery) {
		errors.material_delivery = 'Please select one';
	}
	if (!formValues.material_delivery_alternative && formValues.material_delivery == 'no') {
		errors.material_delivery_alternative = 'Please select one';
	}
	if (!formValues.material_quality) {
		errors.material_quality = 'Please select one';
	}
	if (!formValues.material_quantity) {
		errors.material_quantity = 'Please select one';
	}
	if (!formValues.material_quantity_partial && formValues.material_quantity == 'partial') {
		errors.material_quantity_partial = 'Please select one';
	}
	if (!formValues.goods_packaging) {
		errors.goods_packaging = 'Please select one';
	}
	if (!formValues.goods_damages) {
		errors.goods_damages = 'Please select one';
	}
	if (!formValues.recommended_orders) {
		errors.recommended_orders = 'Please select one';
	}
	if (!formValues.capable_orders) {
		errors.capable_orders = 'Please select one';
	}

	//PROJECTS

	if (!formValues.status || formValues.status == '0') {
		errors.status = 'Please select a status.';
	}

	//USER INVITE

	if (!formValues.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {
		errors.email = 'Please enter a valid email address.';
	}
	if (!formValues.role || formValues.role == '0') {
		errors.role = 'Please select a role.';
	}
	if (!formValues.company || formValues.company == '0') {
		errors.company = 'Please select a company.';
	}

	//ISR

	if (!formValues.project_name || formValues.project_name == '0') {
		errors.project_name = 'Please select a project.';
	}
	if (!formValues.request_type || formValues.request_type == '0') {
		errors.request_type = 'Please select a type.';
	}
	if (!formValues.to) {
		errors.to = 'Please enter a name.';
	}
	if (!formValues.from) {
		errors.from = 'Please enter a name.';
	}
	if (!formValues.reference) {
		errors.reference = 'Please enter a reference.';
	}
	if (
		!formValues.start_date ||
		new Date(formValues.start_date).getFullYear() - new Date().getFullYear() > 20 ||
		new Date().getFullYear() - new Date(formValues.start_date).getFullYear() > 20
	) {
		errors.start_date = 'Please enter a valid date.';
	}
	if (
		!formValues.due_date ||
		new Date(formValues.due_date).getFullYear() - new Date().getFullYear() > 20 ||
		new Date().getFullYear() - new Date(formValues.due_date).getFullYear() > 20
	) {
		errors.due_date = 'Please enter a valid  date.';
	}
	if (!formValues.level_of_priority) {
		errors.level_of_priority = 'Please select a level.';
	}

	// ENTITY

	if (!formValues.entityName) {
		errors.entityName = 'Please enter a name.';
	}

	//REGISTER

	if (!formValues.firstName) {
		errors.firstName = 'Please enter a name';
	}

	if (!formValues.lastName) {
		errors.lastName = 'Please enter a name';
	}

	if (!formValues.token) {
		errors.token = 'Please enter a token';
	}
	if (!formValues.email) {
		errors.email = 'Please enter a email';
	}

	//TICKET

	if (!formValues.department) {
		errors.department = 'Please enter the department';
	}
	if (!formValues.category) {
		errors.category = 'Please enter the category';
	}
	if (!formValues.severity) {
		errors.severity = 'Please select a severity';
	}
	if (!formValues.issue) {
		errors.issue = 'Please enter your issue';
	}
	// if (!formValues.error_message) {
	// 	errors.error_message = 'Please enter your error message';
	// }

	return errors;
};

export default validate;
