import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './auth/authConfig';
import { injectStore } from './apis/axios';
import './i18n/config';
// core styles
import './scss/volt.scss';

// vendor styles
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-datetime/css/react-datetime.css';
import './css/react-widgets/styles.css';

import HomePage from './pages/HomePage';
// import ScrollToTop from "./components/ScrollToTop";
import reducers from './reducers';
import reduxThunk from 'redux-thunk';

const msalInstance = new PublicClientApplication(msalConfig);

const composeEnhancers = window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers?.(applyMiddleware(reduxThunk)));

ReactDOM.render(
	<MsalProvider instance={msalInstance}>
		<Provider store={store}>
			<BrowserRouter>
				{/* <ScrollToTop /> */}
				<HomePage />
			</BrowserRouter>
		</Provider>
	</MsalProvider>,
	document.getElementById('root')
);

injectStore(store);
