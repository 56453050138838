import { useMsal } from '@azure/msal-react';
import {
	faBars,
	faBuilding,
	faCaretSquareLeft,
	faCaretSquareRight,
	faCashRegister,
	faCertificate,
	faChartBar,
	faChartPie,
	faClipboardList,
	faCoffee,
	faDesktop,
	faFile,
	faFileAlt,
	faHammer,
	faHandsHelping,
	faMapMarker,
	faProjectDiagram,
	faQuestion,
	faSignOutAlt,
	faTicketAlt,
	faTimes,
	faUserAlt,
	faWarehouse,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Badge, Button, Dropdown, Image, Nav, Navbar } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import SimpleBar from 'simplebar-react';
import { companyFilter } from '../actions/Auth/authActions';
import authorizationCheckFunction, {
	multiplePagesAuthorizationCheckFunction,
} from '../helpers/authorizationCheckFunction';
import logo from '../img/logo-white.png';
import { Routes } from '../routes';

const Sidebar = (props) => {
	const { user } = props;
	const { policies } = user;
	const { instance } = useMsal();

	const { t } = useTranslation();

	const handleLogout = () => {
		instance.logoutRedirect({
			postLogoutRedirectUri: '/',
		});
	};

	const location = useLocation();
	const { pathname } = location;
	const [show, setShow] = useState(false);
	const [minimize, setMinimize] = useState(false);
	const showClass = show ? 'show' : '';
	const minimizeClass = minimize ? 'contracted' : '';

	const onCollapse = () => {
		setShow(!show);
		setMinimize(false);
	};

	const CollapsableNavItem = (props) => {
		const { eventKey, title, icon, children = null } = props;
		const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : '';

		return (
			<Accordion as={Nav.Item} defaultActiveKey={''}>
				<Accordion.Item eventKey={eventKey}>
					<Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
						<span>
							<span className="sidebar-icon">
								<FontAwesomeIcon icon={icon} />
							</span>
							<span className="sidebar-text" style={{ fontSize: '14px' }}>
								{title}
							</span>
						</span>
					</Accordion.Button>
					<Accordion.Body className="multi-level">
						<Nav className="flex-column ">{children}</Nav>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		);
	};

	const NavItem = (props) => {
		const {
			title,
			link,
			external,
			target,
			icon,
			image,
			badgeText,
			badgeBg = 'secondary',
			badgeColor = 'primary',
			fontSize,
			onClick,
		} = props;
		const classNames = badgeText
			? 'd-flex justify-content-start align-items-center justify-content-between'
			: 'd-flex justify-content-start';

		var navItemClassName;
		var linkProps;
		if (link != null) {
			navItemClassName = link === pathname ? `active ` : ``;
			linkProps = external ? { href: link } : { as: Link, to: link };
		}

		return (
			<Nav.Item onClickCapture={onClick} className={navItemClassName} onClick={() => setShow(false)}>
				<Nav.Link {...linkProps} target={target} className={classNames}>
					<>
						{icon ? (
							<span className="sidebar-icon">
								<FontAwesomeIcon icon={icon} />{' '}
							</span>
						) : null}
						{image ? <Image src={image} className=" sidebar-logo sidebar-icon svg-icon" /> : null}

						<span className="sidebar-text " style={{ fontSize: fontSize }}>
							{title}
						</span>
					</>
					{badgeText ? (
						<Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">
							{badgeText}
						</Badge>
					) : null}
				</Nav.Link>
			</Nav.Item>
		);
	};

	const renderSidebarNavItems = () => {
		return user.companies.map((val, index) => {
			// if(user.companies.includes()){
			return (
				<Accordion.Item key={index} eventKey={index}>
					<Accordion.Button className="d-flex justify-content-between align-items-center" as={Nav.Link}>
						<span>
							<span className="sidebar-icon">
								<FontAwesomeIcon style={{ paddingRight: '3px' }} icon={faBuilding} />
							</span>
							<span className="sidebar-text" style={{ fontSize: '14px', paddingLeft: '5px' }}>
								{val.company_name}
							</span>
						</span>
					</Accordion.Button>
					<Accordion.Body className="multi-level">
						<Nav className="flex-column ">
							<NavItem title={t('procurement')} link={Routes.ISR.path} icon={faChartBar} fontSize="12px" />
							<NavItem title={t('suppliers')} link={Routes.Suppliers.path} icon={faWarehouse} fontSize="12px" />
							{/* ) : null} */}

							<NavItem
								title={t('project-management')}
								link={Routes.Projects.path}
								icon={faProjectDiagram}
								fontSize="12px"
							/>
							<NavItem title={t('reports')} link={Routes.Report.path} icon={faChartBar} fontSize="12px" />
						</Nav>
					</Accordion.Body>
				</Accordion.Item>
			);
			// }
		});
	};

	useEffect(() => {
		if (user.companies.length != 0) {
			props.companyFilter(user.companies[0].company_name);
		}
	}, []);

	const handleCompanyFilter = (event) => {
		props.companyFilter(event.target.value);
	};

	return (
		<>
			<Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none ">
				<Navbar.Brand className="me-lg-5" as={Link} to={Routes.DashboardOverview.path}>
					<Image src={logo} className="navbar-brand-light" />
				</Navbar.Brand>
				<Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
					<span className="navbar-toggler-icon" />
				</Navbar.Toggle>
			</Navbar>
			<CSSTransition timeout={300} in={show} classNames="sidebar-transition">
				<SimpleBar className={`collapse ${showClass}  ${minimizeClass} sidebar d-md-block bg-primary text-white `}>
					<div className="sidebar-inner  px-4 pt-3">
						<div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
							<div className="d-flex align-items-center">
								<div className="user-avatar lg-avatar me-4">
									<div
										className="user-avatar rounded-circle text-dark"
										style={{
											backgroundColor: `#FFA500
											`,
										}}>
										{user.first_name != null ? user.first_name[0] : null}
										{user.last_name != null ? user.last_name[0] : null}
									</div>
								</div>
								<div className="d-block">
									<h6>
										{props.user.first_name != null
											? props.user.first_name + ' ' + props.user.last_name
											: t('unauthorized-user')}
									</h6>
									<Button
										as="button"
										onClick={() => handleLogout()}
										variant="secondary"
										size="xs"
										to={Routes.Signin.path}
										className="text-dark">
										<FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> {t('Sign Out')}
									</Button>
								</div>
							</div>
							<Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
								<FontAwesomeIcon icon={faTimes} />
							</Nav.Link>
						</div>

						{/* SIDEBAR ITEMS */}
						<Nav className={`flex-column h-100  pt-3 pt-md-0`}>
							<NavItem title={t('saportif-portal')} link={Routes.Welcome.path} image={logo} />
							{!minimize ? (
								<button className="sidebar-toggle-button" onClick={() => setMinimize(!minimize)}>
									<FontAwesomeIcon
										icon={minimize ? faCaretSquareRight : faCaretSquareLeft}
										className="me-2 text-muted"
									/>
								</button>
							) : (
								<NavItem icon={faBars} title={t('expand')} onClick={() => setMinimize(!minimize)} />
							)}
							{/* <Form.Group className=" ">
								<Form.Select
									onChange={(event) => handleCompanyFilter(event)}
									style={{ fontSize: '14px' }}
									className={`bg-primary text-white company-dropdown  `}>
									{user.companies.map((company, index) => {
										return (
											<option key={index} value={company.company_name}>
												{company.company_name}
											</option>
										);
									})}
								</Form.Select>
							</Form.Group> */}
							<NavItem icon={faChartPie} title={t('overview')} link={Routes.Overview.path} />
							<div>
								{/* // --------------------------PROCUREMENT-------------------------- */}
								<Accordion as={Nav.Item}>
									{multiplePagesAuthorizationCheckFunction(
										[
											Routes.Procurement.ISR.Index.policy,
											Routes.Procurement.LPO.Index.policy,
											Routes.Procurement.Supplier.Index.policy,
										],
										policies
									) ? (
										<Accordion.Item eventKey={t('procurement')}>
											<Accordion.Button className="d-flex justify-content-between align-items-center" as={Nav.Link}>
												<span>
													<span className="sidebar-icon">
														<FontAwesomeIcon style={{ paddingRight: '3px' }} icon={faFile} />
													</span>
													<span className="sidebar-text">{t('procurement')}</span>
												</span>
											</Accordion.Button>
											<Accordion.Body className="multi-level">
												<Nav className="flex-column ">
													{multiplePagesAuthorizationCheckFunction(
														[Routes.Procurement.ISR.Index.policy, Routes.Procurement.LPO.Index.policy],
														policies
													) ? (
														<NavItem
															title={
																!authorizationCheckFunction(Routes.Procurement.ISR.Index.policy, policies) &&
																authorizationCheckFunction(Routes.Procurement.LPO.Index.policy, policies)
																	? 'LPO'
																	: 'ISR'
															}
															link={
																!authorizationCheckFunction(Routes.Procurement.ISR.Index.policy, policies) &&
																authorizationCheckFunction(Routes.Procurement.LPO.Index.policy, policies)
																	? Routes.Procurement.LPO.Index.path
																	: Routes.Procurement.ISR.Index.path
															}
															icon={faFileAlt}
															fontSize="12px"
														/>
													) : null}

													{multiplePagesAuthorizationCheckFunction(
														[Routes.Procurement.Supplier.Index.policy],
														policies
													) ? (
														<NavItem
															title={t('suppliers-0')}
															link={Routes.Procurement.Supplier.Index.path}
															icon={faWarehouse}
															fontSize="12px"
														/>
													) : null}
													{/* {multiplePagesAuthorizationCheckFunction(
														[Routes.Procurement.Supplier.Index.policy],
														policies
													) ? ( */}
													<NavItem
														title={t('products')}
														link={Routes.Procurement.Item.Index.path}
														icon={faChartBar}
														fontSize="12px"
													/>
													{/* ) : null} */}
												</Nav>
											</Accordion.Body>
										</Accordion.Item>
									) : null}
									{/* // --------------------------FINANCE-------------------------- */}
									{multiplePagesAuthorizationCheckFunction([Routes.Finance.Project.Index.policy], policies) ? (
										<Accordion.Item eventKey={t('finance')}>
											<Accordion.Button className="d-flex justify-content-between align-items-center" as={Nav.Link}>
												<span>
													<span className="sidebar-icon">
														<FontAwesomeIcon style={{ paddingRight: '3px' }} icon={faHammer} />
													</span>
													<span className="sidebar-text">{t('project-management-0')}</span>
												</span>
											</Accordion.Button>
											<Accordion.Body className="multi-level">
												<Nav className="flex-column ">
													<NavItem
														title={t('projects')}
														link={Routes.Finance.Project.Index.path}
														icon={faFileAlt}
														fontSize="12px"
													/>
													<NavItem
														title={t('site-operations')}
														link={Routes.Finance.Project.Index.path}
														icon={faFileAlt}
														fontSize="12px"
													/>
													<NavItem
														title={t('non-conformance-report')}
														link={Routes.Finance.Project.Index.path}
														icon={faFileAlt}
														fontSize="12px"
													/>
													<NavItem
														title={t('work-inspection-request')}
														link={Routes.Finance.Project.Index.path}
														icon={faFileAlt}
														fontSize="12px"
													/>
													<NavItem
														title={t('material-inspection-request')}
														link={Routes.Finance.Project.Index.path}
														icon={faFileAlt}
														fontSize="12px"
													/>
													<NavItem
														title={t('consultant-management')}
														link={Routes.Finance.Project.Index.path}
														icon={faFileAlt}
														fontSize="12px"
													/>
													<NavItem
														title={t('contract-management')}
														link={Routes.Finance.Project.Index.path}
														icon={faFileAlt}
														fontSize="12px"
													/>
													<NavItem
														title={t('contractor-tendering')}
														link={Routes.Finance.Project.Index.path}
														icon={faFileAlt}
														fontSize="12px"
													/>
												</Nav>
											</Accordion.Body>
										</Accordion.Item>
									) : null}
									{/* // --------------------------HR-------------------------- */}
									{multiplePagesAuthorizationCheckFunction(
										[Routes.HumanResources.EmployeeEvaluation.Index.policy],
										policies
									) ? (
										<Accordion.Item eventKey="HR">
											<Accordion.Button className="d-flex justify-content-between align-items-center" as={Nav.Link}>
												<span>
													<span className="sidebar-icon">
														<FontAwesomeIcon style={{ paddingRight: '3px' }} icon={faHandsHelping} />
													</span>
													<span className="sidebar-text">{t('human-resources')}</span>
												</span>
											</Accordion.Button>
											<Accordion.Body className="multi-level">
												<NavItem
													title={t('overview-0')}
													link={Routes.HumanResources.Report.path}
													icon={faChartPie}
													fontSize="12px"
												/>
												{/* <NavItem
													title="Employee Evaluation"
													link={Routes.HumanResources.EmployeeEvaluation.Index.path}
													icon={faFileAlt}
													fontSize="12px"
												/>
												<NavItem
													title="Salary Deduction"
													link={Routes.HumanResources.SalaryDeduction.Index.path}
													icon={faMoneyBillWaveAlt}
													fontSize="12px"
												/>
												<NavItem
													title="Employee"
													link={Routes.HumanResources.Employee.Index.path}
													icon={faUserTie}
													fontSize="12px"
												/> */}
											</Accordion.Body>
										</Accordion.Item>
									) : null}
									{/* // --------------------------PROJECT MANAGEMENT-------------------------- */}

									{/* // --------------------------IT-------------------------- */}
									{multiplePagesAuthorizationCheckFunction([Routes.IT_Department.Ticket.Index.policy], policies) ? (
										<Accordion.Item eventKey="IT">
											<Accordion.Button className="d-flex justify-content-between align-items-center" as={Nav.Link}>
												<span>
													<span className="sidebar-icon">
														<FontAwesomeIcon style={{ paddingRight: '3px' }} icon={faDesktop} />
													</span>
													<span className="sidebar-text">{t('system-support')}</span>
												</span>
											</Accordion.Button>
											<Accordion.Body className="multi-level">
												<NavItem
													title={t('open-tickets')}
													link={Routes.IT_Department.Ticket.Index.path}
													icon={faTicketAlt}
													fontSize="12px"
												/>
											</Accordion.Body>
										</Accordion.Item>
									) : null}
									<Accordion.Item eventKey={t('construction')}>
										<Accordion.Button className="d-flex justify-content-between align-items-center" as={Nav.Link}>
											<span>
												<span className="sidebar-icon">
													<FontAwesomeIcon style={{ paddingRight: '3px' }} icon={faBuilding} />
												</span>
												<span className="sidebar-text">{t('construction-0')}</span>
											</span>
										</Accordion.Button>
										<Accordion.Body className="multi-level">
											<NavItem title={t('projects-0')} link={Routes.Construction.Project.Index.path} icon={faFile} />
											<NavItem
												title={t('punch-list')}
												link={Routes.Construction.PunchList.Index.path}
												icon={faClipboardList}
											/>
											<NavItem
												title={t('locations')}
												link={Routes.Construction.Locations.Index.path}
												icon={faMapMarker}
											/>
											<NavItem
												title={t('disciplines')}
												link={Routes.Construction.Discipline.Index.path}
												icon={faCertificate}
											/>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
								<NavItem title={t('insights')} link={Routes.Insights.Index.path} icon={faChartBar} />
								<NavItem title={t('delivery')} link={Routes.Delivery.Index.path} icon={faCoffee} />
								<NavItem title={t('workflow')} link={Routes.Workflow.Index.path} icon={faProjectDiagram} />
								<NavItem title={t('cash-flow')} link={Routes.CashFlow.Index.path} icon={faCashRegister} />
							</div>
							<div className="Sidebar-bottom">
								{/* // --------------------------ADMINISTRATION-------------------------- */}
								{multiplePagesAuthorizationCheckFunction([Routes.Admin.Department.Index.policy], policies) ? (
									<NavItem title={t('Administration')} link={Routes.Admin.User.Index.path} icon={faUserAlt} />
								) : null}
								<Dropdown.Divider className=" mt-1 border-indigo" />
								<NavItem className="" icon={faQuestion} title={t('Support')} link={Routes.Support.path} />
							</div>
						</Nav>
					</div>
				</SimpleBar>
			</CSSTransition>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.auth,
	};
};

export default connect(mapStateToProps, { companyFilter })(Sidebar);
