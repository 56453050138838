import _ from 'lodash';
import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { getSupplier } from '../../../../actions//Procurement/suppliersActions';
import { clearData } from '../../../../actions/globalActions';
import { Routes } from '../../../../routes';
import { Card, Col, ListGroup, Row } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { useTranslation } from 'react-i18next';

const SuppliersDetails = (props) => {
	const supplierId = props.match.params.id;
	const { supplier, loading } = props;
	useEffect(() => {
		props.getSupplier(supplierId);

		return () => {
			props.clearData();
		};
	}, []);
	const { t } = useTranslation();

	return (
		<div>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="py-4 col-lg-6 col-12">
					<Card className="p-0 ">
						<Card.Header className="border-bottom-0 p-4">
							<h4>{t('supplier-details')}</h4>
						</Card.Header>
						<Card.Body className="px-2 py-0">
							<ListGroup className="border-bottom border-top">
								<ListGroup.Item>
									<Row>
										<Col>
											<h6 className="mb-1">{t('supplier-name')}</h6>
											<small className="text-gray-700">{supplier?.supplier_name}</small>
										</Col>
									</Row>
								</ListGroup.Item>
							</ListGroup>
							<ListGroup className="border-bottom">
								<ListGroup.Item>
									<Row>
										<Col>
											<h6 className="mb-1">{t('contact-name')}</h6>
											<small className="text-gray-700">{supplier?.supplier_contact?.contact_name}</small>
										</Col>
									</Row>
								</ListGroup.Item>
							</ListGroup>
							<ListGroup className="border-bottom">
								<ListGroup.Item>
									<Row>
										<Col>
											<h6 className="mb-1">{t('contact-role')}</h6>
											<small className="text-gray-700">{supplier?.supplier_contact?.contact_role}</small>
										</Col>
									</Row>
								</ListGroup.Item>
							</ListGroup>
							<ListGroup className="border-bottom">
								<ListGroup.Item>
									<Row>
										<Col>
											<h6 className="mb-1">{t('contact-email')}</h6>
											<small className="text-gray-700">{supplier?.supplier_contact?.email}</small>
										</Col>
									</Row>
								</ListGroup.Item>
							</ListGroup>
							<ListGroup className="border-bottom">
								<ListGroup.Item>
									<Row>
										<Col>
											<h6 className="mb-1">{t('account-id')}</h6>
											<small className="text-gray-700">{supplier?.account_id}</small>
										</Col>
									</Row>
								</ListGroup.Item>
							</ListGroup>
							<ListGroup className="border-bottom">
								<ListGroup.Item>
									<Row>
										<Col>
											<h6 className="mb-1">{t('fax-number')}</h6>
											<small className="text-gray-700">{supplier?.supplier_contact?.fax_number}</small>
										</Col>
									</Row>
								</ListGroup.Item>
							</ListGroup>
							<ListGroup className="border-bottom">
								<ListGroup.Item>
									<Row>
										<Col>
											<h6 className="mb-1">{t('mobile-phone-number')}</h6>
											<small className="text-gray-700">{supplier?.supplier_contact?.mobile_phone_number}</small>
										</Col>
									</Row>
								</ListGroup.Item>
							</ListGroup>
							<ListGroup className="border-bottom">
								<ListGroup.Item>
									<Row>
										<Col>
											<h6 className="mb-1">{t('phone-number')}</h6>
											<small className="text-gray-700">{supplier?.supplier_contact?.phone_number}</small>
										</Col>
									</Row>
								</ListGroup.Item>
							</ListGroup>
							<Row className="mt-3 mb-3 px-2">
								<Col>
									<Link className="btn btn-secondary" to={Routes.Procurement.Supplier.Index.path}>
										{t('back')}{' '}
									</Link>
								</Col>
							</Row>
						</Card.Body>
					</Card>
					{/* <div className="shadow-sm p-0 p-md-4 card bg-white border-light">
						<div className="border-bottom p -3 card-header">
							<h4>Supplier Details</h4>
						</div>
						<div className="px-0 py-0 card-body">
							
						</div>
					</div> */}
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		supplier: state.procurement.supplier?.supplier,
		loading: state.procurement.supplier?.getSupplierLoading,
	};
};

export default connect(mapStateToProps, { getSupplier, clearData })(SuppliersDetails);
