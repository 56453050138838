import React from 'react';
import { Card, Col, Row } from '@themesberg/react-bootstrap';
import { Field, reduxForm, initialize, change } from 'redux-form';
import { nonZero, required } from '../../../../helpers/validator';
import renderDropdown from '../../../../components/formComponents/renderDropdown';
import renderInput from '../../../../components/formComponents/renderInput';
import { connect } from 'react-redux';
import { compose } from 'redux';
import SubmitButton from '../../../../components/formComponents/SubmitButton';
import { useEffect } from 'react';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const DisciplineForm = (props) => {
	const { auth, datas, initials, onSubmit, invalid } = props;
	const { t } = useTranslation();
	useEffect(() => {
		{
			initials != null
				? props.initialize({
						...initials,
						user: auth.first_name + ' ' + auth.last_name,
				  })
				: props.initialize({
						user: auth.first_name + ' ' + auth.last_name,
				  });
		}
		props.change('user_id', auth.user_id);
	}, []);

	const renderCompanies = () => {
		return datas.entities.map((obj, i) => {
			return (
				<option key={i} value={obj.company_id}>
					{obj.company_name}
				</option>
			);
		});
	};

	useEffect(() => {
		props.change('isValid', !invalid);
	}, [invalid]);

	return (
		<form onSubmit={props.handleSubmit(onSubmit)}>
			<Row>
				<Col xl={12} lg={12} md={12} sm={12} xs={12}>
					<Card border="light" className="bg-white shadow-sm mb-4  w-100">
						<Card.Body>
							<h5 className="mb-4">{t('projects-form')}</h5>
							<Row className="mb-3 ">
								<Col md={6} sm={12}>
									<Field name="user" label={t('by')} component={renderInput} disabled="disabled" />
								</Col>
								<Col md={6} sm={12}>
									<Field
										validate={[nonZero, required]}
										name="company_id"
										label={t('company')}
										component={renderDropdown}
										renderedOptions={renderCompanies()}
									/>
								</Col>
							</Row>
							<hr />
							<Row className="mb-3">
								<Col lg={6} md={12}>
									<Field
										validate={required}
										name="discipline_name"
										label={t('discipline-name')}
										component={renderInput}
									/>
								</Col>
							</Row>
							<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
								<a onClick={() => window.history.back()} className="btn btn-secondary">
									{t('back')}{' '}
								</a>
								<SubmitButton disabled={false} text={t('submit')} onSubmit={props.onSubmit} invalid={invalid} />
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</form>
	);
};

const mapStateToProps = (state) => {
	const { auth } = state;

	return {
		auth,
	};
};

export default compose(
	connect(mapStateToProps, { initialize, change }),

	reduxForm({
		form: 'discipline',
	})
)(DisciplineForm);
