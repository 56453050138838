import {
	CREATE_CASHFLOW,
	UPDATE_CASHFLOW,
	GET_CASHFLOWS,
	GET_CASHFLOW,
	GET_CASHFLOW_TAGS,
	DELETE_CASHFLOW,
} from './types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';

export const createCashFlow = (formValues) => async (dispatch, getState) => {
	axios
		.post('/cash-flow', formValues)
		.then((response) => {
			dispatch({ type: CREATE_CASHFLOW, payload: response.data });
			history.push(Routes.CashFlow.Index.path);
			toast.success('Cash Flow has been added.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		})
		.catch((err) => {
			toast.error('Something went wrong! Please open a ticket.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		});
};

export const getCashFlows = () => async (dispatch) => {
	const response = await axios.get('/cash-flow');
	dispatch({ type: GET_CASHFLOWS, payload: response.data });
};

export const getCashFlowTags = () => async (dispatch) => {
	const response = await axios.get('/cash-flow/tags');
	dispatch({ type: GET_CASHFLOW_TAGS, payload: response.data });
};

export const getCashFlowsWithFilter = (offset, limit, query, order, search) => async (dispatch) => {
	var params = '';
	params += order.length != 0 ? `${order}&` : '';
	params += query.length != 0 ? `${query}&` : '';
	params += search.length != 0 ? `${search}` : '';
	axios
		.get(`/cash-flow/offset/${offset}/limit/${limit}?${params}`)
		.then((response) => {
			dispatch({ type: GET_CASHFLOWS, payload: response.data });
		})
		.catch((err) => {});
};

export const getCashFlow = (id) => async (dispatch) => {
	const response = await axios.get(`/cash-flow/${id}`);
	dispatch({ type: GET_CASHFLOW, payload: response.data });
};

export const deleteCashFlow = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`cash-flow/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_CASHFLOW, payload: response.data });
			setDeleted(!deleted);
			toast.success('Cash Flow has been deleted.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		})
		.catch((err) => {
			toast.error('Something went wrong! Please open a ticket.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		});
};

export const updateCashFlow = (formValues) => async (dispatch) => {
	axios
		.put(`cash-flow/${formValues.cash_flow_id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_CASHFLOW, payload: response.data });
			history.push(Routes.CashFlow.Index.path);
			toast.success('Cash Flow has been updated.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		})
		.catch((err) => {
			toast.error('Something went wrong! Please open a ticket.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		});
};
