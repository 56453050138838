import React from 'react';
import { connect } from 'react-redux';

import { Card } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { Routes } from '../../../routes';
import { useTranslation } from 'react-i18next';

const OrderDetailCard = ({ order, showCheckoutButton }) => {
	const { t } = useTranslation();

	return (
		<Card>
			<Card.Header className="text-center d-flex flex-column py-2">{t('order-details')}</Card.Header>
			<Card.Body className="text-center">
				{(() => {
					if (order.length === 0) {
						return <div>{t('no-items-selected')}</div>;
					} else {
						return (
							<table className="w-100">
								<thead>
									<tr>
										<th>{t('product')}</th>
										<th>{t('amount')}</th>
									</tr>
								</thead>
								<tbody>
									{order.map((item, index) => (
										<tr key={index}>
											<td>{item.item_name}</td>
											<td className="">{item.item_amount}</td>
										</tr>
									))}
								</tbody>
							</table>
						);
					}
				})()}
			</Card.Body>
			{showCheckoutButton ? (
				<Card.Footer className="d-flex justify-content-end flex-wrap flex-md-nowrap align-items-center py-3">
					<Link
						to={Routes.Delivery.Checkout.path}
						className={order.length === 0 ? 'btn btn-primary text-center disabled' : 'btn btn-primary text-center'}>
						<FontAwesomeIcon icon={faShoppingCart} className="me-2" />
						{t('checkout')}{' '}
					</Link>
				</Card.Footer>
			) : null}
		</Card>
	);
};
export default connect(null, {})(OrderDetailCard);
