import { Breadcrumb, Card, Col, Row } from '@themesberg/react-bootstrap';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { change, Field, initialize, reduxForm } from 'redux-form';
import renderDropdownWithAutoComplete from '../../../../components/formComponents/renderDropdownWithAutoComplete';
import renderInput from '../../../../components/formComponents/renderInput';
import renderTextArea from '../../../../components/formComponents/renderTextArea';
import SubmitButton from '../../../../components/formComponents/SubmitButton';
import { Routes } from '../../../../routes';
import validate from '../../../../validate';
import { useTranslation } from 'react-i18next';

const StartWorkForm = (props) => {
	const { datas, initials, full_name, user_id, companies, onSubmit } = props;
	const { t } = useTranslation();

	useEffect(() => {
		if (initials) {
			props.initialize({
				...initials,
				start_date: new Date(initials.start_date).toISOString().split('T')[0],
				by: full_name,
			});
		} else {
			props.initialize({
				start_date: new Date().toISOString().split('T')[0],
				by: full_name,
				company_id: companies[0].company_id,
			});
		}
		props.change('user_id', user_id);
	}, []);

	return (
		<>
			<div className="d-block mb-4 mb-xl-0 ">
				<Breadcrumb
					className=" d-block"
					listProps={{
						className: 'breadcrumb-dark breadcrumb-transparent',
					}}>
					<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
						<FontAwesomeIcon icon={faHome} />
					</Breadcrumb.Item>
					<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.HumanResources.Overview.path }}>
						{t('human-resources')}{' '}
					</Breadcrumb.Item>
					<Breadcrumb.Item active>{t('start-work-forms')}</Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<form onSubmit={props.handleSubmit(onSubmit)}>
				<Row>
					<Col md={12}>
						<Card border="light" className="bg-white shadow-sm mb-4  w-100">
							<Card.Body>
								<h5 className="mb-4">{t('joining-form')}</h5>
								<Row className="mb-3 ">
									<Col md={6} sm={12}>
										<Field name="by" label={t('by')} component={renderInput} disabled="disabled" />
									</Col>
									<Col md={6} sm={12}>
										<Field
											data={companies}
											dataKey="company_id"
											textField="company_name"
											name="company_id"
											label={t('company')}
											component={renderDropdownWithAutoComplete}
										/>
									</Col>
								</Row>
								<hr />
								<Row>
									<span className="fw-bolder">{t('to-whom-it-may-concern')}</span>
									<p>
										{t(
											'please-be-informed-that-we-have-new-employee-and-ready-to-work-in-our-company-the-details-of-the-employee-is-shown-below'
										)}{' '}
									</p>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field
											name="employee_id"
											label={t('employee')}
											dataKey="employee_id"
											textField="full_name"
											data={datas.employees}
											component={renderDropdownWithAutoComplete}
											onChange={(val) => {
												const selectedEmployee = _.find(datas.employees, { employee_id: val });

												props.change(
													'reference',
													`HR/004/${String(selectedEmployee.employee_number).padStart(3, '0')}/${
														new Date().getMonth() + 1
													}${new Date().getDate()}${new Date().getMilliseconds()}`
												);
											}}
										/>
									</Col>
									<Col>
										<Field name="designation" label={t('designation')} component={renderInput} />
									</Col>
								</Row>

								<Row className="mb-3">
									<Col>
										<Field name="work_place" label={t('work-place')} component={renderInput} />
									</Col>
									<Col>
										<Field name="start_date" label={t('start-date')} inputType="date" component={renderInput} />
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field name="work_area" rows={4} label={t('work-area-project-site')} component={renderTextArea} />
									</Col>
									<Col>
										<Field disabled="disabled" name="reference" label={t('reference')} component={renderInput} />
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field
											name="hr_manager_id"
											label={t('hr-manager')}
											data={datas.users}
											dataKey="user_id"
											textField="full_name"
											component={renderDropdownWithAutoComplete}
										/>
									</Col>
									<Col>
										<Field name="employee_signature" label={t('employees-signature')} component={renderInput} />
									</Col>
									<Col>
										<Field
											data={datas.users}
											dataKey="user_id"
											textField="full_name"
											name="direct_manager_id"
											label={t('direct-manager-in-charge')}
											component={renderDropdownWithAutoComplete}
										/>
									</Col>
								</Row>

								<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
									<Link to={Routes.HumanResources.StartWork.Index.path} className="btn btn-secondary">
										{t('back')}{' '}
									</Link>
									<SubmitButton text={t('submit')} onSubmit={onSubmit} invalid={false} />
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</form>
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		auth: { full_name, user_id, companies },
	} = state;

	return {
		full_name,
		user_id,
		companies,
	};
};

export default compose(
	connect(mapStateToProps, { initialize, change }),
	reduxForm({ form: 'startWork', validate })
)(StartWorkForm);
