import { Form } from '@themesberg/react-bootstrap';
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export default ({ input, label, placeholder, meta, inputType, onChange, value, className, disabled }) => {
	return (
		<Form.Group className={className}>
			{label ? <Form.Label>{label}</Form.Label> : null}
			<PhoneInput
				disabled={disabled}
				onChange={onChange}
				value={value}
				as="input"
				country={'qa'}
				preferredCountries={['qa']}
				// inputProps={{
				// 	enableSearch: true,
				// }}
				inputStyle={{
					width: 'inherit',
					height: 'inherit',
					lineHeight: 'inherit',
					fontSize: 'inherit',
				}}
				{...input}
			/>
			<span className="text-danger">{meta.touched && meta.error ? meta.error : null}</span>
		</Form.Group>
	);
};
