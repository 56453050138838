import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Card, Col, Form, Row, Table } from '@themesberg/react-bootstrap';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { change, Field, initialize, reduxForm } from 'redux-form';
import renderDropdownWithAutoComplete from '../../../../components/formComponents/renderDropdownWithAutoComplete';
import renderInput from '../../../../components/formComponents/renderInput';
import renderPhoneNumberInput from '../../../../components/formComponents/renderPhoneNumberInput';
import renderRadioButton from '../../../../components/formComponents/renderRadioButton';
import SubmitButton from '../../../../components/formComponents/SubmitButton';
import { Routes } from '../../../../routes';
import validate from '../../../../validate';

const ClearanceForm = (props) => {
	const { initials, datas, onSubmit, full_name, user_id, companies } = props;
	const { t } = useTranslation();

	useEffect(() => {
		if (initials) {
			props.initialize({
				...initials,
				by: full_name,
				job_title: initials.employee.job_title,
				date_of_last_work: new Date(initials.date_of_last_work).toISOString().split('T')[0],
				department: _.find(datas.employees, { employee_id: initials.employee_id }).department.department_name,
			});
		} else {
			props.initialize({
				by: full_name,
				company_id: companies[0].company_id,
				date_of_last_work: new Date().toISOString().split('T')[0],
			});
		}
		props.change('user_id', user_id);
	}, []);
	return (
		<>
			<div className="d-block mb-4 mb-xl-0 ">
				<Breadcrumb
					className=" d-block"
					listProps={{
						className: 'breadcrumb-dark breadcrumb-transparent',
					}}>
					<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
						<FontAwesomeIcon icon={faHome} />
					</Breadcrumb.Item>
					<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.HumanResources.Overview.path }}>
						{t('human-resources')}{' '}
					</Breadcrumb.Item>
					<Breadcrumb.Item active>{t('staff-labour-clearance-0')} </Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<form onSubmit={props.handleSubmit(onSubmit)}>
				<Row>
					<Col md={12}>
						<Card border="light" className="bg-white shadow-sm mb-4 w-100">
							<Card.Body>
								<h5 className="mb-4">{t('staff-labour-clearance-form')}</h5>
								<Row className="mb-3">
									<Col md={6} sm={12}>
										<Field name="by" label={t('by')} component={renderInput} disabled="disabled" />
									</Col>
									<Col md={6} sm={12}>
										<Field
											data={companies}
											dataKey="company_id"
											textField="company_name"
											name="company_id"
											label={t('company')}
											component={renderDropdownWithAutoComplete}
										/>
									</Col>
								</Row>
								<hr />
								<Row className="mb-3">
									<Col>
										<Field
											name="employee_id"
											label={t('employee')}
											dataKey="employee_id"
											textField="full_name"
											data={datas.employees}
											component={renderDropdownWithAutoComplete}
											onChange={(val) => {
												const selectedEmployee = _.find(datas.employees, { employee_id: val });
												props.change(
													'reference',
													`HR/006/${String(selectedEmployee.employee_number).padStart(3, '0')}/${
														new Date().getMonth() + 1
													}${new Date().getDate()}${new Date().getMilliseconds()}`
												);
												props.change('job_title', selectedEmployee.job_title);
												props.change('department', selectedEmployee.department.department_name);
												props.change('site', selectedEmployee.site);
											}}
										/>
									</Col>
									<Col>
										<Field name="job_title" disabled="disabled" component={renderInput} label={t('job-title')} />
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field name="reference" disabled="disabled" component={renderInput} label={t('reference')} />
									</Col>
									<Col>
										<Field name="department" disabled="disabled" component={renderInput} label={t('department-0')} />
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field name="site" disabled="disabled" component={renderInput} label={t('site')} />
									</Col>
									<Col>
										<Field
											name="date_of_last_work"
											inputType="date"
											component={renderInput}
											label={t('date-of-last-work')}
										/>
									</Col>
								</Row>
								<Row className="mb-4">
									<Col>
										<Field
											name="phone_number"
											component={renderPhoneNumberInput}
											inputType="number"
											label={t('local-country-mobile-tel-no')}
										/>
									</Col>
									<Col></Col>
								</Row>
								<Row className="mb-3">
									<span className="text-center fw-bolder">{t('for-site-department-division-use-only')}</span>
									<Table responsive className="table-centered table-nowrap rounded mb-0 ">
										<thead>
											<tr>
												<th className="fw-bolder text-center">{t('subject')}</th>
												<th className="fw-bolder text-center">{t('name-code-and-signature')}</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td className="fw-bold ">{t('time-keeper-for-time-sheet')}</td>
												<td>
													<Field name="time_keeper" component={renderInput} />
												</td>
											</tr>
											<tr>
												<td className="fw-bold ">{t('tool-keeper-for-tools-equipment')}</td>
												<td>
													<Field name="tool_keeper" component={renderInput} />
												</td>
											</tr>
											<tr>
												<td className="fw-bold ">{t('site-secretary-for-telephone-and-others')}</td>
												<td>
													<Field name="site_secretary" component={renderInput} />
												</td>
											</tr>
											<tr>
												<td className="fw-bold ">{t('person-in-charge-hand-over')}</td>
												<td>
													<Field name="person_in_charge" component={renderInput} />
												</td>
											</tr>
											<tr>
												<td className="fw-bold ">{t('camp-boss-for-accommodation-and-vehicle')}</td>
												<td>
													<Field name="camp_boss" component={renderInput} />
												</td>
											</tr>
											<tr>
												<td className="fw-bold ">{t('direct-manager-department-head')}</td>
												<td>
													<Field name="direct_manager" component={renderInput} />
												</td>
											</tr>
										</tbody>
									</Table>
								</Row>
								<Row className="mb-3">
									<span className="text-center fw-bolder">
										{t('for-administrative-and-accounting-department-use-only')}
									</span>
									<Table responsive className="table-centered table-nowrap rounded mb-0 ">
										<thead>
											<tr>
												<th className="fw-bolder text-center">{t('company-assets-others')}</th>
												<th className="fw-bolder text-center">{t('status')}</th>
												<th className="fw-bolder text-center">{t('remarks')}</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td className="fw-bold ">{t('did-he-she-clear-the-flat-accommodation')}</td>
												<td>
													<Field
														name="accommodation_status"
														component={renderRadioButton}
														options={[
															{ title: t('yes'), value: t('yes-0') },
															{ title: t('no'), value: t('no-0') },
															{
																title: 'N/A',
																value: 'N/A',
															},
														]}
													/>
												</td>
												<td>
													<Field name="accommodation_remarks" component={renderInput} />
												</td>
											</tr>
											<tr>
												<td className="fw-bold ">{t('did-he-she-return-the-furniture')}</td>
												<td>
													<Field
														name="furniture_status"
														component={renderRadioButton}
														options={[
															{ title: t('yes-1'), value: t('yes-2') },
															{ title: t('no-1'), value: t('no-2') },
															{
																title: 'N/A',
																value: 'N/A',
															},
														]}
													/>
												</td>
												<td>
													<Field name="furniture_remarks" component={renderInput} />
												</td>
											</tr>
											<tr>
												<td className="fw-bold ">{t('laptop-computer')}</td>
												<td>
													<Field
														name="computer_status"
														component={renderRadioButton}
														options={[
															{ title: t('yes-3'), value: t('yes-4') },
															{ title: t('no-3'), value: t('no-4') },
															{
																title: 'N/A',
																value: 'N/A',
															},
														]}
													/>
												</td>
												<td>
													<Field name="computer_remarks" component={renderInput} />
												</td>
											</tr>
											<tr>
												<td className="fw-bold ">{t('telephone-mobile')}</td>
												<td>
													<Field
														name="phone_status"
														component={renderRadioButton}
														options={[
															{ title: t('yes-5'), value: t('yes-6') },
															{ title: t('no-5'), value: t('no-6') },
															{
																title: 'N/A',
																value: 'N/A',
															},
														]}
													/>
												</td>
												<td>
													<Field name="phone_remarks" component={renderInput} />
												</td>
											</tr>
											<tr>
												<td className="fw-bold ">{t('others')}</td>
												<td>
													<Field
														name="other_status"
														component={renderRadioButton}
														options={[
															{ title: t('yes-7'), value: t('yes-8') },
															{ title: t('no-7'), value: t('no-8') },
															{
																title: 'N/A',
																value: 'N/A',
															},
														]}
													/>
												</td>
												<td>
													<Field name="other_remarks" component={renderInput} />
												</td>
											</tr>
										</tbody>
									</Table>
									<Table responsive className="table-centered table-nowrap rounded mb-0 ">
										<thead>
											<tr>
												<th className="fw-bolder text-center">{t('vehicle')}</th>
												<th className="fw-bolder text-center">{t('status')}</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td className="fw-bold">{t('he-she-return-back-the-company-car')}</td>
												<td>
													<Field
														name="vehicle_status"
														component={renderRadioButton}
														options={[
															{ title: t('yes-9'), value: t('yes-10') },
															{ title: t('no-9'), value: t('no-10') },
														]}
													/>
												</td>
											</tr>
											<tr>
												<td className="fw-bold">{t('have-we-received-clearance-certificate-from-traffic-dept')}</td>
												<td>
													<Field
														name="vehicle_clearance_status"
														component={renderRadioButton}
														options={[
															{ title: t('yes-11'), value: t('yes-12') },
															{ title: t('no-11'), value: t('no-12') },
														]}
													/>
												</td>
											</tr>
										</tbody>
									</Table>
								</Row>
								<Row className="mb-3">
									<Col>
										<Form.Group>
											<Form.Label>{t('employement-visa')}</Form.Label>
											<Field
												name="visa_status"
												component={renderRadioButton}
												options={[
													{ title: t('cancelled'), value: t('cancelled-0') },
													{ title: t('not-cancelled'), value: t('not-cancelled-0') },
												]}
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<span className="fw-bold">
											{t('visa-expenses-to-be-deducted-if-he-she-resigned-before-completing-one-year')}{' '}
										</span>
									</Col>
									<Col>
										<Field placeholder="QR" name="visa_deduction" component={renderInput} />
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<span className="fw-bold">{t('other-deduction-if-any-others-penalty-fine-act')}</span>
									</Col>
									<Col>
										<Field placeholder="QR" name="other_deduction" component={renderInput} />
									</Col>
								</Row>
								<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
									<Link to={Routes.HumanResources.Clearance.Index.path} className="btn btn-secondary">
										{t('back')}{' '}
									</Link>
									<SubmitButton invalid={false} onSubmit={onSubmit} text={t('submit')} />
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</form>
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		auth: { full_name, user_id, companies },
	} = state;
	return {
		full_name,
		user_id,
		companies,
	};
};

export default compose(
	connect(mapStateToProps, { initialize, change }),
	reduxForm({ form: 'clearance', validate })
)(ClearanceForm);
