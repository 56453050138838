import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger } from '@themesberg/react-bootstrap';
import { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Handle, Position } from 'react-flow-renderer';
import { connect } from 'react-redux';
import { Multiselect } from 'react-widgets';

import { getUsers } from '../../../../../actions/User/userActions';
import LoadingSpinner from '../../../../../components/LoadingSpinner';

import notificationIcon from '../../../../../img/notification-icon.png';
import SettingsIcon from '../../../../../img/settings.png';

const NotificationNode = (props) => {
	const { data, users, getUsersLoading } = props;

	const [notificationMessage, setNotificationMessage] = useState('');
	const [notificationTo, setNotificationTo] = useState(null);

	useEffect(() => {
		if (data.value.message) {
			setNotificationMessage(data.value.message);
		}

		if (data.value.to) {
			setNotificationTo(data.value.to);
		}
	}, []);

	useEffect(() => {
		props.getUsers();
	}, [getUsersLoading]);

	return (
		<Card style={{ width: '500px', borderRadius: '0px' }}>
			<Handle type="target" position={Position.Top} />
			<div
				className="fw-bold d-flex align-items-center justify-content-between text-white "
				style={{ backgroundColor: '#2a85ff' }}>
				<div className="d-flex align-items-center">
					<div className="d-flex justify-content-center align-items-center" style={{ width: '50px', height: '50px' }}>
						<img style={{ width: '30px', height: '30px' }} src={notificationIcon} />
					</div>
					<span className="ms-2 fs-5">{data.label}</span>
				</div>

				<OverlayTrigger
					trigger="click"
					placement="bottom"
					rootClose
					overlay={
						<Card
							style={{
								position: 'absolute',
								padding: '2px 10px',
								borderRadius: 3,
								zIndex: 11111,
								width: '20vw',
								...props.style,
							}}>
							<Row className="mb-3">
								<Form.Group controlId="exampleForm.ControlTextarea1">
									<Form.Label>Message</Form.Label>
									<Form.Control
										onChange={(e) => {
											data.value.message = e.target.value;
											data.onChange((prev) => !prev);
											setNotificationMessage(e.target.value);
										}}
										as="textarea"
										rows={4}
										value={notificationMessage}
										style={{ resize: 'none' }}
									/>
								</Form.Group>
							</Row>
							<Row className="mb-3">
								<Form.Label>To</Form.Label>
								<Form.Group>
									<Form.Check
										onChange={(e) => {
											data.value.to = 'cc';
											data.onChange((prev) => !prev);
											setNotificationTo('cc');
										}}
										inline
										label="CC"
										name="to"
										type="radio"
										checked={notificationTo == 'cc' ? true : false}
										id="1"
									/>
									<Form.Check
										onChange={(e) => {
											data.value.to = 'bcc';
											data.onChange((prev) => !prev);
											setNotificationTo('bcc');
										}}
										inline
										label="BCC"
										name="to"
										type="radio"
										checked={notificationTo == 'bcc' ? true : false}
										id="2"
									/>
								</Form.Group>
							</Row>
						</Card>
					}>
					<img src={SettingsIcon} width={20} height={20} className="mx-2" />
				</OverlayTrigger>
			</div>
			<Card.Body>
				{getUsersLoading ? (
					<LoadingSpinner />
				) : (
					<>
						<Row className="mb-3">
							<Form.Group>
								<Form.Label>Select Users</Form.Label>

								<Multiselect
									onChange={(e) => {
										data.value.users = e;
										data.onChange((prev) => !prev);
									}}
									defaultValue={data.value.users ?? []}
									data={users}
									dataKey="user_id"
									textField="full_name"
								/>
							</Form.Group>
						</Row>
					</>
				)}
			</Card.Body>
			<Handle type="source" position={Position.Bottom} />
		</Card>
	);
};

const mapStateToProps = (state) => {
	const {
		user: { users, getUsersLoading },
	} = state;

	return { users, getUsersLoading };
};

export default connect(mapStateToProps, { getUsers })(NotificationNode);
