import React, { useEffect } from 'react';
import Report from '../Reports/Report';
import { getProcurementOverview } from '../../../actions//Others/powerbiActions';
import { connect } from 'react-redux';

const ProcurementOverivew = (props) => {
	return (
		<div>
			<Report getEmbedInfo={props.getProcurementOverview} />
		</div>
	);
};

export default connect(null, { getProcurementOverview })(ProcurementOverivew);
