import { Card, Col, Form, Row } from '@themesberg/react-bootstrap';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm, initialize, change } from 'redux-form';
import renderDropdown from '../../../../components/formComponents/renderDropdown';
import renderDropdownWithAutoComplete from '../../../../components/formComponents/renderDropdownWithAutoComplete';
import renderInput from '../../../../components/formComponents/renderInput';
import renderRadioButton from '../../../../components/formComponents/renderRadioButton';
import renderTextArea from '../../../../components/formComponents/renderTextArea';
import renderCheckbox from '../../../../components/formComponents/renderCheckbox';
import validate from '../../../../validate';
import SubmitButton from '../../../../components/formComponents/SubmitButton';

import { Routes } from '../../../../routes';
import { Link } from 'react-router-dom';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faHome, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const selector = formValueSelector('warningLetter');

const WarningLetterForm = (props) => {
	const { formChecks, initials, datas, onSubmit, full_name, user_id, companies } = props;
	const { t } = useTranslation();

	useEffect(() => {
		if (!formChecks.dayDeductionCheck) {
			props.change('day_deduction', '');
		}
	}, [formChecks.dayDeductionCheck]);

	useEffect(() => {
		if (!formChecks.qatarRiyalsCheck) {
			props.change('qatari_riyals', '');
		}
	}, [formChecks.qatarRiyalsCheck]);

	useEffect(() => {
		if (!formChecks.hourDeductionCheck) {
			props.change('overtime_hour_deduction', '');
		}
	}, [formChecks.hourDeductionCheck]);

	useEffect(() => {
		if (initials) {
			props.initialize({
				...initials,
				by: full_name,
				date: new Date(initials.date).toISOString().split('T')[0],
				proposal_date: new Date(initials.proposal_date).toISOString().split('T')[0],
			});
			if (initials.day_deduction) {
				props.change('d_deduction', true);
			}
			if (initials.overtime_hour_deduction) {
				props.change('h_deduction', true);
			}
			if (initials.qatari_riyals) {
				props.change('q_riyals', true);
			}
		} else {
			props.initialize({
				by: full_name,
				company_id: companies[0].company_id,
				date: new Date().toISOString().split('T')[0],
				proposal_date: new Date().toISOString().split('T')[0],
				reasons: `Weak Managing site activities
Low Progress Rate
Poor co-ordination with management ans subcontractor`,
			});
		}
		props.change('user_id', user_id);
	}, []);

	return (
		<>
			<div className="d-block mb-4 mb-xl-0 ">
				<Breadcrumb
					className=" d-block"
					listProps={{
						className: 'breadcrumb-dark breadcrumb-transparent',
					}}>
					<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
						<FontAwesomeIcon icon={faHome} />
					</Breadcrumb.Item>
					<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.HumanResources.Overview.path }}>
						{t('human-resources')}{' '}
					</Breadcrumb.Item>
					<Breadcrumb.Item active>{t('warning-letter')} </Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<form onSubmit={props.handleSubmit(onSubmit)}>
				<Row>
					<Col md={12}>
						<Card border="light" className="bg-white shadow-sm mb-4  w-100">
							<Card.Body>
								<h5 className="mb-4">{t('warning-letter-form')}</h5>
								<Row className="mb-3 ">
									<Col md={6} sm={12}>
										<Field name="by" label={t('by')} component={renderInput} disabled="disabled" />
									</Col>
									<Col md={6} sm={12}>
										<Field
											data={companies}
											dataKey="company_id"
											textField="company_name"
											name="company_id"
											label={t('company')}
											component={renderDropdownWithAutoComplete}
										/>
									</Col>
								</Row>
								<hr />
								<Row className="mb-3">
									<Col>
										<Form.Label>{t('warning-count')}</Form.Label>
										<Form.Group>
											<Field
												name="count"
												component={renderRadioButton}
												options={[
													{ title: t('first'), value: t('first-0') },
													{ title: t('second'), value: t('second-0') },
													{ title: t('third-final'), value: t('third') },
												]}
											/>
										</Form.Group>
									</Col>
									<Col>
										<Field name="date" component={renderInput} inputType="date" label={t('date-0')} />
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field
											name="employee_id"
											label={t('employee')}
											dataKey="employee_id"
											textField="full_name"
											data={datas.employees}
											component={renderDropdownWithAutoComplete}
											onChange={(val) => {
												const selectedEmployee = _.find(datas.employees, { employee_id: val });
												props.change(
													'reference',
													`HR/005/${String(selectedEmployee.employee_number).padStart(3, '0')}/${
														new Date().getMonth() + 1
													}${new Date().getDate()}${new Date().getMilliseconds()}`
												);
											}}
										/>
									</Col>
									<Col>
										<Field name="designation" component={renderInput} label={t('designation')} />
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field name="reasons" component={renderTextArea} label={t('reasons-of-written-warning')} rows={3} />
									</Col>
									<Col>
										<Field disabled="disabled" name="reference" label={t('reference')} component={renderInput} />
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field name="proposed_by" component={renderInput} label={t('proposed-by-0')} />
									</Col>
									<Col>
										<Field name="proposal_date" label={t('proposal-date')} inputType="date" component={renderInput} />
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field name="occupation" component={renderInput} label={t('occupation')} />
									</Col>
									<Col>
										<Field name="witness" label={t('witness')} component={renderInput} />
									</Col>
								</Row>
								<Row className="mb-3">
									<Form.Label>{t('action')}</Form.Label>
									<Col md={4}>
										<Field name="suspension" val={true} component={renderCheckbox} label={t('suspension')} />
									</Col>
									<Col md={4}>
										<Field name="termination" val={true} component={renderCheckbox} label={t('termination')} />
									</Col>
									<Col md={4}>
										<Field name="d_deduction" val={true} component={renderCheckbox} label={t('day-s-deduction')} />
										{formChecks.dayDeductionCheck ? (
											<Field
												name="day_deduction"
												inputType="number"
												placeholder={t('enter-day-s')}
												component={renderInput}
											/>
										) : null}
									</Col>
									<Col md={4}>
										<Field name="q_riyals" component={renderCheckbox} val={true} label={t('qatari-riyals')} />
										{formChecks.qatarRiyalsCheck ? (
											<Field
												name="qatari_riyals"
												inputType="number"
												placeholder={t('enter-amount')}
												component={renderInput}
											/>
										) : null}
									</Col>
									<Col md={4}>
										<Field
											name="h_deduction"
											val={true}
											component={renderCheckbox}
											label={t('overtime-hours-deduction')}
										/>
										{formChecks.hourDeductionCheck ? (
											<Field
												name="overtime_hour_deduction"
												inputType="number"
												placeholder={t('enter-hours')}
												component={renderInput}
											/>
										) : null}
									</Col>
								</Row>
								<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
									<Link to={Routes.HumanResources.WarningLetter.Index.path} className="btn btn-secondary">
										{t('back')}{' '}
									</Link>
									<SubmitButton text={t('submit')} onSubmit={onSubmit} invalid={false} />
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</form>
		</>
	);
};

const mapStateToProps = (state) => {
	const dayDeductionCheck = selector(state, 'd_deduction');
	const qatarRiyalsCheck = selector(state, 'q_riyals');
	const hourDeductionCheck = selector(state, 'h_deduction');

	const {
		auth: { full_name, user_id, companies },
	} = state;

	return {
		formChecks: { dayDeductionCheck, qatarRiyalsCheck, hourDeductionCheck },
		full_name,
		user_id,
		companies,
	};
};

export default compose(
	connect(mapStateToProps, { initialize, change }),
	reduxForm({ form: 'warningLetter', validate })
)(WarningLetterForm);
