export default (pagePolicy, userPolicy) => {
	if (pagePolicy && userPolicy) {
		const { page, action } = pagePolicy;
		var isAllowed = false;
		if (userPolicy[page]) {
			isAllowed = userPolicy[page].actions.some((item) => action.includes(item));
		}

		return isAllowed;
	}
};

export const multiplePagesAuthorizationCheckFunction = (pagePolicies, userPolicy) => {
	if (pagePolicies.length != 0 && userPolicy) {
		var isAllowed = false;
		pagePolicies.forEach((pagePolicy) => {
			const { page, action } = pagePolicy;
			if (userPolicy[page]) {
				isAllowed = isAllowed || userPolicy[page].actions.some((item) => action.includes(item));
			}
		});

		return isAllowed;
	}
};
