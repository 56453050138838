import { Form } from '@themesberg/react-bootstrap';
import DropdownList from 'react-widgets/DropdownList';

export default ({ input, data, dataKey, textField, allowCreate, label }) => {
	return (
		<Form.Group>
			<Form.Label>{label}</Form.Label>
			<DropdownList
				{...input}
				onBlur={() => input.onBlur()}
				onChange={(selected) => {
					if (dataKey) {
						input.onChange(selected[dataKey]);
					} else {
						input.onChange(selected);
					}
				}}
				allowCreate={allowCreate}
				onCreate={(val) => {
					if (allowCreate) {
						input.onChange(val);
					}
				}}
				value={input.value || ''}
				data={data}
				textField={textField || ''}
				dataKey={dataKey}
			/>
		</Form.Group>
	);
};
