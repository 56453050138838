import {
	CREATE_USER_INVITE,
	GET_USER_INVITE,
	GET_USER_INVITES,
	GET_USER_INVITES_WITH_FILTER,
	UPDATE_USER_INVITE,
	DELETE_USER_INVITE,
} from '../../actions/Admin/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	userInvites: [],
	userInvite: {},
	getUserInvitesLoading: true,
	getUserInviteLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CREATE_USER_INVITE:
			return { ...state, userInvite: action.payload, loading: false };
		case GET_USER_INVITES:
			return {
				...state,
				userInvites: action.payload,
				getUserInvitesLoading: false,
			};
		case GET_USER_INVITES_WITH_FILTER:
			return {
				...state,
				userInvites: action.payload,
				getUserInvitesLoading: false,
			};
		case UPDATE_USER_INVITE:
			return { ...state, userInvite: action.payload };
		case GET_USER_INVITE:
			return {
				...state,
				userInvite: action.payload,
				getUserInviteLoading: false,
			};
		case CLEAR_DATA:
			return {
				...state,
				userInvite: action.payload,
				getUserInviteLoading: true,
				getUserInvitesLoading: true,
			};

		case DELETE_USER_INVITE:
			return { ...state };
		default:
			return state;
	}
};
