import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getLocations } from '../../../../actions/Construction/locationsActions';
import { createPunchList } from '../../../../actions/Construction/punchListActions';

import { clearData } from '../../../../actions/globalActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import PunchListForm from './PunchListForm';

const PunchListCreate = (props) => {
	const { datas, loading } = props;

	useEffect(() => {
		props.getLocations();
		return () => {
			props.clearData();
		};
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			//AND ISVALID EKLEYECEKSIN
			props.createPunchList(formValues);
		}
	};

	return (
		<>
			{0 ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<PunchListForm datas={datas} onSubmit={onSubmit} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		construction: {
			locations: { locations, getLocationsLoading },
		},
	} = state;

	return {
		datas: { locations: locations },
		loading: getLocationsLoading,
	};
};

export default connect(mapStateToProps, { createPunchList, getLocations, clearData })(PunchListCreate);
