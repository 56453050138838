import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';
import {
	GET_PROJECT_BUILDING,
	GET_PROJECT_BUILDINGS,
	CREATE_PROJECT_BUILDING,
	DELETE_PROJECT_BUILDING,
	UPDATE_PROJECT_BUILDING,
} from './types';

export const getProjectBuildings = (project_id) => async (dispatch) => {
	axios
		.get(`project_building/project/${project_id}`)
		.then((response) => {
			dispatch({ type: GET_PROJECT_BUILDINGS, payload: response.data });
		})
		.catch((err) => {});
};

export const createProjectBuilding = (formValues) => async (dispatch) => {
	axios
		.post('/project_building', formValues)
		.then((response) => {
			dispatch({ type: CREATE_PROJECT_BUILDING, payload: response.data });
			history.push(Routes.Construction.Project.Details.path.replace(':id', formValues.project_id));
		})
		.catch((err) => {});
};

export const getProjectBuilding = (id) => async (dispatch) => {
	axios
		.get(`project_building/${id}`)
		.then((response) => {
			dispatch({ type: GET_PROJECT_BUILDING, payload: response.data });
		})
		.catch((err) => {});
};

export const updateProjectBuilding = (formValues) => async (dispatch) => {
	axios
		.put(`project_building/${formValues.id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_PROJECT_BUILDING, payload: response.data });
			history.push(Routes.Construction.Project.Details.path.replace(':id', formValues.project_id));
		})
		.catch((err) => {});
};

export const deleteProjectBuilding = (id, deleted, setDeleted, project_id) => async (dispatch) => {
	axios
		.delete(`project_building/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_PROJECT_BUILDING, payload: response.data });
			setDeleted(!deleted);

			history.push(Routes.Construction.Project.Details.path.replace(':id', project_id));
		})
		.catch((err) => {});
};
