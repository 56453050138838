import React from 'react';
import { connect } from 'react-redux';
import DisciplineForm from './DisciplineForm';

import { getEntities } from '../../../../actions/Admin/entityActions';
import { useEffect } from 'react';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import _ from 'lodash';

import { createDiscipline } from '../../../../actions/Construction/disciplineActions';

const DisciplineCreate = (props) => {
	const { loading, datas } = props;

	useEffect(() => {
		props.getEntities();
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			console.log('a');
			props.createDiscipline(formValues);
		}
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<DisciplineForm datas={datas} onSubmit={onSubmit} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		admin: {
			entity: { entities, getEntitiesLoading },
		},
	} = state;

	return {
		loading: getEntitiesLoading,
		datas: { entities },
	};
};

export default connect(mapStateToProps, { getEntities, createDiscipline })(DisciplineCreate);
