import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import ReactFlow, {
	addEdge,
	Background,
	Controls,
	ReactFlowProvider,
	removeElements,
	updateEdge,
} from 'react-flow-renderer';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, initialize, reduxForm } from 'redux-form';
import { v4 as uuidv4 } from 'uuid';

import axios from '../../../apis/axios';
import renderInput from '../../../components/formComponents/renderInput';
import renderSearchableDropdown from '../../../components/formComponents/renderSearchableDropdown';
import SubmitButton from '../../../components/formComponents/SubmitButton';
import { required } from '../../../helpers/validator';
import CustomEdge from './Components/Edges/CustomEdge';
import ActionCreateNode from './Components/Nodes/ActionCreateNode';
import ApprovalNode from './Components/Nodes/ApprovalNode';
import EndNode from './Components/Nodes/EndNode';
import NotificationNode from './Components/Nodes/NotificationNode';
import WorkflowSidebar from './Components/WorkflowSidebar';
import './dnd.css';

const selector = formValueSelector('workflow');

const WorkflowForm = (props) => {
	const {
		onSubmit,
		initial,
		user_id,
		datas: { modules, forms },
		MODULE_VALUE,
	} = props;
	const { t } = useTranslation();

	const reactFlowWrapper = useRef(null);
	const [reactFlowInstance, setReactFlowInstance] = useState(null);
	const [elements, setElements] = useState([]);
	const [value, setValue] = useState(false);
	const [formCheck, setFormCheck] = useState(false);

	const onConnect = (params) =>
		setElements((els) =>
			addEdge(
				{
					...params,
					type: 'custom',
					element_type: 'edge',
				},
				els
			)
		);
	const onElementsRemove = (elementsToRemove) => setElements((els) => removeElements(elementsToRemove, els));

	const onLoad = (_reactFlowInstance) => setReactFlowInstance(_reactFlowInstance);

	const onDragOver = (event) => {
		event.preventDefault();
		event.dataTransfer.dropEffect = 'move';
	};

	const onNodeDragStop = (event, node) => {
		console.log('drag stop', node.position);
		console.log(elements.filter((e) => e.id == node.id)[0].position);

		const updatedList = elements.map((obj) => {
			if (obj.id == node.id) {
				return { ...obj, position: node.position };
			}
			return obj;
		});
		setElements(updatedList);
	};

	const onDrop = (event) => {
		event.preventDefault();

		const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
		const type = event.dataTransfer.getData('application/reactflow/nodeType');
		const label = event.dataTransfer.getData('application/reactflow/label');
		const id = event.dataTransfer.getData('application/reactflow/nodeId');
		const position = reactFlowInstance.project({
			x: event.clientX - reactFlowBounds.left,
			y: event.clientY - reactFlowBounds.top,
		});
		const newNode = {
			id: id + '_' + uuidv4(),
			type,
			position,
			data: { label: label, onChange: setValue, value: {} },
			element_type: 'node',
		};

		setElements((es) => es.concat(newNode));
	};

	const onEdgeUpdate = (oldEdge, newConnection) => setElements((els) => updateEdge(oldEdge, newConnection, els));

	const nodeTypes = {
		actionCreateNode: ActionCreateNode,
		approvalNode: ApprovalNode,
		endNode: EndNode,
		notificationNode: NotificationNode,
	};

	const edgeTypes = {
		custom: CustomEdge,
	};

	useEffect(() => {
		props.change('flow_body', JSON.stringify(elements));
	}, [elements, value]);

	useEffect(() => {
		if (initial) {
			const initialElements = JSON.parse(initial.flow_body).map((e) => {
				if (e.data) {
					e.data.onChange = setValue;
				}
				return e;
			});
			setElements(initialElements);
			props.initialize({
				...initial,
				module_id: _.find(forms, (o) => o.form_id == initial.form_id).module_id,
			});
		}

		props.change('user_id', user_id);
	}, []);

	const checkFormName = (form_id) => {
		if (form_id) {
			axios.get(`workflow/check/${form_id}`).then((response) => {
				setFormCheck(response.data);
			});
		} else {
			setFormCheck(false);
		}
	};

	return (
		<form onSubmit={props.handleSubmit(onSubmit)}>
			<Row>
				<Col xl={12} lg={12} md={12} sm={12} xs={12}>
					<Card border="light" className="bg-white shadow-sm mb-4  w-100">
						<Card.Body>
							<h5 className="mb-4">{t('workflow-form')}</h5>
							<Row className="mb-3">
								<Col>
									<Field
										label={t('module')}
										name="module_id"
										dataKey="module_id"
										textField="module_name"
										data={modules}
										component={renderSearchableDropdown}
										validate={required}
									/>
								</Col>
								<Col>
									<Field
										label={t('form')}
										name="form_id"
										data={MODULE_VALUE ? _.filter(forms, (o) => o.module_id == MODULE_VALUE) : []}
										dataKey="form_id"
										textField="form_name"
										component={renderSearchableDropdown}
										validate={required}
										onChange={(e) => checkFormName(e)}
									/>
									{formCheck && <strong className="text-danger">{t('workflow-already-exists')}</strong>}
								</Col>
							</Row>
							<Row className="mb-3">
								<Col>
									<Field label={t('workflow-name')} name="workflow_name" component={renderInput} validate={required} />
								</Col>
								<Col>
									<Field label={t('description')} name="description" component={renderInput} validate={required} />
								</Col>
							</Row>
							<div className="dndflow">
								<ReactFlowProvider>
									<div className="reactflow-wrapper" ref={reactFlowWrapper}>
										<ReactFlow
											className="touchdevice-flow"
											// style={{ backgroundColor: 'black' }}
											elements={elements}
											snapToGrid={true}
											nodeTypes={nodeTypes}
											edgeTypes={edgeTypes}
											defaultZoom={0.8}
											onLoad={onLoad}
											onDrop={onDrop}
											onConnect={onConnect}
											onElementsRemove={onElementsRemove}
											onEdgeUpdate={onEdgeUpdate}
											onNodeDragStop={onNodeDragStop}
											onDragOver={onDragOver}>
											<Controls />
											<Background color="#aaa" gap={16} />
										</ReactFlow>
									</div>
									<WorkflowSidebar />
								</ReactFlowProvider>
							</div>
							<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 mb-4">
								<a onClick={() => window.history.back()} className="btn btn-secondary">
									{t('back')}{' '}
								</a>
								<SubmitButton disabled={formCheck} invalid={false} onSubmit={onSubmit} text={t('submit')} />
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</form>
	);
};

const mapStateToProps = (state) => {
	const MODULE_VALUE = selector(state, 'module_id');
	const {
		auth: { user_id },
	} = state;

	return { user_id, MODULE_VALUE };
};

export default compose(connect(mapStateToProps, { initialize }), reduxForm({ form: 'workflow' }))(WorkflowForm);
