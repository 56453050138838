import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getSupplier, updateSupplier } from '../../../../actions//Procurement/suppliersActions';
import { clearData } from '../../../../actions/globalActions';
import SuppliersForm from './SuppliersForm';
import LoadingSpinner from '../../../../components/LoadingSpinner';

const SuppliersEdit = (props) => {
	const { id } = props.match.params;
	const { supplier, loading } = props;

	useEffect(() => {
		props.getSupplier(id);

		return () => {
			props.clearData();
		};
	}, []);

	const onSubmit = (formValues) => {
		console.log('a');
		if (!_.isEmpty(formValues) && formValues.isValid) {
			console.log('b');
			props.updateSupplier(formValues);
		}
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<SuppliersForm onSubmit={onSubmit} initials={supplier} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		supplier: state.procurement.supplier.supplier,
		loading: state.procurement.supplier.getSupplierLoading,
	};
};

export default connect(mapStateToProps, {
	getSupplier,
	clearData,
	updateSupplier,
})(SuppliersEdit);
