import { Modal, Button } from '@themesberg/react-bootstrap';
import React from 'react';
import { reduxForm } from 'redux-form';
import validate from '../validate';

const ModalComponent = ({ show, header, handleClose, onSubmit, children, handleSubmit }) => {
	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>{header}</Modal.Title>
			</Modal.Header>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Modal.Body>{children}</Modal.Body>
			</form>
		</Modal>
	);
};

export default reduxForm({ form: 'modal', validate })(ModalComponent);
