import React, { Component, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faHome, faTrash, faTasks, faFile } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb, Nav } from '@themesberg/react-bootstrap';

import { connect } from 'react-redux';
import { getISRsWithPagination, deleteISR } from '../../../../actions//Procurement/isrActions';
import { getProjects } from '../../../../actions//Finance/projectActions';
import { Link } from 'react-router-dom';
import IndexTable from '../../../../components/IndexTable';
import history from '../../../../history';

import onDelete from '../../../../helpers/deleteFunction';
import { clearData } from '../../../../actions/globalActions';

import _ from 'lodash';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import authorizationCheckFunction from '../../../../helpers/authorizationCheckFunction';
import { Routes } from '../../../../routes';
import Multiselect from 'react-widgets/Multiselect';
import TableFilter from '../../../../components/TableFilter';
import ReactPaginate from 'react-paginate';
import { indexTableFilterQueryGenerator } from '../../../../helpers/helpers';
import { useTranslation } from 'react-i18next';

const ISRIndex = (props) => {
	const { isrs_table, loading, userPolicy, companyFilter } = props;
	const [deleted, setDeleted] = useState(false);
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [query, setQuery] = useState('');
	const [order, setOrder] = useState('');
	const [search, setSearch] = useState('');

	const [filters, setFilters] = useState([]);
	const { t } = useTranslation();

	const initialFilterCategories = [
		{ label: t('company'), displayField: null, value: 'company.company_name', queryName: t('company') },
		{ label: t('project'), displayField: null, value: 'project.project_name', queryName: t('project') },
		{ label: t('status'), displayField: null, value: 'status', queryName: t('status') },
	];

	const [filterCategories, setFilterCategories] = useState(initialFilterCategories);

	// ISR GET ALL WHEN
	// ITEM IS DELETED
	// LIMIT || OFFSET || QUERY CHANGES
	useEffect(() => {
		props.getISRsWithPagination(offset, limit, query, order, search);

		return () => {
			props.clearData();
		};
	}, [deleted, limit, offset, query, order, search]);

	// CHANGE QUERY WHEN FILTER OBJ CHANGES
	useEffect(() => {
		indexTableFilterQueryGenerator(filters, setQuery);
	}, [filters.length]);

	const renderRows = () => {
		return isrs_table.map((val, index) => {
			if (index != isrs_table.length - 1) {
				return (
					<tr key={index}>
						<td className="fw-bold text-center">{val.reference}</td>
						<td className="fw-bold text-center">{val.project.project_name}</td>
						<td className="fw-bold text-center">{val.company.company_name}</td>
						<td className="fw-bold text-center">{val.from}</td>
						<td className="fw-bold text-center">{val.status}</td>

						<td className="fw-bold text-center">{new Date(val.start_date).toLocaleDateString()}</td>
						<td className="fw-bold text-center">{new Date(val.due_date).toLocaleDateString()}</td>
						<td className="fw-bold text-center">
							{authorizationCheckFunction(Routes.Procurement.ISR.Delete.policy, userPolicy) ? (
								<button
									onClick={() => {
										onDelete(deleted, setDeleted, props.deleteISR, val.id);
									}}
									className="btn btn-danger mx-2">
									<FontAwesomeIcon icon={faTrash} />
								</button>
							) : null}
							<Link to={Routes.Procurement.ISR.Detail.path.replace(':id', val.id)} className="btn btn-primary mx-2">
								<FontAwesomeIcon icon={faEye} />
							</Link>
							{authorizationCheckFunction(Routes.Procurement.ISR.Edit.policy, userPolicy) ? (
								<Link to={Routes.Procurement.ISR.Edit.path.replace(':id', val.id)} className="btn btn-primary mx-2">
									<FontAwesomeIcon icon={faEdit} />
								</Link>
							) : null}
							{val.status.includes('Approved |') ? (
								<Link
									to={Routes.Procurement.ISR.Checklist.path.replace(':id', val.id)}
									className="btn btn-secondary mx-2">
									<FontAwesomeIcon icon={faTasks} />
								</Link>
							) : null}
						</td>
					</tr>
				);
			} else {
				return null;
			}
		});
	};

	// REACT PAGINATION ON CLICK FUNCTION
	const handlePageClick = (event) => {
		setOffset(event.selected);
	};

	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0 ">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{t('internal-service-requests')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<Nav
					className="justify-content-center row mt-3"
					variant="tabs"
					defaultActiveKey={Routes.Procurement.ISR.Index.path}>
					<Nav.Item className="col text-center ">
						<Nav.Link
							className="bg-primary  text-white"
							onClick={() => {
								history.push(Routes.Procurement.ISR.Index.path);
							}}>
							{t('internal-service-request')}{' '}
						</Nav.Link>
					</Nav.Item>
					<Nav.Item className="col text-center">
						<Nav.Link
							onClick={() => {
								history.push(Routes.Procurement.LPO.Index.path);
							}}>
							{t('local-purchase-order')}{' '}
						</Nav.Link>
					</Nav.Item>
				</Nav>
				<br />
				<div
					className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center
          ">
					<h4 style={{ display: 'inline' }}>{t('internal-service-requests-0')}</h4>
					<div>
						{/* <a
							className="btn btn-secondary py-2 px-3 mx-4"
							target="blank"
							href={`${process.env.REACT_APP_SHAREPOINT_BASE_URL}${process.env.REACT_APP_SHAREPOINT_DOCUMENTS}ISR`}>
							<FontAwesomeIcon className="me-2" icon={faFile} /> Documents
						</a> */}
						<Link className="btn btn-primary py-2 px-3  " to={Routes.Procurement.ISR.Create.path}>
							{t('add-new')}{' '}
						</Link>
					</div>
				</div>
				<p className="mb-0">{t('your-most-recent-requests-can-be-seen-here')}</p>
			</div>

			<IndexTable
				headerProps="text-center"
				// headers={['Project Name',
				// 'Project Type', 'Owner', 'Status', 'REF', 'Start Date', 'Due Date', '']}

				headers={[
					{ label: t('reference'), value: 'reference', sortable: true },
					{ label: t('project-name'), value: 'project.project_name', sortable: false },
					{ label: t('company-name'), value: 'company.company_name', sortable: false },
					{ label: t('owner'), value: 'from', sortable: true },
					{ label: t('status'), value: 'status', sortable: true },
					{ label: t('start-date'), value: 'start_date', sortable: true },
					{ label: t('due-date'), value: 'due_date', sortable: true },
					{ label: '', value: '', sortable: false },
				]}
				renderRows={isrs_table.length > 1 ? renderRows() : null}
				loading={loading}
				setOrder={setOrder}
				searchable
				setSearch={setSearch}
				filter={
					<div className="mb-3">
						<TableFilter
							initialFilterCategories={initialFilterCategories}
							filters={filters}
							setFilters={setFilters}
							datas={isrs_table}
							setQuery={setQuery}
							filterCategories={filterCategories}
							setFilterCategories={setFilterCategories}
						/>
					</div>
				}
				pagination={
					isrs_table.length > 1 ? (
						<ReactPaginate
							nextLabel=" >"
							onPageChange={handlePageClick}
							pageCount={Math.ceil(_.last(isrs_table).page_count)}
							pageRangeDisplayed={3}
							previousLabel="< "
							pageClassName="page-item"
							pageLinkClassName="page-link"
							previousClassName="page-item"
							forcePage={offset}
							previousLinkClassName="page-link"
							nextClassName="page-item"
							nextLinkClassName="page-link"
							breakLabel="..."
							breakClassName="page-item"
							breakLinkClassName="page-link"
							containerClassName="pagination"
							activeClassName="active"
							renderOnZeroPageCount={null}
						/>
					) : null
				}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		isrs_table: state.procurement.isr.isrs_table,
		loading: state.procurement.isr.getISRsLoading,
		userPolicy: state.auth.policies,
		companyFilter: state.auth.companyFilter,
	};
};

export default connect(mapStateToProps, {
	getISRsWithPagination,
	deleteISR,
	clearData,
	getProjects,
})(ISRIndex);
