import {
	GET_TICKET_CATEGORIES,
	GET_TICKET_CATEGORY,
	CREATE_TICKET_CATEGORY,
	UPDATE_TICKET_CATEGORY,
	DELETE_TICKET_CATEGORY,
} from '../../actions/Admin/types';
import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	ticketCategory: {},
	ticketCategories: [],
	getTicketCategoriesLoading: true,
	getTicketCategoryLoading: true,
};
export default (state = initialState, action) => {
	switch (action.type) {
		case GET_TICKET_CATEGORIES:
			return { ...state, ticketCategories: action.payload, getTicketCategoriesLoading: false };
		case GET_TICKET_CATEGORY:
			return { ...state, ticketCategory: action.payload, getTicketCategoryLoading: false };
		case CREATE_TICKET_CATEGORY:
			return { ...state, ticketCategory: action.payload };
		case UPDATE_TICKET_CATEGORY:
			return { ...state };
		case CLEAR_DATA:
			return {
				...state,
				ticketCategory: action.payload,
				getTicketCategoryLoading: true,
				getTicketCategoriesLoading: true,
			};
		case DELETE_TICKET_CATEGORY:
			return { ...state };
		default:
			return state;
	}
};
