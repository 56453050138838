import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Card, Col, Form, Row, Table } from '@themesberg/react-bootstrap';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { change, Field, FieldArray, initialize, reduxForm, formValueSelector } from 'redux-form';
import renderInput from '../../../../components/formComponents/renderInput';

import SubmitButton from '../../../../components/formComponents/SubmitButton';
import { Routes } from '../../../../routes';

import renderItemTable from './ProductsFormComponents/renderItemTable';
import { date, nonZero, required, phoneNumber, minLength, notEmpty, maxLength40 } from '../../../../helpers/validator';
import axios from '../../../../apis/axios';
import { useTranslation } from 'react-i18next';

const selector = formValueSelector('item');
const ProcurementItemForm = (props) => {
	const { initials, item_category_code, onSubmit } = props;
	const { t } = useTranslation();

	const [categoryCodeCheck, setCategoryCodeCheck] = useState(false);
	const [categoryNameCheck, setCategoryNameCheck] = useState(false);
	useEffect(() => {
		if (initials) {
			// const arr=initials.items.map((category,index)=>{
			// 	return{category_id:item.item_id,}
			// })
			props.initialize({ ...initials, item_category_name: initials.item_category_name });
		}
	}, []);
	useEffect(() => {
		props.change('items.0.item_code', item_category_code);
	}, [item_category_code]);

	const checkCategoryCode = (code) => {
		if (code) {
			axios.get(`item-category/check/code/${code}`).then((response) => {
				setCategoryCodeCheck(response.data);
			});
		} else {
			setCategoryCodeCheck(false);
		}
	};

	const checkCategoryName = (name) => {
		if (name) {
			axios.get(`item-category/check/name/${name}`).then((response) => {
				setCategoryNameCheck(response.data);
			});
		} else {
			setCategoryNameCheck(false);
		}
	};

	return (
		<>
			<div className="d-block mb-4 mb-xl-0 ">
				<Breadcrumb
					className=" d-block"
					listProps={{
						className: 'breadcrumb-dark breadcrumb-transparent',
					}}>
					<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
						<FontAwesomeIcon icon={faHome} />
					</Breadcrumb.Item>

					<Breadcrumb.Item active>{t('item')} </Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<form onSubmit={props.handleSubmit(onSubmit)}>
				<Row>
					<Col md={12}>
						<Card border="light" className="bg-white shadow-sm mb-4 w-100">
							<Card.Body>
								<h5 className="mb-4">{t('item-form')}</h5>
								<Row className="mb-3">
									<Col md={6} sm={12}>
										<Field
											validate={required}
											onChange={(e) => checkCategoryCode(e.target.value)}
											name="item_category_code"
											inputType={t('number')}
											label={t('category-code')}
											inputProps={{ min: '100', max: '999' }}
											component={renderInput}></Field>
									</Col>
									<Col md={6} sm={12}>
										<Field
											validate={[required, maxLength40]}
											onChange={(e) => checkCategoryName(e.target.value)}
											name="item_category_name"
											label={t('category-name')}
											component={renderInput}></Field>
									</Col>
								</Row>
								<Row className="mb-3">
									<Col className="mb-3">
										<table className="table overflow-auto formTable w-100 ">
											<thead>
												<tr>
													<th className="text-center">{t('item-id')}</th>
													<th className="text-center">{t('item-name')}</th>
												</tr>
											</thead>
											<tbody>
												<FieldArray
													name="items"
													change={props.change}
													category_code={item_category_code}
													component={renderItemTable}
												/>
											</tbody>
										</table>
									</Col>
								</Row>
								{categoryCodeCheck && (
									<strong className="text-danger d-block">{t('the-category-code-is-already-used')}</strong>
								)}
								{categoryNameCheck && (
									<strong className="text-danger d-block">{t('the-category-name-is-already-used')}</strong>
								)}
								<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
									<Link to={Routes.Procurement.Item.Index.path} className="btn btn-secondary">
										{t('back')}{' '}
									</Link>
									<SubmitButton
										disabled={categoryCodeCheck || categoryNameCheck}
										invalid={false}
										onSubmit={onSubmit}
										text={t('submit')}
									/>
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</form>
		</>
	);
};
const mapStateToProps = (state) => {
	const item_category_code = selector(state, 'item_category_code');

	return { item_category_code };
};
export default compose(
	connect(mapStateToProps, { initialize, change }),
	reduxForm({ form: 'item' })
)(ProcurementItemForm);
