import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getDepartment, updateDepartment } from '../../../../actions/Admin/departmentActions';
import DepartmentForm from './DepartmentForm';

import LoadingSpinner from '../../../../components/LoadingSpinner';

const DepartmentEdit = (props) => {
	const { id } = props.match.params;
	const { department, loading } = props;

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			props.updateDepartment(formValues);
		}
	};

	useEffect(() => {
		props.getDepartment(id);
	}, []);

	if (loading) {
		return <LoadingSpinner />;
	} else {
		return (
			<div className="mt-3">
				<DepartmentForm onSubmit={onSubmit} initials={{ departmentName: department.department_name, id: id }} />
			</div>
		);
	}
};

const mapStateToProps = (state) => {
	return {
		department: state.admin.department.department,
		loading: state.admin.department.getDepartmentLoading,
	};
};

export default connect(mapStateToProps, { getDepartment, updateDepartment })(DepartmentEdit);
