import {
	CREATE_ITEM,
	UPDATE_ITEM,
	GET_ITEMS,
	GET_ITEM,
	DELETE_ITEM,
	GET_CATEGORIES,
	DELETE_CATEGORY,
	CREATE_CATEGORY,
	UPDATE_CATEGORY,
	GET_CATEGORY,
} from './types';

import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';

{
	/* THESE ARE DELIVERY ITEM ACTIONS*/
}
export const createItem = (formValues) => async (dispatch, getState) => {
	axios
		.post('/delivery/item', formValues)
		.then((response) => {
			dispatch({ type: CREATE_ITEM, payload: response.data });
			history.push(Routes.Delivery.Panel.Item.ItemIndex.path);
		})
		.catch((err) => {});
};

export const getItems = () => async (dispatch) => {
	const response = await axios.get('/delivery/item');
	dispatch({ type: GET_ITEMS, payload: response.data });
};

export const getItem = (id) => async (dispatch) => {
	const response = await axios.get(`/delivery/item/${id}`);
	dispatch({ type: GET_ITEM, payload: response.data });
};

export const deleteItem = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`delivery/item/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_ITEM, payload: response.data });
			setDeleted(!deleted);
		})
		.catch((err) => {});
};

export const updateItem = (formValues) => async (dispatch) => {
	axios
		.put(`delivery/item/${formValues.item_id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_ITEM, payload: response.data });
			history.push(Routes.Delivery.Panel.Item.ItemIndex.path);
		})
		.catch((err) => {});
};

{
	/* THIS IS DELIVERY ITEM CATEGORY ACTION*/
}

export const getCategories = () => async (dispatch) => {
	const response = await axios.get('/delivery/item-category');
	dispatch({ type: GET_CATEGORIES, payload: response.data });
};

export const deleteCategory = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`delivery/item-category/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_CATEGORY, payload: response.data });
			setDeleted(!deleted);
		})
		.catch((err) => {});
};

export const createCategory = (formValues) => async (dispatch, getState) => {
	axios
		.post('/delivery/item-category', formValues)
		.then((response) => {
			dispatch({ type: CREATE_CATEGORY, payload: response.data });
			history.push(Routes.Delivery.Panel.Category.Index.path);
		})
		.catch((err) => {});
};

export const updateCategory = (formValues) => async (dispatch) => {
	axios
		.put(`delivery/item-category/${formValues.category_id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_CATEGORY, payload: response.data });
			history.push(Routes.Delivery.Panel.Category.Index.path);
		})
		.catch((err) => {});
};

export const getCategory = (id) => async (dispatch) => {
	const response = await axios.get(`/delivery/item-category/${id}`);
	dispatch({ type: GET_CATEGORY, payload: response.data });
};
