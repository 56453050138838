import { faEdit, faHome, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, BreadcrumbItem } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { clearData } from '../../../../actions/globalActions';
import { deleteItem, getItemsWithFilters } from '../../../../actions/Procurement/itemActions';
import IndexTable from '../../../../components/IndexTable';
import { Routes } from '../../../../routes';
import onDelete from '../../../../helpers/deleteFunction';
import { indexTableFilterQueryGenerator } from '../../../../helpers/helpers';
import TableFilter from '../../../../components/TableFilter';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';

const ProcurementItemIndex = (props) => {
	const { items, itemsLoading } = props;
	const [deleted, setDeleted] = useState(false);
	const { t } = useTranslation();

	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [query, setQuery] = useState('');
	const [order, setOrder] = useState('');
	const [search, setSearch] = useState('');

	const [filters, setFilters] = useState([]);

	const initialFilterCategories = [
		{
			label: t('categories'),
			displayField: 'item_category_name',
			value: 'item_category_code',
			queryName: t('category'),
		},
	];

	const [filterCategories, setFilterCategories] = useState(initialFilterCategories);

	useEffect(() => {
		props.getItemsWithFilters(offset, limit, query, order, search);

		return () => {
			props.clearData();
		};
	}, [deleted, limit, offset, query, order, search]);

	// CHANGE QUERY WHEN FILTER OBJ CHANGES
	useEffect(() => {
		indexTableFilterQueryGenerator(filters, setQuery);
	}, [filters.length]);

	// REACT PAGINATION ON CLICK FUNCTION
	const handlePageClick = (event) => {
		setOffset(event.selected);
	};

	const renderRows = () => {
		return items.map((val, index) => {
			if (index != items.length - 1) {
				let items = _.toString(_.map(val.items, 'item_name'));
				let cutOffLength = 30;
				return (
					<tr key={index} style={{ cursor: 'pointer' }}>
						<td className="fw-bold text-center">{val.item_category_code}</td>
						<td className="fw-bold text-center">{val.item_category_name}</td>
						<td className="fw-bold text-center">
							{items.slice(0, cutOffLength) + (items.length > cutOffLength ? '...' : '')}{' '}
						</td>

						<td className="fw-bold text-center">
							<Link
								to={Routes.Procurement.Item.Edit.path.replace(':id', val.item_category_code)}
								className="btn btn-primary mx-2">
								<FontAwesomeIcon icon={faEdit} />
							</Link>
							<button
								onClick={() => {
									onDelete(deleted, setDeleted, props.deleteItem, val.item_category_code);
								}}
								className="btn btn-danger mx-2">
								<FontAwesomeIcon icon={faTrash} />
							</button>
						</td>
					</tr>
				);
			} else {
				return null;
			}
		});
	};

	return (
		<>
			<div className="py-4">
				<div className="d-block mb-4 mb-xl-0">
					<Breadcrumb className="d-block" listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}>
						<BreadcrumbItem linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</BreadcrumbItem>
						<BreadcrumbItem active>{t('procurement-products')}</BreadcrumbItem>
					</Breadcrumb>
				</div>
				<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
					<h4 style={{ display: 'inline' }}>{t('procurement-products-0')}</h4>
					<div>
						<Link className="btn btn-primary py-2 px-3  " to={Routes.Procurement.Item.Create.path}>
							{t('add-new')}{' '}
						</Link>
					</div>
				</div>
				<p className="mb-0">{t('here-you-can-manage-procurement-departments-products')}</p>
			</div>
			<IndexTable
				headerProps="text-center"
				headers={[
					{ label: t('category-code'), value: 'item_category_code', sortable: true },
					{ label: t('category-name'), value: 'item_category_name', sortable: true },
					t('item-names'),
					'',
				]}
				renderRows={items.length > 1 ? renderRows() : null}
				loading={itemsLoading}
				setOrder={setOrder}
				searchable
				setSearch={setSearch}
				filter={
					<div className="mb-3">
						<TableFilter
							initialFilterCategories={initialFilterCategories}
							filters={filters}
							setFilters={setFilters}
							datas={items}
							setQuery={setQuery}
							filterCategories={filterCategories}
							setFilterCategories={setFilterCategories}
						/>
					</div>
				}
				pagination={
					items.length > 1 ? (
						<ReactPaginate
							nextLabel=" >"
							onPageChange={handlePageClick}
							pageCount={Math.ceil(_.last(items).page_count)}
							pageRangeDisplayed={3}
							previousLabel="< "
							pageClassName="page-item"
							pageLinkClassName="page-link"
							previousClassName="page-item"
							forcePage={offset}
							previousLinkClassName="page-link"
							nextClassName="page-item"
							nextLinkClassName="page-link"
							breakLabel="..."
							breakClassName="page-item"
							breakLinkClassName="page-link"
							containerClassName="pagination"
							activeClassName="active"
							renderOnZeroPageCount={null}
						/>
					) : null
				}></IndexTable>
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		items: state.procurement.item.items,
		itemsLoading: state.procurement.item.getItemsLoading,
	};
};
export default connect(mapStateToProps, { getItemsWithFilters, clearData, deleteItem })(ProcurementItemIndex);
