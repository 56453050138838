import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import ClearanceForm from './ClearanceForm';
import { getEmployees } from '../../../../actions/HumanResources/employeeActions';
import { updateClearance, getClearance } from '../../../../actions/HumanResources/clearanceActions';
import { clearData } from '../../../../actions/globalActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import _ from 'lodash';
const ClearanceEdit = (props) => {
	const { id } = props.match.params;
	const { datas, loading } = props;
	useEffect(() => {
		props.getEmployees();
		props.getClearance(id);
		return () => {
			props.clearData();
		};
	}, []);
	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.updateClearance(formValues);
		}
	};
	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<ClearanceForm datas={datas} onSubmit={onSubmit} initials={datas.clearance} />
				</div>
			)}
		</>
	);
};
const mapStateToProps = (state) => {
	const {
		humanResources: {
			employee: { employees, getEmployeesLoading },
			clearance: { clearance, getClearanceLoading },
		},
	} = state;
	return {
		datas: { employees, clearance },
		loading: getClearanceLoading || getEmployeesLoading,
	};
};

export default connect(mapStateToProps, { getEmployees, clearData, getClearance, updateClearance })(ClearanceEdit);
