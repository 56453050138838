import _, { includes } from 'lodash';
import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getTicket, resolveTicket, redirectTicket, escalateTicket } from '../../../actions//ITDepartment/ticketActions';
import { Card, Col, ListGroup, Row, Modal, Button } from '@themesberg/react-bootstrap';
import { getUsers } from '../../../actions/User/userActions';
import { clearData } from '../../../actions/globalActions';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { Routes } from '../../../routes';
import history from '../../../history';
import ModalComponent from '../../../components/ModalComponent';
import { Field } from 'redux-form';
import renderTextArea from '../../../components/formComponents/renderTextArea';
import renderDropdown from '../../../components/formComponents/renderDropdown';
import { useTranslation } from 'react-i18next';

const TicketDetail = (props) => {
	const ticket_id = props.match.params.id;
	const {
		datas: { ticket, auth, users },
		loading,
	} = props;

	const {
		department: { department_name },
		roles,
		user_id,
	} = auth;

	const [showAssignModal, setShowAssignModal] = useState(false);
	const [showResolvedModal, setShowResolvedModal] = useState(false);
	const [showEscalateModal, setShowEscalateModal] = useState(false);

	useEffect(() => {
		props.getTicket(ticket_id);
		props.getUsers();

		return () => {
			props.clearData();
		};
	}, []);

	const handleAssignModalClose = () => setShowAssignModal(false);
	const handleResolvedModalClose = () => setShowResolvedModal(false);
	const handleEscalateModalClose = () => setShowEscalateModal(false);

	const handleAssignModalSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.redirectTicket(ticket_id, formValues);
			setShowAssignModal(false);
		}
	};

	const handleResolvedModalSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.resolveTicket(ticket_id, formValues);
			setShowResolvedModal(false);
		}
	};

	const handleEscalateModalSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.escalateTicket(ticket_id, formValues);
			setShowEscalateModal(false);
		}
	};

	const renderUserOptions = () => {
		return users.map((user, index) => {
			return (
				<option key={index} value={user.user_id}>
					{user.full_name}
				</option>
			);
		});
	};
	const { t } = useTranslation();

	const renderActionButton = () => {
		if (ticket.status == t('resolved') || ticket.status == t('completed')) {
			return null;
		} else if (
			(ticket.status == t('in-progress') || ticket.status == t('open')) &&
			roles.some((role) => role.role_name.includes(t('it-manager')))
		) {
			// ROLE -> IT MANAGER & STATUS -> IN PROGRESS => Assign/Resolve
			return (
				<>
					<button type="button" className="btn btn-secondary me-3" onClick={() => setShowAssignModal(true)}>
						{t('assign')}{' '}
					</button>
					<button
						className="btn btn-primary"
						onClick={() => {
							setShowResolvedModal(true);
						}}>
						{t('mark-as-resolved')}{' '}
					</button>
				</>
			);
		} else if (
			ticket.status == t('in-progress-0') &&
			department_name == t('engineering')
			// ticket.assignedUser.user_id == user_id
		) {
			// ROLE -> ENGINEER & STATUS -> IN PROGRESS => Escalate/Resolve
			return (
				<>
					<button type="button" className="btn btn-secondary me-3" onClick={() => setShowEscalateModal(true)}>
						{t('escalate')}{' '}
					</button>
					<button className="btn btn-primary" onClick={() => setShowResolvedModal(true)}>
						{t('mark-as-resolved-0')}{' '}
					</button>
				</>
			);
		}
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<>
					{/* //ASSIGN MODAL */}
					<ModalComponent
						children={
							<>
								<Row className="mb-3">
									<Col>
										<Field
											name="assigned_to"
											label={t('user')}
											component={renderDropdown}
											renderedOptions={renderUserOptions()}
										/>
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field rows={3} name="it_manager_notes" label={t('message')} component={renderTextArea} />
									</Col>
								</Row>
								<div className="d-flex justify-content-end flex-wrap flex-md-nowrap align-items-center ">
									<Button type="submit">{t('submit')}</Button>
								</div>
							</>
						}
						onSubmit={handleAssignModalSubmit}
						show={showAssignModal}
						handleClose={handleAssignModalClose}
						header={t('assign-ticket')}
					/>
					{/* //Escalate MODAL */}
					<ModalComponent
						children={
							<>
								<Row className="mb-3">
									<Col>
										<Field rows={3} name="engineer_notes" label={t('message-0')} component={renderTextArea} />
									</Col>
								</Row>
								<div className="d-flex justify-content-end flex-wrap flex-md-nowrap align-items-center ">
									<Button type="submit">{t('submit')}</Button>
								</div>
							</>
						}
						onSubmit={handleEscalateModalSubmit}
						show={showEscalateModal}
						handleClose={handleEscalateModalClose}
						header={t('escalate-ticket')}
					/>
					{/* //RESOLVE MODAL */}
					<ModalComponent
						children={
							<>
								<Row className="mb-3">
									<Col>
										<Field rows={3} name="engineer_notes" label={t('message-1')} component={renderTextArea} />
									</Col>
								</Row>
								<div className="d-flex justify-content-end flex-wrap flex-md-nowrap align-items-center ">
									<Button type="submit">{t('submit')}</Button>
								</div>
							</>
						}
						onSubmit={handleResolvedModalSubmit}
						show={showResolvedModal}
						handleClose={handleResolvedModalClose}
						header={t('resolve-ticket')}
					/>
					<Row>
						<Col md={12}>
							<div>
								{loading ? (
									<LoadingSpinner />
								) : (
									<div className="py-4">
										<Card>
											<Card.Header className="border-bottom-0 pb-0">
												<Row>
													<Col>
														<h4>
															{t('ticket-details')}-{' '}
															<span>
																{ticket.title} | {ticket.code}
															</span>
														</h4>
														<h6 className="ps-3 m-0	text-left ">
															<span className="text-muted">{t('by')} </span>
															{ticket.by.full_name}
															<br />

															<span className="text-muted">{t('to')} </span>
															{ticket.assignedUser.full_name}
														</h6>
													</Col>
													<Col>
														<h6 className="m-0	text-right ">
															<span className="text-muted">{t('submission-date')} </span>
															{new Date(ticket.date_created).toLocaleDateString()}{' '}
														</h6>
														{ticket.date_completer ? (
															<h6 className="m-0	text-right ">
																<span className="text-muted">{t('completion-date')} </span>
																{new Date(ticket.date_completer).toLocaleDateString()}{' '}
															</h6>
														) : null}
													</Col>
												</Row>
											</Card.Header>
											<Card.Body>
												<Col>
													<ListGroup>
														<ListGroup.Item className="border-bottom">
															<Row className="align-items-center  justfiy-content-center">
																<Col>
																	<h5 className="d-inline">
																		<span className="text-muted">Title:</span> {ticket.title}
																	</h5>
																</Col>
															</Row>
														</ListGroup.Item>
														<ListGroup.Item className="border-bottom">
															<Row className="align-items-center  justfiy-content-center">
																<Col>
																	<h5 className="d-inline">
																		<span className="text-muted">Severity:</span> {ticket.severity}
																	</h5>
																</Col>
															</Row>
														</ListGroup.Item>
														<ListGroup.Item className="border-bottom">
															<Row className="align-items-center  justfiy-content-center">
																<Col>
																	<h5 className="d-inline">
																		<span className="text-muted">Status:</span> {ticket.status}
																	</h5>
																</Col>
															</Row>
														</ListGroup.Item>
													</ListGroup>
													<ListGroup className="border-bottom">
														<ListGroup.Item>
															<Row>
																<Col>
																	<h6 className="mb-1">{t('department-or-category')}</h6>
																	<small className="text-gray-700">
																		{ticket?.department} - {ticket?.category}
																	</small>
																</Col>
															</Row>
														</ListGroup.Item>
													</ListGroup>
													<ListGroup className="border-bottom">
														<ListGroup.Item>
															<Row>
																<Col>
																	<h6 className="mb-1">{t('request')}</h6>
																	<small className="text-gray-700">
																		{ticket.issue
																			? ticket.issue.split('<br>').map((issue, index) => {
																					return (
																						<React.Fragment key={index}>
																							{issue}
																							<br />
																						</React.Fragment>
																					);
																			  })
																			: '-'}
																	</small>
																</Col>
															</Row>
														</ListGroup.Item>
													</ListGroup>
													<ListGroup className="border-bottom">
														<ListGroup.Item>
															<Row>
																<Col>
																	<h6 className="mb-1">{t('error-message')}</h6>
																	<small className="text-gray-700">{ticket.error_message}</small>
																</Col>
															</Row>
														</ListGroup.Item>
													</ListGroup>
													<ListGroup className="border-bottom">
														<ListGroup.Item>
															<Row>
																<Col>
																	<h6 className="mb-1">{t('it-manager-notes')}</h6>
																	<small className="text-gray-700">
																		{ticket.it_manager_notes
																			? ticket.it_manager_notes.split('<br>').map((it_manager_notes, index) => {
																					if (it_manager_notes != '') {
																						return (
																							<React.Fragment key={index}>
																								{it_manager_notes}
																								<br />
																							</React.Fragment>
																						);
																					}
																			  })
																			: '-'}
																	</small>
																</Col>
															</Row>
														</ListGroup.Item>
													</ListGroup>
													<ListGroup className="border-bottom">
														<ListGroup.Item>
															<Row>
																<Col>
																	<h6 className="mb-1">{t('engineer-notes')}</h6>
																	<small className="text-gray-700">
																		{ticket.engineer_notes
																			? ticket.engineer_notes.split('<br>').map((engineer_notes, index) => {
																					if (engineer_notes != '') {
																						return (
																							<React.Fragment key={index}>
																								{engineer_notes}
																								<br />
																							</React.Fragment>
																						);
																					}
																			  })
																			: '-'}
																	</small>
																</Col>
															</Row>
														</ListGroup.Item>
													</ListGroup>
												</Col>
												{ticket.status == t('completed-0') ? null : (
													<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
														<div>
															<button
																className="btn btn-secondary justify-content-start"
																onClick={() => {
																	history.push(Routes.IT_Department.Ticket.Index.path);
																}}>
																{t('back')}{' '}
															</button>
														</div>
														<div>{renderActionButton()}</div>
													</div>
												)}
											</Card.Body>
										</Card>
									</div>
								)}
							</div>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		itDepartment: {
			ticket: { ticket, getTicketLoading },
		},
		auth,
		user: { users, getUsersLoading },
	} = state;

	return {
		datas: { ticket, auth, users },
		loading: getTicketLoading || getUsersLoading,
	};
};

export default connect(mapStateToProps, {
	getTicket,
	redirectTicket,
	getUsers,
	clearData,
	resolveTicket,
	escalateTicket,
})(TicketDetail);
