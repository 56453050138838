import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import WarningLetterForm from './WarningLetterForm';

import { getEmployees } from '../../../../actions/HumanResources/employeeActions';
import { createWarningLetter } from '../../../../actions/HumanResources/warningLetterActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import _ from 'lodash';

const WarningLetterCreate = (props) => {
	const { datas, loading } = props;

	useEffect(() => {
		props.getEmployees();
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.createWarningLetter(formValues);
		}
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<WarningLetterForm datas={datas} onSubmit={onSubmit} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		humanResources: {
			employee: { employees, getEmployeesLoading },
		},
	} = state;

	return { datas: { employees }, loading: getEmployeesLoading };
};

export default connect(mapStateToProps, { getEmployees, createWarningLetter })(WarningLetterCreate);
