import { CREATE_LPO, DELETE_LPO, GET_LPO, GET_LPOS } from '../../actions/Procurement/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	lpo: {},
	lpos: [],
	getLPOLoading: true,
	getLPOsLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_LPOS:
			return { ...state, lpos: action.payload, getLPOsLoading: false };
		case GET_LPO:
			return { ...state, lpo: action.payload, getLPOLoading: false };

		case CREATE_LPO:
			return { ...state };
		case CLEAR_DATA:
			return {
				...state,
				lpo: action.payload,
				lpos: [],
				getLPOLoading: true,
				getLPOsLoading: true,
			};

		case DELETE_LPO:
			return { ...state };

		default:
			return state;
	}
};
