import { Form } from '@themesberg/react-bootstrap';
import DropdownList from 'react-widgets/DropdownList';

export default ({ input, data, dataKey, textField, label, meta, defaultSearchTerm }) => {
	return (
		<Form.Group>
			{label ? <Form.Label>{label}</Form.Label> : null}

			<DropdownList
				{...input}
				onBlur={() => input.onBlur()}
				onChange={(selected) => {
					input.onChange(dataKey ? selected[dataKey] : selected);
				}}
				value={input.value || ''}
				data={data || []}
				textField={textField || ''}
				dataKey={dataKey}
				defaultSearchTerm={defaultSearchTerm}
				filter={'contains'}
				className="searchable-dropdown"
			/>

			<span className="text-danger">{meta.touched && meta.error ? meta.error : null}</span>
		</Form.Group>
	);
};
