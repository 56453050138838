import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import EmployeeEvaluationForm from './EmployeeEvaluationForm';
import {
	getHREmployeeEvaluation,
	updateHREmployeeEvaluation,
} from '../../../../actions/HumanResources/employeeEvaluationActions';
import _ from 'lodash';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { getEmployees } from '../../../../actions/HumanResources/employeeActions';
import { clearData } from '../../../../actions/globalActions';

const EmployeeEvaluationEdit = (props) => {
	const { id } = props.match.params;
	const { datas, loading } = props;

	useEffect(() => {
		props.getHREmployeeEvaluation(id);
		props.getEmployees();

		return () => {
			props.clearData();
		};
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.updateHREmployeeEvaluation(formValues);
		}
	};

	return (
		<div className="mt-3">
			{loading ? (
				<LoadingSpinner />
			) : (
				<EmployeeEvaluationForm initials={datas.employeeEvaluation} datas={datas} onSubmit={onSubmit} />
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	const {
		humanResources: {
			employeeEvaluation: { employeeEvaluation, getEmployeeEvaluationLoading },
			employee: { employees, getEmployeesLoading },
		},
	} = state;

	return {
		datas: { employeeEvaluation, employees },
		loading: getEmployeesLoading || getEmployeeEvaluationLoading,
	};
};

export default connect(mapStateToProps, {
	getHREmployeeEvaluation,
	updateHREmployeeEvaluation,
	clearData,
	getEmployees,
})(EmployeeEvaluationEdit);
