// PARENT -> ENTITY FORM A BAK
import { Card, Col, Row } from '@themesberg/react-bootstrap';
import React, { useEffect } from 'react';
import { Field, initialize, reduxForm } from 'redux-form';

import renderInput from '../../../components/formComponents/renderInput';
import SubmitButton from '../../../components/formComponents/SubmitButton';
import validate from '../../../validate';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { clearData } from '../../../actions/globalActions';
import renderPhoneNumberInput from '../../../components/formComponents/renderPhoneNumberInput';
import { useTranslation } from 'react-i18next';

const ProfileForm = (props) => {
	const { initials, onSubmit, invalid } = props;
	useEffect(() => {
		props.initialize(initials);
		props.change('isValid', !invalid);

		return () => {
			props.clearData();
		};
	}, []);
	const { t } = useTranslation();

	return (
		<form onSubmit={props.handleSubmit(onSubmit)}>
			<Row>
				<Col md={12}>
					<Card border="light" className="bg-white shadow-sm mb-4  w-100">
						<Card.Body>
							<h5 className="mb-4">{t('profile-edit')}</h5>
							<Row className="">
								<Col xl={6} lg={6} md={6} sm={12} xs={12} className="mb-3">
									<Field name="first_name" label={t('first-name')} component={renderInput} required />
								</Col>
								<Col xl={6} lg={6} md={6} sm={12} xs={12} className="mb-3">
									<Field name="last_name" label={t('last-name')} component={renderInput} required />
								</Col>
							</Row>
							<Row className="">
								<Col xl={6} lg={6} md={6} sm={12} xs={12} className="mb-3">
									<Field name="phone_number" label={t('phone-number')} component={renderPhoneNumberInput} required />
								</Col>
								<Col xl={6} lg={6} md={6} sm={12} xs={12} className="mb-3">
									<Field
										name="mobile_phone_number"
										label={t('mobile-number')}
										component={renderPhoneNumberInput}
										required
									/>
								</Col>
							</Row>
							<Row className="">
								<Col xl={6} lg={6} md={6} sm={12} xs={12} className="mb-3">
									<Field name="email" label={t('email')} disabled component={renderInput} />
								</Col>
								<Col xl={6} lg={6} md={6} sm={12} xs={12} className="mb-3">
									<Field name="birthday" label={t('birthday')} component={renderInput} inputType="date" />
								</Col>
							</Row>
							<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
								<a onClick={() => window.history.back()} className="btn btn-secondary">
									{t('back')}{' '}
								</a>
								<SubmitButton invalid={invalid} onSubmit={onSubmit} text={t('submit')} />
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</form>
	);
};

export default compose(
	connect(null, { clearData, initialize }),
	reduxForm({
		form: 'profile',
		validate,
	})
)(ProfileForm);
