import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, ListGroup, Row, Table } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getCashFlow } from '../../../actions/CashFlow/cashFlowActions';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { Routes } from '../../../routes';

import onDelete from '../../../helpers/deleteFunction';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const CashFlowDetails = (props) => {
	const cash_flow_id = props.match.params.id;

	const [deleted, setDeleted] = useState(false);

	const { cashflow, loading } = props;
	const { t } = useTranslation();
	useEffect(() => {
		props.getCashFlow(cash_flow_id);
	}, [deleted]);

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3 col-lg-12 col-12">
					<Card className="p-0 ">
						<Card.Header className="border-bottom-0 px-4 my-auto">
							<h4 className="mb-0">{cashflow.title}</h4>
						</Card.Header>
						<Card.Body className="px-2 py-0">
							<ListGroup className=" border-top mb-4">
								<ListGroup.Item className="border-bottom">
									<Row>
										<Col>
											<h6 className="mb-1">{t('category')}</h6>
											<small className="text-gray-700">{cashflow.category}</small>
										</Col>
										<Col>
											<h6 className="mb-1">{t('tags')}</h6>
											<small className="text-gray-700">{cashflow.tags}</small>
										</Col>
									</Row>
								</ListGroup.Item>

								<ListGroup.Item className="border-bottom">
									<Row>
										<Col>
											<h6 className="mb-1">{t('date-0')}</h6>
											<small className="text-gray-700">{new Date(cashflow.date).toLocaleDateString()}</small>
										</Col>
										<Col>
											<h6 className="mb-1">{t('currency')}</h6>
											<small className="text-gray-700">{cashflow.currency}</small>
										</Col>
									</Row>
								</ListGroup.Item>

								<ListGroup.Item className="border-bottom">
									<Row>
										<Col>
											<h6 className="mb-1">{t('base-price')}</h6>
											<small className="text-gray-700">{cashflow.base}</small>
										</Col>
										<Col>
											<h6 className="mb-1">{t('value-added-tax')}</h6>
											<small className="text-gray-700">{cashflow.vat}%</small>
										</Col>
										<Col>
											<h6 className="mb-1">TAX</h6>
											<small className="text-gray-700">
												{(parseInt(cashflow.base) * parseInt(cashflow.vat)) / 100}
											</small>
										</Col>
										<Col>
											<h6 className="mb-1">{t('total')}</h6>
											<small className="text-gray-700">{cashflow.total}</small>
										</Col>
									</Row>
								</ListGroup.Item>
								<ListGroup.Item className="border-bottom">
									<Row>
										<Col>
											<h6 className="mb-1">{t('description')}</h6>
											<small className="text-gray-700">{cashflow.description}</small>
										</Col>
									</Row>
								</ListGroup.Item>
							</ListGroup>
							<Row className="mt-3 mb-3 px-2">
								<Col>
									<Link className="btn btn-secondary" to={Routes.CashFlow.Index.path}>
										{t('back')}{' '}
									</Link>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		cashflow: state.cashFlow.cashflow,
		loading: state.cashFlow.getCashFlowLoading,
	};
};

export default connect(mapStateToProps, { getCashFlow })(CashFlowDetails);
