import React, { Component, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb, Card, Col, Nav, Row, Table } from '@themesberg/react-bootstrap';
import history from '../../../../history';
import { Link } from 'react-router-dom';
import { Routes } from '../../../../routes';

import { getEntities, deleteEntity } from '../../../../actions/Admin/entityActions';
import { connect } from 'react-redux';
import IndexTable from '../../../../components/IndexTable';

import onDelete from '../../../../helpers/deleteFunction';
import { clearData } from '../../../../actions/globalActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const EntityIndex = (props) => {
	const {
		entities: { entities },
		loading,
	} = props;
	const { t } = useTranslation();
	const [deleted, setDeleted] = useState(false);

	useEffect(() => {
		props.getEntities();

		return () => {
			props.clearData();
		};
	}, [deleted]);

	const renderRows = () => {
		return entities.map((value, index) => {
			return (
				<tr key={index}>
					<td className="fw-bold text-center">{index + 1}</td>
					<td className="fw-bold text-center">{value.company_name}</td>
					<td className="fw-bold text-center">{value.userCount}</td>
					<td className="fw-bold text-center">
						<button
							onClick={() => {
								onDelete(deleted, setDeleted, props.deleteEntity, value.company_id);
							}}
							className="btn btn-danger mx-2">
							<i className="fa fa-trash  "></i>
						</button>
						<Link to={Routes.Admin.Entity.Edit.path.replace(':id', value.company_id)} className="btn btn-primary mx-2">
							<i className="fa fa-edit"></i>
						</Link>
					</td>
				</tr>
			);
		});
	};

	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0 ">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{t('entities')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<Nav className="justify-content-center row" variant="tabs" defaultActiveKey={Routes.Admin.Entity.Index.path}>
					<Nav.Item className="col text-center">
						<Nav.Link
							onClick={() => {
								history.push(Routes.Admin.User.Index.path);
							}}>
							{t('users')}{' '}
						</Nav.Link>
					</Nav.Item>
					<Nav.Item className="col text-center ">
						<Nav.Link
							className="bg-primary  text-white"
							onClick={() => {
								history.push(Routes.Admin.Entity.Index.path);
							}}>
							{t('companies')}{' '}
						</Nav.Link>
					</Nav.Item>
					<Nav.Item className="col text-center ">
						<Nav.Link
							onClick={() => {
								history.push(Routes.Admin.Invite.Index.path);
							}}>
							{t('user-invites')}{' '}
						</Nav.Link>
					</Nav.Item>
					<Nav.Item className="col text-center ">
						<Nav.Link
							onClick={() => {
								history.push(Routes.Admin.Department.Index.path);
							}}>
							{t('departments-0')}{' '}
						</Nav.Link>
					</Nav.Item>
				</Nav>
				<br />
				<div
					className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center
          ">
					<h4 style={{ display: 'inline' }}>{t('companies')}</h4>
					<div>
						<a
							className="btn btn-secondary py-2 px-3 mx-2"
							href="https://analytics.google.com/analytics/web/#/p289293858/reports/reportinghub"
							target="_blank"
							rel="noreferrer">
							{t('website-trafic-report')}{' '}
						</a>
						<Link className="btn btn-primary py-2 px-3  " to={Routes.Admin.Entity.Create.path}>
							{t('add-new')}{' '}
						</Link>
					</div>
				</div>
				<p className="mb-0">{t('company-list')}</p>
			</div>

			<IndexTable
				loading={loading}
				headerProps="text-center"
				headers={['#', t('company-name'), t('members-in-company'), '']}
				renderRows={entities.length != 0 ? renderRows() : null}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	return { entities: state.admin.entity, loading: state.admin.entity.getEntitiesLoading };
};

export default connect(mapStateToProps, {
	getEntities,
	deleteEntity,
	clearData,
})(EntityIndex);
