import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';
import { AZURE_LOGIN, COMPANY_FILTER, FIRST_REGISTER, SIGN_IN } from './types';

export const signIn = (instance, callMsGraph, accounts, loginRequest) => async (dispatch) => {
	instance
		.acquireTokenSilent({
			...loginRequest,
			account: accounts[0],
		})
		.then((response) => {
			callMsGraph(response.accessToken).then((msalResponse) => {
				dispatch({
					type: AZURE_LOGIN,
					payload: {
						...msalResponse,
					},
				});
				axios
					.post('user/check', {
						email: msalResponse.mail,
					})
					.then((checkResponse) => {
						axios.get(`policy/${checkResponse.data.user_id}`).then((policyResponse) => {
							dispatch({
								type: SIGN_IN,
								payload: {
									...checkResponse.data,
									policies: policyResponse.data,
									companyFilter: checkResponse.data.companies[0].company_name,
								},
							});
						});
					})
					.catch((err) => {
						if (err.response?.status === 404) {
							console.log('Invite not found');
							history.push(Routes.UnInvited.path);
						} else if (err.response?.status === 401) {
							dispatch({
								type: FIRST_REGISTER,
								payload: msalResponse.mail,
							});
							history.push(Routes.Registration.path);
							console.log('Invite Found & unregistered');
						} else {
							console.log('CHECK REGISTER ERROR: ', err.response?.data.message);
							console.log('ERROR: ', err);
							history.push(Routes.UnderConstruction.path);
						}
					});
			});
		})
		.catch((error) => {
			console.log(error);
			localStorage.clear();
			history.push(Routes.Signin.path);
		});
};

export const companyFilter = (companyFilter) => (dispatch) => {
	dispatch({ type: COMPANY_FILTER, payload: { companyFilter, companyFilterLoading: false } });
};
