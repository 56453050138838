import {
	GET_SUPPLIERS,
	CREATE_SUPPLIER,
	GET_SUPPLIER,
	DELETE_SUPPLIER,
	CREATE_SUPPLIER_EVALUATION,
	GET_SUPPLIER_EVALUATIONS,
	GET_SUPPLIER_EVALUATION,
	DELETE_SUPPLIER_EVALUATION,
	UPDATE_SUPPLIER_EVALUATION,
} from '../../actions/Procurement/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	suppliers: [],
	supplier: {},
	getSupplierLoading: true,
	getSuppliersLoading: true,
	supplierEvaluation: {},
	supplierEvaluations: [],
	getSupplierEvaluationsLoading: true,
	getSupplierEvaluationLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_SUPPLIERS:
			return {
				...state,
				suppliers: action.payload,
				getSuppliersLoading: false,
			};
		case GET_SUPPLIER:
			return { ...state, supplier: action.payload, getSupplierLoading: false };

		case CREATE_SUPPLIER:
			return { ...state, supplier: action.payload };

		case DELETE_SUPPLIER:
			return { ...state };

		case CREATE_SUPPLIER_EVALUATION:
			return { ...state, supplierEvaluation: action.payload };

		case GET_SUPPLIER_EVALUATIONS:
			return {
				...state,
				supplierEvaluations: action.payload,
				getSupplierEvaluationsLoading: false,
			};

		case GET_SUPPLIER_EVALUATION:
			return {
				...state,
				supplierEvaluation: action.payload,
				getSupplierEvaluationLoading: false,
			};
		case DELETE_SUPPLIER_EVALUATION:
			return {
				...state,
			};
		case UPDATE_SUPPLIER_EVALUATION:
			return {
				...state,
			};
		case CLEAR_DATA:
			return {
				...state,
				supplier: action.payload,
				supplierEvaluation: action.payload,
				getSupplierLoading: true,
				getSuppliersLoading: true,
				getSupplierEvaluationLoading: true,
				getSupplierEvaluationsLoading: true,
			};

		default:
			return state;
	}
};
