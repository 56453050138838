import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getCashFlow, updateCashFlow, getCashFlowTags } from '../../../actions/CashFlow/cashFlowActions';
import { clearData } from '../../../actions/globalActions';
import LoadingSpinner from '../../../components/LoadingSpinner';
import _ from 'lodash';
import CashFlowForm from './CashFlowForm';

const CashFlowEdit = (props) => {
	const { id } = props.match.params;
	const { datas, loading } = props;
	useEffect(() => {
		props.getCashFlowTags();
		props.getCashFlow(id);
		return () => {
			props.clearData();
		};
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			props.updateCashFlow(formValues);
		}
	};
	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<CashFlowForm tags={datas.cashflowTags} onSubmit={onSubmit} initials={datas.cashflow} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		cashFlow: { cashflow, getCashFlowLoading, cashflowTags },
	} = state;

	return {
		datas: { cashflow, cashflowTags },
		loading: getCashFlowLoading,
	};
};

export default connect(mapStateToProps, {
	getCashFlow,
	getCashFlowTags,
	clearData,
	updateCashFlow,
})(CashFlowEdit);
