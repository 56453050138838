import { Form } from "@themesberg/react-bootstrap";
import React from "react";

export default ({ label, meta, input, placeholder, rows }) => {
	return (
		<Form.Group>
			<Form.Label>{label}</Form.Label>
			<Form.Control
				as="textarea"
				rows={rows}
				placeholder={placeholder}
				{...input}
			/>
			<span className="text-danger">
				{meta.touched && meta.error ? meta.error : null}
			</span>
		</Form.Group>
	);
};
