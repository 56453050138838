import { Card, Col, Row } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { Field, formValues, reduxForm } from 'redux-form';
import renderDropdown from '../../../components/formComponents/renderDropdown';
import renderInput from '../../../components/formComponents/renderInput';
import renderTextArea from '../../../components/formComponents/renderTextArea';

import { Form } from '@themesberg/react-bootstrap';
import SubmitButton from '../../../components/formComponents/SubmitButton';
import { clearData } from '../../../actions/globalActions';
// import validate from '../../../validate';
import { required, nonZero, maxLength40, maxLength128 } from '../../../helpers/validator';
import { useTranslation } from 'react-i18next';

const TicketForm = (props) => {
	const { initials, onSubmit, invalid } = props;
	const [departmentSelected, setDepartment] = useState('');
	const { t } = useTranslation();

	useEffect(() => {
		props.initialize(initials);
		props.change('isValid', !invalid);
	}, []);

	return (
		<form onSubmit={props.handleSubmit(onSubmit)}>
			<Row>
				<Col xl={10} lg={10} md={12}>
					<Card border="light" className="bg-white shadow-sm mb-4  w-100">
						<Card.Body>
							<h5 className="mb-4">{t('create-a-service-request')}</h5>
							<Row className="mb-3">
								<Col md={6}>
									<Field name="by" label={t('by')} component={renderInput} disabled={true} />
								</Col>
								<Col md={6}>
									<Field
										name="company_id"
										label={t('company')}
										component={renderDropdown}
										validate={[required, nonZero]}
										renderedOptions={props.initials.companies.map((company) => {
											return (
												<option key={company.company_id} value={company.company_id}>
													{company.company_name}
												</option>
											);
										})}
									/>
								</Col>
							</Row>
							<hr />
							<Row className="mb-3">
								<Col md={6}>
									<Field name="title" label={t('title')} validate={[required, maxLength40]} component={renderInput} />
								</Col>
								<Col md={6} sm={12}>
									<Field
										name="severity"
										label={t('severity-risk')}
										component={renderDropdown}
										validate={[required, nonZero]}
										renderedOptions={
											<>
												<option value={t('critical')}>{t('critical-complete-loss-of-service')}</option>
												<option value={t('high')}>{t('high-partial-loss-of-service')}</option>
												<option value={t('medium')}>{t('medium-minor-loss-of-service')}</option>
												<option value={t('low')}>{t('low-no-loss-of-service')}</option>
											</>
										}
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col md={6}>
									<Field
										name="department"
										label={t('department-0')}
										validate={[required, nonZero]}
										onChange={(e) => {
											setDepartment(e.target.value);
										}}
										component={renderDropdown}
										renderedOptions={props.initials.categories.map((item) => {
											return (
												<option key={item.department_name} value={item.department_name}>
													{item.department_name}
												</option>
											);
										})}
									/>
								</Col>
								<Col md={6}>
									<Field
										name="category"
										label={t('category')}
										validate={[required, nonZero]}
										component={renderDropdown}
										renderedOptions={props.initials.categories.map((department) => {
											if (department.department_name === departmentSelected) {
												return department.ticket_categories.map((item) => {
													return (
														<option key={item.category} value={item.category}>
															{item.category}
														</option>
													);
												});
											}
										})}
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col md={12}>
									<Field
										name="issue"
										validate={[required, maxLength128]}
										label={t('please-describe-your-issue')}
										component={renderTextArea}
										rows="4"
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col md={12}>
									<Field
										name="error_message"
										label={t('copy-and-paste-the-error-message-if-exists')}
										validate={[maxLength128]}
										component={renderTextArea}
										rows="4"
									/>
								</Col>
							</Row>
							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<SubmitButton invalid={invalid} onSubmit={onSubmit} text={t('create-ticket')} />
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</form>
	);
};

export default compose(connect(null, {}), reduxForm({ form: 'ticket' }))(TicketForm);
