import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { loginRequest } from '../auth/authConfig';
import { Routes } from '../routes';

// components
import Footer from '../components/Footer';
import Header from '../components/Header';
import Preloader from '../components/Preloader';
import Sidebar from '../components/Sidebar';

// ---------PAGES---------

// PROCUREMENT
import ProcurementOverview from './screens/Insights/ProcurementOverivew';

import ISRChecklist from './screens/Procurement/ISR/ISRChecklist';
import ISRCreate from './screens/Procurement/ISR/ISRCreate';
import ISRDetails from './screens/Procurement/ISR/ISRDetails';
import ISREdit from './screens/Procurement/ISR/ISREdit';
import ISRIndex from './screens/Procurement/ISR/ISRIndex';

import QuotationCreate from './screens/Procurement/Quotation/QuotationCreate';
import QuotationEdit from './screens/Procurement/Quotation/QuotationEdit';
import QuotationIndex from './screens/Procurement/Quotation/QuotationIndex';

import SuppliersCreate from './screens/Procurement/Suppliers/SuppliersCreate';
import SuppliersDetails from './screens/Procurement/Suppliers/SuppliersDetails';
import SuppliersEdit from './screens/Procurement/Suppliers/SuppliersEdit';
import SuppliersIndex from './screens/Procurement/Suppliers/SuppliersIndex';

import SupplierEvaluationCreate from './screens/Procurement/Suppliers/SupplierEvaluation/SupplierEvaluationCreate';
import SupplierEvaluationEdit from './screens/Procurement/Suppliers/SupplierEvaluation/SupplierEvaluationEdit';
import SupplierEvaluationIndex from './screens/Procurement/Suppliers/SupplierEvaluation/SupplierEvaluationIndex';

import LPOCreate from './screens/Procurement/LPO/LPOCreate';
import LPODetails from './screens/Procurement/LPO/LPODetails';
import LPOEdit from './screens/Procurement/LPO/LPOEdit';
import LPOIndex from './screens/Procurement/LPO/LPOIndex';

import ProcurementItemCreate from './screens/Procurement/Products/ProcurementItemCreate';
import ProcurementItemIndex from './screens/Procurement/Products/ProcurementItemIndex';

// FINANCE

import FinanceOverview from './screens/Insights/FinanceOverview';

import ProjectCreate from './screens/Finance/Projects/ProjectCreate';
import ProjectDetails from './screens/Finance/Projects/ProjectDetails';
import ProjectEdit from './screens/Finance/Projects/ProjectEdit';
import ProjectsIndex from './screens/Finance/Projects/ProjectIndex';
import ProjectMilestoneCreate from './screens/Finance/Projects/ProjectMilestoneCreate';
import ProjectMilestoneEdit from './screens/Finance/Projects/ProjectMilestoneEdit';

// HUMAN RESOURCES

import EmployeeCreate from './screens/HumanResources/Employee/EmployeeCreate';
import EmployeeEdit from './screens/HumanResources/Employee/EmployeeEdit';
import EmployeeIndex from './screens/HumanResources/Employee/EmployeeIndex';
import EmployeeEvaluationCreate from './screens/HumanResources/Evaluation/EmployeeEvaluationCreate';
import EmployeeEvaluationEdit from './screens/HumanResources/Evaluation/EmployeeEvaluationEdit';
import EmployeeEvaluationIndex from './screens/HumanResources/Evaluation/EmployeeEvaluationIndex';
import HumanResourcesReport from './screens/HumanResources/HumanResourcesReports';
import HumanResourcesOverview from './screens/Insights/HumanResourcesOverview';

import SalaryDeductionCreate from './screens/HumanResources/SalaryDeduction/SalaryDeductionCreate';
import SalaryDeductionEdit from './screens/HumanResources/SalaryDeduction/SalaryDeductionEdit';
import SalaryDeductionIndex from './screens/HumanResources/SalaryDeduction/SalaryDeductionIndex';

import SalaryIncrementCreate from './screens/HumanResources/SalaryIncrement/SalaryIncrementCreate';
import SalaryIncrementEdit from './screens/HumanResources/SalaryIncrement/SalaryIncrementEdit';
import SalaryIncrementIndex from './screens/HumanResources/SalaryIncrement/SalaryIncrementIndex';

import StartWorkCreate from './screens/HumanResources/StartWork/StartWorkCreate';
import StartWorkEdit from './screens/HumanResources/StartWork/StartWorkEdit';
import StartWorkIndex from './screens/HumanResources/StartWork/StartWorkIndex';

import WarningLetterCreate from './screens/HumanResources/WarningLetter/WarningLetterCreate';
import WarningLetterEdit from './screens/HumanResources/WarningLetter/WarningLetterEdit';
import WarningLetterIndex from './screens/HumanResources/WarningLetter/WarningLetterIndex';

import ClearanceCreate from './screens/HumanResources/Clearance/ClearanceCreate';
import ClearanceEdit from './screens/HumanResources/Clearance/ClearanceEdit';
import ClearanceIndex from './screens/HumanResources/Clearance/ClearanceIndex';

import LeaveCreate from './screens/HumanResources/LeaveRequest/LeaveCreate';
import LeaveEdit from './screens/HumanResources/LeaveRequest/LeaveEdit';
import LeaveIndex from './screens/HumanResources/LeaveRequest/LeaveIndex';

import CashAdvanceRequestCreate from './screens/HumanResources/CashAdvanceRequest/CashAdvanceRequestCreate';
import CashAdvanceRequestEdit from './screens/HumanResources/CashAdvanceRequest/CashAdvanceRequestEdit';
import CashAdvanceRequestIndex from './screens/HumanResources/CashAdvanceRequest/CashAdvanceRequestIndex';

import TerminationCreate from './screens/HumanResources/Termination/TerminationCreate';
import TerminationEdit from './screens/HumanResources/Termination/TerminationEdit';
import TerminationIndex from './screens/HumanResources/Termination/TerminationIndex';

import ResignationCreate from './screens/HumanResources/Resignation/ResignationCreate';
import ResignationEdit from './screens/HumanResources/Resignation/ResignationEdit';
import ResignationIndex from './screens/HumanResources/Resignation/ResignationIndex';

// PROJCT MANAGEMENT

// TRANSPORTATION

import PunchListOverview from './screens/Insights/PunchListOverview';
import TransportationOverview from './screens/Insights/TransportationOverview';

// ADMINISTRATION
import EntitiesCreate from './screens/administration/Entity/EntitiesCreate';
import EntitiesEdit from './screens/administration/Entity/EntitiesEdit';
import EntityIndex from './screens/administration/Entity/EntityIndex';

import UserEdit from './screens/administration/User/UserEdit';
import UserIndex from './screens/administration/User/UserIndex';

import InviteEdit from './screens/administration/InviteUser/InviteEdit';
import InviteIndex from './screens/administration/InviteUser/InviteIndex';
import InvitesCreate from './screens/administration/InviteUser/InvitesCreate';

import DepartmentCreate from './screens/administration/Department/DepartmentCreate';
import DepartmentEdit from './screens/administration/Department/DepartmentEdit';
import DepartmentIndex from './screens/administration/Department/DepartmentIndex';

// DELIVERY

import Delivery from './screens/Delivery/Delivery';
import DeliveryCheckout from './screens/Delivery/DeliveryCheckout';
import CategoryCreate from './screens/Delivery/DeliveryPanel/Category/CategoryCreate';
import CategoryEdit from './screens/Delivery/DeliveryPanel/Category/CategoryEdit';
import CategoryIndex from './screens/Delivery/DeliveryPanel/Category/CategoryIndex';
import ItemCreate from './screens/Delivery/DeliveryPanel/Item/ItemCreate';
import ItemEdit from './screens/Delivery/DeliveryPanel/Item/ItemEdit';
import ItemIndex from './screens/Delivery/DeliveryPanel/Item/ItemIndex';
import MenuIndex from './screens/Delivery/DeliveryPanel/Menu/MenuIndex';
import OrderIndex from './screens/Delivery/Order/OrderIndex';

// AUTHORIZATION

import Registration from './screens/Auth/Registration/Registration';
import Signin from './screens/Auth/Signin';
import Unauthorized from './screens/Auth/Unauthorized';
import UnInvited from './screens/Auth/UnInvited';

//PROFILE
import EditProfile from './screens/Profile/EditProfile';
import ProfileIndex from './screens/Profile/ProfileIndex';

//Ticket
import TicketCreate from './screens/Ticket/TicketCreate';
import TicketDetail from './screens/Ticket/TicketDetail';
import TicketIndex from './screens/Ticket/TicketIndex';
import TicketReview from './screens/Ticket/TicketReview';

// REPORTS

import Report from './screens/Reports/Report';

//WORKFLOW

import WorkflowCreate from './screens/Workflow/WorkflowCreate';
import WorkflowEdit from './screens/Workflow/WorkflowEdit';
import WorkflowIndex from './screens/Workflow/WorkflowIndex';

//CASHFLOW

import CashFlowCreate from './screens/CashFlow/CashFlowCreate';
import CashFlowDetails from './screens/CashFlow/CashFlowDetails';
import CashFlowEdit from './screens/CashFlow/CashFlowEdit';
import CashFlowIndex from './screens/CashFlow/CashFlowIndex';

//PUNCHLIST

import PunchListCreate from './screens/Construction/PunchList/PunchListCreate';
import PunchListDetails from './screens/Construction/PunchList/PunchListDetail';
import PunchListEdit from './screens/Construction/PunchList/PunchListEdit';
import PunchListIndex from './screens/Construction/PunchList/PunchListIndex';

//Locations
import LocationsCreate from './screens/Construction/Location/LocationsCreate';
import LocationsEdit from './screens/Construction/Location/LocationsEdit';
import LocationsIndex from './screens/Construction/Location/LocationsIndex';

//Disciplines
import DisciplineIndex from './screens/Construction/Discipline/DisciplineIndex';
import DisciplineCreate from './screens/Construction/Discipline/DisciplineCreate';
import DisciplineEdit from './screens/Construction/Discipline/DisciplineEdit';

// Construction-Projects

import ConstructionProjectCreate from './screens/Construction/Project/ProjectCreate';
import ConstructionProjectDetails from './screens/Construction/Project/ProjectDetails';
import ConstructionProjectEdit from './screens/Construction/Project/ProjectEdit';
import ConstructionProjectIndex from './screens/Construction/Project/ProjectIndex';
import ConstructionProjectBuildingCreate from './screens/Construction/Project/ProjectBuildingCreate';
import ConstructionProjectBuildingEdit from './screens/Construction/Project/ProjectBuildingEdit';

// OTHERS
import NotFound from './screens/Auth/NotFound';
import Changelog from './screens/Changelog';
import DashboardOverview from './screens/DashboardOverview';
import UnderConstruction from './screens/Errors/UnderConstruction';
import Notification from './screens/Notification';
import Welcome from './screens/Welcome';
import Settings from './Settings';

// functions
import { connect } from 'react-redux';
import { getEntities } from '../actions/Admin/entityActions';
import { getPolicy } from '../actions/Admin/policyActions';
import { signIn } from '../actions/Auth/authActions';
import { callMsGraph } from '../auth/graph';
import authorizationCheckFunction from '../helpers/authorizationCheckFunction';
import history from '../history';

import { ToastContainer } from 'react-toastify';
import TicketCategoryCreate from './screens/administration/TicketCategory/TicketCategoryCreate';
import TicketCategoryEdit from './screens/administration/TicketCategory/TicketCategoryEdit';
import TicketCategoryIndex from './screens/administration/TicketCategory/TicketCategoryIndex';
import LocationCreate from './screens/Delivery/DeliveryPanel/Location/LocationCreate';
import LocationEdit from './screens/Delivery/DeliveryPanel/Location/LocationEdit';
import LocationIndex from './screens/Delivery/DeliveryPanel/Location/LocationIndex';
import OrderDetail from './screens/Delivery/Order/OrderDetail';
import Insights from './screens/Insights/Insights';

import ProjectOverview from './screens/Insights/ProjectOverview';
import ProcurementItemEdit from './screens/Procurement/Products/ProcurementItemEdit';
import Support from './screens/Support';

import { useTranslation } from 'react-i18next';

const RouteWithLoader = ({ component: Component, ...rest }) => {
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => setLoaded(true), 1000);
		return () => clearTimeout(timer);
	}, []);

	return (
		<Route
			{...rest}
			render={(props) => (
				<>
					<Preloader show={loaded ? false : true} /> <Component {...props} />{' '}
				</>
			)}
		/>
	);
};

const RouteWithSidebar = ({ component: Component, userPolicyObj, pagePolicy, ...rest }) => {
	const localStorageIsSettingsVisible = () => {
		// return false;
		return localStorage.getItem('settingsVisible') === 'false' ? false : true;
	};

	const { userPolicy, userPolicyLoading } = userPolicyObj;

	const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

	const toggleSettings = () => {
		setShowSettings(!showSettings);
		localStorage.setItem('settingsVisible', !showSettings);
	};

	if (!userPolicyLoading) {
		if (pagePolicy != null) {
			// PAGES THAT HAVE AUTHORIZATION
			var isAllowed = authorizationCheckFunction(pagePolicy, userPolicy);
			if (isAllowed) {
				// USER CAN ACCESS TO PAGE
				return (
					<Route
						{...rest}
						render={(props) => (
							<>
								<Preloader show={userPolicyLoading} />
								<Sidebar />

								<main className="content d-flex flex-column min-vh-100">
									<Header />
									<Component {...props} />
									<Footer toggleSettings={toggleSettings} showSettings={showSettings} />
									<ToastContainer />
								</main>
							</>
						)}
					/>
				);
			} else {
				// USER CAN NOT ACCESS TO PAGE
				return <Redirect to={Routes.Unauthorized.path} />;
			}
		} else {
			// PAGES THAT HAVE NOT AUTHORIZATION
			return (
				<Route
					{...rest}
					render={(props) => (
						<>
							<Preloader show={userPolicyLoading} />
							<Sidebar />

							<main className="content d-flex flex-column min-vh-100">
								<Header />
								<Component {...props} />
								<Footer toggleSettings={toggleSettings} showSettings={showSettings} />
								<ToastContainer />
							</main>
						</>
					)}
				/>
			);
		}
	} else {
		// LOADER WHILE GETTING POLICY
		return <Preloader show={userPolicyLoading} />;
	}
};

const HomePage = (props) => {
	const { token, initialLogin, userPolicyObj, companyFilter } = props.auth;

	const { i18n } = useTranslation();

	document.body.dir = i18n.dir();

	const { inProgress, instance, accounts } = useMsal();
	const isAuthenticated = useIsAuthenticated();

	if (
		!(history.location.pathname == '/' || history.location.pathname == '/sign-in') &&
		history.location.pathname?.includes('redirect')
	) {
		localStorage.setItem('initalLocation', history.location.pathname);
	}

	if (!isAuthenticated && inProgress === InteractionStatus.None) {
		history.push(Routes.Signin.path);
	}

	useEffect(() => {
		if (isAuthenticated) {
			props.signIn(instance, callMsGraph, accounts, loginRequest);
			// props.getEntities();
		}
	}, [isAuthenticated, initialLogin]);

	const redirectLocation = localStorage.getItem('initalLocation');

	if (isAuthenticated && localStorage.getItem('initalLocation')?.includes('redirect')) {
		history.push(redirectLocation.split('=')[1].toString());
		localStorage.setItem('initalLocation', null);
	}

	useEffect(() => {
		if (token) {
			props.getEntities();
		}
	}, [token]);

	const isUnderConstruction = process.env.REACT_APP_IS_UNDER_CONSTRUCTION?.toLowerCase() === 'true' ? true : false;
	if (isUnderConstruction) {
		return <UnderConstruction />;
	} else {
		return (
			<Router history={history}>
				<Switch>
					<RouteWithLoader exact path={Routes.Signin.path} component={Signin} />

					{/* ------------WELCOME & DASHBOARD------------ */}

					<RouteWithSidebar
						exact
						path={Routes.DashboardOverview.path}
						component={DashboardOverview}
						userPolicyObj={userPolicyObj}
					/>

					<RouteWithSidebar
						exact
						path={Routes.Overview.path}
						component={DashboardOverview}
						userPolicyObj={userPolicyObj}
					/>

					<RouteWithSidebar exact path={Routes.Welcome.path} component={Welcome} userPolicyObj={userPolicyObj} />

					<RouteWithSidebar
						exact
						path={Routes.Workflow.Index.path}
						component={WorkflowIndex}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Workflow.Create.path}
						component={WorkflowCreate}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Workflow.Edit.path}
						component={WorkflowEdit}
						userPolicyObj={userPolicyObj}
					/>

					{/**************CASHFLOW***************/}

					<RouteWithSidebar
						exact
						path={Routes.CashFlow.Index.path}
						component={CashFlowIndex}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.CashFlow.Create.path}
						component={CashFlowCreate}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.CashFlow.Edit.path}
						component={CashFlowEdit}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.CashFlow.Details.path}
						component={CashFlowDetails}
						userPolicyObj={userPolicyObj}
					/>
					{/**************PunchList***************/}

					<RouteWithSidebar
						exact
						path={Routes.Construction.PunchList.Index.path}
						component={PunchListIndex}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Construction.PunchList.Create.path}
						component={PunchListCreate}
						userPolicyObj={userPolicyObj}
					/>

					<RouteWithSidebar
						exact
						path={Routes.Construction.PunchList.Edit.path}
						component={PunchListEdit}
						userPolicyObj={userPolicyObj}
					/>

					<RouteWithSidebar
						exact
						path={Routes.Construction.PunchList.Details.path}
						component={PunchListDetails}
						userPolicyObj={userPolicyObj}
					/>
					{/**************Locations***************/}

					<RouteWithSidebar
						exact
						path={Routes.Construction.Locations.Index.path}
						component={LocationsIndex}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Construction.Locations.Create.path}
						component={LocationsCreate}
						userPolicyObj={userPolicyObj}
					/>

					<RouteWithSidebar
						exact
						path={Routes.Construction.Locations.Edit.path}
						component={LocationsEdit}
						userPolicyObj={userPolicyObj}
					/>

					<RouteWithSidebar
						exact
						path={Routes.Construction.Discipline.Index.path}
						component={DisciplineIndex}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Construction.Discipline.Create.path}
						component={DisciplineCreate}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Construction.Discipline.Edit.path}
						component={DisciplineEdit}
						userPolicyObj={userPolicyObj}
					/>
					{/**************Construction Project***************/}
					<RouteWithSidebar
						exact
						path={Routes.Construction.Project.Index.path}
						component={ConstructionProjectIndex}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Construction.Project.Create.path}
						component={ConstructionProjectCreate}
						userPolicyObj={userPolicyObj}
					/>

					<RouteWithSidebar
						exact
						path={Routes.Construction.Project.Edit.path}
						component={ConstructionProjectEdit}
						userPolicyObj={userPolicyObj}
					/>

					<RouteWithSidebar
						exact
						path={Routes.Construction.Project.Details.path}
						component={ConstructionProjectDetails}
						userPolicyObj={userPolicyObj}
					/>

					<RouteWithSidebar
						exact
						path={Routes.Construction.ProjectBuilding.Edit.path}
						component={ConstructionProjectBuildingEdit}
						userPolicyObj={userPolicyObj}
					/>

					<RouteWithSidebar
						exact
						path={Routes.Construction.ProjectBuilding.Create.path}
						component={ConstructionProjectBuildingCreate}
						userPolicyObj={userPolicyObj}
					/>

					{/* ------------REPORT------------ */}

					<RouteWithSidebar exact path={Routes.Report.path} component={Report} userPolicyObj={userPolicyObj} />

					{/* ------------TRANSPORTATION------------ */}

					<RouteWithSidebar
						exact
						path={Routes.Insights.TransportationOverview.path}
						component={TransportationOverview}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.Insights.TransportationOverview.policy}
					/>

					<RouteWithSidebar
						exact
						path={Routes.Insights.PunchListOverview.path}
						component={PunchListOverview}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.Insights.PunchListOverview.policy}
					/>

					{/* ------------PROCUREMENT------------ */}

					{/* ITEMS */}
					<RouteWithSidebar
						exact
						path={Routes.Procurement.Item.Index.path}
						component={ProcurementItemIndex}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.Insights.ProcurementOverview.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Procurement.Item.Create.path}
						component={ProcurementItemCreate}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.Insights.ProcurementOverview.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Procurement.Item.Edit.path}
						component={ProcurementItemEdit}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.Insights.ProcurementOverview.policy}
					/>

					{/* LPO */}

					<RouteWithSidebar
						exact
						path={Routes.Procurement.LPO.Index.path}
						component={LPOIndex}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.Procurement.LPO.Index.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Procurement.LPO.Create.path}
						component={LPOCreate}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.Procurement.LPO.Create.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Procurement.LPO.Edit.path}
						component={LPOEdit}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.Procurement.LPO.Edit.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Procurement.LPO.Detail.path}
						component={LPODetails}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.Procurement.LPO.Detail.policy}
					/>

					{/* LPO */}

					<RouteWithSidebar
						exact
						path={Routes.Procurement.Quotation.Index.path}
						component={QuotationIndex}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Procurement.Quotation.Create.path}
						component={QuotationCreate}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Procurement.Quotation.Edit.path}
						component={QuotationEdit}
						userPolicyObj={userPolicyObj}
					/>
					{/* <RouteWithSidebar
						exact
						path={Routes.Procurement.Quotation.Detail.path}
						component={LPODetails}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.Procurement.LPO.Detail.policy}
					/> */}

					{/* ISR */}
					<RouteWithSidebar
						exact
						path={Routes.Procurement.ISR.Checklist.path}
						component={ISRChecklist}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.Procurement.ISR.Checklist.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Procurement.ISR.Index.path}
						component={ISRIndex}
						pagePolicy={Routes.Procurement.ISR.Index.policy}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Procurement.ISR.Create.path}
						component={ISRCreate}
						pagePolicy={Routes.Procurement.ISR.Create.policy}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Procurement.ISR.Detail.path}
						component={ISRDetails}
						pagePolicy={Routes.Procurement.ISR.Detail.policy}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Procurement.ISR.Edit.path}
						component={ISREdit}
						pagePolicy={Routes.Procurement.ISR.Edit.policy}
						userPolicyObj={userPolicyObj}
					/>

					{/* Suppliers */}
					<RouteWithSidebar
						exact
						path={Routes.Procurement.Supplier.Index.path}
						component={SuppliersIndex}
						pagePolicy={Routes.Procurement.Supplier.Index.policy}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Procurement.Supplier.Create.path}
						component={SuppliersCreate}
						pagePolicy={Routes.Procurement.Supplier.Create.policy}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Procurement.Supplier.Detail.path}
						component={SuppliersDetails}
						pagePolicy={Routes.Procurement.Supplier.Detail.policy}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Procurement.Supplier.Edit.path}
						component={SuppliersEdit}
						pagePolicy={Routes.Procurement.Supplier.Edit.policy}
						userPolicyObj={userPolicyObj}
					/>

					{/* SUPPLIER EVALUATION */}
					<RouteWithSidebar
						exact
						path={Routes.Procurement.SupplierEvaluation.Create.path}
						component={SupplierEvaluationCreate}
						pagePolicy={Routes.Procurement.SupplierEvaluation.Create.policy}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Procurement.SupplierEvaluation.Index.path}
						component={SupplierEvaluationIndex}
						pagePolicy={Routes.Procurement.SupplierEvaluation.Index.policy}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Procurement.SupplierEvaluation.Edit.path}
						component={SupplierEvaluationEdit}
						pagePolicy={Routes.Procurement.SupplierEvaluation.Edit.policy}
						userPolicyObj={userPolicyObj}
					/>

					{/* ------------FINANCE------------ */}

					<RouteWithSidebar
						exact
						path={Routes.Insights.FinanceOverview.path}
						component={FinanceOverview}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.Insights.FinanceOverview.policy}
					/>

					{/* Projects */}

					<RouteWithSidebar
						exact
						path={Routes.Finance.Project.Index.path}
						component={ProjectsIndex}
						pagePolicy={Routes.Finance.Project.Index.policy}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Finance.Project.Edit.path}
						component={ProjectEdit}
						pagePolicy={Routes.Finance.Project.Edit.policy}
						userPolicyObj={userPolicyObj}
					/>

					<RouteWithSidebar
						exact
						path={Routes.Finance.Project.Create.path}
						component={ProjectCreate}
						pagePolicy={Routes.Finance.Project.Create.policy}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Finance.Project.Details.path}
						component={ProjectDetails}
						pagePolicy={Routes.Finance.Project.Details.policy}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Finance.ProjectMilestone.Create.path}
						component={ProjectMilestoneCreate}
						pagePolicy={Routes.Finance.ProjectMilestone.Create.policy}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Finance.ProjectMilestone.Edit.path}
						component={ProjectMilestoneEdit}
						pagePolicy={Routes.Finance.ProjectMilestone.Edit.policy}
						userPolicyObj={userPolicyObj}
					/>
					{/* END PROJECTS*/}

					{/* ------------HUMAN RESOURCES------------ */}

					<RouteWithSidebar
						exact
						path={Routes.HumanResources.Employee.Index.path}
						component={EmployeeIndex}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.HumanResources.Employee.Index.policy}
					/>

					<RouteWithSidebar
						exact
						path={Routes.HumanResources.Employee.Edit.path}
						component={EmployeeEdit}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.HumanResources.Employee.Edit.policy}
					/>

					<RouteWithSidebar
						exact
						path={Routes.HumanResources.Employee.Create.path}
						component={EmployeeCreate}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.HumanResources.Employee.Create.policy}
					/>

					<RouteWithSidebar
						exact
						path={Routes.Insights.HumanResourcesOverview.path}
						component={HumanResourcesOverview}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.Insights.HumanResourcesOverview.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.HumanResources.Report.path}
						component={HumanResourcesReport}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.HumanResources.Report.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.HumanResources.EmployeeEvaluation.Index.path}
						component={EmployeeEvaluationIndex}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.HumanResources.EmployeeEvaluation.Index.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.HumanResources.EmployeeEvaluation.Create.path}
						component={EmployeeEvaluationCreate}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.HumanResources.EmployeeEvaluation.Create.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.HumanResources.EmployeeEvaluation.Edit.path}
						component={EmployeeEvaluationEdit}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.HumanResources.EmployeeEvaluation.Edit.policy}
					/>

					<RouteWithSidebar
						exact
						path={Routes.HumanResources.SalaryDeduction.Index.path}
						component={SalaryDeductionIndex}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.SalaryDeduction.Index.policy}
					/>

					<RouteWithSidebar
						exact
						path={Routes.HumanResources.SalaryDeduction.Create.path}
						component={SalaryDeductionCreate}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.SalaryDeduction.Create.policy}
					/>

					<RouteWithSidebar
						exact
						path={Routes.HumanResources.SalaryDeduction.Edit.path}
						component={SalaryDeductionEdit}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.SalaryDeduction.Edit.policy}
					/>

					<RouteWithSidebar
						exact
						path={Routes.HumanResources.SalaryIncrement.Index.path}
						component={SalaryIncrementIndex}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.SalaryIncrement.Index.policy}
					/>

					<RouteWithSidebar
						exact
						path={Routes.HumanResources.SalaryIncrement.Create.path}
						component={SalaryIncrementCreate}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.SalaryIncrement.Create.policy}
					/>

					<RouteWithSidebar
						exact
						path={Routes.HumanResources.SalaryIncrement.Edit.path}
						component={SalaryIncrementEdit}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.SalaryIncrement.Edit.policy}
					/>

					<RouteWithSidebar
						exact
						path={Routes.HumanResources.StartWork.Index.path}
						component={StartWorkIndex}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.StartWork.Index.policy}
					/>

					<RouteWithSidebar
						exact
						path={Routes.HumanResources.StartWork.Create.path}
						component={StartWorkCreate}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.StartWork.Create.policy}
					/>

					<RouteWithSidebar
						exact
						path={Routes.HumanResources.StartWork.Edit.path}
						component={StartWorkEdit}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.StartWork.Edit.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.HumanResources.WarningLetter.Edit.path}
						component={WarningLetterEdit}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.StartWork.Edit.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.HumanResources.WarningLetter.Index.path}
						component={WarningLetterIndex}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.StartWork.Edit.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.HumanResources.WarningLetter.Create.path}
						component={WarningLetterCreate}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.StartWork.Edit.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.HumanResources.Clearance.Edit.path}
						component={ClearanceEdit}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.StartWork.Edit.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.HumanResources.Clearance.Index.path}
						component={ClearanceIndex}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.StartWork.Edit.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.HumanResources.Clearance.Create.path}
						component={ClearanceCreate}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.StartWork.Edit.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.HumanResources.Leave.Edit.path}
						component={LeaveEdit}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.StartWork.Edit.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.HumanResources.Leave.Index.path}
						component={LeaveIndex}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.StartWork.Edit.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.HumanResources.Leave.Create.path}
						component={LeaveCreate}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.StartWork.Edit.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.HumanResources.CashAdvanceRequest.Edit.path}
						component={CashAdvanceRequestEdit}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.StartWork.Edit.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.HumanResources.CashAdvanceRequest.Index.path}
						component={CashAdvanceRequestIndex}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.StartWork.Edit.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.HumanResources.CashAdvanceRequest.Create.path}
						component={CashAdvanceRequestCreate}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.StartWork.Edit.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.HumanResources.Termination.Index.path}
						component={TerminationIndex}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.StartWork.Edit.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.HumanResources.Termination.Create.path}
						component={TerminationCreate}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.StartWork.Edit.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.HumanResources.Termination.Edit.path}
						component={TerminationEdit}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.StartWork.Edit.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.HumanResources.Resignation.Index.path}
						component={ResignationIndex}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.StartWork.Edit.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.HumanResources.Resignation.Create.path}
						component={ResignationCreate}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.StartWork.Edit.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.HumanResources.Resignation.Edit.path}
						component={ResignationEdit}
						userPolicyObj={userPolicyObj}
						// pagePolicy={Routes.HumanResources.StartWork.Edit.policy}
					/>

					{/* ------------PROJECT MANAGEMENT------------ */}

					<RouteWithSidebar
						exact
						path={Routes.Insights.ProjectManagementOverview.path}
						component={ProjectOverview}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.Insights.ProjectManagementOverview.policy}
					/>

					{/* ------------ADMINISTRATION------------ */}

					{/* Administration */}
					<RouteWithSidebar
						exact
						path={Routes.Admin.TicketCategory.Index.path}
						component={TicketCategoryIndex}
						pagePolicy={Routes.Admin.TicketCategory.Index.policy}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Admin.TicketCategory.Create.path}
						component={TicketCategoryCreate}
						pagePolicy={Routes.Admin.TicketCategory.Create.policy}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Admin.TicketCategory.Edit.path}
						component={TicketCategoryEdit}
						pagePolicy={Routes.Admin.TicketCategory.Edit.policy}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Admin.Department.Index.path}
						component={DepartmentIndex}
						pagePolicy={Routes.Admin.Department.Index.policy}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Admin.Department.Create.path}
						component={DepartmentCreate}
						pagePolicy={Routes.Admin.Department.Create.policy}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Admin.Department.Edit.path}
						component={DepartmentEdit}
						pagePolicy={Routes.Admin.Department.Edit.policy}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Admin.User.Index.path}
						component={UserIndex}
						pagePolicy={Routes.Admin.User.Index.policy}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Admin.User.Edit.path}
						component={UserEdit}
						pagePolicy={Routes.Admin.User.Edit.policy}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Admin.Invite.Create.path}
						component={InvitesCreate}
						pagePolicy={Routes.Admin.Invite.Create.policy}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Admin.Invite.Index.path}
						component={InviteIndex}
						pagePolicy={Routes.Admin.Invite.Index.policy}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Admin.Invite.Edit.path}
						component={InviteEdit}
						pagePolicy={Routes.Admin.Invite.Edit.policy}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Admin.Entity.Index.path}
						component={EntityIndex}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.Admin.Entity.Index.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Admin.Entity.Edit.path}
						component={EntitiesEdit}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.Admin.Entity.Edit.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Admin.Entity.Create.path}
						component={EntitiesCreate}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.Admin.Entity.Create.policy}
					/>

					{/* DELIVERY */}

					<RouteWithSidebar
						exact
						path={Routes.Delivery.Index.path}
						component={Delivery}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Delivery.Checkout.path}
						component={DeliveryCheckout}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Delivery.Panel.Item.ItemIndex.path}
						component={ItemIndex}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Delivery.Panel.Item.ItemCreate.path}
						component={ItemCreate}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Delivery.Panel.Item.ItemEdit.path}
						component={ItemEdit}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Delivery.Panel.Menu.MenuIndex.path}
						component={MenuIndex}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Delivery.Panel.Location.Index.path}
						component={LocationIndex}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Delivery.Panel.Location.Create.path}
						component={LocationCreate}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Delivery.Panel.Location.Edit.path}
						component={LocationEdit}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Delivery.Order.Index.path}
						component={OrderIndex}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Delivery.Order.Detail.path}
						component={OrderDetail}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Delivery.Panel.Category.Index.path}
						component={CategoryIndex}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Delivery.Panel.Category.Create.path}
						component={CategoryCreate}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Delivery.Panel.Category.Edit.path}
						component={CategoryEdit}
						userPolicyObj={userPolicyObj}
					/>

					{/* ------------AUTHROIZATION------------ */}

					{/* Registration */}
					<RouteWithLoader
						exact
						path={Routes.Registration.path}
						component={Registration}
						userPolicyObj={userPolicyObj}
					/>
					{/* PROFILE*/}
					<RouteWithSidebar
						exact
						path={Routes.Profile.Index.path}
						component={ProfileIndex}
						userPolicyObj={userPolicyObj}
					/>

					{/* PROFILE EDIT  */}

					<RouteWithSidebar
						exact
						path={Routes.Profile.Edit.path}
						component={EditProfile}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.Notification.path}
						component={Notification}
						userPolicyObj={userPolicyObj}
					/>

					{/* TICKET */}
					<RouteWithSidebar
						exact
						path={Routes.IT_Department.Ticket.Index.path}
						component={TicketIndex}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.IT_Department.Ticket.Index.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.IT_Department.Ticket.Detail.path}
						component={TicketDetail}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.IT_Department.Ticket.Detail.policy}
					/>
					<RouteWithSidebar
						exact
						path={Routes.IT_Department.Ticket.Review.path}
						component={TicketReview}
						userPolicyObj={userPolicyObj}
					/>
					<RouteWithSidebar
						exact
						path={Routes.IT_Department.Ticket.Create.path}
						component={TicketCreate}
						userPolicyObj={userPolicyObj}
					/>
					{/* --------------Support---------------- */}
					<RouteWithSidebar exact path={Routes.Support.path} component={Support} userPolicyObj={userPolicyObj} />

					{/* --------------Insights---------------- */}

					<RouteWithSidebar
						exact
						path={Routes.Insights.Index.path}
						component={Insights}
						userPolicyObj={userPolicyObj}
					/>

					<RouteWithSidebar
						exact
						path={Routes.Insights.ProcurementOverview.path}
						component={ProcurementOverview}
						userPolicyObj={userPolicyObj}
						pagePolicy={Routes.Insights.ProcurementOverview.policy}
					/>

					{/* ------------FAQ & DOCUMENTATION------------ */}

					<RouteWithSidebar exact path={Routes.Changelog.path} component={Changelog} userPolicyObj={userPolicyObj} />

					<RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />

					<RouteWithLoader exact path={Routes.UnInvited.path} component={UnInvited} />

					<RouteWithLoader
						exact
						path={Routes.UnderConstruction.path}
						userPolicyObj={userPolicyObj}
						component={UnderConstruction}
					/>

					<RouteWithSidebar
						exact
						path={Routes.Unauthorized.path}
						component={Unauthorized}
						userPolicyObj={userPolicyObj}
					/>

					<RouteWithSidebar to={Routes.NotFound.path} userPolicyObj={userPolicyObj} component={NotFound} />
				</Switch>
			</Router>
		);
	}
};

const mapStateToProps = (state, ownProps) => {
	const { token, initialLogin, companyFilter, policies, policiesLoading } = state.auth;

	return {
		reduxState: state,
		auth: {
			token,
			initialLogin,
			companyFilter,
			userPolicyObj: { userPolicy: policies, userPolicyLoading: policiesLoading },
		},
	};
};

export default connect(mapStateToProps, { signIn, getEntities, getPolicy })(HomePage);
