import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import departmentReducer from './Admin/departmentReducer';
import entityReducer from './Admin/entityReducer';
import ticketCategoryReducer from './Admin/ticketCategoryReducer';
import userInviteReducer from './Admin/userInviteReducer';
import authReducer from './authReducer';
import cashFlowReducer from './CashFlow/cashFlowReducer';
import disciplineReducer from './Construction/disciplineReducer';
import punchListReducer from './Construction/punchListReducer';
import itemReducer from './Delivery/itemReducer';
import locationReducer from './Delivery/locationReducer';
import orderReducer from './Delivery/orderReducer';
import projectMilestoneReducer from './Finance/projectMilestoneReducer';
import projectReducer from './Finance/projectReducer';
import cashAdvanceRequestReducer from './HumanResources/cashAdvanceRequestReducer';
import clearanceReducer from './HumanResources/clearanceReducer';
import employeeEvaluationReducer from './HumanResources/employeeEvaluationReducer';
import employeeReducer from './HumanResources/employeeReducer';
import leaveReducer from './HumanResources/leaveRequestReducer';
import resignationReducer from './HumanResources/resignationReducer';
import salaryDeductionReducer from './HumanResources/salaryDeductionReducer';
import salaryIncrementReducer from './HumanResources/salaryIncrementReducer';
import startWorkReducer from './HumanResources/startWorkReducer';
import terminationReducer from './HumanResources/terminationReducer';
import warningLetterReducer from './HumanResources/warningLetterReducer';
import ticketReducer from './ITDepartment/ticketReducer';
import overviewReducer from './overviewReducer';
import policyReducer from './policyReducer';
import powerbiReducer from './powerbiReducer';
import isrReducer from './Procurement/isrReducer';
import lpoReducer from './Procurement/lpoReducer';
import procurementItemCategoryReducer from './Procurement/procurementItemCategoryReducer';
import procurementItemReducer from './Procurement/procurementItemReducer';
import quotationReducer from './Procurement/quotationReducer';
import suppliersReducer from './Procurement/suppliersReducer';
import productReducer from './productReducer';
import notificationReducer from './User/notificationReducer';
import userReducer from './User/userReducer';
import workflowReducer from './Workflow/workflowReducer';
import constructionProjectReducer from './Construction/projectReducer';
import projectBuildingReducer from './Construction/projectBuildingReducer';

const procurementReducer = combineReducers({
	isr: isrReducer,
	supplier: suppliersReducer,
	lpo: lpoReducer,
	quotation: quotationReducer,
	item: procurementItemReducer,
	itemCategory: procurementItemCategoryReducer,
});

const adminReducer = combineReducers({
	ticketCategory: ticketCategoryReducer,
	userInvite: userInviteReducer,
	department: departmentReducer,
	entity: entityReducer,
});

const humanResourcesReducer = combineReducers({
	employeeEvaluation: employeeEvaluationReducer,
	salaryDeduction: salaryDeductionReducer,
	employee: employeeReducer,
	startWork: startWorkReducer,
	warningLetter: warningLetterReducer,
	clearance: clearanceReducer,
	cashAdvanceRequest: cashAdvanceRequestReducer,
	leave: leaveReducer,
	salaryIncrement: salaryIncrementReducer,
	resignation: resignationReducer,
	termination: terminationReducer,
});

const financeReducer = combineReducers({
	project: projectReducer,
	projectMilestone: projectMilestoneReducer,
});

const projectManagementReducer = combineReducers({});

const itDepartmentReducer = combineReducers({
	ticket: ticketReducer,
});

const transportationReducer = combineReducers({});

const deliveryReducer = combineReducers({
	order: orderReducer,
	item: itemReducer,
	location: locationReducer,
});

const constructionReducer = combineReducers({
	punchList: punchListReducer,
	locations: locationReducer,
	discipline: disciplineReducer,
	project: constructionProjectReducer,
	projectBuilding: projectBuildingReducer,
});
export default combineReducers({
	form: formReducer,
	procurement: procurementReducer,
	admin: adminReducer,
	humanResources: humanResourcesReducer,
	itDepartment: itDepartmentReducer,
	auth: authReducer,
	user: userReducer,
	overview: overviewReducer,
	policy: policyReducer,
	finance: financeReducer,
	notification: notificationReducer,
	powerbi: powerbiReducer,
	product: productReducer,
	delivery: deliveryReducer,
	workflow: workflowReducer,
	cashFlow: cashFlowReducer,
	construction: constructionReducer,
	punchList: punchListReducer,
});
