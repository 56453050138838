import { faEdit, faEye, faHome, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Nav } from '@themesberg/react-bootstrap';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteLPO, getLPOsWithFilter } from '../../../../actions//Procurement/lpoActions';
import { clearData } from '../../../../actions/globalActions';
import IndexTable from '../../../../components/IndexTable';
import TableFilter from '../../../../components/TableFilter';
import onDelete from '../../../../helpers/deleteFunction';
import { indexTableFilterQueryGenerator } from '../../../../helpers/helpers';
import history from '../../../../history';
import { Routes } from '../../../../routes';

const LPOIndex = (props) => {
	const { lpos, lposLoading, userPolicy, companyFilter } = props;

	const [deleted, setDeleted] = useState(false);
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [query, setQuery] = useState('');
	const [order, setOrder] = useState('');
	const [search, setSearch] = useState('');

	const [filters, setFilters] = useState([]);
	const { t } = useTranslation();

	const initialFilterCategories = [
		{ label: t('company'), displayField: null, value: 'company.company_name', queryName: t('company') },
		{ label: t('project'), displayField: null, value: 'project.project_name', queryName: t('project') },
		{ label: t('status'), displayField: null, value: 'status', queryName: t('status') },
		{ label: t('owner'), displayField: 'user.full_name', value: 'user.user_id', queryName: t('user') },
	];

	const [filterCategories, setFilterCategories] = useState(initialFilterCategories);

	useEffect(() => {
		props.getLPOsWithFilter(offset, limit, query, order, search);

		return () => {
			props.clearData();
		};
	}, [deleted, limit, offset, query, order, search]);

	useEffect(() => {
		indexTableFilterQueryGenerator(filters, setQuery);
	}, [filters.length]);

	const renderLPOs = () => {
		return lpos.map((lpo, index) => {
			if (index != lpos.length - 1) {
				return (
					<tr key={index}>
						<td className="fw-bold text-center">{lpo.reference}</td>
						<td className="fw-bold text-center">{lpo.project.project_name}</td>
						<td className="fw-bold text-center">{lpo.company.company_name}</td>
						<td className="fw-bold text-center">{lpo.user.full_name}</td>
						<td className="fw-bold text-center">{lpo.status}</td>
						<td className="fw-bold text-center">{new Date(lpo.date).toLocaleDateString()}</td>
						<td className="fw-bold text-center">
							{/* {authorizationCheckFunction(Routes.ISRDelete.policy, userPolicy) ? ( */}
							<button
								onClick={() => {
									onDelete(deleted, setDeleted, props.deleteLPO, lpo.lpo_id);
								}}
								className="btn btn-danger mx-2">
								<FontAwesomeIcon icon={faTrash} />
							</button>
							{/* ) : null} */}
							<Link to={Routes.Procurement.LPO.Detail.path.replace(':id', lpo.lpo_id)} className="btn btn-primary mx-2">
								<FontAwesomeIcon icon={faEye} />
							</Link>
							{/* {authorizationCheckFunction(Routes.ISRDelete.policy, userPolicy) ? ( */}
							<Link to={Routes.Procurement.LPO.Edit.path.replace(':id', lpo.lpo_id)} className="btn btn-primary mx-2">
								<FontAwesomeIcon icon={faEdit} />
							</Link>
							{/* ) : null} */}
						</td>
					</tr>
				);
			} else {
				return null;
			}
		});
	};

	// REACT PAGINATION ON CLICK FUNCTION
	const handlePageClick = (event) => {
		setOffset(event.selected);
	};
	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0 ">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{t('local-purchase-order')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<Nav
					className="justify-content-center row mt-3"
					variant="tabs"
					defaultActiveKey={Routes.Procurement.LPO.Index.path}>
					<Nav.Item className="col text-center ">
						<Nav.Link
							onClick={() => {
								history.push(Routes.Procurement.ISR.Index.path);
							}}>
							{t('internal-service-request')}{' '}
						</Nav.Link>
					</Nav.Item>
					<Nav.Item className="col text-center">
						<Nav.Link
							className="bg-primary  text-white"
							onClick={() => {
								history.push(Routes.Procurement.LPO.Index.path);
							}}>
							{t('local-purchase-order')}{' '}
						</Nav.Link>
					</Nav.Item>
				</Nav>
				<br />

				<div
					className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center
">
					<h4 style={{ display: 'inline' }}>{t('local-purchase-orders')}</h4>
					<div>
						{/* <a
							className="btn btn-secondary py-2 px-3 mx-4"
							target="blank"
							href={`${process.env.REACT_APP_SHAREPOINT_BASE_URL}${process.env.REACT_APP_SHAREPOINT_DOCUMENTS}ISR`}>
							<FontAwesomeIcon className="me-2" icon={faFile} /> Documents
						</a> */}
						<Link className="btn btn-primary py-2 px-3  " to={Routes.Procurement.LPO.Create.path}>
							{t('add-new')}{' '}
						</Link>
					</div>
				</div>
				<p className="mb-0">{t('your-most-recent-requests-can-be-seen-here')}</p>
			</div>

			<IndexTable
				loading={lposLoading}
				headerProps="text-center"
				// headers={['Company Name', 'Project Name', 'Checked By', 'Payment Type', 'Payment Days', 'Date', '']}
				headers={[
					{ label: t('reference'), value: 'reference', sortable: true },
					{ label: t('project-name'), value: 'project.project_name', sortable: false },
					{ label: t('company-name'), value: 'company.company_name', sortable: false },
					{ label: t('owner'), value: 'user.full_name', sortable: true },
					{ label: t('status'), value: 'status', sortable: true },
					{ label: t('date-0'), value: 'date', sortable: true },
					{ label: '', value: '', sortable: false },
				]}
				renderRows={lpos.length > 1 ? renderLPOs() : null}
				setOrder={setOrder}
				searchable
				setSearch={setSearch}
				filter={
					<div className="mb-3">
						<TableFilter
							initialFilterCategories={initialFilterCategories}
							filters={filters}
							setFilters={setFilters}
							datas={lpos}
							setQuery={setQuery}
							filterCategories={filterCategories}
							setFilterCategories={setFilterCategories}
						/>
					</div>
				}
				pagination={
					lpos.length > 1 ? (
						<ReactPaginate
							nextLabel=" >"
							onPageChange={handlePageClick}
							pageCount={Math.ceil(_.last(lpos).page_count)}
							pageRangeDisplayed={3}
							previousLabel="< "
							pageClassName="page-item"
							pageLinkClassName="page-link"
							previousClassName="page-item"
							forcePage={offset}
							previousLinkClassName="page-link"
							nextClassName="page-item"
							nextLinkClassName="page-link"
							breakLabel="..."
							breakClassName="page-item"
							breakLinkClassName="page-link"
							containerClassName="pagination"
							activeClassName="active"
							renderOnZeroPageCount={null}
						/>
					) : null
				}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		lpos: state.procurement.lpo.lpos,
		lposLoading: state.procurement.lpo.getLPOsLoading,
		userPolicy: state.auth.policies,
		companyFilter: state.auth.companyFilter,
	};
};

export default connect(mapStateToProps, { getLPOsWithFilter, clearData, deleteLPO })(LPOIndex);
