import {
	CREATE_ITEM_CATEGORY,
	GET_ITEM_CATEGORY,
	GET_ITEM_CATEGORIES,
	DELETE_ITEM_CATEGORY,
	UPDATE_ITEM_CATEGORY,
} from '../../actions/Procurement/types';
import { CLEAR_DATA } from '../../actions/types';
const initialState = {
	itemCategory: {},
	itemCategories: [],
	loading: true,
	getItemCategoryLoading: true,
	getItemCategoriesLoading: true,
};
export default (state = initialState, action) => {
	switch (action.type) {
		case GET_ITEM_CATEGORY:
			return { ...state, itemCategory: action.payload, getItemCategoryLoading: false };
		case GET_ITEM_CATEGORIES:
			return { ...state, itemCategories: action.payload, getItemCategoryLoading: false };
		case CREATE_ITEM_CATEGORY:
			return { ...state, itemCategory: action.payload };
		case CLEAR_DATA:
			return {
				...state,
				itemCategory: action.payload,
				getItemCategoryLoading: true,
				getItemCategoriesLoading: true,
			};
		case DELETE_ITEM_CATEGORY:
			return { ...state };
		default:
			return state;
	}
};
