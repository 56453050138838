import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { createISR } from '../../../../actions//Procurement/isrActions';
import ISRForm from './ISRForm';

import { getUsersByRole } from '../../../../actions//User/userActions';
import { getProjects } from '../../../../actions//Finance/projectActions';
import { clearData } from '../../../../actions/globalActions';
import { getCategories, getItems } from '../../../../actions/productActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';

const ISRCreate = (props) => {
	const { datas, loading } = props;

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			if (formValues.isr_contents.length != 0) {
				formValues.isr_contents.map((content, index) => {
					formValues.isr_contents[index].item_name = content['item'].item_name;
					formValues.isr_contents[index].item_code = content['item'].item_code;
					formValues.isr_contents[index].item_category_name = content['category'].item_category_name;
					formValues.isr_contents[index].item_category_code = content['category'].item_category_code;
				});
			}

			props.createISR(formValues);
		}
	};

	useEffect(() => {
		props.getUsersByRole('Cost Controller');
		props.getProjects();
		props.getCategories();
		props.getItems();

		return () => {
			props.clearData();
		};
	}, []);

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<ISRForm datas={datas} onSubmit={onSubmit} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		user: { getUsersLoading, users },
		finance: {
			project: { getProjectsLoading, projects },
		},
		product: { getCategoriesLoading, getItemsLoading, items, categories },
	} = state;

	return {
		auth: state.auth,
		datas: { projects, categories, items, costControllers: users },
		loading: getUsersLoading || getProjectsLoading || getCategoriesLoading || getItemsLoading,
	};
};

export default connect(mapStateToProps, {
	createISR,
	clearData,
	getUsersByRole,
	getProjects,
	getCategories,
	getItems,
})(ISRCreate);
