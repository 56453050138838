import { Table } from '@themesberg/react-bootstrap';
import { Field } from 'redux-form';
import renderRadioButton_v2 from '../../../../components/formComponents/renderRadioButton_v2';
import React, { useState } from 'react';
import renderMultiselect from '../../../../components/formComponents/renderMultiselect';
import renderCheckbox from './formComponents/renderCheckbox';
import { Policies } from '../../../../helpers/policies';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

export default ({ policyViewChecks, setPolicyViewChecks, formChecks, entities }) => {
	const { t } = useTranslation();
	const renderRows = () => {
		return Object.keys(Policies).map((policyName, index) => {
			return (
				<React.Fragment key={index}>
					<tr>
						<td className="fw-bolder">{policyName.replace('_', ' ')}</td>
						<td>
							<div className="form-check">
								<input
									checked={policyViewChecks[policyName]}
									onChange={() => {
										setPolicyViewChecks({ ...policyViewChecks, [policyName]: !policyViewChecks[policyName] });
									}}
									className="form-check-input"
									type="checkbox"
								/>
								<label className="form-check-label">{t('view')}</label>
							</div>
						</td>
						{policyName == 'ADMIN' ? (
							<td>
								<Field
									val="ADD" // TO DO: INVITE CHECKBOX
									name={`policies.${policyName}.actions.ADD`}
									component={renderCheckbox}
									label={t('add')}
								/>
							</td>
						) : (
							<td>
								<Field
									val="ADD"
									name={`policies.${policyName}.actions.ADD`}
									component={renderCheckbox}
									label={t('add-0')}
								/>
							</td>
						)}
						<td>
							<Field
								val="EDIT"
								name={`policies.${policyName}.actions.EDIT`}
								component={renderCheckbox}
								label={t('edit')}
							/>
						</td>
						<td>
							<Field
								val="DELETE"
								name={`policies.${policyName}.actions.DELETE`}
								component={renderCheckbox}
								label={t('delete')}
							/>
						</td>

						<td></td>
					</tr>
					{policyViewChecks[policyName] ? (
						<tr>
							<td></td>
							<td>
								<Field
									name={`policies.${policyName}.actions.VIEW`}
									component={renderRadioButton_v2}
									options={[{ value: 'VIEW:ALL', title: t('all') }]}
								/>
							</td>
							<td>
								<Field
									name={`policies.${policyName}.actions.VIEW`}
									component={renderRadioButton_v2}
									options={[{ value: 'VIEW:COMPANY', title: t('company') }]}
								/>
							</td>
							{policyName == 'ADMIN' ? (
								<td>
									<Field
										name={`policies.${policyName}.actions.VIEW`}
										component={renderRadioButton_v2}
										options={[{ value: 'VIEW:SELF', title: t('self') }]}
									/>
								</td>
							) : (
								<td>
									<Field
										name={`policies.${policyName}.actions.VIEW`}
										component={renderRadioButton_v2}
										options={[{ value: 'VIEW:SELF', title: t('self-0') }]}
									/>
								</td>
							)}
							{formChecks[policyName + '_VIEW_VALUE'] == 'VIEW:COMPANY' ? (
								<>
									<td>
										<p>
											{t('select')} <br /> {t('company')}
										</p>
									</td>
									<td colSpan={4}>
										<Field
											name={`policies.${policyName}.companies`}
											component={renderMultiselect}
											data={entities}
											textField="company_name"
											dataKey="company_id"
										/>
									</td>
								</>
							) : (
								<>
									<td></td>
									<td></td>
									<td></td>
								</>
							)}
						</tr>
					) : null}
				</React.Fragment>
			);
		});
	};

	return (
		<Table responsive className="table-centered table-nowrap rounded mb-0 " style={{ overflowY: 'unset' }}>
			<thead className="thead-light text-center">
				<tr>
					<th>{t('policy')}</th>
					<th className="text-center" colSpan={8}>
						{t('actions')}{' '}
					</th>
				</tr>
			</thead>
			<tbody>{renderRows()}</tbody>
		</Table>
	);
};
