import React, { useEffect } from 'react';
import EmployeeForm from './EmployeeForm';

import { createEmployee } from '../../../../actions/HumanResources/employeeActions';
import { connect } from 'react-redux';
import { getDepartments } from '../../../../actions/Admin/departmentActions';
import { getEntities } from '../../../../actions/Admin/entityActions';
import { clearData } from '../../../../actions/globalActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';

const EmployeeCreate = (props) => {
	const { datas, loading } = props;

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.createEmployee(formValues);
		}
	};

	useEffect(() => {
		props.getDepartments();
		props.getEntities();

		return () => {
			props.clearData();
		};
	}, []);

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<EmployeeForm datas={datas} onSubmit={onSubmit} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		admin: {
			entity: { entities, getEntitiesLoading },
			department: { departments, getDepartmentsLoading },
		},
	} = state;

	return {
		datas: { companies: entities, departments },
		loading: getEntitiesLoading || getDepartmentsLoading,
	};
};

export default connect(mapStateToProps, { createEmployee, getDepartments, getEntities, clearData })(EmployeeCreate);
