import _ from 'lodash';
import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getTicket, completeTicket, reopenTicket } from '../../../actions//ITDepartment/ticketActions';
import { Button, Card, Col, ListGroup, Row } from '@themesberg/react-bootstrap';
import Timeline from '../../../components/Timeline/Timeline';
import { getUsers } from '../../../actions/User/userActions';

import { clearData } from '../../../actions/globalActions';
import LoadingSpinner from '../../../components/LoadingSpinner';
import ModalComponent from '../../../components/ModalComponent';
import { Field } from 'redux-form';
import renderDropdown from '../../../components/formComponents/renderDropdown';
import renderTextArea from '../../../components/formComponents/renderTextArea';
import { useTranslation } from 'react-i18next';

const TicketDetail = (props) => {
	const ticket_id = props.match.params.id;
	const { t } = useTranslation();

	const { datas, loading } = props;
	const { ticket, users } = datas;

	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		props.getTicket(ticket_id);
		props.getUsers();

		return () => {
			props.clearData();
		};
	}, []);

	const completeTicket = (ticket_id) => {
		props.completeTicket(ticket_id);
	};

	const handleModalSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.reopenTicket(ticket_id, formValues);
			setShowModal(false);
		}
	};

	const handleModalClose = () => setShowModal(false);

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<>
					<ModalComponent
						children={
							<>
								<Row className="mb-3">
									<Col>
										<Field rows={3} name="issue" label={t('message-1')} component={renderTextArea} />
									</Col>
								</Row>
								<div className="d-flex justify-content-end flex-wrap flex-md-nowrap align-items-center ">
									<Button type="submit">{t('submit')}</Button>
								</div>
							</>
						}
						onSubmit={handleModalSubmit}
						show={showModal}
						handleClose={handleModalClose}
						header={t('reopen-ticket')}
					/>
					<Row>
						<Col md={12}>
							<div>
								{loading ? (
									<LoadingSpinner />
								) : (
									<div className="py-4">
										<Card>
											<Card.Header className="border-bottom-0 pb-0">
												<Row>
													<Col>
														<h4>
															{t('ticket-details')}-{' '}
															<span>
																{ticket.title} | {ticket.code}
															</span>
														</h4>
														<h6 className="ps-3 m-0	text-left ">
															<span className="text-muted">{t('by')} </span>
															{ticket.by.full_name}
															<br />

															<span className="text-muted">{t('to')} </span>
															{ticket.assignedUser.full_name}
														</h6>
													</Col>
													<Col>
														<h6 className="m-0	text-right ">
															<span className="text-muted">{t('submission-date')} </span>
															{new Date(ticket.date_created).toLocaleDateString()}{' '}
														</h6>
														{ticket.date_completed ? (
															<h6 className="m-0	text-right ">
																<span className="text-muted">{t('completion-date')} </span>
																{new Date(ticket.date_completed).toLocaleDateString()}{' '}
															</h6>
														) : null}
													</Col>
												</Row>
											</Card.Header>
											<Card.Body>
												<Col>
													<ListGroup>
														<ListGroup.Item className="border-bottom">
															<Row className="align-items-center  justfiy-content-center">
																<Col>
																	<h5 className="d-inline">
																		<span className="text-muted">Title:</span> {ticket.title}
																	</h5>
																</Col>
															</Row>
														</ListGroup.Item>
														<ListGroup.Item className="border-bottom">
															<Row className="align-items-center  justfiy-content-center">
																<Col>
																	<h5 className="d-inline">
																		<span className="text-muted">Severity:</span> {ticket.severity}
																	</h5>
																</Col>
															</Row>
														</ListGroup.Item>
														<ListGroup.Item className="border-bottom">
															<Row className="align-items-center  justfiy-content-center">
																<Col>
																	<h5 className="d-inline">
																		<span className="text-muted">Status:</span> {ticket.status}
																	</h5>
																</Col>
															</Row>
														</ListGroup.Item>
													</ListGroup>
													<ListGroup className="border-bottom">
														<ListGroup.Item>
															<Row>
																<Col>
																	<h6 className="mb-1">{t('department-or-category')}</h6>
																	<small className="text-gray-700">
																		{ticket?.department} - {ticket?.category}
																	</small>
																</Col>
															</Row>
														</ListGroup.Item>
													</ListGroup>
													<ListGroup className="border-bottom">
														<ListGroup.Item>
															<Row>
																<Col>
																	<h6 className="mb-1">{t('request')}</h6>
																	<small className="text-gray-700">
																		{ticket.issue
																			? ticket.issue.split('<br>').map((issue, index) => {
																					return (
																						<React.Fragment key={index}>
																							{issue}
																							<br />
																						</React.Fragment>
																					);
																			  })
																			: '-'}
																	</small>
																</Col>
															</Row>
														</ListGroup.Item>
													</ListGroup>
													<ListGroup className="border-bottom">
														<ListGroup.Item>
															<Row>
																<Col>
																	<h6 className="mb-1">{t('error-message')}</h6>
																	<small className="text-gray-700">{ticket.error_message}</small>
																</Col>
															</Row>
														</ListGroup.Item>
													</ListGroup>
													<ListGroup className="border-bottom">
														<ListGroup.Item>
															<Row>
																<Col>
																	<h6 className="mb-1">{t('notes-from-it-manager')}</h6>
																	<small className="text-gray-700">
																		{ticket.it_manager_notes
																			? ticket.it_manager_notes.split('<br>').map((it_manager_notes, index) => {
																					if (it_manager_notes != '') {
																						return (
																							<React.Fragment key={index}>
																								{it_manager_notes}
																								<br />
																							</React.Fragment>
																						);
																					}
																			  })
																			: '-'}
																	</small>
																</Col>
															</Row>
														</ListGroup.Item>
													</ListGroup>
													<ListGroup className="border-bottom">
														<ListGroup.Item>
															<Row>
																<Col>
																	<h6 className="mb-1">{t('notes-from-engineer')}</h6>
																	<small className="text-gray-700">
																		{ticket.engineer_notes
																			? ticket.engineer_notes.split('<br>').map((engineer_notes, index) => {
																					if (engineer_notes != '') {
																						return (
																							<React.Fragment key={index}>
																								{engineer_notes}
																								<br />
																							</React.Fragment>
																						);
																					}
																			  })
																			: '-'}
																	</small>
																</Col>
															</Row>
														</ListGroup.Item>
													</ListGroup>
												</Col>
												<div className="d-flex justify-content-end flex-wrap flex-md-nowrap align-items-center py-4">
													{ticket.status == t('resolved') ? (
														<>
															<button className="btn btn-primary me-3" onClick={() => setShowModal(true)}>
																{t('reopen')}{' '}
															</button>
															<button className="btn btn-primary" onClick={() => completeTicket(ticket_id)}>
																{t('mark-as-complete')}{' '}
															</button>
														</>
													) : null}
												</div>
											</Card.Body>
										</Card>
									</div>
								)}
							</div>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		itDepartment: {
			ticket: { ticket, getTicketLoading },
		},
		user: { users, getUsersLoading },
	} = state;

	return {
		datas: { ticket, users },
		loading: getTicketLoading || getUsersLoading,
	};
};

export default connect(mapStateToProps, { getTicket, clearData, completeTicket, reopenTicket, getUsers })(TicketDetail);
