import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col } from '@themesberg/react-bootstrap';
import { Routes } from '../../../routes';
import history from '../../../history';
import IndexTable from '../../../components/IndexTable';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getProfile } from '../../../actions//User/userActions';
import { clearData } from '../../../actions/globalActions';
import { getTicketsBelongsToUser } from '../../../actions/ITDepartment/ticketActions';
import { useTranslation } from 'react-i18next';

const publicHolidays = [
	{ holiday: 'national-sports-day', date: '08.02.2022', ofDays: '1' },
	{ holiday: 'eid-al-fitr-0', date: '02.05.2022', ofDays: '3' },
	{ holiday: 'eid-al-adha-0', date: '09.07.2022', ofDays: '4' },
	{ holiday: 'qatar-national-day', date: '18.12.2022', ofDays: '1' },
];

const Profile = (props) => {
	const { user, loading, user_id, user_profile, tickets } = props;
	useEffect(() => {
		props.getProfile(user_id);
		props.getTicketsBelongsToUser(user_id);
		return () => {
			props.clearData();
		};
	}, []);
	console.log('TICKET', tickets);
	console.log('PROFILE', user_profile);
	const { t } = useTranslation();

	if (loading) {
		return (
			<div className="d-flex justify-content-center ">
				<div className="spinner-border " role="status">
					<span className="visually-hidden">Loading...</span>
				</div>
			</div>
		);
	} else {
		return (
			<>
				<div className=" py-4">
					<div className="d-block mb-4 mb-xl-0 ">
						<Breadcrumb
							className=" d-block"
							listProps={{
								className: 'breadcrumb-dark breadcrumb-transparent',
							}}>
							<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
								<FontAwesomeIcon icon={faHome} />
							</Breadcrumb.Item>
							<Breadcrumb.Item active>{t('profile')}</Breadcrumb.Item>
						</Breadcrumb>
					</div>

					{/* <div
						className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-5
  ">
						<h4 style={{ display: 'inline' }}>Profile</h4>
					</div>
					<p className="mb-0">Your tickets, profile detail and public holidays can be seen here.</p> */}
				</div>
				<Row className="mt-3">
					<Col xl={6} lg={12} md={12} sm={12} xs={12}>
						<IndexTable
							title={t('my-tickets')}
							loading={loading}
							headerProps="text-center"
							headers={[t('title'), t('category'), t('issue'), t('severity'), t('status')]}
							renderRows={
								tickets && tickets.length != 0
									? tickets.map((ticket, index) => {
											return (
												<tr
													style={{ cursor: 'pointer' }}
													key={index}
													onClick={() => {
														history.push(Routes.IT_Department.Ticket.Review.path.replace(':id', ticket.id));
													}}>
													<td className="fw-bold text-center">{ticket.title}</td>
													<td className="fw-bold text-center">{ticket.department}</td>
													<td className="fw-bold text-center">
														{' '}
														{ticket.issue
															? ticket.issue.split('<br>').map((issue) => {
																	if (issue != '') {
																		return (
																			<>
																				{issue}
																				<br />
																			</>
																		);
																	}
															  })
															: '-'}
													</td>
													<td className="fw-bold text-center">{ticket.severity}</td>
													<td className="fw-bold text-center">{ticket.status}</td>
												</tr>
											);
									  })
									: null
							}
						/>
					</Col>
					<Col xl={6} lg={12} md={12} sm={12} xs={12}>
						<Card className="mb-2">
							<Card.Header className="text-center d-flex justify-content-end" style={{ position: 'relative' }}>
								<h4
									style={{ color: '#4A508A', fontWeight: 'lighter', position: 'absolute', right: '30%', left: '30%' }}>
									{t('profile-information')}{' '}
								</h4>
								<Link to={Routes.Profile.Edit.path} className="field btn btn-secondary">
									{t('edit-profile')}{' '}
								</Link>
							</Card.Header>
							<Card.Body>
								<div className="d-flex mb-2">
									<span className="col-4">
										<b>{t('first-name')}</b>
									</span>
									<span className="col-4">
										<b>{t('last-name')}</b>
									</span>
									<span className="col-4">
										<b>{t('email')}</b>
									</span>
									{/* <span className="col-4">
										<b>Phone Number</b>
									</span> */}
								</div>
								<div className="d-flex mb-2">
									<span className="col-4">{user.first_name}</span>
									<span className="col-4">{user.last_name}</span>
									<span className="col-4">{user.email}</span>
									{/* <span className="col-4">{user_profile ? user_profile.phone_number : '-'}</span> */}
								</div>
								{/* <div className="d-flex mb-2">
								

									<span className="col-4">
										<b>Birthday</b>
									</span> 
								</div> */}
								{/* <div className="d-flex mb-2">
								

									 <span className="col-4">
										{user_profile ? user_profile.birthday.split('T')[0].split('-').reverse().join('.') : '-'}
									</span> 
								</div> */}
								<div className="d-flex mb-2">
									<span className="col-4">
										<b>{t('department-0')}</b>
									</span>
									<span className="col-4">
										<b>{t('company')}</b>
									</span>
									<span className="col-4">
										<b>{t('mobile-phone-number')}</b>
									</span>
								</div>
								{/*
									<span className="col-4">
										<b>Location</b>
									</span>
									
								</div> */}
								<div className="d-flex mb-2">
									<span className="col-4">{t('sales')}</span>
									<span className="col-4">{t('saportif-technology')}</span>
									<span className="col-4">{user_profile ? user_profile.mobile_phone_number : '-'}</span>
								</div>
								{/*
									<span className="col-4">Qatar</span>
								</div>
								<div className="d-flex mb-2">
									<span className="col-4">
										<b>Manager</b>
									</span>
									<span className="col-4">
										<b>Job Title</b>
									</span>
									<span className="col-4">
										<b>Employement Status</b>
									</span>
								</div> */}
								{/* <div className="d-flex mb-2">
									<span className="col-4">John Doe</span>
									<span className="col-4">Sales Consultant</span>
									<span className="col-4">Employed</span>
								</div> */}
								{/* <div className="d-flex mb-2">
									<span className="col-4">
										<b>Working Hours</b>
									</span>
									<span className="col-4">
										<b>Custom Cost Code</b>
									</span>
									<span className="col-4">
										<b>Effective Date</b>
									</span>
								</div> */}
								{/* <div className="d-flex mb-2">
									<span className="col-4">8.00-18.00</span>

									<span className="col-4">E5876SIUY</span>
									<span className="col-4">23.10.2021</span>
								</div>
								<div className="d-flex mb-2">
									<span className="col-4">
										<b>Nationality</b>
									</span>
								</div>
								<div className="d-flex mb-2">
									<span className="col-4">Arabic</span>
								</div> */}
							</Card.Body>
						</Card>
					</Col>
					<Row className="m-0 p-0">
						<Col xl={6} lg={12}></Col>
						<Col xl={6} lg={12} md={12} sm={12} xs={12}>
							<IndexTable
								title={t('public-holidays')}
								headers={[t('holday'), t('of-days-0'), t('start-date')]}
								headerProps="text-center"
								renderRows={publicHolidays.map((publicHoliday, index) => {
									return (
										<tr key={index}>
											<td className="fw-bold text-center">{publicHoliday.holiday}</td>
											<td className="fw-bold text-center">{publicHoliday.ofDays}</td>
											<td className="fw-bold text-center">{publicHoliday.date}</td>
										</tr>
									);
								})}
							/>
						</Col>
					</Row>
				</Row>
			</>
		);
	}
};

const mapStateToProps = (state) => {
	return {
		loading: state.user.getUserProfileLoading,
		user_id: state.auth.user_id,
		user: state.user.userProfile,
		user_profile: state.user.userProfile.user_profile,
		tickets: state.itDepartment.ticket.Tickets,
	};
};

export default connect(mapStateToProps, {
	getTicketsBelongsToUser,
	getProfile,
	clearData,
})(Profile);
