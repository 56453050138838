//PROJECTS

export const CREATE_PROJECT = 'CREATE_PROJECT';
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECT = 'GET_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';

export const CREATE_PROJECT_MILESTONE = 'CREATE_PROJECT_MILESTONE';
export const UPDATE_PROJECT_MILESTONE = 'UPDATE_PROJECT_MILESTONE';
export const GET_PROJECT_MILESTONE = 'GET_PROJECT_MILESTONE';
export const GET_PROJECT_MILESTONES = 'GET_PROJECT_MILESTONES';
export const DELETE_PROJECT_MILESTONE = 'DELETE_PROJECT_MILESTONE';
