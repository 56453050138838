import {
	CREATE_SALARY_INCREMENT,
	GET_SALARY_INCREMENTS,
	GET_SALARY_INCREMENT,
	DELETE_SALARY_INCREMENT,
} from '../../actions/HumanResources/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	salaryIncrements: [],
	salaryIncrement: {},
	loading: true,
	getSalaryIncrementLoading: true,
	getSalaryIncrementsLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_SALARY_INCREMENTS:
			return { ...state, salaryIncrements: action.payload, getSalaryIncrementsLoading: false };

		case GET_SALARY_INCREMENT:
			return { ...state, salaryIncrement: action.payload, getSalaryIncrementLoading: false };

		case CREATE_SALARY_INCREMENT:
			return { ...state };

		case CLEAR_DATA:
			return {
				...state,
				salaryIncrement: action.payload,
				getSalaryIncrementLoading: true,
				getSalaryIncrementsLoading: true,
			};

		case DELETE_SALARY_INCREMENT:
			return { ...state };

		default:
			return state;
	}
};
