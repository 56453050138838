import React from "react";
import { Link } from 'react-router-dom';
import { Routes } from '../../routes'

const TaskItem = (props) => {
  const {
    task: { id, status, content },
  } = props;

  return (
    <>
      <li className="taskList">
        <input
          id={id}
          type="checkbox"
          checked={status}
          onChange={() => props.onCheck(id)}
        />
        <label htmlFor={id}>
          {content == "Prepare LPO as per selected quotations" ? <Link to={Routes.Procurement.LPO.Index.path} style={{textDecoration: 'underline'}}>{content}</Link> : content}
          <span className="task-strike"></span>
        </label>
      </li>
    </>
  );
};
export default TaskItem;
