import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';
import { CREATE_DEPARTMENT, DELETE_DEPARTMENT, GET_DEPARTMENTS, GET_DEPARTMENT, UPDATE_DEPARTMENT } from './types';

export const getDepartments = () => async (dispatch) => {
	axios
		.get('department/')
		.then((response) => {
			dispatch({ type: GET_DEPARTMENTS, payload: response.data });
		})
		.catch((err) => {});
};

export const createDepartment = (formValues) => async (dispatch) => {
	axios
		.post('/department', {
			department_name: formValues.departmentName,
		})
		.then((response) => {
			dispatch({ type: CREATE_DEPARTMENT, payload: response.data });
			history.push(Routes.Admin.Department.Index.path);
		})
		.catch((err) => {});
};

export const getDepartment = (id) => async (dispatch) => {
	axios
		.get(`department/${id}`)
		.then((response) => {
			dispatch({ type: GET_DEPARTMENT, payload: response.data });
		})
		.catch((err) => {});
};

export const updateDepartment = (formValues) => async (dispatch) => {
	axios
		.put(`department/${formValues.id}`, {
			department_name: formValues.departmentName,
		})
		.then((response) => {
			dispatch({ type: UPDATE_DEPARTMENT, payload: response.data });
			history.push(Routes.Admin.Department.Index.path);
		})
		.catch((err) => {});
};

export const deleteDepartment = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`department/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_DEPARTMENT, payload: response.data });
			setDeleted(!deleted);

			history.push(Routes.Admin.Department.Index.path);
		})
		.catch((err) => {});
};
