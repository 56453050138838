import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import ClearanceForm from './ClearanceForm';
import { getEmployees } from '../../../../actions/HumanResources/employeeActions';
import { createClearance } from '../../../../actions/HumanResources/clearanceActions';
const ClearanceCreate = (props) => {
	const { datas, loading } = props;
	useEffect(() => {
		props.getEmployees();
	}, []);
	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.createClearance(formValues);
		}
	};
	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<ClearanceForm datas={datas} onSubmit={onSubmit} />
				</div>
			)}
		</>
	);
};
const mapStateToProps = (state) => {
	const {
		humanResources: {
			employee: { employees, getEmployeesLoading },
		},
	} = state;
	return { datas: { employees }, loading: getEmployeesLoading };
};
export default connect(mapStateToProps, { getEmployees, createClearance })(ClearanceCreate);
