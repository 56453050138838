import { Card, Col, Row } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { change, Field, initialize, reduxForm } from 'redux-form';

import SubmitButton from '../../../../components/formComponents/SubmitButton';
import validate from '../../../../validate';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { getDepartments } from '../../../../actions/Admin/departmentActions';
import { clearData } from '../../../../actions/globalActions';
import renderMultiselect from '../../../../components/formComponents/renderMultiselect';

import renderDropdown from '../../../../components/formComponents/renderDropdown';
import { maxLengthMultiSelect40, nonZero, notEmpty, required } from '../../../../helpers/validator';

import axios from '../../../../apis/axios';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const TicketCategoryForm = (props) => {
	const {
		department: { departments },
		initials,
		onSubmit,
		invalid,
	} = props;
	const { t } = useTranslation();
	const [ticketCategoryCheck, setTicketCategoryCheck] = useState(false);

	useEffect(() => {
		if (initials) {
			props.initialize(initials);
		}
		props.getDepartments();
		return () => {
			props.clearData();
		};
	}, []);

	useEffect(() => {
		props.change('isValid', !invalid);
	}, [invalid]);

	const renderDepartments = () => {
		return departments.map((department, index) => {
			return (
				<option key={index} value={department.department_id}>
					{department.department_name}
				</option>
			);
		});
	};

	const checkTicketCategory = (department_id) => {
		if (department_id) {
			axios.get(`ticket-category/check/${department_id}`).then((response) => {
				setTicketCategoryCheck(response.data);
			});
		} else {
			setTicketCategoryCheck(false);
		}
	};

	return (
		<form onSubmit={props.handleSubmit(onSubmit)}>
			<Row>
				<Col xl={12} lg={12} md={12} sm={12} xs={12}>
					<Card border="light" className="bg-white shadow-sm mb-4  w-100">
						<Card.Body>
							<h5 className="mb-4">{t('ticket-category-form')}</h5>

							<Row>
								<Col className="col-6 mb-3">
									<Field
										validate={[required, nonZero]}
										name="department_id"
										label={t('department-0')}
										onChange={(e) => checkTicketCategory(e.target.value)}
										component={renderDropdown}
										renderedOptions={renderDepartments()}
									/>
								</Col>

								<Col className="col-6 mb-3">
									<Field
										name="ticket_categories"
										label={t('categories')}
										component={renderMultiselect}
										textField="category"
										data={[]}
										validate={[required, notEmpty, maxLengthMultiSelect40]}
										dataKey="category"
										allowCreate
									/>
								</Col>
							</Row>
							{ticketCategoryCheck && (
								<strong className="text-danger">{t('there-is-already-a-category-for-selected-department')}</strong>
							)}
							<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
								<a onClick={() => window.history.back()} className="btn btn-secondary">
									{t('back')}{' '}
								</a>
								<SubmitButton disabled={ticketCategoryCheck} invalid={invalid} onSubmit={onSubmit} text={t('submit')} />
							</div>
						</Card.Body>
					</Card>
				</Col>
				<Col xl={4} lg={4} md={12} sm={12} xs={12}></Col>
			</Row>
		</form>
	);
};
const mapStateToProps = (state) => {
	return { department: state.admin.department };
};
export default compose(
	connect(mapStateToProps, { clearData, initialize, change, getDepartments }),
	reduxForm({
		form: 'ticketCategory',
		validate,
	})
)(TicketCategoryForm);
