import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import axios from '../apis/axios';

import { clearData } from '../actions/globalActions';
import LoadingSpinner from '../components/LoadingSpinner';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Card, Col, Row } from '@themesberg/react-bootstrap';

import doqMD from '../markdown/doq.md';
import SharepointCard from '../components/SharepointCard';

const Documentation = (props) => {
	const [fetchedText, setFetchedText] = useState(null);
	const [sharepointList, setSharepointList] = useState([]);
	const [loading, setLoading] = useState(1);
	useEffect(() => {
		axios
			.get('/sharepoint')
			.then((response) => {
				setSharepointList(response.data);
				setLoading(0);
			})
			.catch((err) => {
				console.log('SHAREPOINT ERROR: ', err.response.data.message);
			});
		setSharepointList(sharepointList);
		fetch(doqMD)
			.then((response) => response.text())
			.then((text) => {
				setFetchedText(text);
			});
		return () => {
			props.clearData();
		};
	}, []);

	return (
		<Row className="">
			<Col xl={12} lg={12} md={12} sm={12} xs={12}>
				<Card border="light" className="bg-white shadow-sm mb-4  w-100">
					<Card.Header>
						<h4 className="text-center">Help Documents</h4>
					</Card.Header>
					<Card.Body>
						<ReactMarkdown children={fetchedText} />
						<ul className="document-list">
							{loading ? (
								<LoadingSpinner />
							) : (
								sharepointList.map((sharepoint, i) => {
									return <SharepointCard key={i} documentName={sharepoint.Name} documentLink={sharepoint.LinkingUrl} />;
								})
							)}
						</ul>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
};
const mapStateToProps = (state) => {
	return {
		sharepoints: state.sharepoint,
	};
};
export default connect(mapStateToProps, {
	clearData,
})(Documentation);
