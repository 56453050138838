import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, ListGroup, Row } from '@themesberg/react-bootstrap';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getISR } from '../../../../actions//Procurement/isrActions';
import Timeline from '../../../../components/Timeline/Timeline';

import { clearData } from '../../../../actions/globalActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';

import { Link } from 'react-router-dom';
import { getQuotationsByISR } from '../../../../actions/Procurement/quotationActions';
import IndexTable from '../../../../components/IndexTable';
import { Routes } from '../../../../routes';
import { useTranslation } from 'react-i18next';

const ISRDetails = (props) => {
	const { t } = useTranslation();

	const isrID = props.match.params.id;
	const {
		datas: { isr, quotations, isr_timelines },
		loading,
	} = props;
	useEffect(() => {
		props.getISR(isrID);
		props.getQuotationsByISR(isrID);
		return () => {
			props.clearData();
		};
	}, []);

	const renderContent = (content) => {
		return content.map((data, index) => {
			return (
				<tr key={index}>
					<th className="fs-6" scope="row">
						{index + 1}
					</th>
					<th className="fs-6">{data.description || '-'}</th>
					<th className="fs-6">{data.bill_of_quantity || '-'}</th>
					<th className="fs-6">{data.unit || '-'}</th>
					<th className="fs-6">{data.quantity_required || '-'}</th>
					<th className="fs-6">{data.availability || '-'}</th>
					<th className="fs-6">{data.balance || '-'}</th>
					<th className="fs-6 ">{data.estimated_value || '-'}</th>
				</tr>
			);
		});
	};

	const colorGenerator = (comparedValue, value) => {
		var saturationValue;

		const level_1 = value * 0.2;
		const level_2 = value * 0.4;
		const absDiff = Math.abs(comparedValue);

		if (absDiff <= level_1) {
			saturationValue = 50;
		} else if (absDiff > level_1 && absDiff < level_2) {
			saturationValue = 30;
		} else {
			saturationValue = 10;
		}

		return saturationValue;
	};

	const renderCompareQuotationTableRows = () => {
		var rows;

		rows = isr.isr_contents.map((content, index) => {
			return (
				<tr className="fw-bold text-center" key={index}>
					<td className="quotation-compare-table-description">{content.description}</td>

					{quotations.map((quotation, index) => {
						if (_.find(quotation.quotation_contents, { description: content.description })) {
							const diff =
								content.estimated_value -
								_.find(quotation.quotation_contents, { description: content.description }).amount;

							const satValue = colorGenerator(diff, content.estimated_value);

							return (
								<td
									style={{
										color: `${diff < 0 ? `hsl(0,100%,${satValue}%)` : `hsl(100,100%,30%)`}`,
									}}
									className="fw-bolder"
									key={index}>
									{_.find(quotation.quotation_contents, { description: content.description }).amount}
								</td>
							);
						} else {
							return <td key={index}>-</td>;
						}
					})}
					<td className="quotation-compare-table-estimated-value">{content.estimated_value}</td>
				</tr>
			);
		});

		rows.push(
			<tr key={'a'} className="fw-bold text-center ">
				<td className="quotation-compare-table-description fw-bolder border border-dark border-2 border-top border-bottom-0  border-start-0">
					Total:
				</td>
				{quotations.map((quotation, index) => {
					const diff = isr.total_value - quotation.total_amount;

					const satVal = colorGenerator(diff, quotation.total_amount);

					return (
						<td
							style={{
								color: `${diff < 0 ? `hsl(0,${satVal}%,50%)` : `hsl(100,${satVal}%,50%)`}`,
							}}
							className="fw-bolder border border-dark border-2 border-top border-bottom-0 border-end-0 border-start-0"
							key={index}>
							{quotation.total_amount}
						</td>
					);
				})}
				<td className="quotation-compare-table-estimated-value border border-dark border-2 border-top border-bottom-0 border-end-0 ">
					{isr.total_value}
				</td>
			</tr>
		);

		return rows;
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<>
					<Row className="mb-3">
						<Col lg={9} md={12}>
							<div>
								{loading ? (
									<LoadingSpinner />
								) : (
									<div className=" pt-4 pb-2">
										<Card>
											<Card.Header className="border-bottom-0">
												<Row>
													<Col>
														<h4>
															{t('isr-details')} <span>{isr.level_of_priority}</span>
														</h4>
													</Col>
													<Col>
														<h6 className="m-0	text-right ">
															<span className="text-muted">{t('start-date-0')} </span>
															{new Date(isr.start_date).toLocaleDateString()} <br />
															<span className="text-muted">{t('due-date-0')} </span>
															{new Date(isr.due_date).toLocaleDateString()}
														</h6>
													</Col>
												</Row>
											</Card.Header>
											<Card.Body>
												<Col>
													<ListGroup>
														<ListGroup.Item className="border-bottom border-top">
															<Row className="align-items-center  justfiy-content-center">
																<Col>
																	<h4>
																		{isr.project.project_name}
																		<span className="h5	"> - {isr.request_type}</span>
																	</h4>
																</Col>
																<Col>
																	<h6 className="m-0	text-right ">
																		<span className="text-muted">{t('by')} </span>
																		{isr.from}
																	</h6>
																</Col>
															</Row>
														</ListGroup.Item>

														<ListGroup.Item className="border-bottom">
															<Row className="align-items-center  justfiy-content-center">
																<Col>
																	<h5 className="d-inline">
																		<span className="text-muted">Reference:</span> {isr.reference}
																	</h5>
																</Col>
															</Row>
														</ListGroup.Item>
														<ListGroup.Item className="border-bottom">
															<Row className="align-items-center  justfiy-content-center">
																<Col>
																	<h5 className="d-inline">
																		<span className="text-muted">{t('notes-and-remarks')}</span> {isr.notes}
																	</h5>
																</Col>
															</Row>
														</ListGroup.Item>
													</ListGroup>
												</Col>
												<div className="overflow-auto my-3">
													<table className="table table-striped text-center rounded ">
														<thead className="thead-light">
															<tr>
																<th scope="col">#</th>
																<th scope="col">
																	{t('description')} <br /> {t('of-works')}
																</th>
																<th scope="col">
																	BOQ <br /> {t('ref')}
																</th>
																<th scope="col">{t('unit')}</th>
																<th scope="col">
																	QTY <br /> {t('required')}
																</th>
																<th scope="col">
																	{t('qty-available')} <br /> {t('in-store')}
																</th>
																<th scope="col">
																	{t('balance-to')} <br /> {t('be-purchased')}
																</th>
																<th scope="col">
																	{t('estimated')} <br /> {t('value')}
																</th>
															</tr>
														</thead>
														<tbody>
															{_.isEmpty(isr.isr_contents) ? (
																<tr>
																	<td colSpan="100%">Loading...</td>
																</tr>
															) : (
																renderContent(isr.isr_contents)
															)}
														</tbody>
														<tfoot>
															<tr>
																<td className="p-3" style={{ borderBottom: 0 }}>
																	<h6 className="m-0">Total: </h6>
																</td>
																<td style={{ borderBottom: 0 }}>-</td>
																<td style={{ borderBottom: 0 }}>-</td>
																<td className="p-3" style={{ borderBottom: 0 }}>
																	<h6 className="m-0">{isr.total_unit || '-'}</h6>
																</td>
																<td className="p-3" style={{ borderBottom: 0 }}>
																	<h6 className="m-0">{isr.total_quantity_required || '-'}</h6>
																</td>
																<td className="p-3" style={{ borderBottom: 0 }}>
																	<h6 className="m-0">{isr.total_availability || '-'}</h6>
																</td>
																<td className="p-3" style={{ borderBottom: 0 }}>
																	<h6 className="m-0">{isr.total_balance || '-'}</h6>
																</td>
																<td className="p-3" style={{ borderBottom: 0 }}>
																	<h6 className="m-0">{isr.total_value || '-'}</h6>
																</td>
															</tr>
														</tfoot>
													</table>
												</div>
												{(() => {
													let referenceLink = isr.reference.split('/').join('-');
													return (
														<div className="d-flex justify-content-end flex-wrap flex-md-nowrap align-items-center py-4">
															<Link
																className="btn btn-primary px-3 mx-2"
																to={Routes.Procurement.ISR.Checklist.path.replace(':id', isrID)}>
																{t('quotations')}{' '}
															</Link>
															<a
																className="btn btn-secondary mx-2 px-3 "
																target="blank"
																href={`${process.env.REACT_APP_SHAREPOINT_BASE_URL}${process.env.REACT_APP_SHAREPOINT_DOCUMENTS}ISR/${isr.company.company_name}/${referenceLink}`}>
																<FontAwesomeIcon className="me-2" icon={faFile} />
																{t('documents')}{' '}
															</a>
														</div>
													);
												})()}
											</Card.Body>
										</Card>
									</div>
								)}
							</div>
							<IndexTable
								headerProps="text-center"
								headers={[
									t('description-of-works'),
									..._.map(quotations, 'supplier.supplier_name'),
									t('estimated-value'),
								]}
								renderRows={renderCompareQuotationTableRows()}
								loading={loading}
								title={
									<>
										<div className="d-flex justify-content-end align-items-center fs-6">
											Price:
											<div
												className=" rounded-circle  text-white mx-3"
												style={{ background: 'hsl(100,100%,30%)', height: '1rem', width: '1rem' }}></div>
											{t('low')}{' '}
											<div
												className=" rounded-circle  text-white mx-3"
												style={{ background: 'hsl(0,100%,50%)', height: '1rem', width: '1rem' }}></div>
											{t('high')}{' '}
											<div
												className=" rounded-circle  text-white mx-3"
												style={{ background: 'hsl(0,100%,30%)', height: '1rem', width: '1rem' }}></div>
											{t('higher')}{' '}
											<div
												className=" rounded-circle  text-white mx-3"
												style={{ background: 'hsl(0,100%,10%)', height: '1rem', width: '1rem' }}></div>
											{t('highest')}{' '}
										</div>
									</>
								}
							/>
							<Row>
								<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
									<Link to={Routes.Procurement.ISR.Index.path} className="btn btn-secondary">
										{t('back')}{' '}
									</Link>
								</div>
							</Row>
						</Col>
						<Col lg={3} md={12} className="py-4">
							<Timeline timelines={isr_timelines} />
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		procurement: {
			isr: {
				isr: { isr, isr_timelines },
				getISRLoading,
			},
			quotation: { quotations, getQuotationsLoading },
		},
	} = state;

	return { datas: { isr, isr_timelines, quotations }, loading: getISRLoading || getQuotationsLoading };
};

export default connect(mapStateToProps, { getISR, clearData, getQuotationsByISR })(ISRDetails);
