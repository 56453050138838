import React from 'react';

import { faUser } from '@fortawesome/free-regular-svg-icons';
import {
	faAngleDown,
	faAngleUp,
	faChartLine,
	faChartPie,
	faFlagUsa,
	faFolderOpen,
	faGlobeAfrica,
	faGlobeEurope,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Row } from '@themesberg/react-bootstrap';

export const CounterWidget = (props) => {
	const { icon, iconColor, category, title, period, percentage } = props;
	const percentageIcon = percentage < 0 ? faAngleDown : faAngleUp;
	const percentageColor = percentage < 0 ? 'text-danger' : 'text-success';

	return (
		<Card border="light" className="shadow-sm">
			<Card.Body>
				<Row className="d-block d-xl-flex align-items-center">
					<Col xl={5} className="text-xl-center d-flex align-items-center justify-content-xl-center mb-3 mb-xl-0">
						<div className={`icon icon-shape icon-lg icon-${'black'} rounded me-4 me-sm-0`}>
							<FontAwesomeIcon icon={icon} />
						</div>
						<div className="d-sm-none">
							<h5>{category}</h5>
							<h3 className="mb-1">{title}</h3>
						</div>
					</Col>
					<Col xs={12} xl={7} className="px-xl-0">
						<div className="d-none d-sm-block">
							<h5>{category}</h5>
							<h3 className="mb-1">{title}</h3>
						</div>
						<small>
							{period}, <FontAwesomeIcon icon={faGlobeEurope} size="xs" /> All Holding
						</small>
						{/* <div className="small mt-2">
							<FontAwesomeIcon
								icon={percentageIcon}
								className={`${percentageColor} me-1`}
							/>
							<span className={`${percentageColor} fw-bold`}>
								{percentage ?? 10}%
							</span>{" "}
							Since last month
						</div> */}
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

export const RankingWidget = () => {
	return (
		<Card border="light" className="shadow-sm">
			<Card.Body>
				<div className="d-flex align-items-center justify-content-between border-bottom border-light pb-3">
					<div>
						<h6>
							<FontAwesomeIcon icon={faGlobeEurope} className="icon icon-xs me-3" /> Global Rank
						</h6>
					</div>
					<div>
						<Card.Link href="#" className="text-primary fw-bold">
							#755 <FontAwesomeIcon icon={faChartLine} className="ms-2" />
						</Card.Link>
					</div>
				</div>
				<div className="d-flex align-items-center justify-content-between border-bottom border-light py-3">
					<div>
						<h6 className="mb-0">
							<FontAwesomeIcon icon={faFlagUsa} className="icon icon-xs me-3" />
							Country Rank
						</h6>
						<div className="small card-stats">
							United States <FontAwesomeIcon icon={faAngleUp} className="icon icon-xs text-success ms-2" />
						</div>
					</div>
					<div>
						<Card.Link href="#top" className="text-primary fw-bold">
							#32 <FontAwesomeIcon icon={faChartPie} className="ms-2" />
						</Card.Link>
					</div>
				</div>
				<div className="d-flex align-items-center justify-content-between pt-3">
					<div>
						<h6 className="mb-0">
							<FontAwesomeIcon icon={faFolderOpen} className="icon icon-xs me-3" />
							Category Rank
						</h6>
						<Card.Link href="#top" className="small card-stats">
							Travel &gt; Accomodation
						</Card.Link>
					</div>
					<div>
						<Card.Link href="#top" className="text-primary fw-bold">
							#16 <FontAwesomeIcon icon={faChartLine} className="ms-2" />
						</Card.Link>
					</div>
				</div>
			</Card.Body>
		</Card>
	);
};

export const IconCardWidget = (props) => {
	const { icon, iconColor, category, title, period, percentage, subtitle } = props;
	// const percentageIcon = percentage < 0 ? faAngleDown : faAngleUp;
	// const percentageColor = percentage < 0 ? "text-danger" : "text-success";

	return (
		<Card border="light" className="shadow-sm " style={{ backgroundColor: 'gainsboro' }}>
			<Card.Body>
				<Row className="d-block d-xl-flex align-items-center">
					<Col md={4} className="text-center">
						<FontAwesomeIcon size="3x" icon={icon} />
					</Col>
					<Col md={8} className="">
						<Card.Title>{title}</Card.Title>
						<Card.Subtitle className="fs-7 text-muted">{subtitle}</Card.Subtitle>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

export const GraphView = () => {
	return (
		<>
			<div className="shadow-sm card border-light">
				<div className="d-flex flex-row align-items-center flex-0 border-bottom card-body">
					<div className="d-block">
						<h6 className="fw-normal text-gray mb-2">Activity by Company</h6>
						<h4>Saportif Technology</h4>
						<small className="mt-2">
							<svg
								aria-hidden="true"
								focusable="false"
								data-prefix="fas"
								data-icon="angle-up"
								className="svg-inline--fa fa-angle-up fa-w-10 text-success me-1"
								role="img"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 320 512">
								<path
									fill="currentColor"
									d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"></path>
							</svg>
							<span className="text-success fw-bold">70%</span>
						</small>
					</div>
					<div className="d-block ms-auto">
						<div className="d-flex align-items-center text-end mb-2">
							<span className="shape-xs rounded-circle bg-primary me-2"></span>
							<span className="fw-normal small">Saportif Technology</span>
						</div>
						<div className="d-flex align-items-center text-end mb-2">
							<span className="shape-xs rounded-circle bg-secondary me-2"></span>
							<span className="fw-normal small">Powervac MEP</span>
						</div>
						<div className="d-flex align-items-center text-end mb-2">
							<span className="shape-xs rounded-circle bg-tertiary me-2"></span>
							<span className="fw-normal small">WoodMaster</span>
						</div>
						<div className="d-flex align-items-center text-end mb-2">
							<span className="shape-xs rounded-circle bg-warning me-2"></span>
							<span className="fw-normal small">Alu Technal</span>
						</div>
						<div className="d-flex align-items-center text-end mb-2">
							<span className="shape-xs rounded-circle bg-info me-2"></span>
							<span className="fw-normal small">Gallery Francais</span>
						</div>
					</div>
				</div>
				<div className="p-2 card-body">
					<div className="ct-chart ct-golden-section">
						<svg xmlns="http://gionkunz.github.com/chartist-js/ct" width="100%" height="100%" className="ct-chart-pie">
							<g className="ct-series ct-series-a">
								<path
									d="M76.164,138.602A102.063,102.063,0,1,0,173.231,5L173.231,107.063Z"
									className="ct-slice-pie "
									value="40"></path>
							</g>
							<g className="ct-series ct-series-b">
								<path
									d="M113.24,24.492A102.063,102.063,0,0,0,76.275,138.94L173.231,107.063Z"
									className="ct-slice-pie"
									value="20"></path>
							</g>
							<g className="ct-series ct-series-c">
								<path
									d="M173.231,5A102.063,102.063,0,0,0,112.953,24.702L173.231,107.063Z"
									className="ct-slice-pie"
									value="10"></path>
							</g>
							<g className="ct-series ct-series-d">
								<path
									d="M173.231,5A102.063,102.063,0,0,0,112.953,24.702L173.231,107.063Z"
									className="ct-slice-pie"
									value="10"></path>
							</g>
							<g className="ct-series ct-series-e">
								<path
									d="M173.231,5A102.063,102.063,0,0,0,112.953,24.702L173.231,107.063Z"
									className="ct-slice-pie"
									value="20"></path>
							</g>
						</svg>
					</div>
				</div>
			</div>
		</>
	);
};
