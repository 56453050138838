import axios from '../../apis/axios';
import { GET_EMBED_INFO } from './types';

export const getProcurementOverview = () => async (dispatch) => {
	axios
		.get('powerbi/FinanceReport')
		.then((response) => {
			dispatch({ type: GET_EMBED_INFO, payload: response.data });
		})
		.catch((err) => {});
};

export const getFinanceOverview = () => async (dispatch) => {
	axios
		.get('powerbi/FinanceReport')
		.then((response) => {
			dispatch({ type: GET_EMBED_INFO, payload: response.data });
		})
		.catch((err) => {});
};
export const getTransportationOverview = () => async (dispatch) => {
	axios
		.get('powerbi/TransportationReport')
		.then((response) => {
			dispatch({ type: GET_EMBED_INFO, payload: response.data });
		})
		.catch((err) => {});
};

export const getPunchListOverview = () => async (dispatch) => {
	axios
		.get('powerbi/PunchListReport')
		.then((response) => {
			dispatch({ type: GET_EMBED_INFO, payload: response.data });
		})
		.catch((err) => {});
};
export const getHumanResourcesOverview = () => async (dispatch) => {
	axios
		.get('powerbi/EmployeeReport')
		.then((response) => {
			dispatch({ type: GET_EMBED_INFO, payload: response.data });
		})
		.catch((err) => {});
};
export const getProjectOverview = () => async (dispatch) => {
	axios
		.get('powerbi/ProjectReport')
		.then((response) => {
			dispatch({ type: GET_EMBED_INFO, payload: response.data });
		})
		.catch((err) => {});
};
