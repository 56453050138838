import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';
import {
	CREATE_WORKFLOW,
	DELETE_WORKFLOW,
	GET_WORKFLOW,
	GET_WORKFLOWS,
	UPDATE_WORKFLOW,
	GET_FORMS,
	GET_MODULES,
} from './types';

export const createWorkflow = (formValues) => async (dispatch) => {
	axios
		.post('/workflow', formValues)
		.then((response) => {
			dispatch({ type: CREATE_WORKFLOW, payload: response.data });
			history.push(Routes.Workflow.Index.path);
		})
		.catch((err) => {});
};

export const getWorkflow = (id) => async (dispatch) => {
	axios
		.get(`workflow/${id}`)
		.then((response) => {
			dispatch({ type: GET_WORKFLOW, payload: response.data });
		})
		.catch((err) => {});
};

export const getWorkflows = () => async (dispatch) => {
	axios
		.get(`workflow`)
		.then((response) => {
			dispatch({ type: GET_WORKFLOWS, payload: response.data });
		})
		.catch((err) => {});
};

export const updateWorkflow = (formValues) => async (dispatch) => {
	axios
		.put(`workflow/${formValues.workflow_id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_WORKFLOW, payload: response.data });
			history.push(Routes.Workflow.Index.path);
		})
		.catch((err) => {});
};

export const deleteWorkflow = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`workflow/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_WORKFLOW, payload: response.data });
			setDeleted(!deleted);

			history.push(Routes.Workflow.Index.path);
		})
		.catch((err) => {});
};

export const getForms = () => async (dispatch) => {
	axios
		.get('workflow/form')
		.then((response) => {
			dispatch({ type: GET_FORMS, payload: response.data });
		})
		.catch((err) => {});
};

export const getModules = () => async (dispatch) => {
	axios
		.get('workflow/module')
		.then((response) => {
			dispatch({ type: GET_MODULES, payload: response.data });
		})
		.catch((err) => {});
};
