import Approved from './Approved';
import Created from './Created';
import Pending from './Pending';
import Rejected from './Rejected';
import Updated from './Updated';

const Timeline = ({ timelines }) => {
	const renderTimelineCard = () => {
		var is_gray = false;
		const update_index = timelines
			.slice()
			.reverse()
			.findIndex((obj) => obj.status == 'Update');
		const last_update_index = update_index == -1 ? 0 : timelines.length - 1 - update_index;

		return timelines.map((val, index) => {
			if (index < last_update_index && last_update_index != 0) {
				is_gray = true;
			} else {
				is_gray = false;
			}
			switch (val.status) {
				case 'Create':
					return (
						<div key={index} className={is_gray ? 'grayed-timeline list-group-item' : 'list-group-item'}>
							<Created username={val.username} action={val.action} action_date={val.action_date} />
						</div>
					);
				case 'Update':
					return (
						<div key={index} className={is_gray ? 'grayed-timeline list-group-item' : 'list-group-item'}>
							<Updated username={val.username} action={val.action} action_date={val.action_date} />
						</div>
					);

				case 'Rejected':
					return (
						<div key={index} className={is_gray ? 'grayed-timeline list-group-item' : 'list-group-item'}>
							<Rejected username={val.username} action={val.action} action_date={val.action_date} />
						</div>
					);

				case 'Approved':
					return (
						<div key={index} className={is_gray ? 'grayed-timeline list-group-item' : 'list-group-item'}>
							<Approved username={val.username} action={val.action} action_date={val.action_date} />
						</div>
					);

				case 'Pending':
					return (
						<div key={index} className={is_gray ? 'grayed-timeline list-group-item' : 'list-group-item'}>
							<Pending username={val.username} action={val.action} action_date={val.action_date} />
						</div>
					);

				default:
					return null;
			}
		});
	};

	return (
		<div className="shadow-sm card border-light">
			<div className="d-flex align-items-center border-bottom border-light card-header ">
				<h3 className="h5 mb-0 ">Form Timeline</h3>
			</div>
			<div className="card-body px-0">
				<div className="list-group-flush list-group-timeline list-group">{renderTimelineCard()}</div>
			</div>
		</div>
	);
};

export default Timeline;
