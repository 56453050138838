import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Report from '../Reports/Report';
import { getTransportationOverview } from '../../../actions/Others/powerbiActions';

const TransportationOverview = (props) => {
	return (
		<>
			<Report getEmbedInfo={props.getTransportationOverview} />
		</>
	);
};

export default connect(null, { getTransportationOverview })(TransportationOverview);
