import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Card, Col, Form, Row } from '@themesberg/react-bootstrap';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import countryList from 'react-select-country-list';
import { compose } from 'redux';
import { change, Field, initialize, reduxForm } from 'redux-form';
import { clearData } from '../../../../actions/globalActions';
import renderDropdown from '../../../../components/formComponents/renderDropdown';
import renderDropdownWithAutoComplete from '../../../../components/formComponents/renderDropdownWithAutoComplete';
import renderInput from '../../../../components/formComponents/renderInput';
import renderPhoneNumberInput from '../../../../components/formComponents/renderPhoneNumberInput';
import SubmitButton from '../../../../components/formComponents/SubmitButton';
import { Routes } from '../../../../routes';
import validate from '../../../../validate';

const EmployeeForm = (props) => {
	const { full_name, user_id, companies, onSubmit, datas, initials } = props;
	const { t } = useTranslation();

	useEffect(() => {
		if (initials) {
			props.initialize({
				...initials,
				by: full_name,
				registration_date: new Date(initials.registration_date).toISOString().split('T')[0],
				start_date: new Date(initials.start_date).toISOString().split('T')[0],
				birthdate: new Date(initials.birthdate).toISOString().split('T')[0],
			});
		} else {
			props.initialize({
				by: full_name,
				registration_date: new Date().toISOString().split('T')[0],
				start_date: new Date().toISOString().split('T')[0],
			});
		}

		props.change('user_id', user_id);
	}, []);

	const renderEmployeeCompanyOptipns = () => {
		return datas.companies.map((company, index) => {
			return (
				<option key={index} value={company.company_id}>
					{company.company_name}
				</option>
			);
		});
	};

	const renderDepartmentOptions = () => {
		return datas.departments.map((department, index) => {
			return (
				<option key={index} value={department.department_id}>
					{department.department_name}
				</option>
			);
		});
	};

	const renderUserCompanyOptions = () => {
		return companies.map((company, index) => {
			return (
				<option key={index} value={company.company_id}>
					{company.company_name}
				</option>
			);
		});
	};

	const renderCountryOptions = () => {
		return countryList()
			.getLabels()
			.map((value, index) => {
				if (value == t('qatar')) {
					return (
						<option key={value} value={value}>
							{value}
						</option>
					);
				} else {
					return (
						<option key={value} value={value}>
							{value}
						</option>
					);
				}
			});
	};

	return (
		<>
			<div className="d-block mb-4 mb-xl-0 ">
				<Breadcrumb
					className=" d-block"
					listProps={{
						className: 'breadcrumb-dark breadcrumb-transparent',
					}}>
					<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
						<FontAwesomeIcon icon={faHome} />
					</Breadcrumb.Item>
					<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.HumanResources.Overview.path }}>
						{t('human-resources')}{' '}
					</Breadcrumb.Item>
					<Breadcrumb.Item active>{t('employees-0')}</Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<form onSubmit={props.handleSubmit(onSubmit)}>
				<Row>
					<Col md={12}>
						<Card border="light" className="bg-white shadow-sm mb-4  w-100">
							<Card.Body>
								<h5 className="mb-4">{t('employee-registration-form')}</h5>
								<Row className="mb-3 ">
									<Col md={6} sm={12}>
										<Field name="by" label={t('by')} component={renderInput} disabled="disabled" />
									</Col>
									<Col md={6} sm={12}>
										<Field
											name="company_id"
											label={t('company')}
											component={renderDropdown}
											renderedOptions={renderUserCompanyOptions()}
										/>
									</Col>
								</Row>
								<hr />
								<Row className="mb-3">
									<Col>
										<Field name="first_name" label={t('first-name')} component={renderInput} />
									</Col>
									<Col>
										<Field name="last_name" label={t('last-name')} component={renderInput} />
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field name="work_mail" label={t('work-mail')} component={renderInput} />
									</Col>
									<Col>
										<Field name="personal_mail" label={t('personal-mail')} component={renderInput} />
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field name="site" label={t('site')} component={renderInput} />
									</Col>
									<Col>
										<Field name="residency_id" label={t('residency-id')} component={renderInput} />
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field name="phone_number" label={t('phone-number')} component={renderPhoneNumberInput} />
									</Col>
									<Col>
										<Field
											name="mobile_phone_number"
											label={t('mobile-phone-number')}
											component={renderPhoneNumberInput}
										/>
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field name="start_date" inputType="date" label={t('start-date')} component={renderInput} />
									</Col>
									<Col>
										<Field
											name="registration_date"
											inputType="date"
											label={t('registration-date')}
											component={renderInput}
										/>
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field
											name="employee_number"
											inputType="number"
											label={t('employee-number')}
											component={renderInput}
										/>
									</Col>
									<Col>
										<Field
											name="status"
											label={t('status')}
											component={renderDropdown}
											renderedOptions={
												<>
													<option value={t('employed')}>{t('employed-0')}</option>
													<option value={t('on-leave-0')}>{t('on-leave-0')}</option>
													<option value={t('freelancer')}>{t('freelancer-0')}</option>
												</>
											}
										/>
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Form.Group>
											<Field
												name="nationality"
												label={t('nationality')}
												dataKey={null}
												component={renderDropdownWithAutoComplete}
												data={countryList().getLabels()}
											/>
										</Form.Group>
									</Col>
									<Col>
										<Field name="birthdate" inputType="date" label={t('birthdate')} component={renderInput} />
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field
											name="department_id"
											label={t('department-0')}
											component={renderDropdown}
											renderedOptions={renderDepartmentOptions()}
										/>
									</Col>
									<Col>
										<Field
											name="employee_company_id"
											label={t('employee-company')}
											component={renderDropdown}
											renderedOptions={renderEmployeeCompanyOptipns()}
										/>
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field name="job_title" label={t('job-title')} component={renderInput} />
									</Col>
									<Col>
										<Field name="manager" label={t('manager')} component={renderInput} />
									</Col>
								</Row>

								<Row className="mb-3">
									<Col>
										<Field name="working_hours" label={t('working-hours')} component={renderInput} />
									</Col>
									<Col></Col>
								</Row>

								<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
									<Link to={Routes.HumanResources.Employee.Index.path} className="btn btn-secondary">
										{t('back')}{' '}
									</Link>
									<SubmitButton text={t('submit')} onSubmit={onSubmit} invalid={false} />
								</div>
							</Card.Body>
						</Card>
					</Col>
					<Col xl={4} lg={4} md={12} sm={12} xs={12}></Col>
				</Row>
			</form>
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		auth: { full_name, user_id, companies },
	} = state;
	return {
		full_name: full_name,
		user_id: user_id,
		companies,
	};
};

export default compose(
	connect(mapStateToProps, { initialize, clearData, change }),
	reduxForm({ form: 'employee', validate })
)(EmployeeForm);
