import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import IndexTable from '../../components/IndexTable';
import { getTickets, getTicketsBelongsToUser } from '../../actions/ITDepartment/ticketActions';

import { Card, Col, Row } from '@themesberg/react-bootstrap';
import { Breadcrumb, Nav } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { Routes } from '../../routes';
import { clearData } from '../../actions/globalActions';

import {
	faEdit,
	faEye,
	faHome,
	faTrash,
	faChartBar,
	faChartArea,
	faBug,
	faCode,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingSpinner from '../../components/LoadingSpinner';
import FAQ from '../../components/FAQ';
import Documentation from '../../components/Documentation';

import history from '../../history';
import { useTranslation } from 'react-i18next';

const Support = (props) => {
	const { tickets, user_id, loading } = props;

	useEffect(() => {
		props.getTicketsBelongsToUser(user_id);
		return () => {
			props.clearData();
		};
	}, []);
	const { t } = useTranslation();

	return loading ? (
		<LoadingSpinner />
	) : (
		<>
			<div className=" pt-4">
				<div className="d-block  mb-xl-0 ">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{t('Support')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div
					className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center
">
					<h4 style={{ display: 'inline' }}>{t('support-center')}</h4>
				</div>
				<Row className="pb-5">
					<Col xl={8} lg={8} md={12} sm={12} xs={12}>
						<IndexTable
							title={
								<div
									className="d-flex justify-content-center flex-wrap flex-md-nowrap align-items-center"
									style={{ position: 'relative' }}>
									<h4 style={{ display: 'inline' }}>{t('my-tickets')}</h4>
									<div className="d-flex justify-content-end" style={{ position: 'absolute', right: '2%' }}>
										<Link className="btn btn-secondary py-2 px-3  " to={Routes.IT_Department.Ticket.Create.path}>
											{t('create-ticket')}{' '}
										</Link>
									</div>
								</div>
							}
							headerProps="text-center"
							headers={[t('title'), t('category'), t('issue'), t('severity'), t('status')]}
							renderRows={
								tickets && tickets.length != 0
									? tickets.map((ticket, index) => {
											return (
												<tr
													style={{ cursor: 'pointer' }}
													key={index}
													onClick={() => {
														history.push(Routes.IT_Department.Ticket.Review.path.replace(':id', ticket.id));
													}}>
													<td className="fw-bold text-center">{ticket.title}</td>
													<td className="fw-bold text-center">{ticket.department}</td>
													<td className="fw-bold text-center">
														{' '}
														{ticket.issue
															? ticket.issue.split('<br>').map((issue) => {
																	if (issue != '') {
																		return (
																			<>
																				{issue}
																				<br />
																			</>
																		);
																	}
															  })
															: null}
													</td>
													<td className="fw-bold text-center">{ticket.severity}</td>
													<td className="fw-bold text-center">{ticket.status}</td>
												</tr>
											);
									  })
									: null
							}
						/>
					</Col>
					<Col xl={4} lg={4} md={12} sm={12} xs={12}>
						<Documentation />
					</Col>
					<Col xl={6} lg={6} md={12} sm={12} xs={12}>
						<Card border="light" className="shadow-sm">
							<Card.Body>
								<h5>{t('latest-updates')}</h5>
								<p>{t('on-19-02-22-version-1-0-0-has-been-deployed-by-saportif-technology')}</p>
								<div className="d-block">
									<div className="d-flex align-items-stretch justify-content-between pt-3 me-5">
										<div className="icon icon-shape icon-sm icon-shape-danger rounded me-3">
											<FontAwesomeIcon icon={faCode} />
										</div>
										<div className="d-block">
											<label className="mb-0">{t('isr-and-lpo-forms')}</label>
											<label className="mb-0 text-muted">
												{t(
													'procurement-workflow-updated-with-the-most-recent-request-by-saportif-technology-and-gallery-francois-procurement-teams'
												)}{' '}
											</label>
										</div>
									</div>
									<div className="d-flex align-items-stretch justify-content-between pt-3">
										<div className="icon icon-shape icon-sm icon-shape-quaternary rounded me-3">
											<FontAwesomeIcon icon={faBug} />
										</div>
										<div className="d-block">
											<label className="mb-0">{t('supplier-pages')}</label>
											<label className="mb-0 text-muted">
												{t('removed-the-bug-that-prevented-users-to-view-details-of-suppliers-with-missing-data')}{' '}
											</label>
										</div>
									</div>
								</div>
							</Card.Body>
						</Card>
					</Col>

					<Col xl={6} lg={6} md={12} sm={12} xs={12}>
						<FAQ />
					</Col>
				</Row>
			</div>
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		tickets: state.itDepartment.ticket.Tickets,
		loading: state.itDepartment.ticket.getTicketsLoading,
		user_id: state.auth.user_id,
	};
};
export default connect(mapStateToProps, { getTicketsBelongsToUser, clearData })(Support);
