import { faEdit, faHome, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import IndexTable from '../../../../components/IndexTable';
import { Routes } from '../../../../routes';
import { getStartWorks, deleteStartWork } from '../../../../actions/HumanResources/startWorkActions';
import onDelete from '../../../../helpers/deleteFunction';
import { useTranslation } from 'react-i18next';

const StartWorkIndex = (props) => {
	const { datas, loading } = props;
	const { t } = useTranslation();

	const [deleted, setDeleted] = useState(false);

	useEffect(() => {
		props.getStartWorks();
	}, [deleted]);

	const renderRows = () => {
		return datas.startWorks.map((startWork, index) => {
			return (
				<tr key={index}>
					<td className="fw-bold text-center">{startWork.reference}</td>
					<td className="fw-bold text-center">{startWork.employee.full_name}</td>
					<td className="fw-bold text-center">{startWork.work_area}</td>
					<td className="fw-bold text-center">{startWork.work_place}</td>
					<td className="fw-bold text-center">{new Date(startWork.start_date).toLocaleDateString()}</td>
					<td className="fw-bold text-center">{startWork.designation}</td>
					<td className="fw-bold text-center">{startWork.status}</td>
					<td className="fw-bold text-center">
						{startWork.status == t('active-0') ? (
							<>
								<button
									onClick={() => {
										onDelete(deleted, setDeleted, props.deleteStartWork, startWork.id);
									}}
									className="btn btn-danger mx-2">
									<FontAwesomeIcon icon={faTrash} />
								</button>
								<Link
									to={Routes.HumanResources.StartWork.Edit.path.replace(':id', startWork.id)}
									className="btn btn-primary mx-2">
									<FontAwesomeIcon icon={faEdit} />
								</Link>
							</>
						) : null}
					</td>
				</tr>
			);
		});
	};

	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0 ">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.HumanResources.Overview.path }}>
							{t('human-resources')}{' '}
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{t('start-work-forms')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>

				<div
					className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-5
">
					<h4 style={{ display: 'inline' }}>{t('start-works')}</h4>
					<Link className="btn btn-primary py-2 px-3  " to={Routes.HumanResources.StartWork.Create.path}>
						{t('add-new')}{' '}
					</Link>
				</div>
				<p className="mb-0">{t('your-most-recent-joining-forms-can-be-seen-here')}</p>
			</div>

			<IndexTable
				loading={loading}
				headerProps="text-center"
				headers={[
					t('reference'),
					t('employee'),
					t('work-area'),
					t('work-place'),
					t('start-date'),
					t('designation'),
					t('status'),
					'',
				]}
				renderRows={datas.startWorks.length != 0 ? renderRows() : null}
				// filter={
				//     <div className="mb-3">
				//         <TableFilter
				//             initialFilterCategories={initialFilterCategories}
				//             filters={filters}
				//             setFilters={setFilters}
				//             datas={projects}
				//             setQuery={setQuery}
				//             filterCategories={filterCategories}
				//             setFilterCategories={setFilterCategories}
				//         />
				//     </div>
				// }
				// pagination={
				//     projects.length > 1 ? (
				//         <ReactPaginate
				//             nextLabel=" >"
				//             onPageChange={handlePageClick}
				//             pageCount={Math.ceil(_.last(projects).page_count)}
				//             pageRangeDisplayed={3}
				//             previousLabel="< "
				//             pageClassName="page-item"
				//             pageLinkClassName="page-link"
				//             previousClassName="page-item"
				//             forcePage={offset}
				//             previousLinkClassName="page-link"
				//             nextClassName="page-item"
				//             nextLinkClassName="page-link"
				//             breakLabel="..."
				//             breakClassName="page-item"
				//             breakLinkClassName="page-link"
				//             containerClassName="pagination"
				//             activeClassName="active"
				//             renderOnZeroPageCount={null}
				//         />
				//     ) : null
				// }
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		humanResources: {
			startWork: { startWorks, getStartWorksLoading },
		},
	} = state;
	return {
		datas: { startWorks },
		loading: getStartWorksLoading,
	};
};

export default connect(mapStateToProps, { getStartWorks, deleteStartWork })(StartWorkIndex);
