import {
	GET_ROLES,
	GET_USER,
	GET_USERS,
	UPDATE_USER,
	DELETE_USER,
	GET_USER_PROFILE,
	UPDATE_USER_PROFILE,
	GET_USERS_WITH_FILTER,
} from '../../actions/User/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	users: [],
	roles: [],
	user: {},
	userProfile: {},
	getUserProfileLoading: true,
	getUsersLoading: true,
	getUserLoading: true,
	getRolesLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_USERS:
			return { ...state, users: action.payload, getUsersLoading: false };
		case GET_USERS_WITH_FILTER:
			return { ...state, users: action.payload, getUsersLoading: false };
		case GET_USER:
			return { ...state, user: action.payload, getUserLoading: false };

		case UPDATE_USER:
			return { ...state, user: action.payload };

		case GET_USER_PROFILE:
			return {
				...state,
				userProfile: action.payload,
				getUserProfileLoading: false,
			};

		case UPDATE_USER_PROFILE:
			return { ...state };
		case GET_ROLES:
			return { ...state, roles: action.payload, getRolesLoading: false };

		case CLEAR_DATA:
			return initialState;
		case DELETE_USER:
			return { ...state };
		default:
			return state;
	}
};
