import React, { useEffect, useState } from 'react';
import { Field, change, reduxForm } from 'redux-form';
import renderInput from '../../../../../components/formComponents/renderInput';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { maxLength40, required, isSixDigit } from '../../../../../helpers/validator';
import { useTranslation } from 'react-i18next';

export default ({ fields, meta, category_code, change }) => {
	if (fields.length < 1) {
		useEffect(() => {
			fields.push({ item_code: `${category_code}`, item_name: '' });
		}, []);
	}
	const { t } = useTranslation();

	return (
		<>
			{fields.map((parentObjName, index) => {
				return (
					<tr key={index}>
						<td>
							<Field
								name={`${parentObjName}.item_code`}
								inputType={t('number')}
								validate={[isSixDigit]}
								component={renderInput}
								value={100000}
								onChange={(event) => {
									if (!/[0-9]/.test(event.target.value)) {
										event.preventDefault();
									}
								}}
							/>
						</td>
						<td>
							<Field name={`${parentObjName}.item_name`} validate={[required, maxLength40]} component={renderInput} />
						</td>

						<td>
							<button
								className="form-control table-delete-button"
								onClick={(e) => {
									e.preventDefault();

									fields.remove(index);
								}}>
								<i className="fa fa-trash fa-lg " style={{ color: 'red' }} aria-hidden="true"></i>
							</button>
						</td>
					</tr>
				);
			})}

			<tr>
				<td style={{ borderBottom: 0 }}>
					<button
						className="field btn btn-secondary"
						onClick={(e) => {
							e.preventDefault();
							fields.push({ item_code: `${category_code}`, item_name: '' });
						}}>
						{t('add-row')}{' '}
					</button>
				</td>
			</tr>
		</>
	);
};
