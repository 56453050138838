import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getEntities } from '../../../../actions/Admin/entityActions';
import { getProjects } from '../../../../actions//Finance/projectActions';
import { getISRs } from '../../../../actions//Procurement/isrActions';
import { getLPO, updateLPO } from '../../../../actions//Procurement/lpoActions';
import { getSuppliers } from '../../../../actions//Procurement/suppliersActions';
import { clearData } from '../../../../actions/globalActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import LPOForm from './LPOForm';

const LPOEdit = (props) => {
	const { id } = props.match.params;

	const {
		entities,
		getEntitiesLoading,
		projects,
		getProjectsLoading,
		isrs,
		getISRsLoading,
		lpo,
		getLPOLoading,
		suppliers,
		getSuppliersLoading,
	} = props;

	useEffect(() => {
		props.getEntities();
		props.getProjects();
		props.getISRs();
		props.getSuppliers();
		props.getLPO(id);

		return () => {
			props.clearData();
		};
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.updateLPO(formValues);
		}
	};

	return (
		<>
			{getEntitiesLoading || getSuppliersLoading || getProjectsLoading || getISRsLoading || getLPOLoading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<LPOForm
						entities={entities}
						projects={projects}
						suppliers={suppliers}
						isrs={isrs}
						initials={lpo}
						onSubmit={onSubmit}
					/>
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		entities: state.admin.entity.entities,
		getEntitiesLoading: state.admin.entity.getEntitiesLoading,
		projects: state.finance.project.projects,
		getProjectsLoading: state.finance.project.getProjectsLoading,
		isrs: state.procurement.isr.isrs,
		getISRsLoading: state.procurement.isr.getISRsLoading,
		suppliers: state.procurement.supplier.suppliers,
		getSuppliersLoading: state.procurement.supplier.getSuppliersLoading,
		lpo: state.procurement.lpo.lpo,
		getLPOLoading: state.procurement.lpo.getLPOLoading,
	};
};

export default connect(mapStateToProps, {
	getEntities,
	getProjects,
	getISRs,
	getLPO,
	clearData,
	getSuppliers,
	updateLPO,
})(LPOEdit);
