import {
	CREATE_CASH_ADVANCE_REQUEST,
	UPDATE_CASH_ADVANCE_REQUEST,
	GET_CASH_ADVANCE_REQUEST,
	GET_CASH_ADVANCE_REQUESTS,
	DELETE_CASH_ADVANCE_REQUEST,
} from './types';
import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';
export const createCashAdvanceRequest = (formValues) => async (dispatch, getState) => {
	axios
		.post('/cash-advance', formValues)
		.then((response) => {
			dispatch({ type: CREATE_CASH_ADVANCE_REQUEST, payload: response.data });
			history.push(Routes.HumanResources.CashAdvanceRequest.Index.path);
		})
		.catch((err) => {});
};
export const getCashAdvanceRequests = () => async (dispatch) => {
	axios
		.get('/cash-advance')
		.then((response) => {
			dispatch({ type: GET_CASH_ADVANCE_REQUESTS, payload: response.data });
		})
		.catch((err) => {
			console.log(err);
		});
};
export const getCashAdvanceRequestsWithFilter = (offset, limit, query) => async (dispatch) => {
	axios
		.get(`/cash-advance/offset/${offset}/limit/${limit}?${query}`)
		.then((response) => {
			dispatch({ type: GET_CASH_ADVANCE_REQUESTS, payload: response.data });
		})
		.catch((err) => {});
};
export const getCashAdvanceRequest = (id) => async (dispatch) => {
	const response = await axios.get(`/cash-advance/${id}`);
	dispatch({ type: GET_CASH_ADVANCE_REQUEST, payload: response.data });
};
export const deleteCashAdvanceRequest = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`/cash-advance/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_CASH_ADVANCE_REQUEST, payload: response.data });
			setDeleted(!deleted);
		})
		.catch((err) => {});
};
export const updateCashAdvanceRequest = (formValues) => async (dispatch) => {
	axios
		.put(`/cash-advance/${formValues.id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_CASH_ADVANCE_REQUEST, payload: response.data });
			history.push(Routes.HumanResources.CashAdvanceRequest.Index.path);
		})
		.catch((err) => {});
};
