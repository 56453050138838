import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import StartWorkForm from './StartWorkForm';
import { getStartWork, updateStartWork } from '../../../../actions/HumanResources/startWorkActions';
import { clearData } from '../../../../actions/globalActions';
import { getEmployees } from '../../../../actions/HumanResources/employeeActions';
import { getUsers } from '../../../../actions/User/userActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';

const StartWorkEdit = (props) => {
	const { id } = props.match.params;

	const { datas, loading } = props;

	useEffect(() => {
		props.getStartWork(id);
		props.getEmployees();
		props.getUsers();

		return () => {
			props.clearData();
		};
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.updateStartWork(formValues);
		}
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<StartWorkForm datas={datas} onSubmit={onSubmit} initials={datas.startWork} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		user: { users, getUsersLoading },
		humanResources: {
			employee: { employees, getEmployeesLoading },
			startWork: { startWork, getStartWorkLoading },
		},
	} = state;

	return {
		datas: { users, employees, startWork },
		loading: getUsersLoading || getEmployeesLoading || getStartWorkLoading,
	};
};

export default connect(mapStateToProps, { getEmployees, getStartWork, getUsers, updateStartWork, clearData })(
	StartWorkEdit
);
