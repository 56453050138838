import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Nav } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import history from '../../../../history';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import IndexTable from '../../../../components/IndexTable';
import { Routes } from '../../../../routes';

import { deleteUser, getUsersWithFilter } from '../../../../actions//User/userActions';
import onDelete from '../../../../helpers/deleteFunction';

import { clearData } from '../../../../actions/globalActions';

import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';

const UserIndex = (props) => {
	const {
		user: { users },
		loading,
	} = props;
	const { t } = useTranslation();
	const [deleted, setDeleted] = useState(false);
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [query, setQuery] = useState('');
	const [order, setOrder] = useState('');
	const [search, setSearch] = useState('');

	useEffect(() => {
		props.getUsersWithFilter(offset, limit, query, order, search);

		return () => {
			props.clearData();
		};
	}, [deleted, limit, offset, query, order, search]);

	// REACT PAGINATION ON CLICK FUNCTION
	const handlePageClick = (event) => {
		setOffset(event.selected);
	};

	const statusColor = (status) => {
		switch (status.toLowerCase()) {
			case 'active':
				return 'success';
			case 'inactive':
				return 'danger';
			case 'in-leave':
				return 'warning';
			default:
				return 'danger';
		}
	};

	const renderRows = () => {
		return users.map((value, index) => {
			if (index != users.length - 1) {
				return (
					<tr key={index + Math.random()}>
						<td className="fw-bold text-center ">
							<div className="user-avatar rounded-circle mx-auto   bg-primary text-white">
								{value.first_name[0]}
								{value.last_name[0]}
							</div>
						</td>
						<td className="fw-bold text-left">
							{value.first_name + ' ' + value.last_name}
							<br />
							<span className={`text-${statusColor(value.status)} text-left`}>● </span>
							<small>{value.email}</small>
						</td>
						<td className="fw-bold text-left">
							{value.companies.map((val, index) => {
								return (
									<React.Fragment key={index}>
										{val.company_name} <br />
									</React.Fragment>
								);
							})}
						</td>
						<td className="fw-bold text-left">
							{value.roles.map((val, index) => {
								return (
									<React.Fragment key={index}>
										{val.role_name} <br />
									</React.Fragment>
								);
							})}
						</td>
						<td className="fw-bold text-center">
							<button
								onClick={() => {
									onDelete(deleted, setDeleted, props.deleteUser, value.user_id);
								}}
								className="btn btn-danger mx-2">
								<i className="fa fa-trash  "></i>
							</button>
							<Link to={Routes.Admin.User.Edit.path.replace(':id', value.user_id)} className="btn btn-primary mx-2">
								<i className="fa fa-edit"></i>
							</Link>
						</td>
					</tr>
				);
			} else {
				return null;
			}
		});
	};

	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0 ">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{t('users')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<Nav className="justify-content-center row" variant="tabs" defaultActiveKey={Routes.Admin.User.Index.path}>
					<Nav.Item className="col text-center">
						<Nav.Link
							className="bg-primary text-white"
							onClick={() => {
								history.push(Routes.Admin.User.Index.path);
							}}>
							{t('users')}{' '}
						</Nav.Link>
					</Nav.Item>
					<Nav.Item className="col text-center ">
						<Nav.Link
							onClick={() => {
								history.push(Routes.Admin.Entity.Index.path);
							}}>
							{t('companies')}{' '}
						</Nav.Link>
					</Nav.Item>
					<Nav.Item className="col text-center ">
						<Nav.Link
							onClick={() => {
								history.push(Routes.Admin.Invite.Index.path);
							}}>
							{t('user-invites')}{' '}
						</Nav.Link>
					</Nav.Item>
					<Nav.Item className="col text-center ">
						<Nav.Link
							onClick={() => {
								history.push(Routes.Admin.Department.Index.path);
							}}>
							{t('departments-0')}{' '}
						</Nav.Link>
					</Nav.Item>
				</Nav>
				<br />
				<div
					className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center
          ">
					<h4 style={{ display: 'inline' }}>{t('users')}</h4>
				</div>
				<p className="mb-0">{t('user-list')}</p>
			</div>

			<IndexTable
				loading={loading}
				headerProps="text-left"
				// headers={['', 'Full Name', 'Company', 'Role', '']}
				headers={[
					'',
					{ label: t('full-name'), value: 'first_name', sortable: true },
					{ label: t('company'), value: 'company', sortable: false },
					{ label: t('role'), value: 'role', sortable: false },
					'',
				]}
				setOrder={setOrder}
				searchable
				setSearch={setSearch}
				renderRows={users.length != 0 ? renderRows() : null}
				pagination={
					users.length > 1 ? (
						<ReactPaginate
							nextLabel=" >"
							onPageChange={handlePageClick}
							pageCount={Math.ceil(_.last(users).page_count)}
							pageRangeDisplayed={3}
							previousLabel="< "
							pageClassName="page-item"
							pageLinkClassName="page-link"
							previousClassName="page-item"
							forcePage={offset}
							previousLinkClassName="page-link"
							nextClassName="page-item"
							nextLinkClassName="page-link"
							breakLabel="..."
							breakClassName="page-item"
							breakLinkClassName="page-link"
							containerClassName="pagination"
							activeClassName="active"
							renderOnZeroPageCount={null}
						/>
					) : null
				}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	return { user: state.user, loading: state.user.getUsersLoading };
};

export default connect(mapStateToProps, { getUsersWithFilter, deleteUser, clearData })(UserIndex);
