import React, { useEffect } from 'react';
import TicketForm from './TicketForm';
import { connect } from 'react-redux';

import { createTicket, getTicketCategories } from '../../../actions//ITDepartment/ticketActions';
import { clearData } from '../../../actions/globalActions';
const TicketCreate = (props) => {
	const {
		user_id,
		company,
		loading,
		name,
		title,
		department: department,
		category: category,
		issue,
		error_message,
		severity,
		company_id,
		assigned_to,
		ticketCategories,
		getTicketCategoriesLoading,
	} = props;
	const initials = () => {};

	useEffect(() => {
		props.getTicketCategories();
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			props.createTicket(formValues);
		}
	};
	if (loading && getTicketCategoriesLoading) {
		return (
			<div className="d-flex justify-content-center ">
				<div className="spinner-border " role="status">
					<span className="visually-hidden">Loading...</span>
				</div>
			</div>
		);
	} else {
		return (
			<div className="mt-3">
				<TicketForm
					onSubmit={onSubmit}
					initials={{
						by: name,
						companies: company,
						title: title,
						categories: ticketCategories,
						issue: issue,
						error_message: error_message,
						severity: severity,
						user_id: user_id,
						assigned_to: assigned_to,
					}}
				/>
			</div>
		);
	}
};
const mapStateToProps = (state) => {
	return {
		name: state.auth.full_name,
		company: state.auth.companies,
		user_id: state.auth.user_id,
		ticketCategories: state.itDepartment.ticket.TicketCategories,
		getTicketCategoriesLoading: state.itDepartment.ticket.getTicketCategoriesLoading,
	};
};
export default connect(mapStateToProps, { createTicket, getTicketCategories })(TicketCreate);
