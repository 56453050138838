import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import _ from 'lodash';
import { getLocations } from '../../../../actions/Construction/locationsActions';
import { getPunchList, updatePunchList } from '../../../../actions/Construction/punchListActions';
import { clearData } from '../../../../actions/globalActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import PunchListForm from './PunchListForm';

const PunchListEdit = (props) => {
	const { id } = props.match.params;
	const { datas, loading } = props;
	useEffect(() => {
		props.getLocations();
		props.getPunchList(id);
		return () => {
			props.clearData();
		};
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			props.updatePunchList(formValues);
		}
	};
	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<PunchListForm onSubmit={onSubmit} datas={datas} initials={datas.punchList} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		punchList: { punchList, getPunchListLoading },
		construction: {
			locations: { locations, getLocationsLoading },
		},
	} = state;

	return {
		datas: {
			punchList,
			locations: locations,
		},
		loading: getPunchListLoading,
	};
};

export default connect(mapStateToProps, {
	getPunchList,
	clearData,
	updatePunchList,
	getLocations,
})(PunchListEdit);
