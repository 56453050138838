import {
	CREATE_TERMINATION,
	GET_TERMINATIONS,
	GET_TERMINATION,
	DELETE_TERMINATION,
} from '../../actions/HumanResources/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	terminations: [],
	termination: {},
	loading: true,
	getTerminationLoading: true,
	getTerminationsLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_TERMINATIONS:
			return { ...state, terminations: action.payload, getTerminationsLoading: false };

		case GET_TERMINATION:
			return { ...state, termination: action.payload, getTerminationLoading: false };

		case CREATE_TERMINATION:
			return { ...state };

		case CLEAR_DATA:
			return {
				...state,
				termination: action.payload,
				getTerminationLoading: true,
				getTerminationsLoading: true,
			};

		case DELETE_TERMINATION:
			return { ...state };

		default:
			return state;
	}
};
