import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Report from '../Reports/Report';
import { getFinanceOverview } from '../../../actions//Others/powerbiActions';

const FinanceOverview = (props) => {
	return (
		<>
			<Report getEmbedInfo={props.getFinanceOverview} />
		</>
	);
};

export default connect(null, { getFinanceOverview })(FinanceOverview);
