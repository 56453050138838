import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Col, Row } from '@themesberg/react-bootstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SprintBurndown from '../../../img/sprint-burndown.svg';
import SprintBurnup from '../../../img/sprint-burnup.svg';
import { Routes } from '../../../routes';
import InsightsCard from './InsightsCard';

const Insights = () => {
	const { t } = useTranslation();
	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0 ">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{t('insights')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<div
					className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center
          ">
					<h4 style={{ display: 'inline' }}>{t('insights')}</h4>
				</div>
				<p className="mb-0">{t('track-information-connect-your-team-and-solve-issues')}</p>
			</div>
			<Row>
				<Col md={4} className="pb-4">
					<InsightsCard
						cardName={t('procurement-overview')}
						desc={t('isr-and-lpo-dashboards')}
						imageName={SprintBurndown}
						link={Routes.Insights.ProcurementOverview.path}
					/>
				</Col>
				<Col md={4} className="pb-4">
					<InsightsCard
						cardName={t('human-resources-overview')}
						desc={t('review-human-resources-entries-and-workflows')}
						imageName={SprintBurnup}
						link={Routes.Insights.HumanResourcesOverview.path}
					/>
				</Col>
				{/* <Col md={4} className="pb-4">
					<InsightsCard
						cardName="Finance Overview"
						desc="Review Financial data"
						link={Routes.Insights.FinanceOverview.path}
					/>
				</Col> */}
				<Col md={4} className="pb-4">
					<InsightsCard
						cardName={t('project-management-overview')}
						desc={t('review-the-project-statuses-and-progress')}
						link={Routes.Insights.ProjectManagementOverview.path}
					/>
				</Col>
				<Col md={4} className="pb-4">
					<InsightsCard
						cardName={t('transportation-overview')}
						desc={t('review-transportation-spendings')}
						link={Routes.Insights.TransportationOverview.path}
					/>
				</Col>
				<Col md={4} className="pb-4">
					<InsightsCard
						cardName={t('punch-list-reports')}
						desc={t('real-time-insights-for-complete-project-oversight')}
						link={Routes.Insights.PunchListOverview.path}
					/>
				</Col>
			</Row>
		</>
	);
};

export default Insights;
