import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

export const required = (value) => (value ? undefined : t('this-field-is-required'));

export const nonZero = (value) => (value != '0' ? undefined : t('this-field-is-required'));

export const notEmpty = (value) => (value && value.length != 0 ? undefined : t('this-field-is-required'));

export const maxLength = (max) => (value) =>
	value && value.length > max ? `${t('must-be')} ${max} ${i18n.t('max')}` : undefined;

export const maxLengthOfMultiSelect = (max) => (value) =>
	_.find(value, (e) => (e && e.length > max ? true : false)) ? `${t('must-be')} ${max} ${t('max')}` : undefined;

export const maxLengthMultiSelect40 = maxLengthOfMultiSelect(40);

export const maxLength40 = maxLength(40);
export const maxLength3 = maxLength(3);
export const maxLength128 = maxLength(128);

export const minLength = (min) => (value) =>
	value && value.length < min ? `${t('must-be')} ${max} ${t('min')}` : undefined;

export const number = (value) =>
	value && isNaN(Number(value)) ? `${t('must-be')} ${max} ${t('number-validate')}` : undefined;

export const minValue = (min) => (value) =>
	value && value < min ? `${t('must-be')} ${t('at-least-validate')} ${min}` : undefined;

export const maxValue = (max) => (value) =>
	value && value > max ? `${t('must-be')} ${t('smaller-validate')} ${max}` : undefined;

export const email = (value) =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? `${t('email-validate')}` : undefined;

export const alphaNumeric = (value) =>
	value && /[^a-zA-Z0-9 ]/i.test(value) ? `${t('alphanumeric-validate')}` : undefined;

export const phoneNumber = (value) => (value && !/\+?\d+/i.test(value) ? `${t('phone-number-validate')}` : undefined);

export const date = (value) =>
	!value ||
	new Date(value).getFullYear() - new Date().getFullYear() > 20 ||
	new Date().getFullYear() - new Date(value).getFullYear() > 20
		? `${t('date-validate')}`
		: undefined;

export const isSixDigit = (value) => (value <= 999999 && value >= 100000 ? undefined : `${t('six-digit-validate')}`);
