import React from 'react';
import { connect } from 'react-redux';

import ItemForm from './ItemForm';

import { createItem } from '../../../../../actions/Delivery/deliveryActions';

class ItemCreate extends React.Component {
	onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			this.props.createItem(formValues);
		}
	};

	render() {
		return (
			<div className="mt-3">
				<ItemForm onSubmit={this.onSubmit} categories={this.props.categories} loading={this.props.loading} />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		loading: state.delivery.item.getItemsLoading,
		categories: state.delivery.item.categories,
	};
};

export default connect(mapStateToProps, { createItem })(ItemCreate);
