import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Card, Col, Row } from '@themesberg/react-bootstrap';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { change, Field, initialize, reduxForm } from 'redux-form';
import renderDropdownWithAutoComplete from '../../../../components/formComponents/renderDropdownWithAutoComplete';
import renderInput from '../../../../components/formComponents/renderInput';
import renderSearchableDropdown from '../../../../components/formComponents/renderSearchableDropdown';
import renderTextArea from '../../../../components/formComponents/renderTextArea';
import SubmitButton from '../../../../components/formComponents/SubmitButton';
import { Routes } from '../../../../routes';
import validate from '../../../../validate';

const SalaryDeductionForm = (props) => {
	const { initials, onSubmit, user_id, full_name, companies, datas } = props;
	const { t } = useTranslation();

	useEffect(() => {
		if (initials) {
			props.initialize({
				...initials,
				full_name: full_name,
				date: new Date(initials.date).toISOString().split('T')[0],
			});
		} else {
			props.initialize({
				full_name: full_name,
				company_id: companies[0].company_id,
				date: new Date().toISOString().split('T')[0],
			});
			props.change('user_id', user_id);
		}
	}, []);

	return (
		<>
			<div className="d-block mb-4 mb-xl-0 ">
				<Breadcrumb
					className=" d-block"
					listProps={{
						className: 'breadcrumb-dark breadcrumb-transparent',
					}}>
					<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
						<FontAwesomeIcon icon={faHome} />
					</Breadcrumb.Item>
					<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.HumanResources.Overview.path }}>
						{t('human-resources')}{' '}
					</Breadcrumb.Item>

					<Breadcrumb.Item active>{t('salary-deductions-0')}</Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<form onSubmit={props.handleSubmit(onSubmit)}>
				<Row>
					<Col md={12}>
						<Card border="light" className="bg-white shadow-sm mb-4  w-100">
							<Card.Body>
								<h5 className="mb-4">{t('salary-deduction-form')}</h5>
								<Row className="mb-3 ">
									<Col md={6} sm={12}>
										<Field name="full_name" label={t('by')} component={renderInput} disabled="disabled" />
									</Col>
									<Col md={6} sm={12}>
										<Field
											data={companies}
											dataKey="company_id"
											textField="company_name"
											name="company_id"
											label={t('company')}
											component={renderSearchableDropdown}
										/>
									</Col>
								</Row>
								<hr />
								<Row className="mb-3">
									<Col>
										<Field
											name="employee_id"
											label={t('employee')}
											dataKey="employee_id"
											textField="full_name"
											data={datas.employees}
											component={renderDropdownWithAutoComplete}
											onChange={(val) => {
												const selectedEmployee = _.find(datas.employees, { employee_id: val });

												props.change(
													'reference',
													`HR/003/${String(selectedEmployee.employee_number).padStart(3, '0')}/${
														new Date().getMonth() + 1
													}${new Date().getDate()}${new Date().getMilliseconds()}`
												);
											}}
										/>
									</Col>
									<Col>
										<Field name="designation" label={t('designation')} component={renderInput} />
									</Col>
								</Row>

								<Row className="mb-3">
									<Col>
										<Field name="total_salary" label={t('total-salary')} component={renderInput} />
									</Col>
									<Col>
										<Field name="date" label={t('date-0')} component={renderInput} inputType="date" />
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field name="reason" rows={4} label={t('reason')} component={renderTextArea} />
									</Col>
									<Col>
										<Field disabled="disabled" name="reference" label={t('reference')} component={renderInput} />
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field name="deducted_amount" label={t('amount-to-be-deducted')} component={renderInput} />
									</Col>
									<Col>
										<Field name="salary_month" label={t('for-the-salary-month-of')} component={renderInput} />
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field name="hr_approver" label={t('hr-approver')} component={renderInput} />
									</Col>
									<Col>
										<Field
											name="general_manager_approver"
											label={t('general-manager-approver')}
											component={renderInput}
										/>
									</Col>
								</Row>

								<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
									<Link to={Routes.HumanResources.SalaryDeduction.Index.path} className="btn btn-secondary">
										{t('back')}{' '}
									</Link>
									<SubmitButton text={t('submit')} onSubmit={props.onSubmit} invalid={false} />
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</form>
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		auth: { user_id, full_name, companies },
	} = state;

	return {
		user_id,
		full_name,
		companies,
	};
};

export default compose(
	connect(mapStateToProps, { initialize, change }),
	reduxForm({ form: 'salaryDeduction', validate })
)(SalaryDeductionForm);
