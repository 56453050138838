import { Card, Col, Row, Dropdown } from '@themesberg/react-bootstrap';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getNotifications } from '../../actions/User/notificationActions';
import LoadingSpinner from '../../components/LoadingSpinner';
import history from '../../history';
import { Routes } from '../../routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faBell, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
const Notification = (props) => {
	const { notifications, getNotificationsLoading, auth } = props;

	useEffect(() => {
		props.getNotifications(auth.user_id);
	}, []);
	const { t } = useTranslation();

	const NotificationItem = ({ from, createdAt, description, type, title, read }) => {
		return (
			<Card className="hover-state rounded-0 py-3 border-bottom border-light">
				<div className="d-flex align-items-center justify-content-between flex-wrap flex-lg-nowrap py-0 card-body">
					<div className="ps-0 col-lg-2 col-10">
						<Card.Link className="d-flex align-items-center">
							{type?.toLowerCase() == 'error' ? (
								<div className="user-avatar rounded-circle me-3 text-white" style={{ background: 'red' }}>
									<FontAwesomeIcon icon={faTimes} />
								</div>
							) : type?.toLowerCase() == 'success' ? (
								<div className="user-avatar rounded-circle me-3 text-white" style={{ background: 'green' }}>
									<FontAwesomeIcon icon={faCheck} />
								</div>
							) : type?.toLowerCase() == 'warning' ? (
								<div className="user-avatar rounded-circle me-3 text-white" style={{ background: '#ffd500' }}>
									<FontAwesomeIcon icon={faExclamation} />
								</div>
							) : from ? (
								<div className="user-avatar rounded-circle me-3   bg-primary text-white">
									{from.split(' ')[0][0]}
									{from.split(' ')[1][0]}
								</div>
							) : (
								<div className="user-avatar rounded-circle me-3   bg-primary text-white">
									<FontAwesomeIcon icon={faBell} />
								</div>
							)}
							<div className={`${read ? 'text-muted' : ''}`}>
								{from ? (
									<span className="fw-bold h6 mb-0">{from}</span>
								) : title ? (
									<span className="fw-bold h6 mb-0">{title}</span>
								) : null}
							</div>
						</Card.Link>
					</div>
					<div className="d-flex align-items-center justify-content-end px-0 order-lg-4 col-lg-2 col-2">
						<div className=" small d-none d-lg-block">{new Date(createdAt).toLocaleDateString()}</div>
					</div>
					<Col md={12} className="d-flex align-items-center mt-3 mt-lg-0 ps-0 col-lg-7">
						<span className="fw-bold ps-lg-3">{description}</span>
					</Col>
				</div>
			</Card>
		);
	};

	const renderNotifications = () => {
		return notifications.map((notification, index) => {
			return <NotificationItem key={index} {...notification} />;
		});
	};

	renderNotifications();

	return (
		<>
			<div className="py-4">
				{getNotificationsLoading ? (
					<LoadingSpinner />
				) : (
					<Row>
						<Col md={12}>
							<div
								className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center
          ">
								<h4 style={{ display: 'inline' }}>{t('notifications')}</h4>
							</div>
							<div className="message-wrapper border bg-white border-light shadow-sm py-1 rounded">
								{renderNotifications()}
								<div className="p-3">
									<button
										className="btn btn-secondary"
										onClick={() => {
											history.goBack();
										}}>
										{t('back')}{' '}
									</button>
								</div>
							</div>
						</Col>
					</Row>
				)}
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		notifications: state.notification.notifications,
		getNotificationsLoading: state.notification.getNotificationsLoading,
	};
};

export default connect(mapStateToProps, { getNotifications })(Notification);
