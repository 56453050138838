import React, { useEffect } from 'react';
import LocationForm from './LocationForm';
import { updateLocation, getLocation } from '../../../../../actions/Delivery/locationActions';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import { clearData } from '../../../../../actions/globalActions';

const LocationEdit = (props) => {
	const { id } = props.match.params;
	const { datas, loading } = props;

	useEffect(() => {
		props.getLocation(id);

		return () => {
			props.clearData();
		};
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.updateLocation(formValues);
		}
	};

	return (
		<div className="mt-3">
			{loading ? <LoadingSpinner /> : <LocationForm initials={datas.location} onSubmit={onSubmit} />}
		</div>
	);
};

const mapStateToProps = (state) => {
	const {
		delivery: {
			location: { location, getLocationLoading },
		},
	} = state;

	return {
		datas: { location },
		loading: getLocationLoading,
	};
};

export default connect(mapStateToProps, { updateLocation, getLocation, clearData })(LocationEdit);
