import { Card, Col, Row } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, Field, FieldArray, formValueSelector, getFormMeta, initialize, reduxForm } from 'redux-form';
import renderDropdown from '../../../../components/formComponents/renderDropdown';
import renderInput from '../../../../components/formComponents/renderInput';
import SubmitButton from '../../../../components/formComponents/SubmitButton';
import { changeCompanyStructure } from '../../../../helpers/helpers';
import { Policies } from '../../../../helpers/policies';
import renderContentTableInput from '../../Procurement/ISR/ISRFormComponents/renderContentTableInput';

import _ from 'lodash';
import FileUpload from '../../../../components/FileUpload';
import renderSearchableDropdown from '../../../../components/formComponents/renderSearchableDropdown';
import { date, nonZero, required } from '../../../../helpers/validator';
import { useTranslation } from 'react-i18next';

const selector = formValueSelector('punchList');

const PunchListForm = (props) => {
	const { t } = useTranslation();
	const {
		title,
		building_id,
		place_id,
		floor_id,
		contentValues,
		initials,
		invalid,
		onSubmit,
		auth,
		datas,
		entities,
		punch_list_contents,
		locations,
		project,
	} = props;

	const [selectedBuilding, setSelectedBuilding] = useState(initials?.punch_list?.building_id);
	const [selectedFloor, setSelectedFloor] = useState(initials?.punch_list?.floor_id);

	useEffect(() => {
		{
			initials?.punch_list != null
				? props.initialize({
						...initials?.punch_list,
						user: auth.first_name + ' ' + auth.last_name,

						date: new Date(initials.punch_list.date).toISOString().split('T')[0],

						punch_list_contents:
							initials.punch_list.punch_list_contents == 0
								? [
										{
											number: '',
											location_building: '',
											location_place: '',
											location_floor: '',
											punch_description: '',
											controller: '',
											start_date: '',
											end_date: '',
											status: '',
											contractor_name: '',
											subcontractor_name: '',
											consultant_name: '',
										},
								  ]
								: initials.punch_list.punch_list_contents.map((content, index) => {
										initials.punch_list.punch_list_contents[index].start_date = new Date(content.start_date)
											.toISOString()
											.split('T')[0];
										initials.punch_list.punch_list_contents[index].end_date = new Date(content.end_date)
											.toISOString()
											.split('T')[0];
										return initials.punch_list.punch_list_contents[index];
								  }),
				  })
				: props.initialize({
						user: auth.first_name + ' ' + auth.last_name,
				  });
		}

		props.change('user_id', auth.user_id);
	}, []);

	const renderCompanies = () => {
		if (auth.policies.ISR.actions.includes(Policies.ISR.VIEW_SELF)) {
			return auth.companies.map((company, index) => {
				return (
					<option key={index} value={company.company_id}>
						{company.company_name}
					</option>
				);
			});
		} else if (auth.policies.ISR.actions.includes(Policies.ISR.VIEW_ALL)) {
			return entities.map((company, index) => {
				return (
					<option key={index} value={company.company_id}>
						{company.company_name}
					</option>
				);
			});
		} else if (auth.policies.ISR.actions.includes(Policies.ISR.VIEW_COMPANY)) {
			return changeCompanyStructure(auth.policies.ISR.companies, entities).map((company, index) => {
				return (
					<option key={index} value={company.company_id}>
						{company.company_name}
					</option>
				);
			});
		} else {
			return null;
		}
	};

	useEffect(() => {
		props.change('isValid', !invalid);
	}, [invalid]);

	return (
		<form onSubmit={props.handleSubmit(onSubmit)}>
			<Row>
				<Col xl={12} lg={12} md={12} sm={12} xs={12}>
					<Card border="light" className="bg-white shadow-sm mb-4  w-100">
						<Card.Body>
							<h5 className="mb-4">{t('punch-list-form')}</h5>
							<Row className="mb-3 ">
								<Col md={6} sm={12}>
									<Field name="user" label={t('by')} component={renderInput} disabled="disabled" />
								</Col>
								<Col md={6} sm={12}>
									<Field
										name="company_id"
										label={t('company')}
										validate={nonZero}
										component={renderDropdown}
										renderedOptions={renderCompanies()}
									/>
								</Col>
							</Row>
							<hr />
							<Row className="mb-3 ">
								<Col md={6} sm={12}>
									<Field name="title" label={t('title')} component={renderInput} />
								</Col>
								<Col md={6} sm={12}>
									<Field name="project" label={t('project-name')} component={renderInput} />
								</Col>
							</Row>
							<Row className="mb-3">
								<Col md={6} sm={12}>
									<Field name="contractor" label={t('contractor')} component={renderInput} />
								</Col>
								<Col lg={6} md={12}>
									<Field name="number" label={t('number')} component={renderInput} />
								</Col>
							</Row>
							<Row className="mb-3">
								<Col md={6} sm={12}>
									<Field name="contract_title" label={t('contract-title')} component={renderInput} />
								</Col>
								<Col lg={6} md={12}>
									<Field name="revision" label={t('revision')} component={renderInput} />
								</Col>
							</Row>
							<Row className="mb-3">
								<Col lg={6} md={12}>
									<Field name="issued_by" label={t('issued-by')} component={renderInput} />
								</Col>
								<Col lg={6} md={12}>
									<Field
										validate={[required, date]}
										name="date"
										inputType="date"
										label={t('date-0')}
										component={renderInput}
									/>
								</Col>
							</Row>
							<hr />
							<h5 className="mb-4">{t('location-and-reference')}</h5>
							<Row className="mb-3">
								<Col lg={6} md={12}>
									<Field
										name="building_id"
										label={t('building')}
										dataKey="building_id"
										textField="building_name"
										component={renderSearchableDropdown}
										data={datas.locations}
										onChange={(e) => {
											setSelectedBuilding(e);
											props.change('floor_id', null);
											props.change('place_id', null);
										}}
									/>
								</Col>
								<Col lg={6} md={12}>
									<Field
										dataKey="floor_id"
										textField="floor_name"
										name="floor_id"
										label={t('floor')}
										component={renderSearchableDropdown}
										data={
											_.find(datas.locations, { building_id: selectedBuilding })
												? _.find(datas.locations, { building_id: selectedBuilding }).construction_floors
												: []
										}
										onChange={(e) => {
											setSelectedFloor(e);
											props.change('place_id', null);
										}}
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col lg={6} md={12}>
									<Field
										dataKey="place_id"
										textField="place_name"
										name="place_id"
										label={t('place')}
										component={renderSearchableDropdown}
										data={
											_.find(datas.locations, { building_id: selectedBuilding })
												? _.find(_.find(datas.locations, { building_id: selectedBuilding }).construction_floors, {
														floor_id: selectedFloor,
												  })
													? _.find(_.find(datas.locations, { building_id: selectedBuilding }).construction_floors, {
															floor_id: selectedFloor,
													  }).construction_places
													: []
												: []
										}
									/>
								</Col>
								<Col md={6} sm={12}>
									<Field name="reference_number" label={t('reference-number')} component={renderInput} />
								</Col>
							</Row>
							<hr />
							<h5 className="mb-4">{t('authors-for-the-list')}</h5>
							<Row className="mb-3">
								<Col md={6} sm={12}>
									<Field name="author_employer" label={t('employer')} component={renderInput} />
								</Col>
								<Col lg={6} md={12}>
									<Field name="author_company" label={t('company')} component={renderInput} />
								</Col>
							</Row>
							<Row className="mb-3">
								<Col lg={6} md={12}>
									<Field name="author_consultant" label={t('consultant')} component={renderInput} />
								</Col>
								<Col lg={6} md={12}>
									<Field name="general_contractor" label={t('general-contractor')} component={renderInput} />
								</Col>
							</Row>
							{/* <Row className="mb-3">
								<Col lg={6} md={12}>
									<Field name="status" label="Status" component={renderInput} />
								</Col>
							</Row> */}
							<hr />
							<h5 className="mb-4">{t('file-upload')}</h5>
							<Row className="mb-3">
								<Col md={12} sm={12}>
									<FileUpload title={title} building_id={building_id} floor_id={floor_id} place_id={place_id} />
								</Col>
							</Row>
							<hr />
							<h5 className="mb-4">{t('punch-list')}</h5>
							<Col className="mb-3">
								<div className="table-responsive">
									<table className="table " style={{ width: 'auto' }}>
										<thead>
											<tr className="text-start text-wrap">
												<th style={{ width: '10%' }}>#</th>
												<th style={{ width: '10%' }}>{t('building')}</th>
												<th style={{ width: '10%' }}>{t('place')}</th>
												<th style={{ width: '10%' }}>{t('floor')}</th>
												<th style={{ width: '5%' }}>{t('punch-description')}</th>
												<th style={{ width: '5%' }}>{t('discipline')}</th>
												<th style={{ width: '10%' }}>{t('controller')}</th>
												<th style={{ width: '10%' }}>{t('start-date')}</th>
												<th style={{ width: '10%' }}>{t('end-date')}</th>
												<th style={{ width: '5%' }}>{t('status')}</th>
												<th style={{ width: '10%' }}>{t('contractor')}</th>
												<th style={{ width: '10%' }}>{t('subcontractor')}</th>
												<th style={{ width: '5%' }}>{t('consultant-0')}</th>
												<th style={{ width: '5%' }}></th>
											</tr>
										</thead>
										<tbody className="">
											<FieldArray
												name="punch_list_contents"
												change={props.change}
												component={renderContentTableInput}
												contentValues={contentValues}
												inputNames={[
													{ name: 'number', col: 1 },
													{ name: 'location_building', col: 1 },
													{ name: 'location_place', col: 1 },
													{ name: 'location_floor', col: 1 },
													{ name: 'punch_description', col: 1 },
													{ name: 'discipline', col: 1 },
													{ name: 'controller', col: 1 },
													{ name: 'start_date', col: 1 },
													{ name: 'end_date', col: 1 },
													{ name: 'status', col: 1 },
													{ name: 'contractor_name', col: 1 },
													{ name: 'subcontractor_name', col: 1 },
													{ name: 'consultant_name', col: 1 },
												]}
												types={[
													'text',
													'text',
													'text',
													'text',
													'text',
													'text',
													'text',
													'date',
													'date',
													'text',
													'text',
													'text',
													'text',
												]}
											/>
										</tbody>
									</table>
								</div>
							</Col>

							<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
								<a onClick={() => window.history.back()} className="btn btn-secondary">
									{t('back')}{' '}
								</a>
								<SubmitButton text={t('submit')} onSubmit={props.onSubmit} invalid={invalid} />
							</div>
						</Card.Body>
					</Card>
				</Col>
				<Col xl={4} lg={4} md={12} sm={12} xs={12}></Col>
			</Row>
		</form>
	);
};

const mapStateToProps = (state) => {
	const value = selector(state, 'punch_list_contents');
	const title = selector(state, 'title');
	const building_id = selector(state, 'building_id');
	const place_id = selector(state, 'place_id');
	const floor_id = selector(state, 'floor_id');

	return {
		title,
		building_id,
		place_id,
		floor_id,
		contentValues: value,
		auth: state.auth,
		locations: state.construction.locations,
		punchList: state.punchList,
		punch_list_contents: state.punchList.punch_list_contents,
		entities: state.admin.entity.entities,
		meta: getFormMeta('punchList')(state),
	};
};

export default compose(
	connect(mapStateToProps, { change, initialize }),

	reduxForm({
		form: 'punchList',
	})
)(PunchListForm);
