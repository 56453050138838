import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Card, Col, Row } from '@themesberg/react-bootstrap';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { change, Field, initialize, reduxForm } from 'redux-form';
import renderDropdownWithAutoComplete from '../../../../components/formComponents/renderDropdownWithAutoComplete';
import renderInput from '../../../../components/formComponents/renderInput';
import renderTextArea from '../../../../components/formComponents/renderTextArea';
import SubmitButton from '../../../../components/formComponents/SubmitButton';
import { Routes } from '../../../../routes';

const TerminationForm = (props) => {
	const { datas, initials, full_name, user_id, companies, onSubmit } = props;
	const { t } = useTranslation();

	useEffect(() => {
		if (initials) {
			props.initialize({
				...initials,
				joining_date: new Date(initials.employee.start_date).toISOString().split('T')[0],
				by: full_name,
				date_of_request: new Date(initials.date_of_request).toISOString().split('T')[0],
				effective_date: new Date(initials.effective_date).toISOString().split('T')[0],
				position: initials.employee.job_title,
				project_department: _.find(datas.employees, { employee_id: initials.employee.employee_id }).department
					.department_name,
			});
		} else {
			props.initialize({
				start_date: new Date().toISOString().split('T')[0],
				by: full_name,
				company_id: companies[0].company_id,
				date_of_request: new Date().toISOString().split('T')[0],
			});
		}
		props.change('user_id', user_id);
	}, []);

	return (
		<>
			<div className="d-block mb-4 mb-xl-0 ">
				<Breadcrumb
					className=" d-block"
					listProps={{
						className: 'breadcrumb-dark breadcrumb-transparent',
					}}>
					<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
						<FontAwesomeIcon icon={faHome} />
					</Breadcrumb.Item>
					<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.HumanResources.Overview.path }}>
						{t('human-resources')}{' '}
					</Breadcrumb.Item>
					<Breadcrumb.Item active>{t('termination-forms')}</Breadcrumb.Item>
				</Breadcrumb>
			</div>

			<form onSubmit={props.handleSubmit(onSubmit)}>
				<Row>
					<Col md={12}>
						<Card border="light" className="bg-white shadow-sm mb-4  w-100">
							<Card.Body>
								<h5 className="mb-4">{t('termination-form')}</h5>
								<Row className="mb-3 ">
									<Col md={6} sm={12}>
										<Field name="by" label={t('by')} component={renderInput} disabled="disabled" />
									</Col>
									<Col md={6} sm={12}>
										<Field
											data={companies}
											dataKey="company_id"
											textField="company_name"
											name="company_id"
											label={t('company')}
											component={renderDropdownWithAutoComplete}
										/>
									</Col>
								</Row>
								<hr />

								<Row className="mb-3">
									<Col>
										<Field
											name="employee_id"
											label={t('employee')}
											dataKey="employee_id"
											textField="full_name"
											data={datas.employees}
											component={renderDropdownWithAutoComplete}
											onChange={(val) => {
												const selectedEmployee = _.find(datas.employees, { employee_id: val });

												props.change(
													'reference',
													`HR/011/${String(selectedEmployee.employee_number).padStart(3, '0')}/${
														new Date().getMonth() + 1
													}${new Date().getDate()}${new Date().getMilliseconds()}`
												);
												props.change('position', selectedEmployee.job_title);
												props.change('joining_date', new Date(selectedEmployee.start_date).toISOString().split('T')[0]);
												props.change('project_department', selectedEmployee.department.department_name);
											}}
										/>
									</Col>
									<Col>
										<Field name="position" disabled="disabled" component={renderInput} label={t('position')} />
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field
											name="joining_date"
											inputType="date"
											component={renderInput}
											disabled="disabled"
											label={t('joining-date')}
										/>
									</Col>
									<Col>
										<Field
											name="project_department"
											component={renderInput}
											disabled="disabled"
											label="Project/Department"
										/>
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field name="effective_date" inputType="date" component={renderInput} label={t('effective-date')} />
									</Col>
									<Col>
										<Field
											name="date_of_request"
											inputType="date"
											component={renderInput}
											label={t('date-of-request-0')}
										/>
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field rows={3} name="reason" component={renderTextArea} label={t('reason')} />
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field
											rows={3}
											name="department_head_recommendation"
											component={renderTextArea}
											label={t('department-head-recommendation')}
										/>
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field
											rows={3}
											name="hr_manager_recommendation"
											component={renderTextArea}
											label={t('hr-manager-and-admin-manager-recommendation')}
										/>
									</Col>
								</Row>
								<Row className="mb-3">
									<Col>
										<Field
											rows={3}
											name="general_manager_recommendation"
											component={renderTextArea}
											label={t('general-manager-assistant-ceo-approval-and-acceptance-recommendation')}
										/>
									</Col>
								</Row>

								<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
									<Link to={Routes.HumanResources.Termination.Index.path} className="btn btn-secondary">
										{t('back')}{' '}
									</Link>
									<SubmitButton text={t('submit')} onSubmit={onSubmit} invalid={false} />
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</form>
		</>
	);
};
const mapStateToProps = (state) => {
	const {
		auth: { full_name, user_id, companies },
	} = state;

	return {
		full_name,
		user_id,
		companies,
	};
};

export default compose(
	connect(mapStateToProps, { initialize, change }),
	reduxForm({ form: 'termination' })
)(TerminationForm);
