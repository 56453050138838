import { CREATE_PUNCHLIST, GET_PUNCHLISTS, GET_PUNCHLIST, DELETE_PUNCHLIST } from '../../actions/Construction/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	punchLists: [],
	punchList: {},
	loading: true,
	getPunchListLoading: true,
	getPunchListsLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_PUNCHLISTS:
			return { ...state, punchLists: action.payload, getPunchListsLoading: false };

		case GET_PUNCHLIST:
			return { ...state, punchList: action.payload, getPunchListLoading: false };

		case CREATE_PUNCHLIST:
			return { ...state, punchList: action.payload };

		case CLEAR_DATA:
			return {
				...state,
				punchList: action.payload,
				getPunchListLoading: true,
				getPunchListsLoading: true,
			};
		case DELETE_PUNCHLIST:
			return { ...state };
		default:
			return state;
	}
};
