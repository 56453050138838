import React, { Component, useEffect } from 'react';
import { createProject } from '../../../../actions//Finance/projectActions';
import { connect } from 'react-redux';
import ProjectForm from './ProjectForm';
import { getDepartments } from '../../../../actions/Admin/departmentActions';
import { getEntities } from '../../../../actions/Admin/entityActions';
import { getUsers } from '../../../../actions/User/userActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
const ProjectCreate = (props) => {
	const { datas, loading } = props;

	useEffect(() => {
		props.getDepartments();
		props.getEntities();
		props.getUsers();
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			props.createProject(formValues);
		}
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<ProjectForm datas={datas} onSubmit={onSubmit} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		admin: {
			entity: { entities, getEntitiesLoading },
			department: { departments, getDepartmentsLoading },
		},
		user: { users, getUsersLoading },
	} = state;

	return {
		datas: { entities, departments, users },
		loading: getEntitiesLoading || getDepartmentsLoading || getUsersLoading,
	};
};

export default connect(mapStateToProps, { createProject, getEntities, getDepartments, getUsers })(ProjectCreate);
