import {
	CREATE_TICKET,
	GET_TICKETS,
	GET_TICKET,
	DELETE_TICKET,
	UPDATE_TICKET,
	GET_TICKETS_BELONG_TO_USER,
	GET_TICKETS_ASSIGNED,
	RESOLVE_TICKET,
	COMPLETE_TICKET,
	REDIRECT_TICKET,
	ESCALATE_TICKET,
	GET_TICKET_CATEGORIES,
} from './types';
import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const createTicket = (formValues) => async (dispatch, getState) => {
	axios
		.post('/ticket', formValues)
		.then((response) => {
			dispatch({ type: CREATE_TICKET, payload: response.data });
			console.log(response);
			history.push(Routes.Profile.Index.path);
			toast.success('Ticket has been created.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		})
		.catch((err) => {
			console.log(err);
			toast.error('Something went wrong! Please open a ticket.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		});
};
export const getTicket = (id) => async (dispatch) => {
	axios
		.get(`ticket/detail/${id}`)
		.then((response) => {
			dispatch({ type: GET_TICKET, payload: response.data });
		})
		.catch((err) => {});
};
export const getTickets = (offset, limit, query, order, search) => async (dispatch) => {
	var params = '';
	params += order.length != 0 ? `${order}&` : '';
	params += query.length != 0 ? `${query}&` : '';
	params += search.length != 0 ? `${search}` : '';

	axios
		.get(`ticket/offset/${offset}/limit/${limit}?${params}`)
		.then((response) => {
			dispatch({ type: GET_TICKETS, payload: response.data });
		})
		.catch((err) => {});
};
export const getLongIncidenceTickets = (offset, limit, query, order, search) => async (dispatch) => {
	var params = '';
	params += order.length != 0 ? `${order}&` : '';
	params += query.length != 0 ? `${query}&` : '';
	params += search.length != 0 ? `${search}` : '';
	axios
		.get(`ticket/long-incidence/offset/${offset}/limit/${limit}?${params}`)
		.then((response) => {
			dispatch({ type: GET_TICKETS, payload: response.data });
		})
		.catch((err) => {});
};
export const getTicketsBelongsToUser = (user_id) => async (dispatch) => {
	axios
		.get(`ticket/${user_id}`)
		.then((response) => {
			dispatch({ type: GET_TICKETS_BELONG_TO_USER, payload: response.data });
		})
		.catch((err) => {});
};
export const getTicketsAssigned = (offset, limit, query, user_id) => async (dispatch) => {
	axios
		.get(`ticket/assigned/${user_id}/offset/${offset}/limit/${limit}?${query}`)
		.then((response) => {
			dispatch({ type: GET_TICKETS_ASSIGNED, payload: response.data });
		})
		.catch((err) => {});
};
export const deleteTicket = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`ticket/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_TICKET, payload: response.data });
			setDeleted(!deleted);
		})
		.catch((err) => {});
};
export const updateTicket = (formValues) => async (dispatch) => {
	axios
		.put(`ticket/${formValues.ticket_id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_TICKET, payload: response.data });
			history.push(Routes.Profile.Index.path);
		})
		.catch((err) => {});
};
export const resolveTicket = (ticket_id, formValues) => async (dispatch, getState) => {
	const state = getState();
	formValues['engineer'] = state.auth.full_name;

	axios
		.put(`ticket/resolve/${ticket_id}`, formValues)
		.then((response) => {
			dispatch({ type: RESOLVE_TICKET, payload: response.data });
			history.push(Routes.IT_Department.Ticket.Index.path);
		})
		.catch((err) => {
			console.log(err);
		});
};
export const completeTicket = (ticket_id) => async (dispatch) => {
	axios
		.put(`ticket/complete/${ticket_id}`)
		.then((response) => {
			dispatch({ type: COMPLETE_TICKET, payload: response.data });
			history.push(Routes.Welcome.path);
		})
		.catch((err) => {});
};

export const redirectTicket = (ticket_id, formValues) => async (dispatch) => {
	axios
		.put(`ticket/redirect/${ticket_id}`, formValues)
		.then((response) => {
			dispatch({ type: REDIRECT_TICKET, payload: response.data });
			history.push(Routes.IT_Department.Ticket.Index.path);
		})
		.catch((err) => {});
};

export const escalateTicket = (ticket_id, formValues) => async (dispatch, getState) => {
	const state = getState();

	formValues['engineer'] = state.auth.full_name;

	axios
		.put(`ticket/escalate/${ticket_id}`, formValues)
		.then((response) => {
			dispatch({ type: ESCALATE_TICKET, payload: response.data });
			history.push(Routes.IT_Department.Ticket.Index.path);
		})
		.catch((err) => {});
};

export const reopenTicket = (ticket_id, formValues) => async (dispatch) => {
	axios
		.put(`ticket/reopen/${ticket_id}`, formValues)
		.then((response) => {
			dispatch({ type: CREATE_TICKET, payload: response.data });
			history.push(Routes.Welcome.path);
		})
		.catch((err) => {});
};
export const getTicketCategories = (id) => async (dispatch) => {
	axios
		.get(`/ticket-category`)
		.then((response) => {
			dispatch({ type: GET_TICKET_CATEGORIES, payload: response.data });
		})
		.catch((err) => {});
};
