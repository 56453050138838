import {
	CREATE_PROJECT_MILESTONE,
	DELETE_PROJECT_MILESTONE,
	GET_PROJECT_MILESTONE,
	GET_PROJECT_MILESTONES,
} from '../../actions/Finance/types';
import { CLEAR_DATA } from '../../actions/types';

const INITIAL_STATE = {
	projectMilestone: {},
	projectMilestones: [],
	getProjectMilestoneLoading: true,
	getProjectMilestonesLoading: true,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_PROJECT_MILESTONES:
			return { ...state, projectMilestones: action.payload, getProjectMilestonesLoading: false };
		case GET_PROJECT_MILESTONE:
			return { ...state, projectMilestone: action.payload, getProjectMilestoneLoading: false };
		case CREATE_PROJECT_MILESTONE:
			return { ...state };
		case CLEAR_DATA:
			return {
				...state,
				projectMilestones: [],
				getProjectMilestoneLoading: true,
				getProjectMilestonesLoading: true,
			};
		case DELETE_PROJECT_MILESTONE:
			return { ...state };

		default:
			return state;
	}
};
