import { GET_CATEGORIES, GET_ITEMS } from '../actions/types';

const initialState = {
	categories: [],
	items: [],
	getCategoriesLoading: true,
	getItemsLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_CATEGORIES:
			return { ...state, categories: action.payload, getCategoriesLoading: false };
		case GET_ITEMS:
			return { ...state, items: action.payload, getItemsLoading: false };
		default:
			return state;
	}
};
