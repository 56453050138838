import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ProjectMilestoneForm from './ProjectMilestoneForm';
import { getProject } from '../../../../actions/Finance/projectActions';
import { updateProjectMilestone, getProjectMilestone } from '../../../../actions/Finance/projectMilestoneActions';
import { clearData } from '../../../../actions/globalActions';

import LoadingSpinner from '../../../../components/LoadingSpinner';
import _ from 'lodash';

const ProjectMilestoneEdit = (props) => {
	const id = props.match.params.id;

	const { datas, loading } = props;

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.updateProjectMilestone(formValues);
		}
	};

	useEffect(() => {
		props.getProjectMilestone(id);

		return () => {
			props.clearData();
		};
	}, []);
	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<ProjectMilestoneForm onSubmit={onSubmit} datas={datas} initials={datas.projectMilestone} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		finance: {
			projectMilestone: { projectMilestone, getProjectMilestoneLoading },
		},
	} = state;

	return { datas: { projectMilestone, project: projectMilestone.project }, loading: getProjectMilestoneLoading };
};

export default connect(mapStateToProps, { updateProjectMilestone, getProjectMilestone, clearData })(
	ProjectMilestoneEdit
);
