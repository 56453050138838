import React from 'react';
import { connect } from 'react-redux';
import DisciplineForm from './DisciplineForm';

import { getEntities } from '../../../../actions/Admin/entityActions';
import { getDiscipline, updateDiscipline } from '../../../../actions/Construction/disciplineActions';
import { useEffect } from 'react';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import _ from 'lodash';
import { clearData } from '../../../../actions/globalActions';

const DisciplineEdit = (props) => {
	const { id } = props.match.params;

	const { loading, datas } = props;

	useEffect(() => {
		props.getEntities();
		props.getDiscipline(id);

		return () => {
			props.clearData();
		};
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			props.updateDiscipline(formValues);
		}
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<DisciplineForm datas={datas} onSubmit={onSubmit} initials={datas.discipline} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		admin: {
			entity: { entities, getEntitiesLoading },
		},
		construction: {
			discipline: { discipline, getDisciplineLoading },
		},
	} = state;

	return {
		loading: getEntitiesLoading | getDisciplineLoading,
		datas: {
			entities,
			discipline,
		},
	};
};

export default connect(mapStateToProps, { getEntities, getDiscipline, clearData, updateDiscipline })(DisciplineEdit);
