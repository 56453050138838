import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import DeliveryCard from './DeliveryCard';
import { Routes } from '../../../routes';
import { getItems } from '../../../actions/Delivery/deliveryActions';
import { clearData } from '../../../actions/globalActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faShoppingCart, faCog, faFile, faList } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb, Col, Nav, Row, Button, Card } from '@themesberg/react-bootstrap';
import LoadingSpinner from '../../../components/LoadingSpinner';
import OrderDetailCard from './OrderDetailCard';
import { saveOrder } from '../../../actions/Delivery/orderActions';
import { useTranslation } from 'react-i18next';

const Delivery = (props) => {
	const { datas, loading, saveOrder } = props;

	const { items, order } = datas;
	const { t } = useTranslation();

	const [category, setCategory] = useState(100);
	const [categoryLoading, setCategoryLoading] = useState(false);

	useEffect(() => {
		props.getItems();
	}, []);

	useEffect(() => {
		setCategoryLoading(false);
	}, [categoryLoading]);

	return (
		<>
			<div className="py-4">
				<div className="d-flex justify-content-between mb-4 mb-xl-0">
					<div>
						<Breadcrumb
							className="d-block"
							listProps={{
								className: 'breadcrumb-dark breadcrumb-transparent',
							}}>
							<Breadcrumb.Item>
								<FontAwesomeIcon icon={faHome} />
							</Breadcrumb.Item>
							<Breadcrumb.Item active>{t('delivery')}</Breadcrumb.Item>
						</Breadcrumb>
					</div>
					<div>
						<Link to={Routes.Delivery.Order.Index.path} className="btn btn-primary text-center m-2">
							<FontAwesomeIcon icon={faList} />
						</Link>
						<Link to={Routes.Delivery.Panel.Item.ItemIndex.path} className="btn btn-primary text-center">
							<FontAwesomeIcon icon={faCog} />
						</Link>
					</div>
				</div>
				<Nav className="justify-content-center row" variant="tabs">
					<Nav.Item className="col text-center ">
						<Nav.Link
							className={category == 100 ? 'bg-primary text-white' : null}
							onClick={() => {
								setCategory(100);
								setCategoryLoading(true);
							}}>
							{t('hot-drinks')}{' '}
						</Nav.Link>
					</Nav.Item>
					<Nav.Item className="col text-center">
						<Nav.Link
							className={category == 200 ? 'bg-primary text-white' : null}
							onClick={() => {
								setCategory(200);
								setCategoryLoading(true);
							}}>
							{t('cold-drinks')}{' '}
						</Nav.Link>
					</Nav.Item>

					{/* <Nav.Item className="col text-center">
						<Nav.Link
							className={category == 300 ? 'bg-primary text-white' : null}
							onClick={() => {
								setCategory(300);
								setCategoryLoading(true);
							}}>
							Snacks
						</Nav.Link>
					</Nav.Item> */}
				</Nav>
				<br />
			</div>
			<Row>
				<Col xl={9} lg={12} md={12} sm={12} xs={12}>
					<Row className="d-flex">
						{loading || categoryLoading ? (
							<LoadingSpinner />
						) : (
							items.map((item, index) => {
								if (item.category_id === category) {
									return (
										<React.Fragment key={index}>
											<DeliveryCard item={item} key={index} setOrder={saveOrder} order={order} />
										</React.Fragment>
									);
								}
							})
						)}
					</Row>
				</Col>
				<Col xl={3} lg={12} md={12} sm={12} xs={12} className="px-3 mb-5">
					<OrderDetailCard showCheckoutButton={true} order={order} />
				</Col>
			</Row>
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		delivery: {
			item: { items, getItemsLoading },
			order: { order },
		},
	} = state;

	return {
		datas: { items, order },

		loading: getItemsLoading,
	};
};

export default connect(mapStateToProps, {
	getItems,
	clearData,
	saveOrder,
})(Delivery);
