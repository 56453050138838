import {
	CREATE_ITEM_CATEGORY,
	GET_ITEM_CATEGORY,
	GET_ITEM_CATEGORIES,
	DELETE_ITEM_CATEGORY,
	UPDATE_ITEM_CATEGORY,
} from './types';
import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';

export const createItemCategory = (formValues) => async (dispatch) => {
	axios
		.post('/item-category', formValues)
		.then((response) => {
			dispatch({ type: CREATE_ITEM_CATEGORY, payload: response.data });
			history.push(Routes.Procurement.Products.Index.path);
		})
		.catch((err) => {});
};
export const getItemCategories = () => async (dispatch) => {
	axios
		.get('/item-category')
		.then((response) => {
			dispatch({ type: GET_ITEM_CATEGORIES, paylaod: response.data });
		})
		.catch((err) => {});
};
export const getItemCategory = (id) => async (dispatch) => {
	axios
		.get(`/item-category/${id}`)
		.then((response) => {
			dispatch({ type: GET_ITEM_CATEGORY, payload: response.data });
		})
		.catch((err) => {});
};
export const deleteItemCategory = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`/item-category/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_ITEM_CATEGORY, payload: response.data });
			setDeleted(!deleted);
		})
		.catch((err) => {});
};
export const updateItemCategory = (formValues) => async (dispatch) => {
	axios
		.put(`/item-category/${formValues.id}`)
		.then((response) => {
			dispatch({ type: UPDATE_ITEM_CATEGORY, payload: response.data });
			history.push(Routes.Procurement.Products.Index.path);
		})
		.catch((err) => {});
};
