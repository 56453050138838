import { CREATE_EMPLOYEE, GET_EMPLOYEES, GET_EMPLOYEE, DELETE_EMPLOYEE } from '../../actions/HumanResources/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	employees: [],
	employee: {},
	loading: true,
	getEmployeeLoading: true,
	getEmployeesLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_EMPLOYEES:
			return { ...state, employees: action.payload, getEmployeesLoading: false };

		case GET_EMPLOYEE:
			return { ...state, employee: action.payload, getEmployeeLoading: false };

		case CREATE_EMPLOYEE:
			return { ...state, employee: action.payload };

		case CLEAR_DATA:
			return {
				...state,
				employee: action.payload,
				getEmployeeLoading: true,
				getEmployeesLoading: true,
			};

		case DELETE_EMPLOYEE:
			return { ...state };

		default:
			return state;
	}
};
