import React from 'react';
import { connect } from 'react-redux';

import EntitiesForm from './EntitiesForm';

import { createEntity } from '../../../../actions/Admin/entityActions';

class EntitiesCreate extends React.Component {
	onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			this.props.createEntity(formValues);
		}
	};

	render() {
		return (
			<div className="mt-3">
				<EntitiesForm onSubmit={this.onSubmit} />
			</div>
		);
	}
}

export default connect(null, { createEntity })(EntitiesCreate);
