import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { createLocation } from '../../../../actions/Construction/locationsActions';
import { clearData } from '../../../../actions/globalActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import LocationsForm from './LocationsForm';

const LocationsCreate = (props) => {
	useEffect(() => {
		return () => {
			props.clearData();
		};
	}, []);
	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			//AND ISVALID EKLEYECEKSIN
			props.createLocation(formValues);
		}
	};

	return (
		<>
			{0 ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<LocationsForm onSubmit={onSubmit} />
				</div>
			)}
		</>
	);
};

export default connect(null, { createLocation, clearData })(LocationsCreate);
