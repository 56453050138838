import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import WorkflowForm from './WorkflowForm';

import { getUsers, getRoles } from '../../../actions/User/userActions';
import { getEntities } from '../../../actions/Admin/entityActions';
import { getDepartments } from '../../../actions/Admin/departmentActions';
import { getForms, getModules } from '../../../actions/Workflow/workflowActions';
import { createWorkflow } from '../../../actions/Workflow/workflowActions';
import LoadingSpinner from '../../../components/LoadingSpinner';

const WorkflowCreate = (props) => {
	const { data, loading } = props;

	useEffect(() => {
		props.getUsers();
		props.getForms();
		props.getModules();
		props.getEntities();
		props.getDepartments();
		props.getRoles();
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.createWorkflow(formValues);
		}
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<WorkflowForm onSubmit={onSubmit} datas={data} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		user: { users, getUsersLoading, getRolesLoading },
		admin: {
			entity: { entities, getEntitiesLoading },
			department: { getDepartmentsLoading },
		},
		workflow: { forms, getFormsLoading, modules, getModulesLoading },
	} = state;

	return {
		data: { users, forms, modules, entities },
		loading:
			getUsersLoading ||
			getEntitiesLoading ||
			getDepartmentsLoading ||
			getFormsLoading ||
			getRolesLoading ||
			getModulesLoading,
	};
};

export default connect(mapStateToProps, {
	getUsers,
	getEntities,
	getDepartments,
	getRoles,
	createWorkflow,
	getForms,
	getModules,
})(WorkflowCreate);
