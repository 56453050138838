import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import WarningLetterForm from './WarningLetterForm';

import { getEmployees } from '../../../../actions/HumanResources/employeeActions';
import { updateWarningLetter, getWarningLetter } from '../../../../actions/HumanResources/warningLetterActions';
import { clearData } from '../../../../actions/globalActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';

const WarningLetterEdit = (props) => {
	const { id } = props.match.params;
	const { datas, loading } = props;

	useEffect(() => {
		props.getEmployees();
		props.getWarningLetter(id);

		return () => {
			props.clearData();
		};
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.updateWarningLetter(formValues);
		}
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<WarningLetterForm datas={datas} onSubmit={onSubmit} initials={datas.warningLetter} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		humanResources: {
			employee: { employees, getEmployeesLoading },
			warningLetter: { warningLetter, getWarningLetterLoading },
		},
	} = state;

	return { datas: { employees, warningLetter }, loading: getEmployeesLoading || getWarningLetterLoading };
};

export default connect(mapStateToProps, { getEmployees, clearData, getWarningLetter, updateWarningLetter })(
	WarningLetterEdit
);
