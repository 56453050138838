import React from 'react';
import moment from 'moment-timezone';
import { Row, Col, Card, OverlayTrigger, Tooltip, Image, Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faDownload, faRocket } from '@fortawesome/free-solid-svg-icons';
import history from '../history';
import { Routes } from '../routes';
import { useTranslation } from 'react-i18next';

export default (props) => {
	const showSettings = props.showSettings;

	const { t } = useTranslation();
	const toggleSettings = (toggle) => {
		props.toggleSettings(toggle);
	};

	return (
		<div>
			{showSettings ? (
				<Card className="theme-settings">
					<Card.Body className="pt-4">
						<Button
							className="theme-settings-close"
							variant="close"
							size="sm"
							aria-label={t('close')}
							onClick={() => {
								toggleSettings(false);
							}}
						/>
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h6 className="m-0 mb-1 me-3 fs-7">{t('how-can-we-help')}</h6>
						</div>
						<div className="m-3 ">
							<Card.Link
								onClick={() => {
									history.push(Routes.Support.path);
								}}>
								<OverlayTrigger
									placement="top"
									trigger={['hover', 'focus']}
									overlay={<Tooltip>{t('check-common-questions-and-answers')}</Tooltip>}>
									<p className="m-0 me-3 fs-7">{t('frequently-asked-questions')}</p>
								</OverlayTrigger>
							</Card.Link>

							<Card.Link
								onClick={() => {
									history.push(Routes.Support.path);
								}}>
								<OverlayTrigger
									placement="top"
									trigger={['hover', 'focus']}
									overlay={<Tooltip>{t('read-through-some-documentation-prepared-by-the-team')}</Tooltip>}>
									<p className="m-0 mb-1 me-3 fs-7">{t('help-documents')}</p>
								</OverlayTrigger>
							</Card.Link>

							<Card.Link
								onClick={() => {
									history.push(Routes.IT_Department.Ticket.Create.path);
								}}>
								<OverlayTrigger
									placement="top"
									trigger={['hover', 'focus']}
									overlay={<Tooltip>{t('notify-us-about-any-problems-you-have-experienced')}</Tooltip>}>
									<p className="m-0 mb-1 me-3 fs-7">{t('open-ticket')}</p>
								</OverlayTrigger>
							</Card.Link>
						</div>
					</Card.Body>
				</Card>
			) : (
				<Card
					className="theme-settings theme-settings-expand"
					onClick={() => {
						toggleSettings(true);
					}}>
					<Card.Body className="p-3 py-2">
						<span className="fw-bold h6">
							<FontAwesomeIcon icon={faInfoCircle} className="me-1 fs-7" /> {t('help')}
						</span>
					</Card.Body>
				</Card>
			)}
		</div>
	);
};
