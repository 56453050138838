import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Button, Popover, Card, Form } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCross, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import Multiselect from 'react-widgets/Multiselect';

const TableFilter = ({
	initialFilterCategories,
	filters,
	setFilters,
	datas,
	filterCategories,
	setFilterCategories,
}) => {
	const [showOverlayTrigger, setShowOverlayTrigger] = useState(false);
	const [filterBy, setFilterBy] = useState('');
	const [displayField, setDisplayField] = useState('');
	const [seletectedFilterOptions, setSelectedFilterOptions] = useState([]);

	// RENDERS THE OPTIONS ACCORDING TO SELECTED CATEGORY
	const renderFilterOptions = (filterBy, displayField) => {
		var options = [];

		if (displayField && filterBy != '') {
			options = datas.map((isr, index) => ({
				[displayField]: _.get(isr, displayField),
				[filterBy]: _.get(isr, filterBy),
			}));
			options.pop();
			return _.uniqBy(options, displayField);
		}
		if (filterBy != '' && !displayField) {
			options = datas.map((isr, index) => {
				return _.get(isr, filterBy);
			});

			// ISR GET ALL LAST ITEM IS PAGINATION
			options.pop();
			return _.uniq(options);
		}
	};

	return (
		<>
			<OverlayTrigger
				trigger="click"
				placement="bottom"
				rootClose
				show={showOverlayTrigger}
				onToggle={() => {
					setShowOverlayTrigger(!showOverlayTrigger);
				}}
				// ON EXIT CATEGORY FILTER & SELECTED OPTIONS ARE REMOVED
				onExit={() => {
					setFilterBy('');
					setDisplayField('');
					setSelectedFilterOptions([]);
				}}
				overlay={
					<Popover title="Filter">
						<Card style={{ width: '30vw' }}>
							<Card.Body>
								<Form.Group className="mb-3">
									<Form.Label>Filter By</Form.Label>
									<Form.Select
										defaultValue=""
										size="sm"
										onChange={(event) => {
											// CATEGORY FILTER DROPDOWN CHANGE
											setFilterBy(event.target.value);
											// FOR SECURE SELECTED OPTIONS ARE REMOVED
											setDisplayField(_.find(filterCategories, { value: event.target.value }).displayField);
											setSelectedFilterOptions([]);
										}}>
										<option disabled value="">
											Select
										</option>
										{filterCategories.map((category, index) => {
											return (
												<option key={index} value={category.value}>
													{category.label}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>
								{filterBy != '' ? (
									<Form.Group className="mb-3">
										<Form.Label>Select</Form.Label>

										<Multiselect
											onChange={(value) => {
												if (displayField) {
													setSelectedFilterOptions(value);
												} else {
													setSelectedFilterOptions(value);
												}
											}}
											value={seletectedFilterOptions}
											data={renderFilterOptions(filterBy, displayField)}
											textField={displayField}
											dataKey={filterBy}
										/>
									</Form.Group>
								) : null}
								<Form.Group
									onClick={() => {
										if (seletectedFilterOptions.length != 0) {
											// ON CLICK SELECTED FILTER OBJ's ADDED TO FILTER OBJ
											// Data type--->
											// filter:{
											// queryName:[selectedFilterOptions]
											// }
											if (displayField) {
												setFilters([
													...filters,
													{
														['query_' + _.find(filterCategories, { value: filterBy }).queryName]:
															seletectedFilterOptions.map((option) => _.get(option, filterBy)),
														display: seletectedFilterOptions.map((option) => _.get(option, displayField)),
													},
												]);
											} else {
												setFilters([
													...filters,
													{
														['query_' + _.find(filterCategories, { value: filterBy }).queryName]:
															seletectedFilterOptions,
														display: seletectedFilterOptions,
													},
												]);
											}

											// REMOVES SELECTED FILTER CATEGORY FROM CATEGORY DROPDOWN
											// PREVENT TO ADD SAME CATEGORY FILTER AGAIN
											setFilterCategories(filterCategories.filter((category) => category.value !== filterBy));
										}
										// HANDLES POPOVER SHOW
										setShowOverlayTrigger(!showOverlayTrigger);
									}}
									className="text-right">
									<Button>Add</Button>
								</Form.Group>
							</Card.Body>
						</Card>
					</Popover>
				}>
				<Button className="btn-secondary badge rounded-pill text-dark p-2 border-0">
					<FontAwesomeIcon icon={faPlus} /> Filter
				</Button>
			</OverlayTrigger>
			{filters.length != 0
				? filters.map((filter, index) => {
						return (
							// <span key={index} className="btn-secondary align-content-center mx-2 badge rounded-pill text-dark p-2">
							<Button
								key={index}
								onClick={() => {
									// REMOVES THE SELECTED ONE FROM FILTER OBJ
									setFilters(filters.filter((obj) => !_.isEqual(obj, filter)));
									// ADD THE CATEGORY NAME TO CATEGORY DROPDOWN
									setFilterCategories([
										...filterCategories,
										_.find(initialFilterCategories, {
											queryName: _.find(Object.keys(filter), (key) => key.includes('query_')).split('_')[1],
										}),
									]);
								}}
								className="btn btn-outline-secondary badge rounded-pill text-dark p-2 ms-2 ">
								<FontAwesomeIcon icon={faTimes} className="me-2" />
								{_.toString(filter.display)}
							</Button>
							// </span>
						);
				  })
				: null}
		</>
	);
};

export default TableFilter;
