import { CREATE_PROJECT, UPDATE_PROJECT, GET_PROJECTS, GET_PROJECT, DELETE_PROJECT } from './types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';

export const createProject = (formValues) => async (dispatch, getState) => {
	axios
		.post('/project', formValues)
		.then((response) => {
			dispatch({ type: CREATE_PROJECT, payload: response.data });
			history.push(Routes.Finance.Project.Index.path);
			toast.success('Project has been added.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		})
		.catch((err) => {
			toast.error('Something went wrong! Please open a ticket.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		});
};

export const getProjects = () => async (dispatch) => {
	const response = await axios.get('/project');
	dispatch({ type: GET_PROJECTS, payload: response.data });
};

export const getProjectsWithFilter = (offset, limit, query) => async (dispatch) => {
	axios
		.get(`/project/offset/${offset}/limit/${limit}?${query}`)
		.then((response) => {
			dispatch({ type: GET_PROJECTS, payload: response.data });
		})
		.catch((err) => {});
};

export const getProject = (id) => async (dispatch) => {
	const response = await axios.get(`/project/${id}`);
	dispatch({ type: GET_PROJECT, payload: response.data });
};

export const deleteProject = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`project/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_PROJECT, payload: response.data });
			setDeleted(!deleted);
			toast.success('Project has been deleted.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		})
		.catch((err) => {
			toast.error('Something went wrong! Please open a ticket.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		});
};

export const updateProject = (formValues) => async (dispatch) => {
	axios
		.put(`project/${formValues.project_id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_PROJECT, payload: response.data });
			history.push(Routes.Finance.Project.Index.path);
			toast.success('Project has been updated.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		})
		.catch((err) => {
			toast.error('Something went wrong! Please open a ticket.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		});
};
