import {
	CREATE_CASHFLOW,
	GET_CASHFLOWS,
	GET_CASHFLOW,
	GET_CASHFLOW_TAGS,
	DELETE_CASHFLOW,
} from '../../actions/CashFlow/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	cashflows: [],
	cashflow: {},
	cashflowTags: [],
	loading: true,
	getCashFlowLoading: true,
	getCashFlowsLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_CASHFLOWS:
			return { ...state, cashflows: action.payload, getCashFlowsLoading: false };

		case GET_CASHFLOW:
			return { ...state, cashflow: action.payload, getCashFlowLoading: false };

		case CREATE_CASHFLOW:
			return { ...state, cashflow: action.payload };

		case CLEAR_DATA:
			return {
				...state,
				cashflow: action.payload,
				getCashFlowLoading: true,
				getCashFlowsLoading: true,
			};
		case GET_CASHFLOW_TAGS:
			return { ...state, cashflowTags: action.payload };
		case DELETE_CASHFLOW:
			return { ...state };

		default:
			return state;
	}
};
