import React, { Fragment, useState } from 'react';
import axios from '../apis/axios';
import Message from './Message';
import Progress from './Progress';
// import Camera from 'react-html5-camera-photo';
import { Col, Row } from 'react-bootstrap';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import Camera from './Camera';
const FileUpload = (params) => {
	const [files, setFiles] = useState([]);
	const [uploadFiles, setUploadFiles] = useState([]);
	const [message, setMessage] = useState('');
	const [uploadPercentage, setUploadPercentage] = useState(0);

	const onSubmit = async (e) => {
		console.log(params);
		e.preventDefault();
		const formData = new FormData();

		if (uploadFiles.length > 0) {
			formData.append('title', params.title);
			formData.append('building_id', params.building_id);
			formData.append('floor_id', params.floor_id);
			formData.append('place_id', params.place_id);

			if (uploadFiles.length == 1) {
				formData.append('files', uploadFiles[0]);
			} else {
				uploadFiles.forEach((f) => {
					formData.append('files', f);
				});
			}
			try {
				const res = await axios.post('/upload', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					onUploadProgress: (progressEvent) => {
						setUploadPercentage(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));
					},
				});

				// Clear percentage
				setTimeout(() => setUploadPercentage(0), 10000);

				const { fileCount } = res.data;

				if (fileCount == 1) {
					setMessage(fileCount + ' File Uploaded');
				} else {
					setMessage(fileCount + ' Files Uploaded');
				}
			} catch (err) {
				if (err.response.status === 500) {
					setMessage('There was a problem with the server');
				} else {
					setMessage(err.response.data.msg);
				}
				setUploadPercentage(0);
			}
		}
	};

	const getUploadParams = () => {
		return { url: 'https://httpbin.org/post' };
	};

	// called every time a file's `status` changes
	const handleChangeStatus = ({ meta, file }, status) => {
		// console.log(status, meta, file);

		if (status == 'done') {
			setUploadFiles((olArr) => [...olArr, file]);
		}

		if (status == 'removed') {
			setUploadFiles(uploadFiles.filter((f) => f.name != file.name));
		}
	};

	// // receives array of files that are done uploading when submit button is clicked
	// const handleSubmit = (files, allFiles) => {
	// 	console.log(files.map((f) => f.meta));
	// 	allFiles.forEach((f) => f.remove());
	// };

	return (
		<Fragment>
			{message ? <Message msg={message} /> : null}
			<Row className="d-flex align-items-center ">
				<Col md={5} sm={12} className="mb-3">
					<Dropzone
						styles={{
							previewImage: { maxHeight: '9vh', marginRight: 10 },
							preview: { minHeight: '12vh' },
							dropzone: { maxHeight: '50vh' },
						}}
						getUploadParams={getUploadParams}
						onChangeStatus={handleChangeStatus}
						accept="image/*"
						initialFiles={files}
					/>
				</Col>
				<Col className="d-flex justify-content-around align-items-center mb-3">
					<span className="fw-bold fs-5">OR</span>
				</Col>

				<Col md={5} sm={12} className="mb-3">
					<Camera setFiles={setFiles} />
				</Col>
			</Row>
			<Row className="mx-auto">
				<Progress percentage={uploadPercentage} />
			</Row>
			<Row>
				<Col className="d-flex justify-content-center w-100 mb-3">
					<button onClick={onSubmit} className="btn btn-primary w-50  ">
						Upload
					</button>
				</Col>
			</Row>
		</Fragment>
	);
};

export default FileUpload;
