import { GET_ENTITIES, CREATE_ENTITY, GET_ENTITY, UPDATE_ENTITY, DELETE_ENTITY } from '../../actions/Admin/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	entities: [],
	entity: {},
	getEntitiesLoading: true,
	getEntityLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_ENTITIES:
			return { ...state, entities: action.payload, getEntitiesLoading: false };
		case CREATE_ENTITY:
			return { ...state, entity: action.payload };
		case GET_ENTITY:
			return { ...state, entity: action.payload, getEntityLoading: false };
		case UPDATE_ENTITY:
			return { ...state };
		case CLEAR_DATA:
			return {
				...state,
				entity: action.payload,
				getEntityLoading: true,
				getEntitiesLoading: true,
			};

		case DELETE_ENTITY:
			return { ...state };
		default:
			return state;
	}
};
