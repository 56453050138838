import React, { Component, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb, Card, Nav, Table } from '@themesberg/react-bootstrap';
import history from '../../../../history';

import { Link } from 'react-router-dom';
import { Routes } from '../../../../routes';
import IndexTable from '../../../../components/IndexTable';
import { connect } from 'react-redux';

import { getTicketCategories, deleteTicketCategory } from '../../../../actions/Admin/ticketCategoryActions';
import onDelete from '../../../../helpers/deleteFunction';

import { clearData } from '../../../../actions/globalActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';

import _ from 'lodash';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const TicketCategoryIndex = (props) => {
	const {
		ticketCategory: { ticketCategories },
		loading,
	} = props;
	const [deleted, setDeleted] = useState(false);
	const { t } = useTranslation();
	useEffect(() => {
		props.getTicketCategories();
	}, [deleted]);

	const renderRows = () => {
		return ticketCategories.map((value, index) => {
			let categories = _.toString(_.map(value.ticket_categories, 'category'));
			let cutOffLength = 30;
			return (
				<tr key={index}>
					<td className="fw-bold text-center">{index + 1}</td>
					<td className="fw-bold text-center">{value.department_name}</td>
					<td className="fw-bold text-center">
						{categories.slice(0, cutOffLength) + (categories.length > cutOffLength ? '...' : '')}
					</td>
					<td className="fw-bold text-center">
						<button
							onClick={() => {
								onDelete(deleted, setDeleted, props.deleteTicketCategory, value.department_id);
							}}
							className="btn btn-danger mx-2">
							<i className="fa fa-trash  "></i>
						</button>
						<Link
							to={Routes.Admin.TicketCategory.Edit.path.replace(':id', value.department_id)}
							className="btn btn-primary mx-2">
							<i className="fa fa-edit"></i>
						</Link>
					</td>
				</tr>
			);
		});
	};

	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0 ">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.IT_Department.Ticket.Index.path }}>
							{t('tickets')}{' '}
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{t('ticket-categories')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<br />
				<div
					className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center
          ">
					<h4 style={{ display: 'inline' }}>{t('ticket-categories-0')}</h4>
					<div>
						<Link className="btn btn-primary py-2 px-3  " to={Routes.Admin.TicketCategory.Create.path}>
							{t('add-new')}{' '}
						</Link>
					</div>
				</div>
				<p className="mb-0">{t('here-you-can-configure-the-metadata-for-ticket-forms')}</p>
			</div>

			<IndexTable
				loading={loading}
				headerProps="text-center"
				headers={['#', t('department-0'), t('ticket-category'), '']}
				renderRows={ticketCategories.length != 0 ? renderRows() : null}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	return { ticketCategory: state.admin.ticketCategory, loading: state.admin.ticketCategory.getTicketCategoriesLoading };
};

export default connect(mapStateToProps, { getTicketCategories, deleteTicketCategory, clearData })(TicketCategoryIndex);
