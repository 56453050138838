import { CREATE_TERMINATION, UPDATE_TERMINATION, GET_TERMINATIONS, GET_TERMINATION, DELETE_TERMINATION } from './types';

import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';

export const createTermination = (formValues) => async (dispatch, getState) => {
	axios
		.post('/termination', formValues)
		.then((response) => {
			dispatch({ type: CREATE_TERMINATION, payload: response.data });
			history.push(Routes.HumanResources.Termination.Index.path);
		})
		.catch((err) => {});
};

export const getTerminations = () => async (dispatch) => {
	axios
		.get('/termination')
		.then((response) => {
			dispatch({ type: GET_TERMINATIONS, payload: response.data });
		})
		.catch((err) => {});
};

export const getTerminationsWithFilter = (offset, limit, query) => async (dispatch) => {
	axios
		.get(`/termination/offset/${offset}/limit/${limit}?${query}`)
		.then((response) => {
			dispatch({ type: GET_TERMINATIONS, payload: response.data });
		})
		.catch((err) => {});
};

export const getTermination = (id) => async (dispatch) => {
	const response = await axios.get(`/termination/${id}`);
	dispatch({ type: GET_TERMINATION, payload: response.data });
};

export const deleteTermination = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`termination/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_TERMINATION, payload: response.data });
			setDeleted(!deleted);
		})
		.catch((err) => {});
};

export const updateTermination = (formValues) => async (dispatch) => {
	axios
		.put(`termination/${formValues.id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_TERMINATION, payload: response.data });
			history.push(Routes.HumanResources.Termination.Index.path);
		})
		.catch((err) => {});
};
