import { Row, Col, ListGroup } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faBell, faExclamation } from '@fortawesome/free-solid-svg-icons';

export default (props) => {
	const { link, from, createdAt, description, title, type, read = false, id, notificationRead } = props;

	return (
		<ListGroup.Item onClick={() => {}} action href={link} className="border-bottom border-light">
			<Row className="align-items-center">
				<Col className="col-auto">
					{type?.toLowerCase() == 'error' ? (
						<div className="user-avatar rounded-circle mx-auto text-white" style={{ background: 'red' }}>
							<FontAwesomeIcon icon={faTimes} />
						</div>
					) : type?.toLowerCase() == 'success' ? (
						<div className="user-avatar rounded-circle mx-auto text-white" style={{ background: 'green' }}>
							<FontAwesomeIcon icon={faCheck} />
						</div>
					) : type?.toLowerCase() == 'warning' ? (
						<div className="user-avatar rounded-circle mx-auto text-white" style={{ background: '#ffd500' }}>
							<FontAwesomeIcon icon={faExclamation} />
						</div>
					) : from ? (
						<div className="user-avatar rounded-circle mx-auto   bg-primary text-white">
							{from.split(' ')[0][0]}
							{from.split(' ')[1][0]}
						</div>
					) : (
						<div className="user-avatar rounded-circle mx-auto   bg-primary text-white">
							<FontAwesomeIcon icon={faBell} />
						</div>
					)}
				</Col>
				<Col className="ps-0 ms--2">
					<div className="d-flex justify-content-between align-items-center">
						<div className={`${read ? 'text-muted' : ''}`}>
							{from ? (
								<h4 className="h6 mb-0 text-small">{from}</h4>
							) : title ? (
								<h4 className="h6 mb-0 text-small">{title}</h4>
							) : null}
						</div>
						<div className="text-end">
							<small>{new Date(createdAt).toLocaleDateString()}</small>
						</div>
					</div>
					<p className="font-small mt-1 mb-0">{description}</p>
				</Col>
			</Row>
		</ListGroup.Item>
	);
};
