import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';
import { CREATE_ENTITY, DELETE_ENTITY, GET_ENTITIES, GET_ENTITY, UPDATE_ENTITY } from './types';

export const getEntities = () => async (dispatch) => {
	axios
		.get('company/')
		.then((response) => {
			dispatch({ type: GET_ENTITIES, payload: response.data });
		})
		.catch((err) => {});
};

export const createEntity = (formValues) => async (dispatch) => {
	axios
		.post('/company', {
			company_name: formValues.entityName,
		})
		.then((response) => {
			dispatch({ type: CREATE_ENTITY, payload: response.data });
			history.push(Routes.Admin.Entity.Index.path);
		})
		.catch((err) => {});
};

export const getEntity = (id) => async (dispatch) => {
	axios
		.get(`company/${id}`)
		.then((response) => {
			dispatch({ type: GET_ENTITY, payload: response.data });
		})
		.catch((err) => {});
};

export const updateEntity = (formValues) => async (dispatch) => {
	axios
		.put(`company/${formValues.id}`, {
			company_name: formValues.entityName,
		})
		.then((response) => {
			dispatch({ type: UPDATE_ENTITY, payload: response.data });
			history.push(Routes.Admin.Entity.Index.path);
		})
		.catch((err) => {});
};

export const deleteEntity = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`company/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_ENTITY, payload: response.data });
			setDeleted(!deleted);

			history.push(Routes.Admin.Entity.Index.path);
		})
		.catch((err) => {});
};
