import { CREATE_CLEARANCE, UPDATE_CLEARANCE, GET_CLEARANCES, GET_CLEARANCE, DELETE_CLEARANCE } from './types';
import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';
import { formValues } from 'redux-form';
export const createClearance = (formValues) => async (dispatch, getState) => {
	axios
		.post('/clearance', formValues)
		.then((response) => {
			dispatch({ type: CREATE_CLEARANCE, payload: response.data });
			history.push(Routes.HumanResources.Clearance.Index.path);
		})
		.catch((err) => {});
};
export const getClearances = () => async (dispatch) => {
	axios
		.get('/clearance')
		.then((response) => {
			dispatch({ type: GET_CLEARANCES, payload: response.data });
		})
		.catch((err) => {});
};
export const getClearancesWithFilter = (offset, limit, query) => async (dispatch) => {
	axios
		.get(`/clearance/offset/${offset}/limit/${limit}?${query}`)
		.then((response) => {
			dispatch({ type: GET_CLEARANCES, payload: response.data });
		})
		.cathc((err) => {});
};
export const getClearance = (id) => async (dispatch) => {
	const response = await axios.get(`/clearance/${id}`);
	dispatch({ type: GET_CLEARANCE, payload: response.data });
};
export const deleteClearance = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`/clearance/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_CLEARANCE, payload: response.data });
			setDeleted(!deleted);
		})
		.catch((err) => {});
};
export const updateClearance = (formValues) => async (dispatch) => {
	axios
		.put(`/clearance/${formValues.id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_CLEARANCE, payload: response.data });
			history.push(Routes.HumanResources.Clearance.Index.path);
		})
		.catch((err) => {
			console.log(err);
		});
};
