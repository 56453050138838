import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faShoppingCart, faCogs, faCog } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb, Col, Nav, Row, Button, Card } from '@themesberg/react-bootstrap';

import { Field, reduxForm, initialize } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import onDelete from '../../../../../helpers/deleteFunction';
import history from '../../../../../history';
import { Routes } from '../../../../../routes';
import IndexTable from '../../../../../components/IndexTable';
import { getCategories, deleteCategory } from '../../../../../actions/Delivery/deliveryActions';
import { clearData } from '../../../../../actions/globalActions';
import { useTranslation } from 'react-i18next';

const CategoryIndex = (props) => {
	const { categories } = props;
	const [deleted, setDeleted] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		props.getCategories();

		return () => {
			props.clearData();
		};
	}, [deleted]);

	const renderRows = () => {
		return categories.map((value, index) => {
			return (
				<tr key={index}>
					<td className="fw-bold text-center">{index + 1}</td>
					<td className="fw-bold text-center">{value.category_name}</td>
					<td className="fw-bold text-center">
						<button
							onClick={() => {
								onDelete(deleted, setDeleted, props.deleteCategory, value.category_id);
							}}
							className="btn btn-danger mx-2">
							<i className="fa fa-trash  "></i>
						</button>
						<Link
							to={Routes.Delivery.Panel.Category.Edit.path.replace(':id', value.category_id)}
							className="btn btn-primary mx-2">
							<i className="fa fa-edit"></i>
						</Link>
					</td>
				</tr>
			);
		});
	};
	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{t('delivery-panel')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<Nav className="justify-content-center row" variant="tabs" defaultActiveKey={window?.location.href}>
					<Nav.Item className=" col text-center ">
						<Nav.Link
							className=""
							onClick={() => {
								history.push(Routes.Delivery.Panel.Item.ItemIndex.path);
							}}>
							{t('item')}{' '}
						</Nav.Link>
					</Nav.Item>

					<Nav.Item
						className="col text-center bg-primary"
						style={{
							borderTopLeftRadius: '0.5rem',
							borderTopRightRadius: '0.5rem',
						}}>
						<Nav.Link className=" text-white">{t('category')}</Nav.Link>
					</Nav.Item>

					<Nav.Item
						className="col text-center "
						onClick={() => {
							history.push(Routes.Delivery.Panel.Location.Index.path);
						}}>
						<Nav.Link className="">{t('location')}</Nav.Link>
					</Nav.Item>
				</Nav>
				<br />
				<div
					className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center 
          ">
					<h4 style={{ display: 'inline' }}>{t('categories')}</h4>
					<div>
						<Link className="btn btn-primary py-2 px-3 " to={Routes.Delivery.Panel.Category.Create.path}>
							{t('add-new')}{' '}
						</Link>
					</div>
				</div>
			</div>
			<IndexTable
				headerProps="text-center"
				headers={['#', t('category-name'), '']}
				renderRows={categories.length != 0 ? renderRows() : null}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		categories: state.delivery.item.categories,
	};
};

export default connect(mapStateToProps, {
	getCategories,
	clearData,
	deleteCategory,
})(CategoryIndex);
