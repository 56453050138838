import { Form } from '@themesberg/react-bootstrap';
import React from 'react';

export default ({ input, label, placeholder, meta, inputType, onChange, value, className, disabled, inputProps }) => {
	return (
		<Form.Group className={className}>
			{label ? <Form.Label>{label}</Form.Label> : null}
			<Form.Control
				disabled={disabled}
				onChange={onChange}
				type={inputType}
				value={value}
				as="input"
				{...input}
				{...inputProps}
				placeholder={placeholder}
			/>
			<span className="text-danger">{meta.touched && meta.error ? meta.error : null}</span>
		</Form.Group>
	);
};
