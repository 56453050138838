import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import SalaryDeductionForm from './SalaryDeductionForm';
import { createSalaryDeduction } from '../../../../actions/HumanResources/salaryDeductionAction';
import { getEmployees } from '../../../../actions/HumanResources/employeeActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';

const SalaryDeductionCreate = (props) => {
	const { datas, loading } = props;

	useEffect(() => {
		props.getEmployees();
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.createSalaryDeduction(formValues);
		}
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<SalaryDeductionForm datas={datas} onSubmit={onSubmit} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		auth: { user_id, full_name, companies },
		humanResources: {
			employee: { employees, getEmployeesLoading },
		},
	} = state;

	return {
		datas: { user: { user_id, full_name, companies }, employees },
		loading: getEmployeesLoading,
	};
};

export default connect(mapStateToProps, { createSalaryDeduction, getEmployees })(SalaryDeductionCreate);
