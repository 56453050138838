import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Nav } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import IndexTable from '../../../../../components/IndexTable';
import history from '../../../../../history';
import { Routes } from '../../../../../routes';
import { clearData } from '../../../../../actions/globalActions';
import onDelete from '../../../../../helpers/deleteFunction';
import { getSupplierEvaluations, deleteSupplierEvaluation } from '../../../../../actions//Procurement/suppliersActions';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import { useTranslation } from 'react-i18next';

const SupplierEvaluationIndex = (props) => {
	const { loading, supplierEvaluations } = props;

	const [deleted, setDeleted] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		props.getSupplierEvaluations();

		return () => {
			props.clearData();
		};
	}, [deleted]);

	const renderRows = () => {
		return supplierEvaluations.map((value, index) => {
			return (
				<tr key={index}>
					<td className="fw-bold text-center">{index + 1}</td>
					<td className="fw-bold text-center">{value.from}</td>
					<td className="fw-bold text-center">{value.role}</td>
					<td className="fw-bold text-center">{value.supplier.supplier_name}</td>
					<td className="fw-bold text-center">{new Date(value.date_created).toDateString('yyyy-MM-dd')}</td>
					<td className="fw-bold text-center">
						<button
							onClick={() => {
								onDelete(deleted, setDeleted, props.deleteSupplierEvaluation, value.supplier_evaluation_id);
							}}
							className="btn btn-danger mx-2">
							<i className="fa fa-trash  "></i>
						</button>
						<Link
							to={Routes.Procurement.SupplierEvaluation.Edit.path.replace(':id', value.supplier_evaluation_id)}
							className="btn btn-primary mx-2">
							<i className="fa fa-edit"></i>
						</Link>
					</td>
				</tr>
			);
		});
	};
	renderRows();

	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0 ">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{t('entities')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<Nav
					className="justify-content-center row"
					variant="tabs"
					defaultActiveKey={Routes.Procurement.Supplier.Index.path}>
					<Nav.Item className="col text-center">
						<Nav.Link
							onClick={() => {
								history.push(Routes.Procurement.Supplier.Index.path);
							}}>
							{t('suppliers-0')}{' '}
						</Nav.Link>
					</Nav.Item>
					<Nav.Item className="col text-center ">
						<Nav.Link
							className="bg-primary  text-white"
							onClick={() => {
								history.push(Routes.Procurement.SupplierEvaluation.Index.path);
							}}>
							{t('supplier-evaluation')}{' '}
						</Nav.Link>
					</Nav.Item>
				</Nav>
				<br />
				<div
					className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center
  ">
					<h4 style={{ display: 'inline' }}>{t('supplier-evaluations')}</h4>

					<Link className="btn btn-primary py-2 px-3  " to={Routes.Procurement.SupplierEvaluation.Create.path}>
						{t('add-new')}{' '}
					</Link>
				</div>
				<p className="mb-0">{t('supplier-evaluation-list')}</p>
			</div>

			<IndexTable
				loading={loading}
				headerProps="text-center"
				headers={['#', t('from'), t('role'), t('supplier-name'), t('date-created'), '']}
				renderRows={supplierEvaluations.length != 0 ? renderRows() : null}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.procurement.supplier.getSupplierEvaluationsLoading,
		supplierEvaluations: state.procurement.supplier.supplierEvaluations,
	};
};

export default connect(mapStateToProps, {
	clearData,
	getSupplierEvaluations,
	deleteSupplierEvaluation,
})(SupplierEvaluationIndex);
