import { CREATE_LOCATION, GET_LOCATIONS, GET_LOCATION, DELETE_LOCATION } from '../../actions/Delivery/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	locations: [],
	location: {},
	loading: true,
	getLocationLoading: true,
	getLocationsLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_LOCATIONS:
			return { ...state, locations: action.payload, getLocationsLoading: false };

		case GET_LOCATION:
			return { ...state, location: action.payload, getLocationLoading: false };

		case CREATE_LOCATION:
			return { ...state, location: action.payload };

		case CLEAR_DATA:
			return {
				...state,
				location: action.payload,
				getLocationLoading: true,
				getLocationsLoading: true,
			};

		case DELETE_LOCATION:
			return { ...state };

		default:
			return state;
	}
};
