import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';
import {
	CREATE_TICKET_CATEGORY,
	GET_TICKET_CATEGORY,
	GET_TICKET_CATEGORIES,
	UPDATE_TICKET_CATEGORY,
	DELETE_TICKET_CATEGORY,
} from './types';

export const getTicketCategory = (id) => async (dispatch) => {
	axios
		.get(`/ticket-category/detail/${id}`)
		.then((response) => {
			dispatch({ type: GET_TICKET_CATEGORY, payload: response.data });
		})
		.catch((err) => {});
};
export const getTicketCategories = () => async (dispatch) => {
	axios
		.get('/ticket-category')
		.then((response) => {
			dispatch({ type: GET_TICKET_CATEGORIES, payload: response.data });
		})
		.catch((err) => {});
};
export const createTicketCategory = (formValues) => async (dispatch, getState) => {
	axios
		.post('/ticket-category', formValues)
		.then((response) => {
			dispatch({ type: CREATE_TICKET_CATEGORY, payload: response.data });
			history.push(Routes.Admin.TicketCategory.Index.path);
		})
		.catch((err) => {});
};
export const updateTicketCategory = (formValues) => async (dispatch) => {
	axios
		.put(`/ticket-category/${formValues.department_id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_TICKET_CATEGORY, payload: response.data });
			history.push(Routes.Admin.TicketCategory.Index.path);
		})
		.catch((err) => {});
};
export const deleteTicketCategory = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`/ticket-category/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_TICKET_CATEGORY, payload: response.data });
			setDeleted(!deleted);
		})
		.catch((err) => {});
};
