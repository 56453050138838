import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getUserInvite, updateUserInvite } from '../../../../actions//Admin/userInviteActions';

import { getEntities } from '../../../../actions/Admin/entityActions';
import { getRoles } from '../../../../actions//User/userActions';
import { getDepartments, getDepartment } from '../../../../actions/Admin/departmentActions';

import InvitesForm from './InvitesForm';
import { Redirect } from 'react-router';
import { Routes } from '../../../../routes';
import LoadingSpinner from '../../../../components/LoadingSpinner';

const InviteEdit = (props) => {
	const { id } = props.match.params;
	const { user, loading, entities, roles, departments, getDepartmentsLoading } = props;

	useEffect(() => {
		props.getUserInvite(id);
		props.getRoles();
		props.getEntities();
		props.getDepartments();
	}, [loading]);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			props.updateUserInvite(formValues);
		}
	};

	if (loading || getDepartmentsLoading) {
		return <LoadingSpinner />;
	} else {
		if (user.is_registered) {
			return <Redirect to={Routes.Unauthorized.path} />;
		} else if (user.is_registered == undefined) {
			return <Redirect to={Routes.Unauthorized.path} />;
		}
		return (
			<div className="mt-3">
				<InvitesForm
					onSubmit={onSubmit}
					roles={roles}
					departments={departments}
					entities={entities}
					initials={{
						id: id,
						...user,
					}}
					emailDisabled="disabled"
				/>
			</div>
		);
	}
};

const mapStateToProps = (state) => {
	return {
		user: state.admin.userInvite.userInvite,
		loading: state.admin.userInvite.getUserInviteLoading,
		entities: state.admin.entity.entities,
		roles: state.user.roles,
		departmentsLoading: state.admin.department.getDepartmentsLoading,
		departments: state.admin.department.departments,
	};
};

export default connect(mapStateToProps, {
	getUserInvite,
	updateUserInvite,
	getRoles,
	getEntities,
	getDepartments,
	getDepartment,
})(InviteEdit);
