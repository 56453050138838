import { faEdit, faHome, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import IndexTable from '../../../components/IndexTable';
import { Routes } from '../../../routes';
import { getWorkflows, deleteWorkflow } from '../../../actions/Workflow/workflowActions';
import onDelete from '../../../helpers/deleteFunction';
import { useTranslation } from 'react-i18next';

const WorkflowIndex = (props) => {
	const { data, loading } = props;

	const [deleted, setDeleted] = useState(false);

	useEffect(() => {
		props.getWorkflows();
	}, [deleted]);
	const { t } = useTranslation();

	const renderRows = () => {
		return data.workflows.map((workflow, index) => {
			return (
				<tr key={index}>
					<td className="fw-bold text-center">{index + 1}</td>
					<td className="fw-bold text-center">{workflow?.workflow_name}</td>
					<td className="fw-bold text-center">{workflow?.form?.module.module_name}</td>
					<td className="fw-bold text-center">{workflow?.form?.form_name}</td>
					<td className="fw-bold text-center">{workflow?.user?.full_name}</td>
					<td className="fw-bold text-center">{new Date(workflow?.createdAt).toLocaleDateString()}</td>
					<td className="fw-bold text-center">{workflow?.description}</td>
					<td className="fw-bold text-center">{workflow?.status}</td>
					<td className="fw-bold text-center">
						{workflow.status != 'DELETED' ? (
							<>
								<button
									onClick={() => {
										onDelete(deleted, setDeleted, props.deleteWorkflow, workflow.workflow_id);
									}}
									className="btn btn-danger mx-2">
									<FontAwesomeIcon icon={faTrash} />
								</button>

								<Link
									to={Routes.Workflow.Edit.path.replace(':id', workflow.workflow_id)}
									className="btn btn-primary mx-2">
									<FontAwesomeIcon icon={faEdit} />
								</Link>
							</>
						) : null}
					</td>
				</tr>
			);
		});
	};

	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0 ">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{t('workflows')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>

				<div
					className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-5
  ">
					<h4 style={{ display: 'inline' }}>{t('workflows-0')}</h4>
					<Link className="btn btn-primary py-2 px-3  " to={Routes.Workflow.Create.path}>
						{t('add-new')}{' '}
					</Link>
				</div>
				<p className="mb-0">{t('your-most-recent-workflows-can-be-seen-here')}</p>
			</div>

			<IndexTable
				loading={loading}
				headerProps="text-center"
				headers={[
					'#',
					t('workflow-name'),
					t('module'),
					t('form'),
					t('creator'),
					t('created-at'),
					t('description'),
					t('status'),
					'',
				]}
				renderRows={data.workflows.length != 0 ? renderRows() : null}
				// filter={
				//     <div className="mb-3">
				//         <TableFilter
				//             initialFilterCategories={initialFilterCategories}
				//             filters={filters}
				//             setFilters={setFilters}
				//             datas={projects}
				//             setQuery={setQuery}
				//             filterCategories={filterCategories}
				//             setFilterCategories={setFilterCategories}
				//         />
				//     </div>
				// }
				// pagination={
				//     projects.length > 1 ? (
				//         <ReactPaginate
				//             nextLabel=" >"
				//             onPageChange={handlePageClick}
				//             pageCount={Math.ceil(_.last(projects).page_count)}
				//             pageRangeDisplayed={3}
				//             previousLabel="< "
				//             pageClassName="page-item"
				//             pageLinkClassName="page-link"
				//             previousClassName="page-item"
				//             forcePage={offset}
				//             previousLinkClassName="page-link"
				//             nextClassName="page-item"
				//             nextLinkClassName="page-link"
				//             breakLabel="..."
				//             breakClassName="page-item"
				//             breakLinkClassName="page-link"
				//             containerClassName="pagination"
				//             activeClassName="active"
				//             renderOnZeroPageCount={null}
				//         />
				//     ) : null
				// }
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		workflow: { workflows, getWorkflowsLoading },
	} = state;

	return { data: { workflows }, loading: getWorkflowsLoading };
};

export default connect(mapStateToProps, { getWorkflows, deleteWorkflow })(WorkflowIndex);
