import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';
import { CREATE_PUNCHLIST, DELETE_PUNCHLIST, GET_PUNCHLIST, GET_PUNCHLISTS, UPDATE_PUNCHLIST } from './types';

export const createPunchList = (formValues) => async (dispatch, getState) => {
	axios
		.post('/punch-list', formValues)
		.then((response) => {
			dispatch({ type: CREATE_PUNCHLIST, payload: response.data });
			history.push(Routes.Construction.PunchList.Index.path);
			toast.success('Punch List has been added.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		})
		.catch((err) => {
			toast.error('Something went wrong! Please open a ticket.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		});
};

export const getPunchLists = () => async (dispatch) => {
	const response = await axios.get('/punch-list');
	dispatch({ type: GET_PUNCHLISTS, payload: response.data });
};

export const getPunchListsWithFilter = (offset, limit, query, order, search) => async (dispatch) => {
	var params = '';
	params += order.length != 0 ? `${order}&` : '';
	params += query.length != 0 ? `${query}&` : '';
	params += search.length != 0 ? `${search}` : '';
	console.log(`/punch-list/offset/${offset}/limit/${limit}?${params}`);
	axios
		.get(`/punch-list/offset/${offset}/limit/${limit}?${params}`)
		.then((response) => {
			dispatch({ type: GET_PUNCHLISTS, payload: response.data });
		})
		.catch((err) => {});
};

// export const getPunchListContents = () => async (dispatch) => {
// 	const response = await axios.get('/punch-list/content/');
// 	dispatch({ type: GET_PUNCHLIST_CONTENTS, payload: response.data });
// };

export const getPunchList = (id) => async (dispatch) => {
	const response = await axios.get(`/punch-list/${id}`);
	dispatch({ type: GET_PUNCHLIST, payload: response.data });
};

export const deletePunchList = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`punch-list/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_PUNCHLIST, payload: response.data });
			setDeleted(!deleted);
			toast.success('Punch List has been deleted.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		})
		.catch((err) => {
			toast.error('Something went wrong! Please open a ticket.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		});
};

export const updatePunchList = (formValues) => async (dispatch) => {
	axios
		.put(`punch-list/${formValues.id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_PUNCHLIST, payload: response.data });
			history.push(Routes.Construction.PunchList.Index.path);
			toast.success('Punch List has been updated.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		})
		.catch((err) => {
			toast.error('Something went wrong! Please open a ticket.', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		});
};
