import {
	CREATE_START_WORK,
	GET_START_WORKS,
	GET_START_WORK,
	DELETE_START_WORK,
} from '../../actions/HumanResources/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	startWorks: [],
	startWork: {},
	loading: true,
	getStartWorkLoading: true,
	getStartWorksLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_START_WORKS:
			return { ...state, startWorks: action.payload, getStartWorksLoading: false };

		case GET_START_WORK:
			return { ...state, startWork: action.payload, getStartWorkLoading: false };

		case CREATE_START_WORK:
			return { ...state };

		case CLEAR_DATA:
			return {
				...state,
				startWork: action.payload,
				getStartWorkLoading: true,
				getStartWorksLoading: true,
			};

		case DELETE_START_WORK:
			return { ...state };

		default:
			return state;
	}
};
