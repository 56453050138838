import React from 'react';
import { connect } from 'react-redux';
import { getPunchListOverview } from '../../../actions/Others/powerbiActions';
import Report from '../Reports/Report';

const PunchListOverview = (props) => {
	return (
		<>
			<Report getEmbedInfo={props.getPunchListOverview} />
		</>
	);
};

export default connect(null, { getPunchListOverview })(PunchListOverview);
