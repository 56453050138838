export const CREATE_LOCATIONS = 'CREATE_LOCATIONS';
export const GET_LOCATION = 'GET_LOCATION';
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const DELETE_LOCATIONS = 'DELETE_LOCATIONS';
export const UPDATE_LOCATIONS = 'UPDATE_LOCATIONS';
export const GET_LOCATIONS_CONTENTS = 'GET_LOCATIONS_CONTENTS';

export const CREATE_PUNCHLIST = 'CREATE_PUNCHLIST';
export const GET_PUNCHLISTS = 'GET_PUNCHLISTS';
export const GET_PUNCHLIST = 'GET_PUNCHLIST';
export const DELETE_PUNCHLIST = 'DELETE_PUNCHLIST';
export const UPDATE_PUNCHLIST = 'UPDATE_PUNCHLIST';
export const GET_PUNCHLIST_CONTENTS = 'GET_PUNCHLIST_CONTENTS';

export const CREATE_DISCIPLINE = 'CREATE_DISCIPLINE';
export const GET_DISCIPLINES = 'GET_DISCIPLINES';
export const GET_DISCIPLINE = 'GET_DISCIPLINE';
export const DELETE_DISCIPLINE = 'DELETE_DISCIPLINE';
export const UPDATE_DISCIPLINE = 'UPDATE_DISCIPLINE';

export const CREATE_PROJECT = 'CREATE_PROJECT';
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECT = 'GET_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';

export const CREATE_PROJECT_BUILDING = 'CREATE_PROJECT_BUILDING';
export const GET_PROJECT_BUILDINGS = 'GET_PROJECT_BUILDINGS';
export const GET_PROJECT_BUILDING = 'GET_PROJECT_BUILDING';
export const DELETE_PROJECT_BUILDING = 'DELETE_PROJECT_BUILDING';
export const UPDATE_PROJECT_BUILDING = 'UPDATE_PROJECT';
