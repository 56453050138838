import React, { useState, useEffect } from 'react';
import TaskItem from './TaskItem';
import { Card, ProgressBar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { Routes } from '../../routes';

const Checklist = ({ initial, title, onSubmit, toggleChecklist }) => {
	const [tasks, setTasks] = useState([]);
	const [now, setNow] = useState(0);
	const [isInvalid, setIsInvalid] = useState(1);

	useEffect(() => {
		if (initial) {
			setTasks(initial);
			progress(initial);
		}
	}, []);

	const TaskList = ({ tasks, title, checkTask }) => {
		let taskItems = tasks.map((task, index) => {
			return <TaskItem tasks={tasks} task={task} key={index} onCheck={checkTask} />;
		});
		return (
			<>
				<h1 className="to-do-title">{title}</h1>
				<ul className="task-list">{taskItems}</ul>
			</>
		);
	};

	const isValid = (count) => {
		if (count === tasks.length) {
			setIsInvalid(0);
		} else {
			setIsInvalid(1);
		}
	};
	const progress = (tasks) => {
		var newValue = 0;
		var count = 0;
		for (var i = 0; i < tasks.length; i++) {
			if (tasks[i].status == true) {
				newValue += 100 / tasks.length;
				count++;
			}
		}
		setNow((count * 100) / tasks.length);
		isValid(count);
	};

	const handleCheckTask = (id) => {
		var tasksNew = tasks;
		var target = tasks.findIndex((index) => index.id === id);
		tasks[target].status = tasks[target].status === true ? false : true;
		setTasks(tasksNew);
		progress(tasks);
		toggleChecklist(tasks[target]);
	};

	return (
		<Card border="light" className="shadow-sm h-100  ">
			<Card.Body className="pb-0">
				<TaskList tasks={tasks} title={title} checkTask={handleCheckTask} />
				<ProgressBar now={now} />
			</Card.Body>
		</Card>
	);
};

export default Checklist;
