import React, { useEffect } from 'react';
import { Col, Row, Card } from '@themesberg/react-bootstrap';
import { Field, reduxForm, initialize, formValueSelector, change } from 'redux-form';
import { compose } from 'redux';
import validate from '../../../validate';
import renderDropdown from '../../../components/formComponents/renderDropdown';
import SubmitButton from '../../../components/formComponents/SubmitButton';
import renderInput from '../../../components/formComponents/renderInput';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Routes } from '../../../routes';
import OrderDetailCard from './OrderDetailCard';
import { getLocations } from '../../../actions/Delivery/locationActions';
import { createOrder } from '../../../actions/Delivery/orderActions';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { renderBuildingDropdown, renderFloorDropdown } from './Order/formComponents/renderDropdown';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const selector = formValueSelector('order');

const DeliveryCheckout = (props) => {
	const { t } = useTranslation();

	const { datas, initials, loading, formValues, createOrder } = props;

	const { order, user, locations } = datas;

	const { selectedBuildingId, selectedFloorId } = formValues;

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.createOrder(formValues);
		}
	};

	useEffect(() => {
		props.getLocations();

		{
			initials != null
				? props.initialize({
						...initials,

						user: user.full_name,
				  })
				: props.initialize({
						status: t('active-0'),
						user: user.full_name,
				  });
		}

		props.change('user_id', user.user_id);
		props.change(
			'order_details',
			order.map((item) => {
				return { item_name: item.item_name, item_id: item.item_id, item_count: item.item_amount };
			})
		);
	}, []);

	const renderBuildingOptions = () => {
		return locations.map((location, index) => {
			return (
				<option key={index} value={location.building_id}>
					{location.building_name}
				</option>
			);
		});
	};

	const renderFloorOptions = () => {
		if (selectedBuildingId && selectedBuildingId != 0) {
			const floors = _.find(locations, { building_id: Number(selectedBuildingId) }).floors;

			return floors.map((floor, index) => {
				return (
					<option key={index} value={floor.floor_id}>
						{floor.floor_name}
					</option>
				);
			});
		} else {
			null;
		}
	};

	const renderRoomOptions = () => {
		if (selectedBuildingId && selectedBuildingId != 0 && selectedFloorId && selectedFloorId != 0) {
			const floors = _.find(locations, { building_id: Number(selectedBuildingId) }).floors;
			const rooms = _.find(floors, { floor_id: Number(selectedFloorId) }).rooms;

			return rooms.map((room, index) => {
				return (
					<option key={index} value={room.room_id}>
						{room.room_name}
					</option>
				);
			});
		} else {
			null;
		}
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<form onSubmit={props.handleSubmit(onSubmit)}>
					<Row className="mt-3">
						<Col xl={8} lg={8} md={12} sm={12} xs={12}>
							<Card border="light" className="bg-white shadow-sm mb-4  w-100">
								<Card.Body>
									<h5 className="mb-4">{t('checkout')}</h5>
									<Row className="mb-3 ">
										<Col md={6} sm={12}>
											<Field name="user" label={t('by')} component={renderInput} disabled="disabled" />
										</Col>
										<Col md={6} sm={12}>
											<Field name="to_email" label={t('to-email')} component={renderInput} />
										</Col>
									</Row>
									<Row>
										<Col>
											<Field
												name="building_id"
												label={t('building')}
												component={renderBuildingDropdown}
												change={props.change}
												renderedOptions={renderBuildingOptions()}
											/>
										</Col>
									</Row>
									<Row className="mb-3">
										<Col>
											<Field
												name="floor_id"
												label={t('floor')}
												component={renderFloorDropdown}
												change={props.change}
												renderedOptions={renderFloorOptions()}
											/>
										</Col>
									</Row>
									<Row className="mb-3">
										<Col>
											<Field
												name="room_id"
												label={t('room')}
												component={renderDropdown}
												renderedOptions={renderRoomOptions()}
											/>
										</Col>
									</Row>
									<Col className="mb-3">
										<Field name="notes" label={t('notes')} component={renderInput} />
									</Col>

									<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
										<Link to={Routes.Delivery.Index.path} className="btn btn-secondary">
											{t('back')}{' '}
										</Link>
										<SubmitButton invalid={false} onSubmit={onSubmit} text={t('submit')} />
									</div>
								</Card.Body>
							</Card>
						</Col>
						<Col xl={4} lg={4} md={12} sm={12} xs={12}>
							<OrderDetailCard showCheckoutButton={false} order={order} />
						</Col>
					</Row>
				</form>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const selectedBuildingId = selector(state, 'building_id');
	const selectedFloorId = selector(state, 'floor_id');

	const {
		delivery: {
			order: { order },
			location: { locations, getLocationsLoading },
		},
		auth: { full_name, user_id },
	} = state;

	return {
		datas: { order, user: { full_name, user_id }, locations },
		loading: getLocationsLoading,
		formValues: { selectedBuildingId, selectedFloorId },
	};
};

export default compose(
	connect(mapStateToProps, { initialize, getLocations, change, createOrder }),

	reduxForm({
		form: 'order',
		validate,
	})
)(DeliveryCheckout);
