import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getSupplierEvaluation, updateSupplierEvaluation } from '../../../../../actions//Procurement/suppliersActions';
import { clearData } from '../../../../../actions/globalActions';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import SupplierFeedbackForm from './SupplierFeedbackForm';
import { getLPOs } from '../../../../../actions/Procurement/lpoActions';

const SupplierEvaluationEdit = (props) => {
	const { id } = props.match.params;
	const {
		datas: { supplierEvaluation, lpos },
		loading,
	} = props;

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			props.updateSupplierEvaluation(formValues);
		}
	};

	useEffect(() => {
		props.getSupplierEvaluation(id);
		props.getLPOs();

		return () => {
			props.clearData();
		};
	}, []);

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<SupplierFeedbackForm datas={lpos} onSubmit={onSubmit} initials={supplierEvaluation} />
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		procurement: {
			lpo: { lpos, getLPOsLoading },
			supplier: { supplierEvaluation, getSupplierEvaluationLoading },
		},
	} = state;

	return {
		datas: { supplierEvaluation, lpos },
		loading: getLPOsLoading || getSupplierEvaluationLoading,
	};
};

export default connect(mapStateToProps, {
	getSupplierEvaluation,
	updateSupplierEvaluation,
	clearData,
	getLPOs,
})(SupplierEvaluationEdit);
