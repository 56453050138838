import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import QuotationForm from './QuotationForm';
import { getSuppliers } from '../../../../actions/Procurement/suppliersActions';
import { getISR } from '../../../../actions/Procurement/isrActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { createQuotation } from '../../../../actions/Procurement/quotationActions';
import _ from 'lodash';

const QuotationCreate = (props) => {
	const { datas, loading } = props;
	const { isrId } = props.match.params;

	useEffect(() => {
		props.getSuppliers();
		props.getISR(isrId);
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.createQuotation(formValues);
		}
	};

	return (
		<div className="mt-3">
			{loading ? <LoadingSpinner /> : <QuotationForm onSubmit={onSubmit} isrId={isrId} datas={datas} />}
		</div>
	);
};

const mapStateToProps = (state) => {
	const {
		auth: { user_id, full_name, companies },
		procurement: {
			supplier: { suppliers, getSuppliersLoading },
			isr: { isr, getISRLoading },
		},
	} = state;

	return {
		datas: {
			user: { user_id, full_name, companies },
			suppliers,
			isr,
		},
		loading: getSuppliersLoading || getISRLoading,
	};
};

export default connect(mapStateToProps, { getSuppliers, getISR, createQuotation })(QuotationCreate);
