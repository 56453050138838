import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import LeaveForm from './LeaveForm';
import { getEmployees } from '../../../../actions/HumanResources/employeeActions';
import { updateLeave, getLeave } from '../../../../actions/HumanResources/leaveRequestActions';
import { clearData } from '../../../../actions/globalActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import _ from 'lodash';

const LeaveEdit = (props) => {
	const { id } = props.match.params;
	const { datas, loading } = props;

	useEffect(() => {
		props.getEmployees();
		props.getLeave(id);
		return () => {
			props.clearData();
		};
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.updateLeave(formValues);
		}
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<LeaveForm datas={datas} onSubmit={onSubmit} initials={datas.leave} />
				</div>
			)}
		</>
	);
};
const mapStateToProps = (state) => {
	const {
		humanResources: {
			employee: { employees, getEmployeesLoading },
			leave: { leave, getLeaveLoading },
		},
	} = state;
	return {
		datas: { employees, leave },
		loading: getLeaveLoading || getEmployeesLoading,
	};
};

export default connect(mapStateToProps, { getEmployees, clearData, getLeave, updateLeave })(LeaveEdit);
