import { faEdit, faHome, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import IndexTable from '../../../../components/IndexTable';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { Routes } from '../../../../routes';
import {
	getHREmployeeEvaluations,
	deleteHREmployeeEvaluation,
} from '../../../../actions/HumanResources/employeeEvaluationActions';
import { useEffect } from 'react';
import authorizationCheckFunction from '../../../../helpers/authorizationCheckFunction';
import onDelete from '../../../../helpers/deleteFunction';
import { clearData } from '../../../../actions/globalActions';
import { useTranslation } from 'react-i18next';

const EmployeeEvaluationIndex = (props) => {
	const { employeeEvaluations, getEmployeeEvaluationsLoading } = props;
	const { t } = useTranslation();

	const [deleted, setDeleted] = useState(false);

	useEffect(() => {
		props.getHREmployeeEvaluations();

		return () => {
			props.clearData();
		};
	}, [deleted]);

	const renderRows = () => {
		return employeeEvaluations.map((eva, index) => {
			return (
				<tr key={index}>
					<td className="fw-bold text-center">{eva.reference}</td>
					<td className="fw-bold  text-center">{eva.employee.full_name}</td>
					<td className="fw-bold  text-center">{eva.overall_assesment}</td>
					<td className="fw-bold  text-center">{eva.number_of_warning_letters}</td>
					<td className="fw-bold  text-center">{eva.supervisor}</td>
					<td className="fw-bold  text-center">{eva.status}</td>
					{eva.status !== 'DELETED' ? (
						<td className="fw-bold text-center">
							{/* {authorizationCheckFunction(Routes.ProjectDelete.policy, userPolicy) ? ( */}
							<button
								onClick={() => {
									onDelete(deleted, setDeleted, props.deleteHREmployeeEvaluation, eva.id);
								}}
								className="btn btn-danger mx-2">
								<FontAwesomeIcon icon={faTrash} />
							</button>
							{/* ) : null} */}
							{/* {authorizationCheckFunction(Routes.ProjectDelete.policy, userPolicy) ? ( */}
							<Link
								to={Routes.HumanResources.EmployeeEvaluation.Edit.path.replace(':id', eva.id)}
								className="btn btn-primary mx-2">
								<FontAwesomeIcon icon={faEdit} />
							</Link>
							{/* ) : null} */}
						</td>
					) : (
						<td></td>
					)}
				</tr>
			);
		});
	};

	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0 ">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.HumanResources.Overview.path }}>
							{t('human-resources')}{' '}
						</Breadcrumb.Item>

						<Breadcrumb.Item active>{t('employee-evaluation')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>

				<div
					className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-5
">
					<h4 style={{ display: 'inline' }}>{t('hr-employee-evaluations')}</h4>
					<Link className="btn btn-primary py-2 px-3  " to={Routes.HumanResources.EmployeeEvaluation.Create.path}>
						{t('add-new')}{' '}
					</Link>
				</div>
				<p className="mb-0">{t('your-most-recent-evaluations-can-be-seen-here')}</p>
			</div>

			<IndexTable
				loading={getEmployeeEvaluationsLoading}
				headerProps="text-center"
				headers={[
					t('reference'),
					t('employee-name'),
					t('overall-assesment'),
					t('reference-of-warning-letters'),
					t('supervisor'),
					t('status'),
					'',
				]}
				renderRows={employeeEvaluations.length != 0 ? renderRows() : null}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		employeeEvaluations: state.humanResources.employeeEvaluation.employeeEvaluations,
		getEmployeeEvaluationsLoading: state.humanResources.employeeEvaluation.getEmployeeEvaluationsLoading,
	};
};

export default connect(mapStateToProps, { getHREmployeeEvaluations, deleteHREmployeeEvaluation, clearData })(
	EmployeeEvaluationIndex
);
