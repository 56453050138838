import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Report from '../Reports/Report';
import { getProjectOverview } from '../../../actions/Others/powerbiActions';

const ProjectOverview = (props) => {
	return (
		<>
			<Report getEmbedInfo={props.getProjectOverview} />
		</>
	);
};

export default connect(null, { getProjectOverview })(ProjectOverview);
