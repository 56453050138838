import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ISRForm from './ISRForm';
import { getUsersByRole } from '../../../../actions//User/userActions';

import { getISR, updateISR } from '../../../../actions//Procurement/isrActions';
import { clearData } from '../../../../actions/globalActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { getProjects } from '../../../../actions//Finance/projectActions';
import { getCategories, getItems } from '../../../../actions/productActions';
const ISREdit = (props) => {
	const { id } = props.match.params;
	const { loading, datas } = props;

	useEffect(() => {
		props.getUsersByRole('Cost Controller');
		props.getISR(id);
		props.getProjects();
		props.getCategories();
		props.getItems();
		return () => {
			props.clearData();
		};
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			if (formValues.isr_contents.length != 0) {
				formValues.isr_contents.map((content, index) => {
					formValues.isr_contents[index].item_name = content['item'].item_name;
					formValues.isr_contents[index].item_code = content['item'].item_code;
					formValues.isr_contents[index].item_category_name = content['category'].item_category_name;
					formValues.isr_contents[index].item_category_code = content['category'].item_category_code;
				});
			}
			props.updateISR(formValues);
		}
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<ISRForm onSubmit={onSubmit} initials={datas.isr} datas={datas} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		user: { users, getUsersLoading },
		finance: {
			project: { getProjectsLoading, projects },
		},
		product: { getCategoriesLoading, getItemsLoading, items, categories },
		procurement: {
			isr: {
				isr: { isr },
				getISRLoading,
			},
		},
	} = state;

	return {
		loading: getISRLoading || getUsersLoading || getProjectsLoading || getCategoriesLoading || getItemsLoading,
		datas: { costControllers: users, projects, categories, items, isr },
	};
};

export default connect(mapStateToProps, {
	getISR,
	clearData,
	updateISR,
	getUsersByRole,
	getProjects,
	getItems,
	getCategories,
})(ISREdit);
