import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import TerminationForm from './TerminationForm';
import { getTermination, updateTermination } from '../../../../actions/HumanResources/terminationActions';
import { getEmployees } from '../../../../actions/HumanResources/employeeActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { clearData } from '../../../../actions/globalActions';

const TerminationEdit = (props) => {
	const { id } = props.match.params;

	const { datas, loading } = props;

	useEffect(() => {
		props.getTermination(id);
		props.getEmployees();

		return () => {
			props.clearData();
		};
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.updateTermination(formValues);
		}
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<TerminationForm onSubmit={onSubmit} initials={datas.termination} datas={datas} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		humanResources: {
			employee: { employees, getEmployeesLoading },
			termination: { termination, getTerminationLoading },
		},
	} = state;

	return {
		datas: { employees, termination },
		loading: getEmployeesLoading || getTerminationLoading,
	};
};

export default connect(mapStateToProps, { updateTermination, getTermination, getEmployees, clearData })(
	TerminationEdit
);
