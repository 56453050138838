import React from 'react';
import { connect } from 'react-redux';

import DepartmentForm from './DepartmentForm';

import { createDepartment } from '../../../../actions/Admin/departmentActions';

class DepartmentCreate extends React.Component {
	onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			this.props.createDepartment(formValues);
		}
	};

	render() {
		return (
			<div className="mt-3">
				<DepartmentForm onSubmit={this.onSubmit} />
			</div>
		);
	}
}

export default connect(null, { createDepartment })(DepartmentCreate);
