import React from "react";

import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/react";

const override = css`
	display: block;
	margin: 0 auto;
	border-color: red;
`;

export default (props) => {
	const { show } = props;

	return (
		<div
			className={`preloader bg-soft flex-column justify-content-center align-items-center ${
				show ? "" : "show"
			}`}>
			<BeatLoader color="black" loading={show} css={override} size={20} />
		</div>
	);
};
