import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';

import {
	CREATE_SUPPLIER,
	GET_SUPPLIERS,
	GET_SUPPLIER,
	DELETE_SUPPLIER,
	UPDATE_SUPPLIER,
	CREATE_SUPPLIER_EVALUATION,
	GET_SUPPLIER_EVALUATIONS,
	DELETE_SUPPLIER_EVALUATION,
	GET_SUPPLIER_EVALUATION,
	UPDATE_SUPPLIER_EVALUATION,
} from './types';

export const getSuppliers = (offset, limit, query) => async (dispatch) => {
	axios
		.get('/supplier/contact')
		// .get(`/supplier/offset/${offset}/limit/${limit}?${query}`)
		.then((response) => {
			dispatch({ type: GET_SUPPLIERS, payload: response.data });
		})
		.catch((err) => {});
};

export const getSuppliersWithFilter = (offset, limit, query, order, search) => async (dispatch) => {
	var params = '';
	params += order.length != 0 ? `${order}&` : '';
	params += query.length != 0 ? `${query}&` : '';
	params += search.length != 0 ? `${search}` : '';

	axios
		.get(`/supplier/offset/${offset}/limit/${limit}?${params}`)
		.then((response) => {
			dispatch({ type: GET_SUPPLIERS, payload: response.data });
		})
		.catch((err) => {});
};

export const getSupplier = (id) => async (dispatch) => {
	axios
		.get(`/supplier/contact/${id}`)
		.then((response) => {
			dispatch({ type: GET_SUPPLIER, payload: response.data });
		})
		.catch((err) => {});
};

export const createSupplier = (formValues) => async (dispatch, getState) => {
	axios
		.post('/supplier/contact', formValues)
		.then((response) => {
			dispatch({ type: CREATE_SUPPLIER, payload: response.data });
			history.push(Routes.Procurement.Supplier.Index.path);
		})
		.catch((err) => {});
};

export const deleteSupplier = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`supplier/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_SUPPLIER, payload: response.data });
			setDeleted(!deleted);
		})
		.catch((err) => {});
};

export const updateSupplier = (formValues) => async (dispatch) => {
	console.log('Update => ', formValues);
	axios
		.put(`supplier/${formValues.id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_SUPPLIER, payload: response.data });
			history.push(Routes.Procurement.Supplier.Index.path);
		})
		.catch((err) => {});
};

// SUPPLIER EVALUATION

export const getSupplierEvaluation = (id) => async (dispatch) => {
	axios
		.get(`supplier-evaluation/${id}`)
		.then((response) => {
			dispatch({ type: GET_SUPPLIER_EVALUATION, payload: response.data });
		})
		.catch((err) => {});
};

export const deleteSupplierEvaluation = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`supplier-evaluation/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_SUPPLIER_EVALUATION, payload: response.data });
			setDeleted(!deleted);
			history.push(Routes.Procurement.SupplierEvaluation.Index.path);
		})
		.catch((err) => {});
};

export const updateSupplierEvaluation = (formValues) => async (dispatch) => {
	axios
		.put(`supplier-evaluation/${formValues.supplier_evaluation_id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_SUPPLIER_EVALUATION, payload: response.data });
			history.push(Routes.Procurement.SupplierEvaluation.Index.path);
		})
		.catch((err) => {});
};

export const createSupplierEvaluation = (formValues) => async (dispatch) => {
	axios
		.post(`supplier-evaluation`, formValues)
		.then((response) => {
			dispatch({ type: CREATE_SUPPLIER_EVALUATION, payload: response.data });
			history.push(Routes.Procurement.SupplierEvaluation.Index.path);
		})
		.catch((err) => {});
};

export const getSupplierEvaluations = () => async (dispatch) => {
	axios
		.get('supplier-evaluation')
		.then((response) => {
			dispatch({ type: GET_SUPPLIER_EVALUATIONS, payload: response.data });
		})
		.catch((err) => {});
};
