export const GET_ROLES = 'GET_ROLES';
export const GET_USERS = 'GET_USERS';
export const GET_USERS_WITH_FILTER = 'GET_USERS_WITH_FILTER';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const CREATE_USER_PROFILE = 'CREATE_USER_PROFILE';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const GET_USER = 'GET_USER';

// NOTIFICATION

export const GET_RECENT_NOTIFICATIONS = 'GET_RECENT_NOTIFICATIONS';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
