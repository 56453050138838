import { Card, Col, Row } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { change, Field, initialize, reduxForm } from 'redux-form';

import renderInput from '../../../../components/formComponents/renderInput';
import SubmitButton from '../../../../components/formComponents/SubmitButton';
// import validate from '../../../../validate';
import { email, nonZero, notEmpty, required } from '../../../../helpers/validator';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { clearData } from '../../../../actions/globalActions';
import renderDropdown from '../../../../components/formComponents/renderDropdown';
import renderMultiselect from '../../../../components/formComponents/renderMultiselect';
import { t } from 'i18next';

import axios from '../../../../apis/axios';
import { useTranslation } from 'react-i18next';

const InvitesForm = (props) => {
	const { onSubmit, roles, entities, initials, emailDisabled, invalid, departments, from } = props;

	const [inviteCheck, setInviteCheck] = useState(null);
	const [buttonDisable, setButtonDisable] = useState(false);
	const { t } = useTranslation();
	useEffect(() => {
		if (initials) {
			props.initialize(initials);
		}
		return () => {
			props.clearData();
		};
	}, []);

	useEffect(() => {
		props.change('isValid', !invalid);
		props.change('from', from);
	}, [invalid]);

	const renderDepartments = () => {
		return departments.map((department, index) => {
			// eslint-disable-next-line react/jsx-key
			return (
				<option key={index} value={department.department_id}>
					{department.department_name}
				</option>
			);
		});
	};

	const checkInvıte = (email) => {
		if (email) {
			axios.get(`invite/check/${email}`).then((response) => {
				if (response.data == 'registered') {
					setInviteCheck(t('this-email-is-already-registered'));
					setButtonDisable(true);
				} else if (response.data == t('not-expired')) {
					setInviteCheck(t('this-email-is-already-invited'));
					setButtonDisable(false);
				} else if (response.data == 'expired') {
					setInviteCheck(null);
					setButtonDisable(false);
				} else if (response.data == 'valid') {
					setInviteCheck(null);
					setButtonDisable(false);
				}

				// setInviteCheck(response.data);
			});
		} else {
			setInviteCheck(null);
			setButtonDisable(false);
		}
	};

	return (
		<form onSubmit={props.handleSubmit(onSubmit)}>
			<Row>
				<Col xl={8} lg={8} md={12} sm={12} xs={12}>
					<Card border="light" className="bg-white shadow-sm mb-4  w-100">
						<Card.Body>
							<h5 className="mb-4">{t('user-invite-form')}</h5>

							<Row>
								<Col className="mb-3">
									<Field
										disabled={emailDisabled}
										name="email"
										component={renderInput}
										onChange={(event) => {
											checkInvıte(event.target.value);
										}}
										label={t('mail')}
										validate={[required, email]}
									/>
								</Col>
							</Row>
							<Row>
								<Col className="mb-3">
									<label className="form-label">{t('role')}</label>

									<Field
										validate={notEmpty}
										name="roles"
										component={renderMultiselect}
										textField="role_name"
										data={roles}
										dataKey="role_id"
									/>
								</Col>
							</Row>
							<Row>
								<Col className="mb-3">
									<label className="form-label">{t('company')}</label>

									<Field
										validate={notEmpty}
										name="companies"
										component={renderMultiselect}
										textField="company_name"
										data={entities}
										dataKey="company_id"
									/>
								</Col>
							</Row>
							<Row>
								<Col className="mb-3">
									<Field
										validate={nonZero}
										name="department_id"
										label={t('department-0')}
										component={renderDropdown}
										renderedOptions={renderDepartments()}
									/>
								</Col>
							</Row>
							{inviteCheck && <strong className="text-danger">{inviteCheck}</strong>}

							<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
								<a onClick={() => window.history.back()} className="btn btn-secondary">
									{t('back')}{' '}
								</a>
								<SubmitButton disabled={buttonDisable} invalid={invalid} onSubmit={props.onSubmit} text={t('submit')} />
							</div>
						</Card.Body>
					</Card>
				</Col>
				<Col xl={4} lg={4} md={12} sm={12} xs={12}></Col>
			</Row>
		</form>
	);
};

const mapStateToProps = (state) => {
	const {
		auth: { email },
	} = state;

	return { from: email };
};

export default compose(
	connect(mapStateToProps, { initialize, clearData, change }),
	reduxForm({ form: 'userInvite' })
)(InvitesForm);
