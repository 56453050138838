import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import '../scss/volt/components/_react-markdown.scss';
import faqMD from '../markdown/faq.md';
import { Card, Row, Col, Accordion } from '@themesberg/react-bootstrap';
import AccordionBody from '@themesberg/react-bootstrap/lib/esm/AccordionBody';

const FAQ = () => {
	const [fetchedText, setFetchedText] = useState(null);
	var questionAnswer;
	useEffect(() => {
		fetch(faqMD)
			.then((response) => response.text())
			.then((text) => {
				setFetchedText(text);
			});
	}, []);
	if (fetchedText) {
		questionAnswer = fetchedText.split('####');
		for (var i = 0; i < questionAnswer.length; i++) {
			questionAnswer[i] = '#### ' + questionAnswer[i] + '` `';
		}
		questionAnswer.shift();
	}
	return (
		<div className="body">
			<Card className="w-100">
				{fetchedText ? (
					<>
						<ReactMarkdown className="reactMarkdownHeader text-center w-100" children={fetchedText.split('####')[0]} />
						<Accordion>
							{questionAnswer.map((questionAnswer, key) => {
								return (
									<Accordion.Item key={key} eventKey={key} className="faq-accordion-item">
										<Accordion.Header className="faq-accordion-header">
											<ReactMarkdown className="reactMarkdown" children={questionAnswer.split('**Answer:**')[0]} />
										</Accordion.Header>
										<Accordion.Body className="faq-accordion-body">
											<ReactMarkdown className="reactMarkdown" children={questionAnswer.split('**Answer:**')[1]} />
										</Accordion.Body>
									</Accordion.Item>
								);
							})}
						</Accordion>
					</>
				) : null}
			</Card>
		</div>
	);
};

export default FAQ;
