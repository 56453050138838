import { CREATE_RESIGNATION, UPDATE_RESIGNATION, GET_RESIGNATIONS, GET_RESIGNATION, DELETE_RESIGNATION } from './types';

import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';

export const createResignation = (formValues) => async (dispatch, getState) => {
	axios
		.post('/resignation', formValues)
		.then((response) => {
			dispatch({ type: CREATE_RESIGNATION, payload: response.data });
			history.push(Routes.HumanResources.Resignation.Index.path);
		})
		.catch((err) => {});
};

export const getResignations = () => async (dispatch) => {
	axios
		.get('/resignation')
		.then((response) => {
			dispatch({ type: GET_RESIGNATIONS, payload: response.data });
		})
		.catch((err) => {});
};

export const getResignationsWithFilter = (offset, limit, query) => async (dispatch) => {
	axios
		.get(`/resignation/offset/${offset}/limit/${limit}?${query}`)
		.then((response) => {
			dispatch({ type: GET_RESIGNATIONS, payload: response.data });
		})
		.catch((err) => {});
};

export const getResignation = (id) => async (dispatch) => {
	const response = await axios.get(`/resignation/${id}`);
	dispatch({ type: GET_RESIGNATION, payload: response.data });
};

export const deleteResignation = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`resignation/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_RESIGNATION, payload: response.data });
			setDeleted(!deleted);
		})
		.catch((err) => {});
};

export const updateResignation = (formValues) => async (dispatch) => {
	axios
		.put(`resignation/${formValues.id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_RESIGNATION, payload: response.data });
			history.push(Routes.HumanResources.Resignation.Index.path);
		})
		.catch((err) => {});
};
