import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faBell, faCoffee, faExternalLinkAlt, faSignOutAlt, faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Container, Form, ListGroup, Nav, Navbar, Row } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import NOTIFICATIONS_DATA from '../data/notifications';
import history from '../history';
import searchData from '../searchData.json';
import SearchBar from './SearchBar';

import { useMsal } from '@azure/msal-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Routes } from '../routes';

import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { getRecentNotifications, notificationRead } from '../actions/User/notificationActions';
import HeaderNotification from './HeaderNotification';
import LoadingSpinner from './LoadingSpinner';
const Header = (props) => {
	const { user, recentNofitications, getRecentNotificationsLoading, notificationRead } = props;

	const { instance } = useMsal();

	const { i18n } = useTranslation();

	const handleLogout = () => {
		instance.logoutRedirect({
			postLogoutRedirectUri: '/',
		});
	};

	useEffect(() => {
		props.getRecentNotifications(user.user_id);
	}, [getRecentNotificationsLoading]);

	const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
	const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);

	const markNotificationsAsRead = () => {
		setTimeout(() => {
			setNotifications(notifications.map((n) => ({ ...n, read: true })));
		}, 300);
	};

	return (
		<Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
			<Container fluid className="px-0">
				<div className="d-flex justify-content-between w-100">
					<div className="d-flex align-items-center">
						{/* <Form className="navbar-search">
              <Form.Group id="topbarSearch">
                <InputGroup className="input-group-merge search-bar">
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                  <Form.Control type="text" placeholder="Search" />
                </InputGroup>
              </Form.Group>
            </Form> */}
						<SearchBar data={searchData} placeholder="Search" />
					</div>
					<Nav className="align-items-center ">
						<Nav.Link
							as={Link}
							to={Routes.Delivery.Index.path}
							className="text-dark icon-notifications d-flex justify-content-center ">
							<span className="icon icon-sm">
								<FontAwesomeIcon icon={faCoffee} className="bell-shake" />
							</span>
						</Nav.Link>

						<Dropdown as={Nav.Item} onToggle={markNotificationsAsRead}>
							<Dropdown.Toggle
								as={Nav.Link}
								className="text-dark icon-notifications me-lg-3 d-flex justify-content-center">
								<span className="icon icon-sm">
									<FontAwesomeIcon icon={faBell} className="bell-shake" />
									{areNotificationsRead ? null : <span className="icon-badge rounded-circle unread-notifications" />}
								</span>
							</Dropdown.Toggle>
							<Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
								<ListGroup className="list-group-flush">
									<Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">
										Notifications
									</Nav.Link>

									{getRecentNotificationsLoading ? (
										<LoadingSpinner />
									) : recentNofitications.length == 0 ? (
										<ListGroup.Item className="border-bottom border-light">
											<Row className="align-items-center">
												<Col className="ps-0 ms--2">
													<p className="font-small text-center mt-1 mb-0">There is not any new notification</p>
												</Col>
											</Row>
										</ListGroup.Item>
									) : (
										recentNofitications.map((notif, index) => {
											return <HeaderNotification key={index} {...notif} />;
										})
									)}

									<Dropdown.Item
										onClick={() => history.push(Routes.Notification.path)}
										className="text-center text-primary fw-bold py-3">
										View all
									</Dropdown.Item>
								</ListGroup>
							</Dropdown.Menu>
						</Dropdown>

						<div
							className="text-center text-muted fw-bold pe-3"
							style={{ borderLeft: '1px solid #757575', height: '30px' }}></div>

						<Dropdown autoClose="outside" as={Nav.Item}>
							<Dropdown.Toggle variant="primary" as={Nav.Link} className="pt-1 px-0">
								<div className="media d-flex align-items-center">
									<div
										className="user-avatar rounded-circle text-dark"
										style={{
											backgroundColor: `#FFA500
											`,
										}}>
										{user.first_name != null ? user.first_name[0] : null}
										{user.last_name != null ? user.last_name[0] : null}
									</div>
									<div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
										<span className="mb-0 font-small fw-bold">
											{props.user.first_name != null
												? props.user.first_name + ' ' + props.user.last_name
												: 'Unauthorized User'}
										</span>
									</div>
								</div>
							</Dropdown.Toggle>
							<Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
								<Link to={Routes.Profile.Index.path} />

								<Dropdown.Item
									className="fw-bold"
									onClick={() => {
										history.push(Routes.Profile.Index.path);
									}}>
									<FontAwesomeIcon icon={faUserCircle} className="me-2" /> My Profile
								</Dropdown.Item>
								<Dropdown.Item className="fw-bold" target="_blank" href="https://www.office.com/">
									<FontAwesomeIcon icon={faExternalLinkAlt} className="me-2" /> Services
								</Dropdown.Item>
								<Dropdown.Item
									className="fw-bold"
									onClick={() => {
										history.push(Routes.Support.path);
									}}>
									<FontAwesomeIcon icon={faTicketAlt} className="me-2" /> Support
								</Dropdown.Item>
								<Dropdown.Item>
									<Form.Select
										className=""
										size="sm"
										onChange={(e) => {
											i18n.changeLanguage(e.target.value);
										}}
										value={i18n.language}>
										<option value="" disabled selected>
											{t('Change language')}
										</option>

										<option value={'tr'}>{t('TR')}</option>
										<option value={'en'}>{t('EN')}</option>
										{/* <option value={'ar'}>{t('AR')}</option> */}
									</Form.Select>
									{/* <Dropdown variant="primary" drop="down" autoClose="outside">
										<Dropdown.Toggle variant="primary"></Dropdown.Toggle>
										<Dropdown.Menu className=" dropdown-menu-right mt-2">
											<Dropdown.Item>A</Dropdown.Item>
											<Dropdown.Item>A</Dropdown.Item>
											<Dropdown.Item>A</Dropdown.Item>
											<Dropdown.Item>A</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown> */}
								</Dropdown.Item>
								<Dropdown.Divider />
								<Dropdown.Item as="button" onClick={() => handleLogout()} className="fw-bold">
									<FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Nav>
				</div>
			</Container>
		</Navbar>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.auth,
		recentNofitications: state.notification.recentNofitications,
		getRecentNotificationsLoading: state.notification.getRecentNotificationsLoading,
	};
};

export default connect(mapStateToProps, {
	getRecentNotifications,
	notificationRead,
})(Header);
