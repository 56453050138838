import { CREATE_LEAVE, GET_LEAVES, GET_LEAVE, DELETE_LEAVE } from '../../actions/HumanResources/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	leaves: [],
	leave: {},
	loading: true,
	getLeaveLoading: true,
	getLeavesLoading: true,
};
export default (state = initialState, action) => {
	switch (action.type) {
		case GET_LEAVES:
			return { ...state, leaves: action.payload, getLeavesLoading: false };
		case GET_LEAVE:
			return { ...state, leave: action.payload, getLeaveLoading: false };
		case CREATE_LEAVE:
			return { ...state };
		case CLEAR_DATA:
			return {
				...state,
				leave: action.payload,
				getLeaveLoading: true,
				getLeavesLoading: true,
			};
		case DELETE_LEAVE:
			return { ...state };
		default:
			return state;
	}
};
