import {
	CREATE_ITEM,
	GET_ITEMS,
	GET_ITEM,
	DELETE_ITEM,
	GET_CATEGORIES,
	CREATE_CATEGORY,
	DELETE_CATEGORY,
	GET_CATEGORY,
} from '../../actions/Delivery/types';
import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	items: [],
	item: {},
	loading: true,
	getItemLoading: true,
	getItemsLoading: true,

	categories: [],
	category: {},
	getCategoriesLoading: true,
	getCategoryLoading: true,
	categoryLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_ITEMS:
			return { ...state, items: action.payload, getItemsLoading: false };

		case GET_ITEM:
			return { ...state, item: action.payload, getItemLoading: false };

		case CREATE_ITEM:
			return { ...state, item: action.payload };

		case DELETE_ITEM:
			return { ...state };

		case GET_CATEGORIES:
			return { ...state, categories: action.payload, getCategoriesLoading: false };
		case GET_CATEGORY:
			return { ...state, category: action.payload, getCategoryLoading: false };
		case CREATE_CATEGORY:
			return { ...state, category: action.payload };
		case DELETE_CATEGORY:
			return { ...state };

		case CLEAR_DATA:
			return {
				...state,
				item: action.payload,
				getItemLoading: true,
				getItemsLoading: true,
			};

		default:
			return state;
	}
};
