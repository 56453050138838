import { Col, Row } from '@themesberg/react-bootstrap';

export default ({ input, meta, options, flexDirection = 'flex-row' }) => {
	const renderOptions = () => {
		return options.map((val, index) => {
			return (
				<Col className="p-1" key={index}>
					<button
						{...input}
						className={` w-100 ${input.value == val.value ? 'btn btn-primary' : 'btn btn-outline-primary'} `}
						onClick={(e) => {
							e.preventDefault();
							input.onChange(val.value);
						}}>
						{val.title}
					</button>
				</Col>
			);
		});
	};

	return (
		<>
			<Row className={flexDirection}>{renderOptions()}</Row>
			{meta.error && meta.touched ? <span className="text-danger">{meta.error}</span> : null}
		</>
	);
};
