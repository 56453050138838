import {
	CREATE_SALARY_INCREMENT,
	UPDATE_SALARY_INCREMENT,
	GET_SALARY_INCREMENTS,
	GET_SALARY_INCREMENT,
	DELETE_SALARY_INCREMENT,
} from './types';

import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';

export const createSalaryIncrement = (formValues) => async (dispatch, getState) => {
	axios
		.post('/salary-increment', formValues)
		.then((response) => {
			dispatch({ type: CREATE_SALARY_INCREMENT, payload: response.data });
			history.push(Routes.HumanResources.SalaryIncrement.Index.path);
		})
		.catch((err) => {});
};

export const getSalaryIncrements = () => async (dispatch) => {
	const response = await axios.get('/salary-increment');
	dispatch({ type: GET_SALARY_INCREMENTS, payload: response.data });
};

export const getSalaryIncrementsWithFilter = (offset, limit, query) => async (dispatch) => {
	axios
		.get(`/salary-increment/offset/${offset}/limit/${limit}?${query}`)
		.then((response) => {
			dispatch({ type: GET_SALARY_INCREMENTS, payload: response.data });
		})
		.catch((err) => {});
};

export const getSalaryIncrement = (id) => async (dispatch) => {
	const response = await axios.get(`/salary-increment/${id}`);
	dispatch({ type: GET_SALARY_INCREMENT, payload: response.data });
};

export const deleteSalaryIncrement = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`salary-increment/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_SALARY_INCREMENT, payload: response.data });
			setDeleted(!deleted);
		})
		.catch((err) => {});
};

export const updateSalaryIncrement = (formValues) => async (dispatch) => {
	axios
		.put(`salary-increment/${formValues.id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_SALARY_INCREMENT, payload: response.data });
			history.push(Routes.HumanResources.SalaryIncrement.Index.path);
		})
		.catch((err) => {});
};
