import { Card, Col, Form, Row, Table } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, initialize, reduxForm } from 'redux-form';

import renderDropdown from '../../../components/formComponents/renderDropdown';
import renderInput from '../../../components/formComponents/renderInput';
import renderMultiselect from '../../../components/formComponents/renderMultiselect';
import renderRadioButton_v2 from '../../../components/formComponents/renderRadioButton_v2';
import renderTextArea from '../../../components/formComponents/renderTextArea';
import SubmitButton from '../../../components/formComponents/SubmitButton';
import { t } from 'i18next';

import { notEmpty, required } from '../../../helpers/validator';
import { useTranslation } from 'react-i18next';

const selector = formValueSelector('cash-flow');

const CashFlowForm = (props) => {
	var { vatTotal, baseTotal, totalTotal, currencyTotal } = props;
	const { onSubmit, invalid, initials } = props;
	const [total, setTotal] = useState(0);
	const [base, setBase] = useState(0);
	const [initialField, setInitialField] = useState(0);
	const { t } = useTranslation();
	const [calculatedVat, setCalculatedVat] = useState(0);
	useEffect(() => {
		{
			// initials != null ? setInitialField('base') : null;

			initials != null
				? props.initialize({
						...initials,
						cash_flow_id: initials.cash_flow_id,
						title: initials.title,
						category: initials.category,
						date: initials.date?.split('T')[0] || '',
						base: initials.base,
						vat: initials.vat,
						amount: initials.amount,
						currency: initials.currency,
						total: initials.total,
						tags: initials.tags.split(','),
				  })
				: props.initialize({
						date: new Date().toISOString().split('T')[0],
				  });
		}
	}, []);

	useEffect(() => {
		props.change('isValid', !invalid);
	}, [invalid]);

	useEffect(() => {
		console.log(initialField, baseTotal, totalTotal, vatTotal);

		if (initialField == 0) {
			if (totalTotal) {
				setInitialField('total');
			} else if (baseTotal) {
				setInitialField('base');
			}
		}
		if (vatTotal == undefined) {
			vatTotal = 0;
		}

		if (initialField == 'base') {
			setTotal(parseInt(baseTotal) * (parseInt(vatTotal) / 100) + parseInt(baseTotal));
			props.change('total', total);
			setCalculatedVat(parseInt(baseTotal) * (parseInt(vatTotal) / 100));
		} else if (initialField == 'total') {
			setBase((parseInt(totalTotal) / (parseInt(vatTotal) + 100)) * 100);
			setCalculatedVat(parseInt(baseTotal) * (parseInt(vatTotal) / 100));
			props.change('base', base);
		}
	}, [vatTotal, baseTotal, totalTotal, base, total]);

	const renderOptions = (options) => {
		return options.map((option, index) => {
			return (
				<option key={index} value={option}>
					{option}
				</option>
			);
		});
	};

	return (
		<form onSubmit={props.handleSubmit(onSubmit)}>
			<Col xl={12} lg={12} md={12} sm={12} xs={12}>
				<Card border="light" className="bg-white shadow-sm mb-4  w-100">
					<Card.Body>
						<h5 className="mb-4">{t('cash-flow-form')}</h5>

						<Row className="mb-3">
							<Col md={6} sm={12}>
								<Field validate={required} name="title" label={t('title')} component={renderInput}></Field>
							</Col>
							<Col className="">
								<label className="form-label">{t('tags')}</label>
								<Field
									validate={[notEmpty, required]}
									name="tags"
									component={renderMultiselect}
									textField="tag_name"
									data={props.tags}
									dataKey="tag_id"
									allowCreate
								/>
							</Col>
						</Row>
						<Row className="mb-3">
							<Col md={6} sm={12}>
								<Field validate={required} name="category" label={t('category')} component={renderInput}></Field>
							</Col>
							<Col md={6} sm={12}>
								<Field
									validate={required}
									name="date"
									inputType={t('date')}
									label={t('date-0')}
									component={renderInput}
								/>
							</Col>
						</Row>
						<Row>
							<Col md={6} sm={12}>
								<Row className="mb-3">
									<Col md={8} sm={12}>
										<Field validate={required} name="base" label={t('base-price')} component={renderInput}></Field>
									</Col>
									<Col md={4} sm={6}>
										<Field
											validate={required}
											name="currency"
											label={t('currency')}
											component={renderDropdown}
											renderedOptions={renderOptions(['USD', 'TL', 'QAT'])}
										/>
									</Col>
								</Row>
								<Row className="mb-3">
									<Col md={8} sm={12}>
										<Table responsive className="table-centered table-nowrap rounded mb-0 ">
											<tbody>
												<tr>
													<td className="fw-bolder">VAT</td>
													<td>
														<Form.Group>
															<Field
																name="vat"
																options={[{ value: 0, title: '0%' }]}
																component={renderRadioButton_v2}
															/>
														</Form.Group>
													</td>
													<td>
														<Form.Group>
															<Field
																name="vat"
																options={[{ value: 1, title: '1%' }]}
																component={renderRadioButton_v2}
															/>
														</Form.Group>
													</td>
													<td>
														<Form.Group>
															<Field
																name="vat"
																options={[{ value: 8, title: '8%' }]}
																component={renderRadioButton_v2}
															/>
														</Form.Group>
													</td>
													<td>
														<Form.Group>
															<Field
																name="vat"
																options={[{ value: 18, title: '18%' }]}
																component={renderRadioButton_v2}
															/>
														</Form.Group>
													</td>
												</tr>
											</tbody>
										</Table>
									</Col>
									<Col className="pt-2 align-right" md={4} sm={12}>
										Tax: {calculatedVat} {currencyTotal}
									</Col>
								</Row>
								<Row className="mb-3">
									<Col md={12} sm={12}>
										<Field validate={required} name="total" label={t('total')} component={renderInput} />
									</Col>
								</Row>
							</Col>
							<Col md={6} sm={12}>
								<Field
									validate={required}
									name="description"
									label={t('description')}
									component={renderTextArea}></Field>
							</Col>
						</Row>

						<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
							<a onClick={() => window.history.back()} className="btn btn-secondary">
								{t('back')}{' '}
							</a>
							<SubmitButton text={t('submit')} onSubmit={props.onSubmit} invalid={invalid} />
						</div>
					</Card.Body>
				</Card>
			</Col>
		</form>
	);
};

const mapStateToProps = (state) => {
	const baseTotal = selector(state, 'base');
	const vatTotal = selector(state, 'vat');
	const totalTotal = selector(state, 'total');
	const currencyTotal = selector(state, 'currency');
	return {
		baseTotal,
		vatTotal,
		totalTotal,
		currencyTotal,
		hero: 'batman',
	};
};

export default compose(
	connect(mapStateToProps, { initialize }),
	reduxForm({
		form: 'cash-flow',
	})
)(CashFlowForm);
