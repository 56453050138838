// CHECK THE VIEW VALUE AND CHANGES THE VIEW CHECKBOX VALUE
export const changeViewCheck = (actions, setState) => {
	var viewAction = ['VIEW:COMPANY', 'VIEW:SELF', 'VIEW:ALL'];

	if (actions.length > 0) {
		actions.map((val, index) => {
			if (viewAction.includes(val)) {
				setState(true);
			}
		});
	}
};

export const checkViewCheckbox = (actions) => {
	var viewAction = ['VIEW:COMPANY', 'VIEW:SELF', 'VIEW:ALL'];

	var isIncluded = false;

	if (actions.length > 0) {
		actions.map((val, index) => {
			if (viewAction.includes(val)) {
				isIncluded = true;
			}
		});
	}
	return isIncluded;
};

// CHANGES THE ACTION STRUCTURE ACCORDING TO INPUT
export const changeActionStructure = (actions) => {
	var viewAction = ['VIEW:COMPANY', 'VIEW:SELF', 'VIEW:ALL'];
	if (actions.length > 0) {
		return actions.reduce((a, v) => ({ ...a, [viewAction.includes(v) ? 'VIEW' : v]: v }), {});
	}
};

// CHANGES THE COMPANY STRUCTURE ACCORDING TO DROPDWON
export const changeCompanyStructure = (companies, entities) => {
	return entities.filter((val, index) => {
		if (companies.includes(val.company_name)) {
			return { val };
		}
	});
};

export const indexTableFilterQueryGenerator = (filters, setQuery) => {
	var tempQuery = '';
	var tempQuery = '';
	filters.map((filter) => {
		const queryObj = _.pickBy(filter, (val, key) => _.includes(key, 'query_'));
		tempQuery += _.keys(queryObj)[0].split('_')[1] + '=' + _.toString(_.values(queryObj)) + '&';
	});
	setQuery(tempQuery);
};
