import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './en/translation.json';
import translationTR from './tr/translation.json';
import translationAR from './ar/translation.json';

const resources = {
	en: {
		translation: translationEN,
	},
	tr: {
		translation: translationTR,
	},
	ar: {
		translation: translationAR,
	},
};

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.use(LanguageDetector)
	.init({
		resources,
		fallbackLng: 'en', // default language
		keySeparator: false, // we do not use keys in form messages.welcome
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

i18n.languages = ['en', 'tr', 'ar'];
export default i18n;
