import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Report from '../Reports/Report';
import { getHumanResourcesOverview } from '../../../actions/Others/powerbiActions';

const HumanResourcesOverview = (props) => {
	return (
		<>
			<Report getEmbedInfo={props.getHumanResourcesOverview} />
		</>
	);
};

export default connect(null, { getHumanResourcesOverview })(HumanResourcesOverview);
