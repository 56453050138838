import { Card, Col, Row } from '@themesberg/react-bootstrap';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { Field, reduxForm, change, initialize, FieldArray, formValueSelector } from 'redux-form';
import renderDropdown from '../../../../components/formComponents/renderDropdown';
import renderInput from '../../../../components/formComponents/renderInput';
import SubmitButton from '../../../../components/formComponents/SubmitButton';
import renderSearchableDropdown from '../../../../components/formComponents/renderSearchableDropdown';
import { Routes } from '../../../../routes';
import validate from '../../../../validate';
import quotationContentTable from './formComponents/quotationContentTable';
import { date, nonZero, required, phoneNumber, minLength, notEmpty } from '../../../../helpers/validator';
import { useTranslation } from 'react-i18next';

const selector = formValueSelector('quotation');

const QuotationForm = (props) => {
	const { datas, initials, onSubmit, contentValues } = props;
	const { t } = useTranslation();

	var total = 0;
	var unitPriceTotal = 0;

	const calculateTableSum = () => {
		contentValues.map((content, index) => {
			content = _.omit(content, 'description', 'unit');

			Object.keys(content).map((value, index) => {
				switch (value) {
					case 'unit_price':
						unitPriceTotal += parseInt(content[value] || 0);
						break;
					case 'amount':
						total += parseInt(content[value] || 0);
						break;
				}
			});
		});
	};

	if (contentValues) {
		calculateTableSum();
	}

	useEffect(() => {
		props.change('total_amount', total);
	}, [total]);

	// LPO CONTENT VALUES CHANGES
	useEffect(() => {
		if (contentValues) {
			contentValues.map((content, index) => {
				props.change(`quotation_contents[${index}].amount`, content.quantity_required * content.unit_price || 0);
			});
		}
	}, [unitPriceTotal]);

	useEffect(() => {
		if (initials) {
			props.initialize({
				...initials,
				quotation_contents: initials.isr.isr_contents,
				user: initials.user.full_name,
				company: initials.company.company_name,
			});
		} else {
			props.initialize({
				user: datas.user.full_name,
				company: datas.user.companies[0].company_name,
				quotation_contents: datas.isr.isr_contents,
				reference: datas.isr.reference,
			});
			props.change('user_id', datas.user.user_id);
			props.change('company_id', datas.user.companies[0].company_id);
			props.change('isr_id', datas.isr.id);
		}
	}, []);

	return (
		<form onSubmit={props.handleSubmit(onSubmit)}>
			<Card border="light" className="bg-white shadow-sm   w-100">
				<Card.Body>
					<h5 className="mb-4">{t('quotations')}</h5>
					<Row className="mb-3 ">
						<Col md={6} sm={12}>
							<Field name="user" label={t('by')} component={renderInput} disabled="disabled" />
						</Col>
						<Col md={6} sm={12}>
							<Field disabled="disabled" name="company" label={t('company')} component={renderInput} />
						</Col>
					</Row>
					<Row className="mb-3 ">
						<Col md={6} sm={12}>
							<Field disabled="disabled" name="reference" label={t('reference-no')} component={renderInput} />
						</Col>
						<Col md={6} sm={12}>
							<Field
								validate={notEmpty}
								dataKey="supplier_id"
								textField="supplier_name"
								data={datas.suppliers}
								name="supplier_id"
								label={t('supplier')}
								component={renderSearchableDropdown}
							/>
						</Col>
					</Row>
					<Row className="mb-3">
						<Col>
							<table className="table overflow-auto form-control formTable ">
								<thead>
									<tr>
										<th colSpan={3}>
											{t('description')} <br /> {t('of-works')}
										</th>

										<th>{t('unit')}</th>
										<th>QTY</th>
										<th>{t('unit-price')}</th>
										<th>{t('amount-qar')}</th>
									</tr>
								</thead>
								<tbody>
									<FieldArray
										name="quotation_contents"
										totals={[null, null, null, null, null, total]}
										component={quotationContentTable}
										inputNames={[
											{ name: 'description', col: 3, disabled: true },
											{ name: 'unit', col: 1, disabled: true },
											{ name: 'quantity_required', col: 1, disabled: true },
											{ name: 'unit_price', col: 1 },
											{ name: 'amount', col: 1 },
										]}
										types={['text', 'text', 'number', 'number', 'number']}
									/>
								</tbody>
							</table>
						</Col>
					</Row>

					<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
						<Link to={Routes.Procurement.ISR.Checklist.path.replace(':id', props.isrId)} className="btn btn-secondary">
							{t('back')}{' '}
						</Link>
						<SubmitButton text={t('submit')} onSubmit={props.onSubmit} invalid={false} />
					</div>
				</Card.Body>
			</Card>
		</form>
	);
};

const mapStateToProps = (state) => {
	const contentValues = selector(state, 'quotation_contents');

	return {
		contentValues,
	};
};

export default compose(
	connect(mapStateToProps, { change, initialize }),
	reduxForm({ form: 'quotation' })
)(QuotationForm);
