import {
	CREATE_WORKFLOW,
	GET_WORKFLOW,
	GET_WORKFLOWS,
	UPDATE_WORKFLOW,
	DELETE_WORKFLOW,
	GET_FORMS,
	GET_MODULES,
} from '../../actions/Workflow/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	workflow: {},
	workflows: [],
	forms: [],
	getFormsLoading: true,
	modules: [],
	getModulesLoading: true,
	getWorkflowLoading: true,
	getWorkflowsLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_FORMS:
			return { ...state, forms: action.payload, getFormsLoading: false };
		case GET_MODULES:
			return { ...state, modules: action.payload, getModulesLoading: false };
		case CREATE_WORKFLOW:
			return { ...state, workflow: action.payload };
		case GET_WORKFLOW:
			return { ...state, workflow: action.payload, getWorkflowLoading: false };
		case GET_WORKFLOWS:
			return { ...state, workflows: action.payload, getWorkflowsLoading: false };
		case UPDATE_WORKFLOW:
			return { ...state };
		case CLEAR_DATA:
			return {
				...state,
				workflow: action.payload,
				getWorkflowLoading: true,
				getWorkflowsLoading: true,
			};

		case DELETE_WORKFLOW:
			return { ...state };
		default:
			return state;
	}
};
