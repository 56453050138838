import {
	CREATE_WARNING_LETTER,
	UPDATE_WARNING_LETTER,
	GET_WARNING_LETTERS,
	GET_WARNING_LETTER,
	DELETE_WARNING_LETTER,
} from './types';

import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';

export const createWarningLetter = (formValues) => async (dispatch, getState) => {
	axios
		.post('/warning-letter', formValues)
		.then((response) => {
			dispatch({ type: CREATE_WARNING_LETTER, payload: response.data });
			history.push(Routes.HumanResources.WarningLetter.Index.path);
		})
		.catch((err) => {});
};

export const getWarningLetters = () => async (dispatch) => {
	axios
		.get('/warning-letter')
		.then((response) => {
			dispatch({ type: GET_WARNING_LETTERS, payload: response.data });
		})
		.catch((err) => {});
};

export const getWarningLettersWithFilter = (offset, limit, query) => async (dispatch) => {
	axios
		.get(`/warning-letter/offset/${offset}/limit/${limit}?${query}`)
		.then((response) => {
			dispatch({ type: GET_WARNING_LETTERS, payload: response.data });
		})
		.catch((err) => {});
};

export const getWarningLetter = (id) => async (dispatch) => {
	const response = await axios.get(`/warning-letter/${id}`);
	dispatch({ type: GET_WARNING_LETTER, payload: response.data });
};

export const deleteWarningLetter = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`warning-letter/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_WARNING_LETTER, payload: response.data });
			setDeleted(!deleted);
		})
		.catch((err) => {});
};

export const updateWarningLetter = (formValues) => async (dispatch) => {
	axios
		.put(`warning-letter/${formValues.id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_WARNING_LETTER, payload: response.data });
			history.push(Routes.HumanResources.WarningLetter.Index.path);
		})
		.catch((err) => {});
};
