import {
	CREATE_RESIGNATION,
	GET_RESIGNATIONS,
	GET_RESIGNATION,
	DELETE_RESIGNATION,
} from '../../actions/HumanResources/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	resignations: [],
	resignation: {},
	loading: true,
	getResignationLoading: true,
	getResignationsLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_RESIGNATIONS:
			return { ...state, resignations: action.payload, getResignationsLoading: false };

		case GET_RESIGNATION:
			return { ...state, resignation: action.payload, getResignationLoading: false };

		case CREATE_RESIGNATION:
			return { ...state };

		case CLEAR_DATA:
			return {
				...state,
				resignation: action.payload,
				getResignationLoading: true,
				getResignationsLoading: true,
			};

		case DELETE_RESIGNATION:
			return { ...state };

		default:
			return state;
	}
};
