import { faEdit, faHome, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import IndexTable from '../../../../components/IndexTable';
import { Routes } from '../../../../routes';
import { getSalaryIncrements, deleteSalaryIncrement } from '../../../../actions/HumanResources/salaryIncrementAction';
import onDelete from '../../../../helpers/deleteFunction';
import { useTranslation } from 'react-i18next';

const SalaryIncrementIndex = (props) => {
	const { datas, loading } = props;

	const [deleted, setDeleted] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		props.getSalaryIncrements();
	}, [deleted]);

	const renderRows = () => {
		return datas.salaryIncrements.map((salaryIncrement, index) => {
			return (
				<tr key={index}>
					<td className="fw-bold text-center">{salaryIncrement.reference}</td>
					<td className="fw-bold text-center">{salaryIncrement.employee.employee_number}</td>
					<td className="fw-bold text-center">{salaryIncrement.employee.full_name}</td>
					<td className="fw-bold text-center">{salaryIncrement.current_total}</td>
					<td className="fw-bold text-center">{salaryIncrement.recommended_total}</td>
					<td className="fw-bold text-center">{salaryIncrement.status}</td>
					<td className="fw-bold text-center">
						{salaryIncrement.status == t('active-0') ? (
							<>
								{' '}
								<button
									onClick={() => {
										onDelete(deleted, setDeleted, props.deleteSalaryIncrement, salaryIncrement.id);
									}}
									className="btn btn-danger mx-2">
									<FontAwesomeIcon icon={faTrash} />
								</button>
								<Link
									to={Routes.HumanResources.SalaryIncrement.Edit.path.replace(':id', salaryIncrement.id)}
									className="btn btn-primary mx-2">
									<FontAwesomeIcon icon={faEdit} />
								</Link>
							</>
						) : null}
					</td>
				</tr>
			);
		});
	};

	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0 ">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.HumanResources.Overview.path }}>
							{t('human-resources')}{' '}
						</Breadcrumb.Item>

						<Breadcrumb.Item active>{t('salary-increments')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>

				<div
					className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-5
  ">
					<h4 style={{ display: 'inline' }}>{t('salary-increments-0')}</h4>
					<Link className="btn btn-primary py-2 px-3  " to={Routes.HumanResources.SalaryIncrement.Create.path}>
						{t('add-new')}{' '}
					</Link>
				</div>
				<p className="mb-0">{t('your-most-recent-salary-increments-can-be-seen-here')}</p>
			</div>

			<IndexTable
				loading={loading}
				headerProps="text-center"
				headers={[
					t('reference'),
					t('employee-number'),
					t('name'),
					t('current-total'),
					t('recommended-total'),
					t('status'),
					'',
				]}
				renderRows={datas.salaryIncrements.length != 0 ? renderRows() : null}
				// filter={
				//     <div className="mb-3">
				//         <TableFilter
				//             initialFilterCategories={initialFilterCategories}
				//             filters={filters}
				//             setFilters={setFilters}
				//             datas={projects}
				//             setQuery={setQuery}
				//             filterCategories={filterCategories}
				//             setFilterCategories={setFilterCategories}
				//         />
				//     </div>
				// }
				// pagination={
				//     projects.length > 1 ? (
				//         <ReactPaginate
				//             nextLabel=" >"
				//             onPageChange={handlePageClick}
				//             pageCount={Math.ceil(_.last(projects).page_count)}
				//             pageRangeDisplayed={3}
				//             previousLabel="< "
				//             pageClassName="page-item"
				//             pageLinkClassName="page-link"
				//             previousClassName="page-item"
				//             forcePage={offset}
				//             previousLinkClassName="page-link"
				//             nextClassName="page-item"
				//             nextLinkClassName="page-link"
				//             breakLabel="..."
				//             breakClassName="page-item"
				//             breakLinkClassName="page-link"
				//             containerClassName="pagination"
				//             activeClassName="active"
				//             renderOnZeroPageCount={null}
				//         />
				//     ) : null
				// }
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		humanResources: {
			salaryIncrement: { salaryIncrements, getSalaryIncrementsLoading },
		},
	} = state;

	return {
		datas: { salaryIncrements },
		loading: getSalaryIncrementsLoading,
	};
};

export default connect(mapStateToProps, { getSalaryIncrements, deleteSalaryIncrement })(SalaryIncrementIndex);
