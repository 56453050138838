//LPO

export const CREATE_LPO = 'CREATE_LPO';
export const GET_LPOS = 'GET_LPOS';
export const GET_LPO = 'GET_LPO';
export const DELETE_LPO = 'DELETE_LPO';
export const UPDATE_LPO = 'UPDATE_LPO';

//QUOTATION

export const CREATE_QUOTATION = 'CREATE_QUOTATION';
export const GET_QUOTATIONS = 'GET_QUOTATIONS';
export const GET_QUOTATION = 'GET_QUOTATION';
export const DELETE_QUOTATION = 'DELETE_QUOTATION';
export const UPDATE_QUOTATION = 'UPDATE_QUOTATION';

// ISR
export const CREATE_ISR = 'CREATE_ISR';
export const GET_ISRS = 'GET_ISRS';
export const GET_ISRS_PAGINATED = 'GET_ISRS_PAGINATED';
export const GET_ISR = 'GET_ISR';
export const DELETE_ISR = 'DELETE_ISR';
export const UPDATE_ISR = 'UPDATE_ISR';
export const GET_ISR_CHECKLISTS = 'GET_ISR_CHECKLISTS';

//SUPPLIER
export const CREATE_SUPPLIER = 'CREATE_SUPPLIER';
export const GET_SUPPLIERS = 'GET_SUPPLIERS';
export const GET_SUPPLIER = 'GET_SUPPLIER';
export const DELETE_SUPPLIER = 'DELETE_SUPPLIER';
export const UPDATE_SUPPLIER = 'UPDATE_SUPPLIER';
//SUPPLIER EVALUATION
export const CREATE_SUPPLIER_EVALUATION = 'CREATE_SUPPLIER_EVALUATION';
export const GET_SUPPLIER_EVALUATIONS = 'GET_SUPPLIER_EVALUATIONS';
export const DELETE_SUPPLIER_EVALUATION = 'DELETE_SUPPLIER_EVALUATION';
export const GET_SUPPLIER_EVALUATION = 'GET_SUPPLIER_EVALUATION';
export const UPDATE_SUPPLIER_EVALUATION = 'UPDATE_SUPPLIER_EVALUATION';
//PRODUCTS
export const CREATE_ITEM = 'CREATE_ITEM';
export const GET_ITEM = 'GET_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const GET_ITEMS = 'GET_ITEMS';
export const UPDATE_ITEM = 'UPDATE_ITEM';

export const CREATE_ITEM_CATEGORY = 'CREATE_ITEM_CATEGORY';
export const GET_ITEM_CATEGORY = 'GET_ITEM_CATEGORY';
export const DELETE_ITEM_CATEGORY = 'DELETE_ITEM_CATEGORY';
export const GET_ITEM_CATEGORIES = 'GET_ITEMS_CATEGORIES';
export const UPDATE_ITEM_CATEGORY = 'UPDATE_ITEM_CATEGORY';
