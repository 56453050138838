import { CLEAR_DATA, GET_OVERVIEW_COUNTERS } from "../actions/types";

const initialState = {
	overviewCounters: {},
	getOverviewCountersLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_OVERVIEW_COUNTERS:
			return {
				...state,
				overviewCounters: action.payload,
				getOverviewCountersLoading: false,
			};

		case CLEAR_DATA:
			return {
				...state,
				getOverviewCountersLoading: true,
				overviewCounters: action.payload,
			};

		default:
			return state;
	}
};
