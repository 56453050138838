import { CREATE_ITEM, GET_ITEM, GET_ITEMS, DELETE_ITEM, UPDATE_ITEM } from '../../actions/Procurement/types';
import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	item: {},
	items: [],
	loading: true,
	getItemLoading: true,
	getItemsLoading: true,
};
export default (state = initialState, action) => {
	switch (action.type) {
		case GET_ITEMS:
			return { ...state, items: action.payload, getItemsLoading: false };
		case GET_ITEM:
			return { ...state, item: action.payload, getItemLoading: false };
		case CREATE_ITEM:
			return { ...state, item: action.payload };
		case CLEAR_DATA:
			return {
				...state,
				item: action.payload,
				getItemLoading: true,
				getItemsLoading: true,
			};
		case DELETE_ITEM:
			return { ...state };
		default:
			return state;
	}
};
