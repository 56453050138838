import React, { useEffect } from 'react';
import { Field } from 'redux-form';

import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import renderInput from '../../../../../components/formComponents/renderInput';
import renderSearchableDropdown from '../../../../../components/formComponents/renderSearchableDropdown';
import { renderCategoryDropdown, renderItemDropdown } from './renderDropdowns';

export default ({ fields, meta, inputNames, types, totals, items, categories, contentValues, change }) => {
	if (fields.length < 1) {
		useEffect(() => {
			fields.push({ category: '', item: '' });
		}, []);
	}

	const renderFields = (inputNames, parentObjName, isrContentIndex) => {
		return inputNames.map((input, index) => {
			if (input.name == 'category') {
				return (
					<td colSpan={input.col} key={index}>
						<Field
							data={categories}
							textField="item_category_name"
							dataKey="item_category_code"
							name={`${parentObjName}.${input.name}`}
							component={renderCategoryDropdown}
						/>
					</td>
				);
			} else if (input.name == 'item') {
				return (
					<td colSpan={input.col} key={index}>
						<Field
							data={
								contentValues.length != 0
									? contentValues[isrContentIndex].category != null
										? items.filter(
												(item) => item.category_code == contentValues[isrContentIndex].category.item_category_code
										  )
										: null
									: null
							}
							textField="item_name"
							dataKey="item_code"
							change={change}
							name={`${parentObjName}.${input.name}`}
							component={renderItemDropdown}
						/>
					</td>
				);
			} else if (input.name == 'discipline') {
				return (
					<td colSpan={input.col} key={index}>
						<Field
							data={['Facade', 'Landscape', 'Elevator', 'Escalator', 'Steel', 'Rough work']}
							name={`${parentObjName}.${input.name}`}
							component={renderSearchableDropdown}
						/>
					</td>
				);
			} else {
				return (
					<td colSpan={input.col} key={index}>
						<Field inputType={types[index]} name={`${parentObjName}.${input.name}`} component={renderInput} />
					</td>
				);
			}
		});
	};

	return (
		<>
			{fields.map((parentObjName, index) => {
				return (
					<tr key={index}>
						{renderFields(inputNames, parentObjName, index)}

						<td style={{ borderStyle: 'none' }}>
							<div className="mt-2 ms-2">
								<FontAwesomeIcon
									className="text-danger"
									icon={faMinusCircle}
									size="2x"
									onClick={(e) => {
										e.preventDefault();

										fields.remove(index);
									}}
								/>
							</div>
						</td>
					</tr>
				);
			})}
			{!totals ? null : (
				<tr>
					<td className="p-3" style={{ borderBottom: 0 }}>
						<h5 className="m-0">Total: </h5>
					</td>
					{totals?.map((total, index) => {
						return (
							<td key={index} className="p-3 text-center" style={{ borderBottom: 0 }}>
								<h5 className="m-0">{total}</h5>
							</td>
						);
					})}
				</tr>
			)}

			<tr>
				<td colSpan="100%" className="text-center border-0">
					<FontAwesomeIcon
						style={{ alignItems: 'center', justifyContent: 'center' }}
						size="2x"
						icon={faPlusCircle}
						onClick={(e) => {
							e.preventDefault();
							fields.push({ category: '', item: '' });
						}}
					/>
				</td>
			</tr>
		</>
	);
};
