import { Card, Col, Row, Table } from '@themesberg/react-bootstrap';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, Field, formValueSelector, initialize, reduxForm } from 'redux-form';
import renderDropdownWithAutoComplete from '../../../../components/formComponents/renderDropdownWithAutoComplete';
import renderInput from '../../../../components/formComponents/renderInput';
import renderSearchableDropdown from '../../../../components/formComponents/renderSearchableDropdown';
import renderTextArea from '../../../../components/formComponents/renderTextArea';
import SubmitButton from '../../../../components/formComponents/SubmitButton';

import _ from 'lodash';
import validate from '../../../../validate';
import { useTranslation } from 'react-i18next';

const selector = formValueSelector('salaryIncrement');

const SalaryIncrementForm = (props) => {
	const { t } = useTranslation();

	const {
		initials,
		onSubmit,
		user_id,
		full_name,
		companies,
		datas,
		current_basic_salary,
		current_food,
		current_transportation,
		current_accomodation,
		current_telephone,
		current_petrol,
		current_other,

		recommended_basic_salary,
		recommended_food,
		recommended_transportation,
		recommended_accomodation,
		recommended_telephone,
		recommended_petrol,
		recommended_other,
	} = props;

	useEffect(() => {
		props.change(
			'current_total',
			Number(current_basic_salary) +
				Number(current_food) +
				Number(current_transportation) +
				Number(current_accomodation) +
				Number(current_telephone) +
				Number(current_petrol) +
				Number(current_other)
		);
	}, [
		current_basic_salary,
		current_food,
		current_transportation,
		current_accomodation,
		current_telephone,
		current_petrol,
		current_other,
	]);

	useEffect(() => {
		props.change(
			'recommended_total',
			Number(recommended_basic_salary) +
				Number(recommended_food) +
				Number(recommended_transportation) +
				Number(recommended_accomodation) +
				Number(recommended_telephone) +
				Number(recommended_petrol) +
				Number(recommended_other)
		);
	}, [
		recommended_basic_salary,
		recommended_food,
		recommended_transportation,
		recommended_accomodation,
		recommended_telephone,
		recommended_petrol,
		recommended_other,
	]);

	useEffect(() => {
		if (initials) {
			props.initialize({
				...initials,
				full_name: full_name,
				employee_id: initials.employee.employee_id,
				employee_number: initials.employee.employee_number,
				job_title: initials.employee.job_title,
				department: _.find(datas.employees, { employee_id: initials.employee.employee_id }).department.department_name,
				date_of_request: new Date(initials.date_of_request).toISOString().split('T')[0],
				residency_id: initials.employee.residency_id,
			});
		} else {
			props.initialize({
				full_name: full_name,
				company_id: companies[0].company_id,
				date_of_request: new Date().toISOString().split('T')[0],
			});
			props.change('user_id', user_id);
		}
	}, []);

	return (
		<form onSubmit={props.handleSubmit(onSubmit)}>
			<Row>
				<Col md={12}>
					<Card border="light" className="bg-white shadow-sm mb-4  w-100">
						<Card.Body>
							<h5 className="mb-4">{t('salary-increment-form')}</h5>

							<Row className="mb-3 ">
								<Col md={6} sm={12}>
									<Field name="full_name" label={t('by')} component={renderInput} disabled="disabled" />
								</Col>
								<Col md={6} sm={12}>
									<Field
										data={companies}
										dataKey="company_id"
										textField="company_name"
										name="company_id"
										label={t('company')}
										component={renderSearchableDropdown}
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col md={3}>
									<Field
										name="employee_id"
										label={t('employee')}
										dataKey="employee_id"
										textField="full_name"
										data={datas.employees}
										component={renderDropdownWithAutoComplete}
										onChange={(val) => {
											const selectedEmployee = _.find(datas.employees, { employee_id: val });

											props.change(
												'reference',
												`HR/009/${String(selectedEmployee.employee_number).padStart(3, '0')}/${
													new Date().getMonth() + 1
												}${new Date().getDate()}${new Date().getMilliseconds()}`
											);
											props.change('employee_number', selectedEmployee.employee_number);
											props.change('job_title', selectedEmployee.job_title);
											props.change('department', selectedEmployee.department.department_name);
											props.change('residency_id', selectedEmployee.residency_id);
										}}
									/>
								</Col>
								<Col md={3}>
									<Field
										name="employee_number"
										label={t('employee-number')}
										component={renderInput}
										disabled="disabled"
									/>
								</Col>
								<Col md={3}>
									<Field name="job_title" label={t('job-title')} component={renderInput} disabled="disabled" />
								</Col>
								<Col md={3}>
									<Field
										disabled="disabled"
										name="department"
										rows={4}
										label={t('department-0')}
										component={renderInput}
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col>
									<Field disabled="disabled" name="residency_id" label={t('residency-id')} component={renderInput} />
								</Col>
								<Col>
									<Field disabled="disabled" name="reference" label={t('reference')} component={renderInput} />
								</Col>
								<Col>
									<Field name="date_of_request" label={t('date-0')} component={renderInput} inputType="date" />
								</Col>
							</Row>
							<Row className="mb-3">
								<Table responsive className="table-centered table-nowrap rounded mb-0 ">
									<thead>
										<tr>
											<th className="fw-bolder text-center">{t('subject')}</th>
											<th className="fw-bolder text-center">{t('current-salary')}</th>
											<th className="fw-bolder text-center">{t('reccommended-salary')}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className="fw-bold ">{t('basic-salary')}</td>
											<td>
												<Field name="current_basic_salary" component={renderInput} inputType="number" />
											</td>
											<td>
												<Field name="recommended_basic_salary" component={renderInput} inputType="number" />
											</td>
										</tr>
										<tr>
											<td className="fw-bold ">{t('food')}</td>
											<td>
												<Field name="current_food" component={renderInput} inputType="number" />
											</td>
											<td>
												<Field name="recommended_food" component={renderInput} inputType="number" />
											</td>
										</tr>
										<tr>
											<td className="fw-bold ">{t('transportation')}</td>
											<td>
												<Field name="current_transportation" component={renderInput} inputType="number" />
											</td>
											<td>
												<Field name="recommended_transportation" component={renderInput} inputType="number" />
											</td>
										</tr>
										<tr>
											<td className="fw-bold ">{t('accommodation')}</td>
											<td>
												<Field name="current_accomodation" component={renderInput} inputType="number" />
											</td>
											<td>
												<Field name="recommended_accomodation" component={renderInput} inputType="number" />
											</td>
										</tr>
										<tr>
											<td className="fw-bold ">{t('telephone')}</td>
											<td>
												<Field name="current_telephone" component={renderInput} inputType="number" />
											</td>
											<td>
												<Field name="recommended_telephone" component={renderInput} inputType="number" />
											</td>
										</tr>
										<tr>
											<td className="fw-bold ">{t('petrol')}</td>
											<td>
												<Field name="current_petrol" component={renderInput} inputType="number" />
											</td>
											<td>
												<Field name="recommended_petrol" component={renderInput} inputType="number" />
											</td>
										</tr>
										<tr>
											<td className="fw-bold ">{t('other-0')}</td>
											<td>
												<Field name="current_other" component={renderInput} inputType="number" />
											</td>
											<td>
												<Field name="recommended_other" component={renderInput} inputType="number" />
											</td>
										</tr>
										<tr>
											<td className="fw-bold ">{t('total')}</td>
											<td>
												<Field name="current_total" component={renderInput} inputType="number" disabled="disabled" />
											</td>
											<td>
												<Field
													name="recommended_total"
													component={renderInput}
													inputType="number"
													disabled="disabled"
												/>
											</td>
										</tr>
									</tbody>
								</Table>
							</Row>
							<Row>
								<Col>
									<Field name="remarks" rows={4} label={t('remarks')} component={renderTextArea} />
								</Col>
							</Row>
							<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
								<a onClick={() => window.history.back()} className="btn btn-secondary">
									{t('back')}{' '}
								</a>
								<SubmitButton text={t('submit')} onSubmit={props.onSubmit} invalid={false} />
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</form>
	);
};

const mapStateToProps = (state) => {
	const current_basic_salary = selector(state, 'current_basic_salary');
	const current_food = selector(state, 'current_food');
	const current_transportation = selector(state, 'current_transportation');
	const current_accomodation = selector(state, 'current_accomodation');
	const current_telephone = selector(state, 'current_telephone');
	const current_petrol = selector(state, 'current_petrol');
	const current_other = selector(state, 'current_other');

	const recommended_basic_salary = selector(state, 'recommended_basic_salary');
	const recommended_food = selector(state, 'recommended_food');
	const recommended_transportation = selector(state, 'recommended_transportation');
	const recommended_accomodation = selector(state, 'recommended_accomodation');
	const recommended_telephone = selector(state, 'recommended_telephone');
	const recommended_petrol = selector(state, 'recommended_petrol');
	const recommended_other = selector(state, 'recommended_other');
	const {
		auth: { user_id, full_name, companies },
	} = state;

	return {
		user_id,
		full_name,
		companies,

		current_basic_salary,
		current_food,
		current_transportation,
		current_accomodation,
		current_telephone,
		current_petrol,
		current_other,

		recommended_basic_salary,
		recommended_food,
		recommended_transportation,
		recommended_accomodation,
		recommended_telephone,
		recommended_petrol,
		recommended_other,
	};
};

export default compose(
	connect(mapStateToProps, { initialize, change }),
	reduxForm({ form: 'salaryIncrement', validate })
)(SalaryIncrementForm);
