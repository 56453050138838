import { Form, Row, Table } from '@themesberg/react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import renderRadioButton_v2 from '../../../../components/formComponents/renderRadioButton_v2';

const EmployeeEvaluationRankinTable = () => {
	const { t } = useTranslation();

	const categories = [
		{ label: t('job-knowledge'), name: 'job_knowledge' },
		{ label: t('quantity-of-work'), name: 'quantity_of_work' },
		{ label: t('quality-of-work'), name: 'quality_of_work' },
		{ label: t('initiative'), name: 'initiative' },
		{ label: t('improvement'), name: 'improvement' },
		{ label: t('overall-assesment'), name: 'overall_assesment' },
	];

	const renderRows = () => {
		return categories.map((category, index) => {
			return (
				<tr key={index}>
					<td className="text-center fw-bolder" colSpan={2}>
						{category.label}
					</td>
					<td className="">
						<Form.Group>
							<Field
								name={category.name}
								options={[{ value: 'excellent' }]}
								radioButtonProps="d-flex justify-content-center"
								component={renderRadioButton_v2}
							/>
						</Form.Group>
					</td>
					<td>
						<Form.Group>
							<Field
								radioButtonProps="d-flex justify-content-center"
								name={category.name}
								options={[{ value: 'good' }]}
								component={renderRadioButton_v2}
							/>
						</Form.Group>
					</td>
					<td>
						<Form.Group>
							<Field
								radioButtonProps="d-flex justify-content-center"
								name={category.name}
								options={[{ value: 'fair' }]}
								component={renderRadioButton_v2}
							/>
						</Form.Group>
					</td>
					<td>
						<Form.Group>
							<Field
								radioButtonProps="d-flex justify-content-center"
								name={category.name}
								options={[{ value: 'poor' }]}
								component={renderRadioButton_v2}
							/>
						</Form.Group>
					</td>
				</tr>
			);
		});
	};

	return (
		<Row className="mb-3">
			<Table responsive className="table-centered table-nowrap rounded mb-0">
				<thead className="thead-light text-left">
					<tr>
						<th colSpan={2} className="text-center">
							{t('performance-categories')}{' '}
						</th>
						<th className="text-center">{t('excellent')}</th>
						<th className="text-center">{t('good')}</th>
						<th className="text-center">{t('fair')}</th>
						<th className="text-center">{t('poor')}</th>
					</tr>
				</thead>

				<tbody>{renderRows()}</tbody>
			</Table>
		</Row>
	);
};

export default EmployeeEvaluationRankinTable;
