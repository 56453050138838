import { GET_HR_EMPLOYEE_EVALUATION, GET_HR_EMPLOYEE_EVALUATIONS } from '../../actions/HumanResources/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	employeeEvaluations: [],
	employeeEvaluation: {},
	getEmployeeEvaluationsLoading: true,
	getEmployeeEvaluationLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_HR_EMPLOYEE_EVALUATIONS:
			return {
				...state,
				employeeEvaluations: action.payload,
				getEmployeeEvaluationsLoading: false,
			};
		case GET_HR_EMPLOYEE_EVALUATION:
			return { ...state, employeeEvaluation: action.payload, getEmployeeEvaluationLoading: false };
		case CLEAR_DATA:
			return {
				...state,
				employeeEvaluation: action.payload,
				getEmployeeEvaluationLoading: true,
				getEmployeeEvaluationsLoading: true,
			};

		default:
			return { ...state };
	}
};
