import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm, initialize, formValueSelector, change } from 'redux-form';
import { Card, Col, Form, FormGroup, FormLabel, Row } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import renderInput from '../../../../../components/formComponents/renderInput';
import renderDropdown from '../../../../../components/formComponents/renderDropdown';
import { Routes } from '../../../../../routes';
import SubmitButton from '../../../../../components/formComponents/SubmitButton';
import renderRadioButton_v2 from '../../../../../components/formComponents/renderRadioButton_v2';

import { getSuppliers } from '../../../../../actions//Procurement/suppliersActions';
import { clearData } from '../../../../../actions/globalActions';
import SupplierEvaluationRankingTable from './SupplierEvaluationRankingTable';
import renderSearchableDropdown from '../../../../../components/formComponents/renderSearchableDropdown';
import renderDropdownWithAutoComplete from '../../../../../components/formComponents/renderDropdownWithAutoComplete';
import { date, nonZero, required, phoneNumber, minLength, notEmpty } from '../../../../../helpers/validator';
import { useTranslation } from 'react-i18next';

const selector = formValueSelector('supplierEvaluation');

const SupplierFeedbackForm = (props) => {
	const {
		suppliers,
		loading,
		name,
		role,
		deliveryStartValue,
		deliveryCompleteValue,
		materialDeliveryValue,
		materialQuantityValue,
		invalid,
		initials,
		datas,
	} = props;

	const { t } = useTranslation();

	useEffect(() => {
		props.getSuppliers();

		{
			_.isEmpty(initials)
				? props.initialize({
						from: name,
						role: role || '-',
						date_created: new Date().toISOString().split('T')[0],
						delivery_start_delay: '',
						delivery_complete_delay: '',
						material_delivery_alternative: '',
						material_quantity_partial: '',
				  })
				: props.initialize({
						...initials,
						date_created: new Date(initials.date_created).toISOString().split('T')[0],
				  });
		}

		return () => {
			props.clearData();
		};
	}, []);

	useEffect(() => {
		props.change('isValid', !invalid);
	}, [invalid]);

	return (
		<>
			{loading ? (
				<div className="d-flex justify-content-center  ">
					<div className="spinner-border " role="status">
						<span className="visually-hidden">Loading...</span>
					</div>
				</div>
			) : (
				<form onSubmit={props.handleSubmit(props.onSubmit)}>
					<Row>
						<Col md={12}>
							<Card border="light" className="bg-white shadow-sm mb-4  w-100">
								<Card.Body>
									<h5 className="mb-4">{t('supplier-evaluation-form')}</h5>
									<Row className="mb-3">
										<Col md={8}>
											<Field
												validate={required}
												name="site_location_name"
												label={t('site-location-name')}
												component={renderInput}
											/>
										</Col>
										<Col md={4}>
											<Field validate={required} name="site_number" label={t('site-number')} component={renderInput} />
										</Col>
									</Row>
									<Row className="mb-3">
										<Col md={8}>
											<Field
												validate={required}
												name="material_services"
												label={t('material-services')}
												component={renderInput}
											/>
										</Col>
										<Col md={4}>
											<Form.Group>
												<Form.Label>{t('report-types')}</Form.Label>

												<Field
													validate={required}
													name="report_type"
													options={[
														{ title: t('interm'), value: t('interm-0') },
														{ title: t('end-of-supply'), value: t('end-of-supply-0') },
													]}
													component={renderRadioButton_v2}
												/>
											</Form.Group>
										</Col>
									</Row>
									<Row className="mb-3">
										<Col md={8}>
											<Field
												validate={notEmpty}
												dataKey="supplier_id"
												textField="supplier_name"
												data={suppliers}
												name="supplier_id"
												label={t('supplier')}
												component={renderSearchableDropdown}
											/>
										</Col>
										<Col>
											<Field
												validate={required}
												name="date_created"
												label={t('date-0')}
												component={renderInput}
												inputType="date"
											/>
										</Col>
										<Col md={4}></Col>
									</Row>
									<Row className="mb-3">
										<Col md={8}>
											<Field
												validate={nonZero}
												name="lpo_no"
												dataKey="lpo_id"
												textField="reference"
												data={datas}
												component={renderDropdownWithAutoComplete}
												label={t('relevant-lpo-no')}
												allowCreate="onFilter"
											/>
										</Col>
										<Col md={4}>
											<Field
												validate={nonZero}
												name="period"
												label={t('period')}
												component={renderDropdown}
												renderedOptions={
													<>
														<option value="Q1">Q1</option>
														<option value="Q2">Q2</option>
														<option value="Q3">Q3</option>
														<option value="Q4">Q4</option>
													</>
												}
											/>
										</Col>
									</Row>
									<Row className="mb-3">
										<Col md={6} sm={12}>
											<Field name="from" label={t('name')} component={renderInput} disabled />
										</Col>
										<Col md={6} sm={12}>
											<Field name="role" label={t('title')} component={renderInput} disabled />
										</Col>
									</Row>
									<SupplierEvaluationRankingTable
										deliveryStartValue={deliveryStartValue}
										deliveryCompleteValue={deliveryCompleteValue}
										materialDeliveryValue={materialDeliveryValue}
										materialQuantityValue={materialQuantityValue}
									/>

									<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
										<Link to={Routes.Procurement.SupplierEvaluation.Index.path} className="btn btn-secondary">
											{t('back')}{' '}
										</Link>
										<SubmitButton invalid={invalid} onSubmit={props.onSubmit} text={t('submit')} />
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</form>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const deliveryStartValue = selector(state, 'delivery_start');
	const deliveryCompleteValue = selector(state, 'delivery_complete');
	const materialDeliveryValue = selector(state, 'material_delivery');
	const materialQuantityValue = selector(state, 'material_quantity');

	return {
		suppliers: state.procurement.supplier.suppliers,
		loading: state.procurement.supplier.getSuppliersLoading,
		name: state.auth.full_name,
		role: state.auth.roles[0].role_name,
		deliveryStartValue,
		deliveryCompleteValue,
		materialDeliveryValue,
		materialQuantityValue,
	};
};

export default compose(
	connect(mapStateToProps, { getSuppliers, clearData, initialize, change }),
	reduxForm({ form: 'supplierEvaluation' })
)(SupplierFeedbackForm);
