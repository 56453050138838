import React, { useEffect } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { Col, Row, Card } from '@themesberg/react-bootstrap';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { clearData } from '../../../actions/globalActions';

const Report = (props) => {
	const { embedInfo: embedInfo, loading, getEmbedInfo } = props;
	useEffect(() => {
		getEmbedInfo();

		return () => {
			props.clearData();
		};
	}, []);

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<>
					<Col className="iframe-container mt-2">
						<PowerBIEmbed
							embedConfig={{
								type: 'report', // Supported types: report, dashboard, tile, visual and qna
								id: embedInfo.embedUrl[0].reportId,
								embedUrl: embedInfo.embedUrl[0].embedUrl,
								accessToken: embedInfo.accessToken,
								tokenType: models.TokenType.Embed,
								settings: {
									panes: {
										filters: {
											expanded: false,
											visible: false,
										},
									},
									background: models.BackgroundType.Transparent,
								},
							}}
							eventHandlers={
								new Map([
									[
										'loaded',
										function () {
											console.log('Report loaded');
										},
									],
									[
										'rendered',
										function () {
											console.log('Report rendered');
										},
									],
									[
										'error',
										function (event) {
											console.log(event.detail);
										},
									],
								])
							}
							cssClassName={'report-style-class'}
							getEmbeddedComponent={(embeddedReport) => {
								window.report = embeddedReport;
							}}
						/>
					</Col>
				</>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		embedInfo: state.powerbi.config,
		loading: state.powerbi.loading,
	};
};

export default connect(mapStateToProps, { clearData })(Report);
