import React, { Component, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Breadcrumb, Card, Nav, Table } from '@themesberg/react-bootstrap';
import history from '../../../../history';

import { Link } from 'react-router-dom';
import { Routes } from '../../../../routes';

import { getUserInvitesWithFilter, deleteUserInvite } from '../../../../actions/Admin/userInviteActions';
import { connect } from 'react-redux';
import IndexTable from '../../../../components/IndexTable';
import onDelete from '../../../../helpers/deleteFunction';
import { clearData } from '../../../../actions/globalActions';

import LoadingSpinner from '../../../../components/LoadingSpinner';

import TableFilter from '../../../../components/TableFilter';
import ReactPaginate from 'react-paginate';
import { indexTableFilterQueryGenerator } from '../../../../helpers/helpers';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const InviteIndex = (props) => {
	const {
		userInvite: { userInvites },
		loading,
	} = props;
	const { t } = useTranslation();

	const [deleted, setDeleted] = useState(false);
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [query, setQuery] = useState('');
	const [order, setOrder] = useState('');
	const [search, setSearch] = useState('');

	useEffect(() => {
		props.getUserInvitesWithFilter(offset, limit, query, order, search);

		return () => {
			props.clearData();
		};
	}, [deleted, limit, offset, query, order, search]);

	// REACT PAGINATION ON CLICK FUNCTION
	const handlePageClick = (event) => {
		setOffset(event.selected);
	};

	const renderRows = () => {
		return userInvites.map((value, index) => {
			if (index != userInvites.length - 1) {
				return (
					<tr key={index}>
						<td className="fw-bold text-center">{index + 1}</td>
						<td className="fw-bold text-center">{value.email}</td>
						<td className="fw-bold text-center">
							{value.companies.map((val, index) => {
								return (
									<React.Fragment key={index}>
										{val.company_name} <br />
									</React.Fragment>
								);
							})}
						</td>
						<td className="fw-bold text-center">
							{value.roles.map((val, index) => {
								return (
									<React.Fragment key={index}>
										{val.role_name} <br />
									</React.Fragment>
								);
							})}
						</td>
						<td className="fw-bold text-center">{new Date(value.invite_date).toDateString('yyyy-MM-dd')}</td>
						<td className="fw-bold text-center">
							{value.is_registered
								? t('registered')
								: (new Date() - new Date(value.invite_date)) / (1000 * 60 * 60 * 24) > 3
								? t('expired')
								: t('not-registered')}
						</td>
						{value.is_registered ? (
							<td></td>
						) : (new Date() - new Date(value.invite_date)) / (1000 * 60 * 60 * 24) > 3 ? (
							<td>
								<Link to={Routes.Admin.Invite.Edit.path.replace(':id', value.id)} className="btn btn-primary mx-2">
									<i className="fa fa-redo"></i>
								</Link>
							</td>
						) : (
							<td className="fw-bold text-center">
								<button
									onClick={() => {
										onDelete(deleted, setDeleted, props.deleteUserInvite, value.id);
									}}
									className="btn btn-danger mx-2">
									<i className="fa fa-trash  "></i>
								</button>
								<Link to={Routes.Admin.Invite.Edit.path.replace(':id', value.id)} className="btn btn-primary mx-2">
									<i className="fa fa-edit"></i>
								</Link>
							</td>
						)}
					</tr>
				);
			} else {
				return null;
			}
		});
	};

	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0 ">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{t('invites')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<Nav className="justify-content-center row" variant="tabs" defaultActiveKey={Routes.Admin.Invite.Index.path}>
					<Nav.Item className="col text-center">
						<Nav.Link
							onClick={() => {
								history.push(Routes.Admin.User.Index.path);
							}}>
							{t('users')}{' '}
						</Nav.Link>
					</Nav.Item>
					<Nav.Item className="col text-center ">
						<Nav.Link
							onClick={() => {
								history.push(Routes.Admin.Entity.Index.path);
							}}>
							{t('companies')}{' '}
						</Nav.Link>
					</Nav.Item>
					<Nav.Item className="col text-center ">
						<Nav.Link
							className="bg-primary text-white"
							onClick={() => {
								history.push(Routes.Admin.Invite.Index.path);
							}}>
							{t('user-invites')}{' '}
						</Nav.Link>
					</Nav.Item>
					<Nav.Item className="col text-center ">
						<Nav.Link
							onClick={() => {
								history.push(Routes.Admin.Department.Index.path);
							}}>
							{t('departments-0')}{' '}
						</Nav.Link>
					</Nav.Item>
				</Nav>
				<br />
				<div
					className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center
          ">
					<h4 style={{ display: 'inline' }}>{t('invites-0')}</h4>

					<Link className="btn btn-primary py-2 px-3  " to="/Invites/create">
						{t('add-new')}{' '}
					</Link>
				</div>
				<p className="mb-0">{t('user-invite-list')}</p>
			</div>

			<IndexTable
				loading={loading}
				headerProps="text-center"
				// headers={['#', 'E-mail', 'Company ', 'Role', 'Invite Date', 'Registered', '']}
				headers={[
					'#',
					{ label: 'E-mail', value: 'email', sortable: true },
					{ label: t('company'), value: 'company', sortable: false },
					{ label: t('role'), value: 'role', sortable: false },
					{ label: t('invite-date'), value: 'invite_date', sortable: true },
					{ label: t('registered-0'), value: 'is_registered', sortable: true },
					'',
				]}
				renderRows={userInvites.length != 0 ? renderRows() : null}
				setOrder={setOrder}
				searchable
				setSearch={setSearch}
				pagination={
					userInvites.length > 1 ? (
						<ReactPaginate
							nextLabel=" >"
							onPageChange={handlePageClick}
							pageCount={Math.ceil(_.last(userInvites).page_count)}
							pageRangeDisplayed={3}
							previousLabel="< "
							pageClassName="page-item"
							pageLinkClassName="page-link"
							previousClassName="page-item"
							forcePage={offset}
							previousLinkClassName="page-link"
							nextClassName="page-item"
							nextLinkClassName="page-link"
							breakLabel="..."
							breakClassName="page-item"
							breakLinkClassName="page-link"
							containerClassName="pagination"
							activeClassName="active"
							renderOnZeroPageCount={null}
						/>
					) : null
				}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		userInvite: state.admin.userInvite,
		loading: state.admin.userInvite.getUserInvitesLoading,
	};
};

export default connect(mapStateToProps, {
	getUserInvitesWithFilter,
	deleteUserInvite,
	clearData,
})(InviteIndex);
