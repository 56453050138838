import { GET_EMBED_INFO } from '../actions/Others/types';
import { CLEAR_DATA } from '../actions/types';

const initialState = {
	config: {},
	loading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_EMBED_INFO:
			return {
				...state,
				config: action.payload,
				loading: false,
			};
		case CLEAR_DATA:
			return {
				...state,
				config: action.payload,
				loading: true,
			};
		default:
			return state;
	}
};
