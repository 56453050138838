export default ({ input, label, val }) => {
	return (
		<div className="form-check">
			<input
				checked={input.value == val}
				onChange={() => {
					if (input.value == val) {
						input.onChange('');
					} else {
						input.onChange(val);
					}
				}}
				className="form-check-input"
				type="checkbox"
			/>
			<label className="form-check-label">{label}</label>
		</div>
	);
};
