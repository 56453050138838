import React, { useEffect } from 'react';
import SalaryIncrementForm from './SalaryIncrementForm';

import { getSalaryIncrement, updateSalaryIncrement } from '../../../../actions/HumanResources/salaryIncrementAction';

import { clearData } from '../../../../actions/globalActions';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import _ from 'lodash';
import { getEmployees } from '../../../../actions/HumanResources/employeeActions';

const SalaryIncrementEdit = (props) => {
	const { id } = props.match.params;

	const { datas, loading } = props;

	useEffect(() => {
		props.getSalaryIncrement(id);
		props.getEmployees();
		return () => {
			props.clearData();
		};
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.updateSalaryIncrement(formValues);
		}
	};

	return (
		<div className="mt-3">
			{loading ? (
				<LoadingSpinner />
			) : (
				<SalaryIncrementForm onSubmit={onSubmit} initials={datas.salaryIncrement} datas={datas} />
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	const {
		humanResources: {
			salaryIncrement: { salaryIncrement, getSalaryIncrementLoading },
			employee: { employees, getEmployeesLoading },
		},
		auth: { companies },
	} = state;

	return {
		datas: { salaryIncrement, companies, employees },
		loading: getSalaryIncrementLoading || getEmployeesLoading,
	};
};

export default connect(mapStateToProps, { getSalaryIncrement, updateSalaryIncrement, clearData, getEmployees })(
	SalaryIncrementEdit
);
