import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Checklist from '../../../../components/Checklist/Checklist';
import { Routes } from '../../../../routes';
import history from '../../../../history';
import { getISRChecklists, toggleChecklist } from '../../../../actions//Procurement/isrActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { Card, Col, Row } from '@themesberg/react-bootstrap';
import QuotationCreate from '../Quotation/QuotationCreate';
import QuotationIndex from '../Quotation/QuotationIndex';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const onSubmit = () => {
	history.push(Routes.Procurement.ISR.Index.path);
};
const ISRChecklist = (props) => {
	const { id } = props.match.params;
	const { checklists, getChecklistsLoading } = props;

	useEffect(() => {
		props.getISRChecklists(id);
	}, []);
	const { t } = useTranslation();

	return (
		<div className=" mt-3">
			{getChecklistsLoading ? (
				<LoadingSpinner />
			) : (
				<Row>
					<Col lg={8} md={12} sm={12}>
						<Card>
							<Card.Body>
								<div
									className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center	
							">
									<h4 style={{ display: 'inline' }}>{t('quotations')}</h4>
									<Link
										className="btn btn-primary py-2 px-3  "
										to={Routes.Procurement.Quotation.Create.path.replace(':isrId', id)}>
										{t('add-new')}{' '}
									</Link>
								</div>
								<p className="mb-0">{t('your-most-recent-quotations-can-be-seen-here')}</p>
								<QuotationIndex isrId={id} />
								<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center ">
									<Link to={Routes.Procurement.ISR.Index.path} className="btn btn-secondary">
										{t('back')}{' '}
									</Link>
								</div>
							</Card.Body>
						</Card>
					</Col>
					<Col lg={4} md={12} sm={12}>
						<Checklist
							initial={checklists}
							title={t('procurement-officer-checklist')}
							onSubmit={onSubmit}
							toggleChecklist={props.toggleChecklist}
						/>
					</Col>
				</Row>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		checklists: state.procurement.isr.checklists,
		getChecklistsLoading: state.procurement.isr.getChecklistsLoading,
	};
};

export default connect(mapStateToProps, { getISRChecklists, toggleChecklist })(ISRChecklist);
