import React, { useState } from 'react';
import IndexTable from '../../../../components/IndexTable';
import { getQuotationsByISR, deleteQuotation } from '../../../../actions/Procurement/quotationActions';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Routes } from '../../../../routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import onDelete from '../../../../helpers/deleteFunction';
import { useTranslation } from 'react-i18next';

const QuotationIndex = (props) => {
	const { datas, loading, isrId } = props;

	const [deleted, setDeleted] = useState(false);

	useEffect(() => {
		props.getQuotationsByISR(isrId);
	}, [deleted]);
	const { t } = useTranslation();

	const renderQuotations = () => {
		return datas.quotations.map((quotation, index) => {
			return (
				<tr key={index}>
					<td className="fw-bold text-center">{index + 1}</td>
					<td className="fw-bold text-center">{quotation.supplier.supplier_name}</td>
					<td className="fw-bold text-center">{quotation.user.full_name}</td>
					<td className="fw-bold text-center">{quotation.company.company_name}</td>
					<td className="fw-bold text-center">{quotation.status}</td>
					<td className="fw-bold text-center">
						{quotation.status != 'DELETED' ? (
							<>
								{/* {authorizationCheckFunction(Routes.Finance.Project.Delete.policy, userPolicy) ? ( */}
								<button
									onClick={() => {
										onDelete(deleted, setDeleted, props.deleteQuotation, quotation.quotation_id);
									}}
									className="btn btn-danger mx-2">
									<FontAwesomeIcon icon={faTrash} />
								</button>
								{/* ) : null} */}
								{/* {authorizationCheckFunction(Routes.Finance.Project.Delete.policy, userPolicy) ? ( */}
								<Link
									to={Routes.Procurement.Quotation.Edit.path.replace(':id', quotation.quotation_id)}
									className="btn btn-primary mx-2">
									<FontAwesomeIcon icon={faEdit} />
								</Link>
								{/* ) : null} */}
							</>
						) : null}
					</td>
				</tr>
			);
		});
	};

	return (
		<div>
			<IndexTable
				loading={loading}
				headerProps="text-center"
				headers={['#', t('supplier'), t('user'), t('company'), t('status'), '']}
				renderRows={datas.quotations.length != 0 ? renderQuotations() : null}
				// filter={
				// 	<div className="mb-3">
				// 		<TableFilter
				// 			initialFilterCategories={initialFilterCategories}
				// 			filters={filters}
				// 			setFilters={setFilters}
				// 			datas={projects}
				// 			setQuery={setQuery}
				// 			filterCategories={filterCategories}
				// 			setFilterCategories={setFilterCategories}
				// 		/>
				// 	</div>
				// }
				// pagination={
				// 	projects.length > 1 ? (
				// 		<ReactPaginate
				// 			nextLabel=" >"
				// 			onPageChange={handlePageClick}
				// 			pageCount={Math.ceil(_.last(projects).page_count)}
				// 			pageRangeDisplayed={3}
				// 			previousLabel="< "
				// 			pageClassName="page-item"
				// 			pageLinkClassName="page-link"
				// 			previousClassName="page-item"
				// 			forcePage={offset}
				// 			previousLinkClassName="page-link"
				// 			nextClassName="page-item"
				// 			nextLinkClassName="page-link"
				// 			breakLabel="..."
				// 			breakClassName="page-item"
				// 			breakLinkClassName="page-link"
				// 			containerClassName="pagination"
				// 			activeClassName="active"
				// 			renderOnZeroPageCount={null}
				// 		/>
				// 	) : null
				// }
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const {
		procurement: {
			quotation: { quotations, getQuotationsLoading },
		},
	} = state;

	return {
		datas: { quotations },
		loading: getQuotationsLoading,
	};
};

export default connect(mapStateToProps, { getQuotationsByISR, deleteQuotation })(QuotationIndex);
