import { Form } from '@themesberg/react-bootstrap';
import React, { useEffect } from 'react';

export default ({ label, input, meta, renderedOptions, disabled }) => {
	return (
		<Form.Group>
			{label ? <Form.Label>{label}</Form.Label> : null}

			<Form.Select {...input} disabled={disabled}>
				<option value="0">Select</option>
				{renderedOptions}
			</Form.Select>
			<span className="text-danger">{meta.touched && meta.error ? meta.error : null}</span>
		</Form.Group>
	);
};
