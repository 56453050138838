import { CREATE_LOCATION, UPDATE_LOCATION, GET_LOCATIONS, GET_LOCATION, DELETE_LOCATION } from './types';

import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';

export const createLocation = (formValues) => async (dispatch, getState) => {
	axios
		.post('/delivery/location', formValues)
		.then((response) => {
			dispatch({ type: CREATE_LOCATION, payload: response.data });
			history.push(Routes.Delivery.Panel.Location.Index.path);
		})
		.catch((err) => {});
};

export const getLocations = () => async (dispatch) => {
	const response = await axios.get('/delivery/location');
	dispatch({ type: GET_LOCATIONS, payload: response.data });
};

export const getLocationesWithFilter = (offset, limit, query) => async (dispatch) => {
	axios
		.get(`/delivery/location/offset/${offset}/limit/${limit}?${query}`)
		.then((response) => {
			dispatch({ type: GET_LOCATIONES, payload: response.data });
		})
		.catch((err) => {});
};

export const getLocation = (id) => async (dispatch) => {
	const response = await axios.get(`/delivery/location/${id}`);
	dispatch({ type: GET_LOCATION, payload: response.data });
};

export const deleteLocation = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`delivery/location/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_LOCATION, payload: response.data });
			setDeleted(!deleted);
		})
		.catch((err) => {});
};

export const updateLocation = (formValues) => async (dispatch) => {
	axios
		.put(`delivery/location/${formValues.building_id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_LOCATION, payload: response.data });
			history.push(Routes.Delivery.Panel.Location.Index.path);
		})
		.catch((err) => {});
};
