import { CREATE_START_WORK, UPDATE_START_WORK, GET_START_WORKS, GET_START_WORK, DELETE_START_WORK } from './types';

import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';

export const createStartWork = (formValues) => async (dispatch, getState) => {
	axios
		.post('/start-work', formValues)
		.then((response) => {
			dispatch({ type: CREATE_START_WORK, payload: response.data });
			history.push(Routes.HumanResources.StartWork.Index.path);
		})
		.catch((err) => {});
};

export const getStartWorks = () => async (dispatch) => {
	axios
		.get('/start-work')
		.then((response) => {
			dispatch({ type: GET_START_WORKS, payload: response.data });
		})
		.catch((err) => {});
};

export const getStartWorksWithFilter = (offset, limit, query) => async (dispatch) => {
	axios
		.get(`/start-work/offset/${offset}/limit/${limit}?${query}`)
		.then((response) => {
			dispatch({ type: GET_START_WORKS, payload: response.data });
		})
		.catch((err) => {});
};

export const getStartWork = (id) => async (dispatch) => {
	const response = await axios.get(`/start-work/${id}`);
	dispatch({ type: GET_START_WORK, payload: response.data });
};

export const deleteStartWork = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`start-work/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_START_WORK, payload: response.data });
			setDeleted(!deleted);
		})
		.catch((err) => {});
};

export const updateStartWork = (formValues) => async (dispatch) => {
	axios
		.put(`start-work/${formValues.id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_START_WORK, payload: response.data });
			history.push(Routes.HumanResources.StartWork.Index.path);
		})
		.catch((err) => {});
};
