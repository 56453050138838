import { useMsal } from '@azure/msal-react';
import { Card, Col, Container, Row, Image } from '@themesberg/react-bootstrap';
import { t } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

import UnauthImage from '../../../assets/img/illustrations/401.svg';

const UnInvited = () => {
	const { t } = useTranslation();
	const { instance } = useMsal();

	setTimeout(() => {
		instance.logoutRedirect({
			postLogoutRedirectUri: '/',
		});
	}, 3000);
	return (
		<main>
			<section className="vh-100 d-flex align-items-center justify-content-center">
				<Container>
					<Row>
						<Col xs={12} className="text-center d-flex align-items-center justify-content-center">
							<div>
								<Card.Link>
									<Image src={UnauthImage} className="img-fluid w-75" />
								</Card.Link>
								<h1 className="text-primary mt-5">{t('you-are-not-invited')}</h1>
								<p className="lead my-4">{t('please-contact-to-admins-for-an-invite')} </p>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</main>
	);
};

export default UnInvited;
