import {
	CREATE_SALARY_DEDUCTION,
	UPDATE_SALARY_DEDUCTION,
	GET_SALARY_DEDUCTIONS,
	GET_SALARY_DEDUCTION,
	DELETE_SALARY_DEDUCTION,
} from './types';

import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';

export const createSalaryDeduction = (formValues) => async (dispatch, getState) => {
	axios
		.post('/salary-deduction', formValues)
		.then((response) => {
			dispatch({ type: CREATE_SALARY_DEDUCTION, payload: response.data });
			history.push(Routes.HumanResources.SalaryDeduction.Index.path);
		})
		.catch((err) => {});
};

export const getSalaryDeductions = () => async (dispatch) => {
	const response = await axios.get('/salary-deduction');
	dispatch({ type: GET_SALARY_DEDUCTIONS, payload: response.data });
};

export const getSalaryDeductionsWithFilter = (offset, limit, query) => async (dispatch) => {
	axios
		.get(`/salary-deduction/offset/${offset}/limit/${limit}?${query}`)
		.then((response) => {
			dispatch({ type: GET_SALARY_DEDUCTIONS, payload: response.data });
		})
		.catch((err) => {});
};

export const getSalaryDeduction = (id) => async (dispatch) => {
	const response = await axios.get(`/salary-deduction/${id}`);
	dispatch({ type: GET_SALARY_DEDUCTION, payload: response.data });
};

export const deleteSalaryDeduction = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`salary-deduction/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_SALARY_DEDUCTION, payload: response.data });
			setDeleted(!deleted);
		})
		.catch((err) => {});
};

export const updateSalaryDeduction = (formValues) => async (dispatch) => {
	axios
		.put(`salary-deduction/${formValues.id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_SALARY_DEDUCTION, payload: response.data });
			history.push(Routes.HumanResources.SalaryDeduction.Index.path);
		})
		.catch((err) => {});
};
