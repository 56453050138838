import React, { Component, useEffect, useState } from 'react';
import { createProject } from '../../../../actions/Construction/projectActions';
import { connect } from 'react-redux';
import ProjectForm from './ProjectForm';
import { getEntities } from '../../../../actions/Admin/entityActions';
import { getUsers } from '../../../../actions/User/userActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
const ProjectCreate = (props) => {
	const { datas, loading } = props;

	const [contract, setContract] = useState([]);
	const [files, setFiles] = useState([]);

	useEffect(() => {
		props.getEntities();
		props.getUsers();
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues) && formValues.isValid) {
			formValues.contract = contract || [];
			formValues.files = files || [];

			props.createProject(formValues);
		}
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<ProjectForm datas={datas} onSubmit={onSubmit} setContract={setContract} setFiles={setFiles} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		admin: {
			entity: { entities, getEntitiesLoading },
		},
		user: { users, getUsersLoading },
	} = state;

	return {
		datas: { entities, users },
		loading: getEntitiesLoading || getUsersLoading,
	};
};

export default connect(mapStateToProps, { createProject, getEntities, getUsers })(ProjectCreate);
