import axios from '../apis/axios';
import { GET_CATEGORIES, GET_ITEMS } from './types';

export const getCategories = () => async (dispatch) => {
	axios
		.get('item-category')
		.then((response) => {
			dispatch({ type: GET_CATEGORIES, payload: response.data });
		})
		.catch((err) => {});
};

export const getItems = () => async (dispatch) => {
	axios
		.get('item')
		.then((response) => {
			dispatch({ type: GET_ITEMS, payload: response.data });
		})
		.catch((err) => {});
};
