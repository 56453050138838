import React, { useEffect } from 'react';
import SalaryDeductionForm from './SalaryDeductionForm';

import { getSalaryDeduction, updateSalaryDeduction } from '../../../../actions/HumanResources/salaryDeductionAction';

import { clearData } from '../../../../actions/globalActions';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import _ from 'lodash';
import { getEmployees } from '../../../../actions/HumanResources/employeeActions';

const SalaryDeductionEdit = (props) => {
	const { id } = props.match.params;

	const { datas, loading } = props;

	useEffect(() => {
		props.getSalaryDeduction(id);
		props.getEmployees();
		return () => {
			props.clearData();
		};
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.updateSalaryDeduction(formValues);
		}
	};

	return (
		<div className="mt-3">
			{loading ? (
				<LoadingSpinner />
			) : (
				<SalaryDeductionForm onSubmit={onSubmit} initials={datas.salaryDeduction} datas={datas} />
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	const {
		humanResources: {
			salaryDeduction: { salaryDeduction, getSalaryDeductionLoading },
			employee: { employees, getEmployeesLoading },
		},
		auth: { companies },
	} = state;

	return {
		datas: { salaryDeduction, companies, employees },
		loading: getSalaryDeductionLoading || getEmployeesLoading,
	};
};

export default connect(mapStateToProps, { getSalaryDeduction, updateSalaryDeduction, clearData, getEmployees })(
	SalaryDeductionEdit
);
