import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faShoppingCart, faCogs, faCog } from '@fortawesome/free-solid-svg-icons';
import { getOrder } from '../../../../actions/Delivery/orderActions';
import { connect } from 'react-redux';
import _ from 'lodash';
import { clearData } from '../../../../actions/globalActions';
import { Routes } from '../../../../routes';
import { Card, Col, ListGroup, Row } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { useTranslation } from 'react-i18next';
const OrderDetail = (props) => {
	const { id } = props.match.params;

	const { datas, loading } = props;

	const { orderInfo } = datas;
	const { t } = useTranslation();

	useEffect(() => {
		props.getOrder(id);
	}, []);

	var orderDate = new Date(orderInfo.createdAt);
	var date_format_str =
		orderDate.getFullYear().toString() +
		'-' +
		((orderDate.getMonth() + 1).toString().length == 2
			? (orderDate.getMonth() + 1).toString()
			: '0' + (orderDate.getMonth() + 1).toString()) +
		'-' +
		(orderDate.getDate().toString().length == 2
			? orderDate.getDate().toString()
			: '0' + orderDate.getDate().toString()) +
		' ' +
		(orderDate.getHours().toString().length == 2
			? orderDate.getHours().toString()
			: '0' + orderDate.getHours().toString()) +
		':' +
		((parseInt(orderDate.getMinutes() / 5) * 5).toString().length == 2
			? (parseInt(orderDate.getMinutes() / 5) * 5).toString()
			: '0' + (parseInt(orderDate.getMinutes() / 5) * 5).toString()) +
		':00';
	return (
		<div>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="py-4 col-lg-6 col-12">
					<Card className="p-0 ">
						<Card.Header className="border-bottom-0 p-4">
							<h4>{t('order-details')}</h4>
						</Card.Header>
						<Card.Body className="px-2 py-0">
							<ListGroup className="border-bottom border-top">
								<ListGroup.Item>
									<Row>
										<Col>
											<h6 className="mb-1">{t('to-email')}</h6>
											<small className="text-gray-700">{orderInfo.to_email}</small>
										</Col>
									</Row>
								</ListGroup.Item>
							</ListGroup>
							<ListGroup className="border-bottom">
								<ListGroup.Item>
									<Row>
										<Col>
											<h6 className="mb-1">{t('notes')}</h6>
											<small className="text-gray-700">{orderInfo.notes}</small>
										</Col>
									</Row>
								</ListGroup.Item>
							</ListGroup>
							<ListGroup className="border-bottom">
								<ListGroup.Item>
									<Row>
										<Col>
											<h6 className="mb-1">{t('status')}</h6>
											<small className="text-gray-700">{orderInfo.status}</small>
										</Col>
									</Row>
								</ListGroup.Item>
							</ListGroup>
							<ListGroup className="border-bottom">
								<ListGroup.Item>
									<Row>
										<Col>
											<h6 className="mb-1">{t('order-detail')}</h6>
											<small className="text-gray-700">
												{orderInfo.order_details.map((item, index) => {
													return (
														<React.Fragment key={index}>
															{item.item_name} : {item.item_count}, &emsp;
														</React.Fragment>
													);
												})}
											</small>
										</Col>
									</Row>
								</ListGroup.Item>
							</ListGroup>
							<ListGroup className="border-bottom">
								<ListGroup.Item>
									<Row>
										<Col>
											<h6 className="mb-1">{t('order-date')}</h6>
											<small className="text-gray-700">{date_format_str}</small>
										</Col>
									</Row>
								</ListGroup.Item>
							</ListGroup>

							<Row className="mt-3 mb-3 px-2">
								<Col>
									<Link className="btn btn-secondary" to={Routes.Delivery.Order.Index.path}>
										{t('back')}{' '}
									</Link>
								</Col>
							</Row>
						</Card.Body>
					</Card>
					{/* <div className="shadow-sm p-0 p-md-4 card bg-white border-light">
						<div className="border-bottom p -3 card-header">
							<h4>Supplier Details</h4>
						</div>
						<div className="px-0 py-0 card-body">
							
						</div>
					</div> */}
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	const {
		delivery: {
			order: { orderInfo, getOrderLoading },
		},
	} = state;

	return {
		datas: { orderInfo },
		loading: getOrderLoading,
	};
};

export default connect(mapStateToProps, { getOrder })(OrderDetail);
