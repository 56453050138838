import { CREATE_EMPLOYEE, UPDATE_EMPLOYEE, GET_EMPLOYEES, GET_EMPLOYEE, DELETE_EMPLOYEE } from './types';

import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';

export const createEmployee = (formValues) => async (dispatch, getState) => {
	axios
		.post('/employee', formValues)
		.then((response) => {
			dispatch({ type: CREATE_EMPLOYEE, payload: response.data });
			history.push(Routes.HumanResources.Employee.Index.path);
		})
		.catch((err) => {});
};

export const getEmployees = () => async (dispatch) => {
	const response = await axios.get('/employee');
	dispatch({ type: GET_EMPLOYEES, payload: response.data });
};

export const getEmployeesWithFilter = (offset, limit, query) => async (dispatch) => {
	axios
		.get(`/employee/offset/${offset}/limit/${limit}?${query}`)
		.then((response) => {
			dispatch({ type: GET_EMPLOYEES, payload: response.data });
		})
		.catch((err) => {});
};

export const getEmployee = (id) => async (dispatch) => {
	const response = await axios.get(`/employee/${id}`);
	dispatch({ type: GET_EMPLOYEE, payload: response.data });
};

export const deleteEmployee = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`employee/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_EMPLOYEE, payload: response.data });
			setDeleted(!deleted);
		})
		.catch((err) => {});
};

export const updateEmployee = (formValues) => async (dispatch) => {
	axios
		.put(`employee/${formValues.employee_id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_EMPLOYEE, payload: response.data });
			history.push(Routes.HumanResources.Employee.Index.path);
		})
		.catch((err) => {});
};
