import {
	CREATE_ISR,
	GET_ISRS,
	GET_ISR,
	DELETE_ISR,
	GET_ISR_CHECKLISTS,
	GET_ISRS_PAGINATED,
} from '../../actions/Procurement/types';

import { CLEAR_DATA } from '../../actions/types';

const initialState = {
	isr: {},
	isrs: [],
	isrs_table: [],
	loading: true,
	getISRLoading: true,
	getISRsLoading: true,
	checklists: {},
	getChecklistsLoading: true,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_ISRS:
			return { ...state, isrs: action.payload, getISRsLoading: false };
		case GET_ISR:
			return { ...state, isr: action.payload, getISRLoading: false };
		case GET_ISRS_PAGINATED:
			return { ...state, isrs_table: action.payload, getISRsLoading: false };

		case CREATE_ISR:
			return { ...state, isr: action.payload };

		case GET_ISR_CHECKLISTS:
			return {
				...state,
				checklists: action.payload,
				getChecklistsLoading: false,
			};

		case CLEAR_DATA:
			return {
				...state,
				checklist: action.payload,
				getChecklistsLoading: true,
				isrs: [],
				isrs_table: [],
				isr: action.payload,
				getISRLoading: true,
				getISRsLoading: true,
			};

		case DELETE_ISR:
			return { ...state };

		default:
			return state;
	}
};
