import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ResignationForm from './ResignationForm';
import { getResignation, updateResignation } from '../../../../actions/HumanResources/resignationActions';
import { getEmployees } from '../../../../actions/HumanResources/employeeActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { clearData } from '../../../../actions/globalActions';

const ResignationEdit = (props) => {
	const { id } = props.match.params;

	const { datas, loading } = props;

	useEffect(() => {
		props.getResignation(id);
		props.getEmployees();

		return () => {
			props.clearData();
		};
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.updateResignation(formValues);
		}
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<ResignationForm onSubmit={onSubmit} initials={datas.resignation} datas={datas} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		humanResources: {
			employee: { employees, getEmployeesLoading },
			resignation: { resignation, getResignationLoading },
		},
	} = state;

	return {
		datas: { employees, resignation },
		loading: getEmployeesLoading || getResignationLoading,
	};
};

export default connect(mapStateToProps, { updateResignation, getResignation, getEmployees, clearData })(
	ResignationEdit
);
