import Profile3 from '../assets/img/team/profile-picture-3.jpg';

export default [
	{
		id: 1,
		read: false,
		image: Profile3,
		sender: 'Saportif Technology Portal',
		time: 'a few moments ago',
		link: '#',
		message: `Welcome to Saportif Technology Portal! If you need any help just click the help button at the end of the page.`,
	},
];
