import axios from '../../apis/axios';
import history from '../../history';
import { Routes } from '../../routes';
import { GET_HR_EMPLOYEE_EVALUATION, GET_HR_EMPLOYEE_EVALUATIONS } from './types';

const url = 'employee-evaluation';

export const getHREmployeeEvaluations = () => async (dispatch) => {
	axios
		.get(url)
		.then((response) => {
			dispatch({ type: GET_HR_EMPLOYEE_EVALUATIONS, payload: response.data });
		})
		.catch((err) => {});
};

export const getHREmployeeEvaluation = (id) => async (dispatch) => {
	axios
		.get(url + `/${id}`)
		.then((response) => {
			dispatch({ type: GET_HR_EMPLOYEE_EVALUATION, payload: response.data });
		})
		.catch((err) => {});
};

export const createHREmployeeEvaluation = (formValues) => async (dispatch) => {
	axios
		.post(url, formValues)
		.then((response) => {
			history.push(Routes.HumanResources.EmployeeEvaluation.Index.path);
		})
		.catch((err) => {});
};

export const updateHREmployeeEvaluation = (formValues) => async (dispatch) => {
	axios
		.put(url + `/${formValues.id}`, formValues)
		.then((response) => {
			history.push(Routes.HumanResources.EmployeeEvaluation.Index.path);
		})
		.catch((err) => {});
};

export const deleteHREmployeeEvaluation = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(url + `/${id}`)
		.then((response) => {
			setDeleted(!deleted);
		})
		.catch((err) => {});
};
