import { faEdit, faHome, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import { deleteDiscipline, getDisciplinesWithFilter } from '../../../../actions/Construction/disciplineActions';
import { clearData } from '../../../../actions/globalActions';
import IndexTable from '../../../../components/IndexTable';
import { Routes } from '../../../../routes';

import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import onDelete from '../../../../helpers/deleteFunction';
import { indexTableFilterQueryGenerator } from '../../../../helpers/helpers';

const DisciplinesIndex = (props) => {
	const { t } = useTranslation();
	const { disciplines, loading } = props;

	const [deleted, setDeleted] = useState(false);
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [query, setQuery] = useState('');
	const [order, setOrder] = useState('');
	const [search, setSearch] = useState('');
	const initialFilterCategories = [
		{ label: t('company'), displayField: null, value: 'company.company_name', queryName: t('company') },
		{ label: t('status'), displayField: null, value: 'status', queryName: t('status') },
	];

	const [filters, setFilters] = useState([]);
	const [filterCategories, setFilterCategories] = useState(initialFilterCategories);

	useEffect(() => {
		props.getDisciplinesWithFilter(offset, limit, query, order, search);
		return () => {
			props.clearData();
		};
	}, [deleted, limit, offset, query, order, search]);

	useEffect(() => {
		indexTableFilterQueryGenerator(filters, setQuery);
	}, [filters.length]);

	const handlePageClick = (event) => {
		setOffset(event.selected);
	};

	const renderDisciplines = () => {
		return disciplines.map((val, index) => {
			if (index != disciplines.length - 1) {
				return (
					<tr key={index}>
						<td className="fw-bold text-center">{index + 1}</td>
						<td className="fw-bold text-center">{val.discipline_name}</td>
						<td className="fw-bold text-center">
							<button
								onClick={() => {
									onDelete(deleted, setDeleted, props.deleteDiscipline, val.discipline_id);
								}}
								className="btn btn-danger mx-2">
								<FontAwesomeIcon icon={faTrash} />
							</button>
							<Link
								to={Routes.Construction.Discipline.Edit.path.replace(':id', val.discipline_id)}
								className="btn btn-primary mx-2">
								<FontAwesomeIcon icon={faEdit} />
							</Link>
						</td>
					</tr>
				);
			} else {
				return null;
			}
		});
	};
	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0 ">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{t('disciplines')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>

				<div
					className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-5
          ">
					<h4 style={{ display: 'inline' }}>{t('disciplines')}</h4>
					<Link className="btn btn-primary py-2 px-3  " to={Routes.Construction.Discipline.Create.path}>
						{t('add-new')}{' '}
					</Link>
				</div>
				<p className="mb-0">{t('manage-your-disciplines-for-construction-projects')}</p>
			</div>

			<IndexTable
				loading={loading}
				searchable
				setSearch={setSearch}
				headerProps="text-center"
				headers={['#', t('disciplines'), '']}
				renderRows={disciplines.length > 1 ? renderDisciplines() : null}
				//FILTER KOY

				pagination={
					disciplines.length > 1 ? (
						<ReactPaginate
							nextLabel=" >"
							onPageChange={handlePageClick}
							pageCount={Math.ceil(_.last(disciplines).page_count)}
							pageRangeDisplayed={3}
							previousLabel="< "
							pageClassName="page-item"
							pageLinkClassName="page-link"
							previousClassName="page-item"
							forcePage={offset}
							previousLinkClassName="page-link"
							nextClassName="page-item"
							nextLinkClassName="page-link"
							breakLabel="..."
							breakClassName="page-item"
							breakLinkClassName="page-link"
							containerClassName="pagination"
							activeClassName="active"
							renderOnZeroPageCount={null}
						/>
					) : null
				}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		construction: {
			discipline: { disciplines, getDisciplinesLoading },
		},
	} = state;

	return {
		disciplines,
		loading: getDisciplinesLoading,
	};
};

export default connect(mapStateToProps, {
	clearData,
	getDisciplinesWithFilter,
	deleteDiscipline,
})(DisciplinesIndex);
