import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ResignationForm from './ResignationForm';
import { getEmployees } from '../../../../actions/HumanResources/employeeActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import { createResignation } from '../../../../actions/HumanResources/resignationActions';

const ResignationCreate = (props) => {
	const { datas, loading } = props;

	useEffect(() => {
		props.getEmployees();
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.createResignation(formValues);
		}
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<ResignationForm onSubmit={onSubmit} datas={datas} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		humanResources: {
			employee: { employees, getEmployeesLoading },
		},
	} = state;

	return {
		datas: { employees },
		loading: getEmployeesLoading,
	};
};

export default connect(mapStateToProps, { getEmployees, createResignation })(ResignationCreate);
