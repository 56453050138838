import { faEdit, faEye, faHome, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import ReactPaginate from 'react-paginate';
import { deletePunchList, getPunchListsWithFilter } from '../../../../actions/Construction/punchListActions';
import { clearData } from '../../../../actions/globalActions';
import IndexTable from '../../../../components/IndexTable';
import TableFilter from '../../../../components/TableFilter';
import onDelete from '../../../../helpers/deleteFunction';
import { indexTableFilterQueryGenerator } from '../../../../helpers/helpers';
import { Routes } from '../../../../routes';
import { useTranslation } from 'react-i18next';

const PunchListIndex = (props) => {
	const { punchLists, loading } = props;
	const { t } = useTranslation();
	const [deleted, setDeleted] = useState(false);
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [query, setQuery] = useState('');
	const [order, setOrder] = useState('');
	const [search, setSearch] = useState('');
	const initialFilterCategories = [
		{ label: t('company'), displayField: null, value: 'company.company_name', queryName: t('company') },
		{ label: t('status'), displayField: null, value: 'status', queryName: t('status') },
	];

	const [filters, setFilters] = useState([]);
	const [filterCategories, setFilterCategories] = useState(initialFilterCategories);

	useEffect(() => {
		console.log('here');
		props.getPunchListsWithFilter(offset, limit, query, order, search);
		return () => {
			props.clearData();
		};
	}, [deleted, limit, offset, query, order, search]);

	useEffect(() => {
		indexTableFilterQueryGenerator(filters, setQuery);
	}, [filters.length]);

	const handlePageClick = (event) => {
		setOffset(event.selected);
	};

	const renderCashFlows = () => {
		return punchLists.map((val, index) => {
			if (index != punchLists.length - 1) {
				/*BURADAKİ ŞEYİ SOR*/
				return (
					<tr key={index}>
						<td className="fw-bold text-center">{index + 1}</td>
						<td className="fw-bold text-center">{val.title}</td>
						<td className="fw-bold text-center">{val.contractor}</td>
						<td className="fw-bold text-center">{val.contract_title}</td>
						<td className="fw-bold text-center">{val.issued_by}</td>
						<td className="fw-bold text-center">{val.status}</td>
						<td className="fw-bold text-center">{new Date(val.date).toLocaleDateString()}</td>
						<td className="fw-bold text-center">
							<button
								onClick={() => {
									onDelete(deleted, setDeleted, props.deletePunchList, val.id);
								}}
								className="btn btn-danger mx-2">
								<FontAwesomeIcon icon={faTrash} />
							</button>
							<Link
								to={Routes.Construction.PunchList.Details.path.replace(':id', val.id)}
								className="btn btn-primary mx-2">
								<FontAwesomeIcon icon={faEye} />
							</Link>
							<Link
								to={Routes.Construction.PunchList.Edit.path.replace(':id', val.id)}
								className="btn btn-primary mx-2">
								<FontAwesomeIcon icon={faEdit} />
							</Link>
						</td>
					</tr>
				);
			} else {
				return null;
			}
		});
	};
	return (
		<>
			<div className=" py-4">
				<div className="d-block mb-4 mb-xl-0 ">
					<Breadcrumb
						className=" d-block"
						listProps={{
							className: 'breadcrumb-dark breadcrumb-transparent',
						}}>
						<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
							<FontAwesomeIcon icon={faHome} />
						</Breadcrumb.Item>
						<Breadcrumb.Item active>{t('punch-list')}</Breadcrumb.Item>
					</Breadcrumb>
				</div>

				<div
					className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-5
          ">
					<h4 style={{ display: 'inline' }}>{t('punch-list')}</h4>
					<Link className="btn btn-primary py-2 px-3  " to={Routes.Construction.PunchList.Create.path}>
						{t('add-new')}{' '}
					</Link>
				</div>
				<p className="mb-0">{t('manage-your-latest-trades-here')}</p>
			</div>

			<IndexTable
				loading={loading}
				filter={
					<div className="align-self-center">
						<TableFilter
							initialFilterCategories={initialFilterCategories}
							filters={filters}
							setFilters={setFilters}
							datas={punchLists}
							setQuery={setQuery}
							filterCategories={filterCategories}
							setFilterCategories={setFilterCategories}
						/>
					</div>
				}
				searchable
				setSearch={setSearch}
				headerProps="text-center"
				headers={['#', t('title'), t('contractor'), t('contract'), t('issued-by'), t('status'), t('date-0'), '']}
				renderRows={punchLists.length > 1 ? renderCashFlows() : null}
				//FILTER KOY
				pagination={
					punchLists.length > 1 ? (
						<ReactPaginate
							nextLabel=" >"
							onPageChange={handlePageClick}
							pageCount={Math.ceil(_.last(punchLists).page_count)}
							pageRangeDisplayed={3}
							previousLabel="< "
							pageClassName="page-item"
							pageLinkClassName="page-link"
							previousClassName="page-item"
							forcePage={offset}
							previousLinkClassName="page-link"
							nextClassName="page-item"
							nextLinkClassName="page-link"
							breakLabel="..."
							breakClassName="page-item"
							breakLinkClassName="page-link"
							containerClassName="pagination"
							activeClassName="active"
							renderOnZeroPageCount={null}
						/>
					) : null
				}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		punchLists: state.punchList.punchLists,
		loading: state.punchList.getpunchListsLoading,
	};
};

export default connect(mapStateToProps, {
	getPunchListsWithFilter,
	clearData,
	deletePunchList,
})(PunchListIndex);
