import { Card, Col, ListGroup, ListGroupItem, Row } from '@themesberg/react-bootstrap';
import { Accordion } from 'react-bootstrap';
import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Routes } from '../../../routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faHome,
	faUserTie,
	faArrowRight,
	faFile,
	faFileAlt,
	faMoneyBillWaveAlt,
	faExclamation,
	faBriefcase,
	faMoneyBill,
	faDoorOpen,
	faTimes,
	faCalendar,
	faCalendarAlt,
	faCalendarTimes,
	faCalendarCheck,
} from '@fortawesome/free-solid-svg-icons';
import history from '../../../history';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import IndexTable from '../../../components/IndexTable';
import { useTranslation } from 'react-i18next';

const HumanResourcesReport = () => {
	const { innerWidth: width, innerHeight: height } = window;

	const { t } = useTranslation();
	const data = {
		labels: [
			t('development-team'),
			t('business-team'),
			t('human-resources'),
			t('devops-team'),
			t('infrastructure-team'),
		],
		datasets: [
			{
				label: t('of-votes'),
				data: [12, 19, 3, 8, 2],
				backgroundColor: ['#262B40', '#17a5ce', '#1B998B', '#ADD8E6', '#24CBB8'],
				borderColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'],
				borderWidth: 1,
			},
		],
	};

	const publicHolidaysData = [
		{
			holiday: t('national-sports-day'),
			days: '1',
			date: t('11-february'),
		},
		{
			holiday: t('eid-al-fitr'),
			days: '3',
			date: t('3-may'),
		},
		{
			holiday: t('eid-al-adha'),
			days: '3',
			date: t('10-july'),
		},
		{
			holiday: t('qatar-national-day'),
			days: '1',
			date: t('18-december'),
		},
	];
	const leaveRequestsData = [
		{
			type: t('sick-leave'),
			start: '18/11/2021',
			total: '2',
			status: t('approved-3'),
		},
		{
			type: t('paid-leave'),
			start: '15/11/2021',
			total: '7',
			status: t('pending-4'),
		},
		{
			type: t('loss-of-pay-leave'),
			start: '07/09/2021',
			total: '3',
			status: t('approved-3'),
		},
		{
			type: t('annual-leave-0'),
			start: '01/12/2021',
			total: '10',
			status: t('pending-4'),
		},
		{
			type: t('annual-leave-0'),
			start: '01/12/2021',
			total: '10',
			status: t('rejected-1'),
		},
	];

	const alertDatas = [
		{
			name: 'Mevlüt Yıldırım',
			qatarID: 123456,
			expiration: '20/05/2023',
			passport: 123456,
		},
		{
			name: 'Yiğit Kotil',
			qatarID: 123456,
			expiration: '20/05/2023',
			passport: 123456,
		},
		{
			name: 'Doğukan Karabeyin',
			qatarID: 123456,
			expiration: '20/05/2023',
			passport: 123456,
		},
		{
			name: 'Çağrı Tarakçıoğlu',
			qatarID: 123456,
			expiration: '20/05/2023',
			passport: 123456,
		},
		{
			name: 'Enes Sevim',
			qatarID: 123456,
			expiration: '20/05/2023',
			passport: 123456,
		},
	];

	const costExpensesDatas = [
		{
			expenseType: 'Fuel-oil',
			date: '11/8/2021',
			expense: '1.23 ',
			status: t('approved-3'),
		},
		{
			expenseType: t('food'),
			date: '25/12/2021',
			expense: '13.78 ',
			status: t('pending-4'),
		},
		{
			expenseType: t('transportation'),
			date: '13/05/2021',
			expense: '2.11 ',
			status: t('rejected-1'),
		},
		{
			expenseType: t('transportation'),
			date: '13/05/2021',
			expense: '2.11 ',
			status: t('rejected-1'),
		},
		{
			expenseType: t('transportation'),
			date: '13/05/2021',
			expense: '2.11 ',
			status: t('pending-4'),
		},
	];

	const setGraphSize = (width) => {
		if (width >= 1200) {
			//XL
			return 300;
		} else if (992 <= width) {
			//LG
			return 250;
		} else if (768 <= width) {
			//MD
			return 250;
		} else if (576 <= width) {
			//SM
			return 250;
		} else if (336 <= width) {
			//XS
			return 200;
		} else {
			return 250;
		}
	};

	const renderHolidays = () => {
		return publicHolidaysData.map((holiday, index) => {
			return (
				<tr key={index}>
					<td className="text-center">{holiday.holiday}</td>
					<td className="text-center">{holiday.days}</td>
					<td className="text-center">{holiday.date}</td>
				</tr>
			);
		});
	};
	const renderLeaveRequests = () => {
		return leaveRequestsData.map((leave, index) => {
			var textColor, icon;
			if (leave.status == t('approved-3')) {
				textColor = 'text-success';
			} else if (leave.status == t('pending-4')) {
				textColor = 'text-warning';
			} else {
				textColor = 'text-danger';
			}

			return (
				<tr key={index}>
					<td className="text-center">{leave.type}</td>
					<td className="text-center">{leave.start}</td>
					<td className="text-center">{leave.total}</td>
					<td className={`text-center ${textColor}`}>{leave.status}</td>
				</tr>
			);
		});
	};

	const renderAlerts = () => {
		return alertDatas.map((alert, index) => {
			return (
				<tr key={index}>
					<td className="text-center">{alert.name}</td>
					<td className="text-center">{alert.qatarID}</td>
					<td className="text-center">{alert.expiration}</td>
				</tr>
			);
		});
	};

	const renderExpenses = () => {
		return costExpensesDatas.map((expense, index) => {
			var textColor, icon;
			if (expense.status == t('approved-3')) {
				textColor = 'text-success';
			} else if (expense.status == t('pending-4')) {
				textColor = 'text-warning';
			} else {
				textColor = 'text-danger';
			}
			return (
				<tr key={index}>
					<td className="text-center">{expense.expenseType}</td>
					<td className="text-center">{expense.expense}</td>
					<td className={`text-center ${textColor}`}>{expense.status}</td>
				</tr>
			);
		});
	};

	return (
		<div className="py-4">
			<div className="d-block mb-4 mb-xl-0 ">
				<Breadcrumb
					className=" d-block"
					listProps={{
						className: 'breadcrumb-dark breadcrumb-transparent',
					}}>
					<Breadcrumb.Item linkAs={Link} linkProps={{ to: Routes.Overview.path }}>
						<FontAwesomeIcon icon={faHome} />
					</Breadcrumb.Item>
					<Breadcrumb.Item active>{t('human-resources')}</Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<Row className="mb-3">
				<Col md={4}>
					<div className="list-group">
						<li className="list-group-item list-group-item-action active text-center" aria-current="true">
							{t('human-resources-forms')}{' '}
						</li>
						<a
							onClick={() => {
								history.push(Routes.HumanResources.Employee.Index.path);
							}}
							className="list-group-item list-group-item-action d-flex justify-content-between">
							<div className="d-flex  align-items-center ">
								<FontAwesomeIcon className="me-2" icon={faUserTie} />
								<span>{t('employee')}</span>
							</div>
							<FontAwesomeIcon icon={faArrowRight} />
						</a>
						<a
							onClick={() => {
								history.push(Routes.HumanResources.EmployeeEvaluation.Index.path);
							}}
							className="list-group-item list-group-item-action d-flex justify-content-between">
							<div className="d-flex  align-items-center ">
								<FontAwesomeIcon className="me-2" icon={faFileAlt} />
								<span>{t('employee-evaluation')}</span>
							</div>
							<FontAwesomeIcon icon={faArrowRight} />
						</a>
						<a
							onClick={() => {
								history.push(Routes.HumanResources.SalaryDeduction.Index.path);
							}}
							className="list-group-item list-group-item-action d-flex justify-content-between">
							<div className="d-flex  align-items-center ">
								<FontAwesomeIcon className="me-2" icon={faMoneyBillWaveAlt} />
								<span>{t('salary-deduction')}</span>
							</div>
							<FontAwesomeIcon icon={faArrowRight} />
						</a>
						<a
							onClick={() => {
								history.push(Routes.HumanResources.StartWork.Index.path);
							}}
							className="list-group-item list-group-item-action d-flex justify-content-between">
							<div className="d-flex  align-items-center ">
								<FontAwesomeIcon className="me-2" icon={faCalendarCheck} />
								<span>{t('start-work')}</span>
							</div>
							<FontAwesomeIcon icon={faArrowRight} />
						</a>
						<a
							onClick={() => {
								history.push(Routes.HumanResources.WarningLetter.Index.path);
							}}
							className="list-group-item list-group-item-action d-flex justify-content-between">
							<div className="d-flex  align-items-center ">
								<FontAwesomeIcon className="me-2" icon={faExclamation} />
								<span>{t('warning-letter')}</span>
							</div>
							<FontAwesomeIcon icon={faArrowRight} />
						</a>
						<a
							onClick={() => {
								history.push(Routes.HumanResources.Clearance.Index.path);
							}}
							className="list-group-item list-group-item-action d-flex justify-content-between">
							<div className="d-flex  align-items-center ">
								<FontAwesomeIcon className="me-2" icon={faBriefcase} />
								<span>{t('staff-labour-clearance-0')}</span>
							</div>
							<FontAwesomeIcon icon={faArrowRight} />
						</a>
						<a
							onClick={() => {
								history.push(Routes.HumanResources.CashAdvanceRequest.Index.path);
							}}
							className="list-group-item list-group-item-action d-flex justify-content-between">
							<div className="d-flex  align-items-center ">
								<FontAwesomeIcon className="me-2" icon={faMoneyBill} />
								<span>{t('request-for-cash-advance')}</span>
							</div>
							<FontAwesomeIcon icon={faArrowRight} />
						</a>
						<a
							onClick={() => {
								history.push(Routes.HumanResources.Leave.Index.path);
							}}
							className="list-group-item list-group-item-action d-flex justify-content-between">
							<div className="d-flex  align-items-center ">
								<FontAwesomeIcon className="me-2" icon={faCalendarAlt} />
								<span>{t('leave-request')}</span>
							</div>
							<FontAwesomeIcon icon={faArrowRight} />
						</a>
						<a
							onClick={() => {
								history.push(Routes.HumanResources.SalaryIncrement.Index.path);
							}}
							className="list-group-item list-group-item-action d-flex justify-content-between">
							<div className="d-flex  align-items-center ">
								<FontAwesomeIcon className="me-2" icon={faMoneyBillWaveAlt} />
								<span>{t('salary-increment')}</span>
							</div>
							<FontAwesomeIcon icon={faArrowRight} />
						</a>
						<a
							onClick={() => {
								history.push(Routes.HumanResources.Resignation.Index.path);
							}}
							className="list-group-item list-group-item-action d-flex justify-content-between">
							<div className="d-flex  align-items-center ">
								<FontAwesomeIcon className="me-2" icon={faCalendarTimes} />
								<span>{t('resignation')}</span>
							</div>
							<FontAwesomeIcon icon={faArrowRight} />
						</a>
						<a
							onClick={() => {
								history.push(Routes.HumanResources.Termination.Index.path);
							}}
							className="list-group-item list-group-item-action d-flex justify-content-between">
							<div className="d-flex  align-items-center ">
								<FontAwesomeIcon className="me-2" icon={faCalendarTimes} />
								<span>{t('termination')}</span>
							</div>
							<FontAwesomeIcon icon={faArrowRight} />
						</a>
					</div>
				</Col>
				<Col md={8}>
					<IndexTable
						headerProps="text-center"
						title={t('expense-claims')}
						headers={[t('expense-type'), t('expense'), t('status')]}
						renderRows={renderExpenses()}
					/>
				</Col>
			</Row>

			<Row>
				<Col md={12}>
					<IndexTable
						headerProps="text-center"
						title={t('leave-requests-0')}
						headers={[t('leave-type'), t('start'), t('total'), t('status')]}
						renderRows={renderLeaveRequests()}
					/>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<IndexTable
						headerProps="text-center"
						title={t('alerts')}
						headers={[t('name'), t('qatar-id'), t('expiration')]}
						renderRows={renderAlerts()}
					/>
				</Col>
			</Row>
			<Row>
				<Col className="mb-4" md={6}>
					<Card className="h-100 ">
						<Card.Header className="fs-5 text-center">{t('activity-by-department-saportif-technology')} </Card.Header>
						<Card.Body>
							<Pie
								className="h-100"
								// height={setGraphSize(width)}
								// width={setGraphSize(width)}
								options={{
									maintainAspectRatio: false,
								}}
								data={data}
							/>
						</Card.Body>
					</Card>
				</Col>
				<Col md={6}>
					<IndexTable
						headerProps="text-center"
						title={t('public-holidays')}
						headers={[t('holiday'), t('of-days'), t('date-0')]}
						renderRows={renderHolidays()}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default HumanResourcesReport;
