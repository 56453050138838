import { Card, Col, Row } from '@themesberg/react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, initialize, reduxForm, change, arrayPush } from 'redux-form';

import renderDropdown from '../../../../components/formComponents/renderDropdown';
import renderDropdownWithAutoComplete from '../../../../components/formComponents/renderDropdownWithAutoComplete';
import renderInput from '../../../../components/formComponents/renderInput';
import renderTextArea from '../../../../components/formComponents/renderTextArea';
import SubmitButton from '../../../../components/formComponents/SubmitButton';

import axios from '../../../../apis/axios';
import { changeCompanyStructure } from '../../../../helpers/helpers';
import { Policies } from '../../../../helpers/policies';
import { date, isSixDigit, nonZero, required } from '../../../../helpers/validator';
import renderFileInput from '../../../../components/formComponents/renderFileInput';
import Dropzone from 'react-dropzone-uploader';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const ProjectForm = (props) => {
	const { initials, invalid, onSubmit, auth, datas, setContract, setFiles } = props;

	const [projectNameCheck, setProjectNameCheck] = useState(false);
	const [projectCodeCheck, setProjectCodeCheck] = useState(false);

	const { t } = useTranslation();
	useEffect(() => {
		{
			initials != null
				? props.initialize({
						...initials,
						project_id: initials.project_id,
						project_name: initials.project_name,
						status: initials.status,
						start_date: initials.start_date?.split('T')[0] || '',
						estimated_due_date: initials.estimated_due_date?.split('T')[0] || '',
						user: auth.first_name + ' ' + auth.last_name,
				  })
				: props.initialize({
						status: t('active-0'),
						user: auth.first_name + ' ' + auth.last_name,
						start_date: new Date().toISOString().split('T')[0],
						estimated_due_date: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
							.toISOString()
							.split('T')[0],
				  });
		}

		props.change('user_id', auth.user_id);
	}, []);

	useEffect(() => {
		props.change('isValid', !invalid);
	}, [invalid]);

	const renderOptions = (options) => {
		return options.map((option, index) => {
			return (
				<option key={index} value={option}>
					{option}
				</option>
			);
		});
	};

	const renderCompanies = () => {
		if (auth.policies.FINANCE.actions.includes(Policies.FINANCE.VIEW_SELF)) {
			return auth.companies.map((company, index) => {
				return (
					<option key={index} value={company.company_id}>
						{company.company_name}
					</option>
				);
			});
		} else if (auth.policies.FINANCE.actions.includes(Policies.FINANCE.VIEW_ALL)) {
			return datas.entities.map((company, index) => {
				return (
					<option key={index} value={company.company_id}>
						{company.company_name}
					</option>
				);
			});
		} else if (auth.policies.FINANCE.actions.includes(Policies.FINANCE.VIEW_COMPANY)) {
			return changeCompanyStructure(auth.policies.FINANCE.companies, datas.entities).map((company, index) => {
				return (
					<option key={index} value={company.company_id}>
						{company.company_name}
					</option>
				);
			});
		} else {
			return null;
		}
	};

	const checkProjectName = (name) => {
		if (name) {
			axios.get(`construction/project/check/${name}`).then((response) => {
				setProjectNameCheck(response.data);
			});
		} else {
			setProjectNameCheck(false);
		}
	};

	const checkProjectCode = (code) => {
		if (code) {
			axios.get(`construction/project/check/code/${code}`).then((response) => {
				setProjectCodeCheck(response.data);
			});
		} else {
			setProjectCodeCheck(false);
		}
	};

	const getUploadParams = () => {
		return { url: 'https://httpbin.org/post' };
	};

	return (
		<form onSubmit={props.handleSubmit(onSubmit)}>
			<Row>
				<Col xl={12} lg={12} md={12} sm={12} xs={12}>
					<Card border="light" className="bg-white shadow-sm mb-4  w-100">
						<Card.Body>
							<h5 className="mb-4">{t('projects-form')}</h5>
							<Row className="mb-3 ">
								<Col md={6} sm={12}>
									<Field name="user" label={t('by')} component={renderInput} disabled="disabled" />
								</Col>
								<Col md={6} sm={12}>
									<Field
										validate={[nonZero, required]}
										name="company_id"
										label={t('company')}
										component={renderDropdown}
										renderedOptions={renderCompanies()}
									/>
								</Col>
							</Row>
							<hr />
							<Row className="mb-3">
								<Col lg={6} md={12}>
									<Field
										validate={required}
										onChange={(e) => {
											checkProjectName(e.target.value);
										}}
										name="project_name"
										label={t('project-name')}
										component={renderInput}
									/>
								</Col>
								{projectNameCheck && <strong className="text-danger">{t('project-already-exists')}</strong>}

								<Col lg={6} md={12}>
									<Field
										validate={[required, isSixDigit]}
										name="project_code"
										onChange={(e) => {
											checkProjectCode(e.target.value);
										}}
										label={t('project-id-code')}
										component={renderInput}
									/>
								</Col>
								{projectCodeCheck && <strong className="text-danger">{t('project-code-already-exists')}</strong>}
							</Row>
							<Row className="mb-3">
								<Col lg={6} md={12}>
									<Field validate={required} name="contractor" label={t('contractor')} component={renderInput} />
								</Col>
								<Col lg={6} md={12}>
									<Field
										validate={required}
										name="project_manager_id"
										label={t('project-manager')}
										component={renderDropdownWithAutoComplete}
										dataKey="user_id"
										textField="full_name"
										data={datas.users}
									/>
								</Col>
							</Row>
							<hr />

							<Row className="mb-3">
								<Col md={6} sm={12}>
									<Field
										validate={[required, date]}
										name="start_date"
										inputType="date"
										label={t('start-date')}
										component={renderInput}
									/>
								</Col>
								<Col md={6} sm={12}>
									<Field
										validate={[required, date]}
										name="estimated_due_date"
										inputType={t('date-0')}
										label={t('estimated-due-date')}
										component={renderInput}
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col lg={6} md={12}>
									<Field name="address" label={t('address')} validate={required} rows={3} component={renderTextArea} />
								</Col>
								<Col lg={6} md={12}>
									<Field
										validate={nonZero}
										name="status"
										label={t('status')}
										component={renderDropdown}
										renderedOptions={renderOptions([t('active-0'), t('inactive-0'), t('on-hold')])}
									/>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col lg={6} md={12}>
									<label>{t('contract')}</label>
									<Dropzone
										getUploadParams={getUploadParams}
										onChangeStatus={({ meta, file }, status) => {
											if (status == 'headers_received') {
												setContract((oldArr) => [...oldArr, file]);
											}
											if (status == 'removed') {
												setContract((oldArr) => oldArr.filter((f) => f.name != file.name));
											}
										}}
										styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
									/>
								</Col>
								<Col lg={6} md={12}>
									<label>{t('files')}</label>
									<Dropzone
										getUploadParams={getUploadParams}
										onChangeStatus={({ meta, file }, status) => {
											if (status == 'headers_received') {
												setFiles((oldArr) => [...oldArr, file]);
											}
											if (status == 'removed') {
												setFiles((oldArr) => oldArr.filter((f) => f.name != file.name));
											}
										}}
										styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
									/>
								</Col>
							</Row>

							<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
								<a onClick={() => window.history.back()} className="btn btn-secondary">
									{t('back')}{' '}
								</a>
								<SubmitButton
									disabled={projectNameCheck || projectCodeCheck}
									text={t('submit')}
									onSubmit={props.onSubmit}
									invalid={invalid}
								/>
							</div>
						</Card.Body>
					</Card>
				</Col>
				<Col xl={4} lg={4} md={12} sm={12} xs={12}></Col>
			</Row>
		</form>
	);
};

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		entities: state.admin.entity.entities,
	};
};

export default compose(
	connect(mapStateToProps, { initialize, change, arrayPush }),

	reduxForm({
		form: 'projects',
	})
)(ProjectForm);
