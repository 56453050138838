import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import StartWorkForm from './StartWorkForm';
import { getEmployees } from '../../../../actions/HumanResources/employeeActions';
import { createStartWork } from '../../../../actions/HumanResources/startWorkActions';
import { getUsers } from '../../../../actions/User/userActions';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import _ from 'lodash';

const StartWorkCreate = (props) => {
	const { datas, loading } = props;

	useEffect(() => {
		props.getUsers();
		props.getEmployees();
	}, []);

	const onSubmit = (formValues) => {
		if (!_.isEmpty(formValues)) {
			props.createStartWork(formValues);
		}
	};

	return (
		<>
			{loading ? (
				<LoadingSpinner />
			) : (
				<div className="mt-3">
					<StartWorkForm onSubmit={onSubmit} datas={datas} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const {
		user: { users, getUsersLoading },
		humanResources: {
			employee: { employees, getEmployeesLoading },
		},
	} = state;

	return { datas: { users, employees }, loading: getUsersLoading || getEmployeesLoading };
};

export default connect(mapStateToProps, { getEmployees, getUsers, createStartWork })(StartWorkCreate);
