import axios from '../../apis/axios';
import history from '../../history';

import { Routes } from '../../routes';
import { FIRST_REGISTER, INITIAL_LOGIN, SIGN_IN } from '../Auth/types';
import {
	CREATE_USER_PROFILE,
	DELETE_USER,
	GET_ROLES,
	GET_USER,
	GET_USERS,
	GET_USER_PROFILE,
	UPDATE_USER,
	UPDATE_USER_PROFILE,
	GET_USERS_WITH_FILTER,
} from './types';

export const register = (formValues) => async (dispatch, getState) => {
	const state = getState();

	axios
		.post('user/register/', {
			email: formValues.email,
			first_name: formValues.firstName,
			last_name: formValues.lastName,
			token: formValues.token,
			user_azure_id: state.auth.id,
		})
		.then((result) => {
			axios
				.get(`policy/${result.data.user_id}`)
				.then((policyResponse) => {
					dispatch(
						{ type: INITIAL_LOGIN, payload: true },
						{
							type: SIGN_IN,
							payload: {
								policies: policyResponse.data,
							},
						}
					);
					history.push(Routes.Welcome.path);
				})
				.catch((err) => {
					console.log('REGISTER ERROR: ', err);
				});
		})
		.catch((err) => {
			console.log('REGISTER ERROR: ', err.response.data);
		});
};

export const getUsers = () => async (dispatch) => {
	axios
		.get('user/')
		.then((response) => {
			dispatch({ type: GET_USERS, payload: response.data });
		})
		.catch((err) => {});
};

export const getUsersWithFilter = (offset, limit, query, order, search) => async (dispatch) => {
	var params = '';
	params += order.length != 0 ? `${order}&` : '';
	params += query.length != 0 ? `${query}&` : '';
	params += search.length != 0 ? `${search}` : '';

	axios
		.get(`/user/offset/${offset}/limit/${limit}?${params}`)
		.then((response) => {
			dispatch({ type: GET_USERS_WITH_FILTER, payload: response.data });
		})
		.catch((err) => {});
};

export const getUsersByRole = (role_name) => async (dispatch) => {
	axios
		.get(`user/role/${role_name}`)
		.then((response) => {
			dispatch({ type: GET_USERS, payload: response.data });
		})
		.catch((err) => {});
};

export const getUser = (id) => async (dispatch) => {
	axios
		.get(`user/${id}`)
		.then((response) => {
			dispatch({ type: GET_USER, payload: response.data });
		})
		.catch((err) => {});
};

export const updateUser = (formValues) => async (dispatch) => {
	axios
		.put(`user/${formValues.id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_USER, payload: response.data });
			history.push(Routes.Admin.User.Index.path);
		})
		.catch((err) => {});
};

export const getRoles = () => async (dispatch, getState) => {
	axios
		.get('role')
		.then((response) => {
			dispatch({ type: GET_ROLES, payload: response.data });
		})
		.catch((err) => {});
};

export const deleteUser = (id, deleted, setDeleted) => async (dispatch) => {
	axios
		.delete(`user/${id}`)
		.then((response) => {
			dispatch({ type: DELETE_USER, payload: response.data });
			setDeleted(!deleted);
		})
		.catch((err) => {});
};
export const createProfile = (formValues) => async (dispatch, getState) => {
	const state = getState();
	axios
		.post(`profile/`, {
			mobile_phone_number: formValues.mobile_phone_number,
			phone_number: formValues.phone_number,
			birthday: formValues.birthday,
			email: formValues.email,
			first_name: formValues.firstName,
			last_name: formValues.lastName,
			user_id: state.auth.user_id,
		})
		.then((response) => {
			dispatch({ type: CREATE_USER_PROFILE, payload: response.data });
			history.push(Routes.Overview.path);
		})
		.catch((err) => {});
};
export const editProfile = (formValues) => async (dispatch) => {
	axios
		.put(`profile/${formValues.user_id}`, formValues)
		.then((response) => {
			dispatch({ type: UPDATE_USER_PROFILE, payload: response.data });
			history.push(Routes.Overview.path);
		})
		.catch((err) => {});
};

export const getProfile = (id) => async (dispatch) => {
	axios
		.get(`profile/${id}`)
		.then((response) => {
			dispatch({ type: GET_USER_PROFILE, payload: response.data });
		})
		.catch((err) => {});
};
