import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faChartLine, faGlobeEurope, faInfo, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Container, Row, Button, Dropdown } from '@themesberg/react-bootstrap';
import React from 'react';
import { IconCardWidget, RankingWidget } from '../../components/Widgets';
import { Routes } from '../../routes';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Welcome = () => {
	const { t } = useTranslation();

	return (
		<>
			<div className="mt-3 container-fluid welcome-container">
				<Row>
					<Col md={12}>
						<Card>
							<Card.Body>
								<Card.Title className="fw-bolder text-center fs-4">
									{t('saportif-technology-digital-services')}
								</Card.Title>
								<Card.Subtitle className="text-muted fs-7 ">
									{t('we-aim-to-utilize-the-portal-application-to-transform-our-holding-to-the-digital-age')} <br />
									{t(
										'feel-free-to-check-the-documentation-page-if-you-need-any-help-development-team-is-always-eager-to-improve-the-portal-based-on-your-feedback'
									)}{' '}
								</Card.Subtitle>
								<Card.Text className="mt-4 mb-2 fw-bold fs-5">{t('business-workflow-processes')}</Card.Text>
								<Card.Text>
									{t(
										'currently-isr-and-supplier-workflows-are-implemented-through-these-forms-you-can-start-and-approval-workflow'
									)}{' '}
								</Card.Text>
								<Card.Text className="mt-4 mb-2 fw-bold fs-5">{t('why-can-and-apos-t-i-view-a-page')}</Card.Text>
								<Card.Text>
									{t(
										'portal-application-has-many-layers-of-access-policies-and-authorization-filtes-in-place-so-if-you-are-unable-to-reach-some-pages-and-seeing-the-unauthorized-page-you-should-get-in-touch-with-the-portal-admins-and-ask-for-a-review-of-your-user-abilities'
									)}{' '}
								</Card.Text>
								<div className="d-flex justify-content-center ">
									<Button className="mt-4" as={Link} to={Routes.Overview.path}>
										{t('go-to-dashboard')}{' '}
									</Button>
								</div>

								<div className="mt-4">
									<Row>
										<Col href="/faq" as="a" className="mb-4">
											<IconCardWidget
												icon={faQuestionCircle}
												// category="CATEGORY"
												subtitle={t('read-the-most-common-questions-and-learn-the-answers')}
												title="FAQ"
											/>
										</Col>
										<Col href="/documentation" as="a">
											<IconCardWidget
												icon={faQuestion}
												// category="CATEGORY"
												subtitle={t('check-out-portal-documentations-and-give-feedback')}
												title={t('Support')}
											/>
										</Col>
									</Row>
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default Welcome;
