import Multiselect from 'react-widgets/Multiselect';
import { Form } from '@themesberg/react-bootstrap';

export default ({ input, data, meta, textField, dataKey, allowCreate, label, style }) => (
	<>
		{label ? <Form.Label>{label}</Form.Label> : null}
		<Multiselect
			{...input}
			onBlur={() => input.onBlur()}
			value={input.value || []} // requires value to be an array
			data={data}
			textField={textField}
			dataKey={dataKey}
			allowCreate={allowCreate ? 'onFilter' : null}
			onCreate={(value) => {
				input.onChange([...input.value, value]);
			}}
			style={style}
		/>
		<span className="text-danger">{meta.touched && meta.error ? meta.error : null}</span>
	</>
);
