import { GET_OVERVIEW_COUNTERS } from "./types";
import axios from "../apis/axios";

export const getOverviewCounters = () => async (dispatch) => {
	axios
		.get("functions/homepage")
		.then((response) => {
			dispatch({ type: GET_OVERVIEW_COUNTERS, payload: response.data });
		})
		.catch((err) => {
			console.log("GET OVERVIEW COUNTERS ERROR: ", err.response.data.message);
		});
};
